























































































































import Vue from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { SpfApiWebSpaceAccessor } from '@/infra/accessor/spf/ucom/spf-api-web-space-accessor';
import dayjs from 'dayjs';

export default Vue.extend({
  name: 'web-space-input',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },

  data: () => ({
    memberId: '', // 会員ID
    ucomAccountMailId: '', // UCOM_アカウントメールID
    accountName: '', // UCOM_アカウント名
    mailAddress: '', // メールアドレス
    homepageAddress: '', // ホームページアドレス
    webSpaceCapacity: '', // 現在のWebスペース容量
    changeCapacity: null, // 追加/縮小するwebスペース容量
    webSpaceMaxCapacity: '', // Webスペース最大容量
    changeCapacityList: [], // 追加/縮小容量リスト
    unitPrice: 0, // 月額利用料
    serviceDescription: '', // サービス説明
    serviceConsent: false, // サービス説明への同意
    paymentStatus: true, //お支払情報登録有無
    isLoading: false, //ローディング
    errorMessages: [], // エラーメッセージを格納する配列
    errorMessageTitle: '恐れ入りますが、内容をもう一度ご確認ください。', // エラーメッセージ部タイトル
    isAgreeTerms: false,
    NOT_SELECTED_MESSAGE: '追加／縮小容量が選択されていません。',
    paymentUrl: `${process.env.VUE_APP_BASE_URL}` + process.env.VUE_APP_UCOM_PAYMENT_REGISTER_URL,
  }),
  async mounted() {
    try {
      this.$data.isLoading = true;

      // UCOM_アカウントメールIDをStoreから取得
      this.$data.ucomAccountMailId = this.$store.getters['ucomAccountOptionStore/ucomAccountMailId'];

      // UCOM_アカウントメールID(Store)を取得出来ない場合、前画面へ戻る
      if (!this.$data.ucomAccountMailId) {
        await this.$router.push('/ucom/account-option-change/account-list');
        this.$data.isLoading = false;
        return;
      }

      // 画面項目セット
      await this.setData(this.$data.ucomAccountMailId);

      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/error');
    }
  },

  methods: {
    async setData(ucomAccountMailId: string): Promise<void> {
      // 確認画面から戻るボタンで遷移の場合はstoreにデータがある
      if (this.$store.getters['ucomWebSpaceStore/paymentStatus']) {
        await this.setDataByStore();
        return;
      }

      // 会員IDを共通情報より取得
      this.$data.memberId = this.$store.getters['memberStore/member'].id;
      if (!this.$data.memberId) {
        await this.$router.push('/error');
        return;
      }
      try {
        // 現在日付の取得
        const now = await SpfApiServerCommonUtilAccessor.getServerTimeNow();
        const targetDate = this.formatDate(now);
        // Webスペース容量変更：入力(ユーザー)_初期表示API 呼び出し
        const userResponse = await SpfApiWebSpaceAccessor.findUcomWebSpaceUserInfo(this.$data.memberId, ucomAccountMailId, String(targetDate));
        // Webスペース容量変更：入力(サービス)_初期表示API 呼び出し
        const serviceResponse = await SpfApiWebSpaceAccessor.findUcomWebSpaceServiceInfo(this.$data.memberId, ucomAccountMailId, String(targetDate));

        // APIからの取得結果を反映
        this.$data.paymentStatus = userResponse.paymentStatus;
        this.$data.accountName = userResponse.accountName;
        this.$data.mailAddress = userResponse.mailAddress;
        this.$data.homepageAddress = userResponse.homepageAddress;
        this.$data.webSpaceCapacity = userResponse.webSpaceCapacity;
        this.$data.changeCapacityList = serviceResponse.changeCapacityList;
        this.$data.unitPrice = serviceResponse.unitPrice;
        this.$data.webSpaceMaxCapacity = serviceResponse.webSpaceMaxCapacity;
        this.$data.serviceDescription = serviceResponse.serviceDescription;
      } catch (e) {
        await this.$router.push('/error');
        return;
      }
    },

    // storeからデータをセット
    async setDataByStore() {
      this.$data.paymentStatus = this.$store.getters['ucomWebSpaceStore/paymentStatus'];
      this.$data.accountName = this.$store.getters['ucomWebSpaceStore/accountName'];
      this.$data.mailAddress = this.$store.getters['ucomWebSpaceStore/mailAddress'];
      this.$data.homepageAddress = this.$store.getters['ucomWebSpaceStore/homepageAddress'];
      this.$data.webSpaceCapacity = this.$store.getters['ucomWebSpaceStore/webSpaceCapacity'];
      this.$data.changeCapacityList = this.$store.getters['ucomWebSpaceStore/changeCapacityList'];
      this.$data.changeCapacityLabel = this.$store.getters['ucomWebSpaceStore/changeCapacityLabel'];
      this.$data.changeCapacityValue = this.$store.getters['ucomWebSpaceStore/changeCapacityValue'];
      this.$data.unitPrice = this.$store.getters['ucomWebSpaceStore/unitPrice'];
      this.$data.webSpaceMaxCapacity = this.$store.getters['ucomWebSpaceStore/webSpaceMaxCapacity'];
      this.$data.applicationDate = this.$store.getters['ucomWebSpaceStore/applicationDate'];
      this.$data.serviceDescription = this.$store.getters['ucomWebSpaceStore/serviceDescription'];
    },

    // 「次へ進む」ボタンクリック
    async onNext() {
      if (this.$data.isLoading) {
        return;
      }

      this.$data.isLoading = true;

      this.$data.errorMessages = [];

      if (!this.$data.changeCapacity) {
        this.$data.errorMessages.push(this.$data.NOT_SELECTED_MESSAGE);
        await this.scrollPageTop();
        this.$data.isLoading = false;
        return;
      }

      // Webスペース容量情報をStoreへ保存
      await this.saveStore();

      // 確認画面へ遷移
      await this.$router.push('/ucom/web-space/confirm');
      this.$data.isLoading = false;
    },

    // Webスペース容量情報をstoreに保存
    async saveStore(): Promise<void> {
      this.$store.commit('ucomWebSpaceStore/paymentStatus', this.$data.paymentStatus);
      this.$store.commit('ucomWebSpaceStore/ucomAccountMailId', this.$data.ucomAccountMailId);
      this.$store.commit('ucomWebSpaceStore/accountName', this.$data.accountName);
      this.$store.commit('ucomWebSpaceStore/mailAddress', this.$data.mailAddress);
      this.$store.commit('ucomWebSpaceStore/homepageAddress', this.$data.homepageAddress);
      this.$store.commit('ucomWebSpaceStore/webSpaceCapacity', this.$data.webSpaceCapacity);
      this.$store.commit('ucomWebSpaceStore/changeCapacityList', this.$data.changeCapacityList);
      this.$store.commit('ucomWebSpaceStore/changeCapacityLabel', this.$data.changeCapacity.changeCapacityLabel);
      this.$store.commit('ucomWebSpaceStore/changeCapacityValue', this.$data.changeCapacity.changeCapacityValue);
      this.$store.commit('ucomWebSpaceStore/unitPrice', this.$data.unitPrice);
      this.$store.commit('ucomWebSpaceStore/webSpaceMaxCapacity', this.$data.webSpaceMaxCapacity);
      this.$store.commit('ucomWebSpaceStore/applicationDate', dayjs(new Date()).format('YYYY/MM/DD'));
      this.$store.commit('ucomWebSpaceStore/serviceDescription', this.$data.serviceDescription);
    },

    // ページTOPへ
    async scrollPageTop() {
      window.scrollTo(0, 0);
    },
    formatDate(dt: Date) {
      const y = dt.getFullYear();
      const m = ('00' + (dt.getMonth() + 1)).slice(-2);
      const d = ('00' + dt.getDate()).slice(-2);
      return y + '/' + m + '/' + d;
    },
  },
  computed: {
    buttonColorSet() {
      return {
        'btn btn04 bs': !this.isAgreeTerms,
        'btn btn01 bs': this.isAgreeTerms,
      };
    },
  },
});
