import { ReportUnhandledrejectionErrorHandler } from "./reporter/report-unhandledrejection-error-handler";
import { RevisionHandler } from "./revision/revision-handler";

export class UnhandledredrejectionErrorHandler {

  public static async handle(error: Error, vm: Vue, info?: string) {
    try {

      // 判断と遷移だけさせて投げる→ブラウザコンソールに表示させるため
      const isRevisionError = await RevisionHandler.checkRevisionError(error, vm);

      if (isRevisionError) {
        // リビジョンエラーは何もしない
        return;
      }

      this.report(error, vm, info);
      await vm.$router.push('/error');
    } catch (error) {
      // 握りつぶす
    }
  }

  private static async report(error: Error, vm: Vue, info?: string) {

    // 検知したバックエンドへ送るべきエラーは全てここで吸収する
    ReportUnhandledrejectionErrorHandler.reportErrorToUnhandledrejectionErrorHandler(error, vm, info);

  }
}