





















import { hasAdditionalAccount } from '@/shared/const/service-plan-type';
import Vue from 'vue';
import LeftMenuCommonLink from '../components/left-menu-common-link.vue';

export default Vue.extend({
  name: 'ucom-left-menu',
  props: [
    'isLinkedEMansion',
    'isLinkedUcom',
    'isLinkedMcloud',
    'isUcomOrEmansionToppage',
    'isLinkedFiveA'
  ],
  components: {
    LeftMenuCommonLink
  },
  data: () => ({
    // サブアカウント用表示判定
    isSubAccount: false
  }),
  async mounted() {
    this.$data.isSubAccount = await this.isSubAccountFunc();
  },
  methods: {
    async isSubAccountFunc() {
      const planType = this.$store.getters['servicePlanTypeStore/servicePlanType'];
      return hasAdditionalAccount(planType);
    }
  }
});
