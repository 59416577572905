




























































































import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiMainMailAddressAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-main-mail-address-accessor';
import { EMansionMainMailAddressCancel } from '@/shared/classes/spf-api/mail/model/main-mail-address/e-mansion-main-mail-address-cancel';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';

export default Vue.extend({
  name: 'cancel-confirm',
  components: {
    LoadingComponent,
  },
  data: () => ({
    isLoading: true,
    mailAddress: '',
    err: false,
    errMsg: '',
    memberInfo: null,
    propertyInfo: null,
    date: '',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      //Storeからメールアドレスを取得
      this.$data.mailAddress = this.$store.getters['eMansionMainMailAddressStore/mailAddress'];
      if (!this.$data.mailAddress) {
        await this.$router.push('/e-mansion/main-mail-address/list');
        return;
      }
      //StoreからアカウントIDを取得
      this.$data.accountInfo = this.$store.getters['eMansionAccountStore/eMansionAccountInfo'].eMansionAccountInfo;
      //Storeから物件IDを取得
      this.$data.propertyInfo = this.$store.getters['propertyStore/property'];

      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error');
      return;
    }
  },
  methods: {
    /**日付をyyyy/mm/ddの形式にする
     * @param date Date型の日付
     * @return yyyy/mm/ddの形式の文字列
     */
    formatDate(date: Date): string {
      const y = date.getFullYear();
      const m = ('00' + (date.getMonth() + 1)).slice(-2);
      const d = ('00' + date.getDate()).slice(-2);
      return y + '/' + m + '/' + d;
    },

    async goBack() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      await this.$router.push('/e-mansion/main-mail-address/list');
      this.$data.isLoading = false;
    },

    async goNext() {
      try {
        if (this.$data.isLoading) {
          return;
        }
        this.$data.isLoading = true;
        this.$data.date = this.formatDate(await SpfApiServerCommonUtilAccessor.getServerTimeNow());

        //AE10-005を実行
        const mainMailCancelResponse: EMansionMainMailAddressCancel = await SpfApiMainMailAddressAccessor.cancelMainMail(
          this.$data.mailAddress,
          this.$data.accountInfo.eMansionAccountId,
          this.$data.propertyInfo.id,
          this.$data.date
        );

        //エラーがある -> err = trueで、エラー画面に遷移
        //TODO:要確認
        if (mainMailCancelResponse.message) {
          await this.$router.push('/e-mansion/error');
          this.$data.isLoading = false;
          return;
        }
        //解約対象メールアドレスをStoreに登録
        this.$store.commit('eMansionMainMailAddressStore/mailAddress', mainMailCancelResponse.mailAddress);
        //サービス終了日をStoreに登録
        this.$store.commit('eMansionMainMailAddressStore/cancelOn', mainMailCancelResponse.finishDate);
        //解約完了ページへ移動
        await this.$router.push('/e-mansion/main-mail-address/cancel-complete');
      } catch (error) {
        await this.$router.push('/e-mansion/error');
        return;
      }
      this.$data.isLoading = false;
    },
  },
});
