
















































































import { AuthService } from '@/shared/services/auth/auth-service';
import LoadingComponent from '@/shared/components/loading-component.vue';
import Vue from 'vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';

/** Completed コンポーネント */
export default Vue.extend({
  name: 'e-mansion-connectix-completed',
  components: {
    LoadingComponent
  },
  data: () => ({
    isLoading: true, // 読み込み状態,最初はロード中
    isOnNextExecuting: false // onNext()実行中かどうか
  }),
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    /** ログインしているか否かの情報を取得 */
    const isLoggedIn = await AuthService.isLoggedIn();
    // ログインしていない場合「総合トップ」画面にリダイレクトする
    if (!isLoggedIn) {
      this.$router.push('/platform').catch(error => {checkRouterError(error)});
      return;
    }

    // 入力値を保持しているストアの削除
    this.$store.commit('eMansionConnectixEntryStore/entryInputForm', null);
    this.$data.isLoading = false;
  },
  methods: {
    onNext() {
      if (this.isOnNextExecuting) {
        return;
      }
      this.isOnNextExecuting = true;

      this.$router.push('/e-mansion').catch(error => {checkRouterError(error)}).finally( () => {
        this.isOnNextExecuting = false;
      });
    }
  }
});
