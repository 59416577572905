





















import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { FiveACustomer } from '@/shared/classes/external-api/five-a/customer-response';
import { FiveASharedErrorResponse } from '@/shared/classes/external-api/five-a/shared-error-response';
import { UcomCustomerResponse } from '@/shared/classes/external-api/ucom/customer-response';
import { UcomSharedErrorResponse } from '@/shared/classes/external-api/ucom/shared-error-response';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { MEMBER_STATUS } from '@/shared/const/member-status';
import { UA_TYPE, ISP_TYPE_REGEXP, TRANSITION_SOURCE_SERVICE, ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { getIspMemberStatusEMansion, getIspMemberStatusFiveA, getIspMemberStatusUcom } from '@/shared/util/func-get-isp-member-status';
import { hasAdditionalAccount } from '@/shared/const/service-plan-type';
import { AccountUtilService } from '@/shared/services/account/account-util-service';
import { SubAccountRoutingService } from '@/shared/services/route/sub-account-routing-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import Vue from 'vue';
import { Route } from 'vue-router';
import { AuthService } from '../shared/services/auth/auth-service';

/** ログイン後コールバック 画面 */
export default Vue.extend({
  name: 'login-callback',
  data: () => ({
    rawLocation: null as any,
    // ISP(e-mansion)会員ステータス
    ispMemberStatusEMansion: '',
    // ISP(Five.A)会員ステータス
    ispMemberStatusFiveA: '',
    // ISP(ucom)会員ステータス
    ispMemberStatusUcom: ''
  }),
  /** コンポーネント生成時の処理 */
  async mounted(): Promise<Route | void> {
    try {
      // キャストが効かないので無理やりanyで解決する
      const rawLocation: any = await AuthService.handleRedirectCallback();
      this.$data.rawLocation = rawLocation;

      const isSubAccountLinK = rawLocation.query?.subAccount ?? false;
      // この段階ではクエリパラメータ、Auth0のトークンの何れかだけが判断材料となる
      // ※ servicePlanTypeはまだ情報が確立する前の為
      if (isSubAccountLinK
        || await AccountUtilService.isSubAccount()) {

        return await this.$router
          .push({
            path: await SubAccountRoutingService.getNextPathOnCallback(rawLocation.query?.from)
          })
          .catch((error) => {
            checkRouterError(error);
          });
      }

      // 会員ストアから会員ステータス情報を取得する
      // meta.isPublic: true なので、beforeEach内で PortasDBCacheWithStore.main() が実行されない
      const memberStatus: MemberStatus = await this.$store.dispatch('memberStore/memberStatus');

      // 会員ステータス無しの場合はメールを入力してもらう
      if (!memberStatus) {
        return await this.$router
          .push({
            path: '/platform/entry/input-email',
            query: { from: rawLocation.query?.from, apid: rawLocation.query?.apid },
          })
          .catch((error) => {
            checkRouterError(error);
          });
      }

      const status = memberStatus.status;

      /** 会員ステータス0or1or6(退会した人が再度入会の場合) /platform/entry/input-emeilに遷移 */
      if (status == MEMBER_STATUS.ACCOUNT_CREATED || status == MEMBER_STATUS.MAIL_SENT || status == MEMBER_STATUS.ACCOUNT_DELETED) {
        return await this.$router.push('/platform/entry/input-email').catch((error) => {
          checkRouterError(error);
        });
      }

      /** 会員ステータス2でFive.Aの場合 /platform/entry/inputに遷移 */
      if (status === MEMBER_STATUS.MAIL_VALIDATED && memberStatus.transitionSourceService === TRANSITION_SOURCE_SERVICE.FIVE_A) {
        return await this.$router.push('/platform/entry/input').catch((error) => {
          checkRouterError(error);
        });

        /** 会員ステータス2でe-mansion/UCOMの場合 /platform/entry/confirm-emailに遷移 */
      } else if (
        status === MEMBER_STATUS.MAIL_VALIDATED &&
        (memberStatus.transitionSourceService === TRANSITION_SOURCE_SERVICE.UCOM || memberStatus.transitionSourceService === TRANSITION_SOURCE_SERVICE.E_MANSION)
      ) {
        return await this.$router.push('/platform/entry/confirm-email').catch((error) => {
          checkRouterError(error);
        });

        // 会員ステータス3 (引越 > 退会 > 再入会 を想定)
      } else if (status === MEMBER_STATUS.ACCOUNT_REGISTERED_NO_PROPERTY) {
        return await this.$router.push('/platform').catch((error) => {
          checkRouterError(error);
        });
      }

      // meta.isPublic: true なので、beforeEach内で PortasDBCacheWithStore.main() が実行されない
      const member: Member = await this.$store.dispatch('memberStore/member');

      const isLinkedToEMansion = member.primaryKeyMye ? true : false;
      const isLinkedToUcom = member.primaryKeyUcom ? true : false;
      const isLinkedToMcloud = member.primaryKeyMcloud ? true : false;
      // const mcloudMemberId = await AuthService.getMcloudMemberId();

      if (!member.propertyId) {
        return await this.$router.push('/platform').catch((error) => {
          checkRouterError(error);
        });
      }

      // 物件情報をStoreから取得
      // meta.isPublic: true なので、beforeEach内で PortasDBCacheWithStore.main() が実行されない
      const property: Property = await this.$store.dispatch('propertyStore/property', member.propertyId);

      // e-mansion連携済み
      if (isLinkedToEMansion && property.apartmentId && property.uaType === UA_TYPE.E_MANSION && status === MEMBER_STATUS.PROPERTY_LINKED) {
        // Account 情報をStoreに格納
        await this.$store.dispatch('eMansionAccountStore/eMansionAccountInfo', await AuthService.getEMansionLoginId());
        // e-mansion TOP 遷移
        return await this.$router.push('/e-mansion').catch((error) => {
          checkRouterError(error);
        });

        // UCOM連携済み.
      } else if (isLinkedToUcom && property.buildingId && property.uaType === UA_TYPE.UCOM && status === MEMBER_STATUS.PROPERTY_LINKED) {
        return await this.$router.push('/ucom').catch((error) => {
          checkRouterError(error);
        });
      } else if (isLinkedToEMansion && property.apartmentId && property.uaType === UA_TYPE.FIVE_A && status === MEMBER_STATUS.PROPERTY_LINKED) {
        // 会員ステータス: 5.物件紐づけ完了のとき
        return await this.$router.push('/5a').catch((error) => {
          checkRouterError(error);
        });
      }

      if (property.apartmentId && property.uaType === UA_TYPE.E_MANSION
          && (!isLinkedToEMansion && !isLinkedToMcloud)
          && (!property.ispType || /^[25]{1}$/.test(property.ispType!))) {

        // 会員ステータス: 4.アカウント登録完了(物件紐づけ必要)のとき
        if (status === MEMBER_STATUS.ACCOUNT_REGISTERED) {
          return await this.$router.push('/e-mansion/linking').catch(error => {checkRouterError(error)});
        }

      } else if (property.apartmentId && property.uaType === UA_TYPE.FIVE_A && !isLinkedToEMansion) {
        // 会員ステータス: 4.アカウント登録完了(物件紐づけ必要)のとき
        if (status === MEMBER_STATUS.ACCOUNT_REGISTERED) {
          return await this.$router.push('/5a/link-service').catch((error) => {
            checkRouterError(error);
          });

          // 会員ステータス: 5:物件紐づけ完了のとき
        } else if (status === MEMBER_STATUS.PROPERTY_LINKED) {
          return await this.$router.push('/platform').catch((error) => {
            checkRouterError(error);
          });
        }

      } else if(property.buildingId && property.uaType === UA_TYPE.UCOM
          && (!isLinkedToUcom && !isLinkedToMcloud)
          && (!property.ispType || /^[14]{1}$/.test(property.ispType!))) {

        // 会員ステータス: 4.アカウント登録完了(物件紐づけ必要)のとき
        if (status === MEMBER_STATUS.ACCOUNT_REGISTERED) {
          return await this.$router.push('/ucom/linking').catch(error => {checkRouterError(error)});
        }
      }

      // // mcloudアカウントでPortas会員登録し、アカウント連携済みのとき
      // if (isLinkedToMcloud) {

      //   // e-mansion対象物件で、未入会のとき
      //   if (property.apartmentId && property.uaType === UA_TYPE.E_MANSION
      //       && ISP_TYPE_REGEXP.E_MANSION_MCLOUD.test(property.ispType!)
      //       && !isLinkedToEMansion) {
      //     return await this.$router.push('/e-mansion/link-service').catch(error => {checkRouterError(error)});

      //   // ucom対象物件で、未入会のとき
      //   } else if(property.buildingId && property.uaType === UA_TYPE.UCOM
      //             && ISP_TYPE_REGEXP.UCOM_MCLOUD.test(property.ispType!)
      //             && !isLinkedToUcom) {
      //     return await this.$router.push('/ucom/link-service').catch(error => {checkRouterError(error)});

      //   } else {
      //     return await this.$router.push('/platform').catch(error => {checkRouterError(error)});
      //   }
      // }

      // // ispTypeから、mcloudのとき、platformに遷移させる
      // if(property.apartmentId && property.uaType === UA_TYPE.E_MANSION && !isLinkedToEMansion && /^[0367]{1}$/.test(property.ispType!)) {
      //   return await this.$router.push('/platform').catch(error => {checkRouterError(error)});
      // }

      // 会員ステータス 7 のとき
      if (status === MEMBER_STATUS.IN_CANCEL_APPLICATION) {
        // e-mansion のとき
        if (property.apartmentId && property.uaType === UA_TYPE.E_MANSION) {
          if (member.primaryKeyMye) {
            if (await this.canTransitionToIspMemberTopEMansion(property.apartmentId, member.primaryKeyMye)) {
              return await this.$router.push('/e-mansion').catch(error => {checkRouterError(error)});
            }
          }

        // Five.A のとき
        } else if (property.apartmentId && property.uaType === UA_TYPE.FIVE_A) {
          if (member.primaryKeyMye) {
            if (await this.canTransitionToIspMemberTopFiveA(property.apartmentId, member.primaryKeyMye)) {
              return await this.$router.push('/5a').catch(error => {checkRouterError(error)});
            }
          }

        // ucom のとき
        } else if (property.buildingId && property.uaType === UA_TYPE.UCOM) {
          if (member.primaryKeyUcom) {
            if (await this.canTransitionToIspMemberTopUcom(String(member.primaryKeyUcom))) {
              return await this.$router.push('/ucom').catch(error => {checkRouterError(error)});
            }
          }
        }
      }

      return await this.$router.push('/platform').catch(error => {checkRouterError(error)});

    } catch (error: any) {
      if (error.response?.status === 503 && error.response.data) {
        // 握りつぶす
        return;
      }

      const util = require('util');

      if (util.inspect(error) === '[Error: Invalid state]') {
        return await this.$router.push('/login-forwarding').catch((error) => {
          checkRouterError(error);
        });
      }

      throw error;
    }
  },
  methods: {
    /**
     * Portas会員ステータスが7のとき、e-mansion会員専用トップに遷移してokかどうか判定する
     */
    async canTransitionToIspMemberTopEMansion(apartmentId: string, eMansionMemberId: string): Promise<boolean> {

      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: UA_TYPE.E_MANSION,
        apartment_id: apartmentId
      });

      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: eMansionMemberId,
        query: findCustomerByIdQueryDto
      });

      if (eMansionCustomer instanceof EMansionCustomer) {

        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, eMansionCustomer);

        /**
         * ISP(e-mansion)会員ステータスが次の4つの場合、e-mansion会員専用トップに遷移してok
         * (ただし、Portas会員ステータスが7のとき)
         *
         * 1. ISP(e-mansion)会員ステータス: 通常会員
         * 2. ISP(e-mansion)会員ステータス: 退会申し込み
         * 3. ISP(e-mansion)会員ステータス: 移転退会
         * 4. ISP(e-mansion)会員ステータス: 退会済みログイン可能
         */
        if (this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.MEMBER
            || this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION
            || this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL
            || this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK) {
          return true;
        }
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }
      return false;
    },
    /**
     * Portas会員ステータスが7のとき、Five.A会員専用トップに遷移してokかどうか判定する
     */
    async canTransitionToIspMemberTopFiveA(apartmentId: string, fiveAMemberId: string): Promise<boolean> {

      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: UA_TYPE.FIVE_A,
        apartment_id: apartmentId
      });

      const fiveACustomer: FiveACustomer | FiveASharedErrorResponse = await this.$store.dispatch('fiveACommonStore/customer', {
        memberId: fiveAMemberId,
        query: findCustomerByIdQueryDto
      });

      if (fiveACustomer instanceof FiveACustomer) {

        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusFiveA = getIspMemberStatusFiveA(memberStatus, fiveACustomer);

        /**
         * ISP(Five.A)会員ステータスが次の3つの場合、Five.A会員専用トップに遷移してok
         * (ただし、Portas会員ステータスが7のとき)
         *
         * 1. ISP(Five.A)会員ステータス: 通常会員
         * 2. ISP(Five.A)会員ステータス: 退会申し込み
         * 3. ISP(Five.A)会員ステータス: 退会済みログイン可能
         */
        if (this.ispMemberStatusFiveA === ISP_MEMBER_STATUS.FIVE_A.MEMBER
            || this.ispMemberStatusFiveA === ISP_MEMBER_STATUS.FIVE_A.IN_CANCEL_APPLICATION
            || this.ispMemberStatusFiveA === ISP_MEMBER_STATUS.FIVE_A.CANCEL_AND_LOGIN_OK) {
          return true;
        }
      } else {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.E_MANSION.NO_CUSTOMER_INFO);
      }
      return false;
    },
    /**
     * Portas会員ステータスが7のとき、ucom会員専用トップに遷移してokかどうか判定する
     */
    async canTransitionToIspMemberTopUcom(ucomMemberId: string): Promise<boolean> {

      const customer: Promise<UcomCustomerResponse | UcomSharedErrorResponse> = await this.$store.dispatch('ucomCommonStore/customer', ucomMemberId);

      if (customer instanceof UcomCustomerResponse) {

        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusUcom = getIspMemberStatusUcom(memberStatus, customer);

        /**
         * ISP(ucom)会員ステータスが次の3つの場合、ucom会員専用トップに遷移してok
         * (ただし、Portas会員ステータスが7のとき)
         *
         * 1. ISP(ucom)会員ステータス: 通常会員
         * 2. ISP(ucom)会員ステータス: 退会申し込み
         * 3. ISP(ucom)会員ステータス: 退会済みログイン可能
         */
        if (this.ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.MEMBER
            || this.ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.IN_CANCEL_APPLICATION
            || this.ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_OK) {
          return true;
        }
      } else if (customer instanceof UcomSharedErrorResponse) {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.CONTRACTOR_INFO);
      }
      return false;
    }
  }
});
