










































































































import Vue from 'vue';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import { FiveAProperty } from '@/shared/classes/external-api/five-a/property-response';
import { FiveASharedErrorResponse } from '@/shared/classes/external-api/five-a/shared-error-response';
import { FiveAEntryInputForm } from '../entry/classes/entry-input-form';
import { AuthService } from '@/shared/services/auth/auth-service';
import { UaTypeValue, UA_TYPE } from '@/shared/const/service-type';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { isUnder18 } from '@/shared/util/func-is-under-18';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/five-a/customer-dto';
import { FiveACustomer } from '@/shared/classes/external-api/five-a/customer-response';

/** Terms コンポーネント */
export default Vue.extend({
  name: 'terms',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    LoadingComponent
  },
  data: () => ({
    /** 会員情報 */
    member: {},
    /** 物件情報 */
    fiveAProperty: {},
    /** 規約と通知に同意する */
    isAgreeTermsAndNotice: false,
    /** エラーメッセージを格納する配列 */
    errorMessages: [],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '',
    /** 読み込み完了 */
    isMounted: false,
    /** サービス規約URL */
    serviceTermsUrl: process.env.VUE_APP_FIVE_A_CONNECTIX_SERVICE_TERMS_URL,
    // onNext実行中かどうか
    isExecutingOnNext: false,
    // 18歳未満の場合,Connectix登録済み等NGフラグ
    isFailed: false
  }),
  computed: {
    isAgreed() {
      return this.$data.isAgreeTermsAndNotice;
    },
    buttonColorSet() {
      return {
        'btn btn04 bs': !this.isAgreeTermsAndNotice,
        'btn btn01 bs': this.isAgreeTermsAndNotice
      }
    },
    /**9桁のランダムな数字を生成 */
    apid() {
      if (this.$data.fiveAProperty) {
        return this.$data.fiveAProperty.IN_APID;
      } else {
        return '';
      }
    }
  },
  async mounted(): Promise<void> {
    // エラーメッセージ格納配列初期化
    this.$data.errorMessages = [];

    // 入力値を保持しているストアの削除
    this.$store.commit('fiveAEntryStore/entryInputForm', null);

    try {
      /** ログインしているか否かの情報を取得 */
      const isLoggedIn = await AuthService.isLoggedIn();
      // ログインしていない場合「総合トップ」画面にリダイレクトする
      if (!isLoggedIn) {
        this.$router.push('/').catch(error => { checkRouterError(error) });
        return;
      }

      // 会員情報取得処理
      const member: Member = this.$store.getters['memberStore/member'];
      if (!member || !member.propertyId) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      }

      // 物件情報をStoreから取得
      const property: Property = this.$store.getters['propertyStore/property'];
      const uaType: UaTypeValue | null = property.uaType ?? null;
      const propertyId: string | null = property.apartmentId ?? null;

      // 物件テーブルに「Mye物件IDが空文字」OR「UA種別が Null」の場合は e-mansion の外部 API の情報が取得できないためエラー表示
      if (!uaType || !propertyId) {
        this.$data.errorMessages.push('Connectix のお申し込みに必要な情報が取得できませんでした。');
        return;
      }

      /** e-mansion 契約基本情報 API を取得してConnectix未契約であることを確認 */
      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: uaType,
        property_id: propertyId
      });

      // five.A のメンバー ID をStoreから取得する
      const fiveAMemberId: string = await this.$store.dispatch('memberStore/fiveAMemberId');

      // 契約基本情報を Store から取得
      const fiveACustomer: FiveACustomer | FiveASharedErrorResponse = await this.$store.dispatch('fiveACommonStore/customer', {
        memberId: fiveAMemberId,
        query: findCustomerByIdQueryDto
      });

      if (fiveACustomer instanceof FiveACustomer) {
        if (fiveACustomer.op_connectix_contract_status === '1') {
          // 既にConnectix契約済みであれば、エラーメッセージ表示
          this.$data.errorMessageTitle = '恐れ入りますが、お申し込みお手続きを進めることができません。';
          this.$data.errorMessages.push('お客様は既にConnectixを契約しています。');
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          // ボタンを非活性化
          this.isMounted = true;
          this.isFailed = true;
          return;
        }
      }

      // 未成年のとき、会員ステータスを 5:物件紐づけ完了 に変更し、エラーメッセージを表示
      if (isUnder18(this.$store.getters['memberStore/member'].birthdate)) {
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        memberStatus.status = '5';
        await this.$store.dispatch('memberStore/updateMemberStatus', { primaryKeyAuth0: memberStatus.primaryKeyAuth0, memberStatus });

        this.$data.errorMessages.push('18歳未満の方の Five.Aサービス へのお申込みには、別途「親権同意書」のご提出が必要となります。 誠に恐れ入りますが、建物専用ホームページ「よくあるご質問」からサポートセンターへご連絡ください。');
        window.scrollTo(0, 0);

        this.isMounted = true;
        this.isFailed = true;
        return;
      }

      this.$data.member = member;
      this.$data.fiveAProperty = this.$store.getters['fiveACommonStore/property'];
      if (!this.$data.fiveAProperty) {
        const property: Property = this.$store.getters['propertyStore/property'];
        if (!property) {
          // データ不整合エラー
          throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_PROPERTY);
        }
        // 物件情報取得処理
        const params = { propertyId: property.apartmentId, uaType: property.uaType };
        const fiveAProperty: FiveAProperty | FiveASharedErrorResponse = await this.$store.dispatch('fiveACommonStore/property', params);
        if (fiveAProperty instanceof FiveASharedErrorResponse) {
          // APIエラー
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.PROPERTY_INFO);
        }
        this.$store.commit('fiveACommonStore/property', fiveAProperty);
        this.$data.fiveAProperty = fiveAProperty;
      }
    } catch (error) {
      throw error;
    }
    // 読み込み完了までボタンを非活性化
    this.isMounted = true;
    this.isFailed = false;
    
  },
  methods: {
    async onNext() {
      if (this.isExecutingOnNext) {
        return;
      }
      this.isExecutingOnNext = true;

      // エラーメッセージ格納配列初期化
      this.$data.errorMessages = [];

      // 規約同意チェック
      if (!this.$data.isAgreeTermsAndNotice) {
        this.$data.errorMessageTitle = '規約・通知に同意してください。';
        this.$data.errorMessages.push('「UCOM光 レジデンスISP会員規約」、「Connectix サービス規約」、「個人情報取得に係る通知」に同意する”にチェックを入れてください。');
        window.scrollTo(0, 0);
        this.isExecutingOnNext = false;
        return;
      }

      try {
        const property: Property = this.$store.getters['propertyStore/property'];
        const customer = new FiveAEntryInputForm({
          /** UA 種別 */
          uaType: UA_TYPE.FIVE_A,
          /** 物件ID */
          propertyId: String(this.$data.fiveAProperty.apartment_id),
          /** 棟ID */
          buildingId: this.$data.member.buildingNumber,
          /** 部屋番号 */
          roomNumber: this.$data.member.roomNumber,
          /** 氏名 */
          nameSurname: this.$data.member.firstName,
          /** 名 */
          nameFirstName: this.$data.member.givenName,
          /** 姓カナ */
          kanaSurname: this.$data.member.firstNameKatakana,
          /** 名カナ */
          kanaFirstName: this.$data.member.givenNameKatakana,
          /** 性別 */
          sex: '',
          /** 生年月日 yyyy/mm/dd形式 */
          birthday: this.$data.member.birthdate.replace(/\-/g, '/'),
          /** 電話番号 */
          phoneNumber: this.$data.member.phoneNumber,
          /** 連絡先メールアドレス */
          emailAddress: this.$data.member.emailAddress,
          /** 書類送付先種別（空） */
          destinationKind: '',
          /** 郵便番号 */
          destinationZipcode: property.zipcode,
          /** 住所 */
          destinationAddress1: property.address1,
          /** 連絡先電話番号（空） */
          destinationTelephone: '',
          /** 希望メールアドレス（空） */
          desiredAccount: '',
          /** ご契約内容の電子交付希望（空） */
          electronicdelivery: '',
          /** 接続コースID（空） */
          courseId: '',
          /** ご希望パスワード（空） */
          password: '',
          /** Connectix同時お申し込み (1で固定)
           * 1：お申し込み, 0：お申し込まない */
          sdwan: '1'
        });
        this.$store.commit('fiveAEntryStore/entryInputForm', customer);
        this.$router.push('/5a/entry/input').catch(error => { checkRouterError(error) });
      } catch (error) {
        throw error;
      } finally {
        this.isExecutingOnNext = false;
      }
    }
  }
});
