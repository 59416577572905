import Router from "vue-router";

/**
 * 遷移時のエラー判定をする
 * NavigationGuard系統は許容してエラーにしない（もみ消す）
 *
 * @param error: エラーオブジェクト
 * @throw NavigationFailureType.redirected/NavigationFailureType.duplicatedではない場合
 */
export function checkRouterError(error: unknown){
  if(Router.isNavigationFailure(error, Router.NavigationFailureType.redirected) || Router.isNavigationFailure(error, Router.NavigationFailureType.duplicated)){
    // 握りつぶす
    return;
  }
  throw error;
}