import PlatformIndex from '@/pages/platform/index.vue';
import Terms from '@/pages/platform/terms.vue';
import PrivacyPolicy from '@/pages/platform/privacy-policy.vue';
import Help from '@/pages/platform/help.vue';
import Contacts from '@/pages/platform/contacts/contacts.vue';
import ContactsConfirm from '@/pages/platform/contacts/confirm.vue';
import ContactsCompleted from '@/pages/platform/contacts/completed.vue';

import PlatformMaintenance from '@/pages/connectix/connectix-maintenance.vue';
import PlatformEntryInputEmail from '@/pages/platform/entry/input-email.vue';
import PlatformEntryAcceptEmail from '@/pages/platform/entry/accept-email.vue';
import PlatformEntryInput from '@/pages/platform/entry/input.vue';
import PlatformEntryCompleted from '@/pages/platform/entry/completed.vue';
import PlatformEntryConfirm from '@/pages/platform/entry/confirm.vue';
import PlatformEntryConfirmEmail from '@/pages/platform/entry/confirm-email.vue';

import PlatformCancelGuide from '@/pages/platform/cancel/guide.vue';
import PlatformCancelConfirm from '@/pages/platform/cancel/confirm.vue';
import PlatformCancelCompleted from '@/pages/platform/cancel/completed.vue';
import PlatformCancelImpossible from '@/pages/platform/cancel/impossible.vue';

import MyPageIndex from '@/pages/platform/my-page/index.vue';
import MyPageMemberEdit from '@/pages/platform/my-page/member-edit.vue';
import MyPageMemberConfirm from '@/pages/platform/my-page/member-confirm.vue';
import MyPageMemberCompleted from '@/pages/platform/my-page/member-completed.vue';
import { Route, NavigationGuardNext } from 'vue-router';
import store from '@/store';
import { AuthService } from '@/shared/services/auth/auth-service';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Member } from '@/shared/classes/spf-api/member';
import { SearchPropertyDetail, SearchResultOver } from '@/shared/classes/spf-api/property';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { UA_TYPE, PROPERTY_SEARCH_TYPE, TRANSITION_SOURCE_SERVICE } from '@/shared/const/service-type';
import { SubAccountRoutingService } from '@/shared/services/route/sub-account-routing-service';
import { AccountUtilService } from '@/shared/services/account/account-util-service';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';

/** プラットフォーム ルーティング定義 */
const platformRouter = [
  {
    path: '/platform',
    name: 'ログイン後トップ',
    component: PlatformIndex,
    meta: {
      title: 'Portas | 会員専用トップページ | ポルタス',
      description:
        'Portas（ポルタス）は、e-mansionやUcom光 レジデンス、Five.Aなどのサービスを展開するアルテリアネットワークス株式会社が提供するインターネットサービスの総合プラットフォームです。',
      keywords: '会員専用トップページ',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (await AccountUtilService.isSubAccount()) {
        next({ path: await SubAccountRoutingService.getNextPathOnCallback(undefined) });
        return;
      }

      const isLoggedIn = await AuthService.isLoggedIn();
      // 会員ストアから会員ステータス情報を取得する
      const memberStatus: MemberStatus | null = store.getters['memberStore/memberStatus'];
      // 会員情報をStoreから取得
      const member: Member | null = store.getters['memberStore/member']; // beforeEach により、会員情報が取得される
      // OEM一覧情報をDBから取得
      await store.dispatch('oemsStore/oems');

      if (isLoggedIn && memberStatus && member) {
        next();
        return;
      } else {
        next({ path: '/' });
        return;
      }
    },
  },
  {
    path: '/platform/terms',
    name: '利用規定',
    component: Terms,
    meta: {
      title: 'Portas | Portas利用規約 | ポルタス',
      description: 'Portas（ポルタス）Portas利用規約　アルテリアネットワークス株式会社',
      keywords: 'Portas利用規約',
      isPublic: true,
    },
  },
  {
    path: '/platform/privacy-policy',
    name: '個人情報の取り扱い',
    component: PrivacyPolicy,
    meta: {
      title: 'Portas | Portasにおける個人情報の取り扱いについて | ポルタス',
      description: 'Portas（ポルタス）Portasにおける個人情報の取り扱いについて　アルテリアネットワークス株式会社',
      keywords: 'Portasにおける個人情報の取り扱いについて',
      isPublic: true,
    },
  },
  // {
  //   path: '/platform/help',
  //   name: 'よくある質問',
  //   component: Help,
  //   meta: {
  //     title: 'Portas | よくある質問 | ポルタス',
  //     description: 'サービスをご利用の皆様へ　よくある質問　アルテリアネットワークス株式会社',
  //     keywords: 'よくある質問',
  //     isPublic: true
  //   }
  // },
  // {
  //   path: '/platform/contacts',
  //   name: 'Portasお問い合わせ 入力',
  //   component: Contacts,
  //   meta: {
  //     title: 'Portas | お問い合せ 入力 | ポルタス',
  //     description: 'Portas（ポルタス）に関するご質問・お問い合わせ内容を記入してください。入力',
  //     keywords: 'お問い合わせ',
  //     isPublic: true
  //   },
  //   beforeEnter: (_to: Route, from: Route, next: NavigationGuardNext) => {
  //     // 確認画面から戻ったときだけ、入力内容が保持されるようにする
  //     if (from.path !== '/platform/contacts/confirm') {
  //       store.commit('contactsStore/contactsInputForm', null);
  //     }
  //     next();
  //   }
  // },
  // {
  //   path: '/platform/contacts/confirm',
  //   name: 'Portasお問い合わせ 確認',
  //   component: ContactsConfirm,
  //   meta: {
  //     title: 'Portas | お問い合せ 確認 | ポルタス',
  //     description: 'Portas（ポルタス）に関するご質問・お問い合わせ内容を記入してください。確認',
  //     keywords: 'お問い合わせ',
  //     isPublic: true
  //   }
  // },
  // {
  //   path: '/platform/contacts/complete',
  //   name: 'Portasお問い合わせ 完了',
  //   component: ContactsCompleted,
  //   meta: {
  //     title: 'Portas | お問い合せ 完了 | ポルタス',
  //     description: 'Portas（ポルタス）に関するご質問・お問い合わせ内容を記入してください。受付完了',
  //     keywords: 'お問い合わせ',
  //     isPublic: true
  //   }
  // },
  {
    path: '/platform/maintenance',
    name: 'ただいまメンテナンス中です',
    component: PlatformMaintenance,
    meta: {
      title: 'ただいまメンテナンス中です',
      description: 'ただいまメンテナンス中です',
      keywords: 'メンテナンス',
    },
  },
  {
    path: '/platform/entry/input-email',
    name: '新規会員登録 規約同意して申し込む',
    component: PlatformEntryInputEmail,
    meta: {
      title: 'Portas | 新規会員登録 規約同意して申し込む | ポルタス',
      description:
        'Portas（ポルタス）は、e-mansionやUcom光 レジデンス、Five.Aなどのサービスを展開するアルテリアネットワークス株式会社が提供するインターネットサービスの総合プラットフォームです。',
      keywords: '新規会員登録規約同意して申し込む',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (from.path === '/') {
        next('/');
        return;
      } else {
        next();
        return;
      }
    },
  },
  {
    path: '/platform/entry/accept-email',
    name: '新規会員登録 お申し込み受付',
    component: PlatformEntryAcceptEmail,
    meta: {
      title: 'Portas | 新規会員登録 お申し込み受付 | ポルタス',
      description:
        'Portas（ポルタス）は、e-mansionやUcom光 レジデンス、Five.Aなどのサービスを展開するアルテリアネットワークス株式会社が提供するインターネットサービスの総合プラットフォームです。',
      keywords: '新規会員登録お申し込み受付',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (from.path === '/') {
        next('/');
        return;
      } else {
        next();
        return;
      }
    },
  },
  {
    path: '/platform/entry/input',
    name: '新規会員登録 お客様情報入力',
    component: PlatformEntryInput,
    meta: {
      title: 'Portas | 新規会員登録 会員情報入力ページ | ポルタス',
      description:
        'Portas（ポルタス）は、e-mansionやUcom光 レジデンス、Five.Aなどのサービスを展開するアルテリアネットワークス株式会社が提供するインターネットサービスの総合プラットフォームです。',
      keywords: '新規会員登録会員情報入力ページ',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      // 確認画面から戻ったときだけ、入力内容が保持されるようにする
      if (from.path !== '/platform/entry/confirm') {
        // platformEntryStoreで変更されるのは1つだけ
        store.commit('platformEntryStore/initEntryInputForm');
        // 物件検索によりストアに保存された値の初期化
        store.commit('platformEditStore/property', null);
        store.commit('platformEditStore/entryProperty', null);
        store.commit('platformEditStore/inputPropertyForm', null);
        store.commit('platformEditStore/searchPropertyForm', null);
      }

      const memberStatus: MemberStatus | null = store.getters['memberStore/memberStatus'];

      if (memberStatus?.transitionSourceService && memberStatus?.transitionSourceServiceId) {
        const TRANSITION_SOURCE_SERVICE_ID_REGEXP = {
          APARTMENT_ID: /^[0-9a-zA-Z]{6}$/,
          BUILDING_ID: /^[0-9]{13}$/,
        } as const;

        let searchType: number = -1;
        if (memberStatus?.transitionSourceService === TRANSITION_SOURCE_SERVICE.E_MANSION) {
          searchType = PROPERTY_SEARCH_TYPE.E_MANSION;
        } else if (memberStatus?.transitionSourceService === TRANSITION_SOURCE_SERVICE.UCOM) {
          if (TRANSITION_SOURCE_SERVICE_ID_REGEXP.APARTMENT_ID.test(memberStatus?.transitionSourceServiceId)) {
            searchType = PROPERTY_SEARCH_TYPE.E_MANSION;
          } else if (TRANSITION_SOURCE_SERVICE_ID_REGEXP.BUILDING_ID.test(memberStatus?.transitionSourceServiceId)) {
            searchType = PROPERTY_SEARCH_TYPE.UCOM;
          } else {
            throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.TRANSITION_SOURCE_INCONSISTENT);
          }
        } else if (memberStatus?.transitionSourceService === TRANSITION_SOURCE_SERVICE.FIVE_A) {
          searchType = PROPERTY_SEARCH_TYPE.E_MANSION;
        } else {
          throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.TRANSITION_SOURCE_INCONSISTENT);
        }
        try {
          const propertyList: SearchPropertyDetail[] | SearchResultOver = await SpfApiService.searchProperties(
            searchType,
            memberStatus.transitionSourceServiceId, // input-email.vueにて、物件テーブル.apartment_id の値に変換されて登録済み
            false
          );
          if ('errorMessage' in propertyList) { // 検索結果が11件以上の場合
            throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.OVER_SEARCH_PROPERTIES);
          } else if (propertyList.length === 0 || propertyList.length >= 2) {
            throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.LINKED_PROPERTY_INCONSISTENT);
          } else {
            if (propertyList[0].uaType === UA_TYPE.E_MANSION || propertyList[0].uaType === UA_TYPE.UCOM) {
              next({ path: '/platform/entry/completed' });
              return;
            } else if (propertyList[0].uaType === UA_TYPE.FIVE_A) {
              next();
              return;
            }
          }
        } catch (error) {
          throw error;
        }
      } else {
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.LINKED_PROPERTY_INCONSISTENT);
      }
    },
  },
  {
    path: '/platform/entry/confirm',
    name: '新規会員登録 お客様確認',
    component: PlatformEntryConfirm,
    meta: {
      title: 'Portas | 新規会員登録 お客様確認 | ポルタス',
      description:
        'Portas（ポルタス）は、e-mansionやUcom光 レジデンス、Five.Aなどのサービスを展開するアルテリアネットワークス株式会社が提供するインターネットサービスの総合プラットフォームです。',
      keywords: '新規会員登録お客様確認',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/platform/entry/input') {
        next({ path: '/platform/entry/input' });
        return;
      }
      next();
      return;
    },
  },
  {
    path: '/platform/entry/confirm-email',
    name: '新規会員登録 お客様確認',
    component: PlatformEntryConfirmEmail,
    meta: {
      title: 'Portas | 新規会員登録 お客様確認 | ポルタス',
      description:
        'Portas（ポルタス）は、e-mansionやUcom光 レジデンス、Five.Aなどのサービスを展開するアルテリアネットワークス株式会社が提供するインターネットサービスの総合プラットフォームです。',
      keywords: '新規会員登録お客様確認',
    },
  },
  {
    path: '/platform/entry/completed',
    name: '新規会員登録 完了',
    component: PlatformEntryCompleted,
    meta: {
      title: 'Portas | 新規会員登録 完了 | ポルタス',
      description:
        'Portas（ポルタス）は、e-mansionやUcom光 レジデンス、Five.Aなどのサービスを展開するアルテリアネットワークス株式会社が提供するインターネットサービスの総合プラットフォームです。',
      keywords: '新規会員登録完了',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      next();
      return;
    },
  },

  {
    path: '/platform/cancel/guide',
    name: '退会の手続き 案内',
    component: PlatformCancelGuide,
    meta: {
      title: 'Portas | Portas会員退会お申し込み | ポルタス',
      description: 'Portas（ポルタス）会員から退会をご検討中の方へ、退会お申し込み時の注意事項やお申し込みのお手続き方法についてご案内しています。',
      keywords: 'Portas会員退会お申し込み'
    }
  },
  {
    path: '/platform/cancel/confirm',
    name: '退会の手続き 確認',
    component: PlatformCancelConfirm,
    meta: {
      title: 'Portas | Portas会員退会お申し込み 確認 | ポルタス',
      description: 'Portas（ポルタス）会員から退会をご検討中の方へ、退会お申し込み時の注意事項やお申し込みのお手続き方法についてご案内しています。',
      keywords: 'Portas会員退会お申し込み,確認'
    },
    beforeEnter: (to: Route, from: Route, next: NavigationGuardNext): void => {

      // 完了画面から戻った場合は会員専用トップへ遷移する
      if (from.path === '/platform/cancel/completed') {
        next({ path: '/platform' });
        return;
      }
      // 直前のURLが退会の手続き案内画面でなければ退会の手続き案内画面に遷移する
      if (from.path !== '/platform/cancel/guide') {
        next({ path: '/platform/cancel/guide' });
        return;
      }
      next();
    },
  },
  {
    path: '/platform/cancel/completed',
    name: '退会の手続き 退会完了',
    component: PlatformCancelCompleted,
    meta: {
      title: 'Portas | Portas会員退会お申し込み 完了 | ポルタス',
      description: 'Portas（ポルタス）会員から退会をご検討中の方へ、退会お申し込み時の注意事項やお申し込みのお手続き方法についてご案内しています。',
      keywords: 'Portas会員退会お申し込み,完了',
      isPublic: true
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      // 直前のURLが退会の手続き確認画面なら通す
      if (from.path === '/platform/cancel/confirm') {
        return next();
      }

      // 直前のURLが退会の手続き確認ではないならば手続きに戻す
      return next({ path: '/platform/cancel/guide' });
    },
  },
  {
    path: '/platform/cancel/impossible',
    name: '退会の手続き 退会不可',
    component: PlatformCancelImpossible,
    meta: {
      title: 'Portas | Portas会員退会 ご案内 | ポルタス',
      description: 'Portas（ポルタス）会員から退会をご検討中の方へ、退会お申し込み時の注意事項やお申し込みのお手続き方法についてご案内しています。',
      keywords: 'Portas会員からの退会お申し込み,ご案内'
    }
  },

  {
    path: '/platform/my-page',
    name: 'マイページ',
    component: MyPageIndex,
    meta: {
      title: 'Portas | マイページ | ポルタス',
      description: 'Portas（ポルタス）の会員情報をご確認いただけます。',
      keywords: 'マイページ',
    },
  },
  {
    path: '/platform/my-page/member-edit',
    name: '会員情報変更 入力',
    component: MyPageMemberEdit,
    meta: {
      title: 'Portas | マイページ 会員情報変更 入力 | ポルタス',
      description: 'Portas（ポルタス）の会員情報をご確認いただけます。',
      keywords: 'マイページ会員情報変更入力',
    },
    /** 遷移前のパスをstoreに保存する */
    beforeEnter: (_to: Route, from: Route, next: NavigationGuardNext) => {
      if (
        from.path === '/platform/my-page' ||
        from.path === '/platform/contacts' ||
        from.path === '/e-mansion/entry/input' ||
        from.path === '/e-mansion/entry/confirm' ||
        from.path === '/ucom/entry/input' ||
        from.path === '/5a/entry/input' ||
        from.path === '/5a/entry/confirm'
      ) {
        store.commit('platformEditStore/beforePath', from.path);
      }

      // 確認画面から戻ったときだけ、入力内容が保持されるようにする
      if (from.path !== '/platform/my-page/member-confirm') {
        store.commit('platformEditStore/editInputForm', null);
        store.commit('platformEditStore/property', null);
        store.commit('platformEditStore/myPageProperty', null);
        store.commit('platformEditStore/inputPropertyForm', null);
        store.commit('platformEditStore/searchPropertyForm', null);
      }
      next();
    },
  },
  {
    path: '/platform/my-page/member-confirm',
    name: '会員情報変更 確認',
    component: MyPageMemberConfirm,
    meta: {
      title: 'Portas | マイページ 会員情報変更 確認 | ポルタス',
      description: 'Portas（ポルタス）の会員情報をご確認いただけます。',
      keywords: 'マイページ会員情報変更確認',
    },
    beforeEnter: (to: Route, from: Route, next: NavigationGuardNext): void => {
      if (from.path !== '/platform/my-page/member-edit') {
        next({ path: '/platform/my-page/member-edit' });
        return;
      }
      next();
    },
  },
  {
    path: '/platform/my-page/member-completed',
    name: '会員情報変更 完了',
    component: MyPageMemberCompleted,
    meta: {
      title: 'Portas | マイページ 会員情報変更 完了 | ポルタス',
      description: 'Portas（ポルタス）の会員情報をご確認いただけます。',
      keywords: 'マイページ会員情報変更完了',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (from.path === '/') {
        next({ path: '/platform/my-page/member-edit' });
        return;
      } else {
        next();
        return;
      }
    },
  },
];

export default platformRouter;
