/** Webスペース容量 確認 */
export class UcomWebSpaceCapacityUpdate {
  readonly _notifyMailAddress: string[]; // 通知先メールアドレス
  readonly _accountName: string; // UCOM_アカウントID
  readonly _homepageAddress: string; // ホームページアドレス
  readonly _webSpaceCapacity: string; // Webスペース容量
  readonly _changeCapacityLabel: string; // リクエストパラメータ."&"追加／縮小容量(label)
  readonly _price: string; // 単価
  readonly _subscriptionDate: string; // お申込日(システム日付 YYYY/MM/DD)
  readonly _serviceDescription: string; // サービス説明
  readonly _errorMessages: string; // エラーメッセージ

  constructor(
    notifyMailAddress: string[],
    accountName: string,
    homepageAddress: string,
    webSpaceCapacity: string,
    changeCapacityLabel: string,
    price: string,
    subscriptionDate: string,
    serviceDescription: string,
    errorMessages: string
  ) {
    this._notifyMailAddress = notifyMailAddress;
    this._accountName = accountName;
    this._homepageAddress = homepageAddress;
    this._webSpaceCapacity = webSpaceCapacity;
    this._changeCapacityLabel = changeCapacityLabel;
    this._price = price;
    this._subscriptionDate = subscriptionDate;
    this._serviceDescription = serviceDescription;
    this._errorMessages = errorMessages;
  }

  get notifyMailAddress(): string[] {
    return this._notifyMailAddress;
  }
  get accountName(): string {
    return this._accountName;
  }
  get homepageAddress(): string {
    return this._homepageAddress;
  }
  get webSpaceCapacity(): string {
    return this._webSpaceCapacity;
  }
  get changeCapacityLabel(): string {
    return this._changeCapacityLabel;
  }
  get price(): string {
    return this._price;
  }
  get subscriptionDate(): string {
    return this._subscriptionDate;
  }
  get serviceDescription(): string {
    return this._serviceDescription;
  }
  get errorMessages(): string {
    return this._errorMessages;
  }
}
