import { REVISION_UP_ERROR } from '@/shared/const/error/error-info';
import axios, { AxiosRequestConfig } from 'axios';
import { AuthService } from '../auth/auth-service';
import { RevisionService } from '../revision/revision-service';

export class BaseApiService {
  public static api() {
    const axiosClient = axios.create({ baseURL: process.env.VUE_APP_API_ENDPOINT_URL });
    axiosClient.interceptors.request.use(async (config) => {
      // リビジョンアップの場合はエラーを投げる
      if (await RevisionService.isRevisionUp()) {
        throw new axios.Cancel(REVISION_UP_ERROR.CANCEL);
      }

      const isLoggedIn = await AuthService.isLoggedIn();
      if (!isLoggedIn) {
        window.location = process.env.VUE_APP_BASE_URL;
        return config;
      }

      const token = await this.fromAuth0BearerToken();
      config.headers.Authorization = token;

      config.params = this.addCacheBusterParameter(config);

      return config;
    });
    return axiosClient;
  }

  /**
   * API呼び出しにおけるブラウザのキャッシュ参照の無効化処理
   *
   * 同じURLでのHTTP Requestはブラウザのキャッシュ参照を優先して実行されないことがある（Safari）
   * URLに日時データパラメタを付与して毎回異なるURLを生成することで、キャッシュ参照を無効化する
   *
   * レビジョン呼び出し処理はキャッシュ対応実装済だが、本処理は通らないので考慮不要
   * rawApi側は、api呼び出し時に同様の記述しているので本処理は不要
   */
  private static addCacheBusterParameter(config: AxiosRequestConfig): any {
    const params = config.params ?? {};

    // パラメータ名は値によって URLが変わればなんでもいい。ここでは'nochache'を採用
    params.nocache = new Date().getTime();

    // 引数値に直接上記処理を設定しても同じだが、
    // 挙動が見えにくいので戻り値を返す実装を選択した
    return params;
  }

  public static rawApi() {
    const axiosClient = axios.create({ baseURL: process.env.VUE_APP_API_ENDPOINT_URL });
    axiosClient.interceptors.request.use(async (config) => {
      // リビジョンアップの場合はエラーを投げる
      if (await RevisionService.isRevisionUp()) {
        throw new axios.Cancel(REVISION_UP_ERROR.CANCEL);
      }

      return config;
    });
    return axiosClient;
  }

  private static async fromAuth0BearerToken() {
    const token = await AuthService.getAuth0AccessToken();
    return `Bearer ${token}`;
  }
}
