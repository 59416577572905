import axios from 'axios';

import { EMansionSharedErrorResponse } from '../../classes/external-api/e-mansion/shared-error-response';
import {
  EMansionCustomer,
  EMansionCustomerCreateResponse,
  EMansionCustomerCreateResponseResult,
  EMansionCustomerResponse,
  EMansionCustomerUpdateResponse,
  EMansionEmailAuthenticationKeyResponse,
  FindAccountValidationByIdResponse,
  FindAccountValidationByIdResponseResult,
} from '../../classes/external-api/e-mansion/customer-response';
import { EMansionProperty, EMansionPropertyResponse } from '../../classes/external-api/e-mansion/property-response';
import { FindCustomerByIdQueryDto } from '../../../shared/classes/external-api/e-mansion/customer-dto';
import { EMansionRoomsResponseResult } from '@/shared/classes/external-api/e-mansion/rooms-response';
import {
  EMansionCustomerCreateRequestResult,
  EMansionCustomerUpdateRequestResult,
  EMansionEmailAuthenticationKeyRequestResult,
  FindAccountValidationByIdQueryDto,
} from '../../classes/external-api/e-mansion/customer-request';
import { EMansionRoomsResponse } from '../../classes/external-api/e-mansion/rooms-response';
import { FindPropertyByIdQueryDto } from '@/shared/classes/external-api/e-mansion/property-request';
import { BaseExternalApiService } from './base-external-api-service';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import { UaTypeValue } from '@/shared/const/service-type';
import { BaseApiService } from '../api/base-api-service';
import { JudgeError } from '@/infra/accessor/spf/common/judge-error';

const api = BaseExternalApiService.api(1);
const portasApi = BaseApiService.api();

/** 共通利用する e-mansion 外部 API をコールするサービス */
export class EMansionSharedExternalApiService {
  /**
   * 契約基本情報取得
   *
   * @param memberId 会員ID
   * @param ua_type UA種別 1:e-mansion,2:Five.A,3:UCOM光R
   * @param property_id 物件ID
   * @return 契約基本情報
   */
  public static async findCustomerById(memberId: string, query: FindCustomerByIdQueryDto): Promise<EMansionCustomer | EMansionSharedErrorResponse> {
    try {
      const response = await portasApi.get<EMansionCustomerResponse>(`/external/e-mansion/customer/${memberId}`, { params: query }).catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });

      return new EMansionCustomer(response.data.result);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * 物件基本情報取得
   *
   * @param propertyId 物件 ID
   * @param uaType 物件種別
   * @return 物件基本情報
   */
  public static async findPropertyById(params: { apartmentId: string; uaType: UaTypeValue }): Promise<EMansionProperty | EMansionSharedErrorResponse> {
    try {
      // params.propertyId = convert6DigitIdTo8DigitId(params.propertyId);
      const query: FindPropertyByIdQueryDto = {
        ua_type: params.uaType,
      };
      const response = await portasApi.get<EMansionPropertyResponse>(`/external/e-mansion/apartment/properties/${params.apartmentId}`, { params: query }).catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });

      return new EMansionProperty(response.data.result.PARAM);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * 部屋番号一覧取得
   *
   * @param uaType UA種別 1:e-mansion,2:Five.A,3:UCOM光R
   * @param property_id 物件ID
   * @return 部屋番号一覧
   */
  public static async findRooms(uaType: UaTypeValue, propertyId: string): Promise<EMansionRoomsResponseResult | EMansionSharedErrorResponse> {
    try {
      const apid = convert6DigitIdTo8DigitId(propertyId);
      const response = await api.get<EMansionRoomsResponse>(`/properties/${apid}`, { params: { ua_type: uaType } });
      return new EMansionRoomsResponseResult(response.data.result);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * 新規会員登録
   * @return レスポンス
   * @param emansionCustomerCreateRequestResult
   */
  public static async createEMansionCustomer(
    emansionCustomerCreateRequestResult: EMansionCustomerCreateRequestResult
  ): Promise<EMansionCustomerCreateResponse | EMansionSharedErrorResponse> {
    try {
      const response = await api.post<EMansionCustomerCreateResponseResult>('/customers', emansionCustomerCreateRequestResult);
      return response.data.result;
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * 会員情報変更
   * @param memberId メンバーID(主キー)
   * @param emansionCustomerUpdateRequest 会員情報変更リクエストボディ
   * @return 処理結果の成否
   */
  public static async updateEMansionCustomer(
    memberId: string,
    emansionCustomerUpdateRequestResult: EMansionCustomerUpdateRequestResult
  ): Promise<EMansionSharedErrorResponse | undefined> {
    try {
      await api.put<EMansionCustomerUpdateResponse>(`/customers/${memberId}`, emansionCustomerUpdateRequestResult);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * メール認証キーチェック
   * @param memberId メンバーID
   * @param eMansionEmailAuthenticationKeyRequest メール認証キーチェック用リクエストボディ
   * @return 処理結果の成否
   */
  public static async createEMansionEmailAuthenticationKey(
    memberId: string,
    eMansionEmailAuthenticationKeyRequestResult: EMansionEmailAuthenticationKeyRequestResult
  ): Promise<EMansionEmailAuthenticationKeyResponse | EMansionSharedErrorResponse> {
    try {
      const response = await api.post<EMansionEmailAuthenticationKeyResponse>(`/customers/${memberId}/emails`, eMansionEmailAuthenticationKeyRequestResult);
      return new EMansionEmailAuthenticationKeyResponse(response.data);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * ご希望アカウントバリデーションチェックAPI
   * @param query クエリパラメーター
   * @return ご希望アカウントが登録済みかどうかの結果
   */
  public static async findAccoutValidationById(query: FindAccountValidationByIdQueryDto): Promise<EMansionSharedErrorResponse | FindAccountValidationByIdResponse> {
    try {
      query.property_id = convert6DigitIdTo8DigitId(query.property_id);
      const response = await api.get<FindAccountValidationByIdResponseResult>('/find-account-id', { params: query });
      return response.data.result;
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * API 通信エラー時のハンドリング
   *
   * @param error エラーオブジェクト
   * @return e-mansion API 共通エラーオブジェクト
   */
  private static handleError(error: unknown): EMansionSharedErrorResponse {
    if (error instanceof axios.Cancel) {
      throw error;
    }

    if (axios.isAxiosError(error)) {
      const errorResponse: EMansionSharedErrorResponse = error.response?.data;
      if (errorResponse) return new EMansionSharedErrorResponse(errorResponse);
    }

    throw error;
  }
}
