import { BaseApiService } from '@/shared/services/api/base-api-service';
import { JudgeError } from '@/infra/accessor/spf/common/judge-error';
import { EMansionWebMailLoginDto } from '@/shared/classes/spf-api/mail/model/e-mansion/web-mail/e-mansion-web-mail-login-dto';

const api = BaseApiService.api();

/** 内部 API をコールするサービス */
export class SpfApiWebMailAccessor {
  /**
   * Webメールにログインする内部手続きを行い、ログイン用のURLを取得する
   *
   * @param eMansionMailAddressId e-mansion_メールアドレスID
   * @return Webメールログイン情報
   */
  public static async webMailLogin(eMansionMailAddressId: number): Promise<EMansionWebMailLoginDto> {
    console.log('とおった2');
    const response = await api
      .post<EMansionWebMailLoginDto>(`/web-mail/e-mansion/login`, {
        eMansionMailAddressId:eMansionMailAddressId,
      })
      .catch(async (error) => {
        
        await JudgeError.judgeError(error);
        console.log('とおったerror');
        throw error;
      });

    // 内部APIからのResponseを、Front にて利用するDtoに変換して返す
    return this.convertEMansionWebMailLoginDto(response);
  }

  /**
   * 内部APIからのResponseを、Front にて利用するDtoに変換して返す
   * 
   * @param response 内部APIからのResponse
   * @returns Webメールログイン情報
   */
  private static async convertEMansionWebMailLoginDto(response: any | undefined): Promise<EMansionWebMailLoginDto> {
    if (!response) {
      throw new Error('webMailLoginでエラーが発生しました');
    }
    return new EMansionWebMailLoginDto(response.data.redirectUrl, response.data.errorMessages);
  }
}
