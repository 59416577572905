

















































































































import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { SearchPropertyDetail } from '@/shared/classes/spf-api/property';
import { MyPageProperty, InputPropertyForm } from '@/store/platform/platform-edit-store';
import Vue from 'vue';
import { PlatformEditInputForm } from '../../../shared/classes/platform/edit-input-form';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { UA_TYPE } from '@/shared/const/service-type';
import { checkRouterError } from '@/shared/util/router-navigation-func';

type DataType = {
  form: PlatformEditInputForm | null;
  property: SearchPropertyDetail | null;
  myPageProperty: MyPageProperty | null;
  inputPropertyForm: InputPropertyForm | null;
  resultMember: Member | null;
  resultMemberStatus: MemberStatus | null;
  errorMessages: string[];
  errorMessageTitle: string;
  buildingName: string;
  roomNumber: string;
  showBuildingName: boolean;
  showRoomNumber: boolean;
  isSubmitting: boolean;
  isPropertyUaTypeFiveA: boolean;
};

/** MemberConfirm コンポーネント */
export default Vue.extend({
  name: 'member-confirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent
  },
  data(): DataType {
    return {
      form: null,
      property: null,
      myPageProperty: null,
      inputPropertyForm: null,
      resultMember: null,
      resultMemberStatus: null,
      errorMessages: [],
      errorMessageTitle: '入力項目にエラーがあります。ご確認をお願いいたします。',
      buildingName: '',
      roomNumber: '',
      showBuildingName: false,
      showRoomNumber: false,
      isSubmitting: false,
      isPropertyUaTypeFiveA: false
    };
  },
  mounted() {
    this.form = this.$store.getters['platformEditStore/editInputForm'];
    // リロードによりストアが初期化されていたら入力画面に飛ばす
    if (!this.form) {
      this.$router.push('/platform/my-page/member-edit').catch(error => { checkRouterError(error) });
      return;
    }
    this.property = this.$store.getters['platformEditStore/property'];
    /**物件登録済みの場合、物件の情報は動かない */
    if (!this.property) {
      this.inputPropertyForm = this.$store.getters['platformEditStore/inputPropertyForm'];
      if (this.inputPropertyForm && !this.inputPropertyForm.isAfterResister) {
        this.myPageProperty = this.$store.getters['platformEditStore/myPageProperty'];
        this.buildingName = this.inputPropertyForm.inputBuildingData.buildingName ?? '';
        this.roomNumber = this.inputPropertyForm.inputBuildingData.roomNumber;
        this.showBuildingName = !!this.buildingName;
        this.showRoomNumber = !!this.roomNumber;
      }
    }

    // PortasDBの会員テーブルのレコードについて、
    // e-mansion, ucom は 名前、電話番号、生年月日がnullなので表示しない
    // Five.A は、名前、電話番号、生年月日に値があるので表示する
    if (this.property?.uaType === UA_TYPE.FIVE_A) {
      this.isPropertyUaTypeFiveA = true;
    }
  },
  methods: {
    async onNext() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      // ボタン押下中扱いとする
      this.isSubmitting = true;
      if (this.form) {
        try {
          const memberBase: Member | null = this.$store.getters['memberStore/member'];
          if (memberBase) {
            let memberDto: Omit<Member, 'id'> = {
              firstName: this.form.firstName,
              givenName: this.form.givenName,
              firstNameKatakana: this.form.firstNameKatakana,
              givenNameKatakana: this.form.givenNameKatakana,
              emailAddress: this.form.emailAddress,
              phoneNumber: `${this.form.phoneNumber1}-${this.form.phoneNumber2}-${this.form.phoneNumber3}`,
              birthdate: `${this.form.birthYear}-${('00' + this.form.birthMonth).slice(-2)}-${('00' + this.form.birthDay).slice(-2)}`,
              memberStatus: 0,
            };

            /** 物件が選択されている */
            if (!this.property && this.myPageProperty) {
              const propertyDto = {
                propertyId: this.myPageProperty.propertyId,
                zipcode: this.myPageProperty.zipcode,
                address: this.myPageProperty.address,
                buildingNumber: this.myPageProperty.buildingId,
                roomNumber: this.roomNumber
              };
              memberDto = { ...memberDto, ...propertyDto };
              // apartmentBlockNameをUA_TYPEで分ける
              // バリデーションチェック回避のため生年月日と電話番号をnullにする
              if (this.inputPropertyForm?.selectedProperty?.uaType === UA_TYPE.UCOM) {
                memberDto.apartmentBlockName = null;
                memberDto.phoneNumber = null;
                memberDto.birthdate = null;
              }
              // e-mansion物件・Mcloud物件のときのApartmentBlockName（選択された棟名）
              // バリデーションチェック回避のため生年月日と電話番号をnullにする
              if (this.inputPropertyForm?.selectedProperty?.uaType === UA_TYPE.E_MANSION) {
                memberDto.apartmentBlockName = this.inputPropertyForm.inputBuildingData.buildingName;
                memberDto.phoneNumber = null;
                memberDto.birthdate = null;
              }

            } else if (this.property) {
              if (this.property.uaType === UA_TYPE.E_MANSION) {
                memberDto.phoneNumber = null;
                memberDto.birthdate = null;
              } else if (this.property.uaType === UA_TYPE.UCOM) {
                memberDto.phoneNumber = null;
                memberDto.birthdate = null;
              }
            }
            await this.$store.dispatch('memberStore/updateMember', { memberId: memberBase.id, memberDto: memberDto });

            // 更新したのでストアの情報を空(null)にする
            this.$store.commit('platformEditStore/inputPropertyForm', null);
            this.$store.commit('platformEditStore/myPageProperty', null);
          }
        } catch (error: any) {
          const status: number = error.response?.status;

          // メアド重複
          if (status === 409) {
            this.$data.errorMessages = ['ご入力いただいたメールアドレスは既に登録されています。'];
            window.scrollTo(0, 0);
            // ボタン押下解除
            this.isSubmitting = false;
            return;
          }

          throw error;
        } finally {
          this.isSubmitting = false;
        }
      }
      /** 会員情報を変更し、会員情報完了画面に遷移する */
      this.$router.push('/platform/my-page/member-completed').catch(error => { checkRouterError(error) });
    },
    onBack() {
      this.$router.push('/platform/my-page/member-edit').catch(error => { checkRouterError(error) });
    }
  }
});
