







import { checkRouterError } from '@/shared/util/router-navigation-func';
import axios from 'axios';
import Vue from 'vue';
import { ContactsDto } from './platform/contacts/classes/contacts-dto';

/** メンテナンス画面 */
export default Vue.extend({
  name: 'maintenance',
  data: () => ({
    html: '',
  }),
  async mounted() {
    this.$data.html = this.$store.getters['maintenanceStore/html'];
    if(!this.$data.html){
      // エラー確認
      const rawApi = axios.create({ baseURL: process.env.VUE_APP_API_ENDPOINT_URL });
      try {
        await rawApi.post<string>('/contacts/not-login', new ContactsDto({}));// 絶対エラーが起こる
      } catch(error: any) {
        if(error.response?.status === 503 && error.response.data){
          this.$data.html = error.response.data;
        } else {
          return this.$router.push('/platform').catch(error => {checkRouterError(error)});
        }
      }
    }
  }
});
