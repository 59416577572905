import dayjs from 'dayjs';
import axios from 'axios';
import {AuthService} from '@/shared/services/auth/auth-service';
import {
    EMansionVirusCheckCancelResponseDto,
    EMansionVirusCheckDto,
    EMansionVirusCheckUpdateResponseDto
} from '@/shared/classes/spf-api/e-mansion-mail';
import {BaseApiService} from '@/shared/services/api/base-api-service';
import {JudgeError} from '@/infra/accessor/spf/common/judge-error';

const api = BaseApiService.api();
const rawApi = axios.create({baseURL: process.env.VUE_APP_API_ENDPOINT_URL});

/** 内部 API をコールするサービス */
export class SpfApiVirusCheckAccessor {
    /**
     * ウィルスチェック利用状況を取得する
     * @return EMansionVirusCheckDto ウィルスチェック利用状況
     */
    public static async findEMansionVirusCheck(): Promise<EMansionVirusCheckDto> {
        try {
            const id = await AuthService.getMemberId();
            const response = await api.get<EMansionVirusCheckDto>(`/virus-check/e-mansion/joukyou/${id}`).catch(async (error) => {
                await JudgeError.judgeError(error);
                throw error;
            });
            return response.data;
        } catch (error: any) {
            // TODO : 必要なら例外のハンドリングを、不要なら try-catch の削除をする
            console.error(error);
            throw error;
        }
    } // findEMansionVirusCheck

    /**
     * e-mansion ウィルスチェック申込を更新する
     * @return メールアドレス, メッセージ
     */
    public static async updateVirusCheck(emailAddressId: number): Promise<EMansionVirusCheckUpdateResponseDto> {
        const id = await AuthService.getMemberId();
        const date = dayjs(new Date()).format('YYYY/MM/DD');
        const response = await api.post<EMansionVirusCheckUpdateResponseDto>(`/virus-check/e-mansion/kakunin-moushikomi`, {
            id,
            emailAddressId,
            date,
        }).catch(async (error) => {
            await JudgeError.judgeError(error);
            throw error;
        });
        return response.data;
    } // updateVirusCheck

    /**
     * e-mansion ウィルスチェックを解約する
     * @return メールアドレス, メッセージ
     */
    public static async cancelVirusCheck(emailAddressId: number): Promise<EMansionVirusCheckCancelResponseDto> {

        const id = await AuthService.getMemberId();
        const date = dayjs(new Date()).format('YYYY/MM/DD');
        const response = await api.patch<EMansionVirusCheckCancelResponseDto>(`/virus-check/e-mansion/kakunin-kaiyaku`, {
            id,
            emailAddressId,
            date,
        }).catch(async (error) => {
            await JudgeError.judgeError(error);
            throw error;
        });
        ;
        return response.data;
    } // cancelVirusCheck
}
