











































































import Vue from 'vue';
/** エラーメッセージ用コンポーネント */
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import {required} from 'vuelidate/lib/validators';
import {AuthManegementSharedErrorResponse} from '@/shared/classes/auth-manegement/shared-error-response';
import {AuthService} from '@/shared/services/auth/auth-service';
import {checkRouterError} from '@/shared/util/router-navigation-func';
import { AuthManagementApiService } from '@/shared/services/auth/auth-management-api-service';
import { ObfuscationService } from '@/shared/services/obfuscation/obfuscation-service';
import { UcomSubAccountLinkingInput } from '@/shared/classes/auth-manegement/ucom-sub-account-linking-input';

export default Vue.extend({
  name: "sub-account-linking",
  components: {
    /** エラーメッセージ用コンポーネント */
    ErrorMessagesComponent,
    LoadingComponent
  },
  data: () => ({
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。' as string,
    /** 入力データ */
    inputData: {
      accountName: '',
      password: ''
    },
    isExecutingOnAccountlinkage: false, // onAccountlinkage()実行中かどうか
    ucomCustomerRegistrationUrl: process.env.VUE_APP_UCOM_CUSTOMER_REGISTRATION_URL 
  }),
  validations: {
    inputData: {
      accountName: {
        required
      },
      password: {
        required
      }
    }
  },
  async mounted(): Promise<void> {
    /** ログインしているか否かの情報を取得 */
    const isLoggedIn = await AuthService.isLoggedIn();
    // ログインしていなければ エラー画面 に遷移する
    if (!isLoggedIn) {
      this.$router.push('/error');
      return;
    }
  },
  methods: {
    /** フォームのバリデーション */
    validate(): boolean {
      this.errorMessages = [];
      if (this.$v.inputData.$invalid) {
        if (!this.$v.inputData.accountName?.required) {
          this.errorMessages.push('アカウントIDを入力してください。');
        }
        if (!this.$v.inputData.password?.required) {
          this.errorMessages.push('パスワードを入力してください。');
        }
        return false;
      }
      return true;
    },
    /** アカウント連携ボタン押下時 */
    async onAccountlinkage(): Promise<void> {
      try {
        if (this.isExecutingOnAccountlinkage) {
          return;
        }
        this.isExecutingOnAccountlinkage = true;

        if (this.validate()) {
          const ucomSubAccountLinkingInput: UcomSubAccountLinkingInput = {
            accountName: this.inputData.accountName,
            password: ObfuscationService.encode(this.inputData.password),
          };

          const result: undefined | AuthManegementSharedErrorResponse = await AuthManagementApiService.linkingSubAccountToUcom(ucomSubAccountLinkingInput);
          if (result instanceof AuthManegementSharedErrorResponse) {
            if (result.status === 409) {
              this.errorMessages.push('アカウント連携に失敗しました。');
              window.scrollTo(0, 0);
              return;
            } else {
              this.errorMessages.push('アカウントあるいはパスワードが一致しません。');
              window.scrollTo(0, 0);
              return;
            }
          }
          await AuthService.refresh();

          // e-mansion サービストップ に遷移
          await this.$router.push('/ucom').catch((error) => {
            checkRouterError(error);
          });
          return;
        }
      } catch (error) {
        this.$router.push('/error');
      } finally {
        this.isExecutingOnAccountlinkage = false;
      }
    },
  },
});
