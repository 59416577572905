





















































































































































































































import Vue from 'vue';
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import { Swiper as SwiperClass, Navigation, Pagination, Autoplay } from 'swiper/core';
import 'swiper/swiper-bundle.min.css';
import '../../styles/swiper-style.css';
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);

import { Member } from '@/shared/classes/spf-api/member';
import { Property } from '@/shared/classes/spf-api/property';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { MEMBER_STATUS } from '@/shared/const/member-status';

SwiperClass.use([Navigation, Pagination, Autoplay]);

const swiperContentsHasConnectixImage = [
  {
    src: {
      isConnectix: true,
      pc: require('../../images/slide01.jpg'),
      sp: require('../../images/slide01-sp.jpg')
    }
  },
  {
    src: {
      isConnectix: false,
      pc: require('../../images/notlogin/slide01.png'),
      sp: require('../../images/notlogin/slide01-sp.png')
    }
  }
];

const swiperContentsNoConnectixImage = {
  src: {
    isConnectix: false,
    pc: require('../../images/notlogin/slide01.png'),
    sp: require('../../images/notlogin/slide01-sp.png')
  }
};

export default Vue.extend({
  name: 'mainvisual',
  props: ['status','isDisplayConnectix'],
  components: {
    Swiper,
    SwiperSlide
  },
  data: () => ({
    mainSwiperOptions: {
      loop: true,
      speed: 1500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false
      },
      navigation: {
        nextEl: '.main-swiper-button-next',
        prevEl: '.main-swiper-button-prev'
      },
      pagination: {
        el: '.main-swiper-pagination',
        clickable: true
      },
      slidesPerView: 'auto',
      centeredSlides: true,
      spaceBetween: 40,
      preloadImages: true,
      updateOnImagesReady: true
    },
    eMansionConnectixLpUrl: process.env.VUE_APP_E_MANSION_LP_URL,
    fiveAConnectixLpUrl: process.env.VUE_APP_FIVE_A_CONNECTIX_LP_URL,
    ucomConnectixLpUrl: process.env.VUE_APP_UCOM_LP_URL,
    swiperContentsHasConnectixImage, // ファーストビューで表示されるswiper用の要素
    swiperContentsNoConnectixImage, // ファーストビューで表示されるswiper用の要素_connectix無し
    loadedList: new Array<boolean>(), // ファーストビューで表示される画像のうち、読み込み終わった件数

    // 会員情報
    member: null as Member | null,
    // 会員ステータス
    memberStatus: null as MemberStatus | null,
    // 会員ステータスタイプ
    memberStatusType: MEMBER_STATUS,
    // 物件情報
    property: null as Property | null,
    // カルーセルの画像読み込み中かどうか
    isLoadingCarouselImages: true,
}),
  async mounted(): Promise<void>{
    this.member = this.$store.getters['memberStore/member'];
    this.memberStatus = this.$store.getters['memberStore/memberStatus'];

    if (this.member?.propertyId) {
      this.property = this.$store.getters['propertyStore/property'];
    }

    this.isLoadingCarouselImages = false;
  },
  methods: {
    updateIsImagesLoaded(value: boolean) {
      this.loadedList.push(value);
      this.changeIsImagesLoaded();
    },
    changeIsImagesLoaded() {
      if (this.isDisplayConnectix && this.loadedList.length === this.swiperContentsHasConnectixImage.length * 2) {
        this.$emit('isImagesLoadedMainSwiper', true); // index.vue 宛てに画像の読み込み終了を通知
      } else if(!this.isDisplayConnectix){
        this.$emit('isImagesLoadedMainSwiper', true); // index.vue 宛てに画像の読み込み終了を通知
      }
    }
  }
});
