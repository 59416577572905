import { RevisionInfo } from '@/shared/classes/revision-info';
import axios from 'axios';

export class RevisionService {

  public static async isRevisionUp() {
    return await this.checkRevision();
  }

  private static async checkRevision(): Promise<boolean> {

    const revisionClient = axios.create();
    const hash = new Date().getTime();
    const response = await revisionClient.get<RevisionInfo>(`${process.env.VUE_APP_REVISION_JSON_URL}?${hash}`);

    const userWindow = window as any;// typescriptとjavascriptで挙動が異なるためanyを許容する

    if (userWindow.userRevision !== response.data.revision) {
      localStorage.setItem('isReload', 'true');// 文字は何でもよい
      return true;
    }

    return false;

  }

  public static async isAlreadyRevisionUp(): Promise<boolean> {
    const revisionClient = axios.create();
    const hash = new Date().getTime();
    const response = await revisionClient.get<RevisionInfo>(`${process.env.VUE_APP_REVISION_JSON_URL}?${hash}`);

    const userWindow = window as any;// typescriptとjavascriptで挙動が異なるためanyを許容する

    if (localStorage.getItem('isReload') && userWindow.userRevision === response.data.revision) {
      localStorage.removeItem('isReload');
      return true;
    }

    return false;
  }
}