








































import { checkRouterError } from '@/shared/util/router-navigation-func';
import Vue from 'vue';

/** コンポーネント */
export default Vue.extend({
  name: 'accept-email',
  methods: {
    toHome(): void {
      this.$router.push('/').catch(error => {checkRouterError(error)});
    }
  }
});
