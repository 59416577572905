import { UaTypeValue } from "@/shared/const/service-type";

/** 物件 */
export class Property {
  /** 物件 ID */
  public id!: number;

  /* Mye 物件 ID … apartment.apartment_id */
  public apartmentId?: string;
  /* UCOM 建物 ID … tBuilding.buildingId */
  public buildingId?: number;
  /* Mcloud 物件 ID … alias_apartments.apartment_id */
  public mcloudId?: number;
  /* 物件名称 … apartment.apartment_name */
  public apartmentName!: string;
  /* 物件名称カナ … apartment.apartment_kana */
  public apartmentKana!: string;
  /** 表示用物件名称 */
  public dispApartmentName!: string;
  /** 住所コード */
  public addressCode!: string;
  /* 郵便番号 … apartment.zipcode */
  public zipcode?: string;
  /* 住所 … apartment.address1 */
  public address1!: string;
  /* 削除フラグ : 0:未削除,1:削除 … apartment.delete_flag */
  public deleteFlag!: string;
  /* ホームページ掲載開始日 … e_apartment_attribute.start_time */
  public startTime?: Date;
  /* ホームページ掲載終了日 … e_apartment_attribute.end_time */
  public endTime?: Date;
  /* e-mansion サイト検索フラグ : 0:可,1:不可 … e_apartment_attribute.e_search_flag */
  public eSearchFlag!: string;
  /* 検索除外可否 : 0:除外しない (検索対象にする),1:除外する */
  public isExcluded!: boolean;
  /** OEM種別 */
  public oemType?: string;
  /** UA種別 */
  public uaType?: UaTypeValue;
  /** ISP契約種別 */
  public ispType?: string;
  /** 棟と部屋番号 */
  public buildingInfoList?: Building[] | [] | undefined;

  constructor(partial: Partial<Property>) {
    Object.assign(this, partial);
  }
}

// 物件検索APIレスポンス
export class SearchPropertyDetail {
  /** 物件 ID */
  public id!: number;
  /* Mye 物件 ID  */
  public apartmentId!: string;
  /* UCOM 建物 ID … tBuilding.buildingId */
  public buildingId?: number;
  /* Mcloud 物件 ID */
  public mcloudId?: number;
  /* 物件名称  */
  public apartmentName!: string;
  /* 物件名称カナ  */
  public propertyKana!: string;
  /** 表示用物件名称 */
  public dispApartmentName!: string;
  /* 郵便番号 … apartment.zipcode */
  public zipcode?: string;
  /* 住所  */
  public address!: string;
  /** OEM種別 */
  public oemType?: string;
  /** UA種別 */
  public uaType?: UaTypeValue;
  /** ISP契約種別 */
  public ispType?: string;
  /** 棟と部屋番号 */
  public buildingInfoList?: Building[] | [] | undefined;

  constructor(partial: Partial<SearchPropertyDetail>) {
    Object.assign(this, partial);
  }
}

/** 棟と部屋番号 */
export class Building {
  /** 棟 ID */
  public buildingId?: string;
  /** 棟 名 */
  public buildingName?: string;
  /** 部屋番号 */
  public roomNumberList?: string[];

  constructor(partial: Partial<Building>) {
    Object.assign(this, partial);
  }
}

export class SearchResultOver {
  public result!: number;
  public errorMessage!: string;

  constructor(partial: Partial<SearchResultOver>) {
    Object.assign(this, partial);
  }
}