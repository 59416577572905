/** 会員ステータステーブルのstatusの定数 */
export const MEMBER_STATUS = {
  ACCOUNT_CREATED: '0',
  MAIL_SENT: '1',
  MAIL_VALIDATED: '2',
  ACCOUNT_REGISTERED_NO_PROPERTY: '3',
  ACCOUNT_REGISTERED: '4',
  PROPERTY_LINKED: '5',
  ACCOUNT_DELETED: '6',
  // Portas退会申し込み
  IN_CANCEL_APPLICATION: '7'
} as const;

export type MEMBER_STATUS = typeof MEMBER_STATUS[keyof typeof MEMBER_STATUS];
