






















































































import { Member } from '@/shared/classes/spf-api/member';
import { Property } from '@/shared/classes/spf-api/property';
import Vue from 'vue';
import { AuthService } from '@/shared/services/auth/auth-service';
import { FiveAPropertyResponse } from '@/shared/classes/external-api/five-a/property-response';
import { FiveASharedErrorResponse } from '@/shared/classes/external-api/five-a/shared-error-response';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { UA_TYPE } from '@/shared/const/service-type';
import { checkRouterError } from '@/shared/util/router-navigation-func';

/** LinkService コンポーネント */
export default Vue.extend({
  name: 'link-service',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    LoadingComponent
  },
  data: () => ({
      /** 物件情報 */
      fiveAProperty: {},
      /** エラーメッセージを格納する配列 */
      errorMessages: [],
      /** エラーメッセージ部に表示するタイトル */
      errorMessageTitle: '',
      /** 読み込み完了 */
      isMounted: false,
      // mounted() 終わるまで、loading-componentを表示
      isDisplayLoading: true,
      // navigateToLocation() 実行中かどうか
      isExecutingNavigateToLocation: false
  }),
  computed: {
    buttonColorSet() {
      return {
        'btn btn04 bs': !this.isMounted,
        'btn btn01 bs': this.isMounted
      }
    }
  },
  async mounted() {
    // エラーメッセージ格納配列初期化
    this.$data.errorMessages = [];

    try {
      /** ログインしているか否かの情報を取得 */
      const isLoggedIn = await AuthService.isLoggedIn();
      // ログインしていない場合「総合トップ」画面にリダイレクトする
      if (!isLoggedIn) {
        this.$router.push('/').catch(error => {checkRouterError(error)});
        return;
      }
      const member: Member = this.$store.getters['memberStore/member'];
      const propertyId: number = member.propertyId!;

      // 以下物件情報のダミーデータ
      const property: Property = this.$store.getters['propertyStore/property'];
      // Portas会員でない、または顧客情報にFive.A物件情報が紐付けられていない場合はエラー画面へ
      if (!member.id || !member.propertyId || property.uaType !== UA_TYPE.FIVE_A) {
        this.$router.push('/error');
        return;
      }

      // 物件情報取得処理
      const params = { propertyId: property.apartmentId, uaType: property.uaType };
      const fiveAProperty: FiveAPropertyResponse | FiveASharedErrorResponse = await this.$store.dispatch('fiveACommonStore/property', params);

      if (fiveAProperty instanceof FiveASharedErrorResponse) {
        // エラー画面に遷移
        this.$router.push('/error');
        return;
      }

      this.$store.commit('fiveACommonStore/property', fiveAProperty);
      this.$data.fiveAProperty = fiveAProperty;

      // サービスイン日のチェック
      // 物件情報取得APIの「サービスイン日（em_sin_time）」が未来日付の場合、エラーメッセージを表示して申し込めないようにする
      if (this.isPrevServiceInDay(this.$data.fiveAProperty.em_sin_time)) {
        // 規約と通知に同意するチェックとお申し込みボタン使用不可
        this.$data.availableApply = false;
        // エラーメッセージを設定し、画面最上部にスクロール
        this.$data.errorMessages.push('Connectixのお申し込みは現在承ることができません。');
        window.scrollTo(0, 0);
        return;
      }
    } catch(e) {
      this.$router.push('/error');
      return;
    } finally {
      this.isDisplayLoading = false;
    }
    // 読み込み完了までボタンを非活性化
    this.isMounted = true;
  },
  methods: {
    // サービスイン日が未来日か否かを判断する
    // 形式は、em_sin_time: '20220406 00:00:00'
    isPrevServiceInDay(serviceInDate: string) {
      const today = new Date();
      const serviceInDay = new Date(parseInt(serviceInDate.slice(0, 4), 10), parseInt(serviceInDate.slice(4, 6), 10) - 1, parseInt(serviceInDate.slice(6, 8), 10));
      return today < serviceInDay;
    },
    navigateToLocation(path: string) {
      if (this.isExecutingNavigateToLocation) {
        return;
      }
      this.isExecutingNavigateToLocation = true;

      this.$router.push(path).catch(error => {checkRouterError(error)}).finally( () => {
        this.isExecutingNavigateToLocation = false;
      });
    }
}
});
