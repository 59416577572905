







































































































































import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { AuthService } from '@/shared/services/auth/auth-service';

export default Vue.extend({
  name: 'ucom-mail-address-register-completed',
  components: {
    LoadingComponent,
  },
  data: () => ({
    isLoading: false,
    mailAddressType: {
      UCOM: '1',
      OTHER: '2',
    },
    ucomAccountId: '',
    /** 画面表示用のデータ */
    displayData: {
      accountName: '',
      mailAddressType: '',
      displayMailAddressType: '',
      mailAddress: '',
      registerSubscriptionOn: '',
      isChange: '',
    },
    /** UCOM の URL */
    ucomUrl: process.env.VUE_APP_UCOM_UPDATE_MAIL_CAUTION_URL,
  }),
  async mounted(): Promise<void> {
    this.isLoading = true;
    try {
      const isLoggedIn = await AuthService.isLoggedIn();
      if (!isLoggedIn) {
        this.$router.push('/').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
    } catch (error) {
      // エラー画面に遷移
      this.$router.push('/error');
      return;
    }

    // Storeから情報を取得する
    this.$data.ucomAccountId = this.$store.getters['ucomAccountOptionStore/ucomAccountId'];
    this.$data.displayData.accountName = this.$store.getters['ucomAccountOptionStore/accountName'];
    this.$data.displayData.displayMailAddressType = this.$store.getters['ucomAccountOptionStore/displayMailAddressType'];
    this.$data.displayData.mailAddress = this.$store.getters['ucomAccountOptionStore/mailAddress'];
    this.$data.displayData.mailAddressType = this.$store.getters['ucomAccountOptionStore/mailAddressType'];
    this.$data.displayData.isChange = this.$store.getters['ucomAccountOptionStore/isChange'];
    this.$data.displayData.registerSubscriptionOn = this.$store.getters['ucomAccountOptionStore/registerSubscriptionOn'];

    this.isLoading = false;
  },
  methods: {
    async toUcomTop() {
      this.$router.push('/ucom').catch((error) => {
        checkRouterError(error);
      });
    },
  },
});
