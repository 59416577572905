
























































































































import Vue from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';

export default Vue.extend({
  name: 'complete',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    // メソッドが実行中かどうか
    isLoading: false,
    mailAddress: '',
    mailPassword: '',
    smtpServer: '',
    popServer: '',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    //前画面の情報を受け取る
    try {
      this.$data.mailAddress = this.$store.getters['eMansionSubAccountStore/mailAddress'];
      this.$data.mailPassword = this.$store.getters['eMansionSubAccountStore/mailPassword'];
      this.$data.smtpServer = this.$store.getters['eMansionSubAccountStore/smtpServer'];
      this.$data.popServer = this.$store.getters['eMansionSubAccountStore/popServer'];
      //リロードで値がなくなったら一覧画面に飛ばす？
      if (!this.$data.mailAddress) {
        await this.$router.push('/e-mansion/sub-account/list');
        return;
      }
      if (!this.$data.mailPassword) {
        await this.$router.push('/e-mansion/sub-account/list');
        return;
      }
      if (!this.$data.smtpServer) {
        await this.$router.push('/e-mansion/sub-account/list');
        return;
      }
      if (!this.$data.popServer) {
        await this.$router.push('/e-mansion/sub-account/list');
        return;
      }
    } catch (error) {
      await this.$router.push('/e-mansion/error');
      return;
    }
  },
  methods: {
    async goTop() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      await this.$router.push('/e-mansion');
      this.isLoading = false;
    },
    async goList() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.$store.commit('eMansionSubAccountStore/desiredAccount', undefined);
      await this.$router.push('/e-mansion/sub-account/list');
      this.isLoading = false;
    },
  },
});
