
















































































import Vue from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiAccountManagementAccessor } from '@/infra/accessor/spf/ucom/spf-api-account-management-accessor';
import { ObfuscationService } from '@/shared/services/obfuscation/obfuscation-service';
import { UCOM_ACCOUNT_AUTHORITY, convertUcomAccountAuthorityValue } from '@/shared/const/ucom/ucom-account-authority';

export default Vue.extend({
  name: 'account-management-input',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },
  data: () => ({
    isLoading: false, // ローディング
    errorMessages: [], // エラーメッセージを格納する配列
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。', // エラーメッセージ部タイトル
    isOneSentence: false, // エラータイトルのみ表示かどうか

    /* 各種パラメータ */
    accountName: '',
    familyName: '',
    firstName: '',
    familyNameKana: '',
    firstNameKana: '',
    userName: '',
    password: '',
    accountAuthorization: '',
    accountAuthorizationDisplay: '',
    ucomAccountId: '',
    isMailAddressRegistration: null,
  }),
  async mounted() {
    try {
      this.$data.isLoading = true;

      // 前画面(アカウントID管理：登録・変更 入力)からの情報をStoreから取得
      this.setDataByStore();

      // 完了画面からブラウザバックで戻ってきた場合
      if (!this.isAuthorization()) {
        // ストアを削除
        this.$store.commit('ucomAccountManagementStore/ucomAccountId', undefined);
        this.$store.commit('ucomAccountManagementInputStore/accountName', undefined);
        await this.$router.push('/ucom/account-management/list');
        return;
      }

      // アカウント権限種別を画面表示用に加工
      this.setAccountAuthorizationDisplay(this.$data.accountAuthorization);

      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/error');
      return;
    }
  },
  methods: {
    // storeからデータをセット
    setDataByStore(): void {
      this.$data.accountName = this.$store.getters['ucomAccountManagementInputStore/accountName'];
      this.$data.familyName = this.$store.getters['ucomAccountManagementInputStore/familyName'];
      this.$data.firstName = this.$store.getters['ucomAccountManagementInputStore/firstName'];
      this.$data.familyNameKana = this.$store.getters['ucomAccountManagementInputStore/familyNameKana'];
      this.$data.firstNameKana = this.$store.getters['ucomAccountManagementInputStore/firstNameKana'];
      this.$data.userName = this.$store.getters['ucomAccountManagementInputStore/userName'];
      this.$data.password = ObfuscationService.decode(this.$store.getters['ucomAccountManagementInputStore/password']);
      this.$data.accountAuthorization = this.$store.getters['ucomAccountManagementInputStore/accountAuthorization'];
      this.$data.ucomAccountId = this.$store.getters['ucomAccountManagementInputStore/ucomAccountId'];
    },
    // 戻るボタンクリック
    async onBack(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      this.saveStore();

      // アカウントID管理：登録・変更 入力画面へ遷移
      await this.$router.push('/ucom/account-management/input');
      this.$data.isLoading = false;
    },
    // 登録ボタンクリック
    async onNext() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      // エラーメッセージ初期化
      this.$data.errorMessages = [];

      try {
        // 更新処理
        if (this.$data.ucomAccountId) {
          await this.updateAccount();
        }

        // 登録処理
        if (!this.$data.ucomAccountId) {
          await this.registAccount();
        }
      } catch (error) {
        await this.$router.push('/error');
        return;
      }

      if (this.$data.errorMessages.length > 0) {
        // エラーメッセージを見せるために画面最上部にスクロール
        this.scrollPageTop();
        this.isLoading = false;
        return;
      }

      // アカウントID 登録・変更情報をstoreに保存
      this.saveStore();

      // 完了画面へ遷移
      await this.$router.push('/ucom/account-management/completed');
      this.$data.isLoading = false;
    },
    // storeへ保存
    saveStore(): void {
      this.$store.commit('ucomAccountManagementInputStore/accountName', this.$data.accountName);
      this.$store.commit('ucomAccountManagementInputStore/familyName', this.$data.familyName);
      this.$store.commit('ucomAccountManagementInputStore/firstName', this.$data.firstName);
      this.$store.commit('ucomAccountManagementInputStore/familyNameKana', this.$data.familyNameKana);
      this.$store.commit('ucomAccountManagementInputStore/firstNameKana', this.$data.firstNameKana);
      this.$store.commit('ucomAccountManagementInputStore/userName', this.$data.userName);
      this.$store.commit('ucomAccountManagementInputStore/password', ObfuscationService.encode(this.$data.password));
      this.$store.commit('ucomAccountManagementInputStore/accountAuthorization', this.$data.accountAuthorization);
      this.$store.commit('ucomAccountManagementInputStore/ucomAccountId', this.$data.ucomAccountId);
      if (this.$data.isMailAddressRegistration) {
        this.$store.commit('ucomAccountManagementInputStore/isMailAddressRegistration', this.$data.isMailAddressRegistration);
      }
    },
    // ページTOPへ
    scrollPageTop() {
      window.scrollTo(0, 0);
    },
    // アカウント権限種別を画面表示用文字列に加工
    setAccountAuthorizationDisplay(accountAuthorization: string): void {
      this.$data.accountAuthorizationDisplay = convertUcomAccountAuthorityValue(Number(accountAuthorization)).value;
    },
    // 登録処理
    async registAccount(): Promise<void> {
      // 会員情報取得
      const member = this.$store.getters['memberStore/member'];

      try {
        // アカウントID管理：登録・変更_確認_登録API実行
        const accountRegistResponse = await SpfApiAccountManagementAccessor.registerAccount(
          member.id,
          this.$data.accountName,
          this.$data.familyName,
          this.$data.firstName,
          this.$data.familyNameKana,
          this.$data.firstNameKana,
          this.$data.userName,
          ObfuscationService.encode(this.$data.password),
          String(this.$data.accountAuthorization)
        );

        // エラーメッセージがあった場合
        if (accountRegistResponse.errorMessages.length > 0) {
          this.$data.errorMessages = accountRegistResponse.errorMessages;
          return;
        }

        // 次の画面に渡すためのデータをレスポンスから設定
        this.$data.ucomAccountId = accountRegistResponse.ucomId;
        this.$data.accountName = accountRegistResponse.accountName;
        this.$data.familyName = accountRegistResponse.familyName;
        this.$data.firstName = accountRegistResponse.firstName;
        this.$data.familyNameKana = accountRegistResponse.familyNameKana;
        this.$data.firstNameKana = accountRegistResponse.firstNameKana;
        this.$data.userName = accountRegistResponse.userName;
        this.$data.password = ObfuscationService.decode(accountRegistResponse.password);
        this.$data.accountAuthorization = accountRegistResponse.accountAuthorizationDisplay;
      } catch (error) {
        throw error;
      }
    },
    // 更新処理
    async updateAccount(): Promise<void> {
      try {
        // アカウントID管理：登録・変更_確認_登録API実行
        const accountUpdateResponse = await SpfApiAccountManagementAccessor.updateAccount(
          this.$data.ucomAccountId,
          this.$data.accountName,
          this.$data.familyName,
          this.$data.firstName,
          this.$data.familyNameKana,
          this.$data.firstNameKana,
          this.$data.userName,
          ObfuscationService.encode(this.$data.password),
          String(this.$data.accountAuthorization)
        );

        // エラーメッセージがあった場合
        if (accountUpdateResponse.errorMessages.length > 0) {
          this.$data.errorMessages = accountUpdateResponse.errorMessages;
          return;
        }

        // 次の画面に渡すためのデータをレスポンスから設定
        this.$data.ucomAccountId = accountUpdateResponse.ucomId;
        this.$data.accountName = accountUpdateResponse.accountName;
        this.$data.familyName = accountUpdateResponse.familyName;
        this.$data.firstName = accountUpdateResponse.firstName;
        this.$data.familyNameKana = accountUpdateResponse.familyNameKana;
        this.$data.firstNameKana = accountUpdateResponse.firstNameKana;
        this.$data.userName = accountUpdateResponse.userName;
        this.$data.password = ObfuscationService.decode(accountUpdateResponse.password);
        this.$data.accountAuthorization = accountUpdateResponse.accountAuthorizationDisplay;
        this.$data.isMailAddressRegistration = accountUpdateResponse.isMailAddressRegistration;
      } catch (error) {
        throw error;
      }
    },
    isAuthorization(): boolean {
      if (this.$data.accountAuthorization == UCOM_ACCOUNT_AUTHORITY.NORMAL_ACCOUNT) {
        return true;
      }
      if (this.$data.accountAuthorization == UCOM_ACCOUNT_AUTHORITY.ADMIN_ACCOUNT) {
        return true;
      }
      if (this.$data.accountAuthorization == UCOM_ACCOUNT_AUTHORITY.CUSTOMER_NO_USER) {
        return true;
      }
      return false;
    },
  },
});
