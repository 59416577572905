



























































































































import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { SpfApiHomepageAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-homepage-accessor';
import { EMansionHomepageCancelInitialDisplayRequest } from '@/infra/request/spf/e-mansion/homepage/e-mansion-homepage-cancel-initial-display-request';
import { MYE_REGISTER_STATUS } from '@/shared/const/e-mansion/mye-register-status';
import { HomepageData } from '@/infra/response/spf/e-mansion/homepage/e-mansion-homepage-cancel-initial-display-response';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { ObfuscationService } from '@/shared/services/obfuscation/obfuscation-service';

export default Vue.extend({
  name: 'homepage-usage-cancel',
  components: {
    LoadingComponent,
  },

  data: () => ({
    isLoading: true,
    errorMessage: undefined,
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、内容をもう一度ご確認ください。',
    /** 画面に表示するホームページ情報 */
    displayData: HomepageData,
    ftpPassword: undefined,
    /**登録申込中かどうかのフラグ */
    isInContractApplication: false,
    /** 登録済みかどうかのフラグ */
    isContracted: false,
    /** 解約申請中かどうかのフラグ */
    isInWithdrawalApplication: false,
    serviceEndDate: Date,
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      this.$data.isLoading = true;

      const eMansionAccountInfo = this.$store.getters['eMansionAccountStore/eMansionAccountInfo']; // e-mansion アカウント情報取得
      const now = await SpfApiServerCommonUtilAccessor.getServerTimeNow();

      // AE05-003.ホームページ：ご利用状況／ご解約_初期表示API呼び出し
      const response = await SpfApiHomepageAccessor.callHomepageCancelInitialDisplay(
        new EMansionHomepageCancelInitialDisplayRequest({
          _eMansionAccountId: eMansionAccountInfo.eMansionAccountInfo.eMansionAccountId,
          _date: now,
        })
      );

      this.$data.errorMessage = response.homepageOpenedMessage;
      // エラーメッセージがあれば表示して終了とする
      if (response.homepageOpenedMessage) {
        this.$data.isLoading = false;
        return;
      }

      // 表示するホームページ情報
      this.$data.displayData = response.data;
      // パスワードは解読して表示
      this.$data.ftpPassword = ObfuscationService.decode(response.data.ftpPassword);
      // サービス終了日は整形して表示
      const serviceEndDate = response.data.serviceEndDate ? new Date(response.data.serviceEndDate!) : undefined;
      this.$data.serviceEndDate = serviceEndDate ? serviceEndDate.toLocaleDateString() : undefined;
      // 登録申込中かどうかのフラグ
      if (Number(response.registerStatus) === MYE_REGISTER_STATUS.IN_CONTRACT_APPLICATION.id) {
        this.$data.isInContractApplication = true;
      }
      // 登録済みかどうかのフラグ
      if (Number(response.registerStatus) === MYE_REGISTER_STATUS.CONTRACTED.id) {
        this.$data.isContracted = true;
      }
      // 解約申請中かどうかのフラグ
      if (Number(response.registerStatus) === MYE_REGISTER_STATUS.IN_WITHDRAWAL_APPLICATION.id) {
        this.$data.isInWithdrawalApplication = true;
      }
      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error');
      return;
    }
  },
  methods: {
    async goCancel() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      // e-mansion_ホームページIDを次画面用に保持
      this.$store.commit('eMansionHomepageOpenStore/eMansionHomepageId', this.$data.displayData.eMansionHomepageId);

      await this.$router.push('/e-mansion/homepage/cancel-confirm').catch((error) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
  },
});
