















































































































































































































































































































































































































































































































import Vue from 'vue';

import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionConnectixErrorResponse } from '../../connectix/e-mansion/classes/external-api/connectix-error-response';
import { UcomCustomerResponse } from '@/shared/classes/external-api/ucom/customer-response';
import { McloudUserSessionResponse } from '@/shared/classes/external-api/mcloud/user-session-response';
import { McloudApartmentResponse } from '@/shared/classes/external-api/mcloud/apartment-response';
import { Member } from '@/shared/classes/spf-api/member';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { Property } from '@/shared/classes/spf-api/property';
import { McloudSharedErrorResponse } from '@/shared/classes/external-api/mcloud/shared-error-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { ISP_MEMBER_STATUS, ISP_TYPE_REGEXP, UA_TYPE } from '@/shared/const/service-type';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { UcomPropertyResponse } from '@/shared/classes/external-api/ucom/property-response';
import { UcomSharedErrorResponse } from '@/shared/classes/external-api/ucom/shared-error-response';
import { FiveARoomsResponseResult } from '@/shared/classes/external-api/five-a/rooms-response';
import { FiveACustomer } from '@/shared/classes/external-api/five-a/customer-response';
import { FiveASharedErrorResponse } from '@/shared/classes/external-api/five-a/shared-error-response';
import store from '@/store';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { MEMBER_STATUS } from '@/shared/const/member-status';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { getIspMemberStatusFiveA, getIspMemberStatusUcom } from '@/shared/util/func-get-isp-member-status';
import { EMansionSharedExternalApiService } from '@/shared/services/external-api/e-mansion-shared-external-api-service';

export default Vue.extend({
  name: 'portal-service-component',
  data: () => ({
    // Mcloud連携済かどうか
    isLinkedToMcloud: false,
    // UCOM連携済かどうか
    isLinkedToUcom: false,
    // e-mansion連携済かどうか
    isLinkedToEMansion: false,
    // Mcloud未対象物件かどうか
    isTargetToMcloud: false,
    // Connectixお申し込み済みかどうか
    isConnectixContractEMansion: false,
    // Connectixお申し込み可能かどうか
    isAbleToConnectixContractEMansion: false,
    // Connectixお申し込み済みかどうか
    isConnectixContractUcom: false,
    // Connectixお申し込み可能かどうか
    isAbleToConnectixContractUcom: false,
    // FiveA 連携済みかどうか
    isLinkedToFiveA: false,
    // FiveA 会員ID
    fiveAMemberId: '',
    // FiveA Connectixお申し込み済みかどうか
    isConnectixContractFiveA: false,
    // FiveA Connectixお申し込み可能かどうか
    isAbleToConnectixContractFiveA: false,
    // 施設予約機能を利用しているかどうか
    isMcloudUseReservation: false,
    // お知らせ機能を利用しているかどうか
    isMcloudUseNotice: false,
    // カレンダー機能を利用しているかどうか
    isMcloudUseCalendar: false,
    // 会員情報
    member: Member,
    // 会員ステータス
    memberStatus: null as MemberStatus | null,
    // 物件情報
    property: Property,
    // ログイン状態
    isLoggedIn: false,
    // UCOMの会員情報
    ucomMemberId: 0,
    // e-mansionの会員情報
    eMansionMemberId: '',
    // Connectix URL
    eMansionConnectixLpUrl: process.env.VUE_APP_E_MANSION_LP_URL,
    ucomConnectixLpUrl: process.env.VUE_APP_UCOM_LP_URL,
    fiveAConnectixLpUrl: process.env.VUE_APP_FIVE_A_CONNECTIX_LP_URL,
    /** MCLOUD の URL */
    mcloudUrl: process.env.VUE_APP_MCLOUD_URL,
    // e-mansion退会申し込み中・退会済み（ログイン可）フラグ
    isEMansionCancel: false,
    // ISP(FiveA)会員ステータス
    ispMemberStatusFiveA: '',
    // Five.Aバナー表示するかどうか
    isDisplayBannerFiveA: false,
    // Five.Aバナー内のconnectixバナー表示制御
    isDisplayConnectixInFiveABanner: false,
    // ISP(ucom)会員ステータス
    ispMemberStatusUcom: '',
    // ucomバナー表示するかどうか
    isDisplayBannerUcom: false,
    // ucomバナー内のconnectixバナー表示制御
    isDisplayConnectixInUcomBanner: false
  }),

  async mounted(): Promise<void> {
    try {
      // 会員情報取得
      this.$data.member = this.$store.getters['memberStore/member'];
      this.memberStatus = this.$store.getters['memberStore/memberStatus'];
      if (!this.$data.member || !this.memberStatus) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      }

      if (this.$data.member.propertyId) {
        // ここを store.getters に変更すると、エラーになってしまう(原因を突き止めることができなかったため変更せず)
        this.$data.property = await SpfApiService.findPropertyById(this.$data.member.propertyId);

        /**UCOM連携済み */
        if (this.$data.member.primaryKeyUcom) {
          this.$data.isLinkedToUcom = true;
          this.$data.ucomMemberId = this.$data.member.primaryKeyUcom;
          await this.ucomPattern();

          /**Five.A連携済み */
        } else if (this.$data.member.primaryKeyMye && this.$data.property?.uaType === UA_TYPE.FIVE_A) {
          this.$data.isLinkedToFiveA = true;
          this.$data.fiveAMemberId = this.$data.member.primaryKeyMye;
          await this.fiveAPattern();

          /**e-mansion連携済み */
        } else if (this.$data.member.primaryKeyMye) {
          this.$data.isLinkedToEMansion = true;
          this.$data.eMansionMemberId = this.$data.member.primaryKeyMye;
          await this.eMansionPattern();
        }
        // Mcloudは他と一緒があり得るので
        if (this.$data.member.primaryKeyMcloud || this.$data.property?.mcloudId > 0) {
          await this.mcloudPattern();
        }
      }
    } catch (error) {
      throw error;
    }
    this.$emit('isMountedPortalService', true); // platform/index.vue 宛てに送信
  },
  methods: {
    async mcloudPattern() {
      // 物件がMcloud対象物件かどうか確認
      if (
        typeof this.$data.property.ispType === 'string' &&
        ((this.$data.property.uaType === UA_TYPE.UCOM && ISP_TYPE_REGEXP.UCOM_MCLOUD.test(this.$data.property.ispType)) ||
          (this.$data.property.uaType === UA_TYPE.E_MANSION &&
            (ISP_TYPE_REGEXP.MCLOUD.test(this.$data.property.ispType) || ISP_TYPE_REGEXP.E_MANSION_MCLOUD.test(this.$data.property.ispType))))
      ) {
        this.$data.isTargetToMcloud = true;
      }

      try {
        if (this.$data.member.primaryKeyMcloud) {
          this.$data.isLinkedToMcloud = true;
          // Mcloud連携済の場合の機能の利用状況を確認する
          let apartmentID = null;
          const userSession: McloudUserSessionResponse | McloudSharedErrorResponse = await this.$store.dispatch('mcloudCommonStore/userSession');
          if (userSession instanceof McloudUserSessionResponse) {
            // マンションIDを取得
            apartmentID = userSession.attributes.apartment_id;
          } else {
            // エラー画面に遷移
            this.$router.push('/error');// Mcloudのためそのまま
            return;
          }
          const apartment: McloudApartmentResponse | McloudSharedErrorResponse = await this.$store.dispatch('mcloudCommonStore/apartment', Number(apartmentID));
          if (apartment instanceof McloudApartmentResponse) {
            // 機能利用状況を取得
            this.$data.isMcloudUseReservation = apartment.attributes.use_reservation;
            this.$data.isMcloudUseNotice = apartment.attributes.use_notice;
            this.$data.isMcloudUseCalendar = apartment.attributes.use_calendar;
          } else {
            // エラー画面に遷移
            this.$router.push('/error');// Mcloudのためそのまま
            return;
          }
        }
      } catch (error) {
        // エラー画面に遷移
        this.$router.push('/error');// Mcloudのためそのまま
        return;
      }
    },
    async eMansionPattern() {
      // 契約基本情報取得 API 用のクエリ
      // const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
      //   ua_type: UA_TYPE.E_MANSION,
      //   apartment_id: this.$data.property?.apartmentId,
      // });

      // e-mansionの契約基本情報をStoreから取得
      const customer: EMansionCustomer | EMansionSharedErrorResponse  = await this.$store.getters['eMansionCommonStore/customer'];
      if (customer instanceof EMansionCustomer) {
        // connectix申し込み済み判定
        if (customer.op_connectix_contract_status === '1') {
          this.isConnectixContractEMansion = true;
        }
        // 各ISP退会申し込み中・退会済み判定
        const today = new Date();
        if (customer.quit_date) {
          if (new Date(customer.quit_date) < today) {
            this.isEMansionCancel = true;
          } else {
            if (customer.status === "01") {
              this.isEMansionCancel = true;
            }
            if (customer.status === "03") {
              this.isEMansionCancel = true;
            }
          }
        }
        // 顧客基本情報を取得し物件IDを渡す
        const resultFindProperty: EMansionProperty | EMansionSharedErrorResponse = await this.$store.getters['eMansionCommonStore/property'];
        if (resultFindProperty instanceof EMansionSharedErrorResponse) {
          // 外部APIエラー
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.PROPERTY_INFO);
        } else if (resultFindProperty instanceof EMansionProperty) {
          /**
           * ・Connectix対象物件であること
           * 物件情報取得APIの「SD-WANフラグ（op_sdwan_flag）」が"1"
           */
          if (resultFindProperty.op.sdwan.flag === '1') {
            this.$data.isAbleToConnectixContractEMansion = true;
          }
        } else if (customer instanceof EMansionConnectixErrorResponse) {
          // API側から返されたエラーメッセージを表示
          this.$data.errorMessages = customer.errorInfo;
          // エラーメッセージを見せる為に画面最上部にスクロール
          window.scrollTo(0, 0);
          return;
        }
      }
    },
    async ucomPattern() {
      // ucomの契約基本情報をStoreから取得
      const customer = await this.$store.dispatch('ucomCommonStore/customer', this.$data.ucomMemberId);
      if (!(customer instanceof UcomCustomerResponse)) {
        // 外部APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.CONTRACTOR_INFO);
      } else {
        this.$data.isConnectixContractUcom = customer.connectix_status;

        // Connectixにお申し込み済みかどうかのチェック
        if (!customer.connectix_status && customer.connectix_offer) {
          // まだ申し込んでないけど提供できる場合
          const ucomProperty: UcomPropertyResponse | UcomSharedErrorResponse = await this.$store.dispatch('ucomCommonStore/property', this.$data.property.buildingId);
          if (!(ucomProperty instanceof UcomPropertyResponse)) {
            // 外部APIエラー
            throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.PROPERTY_INFO);
          } else {
            const servicesInDate = Date.UTC(
              Number(ucomProperty.connectix_start_date.substring(0, 4)),
              Number(ucomProperty.connectix_start_date.substring(5, 7)) - 1,
              Number(ucomProperty.connectix_start_date.substring(8, 10))
            );

            /**
             * ・Connectix対象物件であること
             *   UCOM契約基本情報取得APIの「Connectix提供有無（connectix_offer）」が"1"
             * ・SIN日以降であること
             *   UCOM物件基本情報取得APIの「Connectixの提供開始日（connectix_start_date）」
             * ・Connectix未契約であること
             *   UCOM契約基本情報取得APIの「Connectixの契約状態（connectix_status）」が"0"
             */
            if (servicesInDate <= new Date().getTime()) {
              this.$data.isConnectixContractUcom = false;
              this.$data.isAbleToConnectixContractUcom = true;
            }
          }
        }
        this.ispMemberStatusUcom = getIspMemberStatusUcom(this.memberStatus!, customer);
        this.isDisplayBannerUcom = this.getFlagDisplayBannerUcom();
        this.isDisplayConnectixInUcomBanner = this.getFlagDisplayConnectixInUcomBanner();
      }
    },
    /**
     * ucomバナーの表示制御
     *
     * | isDisplayBannerUcom | isp-member-status | portas-member-status | isLinkedToUcom |
     * | true                | member            | 5                    | true           |
     * | true                | member            | 7                    | true           |
     * | true                | cancelApplication | 5                    | true           |
     * | true                | cancelApplication | 7                    | true           |
     * | true                | cancelAndLoginOK  | 5                    | true           |
     * | true                | cancelAndLoginOK  | 7                    | true           |
     * | false               | cancelAndLoginNG  | 3                    | true or false  |
     */
    getFlagDisplayBannerUcom() {

      const isExpectedIspMemberStatus = [
        ISP_MEMBER_STATUS.UCOM.MEMBER,
        ISP_MEMBER_STATUS.UCOM.IN_CANCEL_APPLICATION,
        ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_OK
      ].some((value) => value === this.ispMemberStatusUcom);

      const isExpectedPortasMemberStatus = [
        MEMBER_STATUS.PROPERTY_LINKED,
        MEMBER_STATUS.IN_CANCEL_APPLICATION
      ].some((value) => value === this.memberStatus?.status);

      if (this.isLinkedToUcom && isExpectedIspMemberStatus
          && isExpectedPortasMemberStatus) {
        return true;
      }
      return false;
    },
    /**
     * ucomバナー内のconnectixバナー表示制御
     *
     * | isDisplayConnectixInUcomBanner | isp-member-status | portas-member-status | isConnectixContractUcom | isAbleToConnectixContractUcom |
     * | true                           | member            | 5                    | false                   | true                          |
     * | true                           | member            | 7                    | false                   | true                          |
     * | true                           | cancelApplication | 5                    | false                   | true                          |
     * | true                           | cancelApplication | 7                    | false                   | true                          |
     * | false                          | cancelAndLoginOK  | 5                    | true or false           | true or false                 |
     * | false                          | cancelAndLoginOK  | 7                    | true or false           | true or false                 |
     * | false                          | cancelAndLoginNG  | 3                    | true or false           | true or false                 |
     */
    getFlagDisplayConnectixInUcomBanner() {

      const isExpectedIspMemberStatus = [
        ISP_MEMBER_STATUS.UCOM.MEMBER,
        ISP_MEMBER_STATUS.UCOM.IN_CANCEL_APPLICATION
      ].some((value) => value === this.ispMemberStatusUcom);

      const isExpectedPortasMemberStatus = [
        MEMBER_STATUS.PROPERTY_LINKED,
        MEMBER_STATUS.IN_CANCEL_APPLICATION
      ].some((value) => value === this.memberStatus?.status);

      if (isExpectedIspMemberStatus && isExpectedPortasMemberStatus
          && !this.isConnectixContractUcom && this.isAbleToConnectixContractUcom) {
        return true;
      }
      return false;
    },
    async fiveAPattern() {
      // FiveA の契約基本情報をStoreから取得（e-mansion 契約基本情報取得API を使用）
      const fiveAFindCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: UA_TYPE.FIVE_A,
        apartment_id: this.$data.property.apartmentId,
      });
      const fiveACustomer: FiveACustomer | FiveASharedErrorResponse = await this.$store.dispatch('fiveACommonStore/customer', {
        memberId: this.$data.fiveAMemberId,
        query: fiveAFindCustomerByIdQueryDto,
      });

      if (fiveACustomer instanceof FiveASharedErrorResponse) {
        // 外部APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.CONTRACTOR_INFO);
      } else if (fiveACustomer instanceof FiveACustomer) {
        if (fiveACustomer.op_connectix_contract_status === '1') this.$data.isConnectixContractFiveA = true;
      }

      // 物件基本情報を取得し物件IDを渡す
      const resultFindProperty: EMansionProperty | EMansionSharedErrorResponse = await EMansionSharedExternalApiService.findPropertyById({
        uaType: UA_TYPE.FIVE_A,
        apartmentId: this.$data.property.apartmentId,
      });

      if (resultFindProperty instanceof EMansionSharedErrorResponse) {
        // 外部APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.PROPERTY_INFO);
      } else if (resultFindProperty instanceof EMansionProperty) {
        /**
         * ・Connectix対象物件であること
         * 物件情報取得APIの「SD-WANフラグ（op_sdwan_flag）」が"1"
         */
        if (resultFindProperty.op.sdwan.flag === '1') {
          this.isAbleToConnectixContractFiveA = true;
        }
      }

      this.ispMemberStatusFiveA = getIspMemberStatusFiveA(this.memberStatus!, fiveACustomer);
      this.isDisplayBannerFiveA = this.getFlagDisplayBannerFiveA();
      this.isDisplayConnectixInFiveABanner = this.getFlagDisplayConnectixInFiveABanner();
    },
    /**
     * Five.Aバナーの表示制御
     *
     * | isDisplayBannerFiveA | isp-member-status | portas-member-status | isAbleToConnectixContractFiveA |
     * | -------------------- | ----------------- | -------------------- | ------------------------------ |
     * | true                 | member            | 5                    | true                           |
     * | true                 | member            | 7                    | true                           |
     * | true                 | cancelApplication | 5                    | true                           |
     * | true                 | cancelApplication | 7                    | true                           |
     * | true                 | cancelAndLoginOK  | 5                    | true                           |
     * | true                 | cancelAndLoginOK  | 7                    | true                           |
     * | false                | cancelAndLoginNG  | 3                    | true or false                  |
     * | false                | cancelAndLoginNG  | 3                    | true or false                  |
     */
    getFlagDisplayBannerFiveA() {

      const isExpectedIspMemberStatus = [
        ISP_MEMBER_STATUS.FIVE_A.MEMBER,
        ISP_MEMBER_STATUS.FIVE_A.IN_CANCEL_APPLICATION,
        ISP_MEMBER_STATUS.FIVE_A.CANCEL_AND_LOGIN_OK
      ].some((value) => value === this.ispMemberStatusFiveA);

      const isExpectedPortasMemberStatus = [
        MEMBER_STATUS.PROPERTY_LINKED,
        MEMBER_STATUS.IN_CANCEL_APPLICATION
      ].some((value) => value === this.memberStatus?.status);

      if (this.isLinkedToFiveA && isExpectedIspMemberStatus
          && isExpectedPortasMemberStatus && this.isAbleToConnectixContractFiveA) {
        return true;
      }
      return false;
    },
    /**
     * Five.Aバナー内のconnectixバナー表示制御
     *
     * | isDisplayConnectixInFiveABanner | isp-member-status | portas-member-status | isConnectixContractFiveA |
     * | ------------------------------- | ----------------- | -------------------- | ------------------------ |
     * | true                            | member            | 5                    | false                    |
     * | true                            | member            | 7                    | false                    |
     * | false                           | cancelApplication | 5                    | true or falase           |
     * | false                           | cancelApplication | 7                    | true or false            |
     * | false                           | cancelAndLoginOK  | 5                    | true or false            |
     * | false                           | cancelAndLoginOK  | 7                    | true or false            |
     * | false                           | cancelAndLoginNG  | 3                    | true or flase            |
     * | false                           | cancelAndLoginNG  | 3                    | true or flase            |
      */
    getFlagDisplayConnectixInFiveABanner() {

      const isExpectedIspMemberStatus = ISP_MEMBER_STATUS.FIVE_A.MEMBER === this.ispMemberStatusFiveA;

      const isExpectedPortasMemberStatus = [
        MEMBER_STATUS.PROPERTY_LINKED,
        MEMBER_STATUS.IN_CANCEL_APPLICATION
      ].some((value) => value === this.memberStatus?.status)

      if (this.isLinkedToFiveA && isExpectedIspMemberStatus
          && isExpectedPortasMemberStatus && !this.isConnectixContractFiveA) {
        return true;
      }
      return false;
    }
  }
});
