import { Module } from 'vuex';
import { RootState } from '../index';
import { PlatformEntryInputForm } from '../../shared/classes/platform/entry-input-form';
import { Property, Building } from '../../shared/classes/spf-api/property';

/** Platform 新規会員登録 State */
const platformEntryState: {
  entryInputForm: PlatformEntryInputForm | null; // ユーザー情報
  selectedProperty: Property | null; // 物件情報
  propertyList: Property[] | []; // 物件情報の検索結果
  selectedBuildingId: string; // 選択した棟ID
} = {
  /** Platform 新規会員登録 入力フォーム */
  entryInputForm: {
    firstName: '',
    lastName: '',
    firstNameKana: '',
    lastNameKana: '',
    mailAddress: '',
    phoneNumber1: '',
    phoneNumber2: '',
    phoneNumber3: '',
    birthYear: '',
    birthMonth: '',
    birthDay: '',
    roomNumber: '',
    buildingId: '',
    buildingName: ''
  },
  selectedProperty: null,
  propertyList: [],
  selectedBuildingId: ''
};

const emptyEntryInputForm: PlatformEntryInputForm = {
  firstName: '',
  lastName: '',
  firstNameKana: '',
  lastNameKana: '',
  mailAddress: '',
  phoneNumber1: '',
  phoneNumber2: '',
  phoneNumber3: '',
  birthYear: '',
  birthMonth: '',
  birthDay: '',
  roomNumber: '',
  buildingId: '',
  buildingName: '',
};

/** State の型 */
type PlatformEntryState = typeof platformEntryState;

/** Platform 新規会員登録 Store */
export const platformEntryStore: Module<PlatformEntryState, RootState> = {
  namespaced: true,
  state: platformEntryState,
  mutations: {
    initEntryInputForm: (state) => { Object.assign(state.entryInputForm, emptyEntryInputForm) },
    entryInputForm: (state, value: PlatformEntryInputForm) => (state.entryInputForm = value),
    selectedProperty: (state, value: Property) => (state.selectedProperty = value),
    updateFormItem: (state, param: {value: string, itemName: string}) => {
      const key: keyof PlatformEntryInputForm = param.itemName as keyof PlatformEntryInputForm;
      if(!state.entryInputForm){
        return;
      }
      state.entryInputForm[key] = param.value;
    },
    updatePropertyList: (state, value: Property[] | []) => (state.propertyList = value),
    updateBuildingId: (state, value: string) => {
      state.selectedBuildingId = value;
    }
  },
  getters: {
    entryInputForm: (state): PlatformEntryInputForm | null => state.entryInputForm,
    entryInputFormItem: (state) => (itemName: string): string | undefined => {
      const key: keyof PlatformEntryInputForm = itemName as keyof PlatformEntryInputForm;
      if(!state.entryInputForm){
        return '';
      }
      return state.entryInputForm[key];
    },
    selectedProperty: (state): Property | null => state.selectedProperty,
    propertyList: (state): Property[] => state.propertyList,
    selectedPropertyItem: (state) => (itemName: string): Building[] | string | number | Date | boolean | undefined => {
      const key: keyof Property = itemName as keyof Property;
      if(!state.selectedProperty){
        return '';
      }
      return state.selectedProperty[key];
    },
    selectedBuildingId: (state): string => state.selectedBuildingId,
    selectRoomNumberList: (state) => (id: number, buildingId: string): string[] | [] | undefined => {
      // idなければ空
      if(!id){
        return [];
      }
      const property = state.propertyList.find(e => e.id === Number(id));
      // 物件未選択でも空
      if(!property){
        return [];
      }
      const building = property.buildingInfoList?.find(e => e.buildingId === buildingId);
      // 棟リストがない場合も空
      if(!building){
        return [];
      }
      return building.roomNumberList;
    }
  }
};
