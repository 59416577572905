import { Module } from 'vuex';
import { RootState } from '../index';
import { PlatformEditInputForm } from '../../shared/classes/platform/edit-input-form';
import { Property, SearchPropertyDetail } from '../../shared/classes/spf-api/property';
import { EMansionRoomsDetail } from '@/shared/classes/external-api/e-mansion/rooms-response';

export type BuildingInfoStatus = 'both' | 'buildingOnly' | 'roomOnly' | 'nothing';
export const BUILDING_INFO_STATUS = {
  BOTH: 'both',
  BUILDING_ONLY: 'buildingOnly',
  ROOM_ONLY: 'roomOnly',
  NOTHING: 'nothing'
} as const;

/**共通コンポーネント用
 * src/shared/components/platform/input-property.vue で利用する
 */
export interface InputPropertyForm {
  properties: SearchPropertyDetail[]; //物件検索情報
  selectedProperty: SearchPropertyDetail | null; //登録物件
  inputBuildingData: {
    buildingId: string;
    buildingName: string;
    roomNumber: string;
  }; //棟情報入力情報
  /** 部屋番号一覧取得APIの返却値を物件毎にグループ化するための配列 */
  roomsData: {
    id: string;
    buildingInfoStatus: BuildingInfoStatus; // 棟情報を含んでいるか
    rooms: EMansionRoomsDetail[];
  }[];
  buildings: {
    buildingId: string;
    buildingName: string;
  }[];
  isAfterResister: boolean; //後で登録するか
  eMansionSpecialPropertyName: string | null;
  eMansionSpecialPropertyId: number | null; // e-mansion特殊物件のID
}

/**マイページ用登録物件情報 */
export interface MyPageProperty {
  propertyId: number;
  apartmentName: string;
  dispApartmentName: string;
  zipcode?: string;
  address: string;
  buildingId: string;
  roomNumber: string;
}

/**Portas新規登録画面用登録物件情 */
export interface EntryProperty extends MyPageProperty {}

/** お住まい検索欄の型定義（入力ページに戻ってきた際の検索条件再現に使用するもの） */
export interface SearchPropertyForm {
  isPerfectMatch: boolean;
  searchKey: string;
}

/** Platform 会員情報変更 State */
const platformEditState: {
  editInputForm: PlatformEditInputForm | null; // ユーザー情報
  property: SearchPropertyDetail | null; // 登録物件
  myPageProperty: MyPageProperty | null; // 選択した物件
  entryProperty: EntryProperty | null; // 選択した物件
  inputPropertyForm: InputPropertyForm | null;
  beforePath: string | null; // 遷移元パス
  searchPropertyForm: SearchPropertyForm | null; // お住まい検索欄
  isIncludesBuildingsInfo: boolean; // mcloud 物件で 棟情報を持っているか否か（e-mansion にも使えるかもしれない）
} = {
  /** Platform 会員情報変更フォーム */
  editInputForm: null,
  property: null,
  myPageProperty: null,
  entryProperty: null,
  inputPropertyForm: null,
  beforePath: null,
  searchPropertyForm: null,
  isIncludesBuildingsInfo: false
};

/** State の型 */
type PlatformEditState = typeof platformEditState;

/** Platform 会員情報変更 Store */
export const platformEditStore: Module<PlatformEditState, RootState> = {
  namespaced: true,
  state: platformEditState,
  mutations: {
    editInputForm: (state, value: PlatformEditInputForm) => (state.editInputForm = value),
    property: (state, value: SearchPropertyDetail) => (state.property = value),
    myPageProperty: (state, value: MyPageProperty | null) => (state.myPageProperty = value),
    entryProperty: (state, value: EntryProperty) => (state.entryProperty = value),
    inputPropertyForm: (state, value: InputPropertyForm) => (state.inputPropertyForm = value),
    beforePath: (state, value: string | null) => (state.beforePath = value),
    searchPropertyForm: (state, value: SearchPropertyForm) => (state.searchPropertyForm = value),
    isIncludesBuildingsInfo: (state, value: boolean) => (state.isIncludesBuildingsInfo = value)
  },
  getters: {
    editInputForm: (state): PlatformEditInputForm | null => state.editInputForm,
    property: (state): SearchPropertyDetail | null => state.property,
    myPageProperty: (state): MyPageProperty | null => state.myPageProperty,
    entryProperty: (state): EntryProperty | null => state.entryProperty,
    inputPropertyForm: (state): InputPropertyForm | null => state.inputPropertyForm,
    beforePath: (state): string | null => state.beforePath,
    searchPropertyForm: (state): SearchPropertyForm | null => state.searchPropertyForm,
    isIncludesBuildingsInfo: (state): boolean => state.isIncludesBuildingsInfo
  }
};
