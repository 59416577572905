












import { PermissionControlService } from '@/shared/services/permission/permission-control-service';
import Vue from 'vue';

export default Vue.extend({
  name: 'permission-controled-router-link',
  props: {
    title: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: '',
    },
    functionId: {
      type: String,
      default: undefined,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isEnabled: false,
  }),
  async mounted(): Promise<void> {
    // メニューリンクは"R"以上のパーミッションがあれば利用可
    this.$data.isEnabled = await PermissionControlService.isReadable(this.functionId);
  },
});
