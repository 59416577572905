








































































































































































































































































































































































































































































































































































import Vue from 'vue';
import { PlatformEntryInputForm } from '@/shared/classes/platform/entry-input-form';
import { Building, Property, SearchPropertyDetail, SearchResultOver } from '@/shared/classes/spf-api/property';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { EMansionSharedExternalApiService } from '@/shared/services/external-api/e-mansion-shared-external-api-service';
import { EMansionRoomsDetail, EMansionRoomsResponseResult } from '@/shared/classes/external-api/e-mansion/rooms-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { BuildingInfoStatus, BUILDING_INFO_STATUS, InputPropertyForm, SearchPropertyForm } from '@/store/platform/platform-edit-store';
import { AuthService } from '@/shared/services/auth/auth-service';
import { PROPERTY_SEARCH_TYPE, TransitionSourceServiceType, TRANSITION_SOURCE_SERVICE, UaTypeValue, UA_TYPE, ISP_TYPE_REGEXP, OEM_TYPE } from '@/shared/const/service-type';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';

/** dataオブジェクトの型  */
type DataType = {
  form: PlatformEntryInputForm; // 会員情報入力フォーム
  /**フラグ系 */
  isPerfectMatch: boolean; // 完全一致 or 部分一致
  isAfterResister: boolean; // 後で登録するか
  isAutoLink: boolean; // 自動紐づけフラグ
  isSearchedProperties: boolean; // 物件検索済み
  isExistingMember: boolean; // 既存会員の検索させない場合のフラグ
  isOverPropertiesCount: boolean; // 物件検索結果数が11件以上
  isNothingPropertiesCount: boolean; // 物件検索結果が0件
  searchKey: string; // 名称検索フォーム
  errorMessages: string[];
  errorMessageTitle: string;
  properties: SearchPropertyDetail[]; // 物件検索APIレスポンス
  selectedProperty: SearchPropertyDetail | null; // 選択した物件
  buildings: {
    buildingId: string;
    buildingName: string;
  }[]; // 部屋番号一覧取得APIから取得した棟一覧情報(リアクティブな挙動を保証する事が難しい為、専用の受け皿を用意)
  inputBuildingData: {
    buildingId: string;
    buildingName: string;
    roomNumber: string;
  }; // 棟情報入力情報
  eMansionBuildingName: string;
  mcloudBuildingName: string;
  /** 部屋番号一覧取得APIの返却値を物件毎にグループ化するための配列
   * idは物件テーブルの物件IDと一致させる
   */
  roomsData: {
    id: string;
    buildingInfoStatus: BuildingInfoStatus; // 棟情報を含んでいるか
    rooms: EMansionRoomsDetail[];
  }[];
  eMansionSpecialPropertyName: string | null;
  eMansionSpecialPropertyId: number | null; //e-mansionの特殊物件対応時に格納する物件ID
  propertyResistRadioValue: 'now' | 'after'; // ラジオボタン初期値切り替え用
  propertyTransitionRadioValue: 'select' | ''; // ラジオボタン切り替え用
  transitionSourceService: TransitionSourceServiceType | null;
  transitionSourceServiceId: string | null;
  /**表示用棟名情報 */
  eMansionBuildingNames: {
    id: number; // Portas物件ID
    buildingNames: string[]; // 部屋番号検索APIから取得された棟名
  }[];
  isSearching: boolean // 検索して結果が表示されるまでの間スピナー表示する用
};

/** エラーメッセージ表示エリアコンポーネント */
export default Vue.extend({
  name: 'input-property',
  components: {
    LoadingComponent
  },
  // 親コンポーネントから受け取るデータ
  props: {
    property: { default: null, type: Property, required: false }, // 新規登録で既に紐付いている物件がある場合ここにデータが入る
    isReEnter: { default: false, type: Boolean, required: false }, // 新規登録で物件の再検索を選択した場合
    isMyPage: { default: false, type: Boolean, required: false }, // マイページでの更新処理の場合
    isEntry: { default: false, type: Boolean, required: false } // 新規登録時の場合
  },
  data(): DataType {
    return {
      form: {
        roomNumber: '',
        buildingId: '',
        buildingName: ''
      },
      searchKey: '',
      errorMessages: [],
      errorMessageTitle: '入力項目にエラーがあります。ご確認をお願いいたします。',
      isPerfectMatch: false, /** true: 完全一致検索、false: 部分一致検索 */
      buildings: [],
      properties: [],
      selectedProperty: null,
      inputBuildingData: {
        /**棟ID */
        buildingId: '',
        /** 棟名 */
        buildingName: '',
         /** 部屋番号 */
        roomNumber: ''
      },
      eMansionBuildingName: '',
      mcloudBuildingName: '',
      isAfterResister: true,
      isAutoLink: false,
      isSearchedProperties: false,
      isExistingMember: false,
      roomsData: [],
      eMansionSpecialPropertyName: null,
      eMansionSpecialPropertyId: null,
      propertyResistRadioValue: 'after',
      propertyTransitionRadioValue: '',
      isOverPropertiesCount: false,
      isNothingPropertiesCount: false,
      transitionSourceService: null,
      transitionSourceServiceId: null,
      /**表示用棟名情報 */
      eMansionBuildingNames: [],
      isSearching: false
    };
  },
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    try {

      // 検索はさせない
      this.isAutoLink = true;
      this.$data.isExistingMember = true;

      // 遷移元サービスID情報取得
      const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];

      // Auth0情報がある場合
      if (await this.isExistingMembers()) {
        // 検索欄は出させない
        this.$data.isExistingMember = true;

        this.transitionSourceService = memberStatus.transitionSourceService;
        this.transitionSourceServiceId = memberStatus.transitionSourceServiceId;

        //  else if(await AuthService.getMcloudMemberId() && await AuthService.getMcloudPropertyId()){
        //   this.$data.transitionSourceService = TRANSITION_SOURCE_SERVICE.MCLOUD;
        //   this.$data.transitionSourceServiceId = await AuthService.getMcloudPropertyId();

        // }

        this.isAfterResister = false;
        this.updateInputIsAfterRegister(false);
        this.isAutoLink = true;
        await this.searchPropertyWithTransition(Number(this.$data.transitionSourceService), this.$data.transitionSourceServiceId);
        this.$emit('isLoading', false);
        return;
      }
      /** 確認画面から戻った際にstoreに物件情報が残っていれば表示する */
      const searchPropertyForm: SearchPropertyForm | null = this.$store.getters['platformEditStore/searchPropertyForm'];
      if (searchPropertyForm) {
        this.isPerfectMatch = searchPropertyForm.isPerfectMatch;
        this.searchKey = searchPropertyForm.searchKey;
      }
      const inputProperty: InputPropertyForm | null = this.$store.getters['platformEditStore/inputPropertyForm'];
      if (inputProperty) {
        this.properties = inputProperty.properties;
        this.selectedProperty = inputProperty.selectedProperty;
        this.inputBuildingData = inputProperty.inputBuildingData;
        this.roomsData = inputProperty.roomsData;
        this.isAfterResister = inputProperty.isAfterResister;
        this.eMansionBuildingName = inputProperty.inputBuildingData.buildingName;
        this.mcloudBuildingName = inputProperty.inputBuildingData.buildingName;
        /** 物件を選択していた場合は再表示 */
        if (this.selectedProperty) {
          await this.setProperty(this.selectedProperty);
          this.eMansionSpecialPropertyName = inputProperty.eMansionSpecialPropertyName;
          this.eMansionSpecialPropertyId = inputProperty.eMansionSpecialPropertyId;
          this.propertyResistRadioValue = 'now';
        }
      } else {
        if (this.$route.path === '/platform/entry/input') {
          this.isAfterResister = false;
          this.propertyResistRadioValue = 'now';
        }
      }

      // 他サービスから物件選択状態でSPFへ来た場合
      if (memberStatus.transitionSourceServiceId && memberStatus.transitionSourceService) {
        this.propertyResistRadioValue = 'now';
        this.transitionSourceService = memberStatus.transitionSourceService;
        this.transitionSourceServiceId = memberStatus.transitionSourceServiceId;
      }

      // 物件自動検索
      if (this.transitionSourceService && this.transitionSourceServiceId) {
        await this.searchPropertyWithTransition(Number(this.transitionSourceService), this.transitionSourceServiceId);
      }

      this.$emit('isLoading', false);
    } catch (error) {
      this.initInputPropertyForm();
      throw error;
    }
  },
  methods: {
    /** 物件検索実行 */
    async searchProperty() {
      // 検索中は何もしない
      if (this.isSearching) {
        return;
      }

      this.isSearching = true;

      this.propertyTransitionRadioValue ='';
      if (!this.searchKey.trim) {
        this.isSearching = false;
        return;
      }
      // e-mansion 特殊物件のデータを空にする
      this.eMansionSpecialPropertyName = null;
      this.eMansionSpecialPropertyId = null;
      /** inputBuildingData（棟名と部屋番号）と SelectedProperty（選択物件）をリセットする */
      this.updateInputSelectedProperty(null);
      this.initInputBuildingData();
      try {
        this.isOverPropertiesCount = false;
        this.isNothingPropertiesCount = false;
        this.errorMessages.splice(0);
        // 物件検索API実行
        const propertyList: SearchPropertyDetail[] | SearchResultOver = await SpfApiService.searchProperties(1, this.searchKey, this.isPerfectMatch);
        this.isSearchedProperties = true;

        // 検索結果が11件以上の場合（SearchResultOver 型が返却され errorMessage プロパティを有する場合）
        if ('errorMessage' in propertyList) {
          this.isOverPropertiesCount = true;
          this.updateInputProperties([]);
          this.errorMessages.push(propertyList.errorMessage);
          this.isSearching = false;
          return;
        }

        // 検索結果が0件の場合
        if (propertyList.length === 0) {
          this.isNothingPropertiesCount = true;
          this.updateInputProperties([]);
          this.isSearching = false;
          return;
        }

        // ispTypeが想定する値のときのみ検索結果として表示するための処理
        const filteredPropertyList: SearchPropertyDetail[] = propertyList.filter((value) => {
          if (!value.ispType) return true;
          return ISP_TYPE_REGEXP.ALL.test(value.ispType) === true;
        });

        /** 検索結果を this.properties 及び platformEditState に格納 */
        this.updateInputProperties(filteredPropertyList);

        /**パフォーマンスの観点から、物件検索時点で棟情報・部屋情報を確保しておく
         * ※要件が変更されたためここで一括取得する意味は薄くなってしまった（リファクタリングの際に修正）
         */
        /**同期的に実行するため、強引にmap&asyncを使用している */
        this.eMansionBuildingNames.splice(0);
        // e-mansionの部屋番号検索APIが400を返してきた物件を除く用
        let eMansionFindRoomsResult400: number[] = [];
        const roomPromises = this.properties.map(async (property) => {
          /**e-mansion物件の場合 */
          if (this.isEmansionProperty(property.uaType, property.ispType)) {
            try {

              const rooms: EMansionRoomsResponseResult | EMansionSharedErrorResponse = await EMansionSharedExternalApiService.findRooms(UA_TYPE.E_MANSION, property.apartmentId);
              if (rooms instanceof EMansionRoomsResponseResult) {
                this.roomsData.push({
                  id: String(property.id),
                  buildingInfoStatus: this.checkIncludesBuildingInfo(rooms.rooms),
                  rooms: rooms.rooms
                });
              } else {
                this.initInputPropertyForm();
                eMansionFindRoomsResult400.push(property.id);
              }

            } catch(error: any) {
              const status: number = error.response?.status;
              if(status === 400){
                this.initInputPropertyForm();
                eMansionFindRoomsResult400.push(property.id);
              }
            }

          }
          return property;
        });
        if (roomPromises.length) {
          await Promise.all(roomPromises);
        }

        // e-mansionの部屋番号検索APIが400を返してきた物件を除く
        const excludingWithoutRoomNumbers = this.properties.filter((property: SearchPropertyDetail) => {
          if (eMansionFindRoomsResult400.indexOf(property.id) === -1) return true;
        });

        // 最終的な検索結果が0件の場合
        if (excludingWithoutRoomNumbers.length === 0) {
          this.isNothingPropertiesCount = true;
          this.updateInputProperties([]);
          this.isSearching = false;
          return;
        }

        // 物件を this.properties 及び platformEditState に格納
        this.updateInputProperties(excludingWithoutRoomNumbers);

        // 取得した部屋情報（及び附随データ）を platformEditState に格納
        this.updateInputRoomsData(this.roomsData);

        // 検索情報を platformEditState に保存する（入力ページに戻ってきた際の検索条件再現に使用）
        const searchPropertyForm = {
          isPerfectMatch: this.isPerfectMatch,
          searchKey: this.searchKey
        };
        this.$store.commit('platformEditStore/searchPropertyForm', searchPropertyForm);
        this.isSearching = false;
      } catch(error: any) {
        this.initInputPropertyForm();
        const status: number = error.response?.status;
        // 検索エラー
        if (status === 400) {
          this.errorMessages.push('検索結果が多いため表示できません。');
          window.scrollTo(0, 0);
          this.isSearching = false;
          return;
        }
        throw error;
      } finally {
        this.isSearching = false;
      }
    },
    /** 物件検索実行（物件選択済みで遷移してきた場合） */
    async searchPropertyWithTransition(transitionSourceService: number, transitionSourceServiceId: string) {
      try {
        if (this.selectedProperty || !this.transitionSourceService || !this.transitionSourceServiceId) return;
        this.isOverPropertiesCount = false;
        this.isNothingPropertiesCount = false;
        this.errorMessages.splice(0);
        // 物件検索API実行（Mye物件ID または UCOM建物ID で検索をするので1件のみ取得が想定）
        // transitionSourceServiceに 1: e-mansion, 2: ucom, 3: Five.A が入っている
        // searchTypeは 3: emansion, 4: ucom
        const propertyList: SearchPropertyDetail[] | SearchResultOver = await SpfApiService.searchProperties(
          this.convertSourceServiceToSearchType(transitionSourceService, transitionSourceServiceId),
          transitionSourceServiceId,
          false
        );

        this.isSearchedProperties = true;

        // 検索結果が11件以上の場合（SearchResultOver 型が返却され errorMessage プロパティを有する場合）
        if ('errorMessage' in propertyList) {
          this.isOverPropertiesCount = true;
          this.updateInputProperties([]);
          this.errorMessages.push(propertyList.errorMessage);
          return;
        }

        // 既存会員かつ検索０件はNG
        if(propertyList.length === 0 && this.$data.isExistingMember){
          throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.INPUT_PROPERTY_NO_RESULT);
        }

        // 検索結果が0件の場合
        if (propertyList.length === 0) {
          this.isNothingPropertiesCount = true;
          this.updateInputProperties([]);
          return;
        }

        // ispTypeが想定する値のときのみ検索結果として表示するための処理
        const filteredPropertyList = propertyList.filter((value: SearchPropertyDetail) => {
          if (!value.ispType) return true;
          return ISP_TYPE_REGEXP.ALL.test(value.ispType) === true;
        });

        /** 検索結果を this.properties 及び platformEditState に格納 */
        this.updateInputProperties(filteredPropertyList);

        /**パフォーマンスの観点から、物件検索時点で部屋情報を確保しておく
         * （e-mansion, UCOM両方について取得）
         */
        /** e-mansion 物件を選択取得 */
        const apartments = this.properties.filter((property) => {
          return property.uaType === UA_TYPE.E_MANSION;
        });
        this.eMansionBuildingNames.splice(0);
        // e-mansionの部屋番号検索APIが400を返してきた物件を除く用
        let eMansionFindRoomsResult400: number[] = [];
        const roomPromises: Promise<SearchPropertyDetail>[] = [];
        if (apartments.length) {
          /**Promise型を解決するため、強引にmapを使用している */
          const promises = apartments.map(async (property) => {
          /**e-mansion物件の場合 */
          if (this.isEmansionProperty(property.uaType, property.ispType)) {
            try {
              const rooms: EMansionRoomsResponseResult | EMansionSharedErrorResponse = await EMansionSharedExternalApiService.findRooms(UA_TYPE.E_MANSION, property.apartmentId);
              if (rooms instanceof EMansionRoomsResponseResult) {
                this.roomsData.push({
                  id: String(property.id),
                  buildingInfoStatus: this.checkIncludesBuildingInfo(rooms.rooms),
                  rooms: rooms.rooms
                });
              } else {
                this.initInputPropertyForm();
                eMansionFindRoomsResult400.push(property.id);
              }

            } catch(error: any) {
              const status: number = error.response?.status;
              if(status === 400){
                this.initInputPropertyForm();
                eMansionFindRoomsResult400.push(property.id);
              }
            }
          }
            return property;
          });
          roomPromises.push(...promises);
        }
        if (roomPromises.length) {
          await Promise.all(roomPromises);
        }

        //e-mansionの部屋番号検索APIが400を返してきた物件を除く
        const excludingWithoutRoomNumbers = this.properties.filter((property: SearchPropertyDetail) => {
          if (eMansionFindRoomsResult400.indexOf(property.id) === -1) return true;
        });

        // 最終的な検索結果が0件の場合
        if (excludingWithoutRoomNumbers.length === 0) {
          this.isNothingPropertiesCount = true;
          this.updateInputProperties([]);
          this.isSearching = false;
          return;
        }

        // 物件を this.properties 及び platformEditState に格納
        this.updateInputProperties(excludingWithoutRoomNumbers);

        // 取得した部屋情報（及び附随データ）を platformEditState に格納
        this.updateInputRoomsData(this.roomsData);

        // ID一意検索なので1件以上の場合はない認識だが、1件以上ある場合は選んでもらう
        if(propertyList.length > 1){
          this.isAutoLink = false;
          this.isAfterResister = false;
          this.updateInputIsAfterRegister(this.isAfterResister);
          this.propertyResistRadioValue = 'now';
          return;
        }

        // 1件で検索できたら勝手に選ぶ 部屋番号とかは自動的にはいかないので選ぶか入力してもらう
        await this.clickedProperty(propertyList[0]);
        this.searchKey = propertyList[0].apartmentName;
        this.propertyTransitionRadioValue ='select';

      } catch(error: any) {
        this.initInputPropertyForm();
        throw error;
      }
    },
    async clickedProperty(property: SearchPropertyDetail) {
      /** inputBuildingData（棟名と部屋番号）と SelectedProperty（選択物件）をリセットする */
      this.initInputBuildingData();
      await this.setProperty(property);
    },
    /** リストから選択した物件をセットしプルダウン用の棟情報を取得する */
    async setProperty(property: SearchPropertyDetail) {
      // e-mansion 特殊物件のデータを空にする
      this.eMansionSpecialPropertyName = null;
      this.eMansionSpecialPropertyId = null;
      this.buildings.splice(0);
      this.updateInputSelectedProperty(property);
      if (!this.selectedProperty) { return; }
      // e-mansion・Mcloud対象物件の場合、プルダウン用に棟情報を取得する
      if (this.isEmansionProperty(this.selectedProperty.uaType, this.selectedProperty.ispType)) {
        const building = await this.fetchBuildings(this.selectedProperty?.apartmentId);
        if (building.length) {
          // 棟情報を持っているか否かは setBuildingNames メソッドで保存
          this.setBuildingNames(building);
        } else {
          // 棟情報を持っていないことを Store に保存
          this.$store.commit('platformEditStore/isIncludesBuildingsInfo', false);
        }
      } else if (this.isDisplayOnlyMcloud(this.selectedProperty.uaType, this.selectedProperty.ispType)) {
        const building = await this.fetchBuildings(this.selectedProperty?.apartmentId);
        if (building.length) {
          // 棟情報を持っているか否かは setBuildingNames メソッドで保存
          this.setBuildingNames(building);
        } else {
          // 棟情報を持っていないことを Store に保存
          this.$store.commit('platformEditStore/isIncludesBuildingsInfo', false);
        }
      }
    },
    //棟名と部屋番号が入力された時点で物件IDと表示するマンション名を動的に変更する（e-mansion または Mcloud の場合）
    async changeMansionData() {
      try {
        /**入力値の保存 */
        this.updateInputBuildingData(this.inputBuildingData);
        const uaType = this.selectedProperty?.uaType;
        const ispType = this.selectedProperty?.ispType;
        // Mcloud物件の場合
        if (this.isDisplayOnlyMcloud(uaType, ispType)) {
          /**部屋番号未入力の場合はreturn */
          if (!this.inputBuildingData.roomNumber) { return; }
          const building = this.buildings.find((building) => {
            return building.buildingName === this.mcloudBuildingName;
          });
          if (!building) { return; }
          this.inputBuildingData.buildingId = building.buildingId;
          this.inputBuildingData.buildingName = building.buildingName;
          this.updateInputBuildingData(this.inputBuildingData);

        /**e-mansion物件の場合 */
        } else if (this.isEmansionProperty(uaType, ispType)) {
          /** 部屋番号未入力の場合はreturn */
          if (!this.inputBuildingData.roomNumber) return;

          // 選択した棟名に基づき棟情報データ（内部APIで取得した棟情報データ）を取得
          const building = this.buildings.find((building) => {
            return building.buildingName === this.eMansionBuildingName;
          });
          this.inputBuildingData.buildingId = building?.buildingId ?? ''; // この情報取得のためだけに const building が使われる
          this.inputBuildingData.buildingName = building?.buildingName ?? ''; // イコール this.eMansionBuildingName
          this.updateInputBuildingData(this.inputBuildingData);

          /** 以下 e-mansion特殊物件対応のための処理 */
          /** e-mansion特殊物件データを初期化する */
          await this.updateEmansionSpecialProperty(null);
          /** e-mansion特殊物件名を e-mansion外部APIを使用して取り直す場合は、上記1行に代えて以下の1行を使用する */
          // await this.updateEmansionSpecialPropertyByEMansionExternalApi(null, null);

          /** 選択済み物件の e-mansion部屋番号一覧API のレスポンスを再取得（Portas物件ID一致で抽出） */
          const eMansionApiBuilding = this.roomsData.find((buildingDataWithinRooms) => {
            return buildingDataWithinRooms.id === String(this.selectedProperty?.id);
          });
          if (!eMansionApiBuilding) return; // e-mansion部屋番号一覧APIで取得できなかった場合は return（※なければ特殊物件の判定はできないということ）

          // 棟情報の有無で場合分け
          if (building) {
            /** 棟と部屋番号情報を持っている場合、棟・部屋番号との一致を確認
             * これも e-mansion特殊物件判定のためだけの作業（部屋番号チェックは別途バリデーション部分で実行される）
             */
            if (eMansionApiBuilding.buildingInfoStatus === BUILDING_INFO_STATUS.BOTH) {
              /**棟名未入力の場合はreturn */
              if (!this.inputBuildingData.buildingName) return;
              /**入力された棟・部屋番号に一致する "部屋番号一覧取得API" のレコードの物件IDと、選択した物件IDを比較 */
              const targetRoom: EMansionRoomsDetail | undefined = eMansionApiBuilding.rooms.find((room) => {
                return (
                  room.building_name === this.inputBuildingData.buildingName &&
                  room.room_number === this.inputBuildingData.roomNumber
                );
              });
              /**物件IDが異なっていた場合（e-mansion 特殊物件である場合） */
              if (targetRoom && targetRoom.property_id !== this.selectedProperty?.apartmentId) {
                /** 物件基本情報取得API で取得した物件の mye物件ID で 特殊物件の Portas 物件 ID を更新し、表示名を書き換える */
                await this.updateEmansionSpecialProperty(targetRoom.property_id);

                /** e-mansion特殊物件名を e-mansion外部APIを使用して取り直す場合は、上記1行に代えて以下の13行を復活させる */
                /** 物件基本情報取得API で取得した物件の mye物件ID で 特殊物件の Portas 物件 ID を更新し、表示名を書き換える */
                // /**物件基本情報取得API実行 */
                // const anotherProperty: EMansionProperty | EMansionSharedErrorResponse = await EMansionSharedExternalApiService.findPropertyById({
                //   propertyId: targetRoom.property_id,
                //   uaType: UA_TYPE.E_MANSION
                // });
                // if (anotherProperty instanceof EMansionSharedErrorResponse) {
                //   this.$router.push('/error');
                //   return;
                // } else {
                //   /**物件基本情報取得API で取得した物件の mye物件ID で 特殊物件の Portas 物件 ID を更新し、表示名を書き換える */
                //   await this.updateEmansionSpecialPropertyByEMansionExternalApi(targetRoom.property_id, anotherProperty.em_ap_name);
                // }
              }
              return;

            /**棟情報が含まれる場合（部屋情報無し） */
            } else if (eMansionApiBuilding.buildingInfoStatus === BUILDING_INFO_STATUS.BUILDING_ONLY) {
              /**棟名未入力の場合はreturn */
              if (!this.inputBuildingData.buildingName) return;
              /**入力された棟・部屋番号に一致する "部屋番号一覧取得API" のレコードの物件IDと、選択した物件IDを比較 */
              const targetRoom = eMansionApiBuilding.rooms.find((room) => {
                return (
                  room.building_name === this.inputBuildingData.buildingName
                );
              });
              /**物件IDが異なっていた場合（e-mansion 特殊物件である場合） */
              if (targetRoom && targetRoom.property_id !== this.selectedProperty?.apartmentId) {
                /** 物件基本情報取得API で取得した物件の mye物件ID で 特殊物件の Portas 物件 ID を更新し、表示名を書き換える */
                await this.updateEmansionSpecialProperty(targetRoom.property_id);

                /** e-mansion特殊物件名を e-mansion外部APIを使用して取り直す場合は、上記1行に代えて以下の13行を復活させる */
                /** 物件基本情報取得API で取得した物件の mye物件ID で 特殊物件の Portas 物件 ID を更新し、表示名を書き換える */
                // /**物件基本情報取得API実行 */
                // const anotherProperty = await EMansionSharedExternalApiService.findPropertyById({
                //   propertyId: targetRoom.property_id,
                //   uaType: UA_TYPE.E_MANSION
                // });
                // if (anotherProperty instanceof EMansionSharedErrorResponse) {
                //   this.$router.push('/error');
                //   return;
                // } else {
                //   /**物件基本情報取得API で取得した物件の mye物件ID で SelectedProperty を更新し、表示名を書き換える */
                //   await this.updateEmansionSpecialPropertyByEMansionExternalApi(targetRoom.property_id, anotherProperty.em_ap_name);
                // }
              }
              return;
            }
          // 棟情報がない場合
          } else {
            /**棟ID:"0000"と部屋情報が含まれる場合 */
            if (eMansionApiBuilding.buildingInfoStatus === BUILDING_INFO_STATUS.ROOM_ONLY) {
              /**入力された部屋番号に一致する "部屋番号一覧取得API" のレコードの物件IDと、選択した物件IDを比較 */
              const targetRoom = eMansionApiBuilding.rooms.find((room) => {
                return (
                  room.building_id === '0000' &&
                  room.room_number === this.inputBuildingData.roomNumber
                );
              });
              /**物件IDが異なっていた場合 */
              if (targetRoom && targetRoom.property_id !== this.selectedProperty?.apartmentId) {
                /** 物件基本情報取得API で取得した物件の mye物件ID で 特殊物件の Portas 物件 ID を更新し、表示名を書き換える */
                await this.updateEmansionSpecialProperty(targetRoom.property_id);

                /** e-mansion特殊物件名を e-mansion外部APIを使用して取り直す場合は、上記1行に代えて以下の13行を復活させる */
                // /**物件基本情報取得API実行 */
                // const anotherProperty = await EMansionSharedExternalApiService.findPropertyById({
                //   propertyId: targetRoom.property_id,
                //   uaType: UA_TYPE.E_MANSION
                // });
                // if (anotherProperty instanceof EMansionSharedErrorResponse) {
                //   this.initInputPropertyForm();
                //   this.$router.push('/error');
                //   return;
                // } else {
                //   /**物件基本情報取得API で取得した物件の mye物件ID で SelectedProperty を更新し、表示名を書き換える */
                //   await this.updateEmansionSpecialPropertyByEMansionExternalApi(targetRoom.property_id, anotherProperty.em_ap_name);
                // }
              }
            }
          }
        }
      } catch (error) {
        this.initInputPropertyForm();
        throw error;
      }
    },
    /** 後で登録を選択した際、設定中の物件情報がリセットされる */
    clearProperty() {
      this.updateInputIsAfterRegister(true);
      this.updateInputSelectedProperty(null);
    },
    checkIncludesBuildingInfo(rooms: EMansionRoomsDetail[]) {
      let [
        isExistBuildingInfo,
        isExistRoomInfo
      ] = [false, false];
      rooms.forEach((room) => {
        if (room.building_id !== '0000' && room.building_name) {
          isExistBuildingInfo = true;
        }
        if (room.room_number) {
          isExistRoomInfo = true;
        }
      });
      /**レスポンスに棟と部屋情報が含まれる場合 */
      if (isExistBuildingInfo && isExistRoomInfo) {
        return BUILDING_INFO_STATUS.BOTH;
      }
      /**レスポンスに棟情報が含まれる場合（部屋情報無し） */
      if (isExistBuildingInfo && !isExistRoomInfo) {
        return BUILDING_INFO_STATUS.BUILDING_ONLY;
      }
      /**レスポンスに棟ID:"0000"と部屋情報が含まれる場合 */
      if (!isExistBuildingInfo && isExistRoomInfo) {
        return BUILDING_INFO_STATUS.ROOM_ONLY;
      }
      /**レスポンスが空の場合 */
      return BUILDING_INFO_STATUS.NOTHING;
    },
    isExistBuildings(id: number) {
      const room = this.roomsData.find((room) => {
        return room.id === String(id);
      });
      return (
        room?.buildingInfoStatus === BUILDING_INFO_STATUS.BOTH ||
        room?.buildingInfoStatus === BUILDING_INFO_STATUS.BUILDING_ONLY
      );
    },
    setEmansionDisplayBuildingNames(id: number, rooms: EMansionRoomsDetail[]): void {
      const displayBuildingNames: string[] = [];
      /**棟ID重複削除 */
      const buildingIds: string[] = [
        ...new Set(rooms.map((room) => room.building_id))
      ];
      /**棟名が無いものは弾いて取得する */
      buildingIds.forEach((building_id) => {
        if (building_id !== '0000') {
          const resultRoom = rooms.find((room) => {
            return room.building_id === building_id;
          });
          if (resultRoom && resultRoom.building_name) {
            displayBuildingNames.push(resultRoom.building_name);
          }
        }
      });
      this.eMansionBuildingNames.push({
        id: id,
        buildingNames: displayBuildingNames
      });
    },
    /**物件検索結果のうち、e-mansion物件の場合に表示する棟名 */
    getDisplayBuildingNames(id: number): string[] {
      const building = this.eMansionBuildingNames.find((building) => {
        return building.id === id;
      });
      return building?.buildingNames ?? [];
    },
    /**棟名選択ボックス用の棟名一覧をセット */
    setBuildingNames(buildingData: Building[]): void {
      /**棟ID重複削除 */
      const buildingIds = [...new Set(buildingData.map((data) => data.buildingId))];
      this.buildings = []; // データを初期化
      /**棟名が無いものは弾いて取得する */
      buildingIds.forEach((id) => {
        if (typeof id === 'string' && id !== '0000') {
          const building = buildingData.find((data) => {
            return data.buildingId === id;
          });
          if (building && building.buildingName) {
            this.buildings.push({
              buildingId: id,
              buildingName: building.buildingName
            });
          }
        }
      });
      // 棟情報を持っているか否かを Store に保存
      const isIncludesBuildingsInfo: boolean = this.buildings.length > 0;
      this.$store.commit('platformEditStore/isIncludesBuildingsInfo', isIncludesBuildingsInfo);
    },
    /** Store の物件検索情報を更新 */
    updateInputProperties(value: SearchPropertyDetail[]) {
      this.properties.splice(0);
      this.properties.push(...value);
      const object = this.getInputPropertyform();
      object.properties = value;
      this.$store.commit('platformEditStore/inputPropertyForm', object);
    },
    updateInputSelectedProperty(value: SearchPropertyDetail | null) {
      const object = this.getInputPropertyform();
      object.selectedProperty = value;
      this.$store.commit('platformEditStore/inputPropertyForm', object);
      this.selectedProperty = value;
    },
    updateInputBuildingData(value: {
      buildingId: string,
      buildingName: string,
      roomNumber: string
    }) {
      this.inputBuildingData = value;
      const object = this.getInputPropertyform();
      object.inputBuildingData = value;
      this.$store.commit('platformEditStore/inputPropertyForm', object);
    },
    updateInputRoomsData(value: {
      id: string;
      buildingInfoStatus: BuildingInfoStatus;
      rooms: EMansionRoomsDetail[];
    }[]) {
      const object = this.getInputPropertyform();
      object.roomsData = value;
      this.$store.commit('platformEditStore/inputPropertyForm', object);
    },
    updateInputIsAfterRegister(value: boolean) {
      this.isAfterResister = value;
      const object = this.getInputPropertyform();
      object.isAfterResister = value;
      this.$store.commit('platformEditStore/inputPropertyForm', object);
      this.$emit('changeIsAfterRegister', value);
    },
    /** e-mansion 特殊物件情報を更新（Portas内部APIで物件名を取り直す場合）
     * inputPropertyForm.selectedProperty は更新しない（他のデータへの影響が大きいため）
     */
    async updateEmansionSpecialProperty(apartmentId: string | null) {
      // Mye物件ID (apartmentId) を使用して Portas 物件情報 を取得
      let alternativePortasPropertyId: number | null = null; // Portas 物件 ID
      let alternativePortasPropertyDispApartmentName: string | null = null; // Portas 表示用物件名
      if (apartmentId) {
        const alternativeProperty: SearchPropertyDetail[] | SearchResultOver = await SpfApiService.searchProperties(PROPERTY_SEARCH_TYPE.E_MANSION, apartmentId, false);
        // 戻り値が SearchPropertyDetail[] 型であること && 取得した Portas 物件情報が1件であること（後者の要件は外してもよいかもしれない／searchPropertyWithTransition では許容している）
        if (!('errorMessage' in alternativeProperty) && alternativeProperty.length === 1) {
          alternativePortasPropertyId = alternativeProperty[0].id;
          alternativePortasPropertyDispApartmentName = alternativeProperty[0].dispApartmentName;
        }
      }
      this.eMansionSpecialPropertyId = alternativePortasPropertyId;
      this.eMansionSpecialPropertyName = alternativePortasPropertyDispApartmentName;
      const object = this.getInputPropertyform();
      object.eMansionSpecialPropertyId = alternativePortasPropertyId;
      object.eMansionSpecialPropertyName = alternativePortasPropertyDispApartmentName;
      this.$store.commit('platformEditStore/inputPropertyForm', object);
    },
    /** e-mansion特殊物件名を e-mansion外部APIを使用して取り直す場合は、上記に代えて以下のメソッドを復活させる */
    // /** e-mansion 特殊物件情報を更新（e-mansion外部APIで物件名を取り直す場合）
    //  * inputPropertyForm.selectedProperty は更新しない（他のデータへの影響が大きいため）
    //  */
    // async updateEmansionSpecialPropertyByEMansionExternalApi(apartmentId: string | null, propertyName: string | null) {
    //   // Mye物件ID (apartmentId) を使用して Portas 物件情報 を取得
    //   let alternativePortasPropertyId: number | null = null; // Portas 物件 ID
    //   if (apartmentId) {
    //     const alternativeProperty: SearchPropertyDetail[] | SearchResultOver = await SpfApiService.searchProperties(PROPERTY_SEARCH_TYPE.E_MANSION, apartmentId, false);
    //     // 戻り値が SearchPropertyDetail[] 型であること && 取得した Portas 物件情報が1件であること（後者の要件は外してもよいかもしれない／searchPropertyWithTransition では許容している）
    //     if (!('errorMessage' in alternativeProperty) && alternativeProperty.length === 1) {
    //       alternativePortasPropertyId = alternativeProperty[0].id;
    //     }
    //   }
    //   this.eMansionSpecialPropertyId = alternativePortasPropertyId;
    //   this.eMansionSpecialPropertyName = propertyName;
    //   const object = this.getInputPropertyform();
    //   object.eMansionSpecialPropertyId = alternativePortasPropertyId;
    //   object.eMansionSpecialPropertyName = propertyName;
    //   this.$store.commit('platformEditStore/inputPropertyForm', object);
    // },
    initInputPropertyForm() {
      this.$store.commit('platformEditStore/inputPropertyForm', null);
    },
    /** 入力した棟名・部屋番号を空にする */
    initInputBuildingData() {
      this.updateInputBuildingData({ buildingId: '', buildingName: '', roomNumber: '' });
    },
    getInputPropertyform(): InputPropertyForm {
      const object: InputPropertyForm | null = this.$store.getters['platformEditStore/inputPropertyForm'];
      if (object) {
        return object;
      } else {
        return {
          properties: [],
          selectedProperty: null,
          inputBuildingData: {
            buildingId: '',
            buildingName: '',
            roomNumber: ''
          },
          buildings: [],
          roomsData: [],
          isAfterResister: this.isAfterResister,
          eMansionSpecialPropertyId: null,
          eMansionSpecialPropertyName: null
        };
      }
    },
    /**
     * 住所に誤りがあるとき、お住まいを再検索
     */
    searchAgain() {
      this.$emit('changeIsReEnter', true);
    },
    /**e-mansion対象物件判定
     * UA種別が1かつISP契約種別が0,3,6,7以外
     */
    isEmansionProperty(
      uaType?: UaTypeValue | null,
      ispType?: string | null
    ): boolean {
      return (
        uaType === UA_TYPE.E_MANSION &&
        (
          !ispType ||
          !ISP_TYPE_REGEXP.MCLOUD.test(ispType)
        )
      )
    },
    /**UCOM物件判定はUA種別のみでOK */
    isUcomProperty(uaType?: UaTypeValue | null): boolean {
      return uaType === UA_TYPE.UCOM;
    },
    /**OEM種別は見ない*/
    isFiveAProperty(uaType?: UaTypeValue | null) {
      return (
        uaType === UA_TYPE.FIVE_A
      );
    },
    /**OEM種別のチェック */
    isFiveAOem(oemType?: string | null) {
      return (oemType === OEM_TYPE.FIVE_A)
    },
    isLnetOem(oemType?: string | null) {
      return (oemType === OEM_TYPE.L_NET)
    },
    isNAssetOem(oemType?: string | null) {
      return (oemType === OEM_TYPE.N_ASSET)
    },
    // ispTypeとuaTypeによって、表示するラベルの場合分けをする
    isDisplayOnlyMcloud(
      uaType?: UaTypeValue | null,
      ispType?: string | null
    ): boolean {
      return (
        uaType === UA_TYPE.E_MANSION &&
        (
          typeof ispType === 'string' &&
          ISP_TYPE_REGEXP.MCLOUD.test(ispType)
        )
      );
    },
    isDisplayMcloudWithUcom(
      uaType?: UaTypeValue | null,
      ispType?: string | null
    ): boolean {
      return (
        uaType === UA_TYPE.UCOM &&
        (
          typeof ispType === 'string' &&
          ISP_TYPE_REGEXP.UCOM_MCLOUD.test(ispType)
        )
      );
    },
    isDisplayMcloudWithEmansion(
      uaType?: UaTypeValue | null,
      ispType?: string | null
    ): boolean {
      return (
        uaType === UA_TYPE.E_MANSION &&
        (
          typeof ispType === 'string' &&
          ISP_TYPE_REGEXP.E_MANSION_MCLOUD.test(ispType)
        )
      );
    },
    async isExistingMembers(): Promise<boolean> {
      const hasMemberId: boolean =
        (await AuthService.getEMansionMemberId()) !== '' || (await AuthService.getUcomMemberId()) !== '' || (await AuthService.getMcloudMemberId()) !== '';
      return hasMemberId;
    },
    convertSourceServiceToSearchType(sourceService: number, sourceServiceId: string): number {
      const SOURCE_SERVICE_ID_REGEX = {
        APARTMENT_ID: /^[0-9a-zA-Z]{6}$/,
        BUILDING_ID: /^[0-9]{13}$/
      } as const;

      if (sourceService === Number(TRANSITION_SOURCE_SERVICE.E_MANSION)) {
        return PROPERTY_SEARCH_TYPE.E_MANSION;
      } else if (sourceService === Number(TRANSITION_SOURCE_SERVICE.UCOM)) {
        if (SOURCE_SERVICE_ID_REGEX.APARTMENT_ID.test(sourceServiceId)) {
          return PROPERTY_SEARCH_TYPE.E_MANSION;
        } else if (SOURCE_SERVICE_ID_REGEX.BUILDING_ID.test(sourceServiceId)) {
          return PROPERTY_SEARCH_TYPE.UCOM;
        }
      } else if (sourceService === Number(TRANSITION_SOURCE_SERVICE.FIVE_A)) {
        return PROPERTY_SEARCH_TYPE.E_MANSION;
      }
      // if (sourceService === Number(TRANSITION_SOURCE_SERVICE.MCLOUD)) {
      //   return PROPERTY_SEARCH_TYPE.MCLOUD;
      // }
      return 0;
    },
    /**物件棟検索APIコール */
    async fetchBuildings(apartmentId: string): Promise<Building[]> {
      return await SpfApiService.searchBuildings(apartmentId);
    }
  },
  computed: {
    /** 「棟名と部屋番号を選択してください」エリアの出し分け条件*/
    /** 選択した建物がe-mansion対象物件で、棟情報を持っている場合 */
    isEmansionAndIncludesBuildings() {
      if (this.isEmansionProperty(this.selectedProperty?.uaType, this.selectedProperty?.ispType) && this.buildings.length) {
        return true;
      } else {
      return false;
      }
    },
    /**選択した建物がe-mansion対象物件で、棟情報を持っていない場合
     * または、選択した建物がFive.A/Five.A（OEM）対象物件の場合
     */
    isEmansionAndNotBuildings() {
      if (this.isEmansionProperty(this.selectedProperty?.uaType, this.selectedProperty?.ispType) && !this.buildings.length) {
        return true;
      } else if (this.isFiveAProperty(this.selectedProperty?.uaType)) {
        return true;
      } else {
        return false;
      }
    },
    /**選択した建物がUCOMのみ対象物件の場合 */
    isUcomBuilding() {
      if (this.isUcomProperty(this.selectedProperty?.uaType) && !ISP_TYPE_REGEXP.UCOM_MCLOUD.test(this.selectedProperty?.ispType!)) {
        return true;
      } else {
        return false;
      }
    },
    /**選択した建物がUCOM&Mcloud対象物件の場合 */
    isUcomAndMcloudBuilding() {
      if (this.isUcomProperty(this.selectedProperty?.uaType) && ISP_TYPE_REGEXP.UCOM_MCLOUD.test(this.selectedProperty?.ispType!)) {
        return true;
      } else {
        return false;
      }
    },
    isFiveABuilding() {
      if (this.isFiveAProperty(this.selectedProperty?.uaType)) {
        return true;
      } else {
        return false;
      }
    },
    /**選択した建物がMcloud対象物件で、棟情報を持っている場合 */
    isMcloudBuildingAndIncludesBuildings() {
      // 棟名が無いものは弾いて取得しているので
      if (this.isDisplayOnlyMcloud(this.selectedProperty?.uaType, this.selectedProperty?.ispType) && this.buildings.length) {
        return true;
      } else {
        return false;
      }
    },
    /**選択した建物がMcloud対象物件で、棟情報を持っていない場合 */
    isMcloudBuildingAndNotBuildings() {
      if (this.isDisplayOnlyMcloud(this.selectedProperty?.uaType, this.selectedProperty?.ispType) && !this.buildings.length) {
        return true;
      } else {
        return false;
      }
    }
  }
});
