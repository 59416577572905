























































































































import Vue from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiMailboxCapacityAccessor } from '@/infra/accessor/spf/ucom/spf-api-mailbox-capacity-accessor';
import { FindUcomMbCapacityRequest } from '@/infra/request/spf/ucom/mailbox-capacity/find-ucom-mb-capacity-request';
import { UCOM_REGISTERED_PAYMENT } from '@/shared/const/ucom/ucom-registered-payment';
import { processDate } from '@/shared/util/func-process-date';
import { UCOM_MAIL_ADDRESS_TYPE } from '@/shared/const/ucom/ucom-mail-address-type';

export default Vue.extend({
  name: 'mailbox-capacity-input',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },
  data: () => ({
    ucomAccountMailId: '', // UCOM_アカウントメールID
    mailAddressType: '', //UCOMメールor他社メール
    accountName: '', // アカウント名
    mailAddress: '', // メールアドレス
    mailBoxCapacity: '', // メールボックス容量
    changeCapacity: '', // 追加／縮小容量プルダウン
    changeCapacityLabel: '', // 追加／縮小容量ラベル
    changeCapacityValue: '', // 追加／縮小容量バリュー
    serviceDescription: '', // サービス説明
    price: '', // 追加／縮小 単価(9.0GB 毎)
    errorMessages: [], // エラーメッセージを格納する配列
    errorMessageTitle: '恐れ入りますが、内容をもう一度ご確認ください。', // エラーメッセージ部タイトル
    isLoading: false, // ローディング
    isAgreeTerms: false, // サービス説明同意
    isInput: true, // お支払い情報登録が無い場合はfalseにする
    NOT_SELECTED_MESSAGE: '追加／縮小容量が選択されていません。',
    OTHER_COMPANY_MAIL_MESSAGE: 'UCOM光の提供するメールサービスを利用されていないため、メールボックス容量変更はご利用になれません。',
    paymentUrl: `${process.env.VUE_APP_BASE_URL}` + process.env.VUE_APP_UCOM_PAYMENT_REGISTER_URL,
  }),
  async mounted() {
    try {
      this.$data.isLoading = true;
      this.$data.errorMessages = [];
      // 前画面からの情報をStoreから取得
      this.$data.ucomAccountMailId = this.$store.getters['ucomAccountOptionStore/ucomAccountMailId'];
      // UCOM_アカウントメールID(Store)を取得出来ない場合、前画面へ戻る
      if (!this.$data.ucomAccountMailId) {
        await this.$router.push('/ucom/account-option-change/account-list');
        this.$data.isLoading = false;
        return;
      }
      this.$data.mailAddressType = this.$store.getters['ucomAccountOptionStore/mailAddressType'];
      if (this.$data.mailAddressType == UCOM_MAIL_ADDRESS_TYPE.OTHER_COMPANY.toString()) {
        this.$data.errorMessages.push(this.$data.OTHER_COMPANY_MAIL_MESSAGE);
      }
      // 画面項目データセット
      await this.setData(this.$data.ucomAccountMailId);

      // お支払方法が未登録の場合
      if (this.$data.isRegisteredPayment === UCOM_REGISTERED_PAYMENT.NOT_REGISTERED.id.toString()) {
        this.$data.isInput = false;
      }

      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/error');
      return;
    }
  },
  methods: {
    async setData(ucomAccountMailId: number): Promise<void> {
      // 確認画面から戻るボタンで遷移の場合はstoreにデータがある
      if (this.$store.getters['ucomMailboxCapacityStore/ucomAccountMailId']) {
        this.setDataByStore();
        return;
      }
      // 会員情報取得
      const member = this.$store.getters['memberStore/member'];
      // メールボックス容量情報(ユーザ、サービス)取得
      const requestDto = new FindUcomMbCapacityRequest(member.id, ucomAccountMailId);
      try {
        const mbCapacityUser = await SpfApiMailboxCapacityAccessor.findUcomMbCapacityUser(requestDto);
        const mbCapacityService = await SpfApiMailboxCapacityAccessor.findUcomMbCapacityService(requestDto);
        // 画面項目データをセット
        this.$data.accountName = mbCapacityUser.accountName;
        this.$data.mailAddress = mbCapacityUser.mailAddress;
        this.$data.mailBoxCapacity = mbCapacityUser.mailBoxCapacity;
        this.$data.changeCapacityLabel = mbCapacityService.changeCapacityLabel;
        this.$data.changeCapacityValue = mbCapacityService.changeCapacityValue;
        this.$data.price = mbCapacityService.price;
        this.$data.isRegisteredPayment = mbCapacityUser.isRegisteredPayment;
        this.$data.serviceDescription = mbCapacityService.serviceDescription;
      } catch (e) {
        await this.$router.push('/error');
        return;
      }
    },
    // storeからデータをセット
    setDataByStore(): void {
      this.$data.accountName = this.$store.getters['ucomMailboxCapacityStore/accountName'];
      this.$data.mailAddress = this.$store.getters['ucomMailboxCapacityStore/mailAddress'];
      this.$data.mailBoxCapacity = this.$store.getters['ucomMailboxCapacityStore/mailBoxCapacity'];
      this.$data.changeCapacityLabel = this.$store.getters['ucomMailboxCapacityStore/changeCapacityLabel'];
      this.$data.changeCapacityValue = this.$store.getters['ucomMailboxCapacityStore/changeCapacityValue'];
      this.$data.price = this.$store.getters['ucomMailboxCapacityStore/price'];
      this.$data.isRegisteredPayment = this.$store.getters['ucomMailboxCapacityStore/isRegisteredPayment'];
      this.$data.serviceDescription = this.$store.getters['ucomMailboxCapacityStore/serviceDescription'];
      // 追加／縮小容量 初期表示セット
      this.$data.changeCapacity = this.$store.getters['ucomMailboxCapacityStore/changeCapacityValue'];
    },
    // 次へボタンクリック
    async onNext() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      // エラーメッセージ初期化
      this.$data.errorMessages = [];

      // 追加／縮小容量の選択がない場合
      if (!this.$data.changeCapacity) {
        this.$data.errorMessages.push(this.$data.NOT_SELECTED_MESSAGE);
        // エラーメッセージを見せるために画面最上部にスクロール
        this.scrollPageTop();
        this.isLoading = false;
        return;
      }

      // メールボックス容量情報をstoreに保存
      this.saveStore();

      // 確認画面へ遷移
      await this.$router.push('/ucom/mailbox-capacity/confirm');
      this.$data.isLoading = false;
    },
    // storeへ保存
    saveStore(): void {
      this.$store.commit('ucomMailboxCapacityStore/ucomAccountMailId', this.$data.ucomAccountMailId);
      this.$store.commit('ucomMailboxCapacityStore/accountName', this.$data.accountName);
      this.$store.commit('ucomMailboxCapacityStore/mailAddress', this.$data.mailAddress);
      this.$store.commit('ucomMailboxCapacityStore/mailBoxCapacity', this.$data.mailBoxCapacity);
      this.$store.commit('ucomMailboxCapacityStore/changeCapacityLabel', this.$data.changeCapacityLabel);
      this.$store.commit('ucomMailboxCapacityStore/changeCapacityValue', this.$data.changeCapacityValue);
      this.$store.commit('ucomMailboxCapacityStore/price', this.$data.price);
      this.$store.commit('ucomMailboxCapacityStore/effectiveDate', processDate('YYYY/MM/DD'));
      this.$store.commit('ucomMailboxCapacityStore/serviceDescription', this.$data.serviceDescription);
      this.$store.commit('ucomMailboxCapacityStore/isRegisteredPayment', this.$data.isRegisteredPayment);
    },
    // ページTOPへ
    scrollPageTop() {
      window.scrollTo(0, 0);
    },
  },
  computed: {
    buttonColorSet() {
      return {
        'btn btn04 bs': !this.isAgreeTerms,
        'btn btn01 bs': this.isAgreeTerms,
      };
    },
  },
});
