import axios from 'axios';

import { VeritransCreditCardTokenRequest } from '@/shared/classes/veritrans-credit-card-token-request';
import { VeritransCreditCardTokenResponse } from '@/shared/classes/veritrans-credit-card-token-response';

export const api = axios.create({
  baseURL: process.env.VUE_APP_VERITRANS_ENDPOINT_URL
});

/** Veritrans API をコールするサービス */
export class VeritransApiService {
  /**
   * Veritrans クレジットカードトークンを取得する
   *
   * 例外は呼び出し元でハンドリングすること
   *
   * @param veritransCreditCardTokenRequest リクエスト情報
   * @return レスポンス
   */
  public static async fetchCreditCardToken(veritransCreditCardTokenRequest: VeritransCreditCardTokenRequest): Promise<VeritransCreditCardTokenResponse> {
    const response = await api.post<VeritransCreditCardTokenResponse>('/4gtoken', veritransCreditCardTokenRequest);
    return response.data;
  }
}
