































































































































import Vue from 'vue';

/** 使用するバリデーションの定義 */
import { required, maxLength } from 'vuelidate/lib/validators';
import { halfWidth } from '@/shared/util/validators';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/five-a/customer-dto';
import { Property } from '@/shared/classes/spf-api/property';
import { Member } from '@/shared/classes/spf-api/member';
import { AuthService } from '@/shared/services/auth/auth-service';
import { FiveAConnectixRoomsRequest } from '@/shared/classes/external-api/five-a/connectix-room-request';
import { FiveAConnectixPropertyRooms, FiveAConnectixPropertyRoomsResponse } from './classes/external-api/property-rooms-response';
import { FiveAConnectixErrorResponse } from './classes/external-api/connectix-error-response';
import { FiveACustomer } from '@/shared/classes/external-api/five-a/customer-response';
import { FiveASharedErrorResponse } from '@/shared/classes/external-api/five-a/shared-error-response';
import { FiveAProperty } from '@/shared/classes/external-api/five-a/property-response';
import { ISP_MEMBER_STATUS, UaTypeValue, UA_TYPE } from '@/shared/const/service-type';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { getIspMemberStatusFiveA } from '@/shared/util/func-get-isp-member-status';

/** Apply コンポーネント */
export default Vue.extend({
  name: 'five-a-connectix-apply',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    LoadingComponent
  },
  data: () => ({
    /** 建物名 */
    propertyName: '',
    /** Connectix利用金額 */
    connectixUsageFee: '',
    /** Connectix利用金額 */
    connectixUsageFeeCp: '',
    /** 画面入力項目 */
    inputData: {
      /** 部屋番号 */
      roomNumber: ''
    },
    /** エラーの発生有無 部屋番号 */
    isInvalidRoomNumber: false,
    /** 物件基本情報 */
    property: {},
    /** UCOM 物件 ID */
    propertyId: '',
    /** 部屋番号一覧 */
    propertyRooms: null as FiveAConnectixPropertyRoomsResponse | null,
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    // mounted()実行中かどうか
    isMounted: false as boolean,
    // onApply()実行中かどうか
    isExecutingOnApply: false as boolean,
    // ISP(Five.A)会員ステータス
    ispMemberStatusFiveA: ''
  }),
  /** バリデーション定義 */
  validations: {
    inputData: {
      /** 部屋番号 */
      roomNumber: {
        required,
        halfWidth,
        maxLength: maxLength(8)
      }
    }
  },
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    /** ログインしていない状態で遷移した場合は「総合トップ」画面にリダイレクトする */
    try {
      const isLoggedIn = await AuthService.isLoggedIn();
      if (!isLoggedIn) {
        this.$router.push('/');
        return;
      }
      // エラー判定の初期化
      this.isInvalidRoomNumber = false;

      // 会員情報をStoreから取得
      const member: Member = this.$store.getters['memberStore/member'];
      const fiveAMemberId = member.primaryKeyMye ?? '';

      // 物件情報をStoreから取得
      const property: Property = this.$store.getters['propertyStore/property'];
      const uaType: UaTypeValue | undefined = property.uaType;
      const propertyId: string | undefined = property.apartmentId;

      // 必要な物件情報を取得できなかった場合はエラー画面に遷移
      if (!uaType) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.PROPERTY_NO_UA_TYPE);
      }

      // 契約基本情報取得 API 用のクエリ
      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: uaType,
        property_id: propertyId
      });
      // 部屋番号
      this.inputData.roomNumber = member.roomNumber ?? '';

      const customer: FiveACustomer | FiveASharedErrorResponse = await this.$store.dispatch('fiveACommonStore/customer', {
        memberId: fiveAMemberId,
        query: findCustomerByIdQueryDto
      });
      if (customer instanceof FiveACustomer) {
        //物件IDの取得
        this.propertyId = customer.property_id;
      } else {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.PROPERTY_NO_UA_TYPE);
      }

      const params = {
        propertyId: this.propertyId,
        uaType: UA_TYPE.FIVE_A
      };

      // 物件基本情報を取得
      const propertyInfo: FiveAProperty | FiveASharedErrorResponse = await this.$store.dispatch('fiveACommonStore/property', params);
      if (propertyInfo) {
        this.property = propertyInfo;
        if (this.property instanceof FiveAProperty) {
          this.propertyName = this.property.em_ap_name;
          // Connectix利用金額
          this.connectixUsageFee = this.property.op.sdwan.fee;
          this.connectixUsageFeeCp = this.property.op.sdwan.fee_cp;
        } else {
          // 外部APIエラー
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
        }
      }
      // 部屋番号一覧をStoreから取得
      const fiveAConnectixRoomsRequest: FiveAConnectixRoomsRequest = {
        ua_type: UA_TYPE.FIVE_A
      };
      const roomParams = { propertyId: propertyId, fiveAConnectixRoomsRequest: fiveAConnectixRoomsRequest }
      const propertyRooms: FiveAConnectixPropertyRoomsResponse | FiveAConnectixErrorResponse = await this.$store.dispatch('fiveAConnectixEntryStore/propertyRooms', roomParams);

      if (propertyRooms instanceof FiveAConnectixPropertyRoomsResponse) {
        if (!propertyRooms.rooms.length) {
          this.errorMessages.push('物件の部屋番号情報が取得できませんでした。');
          window.scrollTo(0, 0);
          return;
        } else {
          this.propertyRooms = propertyRooms;
        }
      } else if (propertyRooms instanceof FiveAConnectixErrorResponse) {
        // エラーケースの場合
        // API側から返されたエラーメッセージを表示
        this.$data.errorMessages = propertyRooms.errorInfo;
        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
        return;
      } else {
        // 外部APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.ROOM_INFO);
      }
    } catch (error) {
      throw error;
    } finally {
      // 確認画面から戻って来た場合は、事前に入力されていた情報を再現する
      const inputApply = this.$store.getters['fiveAConnectixEntryStore/entryInputForm'];
      if (inputApply != null) {
        this.inputData.roomNumber = inputApply.roomNumber;
      }

      this.isMounted = true;
    }
  },
  methods: {
    async onApply() {
      if (this.isExecutingOnApply) {
        return
      }
      this.isExecutingOnApply = true;

      try {
        // エラーメッセージ格納配列初期化
        this.errorMessages = [];
        this.errorMessageTitle = `恐れ入りますが、入力内容をもう一度ご確認ください。`;

        // ISP(Five.A)側で退会申し込み中のとき、/connectix/5a/terms に遷移してエラーメッセージを表示
        if (await this.isInCancelApplication()) {
          this.$router.push('/connectix/5a/terms').catch(error => {checkRouterError(error)});
          return; 
        }

        // 部屋番号
        if (this.$v.inputData.roomNumber?.$invalid) {
          this.isInvalidRoomNumber = true;
          // 必須チェック
          if (!this.$v.inputData.roomNumber.required) {
            this.errorMessages.push(`部屋番号を入力してください。`);
          }
          // 形式チェック
          if (!this.$v.inputData.roomNumber.halfWidth) {
            this.errorMessages.push(`正しい部屋番号を入力してください。`);
          }
          // 桁数チェック
          if (!this.$v.inputData.roomNumber.maxLength) {
            this.errorMessages.push(`正しい部屋番号を入力してください。`);
          }
        }

        if (this.errorMessages.length > 0) {
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          this.isExecutingOnApply = false;
          return;
        }

        if (!this.propertyRooms) {
          // データ不整合エラー
          throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.PROPERTY_NO_ROOM);
        }

        const propertyRooms = this.propertyRooms;
        this.inputData.roomNumber = this.inputData.roomNumber.trim();
        // お申し込み可能な部屋の中に自部屋があるかチェック
        const applyRooms = propertyRooms.rooms.find(
          (propertyRoom: FiveAConnectixPropertyRooms) => propertyRoom.apply_connectix_id === '0' && propertyRoom.room_number === this.inputData.roomNumber
        );
        if (applyRooms === undefined) {
          // 入力した部屋がお申し込み可能な部屋ではなかった場合はエラー
          this.errorMessages.push(`正しい部屋番号を入力してください。`);
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          this.isExecutingOnApply = false;
          return;
        }
      } catch (error) {
        throw error;
      }

      // 入力情報をストアに保存
      const inputData = this.inputData;

      this.$store.commit('fiveAConnectixEntryStore/entryInputForm', inputData);

      if (!this.errorMessages.length) {
        this.$router.push('/connectix/5a/confirm').catch(error => {checkRouterError(error)}).finally( () => {
          this.isExecutingOnApply = false;
        });
      }
    },
    async isInCancelApplication() {
      try {
        const property: Property = this.$store.getters['propertyStore/property'];

        const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
          ua_type: property.uaType,
          property_id: property.apartmentId
        });

        const member: Member = this.$store.getters['memberStore/member'];
        const fiveAMemberId = member.primaryKeyMye ?? '';

        const fiveACustomer: FiveACustomer | FiveASharedErrorResponse = await this.$store.dispatch('fiveACommonStore/customer', {
          memberId: fiveAMemberId,
          query: findCustomerByIdQueryDto
        });

        if (fiveACustomer instanceof FiveACustomer) {

          // ISP会員ステータス取得
          const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
          this.ispMemberStatusFiveA = getIspMemberStatusFiveA(memberStatus, fiveACustomer);

          /**
           * ISP(Five.A)会員ステータスが次の2つの場合、ISP退会申し込み中であると判定する
           *
           * 1. ISP(Five.A)会員ステータス: 退会申し込み
           * 2. ISP(Five.A)会員ステータス: 退会済みログイン可能
           */
          if (this.ispMemberStatusFiveA === ISP_MEMBER_STATUS.FIVE_A.IN_CANCEL_APPLICATION
              || this.ispMemberStatusFiveA === ISP_MEMBER_STATUS.FIVE_A.CANCEL_AND_LOGIN_OK) {
            return true;
          }
        } else {
          // データ不整合エラー
          throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.E_MANSION.NO_CUSTOMER_INFO);
        }
      } catch (e) {
        throw e;
      }
      return false;
    }
  }
});
