




















































































































import Vue from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { EMansionHomepageCapacityInput } from '@/shared/classes/spf-api/homepage/model/e-mansion-homepage-capacity-input';
import { SpfApiHomepageCapacityAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-homepage-capacity-accessor';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';

export default Vue.extend({
  name: 'homepageCapacityInput',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    ftpAccount: '', // FTPアカウント
    eMansionHomepageCapacity: '', // 現在のホームページ容量
    eMansionHomepageCapacitySelected: '', // 選択したホームページ容量
    homepageCapacityListCount: 0, // ホームページ容量追加要素数
    homepageCapacityList: [], // ホームページ容量配列
    eMansionAccountId: '', // e-mansion アカウントID
    eMansionHomepageId: '', // e-mansion ホームページID
    fee: '', // 月額利用料
    jimFee: '', // 事務手数料
    maxQuota: '', // 最大容量(MB)
    tax: '', // 消費税率
    errorMessages: [], // エラーメッセージを格納する配列
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。', // エラーメッセージ部に表示するタイトル
    isChangeBtn: false, //変更ボタン活性化するか
    isSameMonthChange: false, //同月内変更有無
    isLoading: false, //ローディング
    NOT_SELECTED_MESSAGE: 'ホームページ容量が選択されていません。',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      this.$data.isLoading = true;
    
      // 前画面からの情報をStoreから取得
      if (!this.$store.getters['eMansionHomepageCapacityStore/eMansionAccountId']) {
        // e-mansionアカウントID(Store)を取得できない場合、前画面へ戻る
        await this.$router.push('/e-mansion/homepage-capacity/list');
        this.$data.isLoading = false;
        return;
      }

      // 前画面情報の取得
      this.$data.eMansionAccountId = this.$store.getters['eMansionHomepageCapacityStore/eMansionAccountId'];
      this.$data.eMansionHomepageId = this.$store.getters['eMansionHomepageCapacityStore/eMansionHomepageId'];
      this.$data.ftpAccount = this.$store.getters['eMansionHomepageCapacityStore/ftpAccount'];
      this.$data.eMansionHomepageCapacity = this.$store.getters['eMansionHomepageCapacityStore/eMansionHomepageCapacity'];
      this.$data.fee = this.$store.getters['eMansionHomepageCapacityStore/fee'];
      this.$data.jimFee = this.$store.getters['eMansionHomepageCapacityStore/jimFee'];
      this.$data.maxQuota = this.$store.getters['eMansionHomepageCapacityStore/maxQuota'];

      // 現在日付の取得
      const currentDateObject = await SpfApiServerCommonUtilAccessor.getServerTimeNow();

      // 初期表示APIよりデータを取得する
      const response = await SpfApiHomepageCapacityAccessor.getEMansionHomepageCapacityInput(this.$data.eMansionAccountId, this.$data.eMansionHomepageId, this.$data.maxQuota, currentDateObject);
      // API処理結果にエラーメッセージがある場合
      if (response.errorMessages.length != 0) {
        for (const errMsg of response.errorMessages) {
          this.$data.errorMessages.push(errMsg);
        }
      }

      // 画面項目セット
      this.$data.homepageCapacityListCount = response.hpCapacityListCount;
      this.$data.homepageCapacityList = response.hpCapacityList;

      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error');
      return;
    }
  },
  methods: {
    // 戻るボタンクリック
    async goBack() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      await this.$router.push('/e-mansion/homepage-capacity/list');
      this.isLoading = false;
    },
    // 申し込みボタンクリック
    async goEntry() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      // エラーメッセージ格納配列初期化
      this.$data.errorMessages = [];

      // ホームページ容量の選択がない場合
      if(!this.$data.eMansionHomepageCapacitySelected) {
        this.$data.errorMessages.push(this.$data.NOT_SELECTED_MESSAGE);
        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }

      // ホームページ容量情報をstoreに保存する
      this.$store.commit('eMansionHomepageCapacityStore/eMansionHomepageId', this.$data.eMansionHomepageId);
      this.$store.commit('eMansionHomepageCapacityStore/ftpAccount', this.$data.ftpAccount);
      this.$store.commit('eMansionHomepageCapacityStore/eMansionHomepageCapacity', this.$data.eMansionHomepageCapacity);
      this.$store.commit('eMansionHomepageCapacityStore/eMansionHomepageCapacitySelected', this.$data.eMansionHomepageCapacitySelected);
      this.$store.commit('eMansionHomepageCapacityStore/fee', this.$data.fee);
      this.$store.commit('eMansionHomepageCapacityStore/jimFee', this.$data.jimFee);
      this.$store.commit('eMansionHomepageCapacityStore/maxQuota', this.$data.maxQuota);

      await this.$router.push('/e-mansion/homepage-capacity/confirm');
      this.$data.isLoading = false;
    },
  },
});
