export class UcomAccountInfoList {
  readonly _accountInfoList!: UcomAccountInfo[];

  constructor(
    accountInfoList: UcomAccountInfo[]
  ) {
    this._accountInfoList = accountInfoList;
  }

  get accountInfoList(): UcomAccountInfo[] {
    return this._accountInfoList;
  }
}

export class UcomAccountInfo {
  readonly _accountName!: string; // アカウント名
  readonly _familyName!: string; // 姓
  readonly _firstName!: string; // 名
  readonly _familyNameKana!: string; // 姓カナ
  readonly _firstNameKana!: string; // 名カナ
  readonly _userName!: string; // ユーザネーム
  readonly _password!: string; // UCOM_アカウントパスワード
  readonly _accountAuthorization!: string; // アカウント権限（表示用）
  readonly _mailAddress!: string; // メールアドレス
  readonly _isOldDomain!: string; // 旧ドメインフラグ
  readonly _mailboxDefaultCapacity!: string | undefined; // メール基本容量
  readonly _mailboxAddCapacity!: string | undefined; // メール追加容量
  readonly _mailSumCappacity!: string | undefined; // メール合計容量
  readonly _virusCheckId!: string | undefined; // UCOM_ウィルスチェックID
  readonly _mailAddressType!: string | undefined; // メールアドレス種別
  readonly _ucomAccountMailId!: string | undefined; // UCOM_アカウントメールID
  readonly _ucomWebSpaceId!: string | undefined; // UCOM_WebスペースID
  readonly _homepageAddress!: string | undefined; // ホームページアドレス
  readonly _webSpaceDefaultCapacity!: string | undefined; // ホームページ基本容量
  readonly _webspaceAddCapacity!: string | undefined; // ホームページ追加容量
  readonly _webSpaceSumCapacity!: string | undefined; // ホームページ合計容量
  readonly _ucomAccountId!: string; // UCOM_アカウントID
  readonly _mailForwardStatus!: string; //自動転送設定状態
  readonly _mailReplyStatus!: string; //自動返信設定状態
  /** コンストラクタ */
  constructor(
    accountName: string, // アカウント名
    familyName: string, // 姓
    firstName: string, // 名
    familyNameKana: string, // 姓カナ
    firstNameKana: string, // 名カナ
    userName: string, // ユーザネーム
    password: string, // UCOM_アカウントパスワード
    accountAuthorization: string, // アカウント権限（表示用）
    mailAddress: string, // メールアドレス
    isOldDomain: string, // 旧ドメインフラグ
    mailboxDefaultCapacity: string | undefined, // メール基本容量
    mailboxAddCapacity: string | undefined, // メール追加容量
    mailSumCappacity: string | undefined, // メール合計容量
    virusCheckId: string | undefined, // UCOM_ウィルスチェックID
    mailAddressType: string | undefined, // メールアドレス種別
    ucomAccountMailId: string | undefined, // UCOM_アカウントメールID
    ucomWebSpaceId: string | undefined, // UCOM_WebスペースID
    homepageAddress: string | undefined, // ホームページアドレス
    webSpaceDefaultCapacity: string | undefined, // ホームページ基本容量
    webspaceAddCapacity: string | undefined, // ホームページ追加容量
    webSpaceSumCapacity: string | undefined, // ホームページ合計容量
    ucomAccountId: string, // UCOM_アカウントID
    mailForwardStatus: string, //自動転送設定状態
    mailReplyStatus: string //自動返信設定状態
  ) {
    this._accountName = accountName;
    this._familyName = familyName;
    this._firstName = firstName;
    this._familyNameKana = familyNameKana;
    this._firstNameKana = firstNameKana;
    this._userName = userName;
    this._password = password;
    this._accountAuthorization = accountAuthorization;
    this._mailAddress = mailAddress;
    this._isOldDomain = isOldDomain;
    this._mailboxDefaultCapacity = mailboxDefaultCapacity;
    this._mailboxAddCapacity = mailboxAddCapacity;
    this._mailSumCappacity = mailSumCappacity;
    this._virusCheckId = virusCheckId;
    this._mailAddressType = mailAddressType;
    this._ucomAccountMailId = ucomAccountMailId;
    this._ucomWebSpaceId = ucomWebSpaceId;
    this._homepageAddress = homepageAddress;
    this._webSpaceDefaultCapacity = webSpaceDefaultCapacity;
    this._webspaceAddCapacity = webspaceAddCapacity;
    this._webSpaceSumCapacity = webSpaceSumCapacity;
    this._ucomAccountId = ucomAccountId;
    this._mailForwardStatus = mailForwardStatus;
    this._mailReplyStatus = mailReplyStatus;
  }

  get accountName(): string {
    return this._accountName;
  }

  get familyName(): string {
    return this._familyName;
  }

  get firstName(): string {
    return this._firstName;
  }

  get familyNameKana(): string {
    return this._familyNameKana;
  }

  get firstNameKana(): string {
    return this._firstNameKana;
  }

  get userName(): string {
    return this._userName;
  }

  get password(): string {
    return this._password;
  }

  get accountAuthorization(): string {
    return this._accountAuthorization;
  }

  get mailAddress(): string {
    return this._mailAddress;
  }

  get isOldDomain(): string {
    return this._isOldDomain;
  }

  get mailboxDefaultCapacity(): string | undefined {
    return this._mailboxDefaultCapacity;
  }

  get mailboxAddCapacity(): string | undefined {
    return this._mailboxAddCapacity;
  }

  get mailSumCappacity(): string | undefined {
    return this._mailSumCappacity;
  }

  get virusCheckId(): string | undefined {
    return this._virusCheckId;
  }

  get mailAddressType(): string | undefined {
    return this._mailAddressType;
  }

  get ucomAccountMailId(): string | undefined {
    return this._ucomAccountMailId;
  }

  get ucomWebSpaceId(): string | undefined {
    return this._ucomWebSpaceId;
  }

  get homepageAddress(): string | undefined {
    return this._homepageAddress;
  }

  get webSpaceDefaultCapacity(): string | undefined {
    return this._webSpaceDefaultCapacity;
  }

  get webspaceAddCapacity(): string | undefined {
    return this._webspaceAddCapacity;
  }

  get webSpaceSumCapacity(): string | undefined {
    return this._webSpaceSumCapacity;
  }

  get ucomAccountId(): string {
    return this._ucomAccountId;
  }

  
  get mailForwardStatus(): string {
    return this._mailForwardStatus;
  }

  get mailReplyStatus(): string {
    return this._mailReplyStatus;
  }
}
