

























































































































































































































































import Vue from 'vue';
import {
  required,
  numeric,
  maxLength,
} from 'vuelidate/lib/validators';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import { Property } from '../../../shared/classes/spf-api/property';
import { PlatformEditInputForm } from '../../../shared/classes/platform/edit-input-form';
import { Member } from '@/shared/classes/spf-api/member';
import { AuthService } from '@/shared/services/auth/auth-service';
import InputProperty from '@/shared/components/platform/input-property.vue';
import { BuildingInfoStatus, BUILDING_INFO_STATUS, InputPropertyForm, MyPageProperty } from '@/store/platform/platform-edit-store';
import { emailAddressMaxLength, emailAddressIllegalChar, emailAddressIllegalCharMark, fullKanaAndBlank, fullWidthNoNum, blank } from '@/shared/util/validators';
import { ISP_TYPE_REGEXP, UA_TYPE } from '@/shared/const/service-type';
import { validateRoomNumber, checkIsMatchRoom } from '@/shared/util/input-property-func';
import { EMansionRoomsDetail } from '@/shared/classes/external-api/e-mansion/rooms-response';
import parsePhoneNumber from 'libphonenumber-js';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { MEMBER_STATUS } from '@/shared/const/member-status';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';

/** dataオブジェクトの型  */
type DataType = {
  form: PlatformEditInputForm; // 会員情報変更フォーム
  property: Property | null;
  displayProperty: string; // 物件の表示用文字列
  searchKey: string; // 名称検索フォーム
  isAfterResister: boolean; //後で登録するか
  isSearchedProperties: boolean; //検索したか
  isPerfectMatch: boolean; //完全一致検索か
  errorMessages: string[];
  errorMessageTitle: string;
  eMansionName: string;
  isSubmitting: boolean; //ボタン押下対応
  isMounting: boolean; // vueのmounted実行中
  isPropertyUaTypeEmansion: boolean,
  isPropertyUaTypeUcom: boolean,
  isPropertyUaTypeFiveA: boolean;
  memberStatus: MemberStatus | null;
  memberStatusType: typeof MEMBER_STATUS;
};

/** MemberEdit コンポーネント */
export default Vue.extend({
  name: 'my-page-member-edit',
  components: {
    ErrorMessagesComponent,
    InputProperty,
    LoadingComponent
  },
  data(): DataType {
    return {
      form: {
        firstName: '',
        givenName: '',
        firstNameKatakana: '',
        givenNameKatakana: '',
        emailAddress: '',
        phoneNumber1: '',
        phoneNumber2: '',
        phoneNumber3: '',
        birthYear: '',
        birthMonth: '',
        birthDay: '',
      },
      property: null,
      displayProperty: '',
      searchKey: '',
      isAfterResister: true,
      isSearchedProperties: false,
      isPerfectMatch: false,
      errorMessages: [],
      errorMessageTitle: '入力項目にエラーがあります。ご確認をお願いいたします。',
      eMansionName: '',
      isSubmitting: false,
      isMounting: true,
      isPropertyUaTypeEmansion: false,
      isPropertyUaTypeUcom: false,
      isPropertyUaTypeFiveA: false,
      memberStatus: {} as MemberStatus,
      memberStatusType: MEMBER_STATUS
    };
  },
  validations: {
    form: {
      // 姓 + '空白' + 名 が 30文字以内
      firstName: { required, fullWidthNoNum, maxLength: maxLength(28), blank },
      givenName: { required, fullWidthNoNum, maxLength: maxLength(28), blank },
      // 姓 カタカナ + '空白' + 名 カタカナ が 30文字以内
      firstNameKatakana: { required, maxLength: maxLength(28), fullKanaAndBlank },
      givenNameKatakana: { required, maxLength: maxLength(28), fullKanaAndBlank },
      emailAddress: { required, emailAddressMaxLength, emailAddressIllegalChar, emailAddressIllegalCharMark, },
      phoneNumber1: { required, numeric },
      phoneNumber2: { required, numeric },
      phoneNumber3: { required, numeric },
    },
    inputBuildingData: {
      roomNumber: {
        required,
        numeric,
        maxLength: maxLength(8)
      },
    },
    selectedProperty: { required },
  },
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    try {
      const isLoggedIn = AuthService.isLoggedIn();
      // ログインしていない場合「総合トップ」画面にリダイレクトする
      if (!isLoggedIn) {
        this.$router.push('/').catch(error => { checkRouterError(error) });
        return;
      }
      window.scrollTo(0, 0);

      /** ポルタス会員情報を初期値として表示する */
      const member: Member | null = this.$store.getters['memberStore/member'];
      if (member) {
        this.form.firstName = member.firstName;
        this.form.givenName = member.givenName;
        this.form.firstNameKatakana = member.firstNameKatakana;
        this.form.givenNameKatakana = member.givenNameKatakana;
        this.form.emailAddress = member.emailAddress;
        if (member.phoneNumber) {
          this.form.phoneNumber1 = member.phoneNumber.split('-')[0];
          this.form.phoneNumber2 = member.phoneNumber.split('-')[1];
          this.form.phoneNumber3 = member.phoneNumber.split('-')[2];
        }
        if (member.birthdate) {
          this.form.birthYear = member.birthdate.split('-')[0];
          this.form.birthMonth = member.birthdate.split('-')[1];
          this.form.birthDay = member.birthdate.split('-')[2];
        }

        /** 会員情報に紐づく物件があれば初期値として表示する */
        if (member.propertyId) {
          this.property = this.$store.getters['propertyStore/property'];
          if (!this.property) {
            throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_PROPERTY);
          }

          if (this.property.uaType === UA_TYPE.E_MANSION && member.primaryKeyMye) {
            /** 契約基本情報をStoreから取得 */
            // const params = { memberId: String(member.primaryKeyMye), query: { 'ua_type': UA_TYPE.E_MANSION, 'property_id': this.property.apartmentId } };
            // const customer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', params);
            const customer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.getters['eMansionCommonStore/customer'];
            if (customer instanceof EMansionCustomer) {
              this.displayProperty = customer.building_name ? `${this.property.apartmentName}（${customer.building_name}）` : this.property.dispApartmentName;
              // 行儀悪いけど上書き
              this.property.dispApartmentName = this.displayProperty;
            } else {
              throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
            }

          } else {
            this.displayProperty = this.property.dispApartmentName;
          }
        }
      } else {
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      }

      // 確認画面から戻った際にstoreに会員情報が残っていれば表示する
      // vue-routerのbeforeEnterにて、ストアの値保持する/しないの処理
      const editForm = this.$store.getters['platformEditStore/editInputForm'];
      if (editForm) {
        this.form = editForm;
      }
      const inputPropertyForm = this.$store.getters['platformEditStore/inputPropertyForm'];
      if (inputPropertyForm) {
        this.isAfterResister = inputPropertyForm.isAfterResister;
      }

      // PortasDBの会員テーブルのレコードについて、
      // e-mansion, ucom は 名前、電話番号、生年月日がnullなので表示しない
      // Five.A は、名前、電話番号、生年月日があるので表示する
      if (this.property?.uaType === UA_TYPE.E_MANSION) {
        this.isPropertyUaTypeEmansion = true;
      } else if (this.property?.uaType === UA_TYPE.UCOM) {
        this.isPropertyUaTypeUcom = true;
      } else if (this.property?.uaType === UA_TYPE.FIVE_A) {
        this.isPropertyUaTypeFiveA = true;
      }

      this.memberStatus = this.$store.getters['memberStore/memberStatus'];

      this.isMounting = false;
    } catch (error) {
      throw error;
    } finally {
      this.isMounting = false;
    }
  },
  methods: {
    /** 次へボタン押下時： フォームの値をストアに保存し会員情報変更確認画面に遷移する */
    async onNext() {
      // ボタン押下中は何もしない
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中とする
      this.isSubmitting = true;

      try {
        if (this.isPropertyUaTypeFiveA) {
          this.validateForm();
        } else {
          this.validateFormEmailAddress();
        }

        const inputPropertyForm: InputPropertyForm | null = this.$store.getters['platformEditStore/inputPropertyForm'];

        // 物件未登録時の処理
        if (!this.property) {
          /**「お住まいを登録する」を選択中
           * 物件検索APIの正常系レスポンスが取得出来ている前提
           */
          // inputPropertyForm?.properties.length は検索結果の有無なので要件としては不要となる
          if (!this.isAfterResister && inputPropertyForm?.properties.length) {
            // 物件が未選択の場合
            if (!inputPropertyForm.selectedProperty) {
              this.errorMessages.push('お住まいを選択してください。');
            } else {
              // UA_TYPE・ISP_TYPEで分ける
              const uaType = inputPropertyForm.selectedProperty.uaType;
              const ispType = inputPropertyForm.selectedProperty.ispType;
              // e-mansion, five.A, ucom について、部屋番号バリデーション実施
              this.errorMessages.push(
                ...validateRoomNumber(inputPropertyForm.inputBuildingData.roomNumber, uaType, ispType)
              );

              if (uaType === UA_TYPE.E_MANSION) {
                /**Mcloud物件の場合 */
                if (typeof ispType === 'string' && ISP_TYPE_REGEXP.MCLOUD.test(ispType)) {
                  // 棟情報を持った物件かどうかをストアから取得する
                  const isIncludesBuildingsInfo: boolean = this.$store.getters['platformEditStore/isIncludesBuildingsInfo'];
                  if (isIncludesBuildingsInfo && !inputPropertyForm.inputBuildingData.buildingName) {
                    this.errorMessages.push('棟名が選択されていません。');
                  }
                  /**e-mansionの場合 */
                } else {
                  const rooms: { id: string; buildingInfoStatus: BuildingInfoStatus; rooms: EMansionRoomsDetail[] } | undefined = inputPropertyForm.roomsData.find((room) => {
                    return room.id === String(inputPropertyForm.selectedProperty?.id); // e-mansion 特殊物件の場合でも「検索当初の portas 物件 id == room.id」となるため問題はない
                  });
                  const buildingInfoStatus = rooms?.buildingInfoStatus ?? '';
                  // 棟情報を持った物件かどうかをストアから取得する
                  const isIncludesBuildingsInfo: boolean = this.$store.getters['platformEditStore/isIncludesBuildingsInfo'];
                  // 棟情報を持った物件である場合
                  if (isIncludesBuildingsInfo) {
                    /**棟名バリデーションチェック */
                    if (
                      buildingInfoStatus === BUILDING_INFO_STATUS.BOTH ||
                      buildingInfoStatus === BUILDING_INFO_STATUS.BUILDING_ONLY
                    ) {
                      if (!inputPropertyForm.inputBuildingData.buildingName) {
                        this.errorMessages.push('棟名が選択されていません。');
                      } else {
                        // 建物選択後の検索であるが検索結果全ての建物を対象としたチェックとなっている（const rooms を送る形式で新たな checkIsMatchRoom を作成するかどうか）
                        const isMatch = checkIsMatchRoom(
                          buildingInfoStatus,
                          inputPropertyForm.roomsData,
                          inputPropertyForm.inputBuildingData
                        );
                        if (!isMatch) {
                          this.errorMessages.push(
                            `正しい棟名と部屋番号を入力してください。`
                          );
                          window.scrollTo(0, 0);

                          // ボタン押下解除
                          this.isSubmitting = false;
                          return;
                        }
                      }
                    }
                  } else {
                    // 棟情報を持たない物件である場合
                    if (buildingInfoStatus === BUILDING_INFO_STATUS.ROOM_ONLY) {
                      // 建物選択後の検索であるが検索結果全ての建物を対象としたチェックとなっている（const rooms を送る形式で新たな checkIsMatchRoom を作成するかどうか）
                      const isMatch = checkIsMatchRoom(
                        buildingInfoStatus,
                        inputPropertyForm.roomsData,
                        inputPropertyForm.inputBuildingData
                      );
                      if (!isMatch) {
                        this.errorMessages.push(
                          `正しい部屋番号を入力してください。`
                        );
                        window.scrollTo(0, 0);

                        // ボタン押下解除
                        this.isSubmitting = false;
                        return;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        if (this.errorMessages.length !== 0) {
          window.scrollTo(0, 0);

          // ボタン押下解除
          this.isSubmitting = false;
          return;
        }

        /** 入力値の保存 */
        this.$store.commit('platformEditStore/editInputForm', this.form);
        /** 物件が紐付いている場合は物件情報を保存
         * 物件が紐付いておらず、お住まいを登録している場合は選択した物件情報を保存
         */
        if (this.property) {
          this.$store.commit('platformEditStore/property', this.property);
        } else {
          if (!this.isAfterResister && inputPropertyForm?.selectedProperty) {
            const myPageProperty: MyPageProperty = {
              propertyId: inputPropertyForm.eMansionSpecialPropertyId ? inputPropertyForm.eMansionSpecialPropertyId : inputPropertyForm.selectedProperty.id,
              apartmentName: inputPropertyForm.eMansionSpecialPropertyName
                ? `${inputPropertyForm.eMansionSpecialPropertyName} ${inputPropertyForm.inputBuildingData.buildingName}`
                : `${inputPropertyForm.selectedProperty.apartmentName} ${inputPropertyForm.inputBuildingData.buildingName}`,
              dispApartmentName: this.displayProperty,
              zipcode: inputPropertyForm.selectedProperty.zipcode,
              address: inputPropertyForm.selectedProperty.address,
              buildingId: inputPropertyForm.inputBuildingData.buildingId,
              roomNumber: inputPropertyForm.inputBuildingData.roomNumber
            };
            this.$store.commit('platformEditStore/myPageProperty', myPageProperty);
          } else {
            /** お住まいを後で登録を選択した場合、以前の入力データを空にしておく */
            this.$store.commit('platformEditStore/myPageProperty', null);
          }
        }
        this.$router.push('/platform/my-page/member-confirm').catch(error => { checkRouterError(error) });
      } catch (error) {
        throw error;
      } finally {
        // ボタン押下解除
        this.isSubmitting = false;
      }
    },
    async onBack() {

      this.isMounting = true;
      /** 遷移元パスをストアから呼び出す */
      const path = this.$store.getters['platformEditStore/beforePath'];
      if (
        path === '/ucom/entry/input' ||
        path === '/e-mansion/entry/input' ||
        path === '/e-mansion/entry/confirm' ||
        path === '/5a/entry/input' ||
        path === '/5a/entry/confirm' ||
        path === '/platform/contacts'
      ) {
        await this.$router.push(path).catch(error => { checkRouterError(error) });
      } else {
        await this.$router.push('/platform/my-page').catch(error => { checkRouterError(error) });
      }

    },
    changeIsAfterRegister(value: boolean) {
      this.isAfterResister = value;
    },
    validateFormEmailAddress() {
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];

      if (!this.$v.form.emailAddress?.required) {
        this.errorMessages.push(`メールアドレスを入力してください。`);
      } else if (!this.$v.form.emailAddress?.emailAddressMaxLength) {
        this.errorMessages.push('メールアドレスは48文字以内で入力してください。');
      } else if (!this.$v.form.emailAddress?.emailAddressIllegalChar) {
        this.errorMessages.push('メールアドレスの形式が正しくありません。');
      } else if (!this.$v.form.emailAddress?.emailAddressIllegalCharMark) {
        this.errorMessages.push('メールアドレスの形式が正しくありません。');
      }
    },
    validateForm() {
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];

      // 氏名欄チェック
      if (
        !this.$v.form.firstName?.required ||
        !this.$v.form.givenName?.required
      ) {
        this.errorMessages.push(`お名前を入力してください。`);
      } else if (
        !this.$v.form.firstName?.fullWidthNoNum ||
        !this.$v.form.givenName?.fullWidthNoNum
      ) {
        this.errorMessages.push('お名前の形式が正しくありません。');
      } else if (
        this.$v.form.firstName?.blank ||
        this.$v.form.givenName?.blank
      ) {
        this.errorMessages.push('お名前に空白を含めないようにしてください。');
      }
      if (this.isOver31Characters()) {
        this.errorMessages.push('お名前は姓と名合わせて全角29文字以内で入力してください。');
      }
      // フリガナ欄チェック
      if (
        !this.$v.form.firstNameKatakana?.required ||
        !this.$v.form.givenNameKatakana?.required
      ) {
        this.errorMessages.push(`フリガナを入力してください。`);
      } else if (
        !this.$v.form.firstNameKatakana?.fullKanaAndBlank ||
        !this.$v.form.givenNameKatakana?.fullKanaAndBlank
      ) {
        // フリガナが全角カタカナで入力されていない時
        this.errorMessages.push(`フリガナの形式が正しくありません。`);
      }
      if (this.isOver31CharactersKatakana()) {
        this.errorMessages.push('フリガナはセイとメイ合わせて全角29文字以内で入力してください。');
      }
      // メールアドレスチェック
      if (!this.$v.form.emailAddress?.required) {
        this.errorMessages.push(`メールアドレスを入力してください。`);
      } else if (!this.$v.form.emailAddress?.emailAddressMaxLength) {
        this.errorMessages.push('メールアドレスは48文字以内で入力してください。');
      } else if (!this.$v.form.emailAddress?.emailAddressIllegalChar) {
        this.errorMessages.push('メールアドレスの形式が正しくありません。');
      } else if (!this.$v.form.emailAddress?.emailAddressIllegalCharMark) {
        this.errorMessages.push('メールアドレスの形式が正しくありません。');
      }
      // 電話番号欄チェック
      if (
        !this.$v.form.phoneNumber1?.required ||
        !this.$v.form.phoneNumber2?.required ||
        !this.$v.form.phoneNumber3?.required
      ) {
        this.errorMessages.push(`電話番号を入力してください。`);
      } else if (
        !this.$v.form.phoneNumber1?.numeric ||
        !this.$v.form.phoneNumber2?.numeric ||
        !this.$v.form.phoneNumber3?.numeric
      ) {
        this.errorMessages.push(`電話番号の形式が正しくありません。`);
      } else {
        const phoneNumber = `${this.form.phoneNumber1}-${this.form.phoneNumber2}-${this.form.phoneNumber3}`;
        if (!parsePhoneNumber(phoneNumber, 'JP')?.isValid()) {
          this.errorMessages.push('電話番号の形式が正しくありません。');
        }
      }
    },
    isOver31Characters(): boolean {
      const array = [
        this.form.firstName,
        '　',
        this.form.givenName
      ];
      return array.join('').length >= 31;
    },
    isOver31CharactersKatakana(): boolean {
      const array = [
        this.form.firstNameKatakana,
        '　',
        this.form.givenNameKatakana
      ];
      return array.join('').length >= 31;
    }
  }
});
