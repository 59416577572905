























































































import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';

export default Vue.extend({
  name: 'mailbox-capacity-completed',
  components: {
    LoadingComponent,
  },
  data: () => ({
    notifyMailAddress: '', // 通知先メールアドレス
    accountName: '', // アカウント名
    mailAddress: '', // メールアドレス
    mailBoxCapacity: '', // メールボックス容量
    changeCapacityLabel: '', // 追加／縮小容量ラベル
    price: '', // 単価
    effectiveDate: '', // 申込日
    serviceDescription: '', // サービス説明
    isLoading: false, //ローディング
  }),
  async mounted() {
    // リロード、ブラウザバックした場合は会員専用トップページへ
    if (!this.$store.getters['ucomMailboxCapacityStore/ucomAccountMailId']) {
      await this.$router.push('/ucom');
      return;
    }

    try {
      this.$data.isLoading = true;

      // 前画面からの情報をStoreから取得し画面項目データをセット
      await this.setDataByStore();

      // storeをクリア
      this.deleteStore();
      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/error');
      return;
    }
  },
  methods: {
    // storeから画面項目データをセット
    async setDataByStore(): Promise<void> {
      this.$data.notifyMailAddress = this.makeMailAddressForView(this.$store.getters['ucomMailboxCapacityStore/notifyMailAddress']);
      this.$data.accountName = this.$store.getters['ucomMailboxCapacityStore/accountName'];
      this.$data.mailAddress = this.$store.getters['ucomMailboxCapacityStore/mailAddress'];
      this.$data.mailBoxCapacity = this.$store.getters['ucomMailboxCapacityStore/mailBoxCapacity'];
      this.$data.changeCapacityLabel = this.$store.getters['ucomMailboxCapacityStore/changeCapacityLabel'];
      this.$data.price = this.$store.getters['ucomMailboxCapacityStore/price'];
      this.$data.effectiveDate = this.$store.getters['ucomMailboxCapacityStore/effectiveDate'];
      this.$data.serviceDescription = this.$store.getters['ucomMailboxCapacityStore/serviceDescription'];
    },
    // storeをクリア
    deleteStore(): void {
      this.$store.commit('ucomMailboxCapacityStore/ucomAccountMailId', undefined);
      this.$store.commit('ucomMailboxCapacityStore/accountName', undefined);
      this.$store.commit('ucomMailboxCapacityStore/mailAddress', undefined);
      this.$store.commit('ucomMailboxCapacityStore/mailBoxCapacity', undefined);
      this.$store.commit('ucomMailboxCapacityStore/changeCapacityLabel', undefined);
      this.$store.commit('ucomMailboxCapacityStore/changeCapacityValue', undefined);
      this.$store.commit('ucomMailboxCapacityStore/price', undefined);
      this.$store.commit('ucomMailboxCapacityStore/effectiveDate', undefined);
      this.$store.commit('ucomMailboxCapacityStore/serviceDescription', undefined);
      this.$store.commit('ucomMailboxCapacityStore/isRegisteredPayment', undefined);
      this.$store.commit('ucomMailboxCapacityStore/subscriptionDate', undefined);
      this.$store.commit('ucomMailboxCapacityStore/notifyMailAddress', undefined);
    },
    makeMailAddressForView(mailAddress: string[]): string {
      if (!mailAddress[0]) {
        return mailAddress[1];
      }
      if (mailAddress[0] === '') {
        return mailAddress[1];
      }
      return `${mailAddress[0]}, ${mailAddress[1]}`;
    },
    // 「UCOM光レジデンス会員専用トップ」ボタンクリック
    async onUcomTop(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      // データの変更は無いのでそのまま入力画面へ遷移
      await this.$router.push('/ucom');
      this.$data.isLoading = false;
    },
    // ページトップへスクロール
    scrollPageTop(): void {
      window.scrollTo(0, 0);
    },
  },
});
