










































































import Vue from 'vue';

/** ロード中を表現するスピナーのコンポーネント */
export default Vue.extend({
  name: 'loading-component'
});
