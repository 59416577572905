































































































































































































































































import Vue from 'vue';
import { AuthService } from '@/shared/services/auth/auth-service';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
/** クレジットカード用コンポーネント */
import CreditCardComponent from '@/shared/components/veritrans-credit-card-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
/** Entity five-a Connectix API : エラー時のレスポンス */
import { FiveAProperty } from '../../../shared/classes/external-api/five-a/property-response';
import { FiveAConnectixFee, FiveAEntryInputForm } from '../entry/classes/entry-input-form';
import { FiveASharedErrorResponse } from '@/shared/classes/external-api/five-a/shared-error-response';
import { LinkServiceWithFiveARequest } from '@/shared/classes/spf-api/link-service-with-five-a';
import { Property } from '@/shared/classes/spf-api/property';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { Member } from '@/shared/classes/spf-api/member';
import { UA_TYPE, SERVICE_TYPE } from '@/shared/const/service-type';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { ConnectixMaintenanceInfo } from '@/shared/classes/spf-api/connectix-maintenance';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';

/** Confirm コンポーネント */
export default Vue.extend({
  name: 'confirm',
  components: {
    ErrorMessagesComponent,
    /** クレジットカード入力フォームコンポーネント */
    CreditCardComponent,
    LoadingComponent
  },
  data: () => ({
    customer: null as FiveAEntryInputForm | null,
    member: null,
    property: null as FiveAProperty | null,
    /** 性別（表示用） */
    sex: '' as string,
    /** 生年月日（表示用） */
    dispBirthday: '' as string,
    /** Connectix利用金額 */
    connectixUsageFee: '' as string,
    /** Connectixキャンペーン利用金額 */
    connectixUsageFeeCp: '' as string,
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    // クレジットカード情報必要可否フラグ
    needCreditCard: false as boolean,
    /** クレジットカードアクセストークン */
    creditCardAccessToken: '' as string,
    /** クレジットカード */
    creditTokenExpireDate: '' as string,
    /** APIトークンキー */
    apiTokenKey: '' as string,
    /** トークン取得成功後に表示するメッセージ */
    successMessage: '画面下の「登録」ボタンをクリックしてください。',
    /** ボタン押下判定 */
    isSubmitting: false as boolean,
    /** 建物名 */
    propertyName: '' as string,
    /** 会員ID */
    memberId: 0 as number,
    // mounted()実行中かどうか
    isMounted: false as boolean
  }),
  async mounted(): Promise<void> {
    try {
      /** ログインしているか否かの情報を取得 */
      const isLoggedIn = await AuthService.isLoggedIn();
      // ログインしていない場合「総合トップ」画面にリダイレクトする
      if (!isLoggedIn) {
        this.$router.push('/').catch(error => {checkRouterError(error)});
        return;
      }
      // 会員情報取得処理
      this.$data.member = this.$store.getters['memberStore/member'];
      if (!this.$data.member || !this.$data.member.propertyId) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      }

      // Five.A会員情報取得処理
      this.customer = this.$store.getters['fiveAEntryStore/entryInputForm'];
      // customer情報が無い場合、会員規約の同意をしてないことになるため、エラー画面にする
      if (!this.customer) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_INPUT_DATA);
      }

      // !this.member は、ほぼ生じえない
      // マイページ編集後に限定して処理した方がより良いが、煩瑣になるため一旦はこの形としておく
      if (this.$store.getters['platformEditStore/beforePath'] === '/5a/entry/confirm' || !this.member) {
        this.$store.commit('platformEditStore/beforePath', null);
        this.$data.member = this.$store.getters['memberStore/member']; // beforeEach により、変更後の会員情報が取得される
        if (!this.$data.member || !this.$data.member.propertyId) {
          // データ不整合エラー
          throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_MEMBER);
        }
        // 修正後の member データで更新（紐づけ物件の修正は生じえない）
        this.customer.buildingId = this.$data.member.buildingNumber ?? '';
        this.customer.roomNumber = this.$data.member.roomNumber ?? '';
        this.customer.nameSurname = this.$data.member.firstName;
        this.customer.nameFirstName = this.$data.member.givenName;
        this.customer.kanaSurname = this.$data.member.firstNameKatakana;
        this.customer.kanaFirstName = this.$data.member.givenNameKatakana;
        this.customer.birthday = this.$data.member.birthdate.replace(/\-/g, '/');
        this.customer.phoneNumber = this.$data.member.phoneNumber;
        this.customer.emailAddress = this.$data.member.emailAddress;
      }

      // 物件情報の取得
      const property: Property | null = this.$store.getters['propertyStore/property'];
      if (property) {
        this.propertyName = property.dispApartmentName;
      }
      if (!property || !property.apartmentId) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_PROPERTY);
      }

      this.sex = this.getGender(this.customer.sex) ?? '';
      this.dispBirthday = this.getBirthday(this.customer.birthday);

      // 物件基本情報を取得
      const params = {
        propertyId: property.apartmentId,
        uaType: UA_TYPE.FIVE_A
      };
      const propertyInfo: FiveAProperty | FiveASharedErrorResponse = await this.$store.dispatch('fiveACommonStore/property', params);
      if (propertyInfo instanceof FiveAProperty) {
        this.property = propertyInfo;
        // Connectix利用金額
        this.connectixUsageFee = this.property.op.sdwan.fee;
        this.connectixUsageFeeCp = this.property.op.sdwan.fee_cp;
        const fiveAConnectixFee: FiveAConnectixFee = {
          connectixUsageFee: this.connectixUsageFee,
          connectixUsageFeeCp: this.connectixUsageFeeCp
        };
        this.$store.commit('fiveAEntryStore/fiveAConnectixFee', fiveAConnectixFee);
      } else {
        // データ不整合エラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.PROPERTY_INFO);
      }

      // APIトークンキーを設定
      this.$data.apiTokenKey = process.env.VUE_APP_VERITRANS_TOKEN_API_KEY_E_MANSION;
    } catch (error) {
      throw error;
    } finally {
      this.isMounted = true;
    }
  },
  methods: {
    /** 表示用性別を取得する */
    getGender(sex: string) {
      if (sex === '1') {
        return '男性';
      } else if (sex === '2') {
        return '女性';
      } else {
        return '';
      }
    },
    /** 表示用生年月日を取得する */
    getBirthday(birthday: string) {
      const date = birthday.split('/');
      const birthdayYear = Number(date[0]);
      const birthdayMonth = Number(date[1]);
      const birthdayDate = Number(date[2]);
      return (String(birthdayYear) + '年 ' + String(birthdayMonth) + '月 ' + String(birthdayDate) + '日');
    },
    /** クレジット関連関数 ここから */
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークンを受け取る */
    getCreditCardToken(token: string) {
      // エラーメッセージ格納配列初期化
      this.$data.errorMessages = [];
      this.$data.creditCardAccessToken = token;
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークン有効期限を受け取る */
    getTokenExpireDate(creditTokenExpireDate: string) {
      this.$data.creditTokenExpireDate = creditTokenExpireDate;
    },
    /** クレジットカードエラーが発生していた場合 */
    executeCreditCardError(errorMessage: string) {
      this.$data.errorMessages = [errorMessage];
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
    },
    /** クレジット関連関数 ここまで */
    /** 修正ボタン押下時：入力に戻る */
    async modCustomerInfo() {
      this.$router.push('/platform/my-page/member-edit').catch(error => {checkRouterError(error)});
    },
    /** 次へボタン押下時： 完了画面に遷移する */
    async onNext() {
      if (!this.customer) {
        return;
      }

      const connectixMaintenance: ConnectixMaintenanceInfo =  await this.$store.dispatch('connectixMaintenanceStore/connectixMaintenance', SERVICE_TYPE.FIVE_A);

      if(connectixMaintenance){
        this.$router.push('/platform/maintenance').catch(error => {checkRouterError(error)});
        return;
      }

      try {
        // ボタン押下中は何もしない
        if (this.isSubmitting) {
          return;
        }
        // ボタン押下中扱いとする
        this.isSubmitting = true;
        // エラーメッセージ格納配列初期化
        this.errorMessages = [];
        if (this.creditCardAccessToken === '') {
          // クレジットカードが登録されておらず、かつAPIキーが発行されていない場合はエラー
          this.errorMessages.push(`カード番号、有効期限を正しく入力してください。`);
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          // ボタン押下解除
          this.isSubmitting = false;
          return;
        }

        // PORTAS会員IDの取得
        const member: Member | null = this.$store.getters['memberStore/member'];
        if (member) {
          this.memberId = member.id;
        }

        /** クレジットカードの情報を追加 */
        if (this.creditCardAccessToken) this.customer.creditCardToken = this.creditCardAccessToken;
        if (this.creditTokenExpireDate) this.customer.tokenExpireDate = this.creditTokenExpireDate;

        /** 郵便番号を数値のみに修正（ハイフンを削除） */
        this.customer.destinationZipcode = this.customer.destinationZipcode.replaceAll(/-/g, '');
        if (!this.customer.buildingId) {
          this.customer.buildingId = '0000';
        }

        const linkServiceWithFiveARequest: LinkServiceWithFiveARequest = new LinkServiceWithFiveARequest(this.customer);
        if(linkServiceWithFiveARequest.faxNumber === ''){
          linkServiceWithFiveARequest.faxNumber = undefined;
        }
        linkServiceWithFiveARequest.hikariphone = '0';
        await SpfApiService.linkServiceWithFiveA(this.memberId, linkServiceWithFiveARequest);
        await AuthService.refresh();

        // SpfApiService.linkServiceWithFiveA() に成功するとバックエンドで会員ステータスが更新される
        // ページ遷移時に beforeEach で会員ステータス取得できるように null にする
        this.$store.commit('memberStore/memberStatus', null);
        this.$store.commit('memberStore/member', null);

        this.$router.push('/5a/entry/completed').catch(error => {checkRouterError(error)});
      } catch (error) {
        throw error;
      } finally {
        this.isSubmitting = false;
      }
    },
    onEdit() {
      this.$router.push('/platform/my-page/member-edit').catch(error => {checkRouterError(error)});
    },
    onBack() {
      // 入力ページに遷移
      this.$router.push('/5a/entry/input').catch(error => {checkRouterError(error)});
    }
  }
});
