import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { Property } from '@/shared/classes/spf-api/property';
import { UA_TYPE } from '@/shared/const/service-type';
import { Route } from 'vue-router';
import { AuthService } from '../auth/auth-service';
import store from '@/store';

/**
 * e-mansion ユーザー共通データロード用サービス
 */
export class EMansionCommonDataLoadService {
  /**
   * ログイン時共通データのロード
   * @returns なし
   */
  public static async loadInitialData(to: Route): Promise<void> {
    const loginId = await AuthService.getEMansionLoginId();
    if (!loginId) {
      return;
    }

    // 強制リフレッシュが必要か確認
    const doRefresh = this.needRefresh(to);
    // データ読み込みの待ち合わせ
    await Promise.all([await this.storeCustomer(store, doRefresh), await this.storeProperty(store, doRefresh), await this.storeEMansionAccount(store, doRefresh)]);

    // 会員種別をストック
    if (!store.getters['servicePlanTypeStore/servicePlanType']) {
      await store.dispatch('servicePlanTypeStore/servicePlanType');
    }
  }

  /**
   * 強制読み込みが必要かチェックする
   * @param to 遷移先のパス
   * @returns boolean trueの場合、強制リロードが必要
   */
  private static needRefresh(to: Route): boolean {
    // Portasのメニュー画面や、会員専用トップではクレカの登録情報などを最新状態にする必要がある為、
    // 必ずロードし直さなければならない
    // その為、メール基盤統合で追加した画面以外は強制読み込み要と判断する
    return to.path.match(/^\/e-mansion\/.+/) == null;
  }

  /**
   * 契約基本情報を取得し、storeに格納する
   * @param store データストア
   * @param doRefresh 強制更新フラグ
   */
  private static async storeCustomer(store: any, doRefresh: boolean): Promise<void> {
    const needLoad = doRefresh || !store.getters['eMansionCommonStore/customer'];
    if (!needLoad) {
      return;
    }

    const property: Property = store.getters['propertyStore/property'];
    const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
      ua_type: UA_TYPE.E_MANSION,
      apartment_id: property.apartmentId,
    });

    const eMansionMemberId = await AuthService.getEMansionMemberId();
    await store.dispatch('eMansionCommonStore/customer', {
      memberId: eMansionMemberId,
      query: findCustomerByIdQueryDto,
    });
  }

  /**
   * 物件基本情報を取得し、storeに格納する
   * @param store データストア
   * @param doRefresh 強制リロードフラグ
   */
  private static async storeProperty(store: any, doRefresh: boolean): Promise<void> {
    const needLoad = doRefresh || !store.getters['eMansionCommonStore/property'];
    if (!needLoad) {
      return;
    }

    const property: Property = store.getters['propertyStore/property'];
    const propertyParams = { apartmentId: property.apartmentId, uaType: UA_TYPE.E_MANSION };
    await store.dispatch('eMansionCommonStore/property', propertyParams);
  }

  /**
   * e-mansion_アカウント取得
   * @param store データストア
   * @param doRefresh 強制リロードフラグ
   */
  private static async storeEMansionAccount(store: any, doRefresh: boolean): Promise<void> {
    const needLoad = doRefresh || !store.getters['eMansionCommonStore/eMansionAccountInfo'];
    if (!needLoad) {
      return;
    }

    const loginId = await AuthService.getEMansionLoginId();
    // EMansion と紐付け済みか
    if (loginId) {
      await store.dispatch('eMansionAccountStore/eMansionAccountInfo', loginId);
    }
  }
}
