







import { PermissionControlService } from "@/shared/services/permission/permission-control-service";
import Vue from "vue";

export default Vue.extend({
  name: 'permission-controled-list-link',
  props: {
    title: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    functionId: {
      type: String,
      default: undefined,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tabTarget: {
      type: String,
      default: '_blank',
    },
  },
  data: () => ({
    isEnabled: false
  }),
  async mounted(): Promise<void> {
    // メニューリンクは"R"以上のパーミッションがあれば利用可
    this.$data.isEnabled = await PermissionControlService.isReadable(this.functionId);
  }
});
