











































































































import Vue from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { VirusCheckFee } from '@/shared/classes/spf-api/e-mansion-mail';
import { SpfApiVirusCheckAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-virus-check-accessor';
import { SpfApiPaymentStatusAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-payment-status-accessor';
import { AuthService } from '@/shared/services/auth/auth-service';

export default Vue.extend({
  name: 'virus-check-apply-confirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },

  data(): {
    isBusy: boolean;
    isAgreed: boolean;
    errorMessageTitle: string;
    errorMessages: string[];
    paymentStatus: string;
  } {
    return {
      isBusy: true,
      isAgreed: false,
      errorMessageTitle: '',
      errorMessages: [],
      paymentStatus: '',
    };
  },

  async mounted() {
    if (this.feeInfo.fee > 0) {
      await this.initPaymentStatus();
    }
    this.isBusy = false;
  },
  methods: {
    /**
     * お支払い情報登録状態を API から取得して初期化する
     */
    async initPaymentStatus(): Promise<void> {
      const memberId = await AuthService.getMemberId();
      const paymentStatusInfo = await SpfApiPaymentStatusAccessor.getPaymentStatus(Number(memberId));
      this.paymentStatus = paymentStatusInfo.status;
    },

    /**
     * 「申し込む」のクリックハンドラ
     */
    async applyVirusCheck(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isBusy = true;
      // 「サービス規約の内容に同意する」がチェックされていない場合、エラー表示
      if (!this.isAgreed) {
        // TODO : メッセージの内容は適当です。仕様を確認してください。
        this.errorMessages = ['お申し込みの前にサービス規約を必ずお読みになり、「サービス規約の内容に同意する」にチェックしてください。'];
        this.isBusy = false;
        return;
      }
      try {
        // ウィルスチェック申込更新 API を呼び出す
        const eMansionVirusCheckUpdateResponseDto = await SpfApiVirusCheckAccessor.updateVirusCheck(this.mailAddressId);

        // レスポンスの message に値があればエラーを表示して終了する
        // TODO : エラーの表示方法については問合せ中。
        if (eMansionVirusCheckUpdateResponseDto.message) {
          this.errorMessages = [eMansionVirusCheckUpdateResponseDto.message];
          this.isAgreed = false;
          this.isBusy = false;
          return;
        }

        // メールアドレスを Store に保存する
        this.$store.commit('eMansionMailStore/setSelectedMailAddress', this.mailAddress);
        // 完了 (申込) 画面に遷移する
        this.$router.push('/e-mansion/mail/virus-check-apply-complete');
      } catch (error) {
        await this.$router.push('/e-mansion/error');
      }
    },

    /**
     * 「戻る」のクリックハンドラ
     */
    goBack(): void {
      this.$router.push('/e-mansion/mail/usage');
    },
  }, // methods

  computed: {
    /** ローディング中かどうかの判定 */
    isLoading(): boolean {
      return this.isBusy;
    }, // isLoading

    /** 処理対象のメールアドレスID */
    mailAddressId(): number {
      const mailAddressId = this.$store.getters['eMansionMailStore/selectedMailAddressId'];

      if (!mailAddressId) {
        throw new Error();
      }

      return mailAddressId;
    },

    /** 処理対象のメールアドレス */
    mailAddress(): string {
      const mailAddress = this.$store.getters['eMansionMailStore/selectedMailAddress'];

      if (!mailAddress) {
        throw new Error();
      }

      return mailAddress;
    },

    /** 処理対象の利用料情報 */
    feeInfo(): VirusCheckFee {
      const feeInfo = this.$store.getters['eMansionMailStore/selectedFeeInfo'];
      return feeInfo;
    },
  }, // computed
});
