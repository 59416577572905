import FiveAIndex from '@/pages/five-a/index.vue';
import { NavigationGuardNext, Route } from 'vue-router';

import LinkService from '@/pages/five-a/link-service.vue';

import Terms from '@/pages/five-a/entry/terms.vue';
import Input from '@/pages/five-a/entry/input.vue';
import Confirm from '@/pages/five-a/entry/confirm.vue';
import Completed from '@/pages/five-a/entry/completed.vue';
import store from '@/store';

import { Property } from '@/shared/classes/spf-api/property';
import { SERVICE_TYPE, UA_TYPE } from '@/shared/const/service-type';
import { FiveAProperty } from '@/shared/classes/external-api/five-a/property-response';
import { FiveASharedErrorResponse } from '@/shared/classes/external-api/five-a/shared-error-response';
import { isTransitionNoLinked } from '.';
import { ConnectixMaintenanceInfo } from '@/shared/classes/spf-api/connectix-maintenance';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';

/** Five.A ルーティング定義 */
const fiveARouter = [
  {
    path: '/5a',
    name: 'Five.A サービストップ',
    component: FiveAIndex,
    meta: {
      title: 'Portas | Five.A 会員専用トップページ | ポルタス',
      description: 'Portas（ポルタス）Five.A会員専用トップページになります。',
      keywords: 'Portas,ポルタス,Five.A,Connectix,コネクティクス'
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if(await isTransitionNoLinked(UA_TYPE.FIVE_A)){
        return next();
      }
      return next({ path: '/login-forwarding' });
    }
  },
  {
    path: '/5a/link-service',
    name: 'Five.A Connectix お申し込み ',
    component: LinkService,
    meta: {
      title: 'Portas | Five.A Connectix お申し込み | ポルタス',
      description: 'Portas（ポルタス）でのFive.A Connectixお申し込みを行います。',
      keywords: 'Portas,ポルタス,Five.A,Connectix,コネクティクス'
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      try {

        if(!await isTransitionNoLinked(UA_TYPE.FIVE_A)){
          return next({ path: '/platform' });
        }

        const property: Property = store.getters['propertyStore/property'];
        const propertyParams = { propertyId: property.apartmentId, uaType: UA_TYPE.FIVE_A };
        const fiveAProperty: FiveAProperty | FiveASharedErrorResponse = await store.dispatch('fiveACommonStore/property', propertyParams);

        let isAvailableConnectix = false;

        if (fiveAProperty instanceof FiveAProperty) { // 物件基本情報を使う
          // connectix 対象物件かどうか調べる
          if (fiveAProperty.op.sdwan.flag === '1') {
            isAvailableConnectix = true;
          }
        }

        if (fiveAProperty instanceof FiveASharedErrorResponse) {
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.PROPERTY_INFO);
        } else if (isAvailableConnectix) {
          next(); // connectix 対象物件 のとき
          return;
        } else {
          next({ path: '/platform' });
          return;
        }
      } catch (error) {
        throw error;
      }
    }
  },
  {
    path: '/5a/entry/terms',
    name: 'five-a 新規会員登録 サービス規約',
    component: Terms,
    meta: {
      title: 'Portas | Five.A Connectix お申し込み サービス規約 | ポルタス',
      description: 'Portas（ポルタス）でのFive.A Connectixのお申し込みはこちらから行ってください。サービス規約',
      keywords: 'Five.A Connectixお申し込みサービス規約'
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      try {

        const connectixMaintenance: ConnectixMaintenanceInfo =  await store.dispatch('connectixMaintenanceStore/connectixMaintenance', SERVICE_TYPE.FIVE_A);

        if(connectixMaintenance){
          next('/platform/maintenance');
          return;
        }

        if(!await isTransitionNoLinked(UA_TYPE.FIVE_A)){
          return next({ path: '/platform' });
        }

        const property: Property = store.getters['propertyStore/property'];
        const propertyParams = { propertyId: property.apartmentId, uaType: UA_TYPE.FIVE_A };
        const fiveAProperty: FiveAProperty | FiveASharedErrorResponse = await store.dispatch('fiveACommonStore/property', propertyParams);

        let isAvailableConnectix = false;

        if (fiveAProperty instanceof FiveAProperty) { // 物件基本情報を使う
          // connectix 対象物件かどうか調べる
          if (fiveAProperty.op.sdwan.flag === '1') {
            isAvailableConnectix = true;
          }
        }

        if (fiveAProperty instanceof FiveASharedErrorResponse) {
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.PROPERTY_INFO);
        } else if (isAvailableConnectix) {
          next(); // connectix 対象物件 のとき
          return;
        } else {
          next({ path: '/platform' });
          return;
        }
      } catch (error) {
        throw error;
      }
    }
  },
  {
    path: '/5a/entry/input',
    name: 'five-a 新規会員登録 入力',
    component: Input,
    meta: {
      title: 'Portas | Five.A Connectix お申し込み お客様情報入力 | ポルタス',
      description: 'Portas（ポルタス）でのFive.A Connectixのお申し込みはこちらから行ってください。お客様情報入力',
      keywords: 'Five.A Connectixお申し込みお客様情報入力'
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {

      const connectixMaintenance: ConnectixMaintenanceInfo =  await store.dispatch('connectixMaintenanceStore/connectixMaintenance', SERVICE_TYPE.FIVE_A);

      if(connectixMaintenance){
        next('/platform/maintenance');
        return;
      }

      if(!await isTransitionNoLinked(UA_TYPE.FIVE_A)){
        return next({ path: '/platform' });
      }

      // 直前のURLがサービス規約画面などでなければサービス規約画面に遷移する
      if (
        from.path !== '/5a/entry/terms' &&
        from.path !== '/5a/entry/confirm' &&
        from.path !== '/platform/my-page/member-edit' &&
        from.path !== '/platform/my-page/member-completed'
      ) {
        next({ path: '/5a/entry/terms' });
        return;
      }

      next();
    }
  },
  {
    path: '/5a/entry/confirm',
    name: 'five-a 新規会員登録 確認',
    component: Confirm,
    meta: {
      title: 'Portas | Five.A Connectix お申し込み 確認 | ポルタス',
      description: 'Portas（ポルタス）でのFive.A Connectixのお申し込みはこちらから行ってください。確認',
      keywords: 'Five.A Connectixお申し込み確認'
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {

      const connectixMaintenance: ConnectixMaintenanceInfo =  await store.dispatch('connectixMaintenanceStore/connectixMaintenance', SERVICE_TYPE.FIVE_A);

      if(connectixMaintenance){
        next('/platform/maintenance');
        return;
      }

      if(!await isTransitionNoLinked(UA_TYPE.FIVE_A)){
        return next({ path: '/platform' });
      }

      // 直前のURLが入力画面でなければサービス規約画面に遷移する
      if (
        from.path !== '/5a/entry/input' &&
        from.path !== '/platform/my-page/member-edit' &&
        from.path !== '/platform/my-page/member-completed'
      ) {
        next({ path: '/5a/entry/terms' });
        return;
      }

      next();
    }
  },
  {
    path: '/5a/entry/completed',
    name: 'five-a 新規会員登録 完了',
    component: Completed,
    meta: {
      title: 'Portas | Five.A Connectix お申し込み 完了 | ポルタス',
      description: 'Portas（ポルタス）でのFive.A Connectixのお申し込みはこちらから行ってください。完了',
      keywords: 'Five.A Connectix申し込み完了'
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {

      const connectixMaintenance: ConnectixMaintenanceInfo = await store.dispatch('connectixMaintenanceStore/connectixMaintenance', SERVICE_TYPE.FIVE_A);

      if(connectixMaintenance){
        next('/platform/maintenance');
        return;
      }

      if(!await isTransitionNoLinked(UA_TYPE.FIVE_A)){
        return next({ path: '/platform' });
      }

      // 直前のURLが確認画面でなければサービス規約画面に遷移する
      if (from.path !== '/5a/entry/confirm') {
        next({ path: '/5a/entry/terms' });
        return;
      }
      
      next();
    }
  }
];

export default fiveARouter;
