import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router';

import { AuthService } from '@/shared/services/auth/auth-service';

import Index from '@/pages/index.vue';
import Top from '@/pages/top.vue';
import Maintenance from '@/pages/maintenance.vue';
import LoginForwarding from '@/pages/login-forwarding.vue';
import LoginCallback from '@/pages/login-callback.vue';
import Error from '@/pages/error.vue';
import RegisterPortas from '@/pages/register-portas.vue';
import RegisterEMansion from '@/pages/register-e-mansion.vue';
import RegisterUcom from '@/pages/register-ucom.vue';

import platformRouter from './platform';
import eMansionRouter from './e-mansion';
import ucomRouter from './ucom';
import mcloudRouter from './mcloud';
import connectixRouter from './connectix';
import fiveARouter from './five-a';

import NotFound from '@/pages/not-found.vue';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import store from '@/store';
// import LoginCallbackSub from '@/pages/login-callback-sub.vue';
import { Property } from '@/shared/classes/spf-api/property';
import { PortasDBCacheWithStore } from '@/router/before/portas-db-cache-with-store';
import {EMansionCustomer} from "@/shared/classes/external-api/e-mansion/customer-response";
import { NoticeRevisionUpdate } from './before/notice-update';
import { UserScreenTransitionHistoryWithStore } from './before/user-screen-transition-history-with-store';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: Index,
    children: [
      {
        path: '/',
        name: '総合トップ',
        component: Top,
        meta: {
          title: 'Portas | 総合トップページ | ポルタス',
          description:
            'Portas（ポルタス）は、e-mansionやUcom光 レジデンス、Five.Aなどのサービスを展開するアルテリアネットワークス株式会社が提供するインターネットサービスの総合プラットフォームです。',
          keywords: '総合トップページ',
          isPublic: true,
        },
        beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {

          const isLoggedIn = await AuthService.isLoggedIn();

          // meta.isPublic: true なので、beforeEach内で PortasDBCacheWithStore.main() が実行されない
          await store.dispatch('memberStore/member'); // 会員情報を取得
          await store.dispatch('memberStore/memberStatus'); // 会員ステータス情報を取得
          await store.dispatch('oemsStore/oems');

          const member: Member | null = store.getters['memberStore/member'];
          const memberStatus: MemberStatus | null = store.getters['memberStore/memberStatus'];

          if (isLoggedIn && memberStatus && member) {
            return next({ path: '/platform' });
          } else {
            return next();
          }
        },
      },
      {
        path: '/error',
        name: 'エラー画面',
        component: Error,
        meta: {
          title: 'Portas | エラー | ポルタス',
          description: '申し訳ございません。エラーが発生しました。',
          keywords: 'エラー画面',
          isPublic: true,
        },
      },
      // {
      //   path: '/register-portas',
      //   name: 'Portas 会員登録手順',
      //   component: RegisterPortas,
      //   meta: {
      //     title: 'Portas | Portas 会員登録手順 | ポルタス',
      //     description: 'Portas（ポルタス）の会員登録は、こちらの手順に従って行ってください。',
      //     keywords: 'Portas 会員登録手順',
      //     isPublic: true
      //   }
      // },
      // {
      //   path: '/register-e-mansion',
      //   name: 'Portasおよびe-mansion 入会登録手順',
      //   component: RegisterEMansion,
      //   meta: {
      //     title: 'Portas | Portasおよびe-mansion 入会登録手順 | ポルタス',
      //     description: 'Portas（ポルタス）でのe-mansionの入会登録は、こちらの手順に従って行ってください。',
      //     keywords: 'e-mansion 会員登録手順',
      //     isPublic: true
      //   }
      // },
      // {
      //   path: '/register-ucom',
      //   name: 'UCOM光 レジデンス 会員登録手順',
      //   component: RegisterUcom,
      //   meta: {
      //     title: 'Portas | PortasおよびUCOM光 レジデンス 会員登録手順 | ポルタス',
      //     description: 'Portas（ポルタス）でのUCOM光 レジデンスの会員登録は、こちらの手順に従って行ってください。',
      //     keywords: 'UCOM光 レジデンス 会員登録手順',
      //     isPublic: true
      //   }
      // },

      ...platformRouter,
      ...eMansionRouter,
      // ...mcloudRouter,
      ...ucomRouter,
      ...connectixRouter,
      ...fiveARouter,
    ],
  },
  {
    path: '/login-callback',
    name: 'ログイン後コールバック',
    component: LoginCallback,
    meta: {
      isPublic: true,
      title: 'ログイン後コールバック'
    }
  },
  {
    path: '/login-forwarding',
    name: 'アカウント登録踏み台',
    component: LoginForwarding,
    meta: {
      isPublic: true,
      title: 'アカウント登録踏み台'
    }
  },
  {
    path: '/maintenance',
    name: 'メンテナンス',
    component: Maintenance,
    meta: {
      title: 'Portas | メンテナンス | ポルタス',
      description: 'メンテナンス中',
      keywords: 'メンテナンス',
      isPublic: true,
    },
  },
  {
    path: '/',
    component: Index,
    children: [
      // 存在しない URL に遷移した場合 : 必ず Router の最後に記述すること
      {
        path: '*',
        name: 'Not Found',
        component: NotFound,
        meta: {
          title: 'Portas | Not Found | ポルタス',
          description: 'お探しのページは見つかりませんでした。',
          keywords: 'Not Found',
          isPublic: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  /** スクロールの振る舞いを指定 https://router.vuejs.org/ja/guide/advanced/scroll-behavior.html */
  scrollBehavior(to, _from, savedPosition) {
    if (savedPosition) return savedPosition;
    if (to.hash) return { selector: to.hash };
    return { x: 0, y: 0 };
  },
});

/** カードなし滞納有りの場合 */
async function isNoCardInArrears(to: Route): Promise<boolean> {
  if(to.path.endsWith('/no-card-in-arrears')){
    return false;
  }

  const NO_CARD_IN_ARREARS: string = '2'; // カード登録なし滞納
  const customer: EMansionCustomer = await store.getters['eMansionCommonStore/customer'];

  //初回ログイン時等ISPにたどり付けていないユーザは空のため、支払のチェックは出来ない
  if(customer){
    return customer.defaulter_status === NO_CARD_IN_ARREARS;
  }
  return false;

}

router.beforeEach(async (to: Route, _from, next) => {

  // ユーザの画面遷移履歴を蓄積する
  UserScreenTransitionHistoryWithStore.addHistory(to, _from);

  if (await NoticeRevisionUpdate.check()) {
    NoticeRevisionUpdate.notice();
  }

  const isLoggedIn = await AuthService.isLoggedIn();

  if (to.meta?.isPublic) return next(); // 公開ページもしくはログイン済なら通常どおり遷移させる

  if (isLoggedIn) {
    await PortasDBCacheWithStore.main(to);

    if(await isNoCardInArrears(to)){
      return next('/e-mansion/no-card-in-arrears')
    }

    return next();
  } else {
    // connectix系ページについて、connectixメンテナンス中であれば、未ログイン時もメンテナンス画面を表示させる
    const PAGES = ['terms', 'apply', 'confirm', 'completed'];

    const E_MANSION_PATHS = PAGES.map((pages) => '/connectix/e-mansion/' + pages);
    const UCOM_PATHS = PAGES.map((pages) => '/connectix/ucom/' + pages);
    const FIVE_A_PATHS = PAGES.map((pages) => '/connectix/5a/' + pages);

    if (E_MANSION_PATHS.indexOf(to.path) !== -1 || UCOM_PATHS.indexOf(to.path) !== -1 || FIVE_A_PATHS.indexOf(to.path) !== -1) {
      return next();
    } else if (to.path === '/platform/maintenance') {
      return next();
    }
  }

  return next({ path: '/' });
});

export default router;

// e-mansionユーザでない場合画面遷移を制御する
export async function isTransitionEmansion(): Promise<boolean> {
  if (!(await AuthService.getEMansionMemberId())) {
    return false;
  }
  return true;
}

// UCOMユーザでない場合画面遷移を制御する
export async function isTransitionUcom(): Promise<boolean> {
  if (!(await AuthService.getUcomMemberId())) {
    return false;
  }
  return true;
}

// Mcloudユーザでない場合画面遷移を制御する
export async function isTransitionMcloud(): Promise<boolean> {
  if (!(await AuthService.getMcloudMemberId())) {
    return false;
  }
  return true;
}

// 各種サービスが紐づいている場合画面遷移を制御する
export async function isTransitionNoLinked(uaType: string): Promise<boolean> {
  const member: Member = store.getters['memberStore/member'];
  const property: Property = store.getters['propertyStore/property'];
  if (property.uaType !== uaType) {
    return false;
  }
  if (!(await AuthService.getEMansionMemberId()) && !(await AuthService.getUcomMemberId())) {
    return true;
  }
  return false;
}
