





































































import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import { SpfApiHomepageAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-homepage-accessor';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { convertHpCapacityByValue, hasHpCapacityMb } from '@/shared/const/e-mansion/e-mansion-homepage-capacity';
import { EMansionHomepageApplicationUpdateRequest } from '@/infra/request/spf/e-mansion/homepage/e-mansion-homepage-application-update-request';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default Vue.extend({
  name: 'homepage-application-confirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },

  data: () => ({
    isLoading: true,
    /** エラーメッセージを格納する配列 */
    errorMessages: [],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    requestUrl: undefined,
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      this.$data.isLoading = true;
      this.$data.requestUrl = await this.$store.getters['eMansionHomepageOpenStore/requestUrl'];

      // storeに情報が無ければ入力画面に遷移
      if (!this.$data.requestUrl) {
        await this.$router.push('/e-mansion/homepage/application/').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error');
      return;
    }
  },
  methods: {
    async goBack() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // ホームページURLをstoreに保存する
      this.$store.commit('eMansionHomepageOpenStore/requestUrl', this.$data.requestUrl);
      await this.$router.push('/e-mansion/homepage/application').catch((error) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    async goApply() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;

      try {
        const eMansionAccountInfo = this.$store.getters['eMansionAccountStore/eMansionAccountInfo']; // e-mansion アカウント情報取得
        const hpQuota = this.$store.getters['eMansionCommonStore/property'].op.hp.hp_quota; // ホームページ基本容量（50～500）
        // ホームページ容量を容量からID値に変換
        const homepageCapacity = convertHpCapacityByValue(hpQuota)?.id;
        if (!homepageCapacity) {
          throw new Error();
        }
        const now = await SpfApiServerCommonUtilAccessor.getServerTimeNow();

        // AE05-001 ホームページ：お申込み_入力チェックAPIの呼び出し
        const response = await SpfApiHomepageAccessor.callHomepageApplicationUpdate(
          new EMansionHomepageApplicationUpdateRequest({
            _portasId: this.$store.getters['memberStore/member'].id,
            _requestUrl: this.$data.requestUrl,
            _processingDate: now.toLocaleDateString(),
            _eMansionAccountId: eMansionAccountInfo.eMansionAccountInfo.eMansionAccountId,
            _homepageCapacity: homepageCapacity,
          })
        );
        // エラーメッセージがあれば画面に表示する
        if (response.errorMessages.length > 0) {
          this.$data.errorMessages = response.errorMessages;
          window.scrollTo(0, 0);
          this.$data.isLoading = false;
          return;
        }

        // 次画面に値を設定
        this.$store.commit('eMansionHomepageOpenStore/ftpAccount', response.ftpAccount);
        this.$store.commit('eMansionHomepageOpenStore/ftpPassword', response.ftpPassword);
        this.$store.commit('eMansionHomepageOpenStore/ftpServerName', response.ftpServerName);
        this.$store.commit('eMansionHomepageOpenStore/fileDest', response.fileDest);
        this.$store.commit('eMansionHomepageOpenStore/homepageUrl', response.homepageUrl);

        // ホームページ：お申し込み完了画面に遷移する
        await this.$router.push('/e-mansion/homepage/application-complete').catch((error) => {
          checkRouterError(error);
        });
        this.isLoading = false;
      } catch (error) {
        if (error.response?.status !== 503) {
          await this.$router.push('/e-mansion/error');
          return;
        }
      }
    },
  },
});
