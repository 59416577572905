




























































































import Vue from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default Vue.extend({
  name: 'homepageCapacityConfirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    isLoading: false,
    isSlimPlan: false, // スリムプラン該否
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      this.$data.isLoading = true;

      // 会員種別の取得
      const servicePlanType: SERVICE_PLAN_TYPE = this.$store.getters['servicePlanTypeStore/servicePlanType'];

      // スリムプラン会員か否か
      this.$data.isSlimPlan = this.checkSlimPlan(servicePlanType);

      this.$data.isLoading = false;
    } catch (error) {
      this.$router.push('/e-mansion/error');
      return;
    }
  },
  methods: {
    async goTop() {
      if (this.isLoading) {
        return;
      }

      this.$data.isLoading = true;
      // e-mansion 会員専用トップページへ
      await this.$router.push('/e-mansion').catch((error) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    // スリムプラン会員か否かのチェック
    checkSlimPlan(servicePlanType: SERVICE_PLAN_TYPE): boolean {
      return servicePlanType == SERVICE_PLAN_TYPE.EM_SLIM || servicePlanType == SERVICE_PLAN_TYPE.EM_SLIM_SPECIAL;
    },
  },
});
