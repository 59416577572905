/** AE05-001 ホームページ：お申込み_入力チェックAPIの呼び出す際のリクエストデータ */
export class EMansionHomepageApplicationUpdateRequest {
  readonly _portasId!: number;
  readonly _requestUrl!: string;
  readonly _processingDate!: string;
  readonly _eMansionAccountId!: number;
  readonly _homepageCapacity!: string;

  constructor(partial: Partial<EMansionHomepageApplicationUpdateRequest>) {
    Object.assign(this, partial);
  }

  public get portasId(): number {
    return this._portasId;
  }

  public get requestUrl(): string {
    return this._requestUrl;
  }

  public get processingDate(): string {
    return this._processingDate;
  }

  public get eMansionAccountId(): number {
    return this._eMansionAccountId;
  }

  public get homepageCapacity(): string {
    return this._homepageCapacity;
  }
}
