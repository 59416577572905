









































import { ConnectixMaintenanceInfo } from '@/shared/classes/spf-api/connectix-maintenance';
import Vue from 'vue';

type DataType = {
  connectixMaintenanceInfo: ConnectixMaintenanceInfo | null;
  startDateYear: string | null;
  startDateMonth: string | null;
  startDateDay: string | null;
  startTimeHour: string | null;
  startTimeMin: string | null;
  startDayOfWeekStr: string | null;
  endDateYear: string | null;
  endDateMonth: string | null;
  endDateDay: string | null;
  endTimeHour: string | null;
  endTimeMin: string | null;
  endDayOfWeekStr: string | null;
  dayOfWeekStr: [ "日", "月", "火", "水", "木", "金", "土" ],
  isSameDate: boolean,
}

/** エラー画面 */
export default Vue.extend({
  name: 'connectix-maintenace',
  data(): DataType {
    return {
      connectixMaintenanceInfo: null,
      startDateYear: null,
      startDateMonth: null,
      startDateDay: null,
      startTimeHour: null,
      startTimeMin: null,
      startDayOfWeekStr: null,
      endDateYear: null,
      endDateMonth: null,
      endDateDay: null,
      endTimeHour: null,
      endTimeMin: null,
      endDayOfWeekStr: null,
      dayOfWeekStr: [ "日", "月", "火", "水", "木", "金", "土" ],
      isSameDate: false,
    };
  },
  mounted() {
    this.$data.connectixMaintenanceInfo = this.$store.getters['connectixMaintenanceStore/connectixMaintenance'];

    // // 日本時間等の変換が手間なので文字列から取得
    this.$data.startTimeHour = (this.$data.connectixMaintenanceInfo.srartDateTime as string).substring(8,10);
    this.$data.startTimeMin = (this.$data.connectixMaintenanceInfo.srartDateTime as string).substring(10,12);
    this.$data.endTimeHour = (this.$data.connectixMaintenanceInfo.endDateTime as string).substring(8,10);
    this.$data.endTimeMin = (this.$data.connectixMaintenanceInfo.endDateTime as string).substring(10,12);

    this.$data.startDateYear = (this.$data.connectixMaintenanceInfo.srartDateTime as string).substring(0,4);
    this.$data.startDateMonth = (this.$data.connectixMaintenanceInfo.srartDateTime as string).substring(4,6);
    this.$data.startDateDay = (this.$data.connectixMaintenanceInfo.srartDateTime as string).substring(6,8);

    this.$data.endDateYear = (this.$data.connectixMaintenanceInfo.endDateTime as string).substring(0,4);
    this.$data.endDateMonth = (this.$data.connectixMaintenanceInfo.endDateTime as string).substring(4,6);
    this.$data.endDateDay = (this.$data.connectixMaintenanceInfo.endDateTime as string).substring(6,8);

    const startDate = new Date (`${this.$data.startDateYear}/${this.$data.startDateMonth}/${this.$data.startDateDay}`) ;
    const startDayOfWeek = startDate.getDay() ;	// 曜日(数値)
    this.$data.startDayOfWeekStr = this.$data.dayOfWeekStr[startDayOfWeek] ;	// 曜日(日本語表記)
    const endDate = new Date (`${this.$data.endDateYear}/${this.$data.endDateMonth}/${this.$data.endDateDay}`) ;
    const endDayOfWeek = endDate.getDay() ;	// 曜日(数値)
    this.$data.endDayOfWeekStr = this.$data.dayOfWeekStr[endDayOfWeek] ;	// 曜日(日本語表記)

    if(startDate.getTime() === endDate.getTime()){
      this.$data.isSameDate = true;
    }
  }
});
