import {MYE_REGISTER_STATUS} from "@/shared/const/e-mansion/mye-register-status";
import {E_MANSION_ACCOUNT_SUB_DOMAIN} from "@/shared/const/e-mansion/e-mansion-account-sub-domain";
import {E_MANSION_ACCOUNT_AUTHORITY} from "@/shared/const/e-mansion/e-mansion-account-authority";

export class EMansionAccount {
	readonly _eMansionAccountId!: number;
	readonly _memberId!: number;
	readonly _primaryKeyMye!: string;
	readonly _myeRegisterStatus!: MYE_REGISTER_STATUS;
	readonly _accountName!: string;
	readonly _primaryKeyAuth0!: string;
	readonly _initialAccountPassword!: string;
	readonly _accountPassword!: string;
	readonly _accountSubDomain!: E_MANSION_ACCOUNT_SUB_DOMAIN;
	readonly _accountAuthorization!: E_MANSION_ACCOUNT_AUTHORITY;
	readonly _registerSubscriptionOn!: Date | undefined;
	readonly _registerOn!: Date | undefined;
	readonly _registerProcessOn!: Date | undefined;
	readonly _cancelSubscriptionOn!: Date | undefined;
	readonly _cancelOn!: Date | undefined;
	readonly _cancelProcessOn!: Date | undefined;
	readonly _deleteOn!: Date | undefined;
	readonly _isDelete!: boolean;

	constructor(partial: Partial<EMansionAccount>) {
		Object.assign(this, partial);
	}

	get eMansionAccountId(): number {
		return this._eMansionAccountId;
	}

	get memberId(): number {
		return this._memberId;
	}

	get primaryKeyMye(): string {
		return this._primaryKeyMye;
	}

	get myeRegisterStatus(): MYE_REGISTER_STATUS {
		return this._myeRegisterStatus;
	}

	get accountName(): string {
		return this._accountName;
	}

	get primaryKeyAuth0(): string {
		return this._primaryKeyAuth0;
	}

	get initialAccountPassword(): string {
		return this._initialAccountPassword;
	}

	get accountPassword(): string {
		return this._accountPassword;
	}

	get accountSubDomain(): E_MANSION_ACCOUNT_SUB_DOMAIN {
		return this._accountSubDomain;
	}

	get accountAuthorization(): E_MANSION_ACCOUNT_AUTHORITY {
		return this._accountAuthorization;
	}

	get registerSubscriptionOn(): Date | undefined {
		return this._registerSubscriptionOn;
	}

	get registerOn(): Date | undefined {
		return this._registerOn;
	}

	get registerProcessOn(): Date | undefined {
		return this._registerProcessOn;
	}

	get cancelSubscriptionOn(): Date | undefined {
		return this._cancelSubscriptionOn;
	}

	get cancelOn(): Date | undefined {
		return this._cancelOn;
	}

	get cancelProcessOn(): Date | undefined {
		return this._cancelProcessOn;
	}

	get deleteOn(): Date | undefined {
		return this._deleteOn;
	}

	get isDelete(): boolean {
		return this._isDelete;
	}
}
