import axios from 'axios';

import { UcomSharedErrorResponse } from '../../classes/external-api/ucom/shared-error-response';
import { UcomCustomerResponseResult, UcomCustomerResponse } from '../../classes/external-api/ucom/customer-response';
import { UcomPropertyResponseResult, UcomPropertyResponse } from '../../classes/external-api/ucom/property-response';
import { BaseExternalApiService } from './base-external-api-service';

const api = BaseExternalApiService.api(0);

/** 共通利用する UCOM 外部 API をコールするサービス */
export class UcomSharedExternalApiService {
  /**
   * 契約基本情報取得
   *
   * @param customerId 顧客 ID
   * @return 契約基本情報
   */
  public static async findCustomerById(customerId: string): Promise<UcomCustomerResponse | UcomSharedErrorResponse> {
    try {
      const response = await api.get<UcomCustomerResponseResult>(`/customers/${customerId}`);
      return new UcomCustomerResponse(response.data.result);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * 物件基本情報取得
   *
   * @param propertyId 物件 ID
   * @return 物件基本情報
   */
  public static async findPropertyById(propertyId: string): Promise<UcomPropertyResponse | UcomSharedErrorResponse> {
    try {
      const response = await api.get<UcomPropertyResponseResult>(`/properties/${propertyId}`);
      return new UcomPropertyResponse(response.data.result);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * API 通信エラー時のハンドリング
   *
   * @param error エラーオブジェクト
   * @return UCOM API 共通エラーオブジェクト
   */
  private static handleError(error: unknown): UcomSharedErrorResponse {
    if (axios.isAxiosError(error) && !(error instanceof axios.Cancel)) {
      const errorResponse: UcomSharedErrorResponse = error.response?.data;
      if (errorResponse) return new UcomSharedErrorResponse(errorResponse);
    }
    throw error;
  }
}
