



























































































import Vue from 'vue';
import dayjs from 'dayjs';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiAccountInfoForDeleteAccessor } from '@/infra/accessor/spf/ucom/spf-api-account-info-for-delete-accessor';
import { UcomAccountInfoForDelete } from '@/shared/classes/spf-api/mail/model/ucom/account/ucom-account-info-for-delete';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { UCOM_MAIL_ADDRESS_TYPE } from '@/shared/const/ucom/ucom-mail-address-type';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';

type DataType = { isLoading: boolean; ucomAccountInfo: UcomAccountInfoForDelete | undefined };

export default Vue.extend({
  name: 'account-info-for-delete',
  components: {
    LoadingComponent,
  },
  data: (): DataType => ({
    isLoading: true,
    ucomAccountInfo: undefined,
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      const ucomAccountId = this.$store.getters['ucomAccountManagementStore/ucomAccountId'];
      const date = this.$store.getters['ucomAccountManagementStore/deleteOn'];
      if (!ucomAccountId || !date) {
        //アカウントID管理一覧画面へ
        await this.$router.push('/ucom/account-management/list').catch((error) => {
          checkRouterError(error);
        });
        this.$data.isLoading = false;
        return;
      }
      const formedDate = dayjs(date).format('YYYY-MM-DD');
      this.$data.ucomAccountInfo = await SpfApiAccountInfoForDeleteAccessor.getAccountInfo(ucomAccountId, formedDate);
      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/error');
      this.$data.isLoading = false;
      return;
    }
  },

  methods: {
    /** 戻るボタン押下時 */
    async goBack() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      // 削除関連store情報の削除
      this.$store.commit('ucomAccountManagementDeleteStore/clear');
      await this.$router.push('/ucom/account-management/list').catch((error) => {
        checkRouterError(error);
      });
      this.$data.isLoading = false;
    },
    /**削除 確認ボタン押下時 */
    async goDelete() {
      if (this.$data.isLoading) {
        return;
      }
      if (this.$data.ucomAccountInfo == undefined) {
        return;
      }
      this.$data.isLoading = true;
      if (this.$data.ucomAccountInfo.mailAddressType == UCOM_MAIL_ADDRESS_TYPE.UCOM_HIKARI.toString()) {
        const ucomAccountId = this.$store.getters['ucomAccountManagementStore/ucomAccountId'];

        // 解約確認画面からメールアドレス変更画面へのリンク遷移で必要となる
        this.$store.commit('ucomAccountManagementDeleteStore/ucomAccountId', ucomAccountId);
        this.$store.commit('ucomAccountManagementDeleteStore/accountName', this.$data.ucomAccountInfo.accountName);
        this.$store.commit('ucomAccountManagementDeleteStore/mailAddress', this.$data.ucomAccountInfo.mailAddress);
        this.$store.commit('ucomAccountManagementDeleteStore/accountAuthorization', this.$data.ucomAccountInfo.accountAuthorization);
        this.$store.commit('ucomAccountManagementDeleteStore/mailAddressType', this.$data.ucomAccountInfo.mailAddressType);

        /** SU14-013 メールサービス解約確認画面 */
        await this.$router.push('/ucom/account-management/delete-confirmation').catch((error) => {
          checkRouterError(error);
        });
        this.$data.isLoading = false;
      } else {
        /** SU14-014 削除完了画面 */
        const ucomAccountId = this.$store.getters['ucomAccountManagementStore/ucomAccountId'];

        try {
          const deletedUcomAccountInfo = await SpfApiAccountInfoForDeleteAccessor.deleteAccountInfo(ucomAccountId);

          this.$store.commit('ucomAccountManagementDeleteStore/ucomAccountId', ucomAccountId);
          this.$store.commit('ucomAccountManagementDeleteStore/accountName', deletedUcomAccountInfo.accountName);
          this.$store.commit('ucomAccountManagementDeleteStore/fullName', deletedUcomAccountInfo.fullName);
          this.$store.commit('ucomAccountManagementDeleteStore/mailAddress', deletedUcomAccountInfo.mailAddress);
          this.$store.commit('ucomAccountManagementDeleteStore/accountAuthorization', deletedUcomAccountInfo.accountAuthorization);
          this.$store.commit('ucomAccountManagementDeleteStore/isTargetLoginUser', deletedUcomAccountInfo.isTargetLoginUser);
        } catch (error) {
          // 削除関連store情報の削除
          this.$store.commit('ucomAccountManagementDeleteStore/clear');
          await this.$router.push('/error');
          this.$data.isLoading = false;
          return;
        }

        /** SU14-014 削除処理 */
        await this.$router.push('/ucom/account-management/delete-complete').catch((error) => {
          checkRouterError(error);
        });
        this.$data.isLoading = false;
      }
    },
    // ページTOPへ
    scrollPageTop() {
      window.scrollTo(0, 0);
    },
  },
});
