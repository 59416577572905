





















































































































































































































































































































































































































































































































































































































































































































import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { EMansionProperty, EmInfoMessage } from '@/shared/classes/external-api/e-mansion/property-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { UcomCustomerResponse } from '@/shared/classes/external-api/ucom/customer-response';
import { UcomPropertyResponse } from '@/shared/classes/external-api/ucom/property-response';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { MEMBER_STATUS } from '@/shared/const/member-status';
import { ISP_MEMBER_STATUS, UA_TYPE } from '@/shared/const/service-type';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import { getIspMemberStatusUcom } from '@/shared/util/func-get-isp-member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import Vue from 'vue';
import { UcomSharedErrorResponse } from '../../shared/classes/external-api/ucom/shared-error-response';
import PermissionControledRouterLink from '@/shared/components/permission-controled-router-link.vue';
import PermissionControledListLink from '@/shared/components/permission-controled-list-link.vue';
import PermissionControledSpanLink from '@/shared/components/permission-controled-span-link.vue';
import PermissionControledMenuLink from '@/shared/components/permission-controled-menu-link.vue';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { SERVICE_ID } from '@/shared/const/service-ids';

type DataType = {
  ScrollHint: any;
  customer: UcomCustomerResponse | null;
  property: UcomPropertyResponse | null;
  emInfoMessages: Array<EmInfoMessage>;
  connectixLpUrl: string;
  ucomConnectixUrl: string;
  isApplyConnectix: boolean;
  apid: string;
  ucomUrl: string;
  ucomMyUrl: string;
  isContractConnectixFlag: boolean;
  ispMemberStatusUcom: string;
  isDisplayOnlyBillingDetails: boolean;
  billingDetailsURL: string;
  canPurchaseMailPack: boolean;
  planType: SERVICE_PLAN_TYPE | undefined;
  functionIdContractAccountInfo: string;
  functionIdChangeCustomerInfo: string;
  functionIdChangeCustomerPassword: string;
  functionIdChangeCardInfo: string;
  functionIdMonthlyBillInquiry: string;
  functionIdOptionServices: string;
  functionIdConnectixApplication: string;
  functionIdAccountManagement: string;
  functionIdAccountList: string;
  functionIdMailServicePurchase: string;
  functionIdPropertyInfo: string;
};

/** 「UCOM サービストップ」画面 */
export default Vue.extend({
  name: 'ucom-index',
  components: {
    PermissionControledRouterLink,
    PermissionControledListLink,
    PermissionControledSpanLink,
    PermissionControledMenuLink,
  },
  data(): DataType {
    return {
      ScrollHint: require('../../shared/libraries/scroll-hint/scroll-hint'),
      property: null,
      emInfoMessages: [],
      customer: null,
      connectixLpUrl: process.env.VUE_APP_UCOM_LP_URL,
      ucomConnectixUrl: process.env.VUE_APP_UCOM_CONNECTIX_SERVICE_URL,
      isApplyConnectix: false,
      /** 物件APID */
      apid: '',
      /** UCOM の URL */
      ucomUrl: process.env.VUE_APP_UCOM_URL,
      /** UCOM の MY URL */
      ucomMyUrl: process.env.VUE_APP_UCOM_MY_URL,
      /** Connectix契約済み/未契約フラグ */
      isContractConnectixFlag: false,
      // ISP(ucom)会員ステータス
      ispMemberStatusUcom: '',
      // ISP(ucom)会員ステータスが「退会ログイン可能」のとき表示するご請求明細・販売証明書を表示するかどうか
      isDisplayOnlyBillingDetails: false,
      // ISP(ucom)会員ステータスが「退会ログイン可能」のとき表示するご請求明細・販売証明書表示URL
      billingDetailsURL: process.env.VUE_APP_UCOM_BILLING_DETAILS_URL,
      /** メールパック購入可否 */
      canPurchaseMailPack: false,
      /** アカウント種別 */
      planType: undefined,

      // UCOM ご契約情報一覧 の機能ID
      functionIdContractAccountInfo: SERVICE_ID.UCOM.LINK_UCOM_CONTRACT_INFO_LIST,
      // UCOM お客様情報変更 の機能ID
      functionIdChangeCustomerInfo: SERVICE_ID.UCOM.LINK_UCOM_GUEST_INFO_CHANGE,
      // UCOM お客様番号パスワード変更 の機能ID
      functionIdChangeCustomerPassword: SERVICE_ID.UCOM.LINK_UCOM_GUEST_NUMBER_PASSWORD_CHANGE,
      // UCOM お支払方法変更 の機能ID
      functionIdChangeCardInfo: SERVICE_ID.UCOM.LINK_UCOM_PAYMENT_METHODS_CHANGE,
      // UCOM 月額請求明細照合 の機能ID
      functionIdMonthlyBillInquiry: SERVICE_ID.UCOM.LINK_UCOM_MONTHLY_BILL_INQUIRY,
      // UCOM UCOM光レジデンス 各種オプションサービスのお申込み・変更はこちらから の機能ID
      functionIdOptionServices: SERVICE_ID.UCOM.LINK_UCOM_OPTION_SERVICES,
      // UCOM Connectix お申し込み・解約 の機能ID
      functionIdConnectixApplication: SERVICE_ID.UCOM.LINK_CONNECTIX_APPLICATION_CANSEL,
      // UCOM アカウントID管理 の機能ID
      functionIdAccountManagement: SERVICE_ID.UCOM_ACCOUNT_ID_MANAGEMENT,
      // UCOM アカウントID情報一覧 の機能ID
      functionIdAccountList: SERVICE_ID.UCOM_ACCOUNT_ID_OPTION_CHANGE,
      // UCOM メールサービス購入 の機能ID
      functionIdMailServicePurchase: SERVICE_ID.UCOM_MAIL_SERVICE_PURCHASE,
      // UCOM お住まいの「建物専用サイト」はこちら の機能ID
      functionIdPropertyInfo: SERVICE_ID.UCOM.LINK_UCOM_BUILDING_ONLY_SITE,
    };
  },
  async mounted() {
    try {
      const isLoggedIn = await AuthService.isLoggedIn();
      // ログインしていない場合「総合トップ」画面にリダイレクトする
      if (!isLoggedIn) {
        this.$router.push('/').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      /** 会員情報を取得 */
      const member: Member = this.$store.getters['memberStore/member'];
      /** 会員の物件IDに紐づく物件を取得 */
      const memberProperty = await SpfApiService.findPropertyById(member.propertyId!);
      /** 物件テーブルに UCOM物件ID および Mye物件ID が含まれていなければエラー画面 */
      if (!memberProperty.buildingId || !memberProperty.apartmentId) {
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.UCOM.NO_BUILDING_ID);
      }
      this.apid = this.toApid(memberProperty.apartmentId);

      /** 契約基本情報をStoreから取得 */
      const customer = await this.$store.dispatch('ucomCommonStore/customer', member.primaryKeyUcom);
      if (customer instanceof UcomCustomerResponse) {
        // データ設定
        this.customer = customer;
        // Connectixの判定
        this.$data.isApplyConnectix = customer.connectix_offer;
        this.$data.isContractConnectixFlag = this.customer.connectix_status;

        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusUcom = getIspMemberStatusUcom(memberStatus, customer);
        this.isDisplayOnlyBillingDetails = this.getFlagDisplayOnlyBillingDetails(memberStatus);
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.CONTRACTOR_INFO);
      }

      // 顧客に物件が紐付いていない場合エラーとする
      if (!member.propertyId) {
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_PROPERTY);
      }

      /** 物件基本情報をStoreから取得 */
      const propertyInfo: UcomPropertyResponse | UcomSharedErrorResponse = await this.$store.dispatch('ucomCommonStore/property', memberProperty.buildingId);
      if (propertyInfo instanceof UcomPropertyResponse) {
        // データ設定
        this.property = propertyInfo;
      } else {
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.PROPERTY_INFO);
      }

      // e-mansion 物件基本情報を Store から取得（重複利用は生じないので e-mansion の Store をそのまま使用する）
      const propertyParams = { apartmentId: memberProperty.apartmentId, uaType: UA_TYPE.UCOM };
      const eMansionProperty: EMansionProperty | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/property', propertyParams);
      if (eMansionProperty instanceof EMansionProperty) {
        if (eMansionProperty.em_info_message !== null) {
          this.emInfoMessages = eMansionProperty.em_info_message;
        }
      } else {
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.E_MANSION_API_PROPERTY_INFO);
      }

      // メールパック購入可否を API から取得する
      this.canPurchaseMailPack = this.$store.getters['ucomAccountStore/ucomAccount'].isEmailPackPurchaseEnabled;

      // アカウント種別を取得
      this.planType = this.$store.getters['servicePlanTypeStore/servicePlanType'];
    } catch (error) {
      throw error;
    }

    this.$nextTick(() => {
      this.jsScrollAble();
    });
    this.$emit('isMemberTopMountingUcom', false); // pages/index.vue 宛てにmounted終了を通知
  },
  methods: {
    /** APID変換 */
    toApid(apid: string): string {
      return convert6DigitIdTo8DigitId(apid);
    },
    getKindString(kind: string): string {
      let kindStr = '';
      switch (kind) {
        case '01':
          kindStr = 'お知らせ';
          break;
        case '02':
          kindStr = 'キャンペーン';
          break;
        case '03':
          kindStr = 'メンテナンス';
          break;
        case '04':
          kindStr = '障害';
          break;
        case '05':
          kindStr = '障害「マンション全体」';
          break;
        case '06':
          kindStr = '障害「マンション一部」';
          break;
        default:
          kindStr = 'お知らせ';
          break;
      }
      return kindStr;
    },
    jsScrollAble() {
      new this.ScrollHint('.js-scrollable');
      new this.ScrollHint('.js-scrollable-shadow', {
        suggestiveShadow: true,
      });
    },
    /**
     * ISP(ucom)会員ステータスが「退会ログイン可能」のとき表示するご請求明細・販売証明書を表示するかどうか
     *
     * | isDisplayOnlyBillingDetails | isp-member-status | portas-member-status |
     * | true                        | cancelAndLoginOK  | 5                    |
     * | true                        | cancelAndLoginOK  | 7                    |
     */
    getFlagDisplayOnlyBillingDetails(memberStatus: MemberStatus) {
      const isExpectedPortasMemberStatus = [MEMBER_STATUS.PROPERTY_LINKED, MEMBER_STATUS.IN_CANCEL_APPLICATION].some((value) => value === memberStatus.status);

      if (isExpectedPortasMemberStatus && this.ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_OK) {
        return true;
      }
      return false;
    },
  },
  computed: {
    /** アカウント種別が「お客様番号権限」または「管理者権限」である */
    isCustomerNumberOrAdmin(): boolean {
      return this.planType === SERVICE_PLAN_TYPE.UC_CUSTOMER_NO || this.planType === SERVICE_PLAN_TYPE.UC_ADMIN;
    },
  },
});
