import { AuthService } from '../auth/auth-service';

export class SubAccountRoutingService {
  /**
   * ログインコールバック時の、追加アカウントのユーザーの遷移先パスを取得する
   *
   * @param from URLのクエリパラメータfrom
   * @returns 遷移先パス
   */
  public static async getNextPathOnCallback(from: string | undefined): Promise<string> {
    if (await AuthService.getEMansionLoginId()) {
      return '/e-mansion';
    }
    if (await AuthService.getUcomAccountName()) {
      return '/ucom';
    }

    const FROM_EMANSION = 'emansion';
    const FROM_UCOM = 'ucom-r';

    if (from === FROM_EMANSION) {
      return '/e-mansion/sub-account-linking';
    }
    if (from === FROM_UCOM) {
      return '/ucom/sub-account-linking';
    }
    throw new Error('遷移先が不明です');
  }
}
