
























































































































































































































































































































































































































































































import Vue from 'vue';

/** Entity UCOM 共通利用 API : 契約基本情報取得 : レスポンス */
import { UcomCustomerResponse } from '../../../shared/classes/external-api/ucom/customer-response';
/** Entity UCOM 共通利用 API : 物件基本情報取得 : レスポンス */
import { UcomPropertyResponse } from '../../../shared/classes/external-api/ucom/property-response';

/** エラーメッセージ用コンポーネント */
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { Member } from '@/shared/classes/spf-api/member';
import { AuthService } from '@/shared/services/auth/auth-service';
import { UcomSharedErrorResponse } from '@/shared/classes/external-api/ucom/shared-error-response';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { getIspMemberStatusUcom } from '@/shared/util/func-get-isp-member-status';
import { ISP_MEMBER_STATUS } from '@/shared/const/service-type';

/** UCOM光レジデンス Connectixお申し込み サービス規約画面 */
export default Vue.extend({
  name: 'ucom-connectix-terms',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    LoadingComponent
  },
  data: () => ({
    /** サービス利用規約に同意するチェック、お申し込みボタン 表示/非表示 */
    availableApply: true,
    /** サービス利用規約に同意する */
    isAgreeTerms: false,
    /** エラーメッセージを格納する配列 */
    errorMessages: [],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、お申し込み手続きを進めることができません。',
    /** UCOM 物件 ID */
    ucomPropertyId: '',
    /** UCOM 会員 ID */
    ucomMemberId: '',
    // vueのmounted実行中かどうか
    isMounting: true,
    // Connectixサービスイン前エラー用に1文エラーにするかどうか
    isErrorMessageOneSentence: false,
    // ISP(ucom)会員ステータス
    ispMemberStatusUcom: ''
  }),
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    // 入力値を保持しているストアの削除
    this.$store.commit('ucomConnectixEntryStore/entryInputForm', null);

    /** UCOM の会員であるかの条件を確認 */
    try {
      /** ログインしているか否かの情報を取得 */
      const isLoggedIn = await AuthService.isLoggedIn();
      // ログインしていない場合「総合トップ」画面にリダイレクトする
      if (!isLoggedIn) {
        this.$router.push('/').catch(error => { checkRouterError(error) });
        return;
      }
      // // 会員情報をStoreから取得
      const member: Member = this.$store.getters['memberStore/member'];
      // // 会員情報（member テーブル）の primary_key_ucom が null の場合は UCOM  会員ではないと判断
      if (!member.primaryKeyUcom) {
        this.$router.push('/platform').catch(error => { checkRouterError(error) });
        return;
      }
    } catch (error) {
      throw error;
    }

    /** UCOM 契約基本情報を取得 */
    try {
      // UCOM のメンバー ID をStoreから取得する
      this.$data.ucomMemberId = await AuthService.getUcomMemberId();
      const customer: Promise<UcomCustomerResponse | UcomSharedErrorResponse> = await this.$store.dispatch('ucomCommonStore/customer', this.$data.ucomMemberId);

      // 正常ケースの場合
      if (customer instanceof UcomCustomerResponse) {

        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusUcom = getIspMemberStatusUcom(memberStatus, customer);

        /**
         * ISP(ucom)会員ステータスが次の2つの場合、エラーメッセージを表示する
         *
         * 1. ISP(ucom)会員ステータス: 退会申し込み
         * 2. ISP(ucom)会員ステータス: 退会済みログイン可能
         */
        if (this.ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.IN_CANCEL_APPLICATION
            || this.ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_OK) {
          this.availableApply = false; // 利用規約同意チェックとお申し込みボタン使用不可
          this.isErrorMessageOneSentence = true;
          this.$data.errorMessages.push('Connectixのお申し込みは現在承ることができません。');
          window.scrollTo(0, 0);
          return;
        }

        // Connectixにお申し込み済みかどうかのチェック
        if (customer.connectix_status) {
          this.$data.availableApply = false; // 利用規約同意チェックとお申し込みボタン使用不可
          this.$data.errorMessages.push('お客様は既にConnectixを契約しています。');
          window.scrollTo(0, 0); // エラーメッセージを見せるために画面最上部にスクロール
          return;
        }
        if (!customer.connectix_offer) {
          this.$data.availableApply = false; // 利用規約同意チェックとお申し込みボタン使用不可
          this.$data.errorMessages.push('お客様の物件はConnectixのお申し込みを承ることができません。');
          window.scrollTo(0, 0); // エラーメッセージを見せるために画面最上部にスクロール
          return;
        }
      } else if (customer instanceof UcomSharedErrorResponse) {
        // APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.CONTRACTOR_INFO);
      }
    } catch (error) {
      throw error;
    } finally {
      this.isMounting = false;
    }

    /** UCOM 物件基本情報を取得 */
    try {
      this.isMounting = true;

      // Auth0ユーザのapp_metadataからUCOMの物件IDを取得する
      this.$data.ucomPropertyId = await AuthService.getUcomPropertyId();

      const property: Promise<UcomPropertyResponse | UcomSharedErrorResponse> = await this.$store.dispatch('ucomCommonStore/property', this.$data.ucomPropertyId);
      if (property instanceof UcomPropertyResponse) {
        // 正常ケースの場合
        let start_date = new Date(property.connectix_start_date);
        let today = new Date();
        // Connectixの提供開始日が未来日付であるかチェック
        if (today < start_date) {
          // 利用規約同意チェックとお申し込みボタン使用不可
          this.$data.availableApply = false;
          this.isErrorMessageOneSentence = true;
          this.$data.errorMessages.push('Connectixのお申し込みは現在承ることができません。');
        }
      } else if (property instanceof UcomSharedErrorResponse) {
        // APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.PROPERTY_INFO);
      }
    } catch (error) {
      throw error;
    } finally {
      this.isMounting = false;
    }
  },
  methods: {
    /** 「規約に同意してお申し込み ➡」ボタン押下時 : お申し込み画面に遷移する */
    async onApplication() {
      // エラーメッセージを初期化
      this.$data.errorMessageTitle = '';
      this.$data.errorMessages = [];
      this.isErrorMessageOneSentence = false;

      // ISP(ucom)側で退会申し込み中のとき、エラーメッセージを表示させる
      if (await this.isInCancelApplication()) {
        this.isErrorMessageOneSentence = true;
        this.$data.errorMessages.push('Connectixのお申し込みは現在承ることができません。');
        window.scrollTo(0, 0);
        return;
      }

      // 「サービス利用規約に同意する」がチェックされている場合はお申し込み画面に遷移する。
      if (this.$data.isAgreeTerms) {
        this.$router.push('/connectix/ucom/apply').catch(error => { checkRouterError(error) });
      } else {
        this.$data.errorMessages.push('「Connectix サービス規約に同意する」にチェックを入れてください。');
      }
    },
    async isInCancelApplication() {
      try {
        this.$data.ucomMemberId = await AuthService.getUcomMemberId();
        const customer: Promise<UcomCustomerResponse | UcomSharedErrorResponse> = await this.$store.dispatch('ucomCommonStore/customer', this.$data.ucomMemberId);

        if (customer instanceof UcomCustomerResponse) {

          // ISP会員ステータス取得
          const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
          this.ispMemberStatusUcom = getIspMemberStatusUcom(memberStatus, customer);

          /**
           * ISP(ucom)会員ステータスが次の2つの場合、ISP退会申し込み中であると判定する
           *
           * 1. ISP(ucom)会員ステータス: 退会申し込み
           * 2. ISP(ucom)会員ステータス: 退会済みログイン可能
           */
          if (this.ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.IN_CANCEL_APPLICATION
              || this.ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_OK) {
            return true;
          }
        } else if (customer instanceof UcomSharedErrorResponse) {
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.CONTRACTOR_INFO);
        }
      } catch (e) {
        throw e;
      }
      return false;
    }
  },
  computed: {
    buttonColorSet() {
      if (this.availableApply && this.isAgreeTerms) {
        return 'btn btn01 bs'
      }
      return 'btn btn04 bs';
    },
    activeApplyButton() {
      if (this.availableApply && this.isAgreeTerms) {
        return true;
      }
      return false;
    }
  }
});
