


































































import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { EMansionAccountInfo } from '@/shared/classes/spf-api/mail/model/account/e-mansion-account-info';

export default Vue.extend({
  name: 'complete',
  components: {
    LoadingComponent,
  },

  data: () => ({
    isLoading: true,
    newMailAddressAddDomain: '',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      //Storeからメールアドレスを取得
      this.$data.newMailAddressAddDomain = this.$store.getters['eMansionMainMailAddressStore/newMailAddressAddDomain'];
      if(!this.$data.newMailAddressAddDomain) {
        // 現在のメールアドレスを取得(共通情報より取得)
        const accountInfo: EMansionAccountInfo = await this.$store.getters['eMansionAccountStore/eMansionAccountInfo'];
        this.$data.newMailAddressAddDomain = accountInfo.eMansionMailAddressInfo?.mailAddress;
      }

      //Storeをクリアする
      this.$store.commit('eMansionMainMailAddressStore/mailAddress', null);
      this.$store.commit('eMansionMainMailAddressStore/newMailAddress', null);
      this.$store.commit('eMansionMainMailAddressStore/newMailAddressAddDomain', null);

      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error');
      return;
    }
  },
  methods: {
    async goList() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      await this.$router.push('/e-mansion/main-mail-address/list');
      this.$data.isLoading = false;
    },
    async goTop() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      await this.$router.push('/e-mansion');
      this.$data.isLoading = false;
    },
  },
});
