
















































































import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default Vue.extend({
  name: 'homepage-cancel-complete',
  components: {
    LoadingComponent,
  },

  data: () => ({
    isLoading: true,
    /** e-mansion サービス終了日 */
    serviceEndDate: undefined,
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      this.$data.isLoading = true;

      const serviceEndDate = await this.$store.getters['eMansionHomepageOpenStore/serviceEndDate'];
      // storeにデータが無ければ、利用状況/解約画面に遷移する
      if (!serviceEndDate) {
        await this.$router.push('/e-mansion/homepage/usage').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      this.$data.serviceEndDate = new Date(serviceEndDate).toLocaleDateString();

      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error');
      return;
    }
  },
  methods: {
    async goTop() {
      if (this.isLoading) {
        return;
      }

      this.$data.isLoading = true;
      // e-mansion 会員専用トップページへ
      await this.$router.push('/e-mansion').catch((error) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
  },
});
