



































































































































































































































































import dayjs from 'dayjs';
import Vue from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import PermissionControledRouterLink from '@/shared/components/permission-controled-router-link.vue';

import { EMansionMbCapacityDto, EMansionVirusCheckDto, MbCapacityLabel, SUBSCRIPTION_STATUS, SubscriptionStatus } from '@/shared/classes/spf-api/e-mansion-mail';
import { SpfApiVirusCheckAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-virus-check-accessor';
import { SpfApiMailBoxAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-mail-box-accessor';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { hasEMansionOriginalDomain } from '@/shared/const/e-mansion';
import { SERVICE_ID } from '@/shared/const/service-ids';

/** メールアドレスごとの情報を表示用にまとめたもの */
interface MailAddressInfo {
  mailAddressId: number; // e-mansion_メールアドレスID
  mailAddress: string; // メールアドレス
  virusCheck: {
    // ウィルスチェックに関する情報
    subscriptionStatusDisplay: string; // 契約ステータス (表示用)
    registerSubscriptionOn: Date | null; // 申込日
    serviceInTime: Date | null; // サービスイン日
    subscriptionStatus: SubscriptionStatus; // 契約ステータス (値)
    cancelOn: Date | null; // サービス終了日
  };
  capacity: {
    // メールボックス容量に関する情報
    amount: MbCapacityLabel; // メールボックス容量 (表示文字列)
    canChange: boolean; // 変更可否
  };
}

export default Vue.extend({
  name: 'mail-usage',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
    PermissionControledRouterLink,
  },

  data(): {
    isBusy: boolean;
    virusCheck: EMansionVirusCheckDto | undefined;
    mbCapacity: EMansionMbCapacityDto | undefined;
    errorMessageTitle: string;
    errorMessages: string[];
    planType: SERVICE_PLAN_TYPE | undefined;
    isMailMember: boolean;
    hasOriginalDomain: boolean;
    functionIdMainMail: string;
    functionIdAddAccount: string;
  } {
    return {
      isBusy: false,
      virusCheck: undefined,
      mbCapacity: undefined,
      errorMessageTitle: '',
      errorMessages: [],
      planType: undefined,
      isMailMember: false,
      hasOriginalDomain: false,
      functionIdMainMail: SERVICE_ID.MAIN_MAIL_ADDRESS_USAGE,
      functionIdAddAccount: SERVICE_ID.MAIL_ACCOUNT_ADDITION,
    };
  },

  async mounted() {
    // 会員種別を取得する
    this.planType = this.$store.getters['servicePlanTypeStore/servicePlanType'];
    if (this.planType === undefined) {
      await this.$router.push('/e-mansion/error');
    }

    // メール会員フラグを取得する
    // TODO : 取得の仕方はこれでよいか確認が必要 (会員専用トップと同様)
    const customer: EMansionCustomer | EMansionSharedErrorResponse = this.$store.getters['eMansionCommonStore/customer'];
    if (!(customer instanceof EMansionCustomer)) {
      await this.$router.push('/e-mansion/error');
    }
    this.isMailMember = (customer as EMansionCustomer).email_member_flag === '1';
    this.isMailMember = false; // TODO : true になってしまうのでダミー値をセットしている。この行は削除すること

    await this.initVirusCheck();
    await this.initMbCapacity();
    await this.initHasOriginalDomain();
  },

  methods: {
    /**
     * ウィルスチェック利用状況を API から取得して初期化する
     */
    async initVirusCheck(): Promise<void> {
      try {
        this.virusCheck = await SpfApiVirusCheckAccessor.findEMansionVirusCheck();
      } catch (error) {
        await this.$router.push('/e-mansion/error');
      }
    }, // initVirusCheck

    /**
     * メールボックス容量を API から取得して初期化する
     */
    async initMbCapacity() {
      try {
        this.mbCapacity = await SpfApiMailBoxAccessor.findEMansionMbCapacity();
      } catch (error) {
        await this.$router.push('/e-mansion/error');
      }
    }, // initMbCapacity

    /**
     * 独自ドメイン有無を物件基本情報から取得して初期化する
     */
    async initHasOriginalDomain() {
      const property: EMansionProperty = this.$store.getters['eMansionCommonStore/property'];
      if (!property) {
        await this.$router.push('/e-mansion/error');
        return;
      }
      this.hasOriginalDomain = hasEMansionOriginalDomain(property.em_op_mail_flag);
    }, // initHasOriginalDomain

    /**
     * ウィルスチェックの「申し込む」ボタンを表示するかどうかを返す
     * @param mailAddressInfo メールアドレスごとの情報
     * @return 「申し込む」ボタンを表示するかどうか
     */
    shouldShowApplyButton(mailAddressInfo: MailAddressInfo): boolean {
      return !mailAddressInfo.virusCheck.subscriptionStatus;
    }, // shouldShowApplyButton

    /**
     * ウィルスチェックの「申し込む」(非表示) ボタンを表示するかどうかを返す
     * @param mailAddressInfo メールアドレスごとの情報
     * @return 「申し込む」(非表示) ボタンを表示するかどうか
     */
    shouldShowInvisibleApplyButton(mailAddressInfo: MailAddressInfo): boolean {
      if (this.shouldShowCancelButton(mailAddressInfo)) {
        return false;
      }
      return mailAddressInfo.virusCheck.subscriptionStatus === SUBSCRIPTION_STATUS.APPLYING;
    }, // shouldShowInvisibleApplyButton

    /**
     * ウィルスチェックの「解約」ボタンを表示するかどうかを返す
     * @param mailAddressInfo メールアドレスごとの情報
     * @return 「解約」ボタンを表示するかどうか
     */
    shouldShowCancelButton(mailAddressInfo: MailAddressInfo): boolean {
      // ウィルスチェックが契約済みの場合
      if (mailAddressInfo.virusCheck.subscriptionStatus === SUBSCRIPTION_STATUS.APPLIED) {
        return true;
      }
      // ウィルスチェックが契約申し込み中でサービスイン日が未来日の場合
      const serviceInTime = mailAddressInfo.virusCheck.serviceInTime;
      const now = new Date();
      if (serviceInTime && mailAddressInfo.virusCheck.subscriptionStatus === SUBSCRIPTION_STATUS.APPLYING && serviceInTime.getTime() > now.getTime()) {
        return true;
      }
      return false;
    }, // shouldShowCancelButton

    /**
     * ウィルスチェックのサービス終了日を表示するかどうかを返す
     * @param mailAddressInfo メールアドレスごとの情報
     * @return サービス終了日を表示するかどうか
     */
    shouldShowExpiration(mailAddressInfo: MailAddressInfo): boolean {
      return mailAddressInfo.virusCheck.subscriptionStatus === SUBSCRIPTION_STATUS.CANCELLING;
    }, // shouldShowExpiration

    /**
     * Date 型を YYYY/MM/DD に変換する
     * @param date 変換元の日時
     * @return 変換先の文字列
     */
    formatDateToYyyymmdd(date: Date | null): string {
      return date ? dayjs(date).format('YYYY/MM/DD') : '';
    },

    /**
     * ウィルスチェックの「申し込む」のクリックハンドラ
     * @param mailAddressInfo メールアドレスごとの情報
     */
    applyVirusCheck(mailAddressInfo: MailAddressInfo): void {
      // 処理対象のメールアドレスID, メールアドレス, 利用料情報を Store に保存する
      this.$store.commit('eMansionMailStore/setSelectedMailAddressId', mailAddressInfo.mailAddressId);
      this.$store.commit('eMansionMailStore/setSelectedMailAddress', mailAddressInfo.mailAddress);
      this.$store.commit('eMansionMailStore/setSelectedFeeInfo', this.virusCheck?.feeInfo);
      // 確認 (申込) 画面に遷移する
      this.$router.push('/e-mansion/mail/virus-check-apply-confirm');
    },

    /**
     * ウィルスチェックの「解約」のクリックハンドラ
     * @param mailAddressInfo メールアドレスごとの情報
     */
    cancelVirusCheck(mailAddressInfo: MailAddressInfo): void {
      // メールアドレスID, メールアドレスを Store に保存する
      this.$store.commit('eMansionMailStore/setSelectedMailAddressId', mailAddressInfo.mailAddressId);
      this.$store.commit('eMansionMailStore/setSelectedMailAddress', mailAddressInfo.mailAddress);
      // 確認 (解約) 画面に遷移する
      this.$router.push('/e-mansion/mail/virus-check-cancel-confirm');
    },

    /**
     * メールボックス容量変更の「申し込む」のクリックハンドラ
     * @param mailAddressInfo メールアドレスごとの情報
     */
    async applyMbCapacityChange(mailAddressInfo: MailAddressInfo): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isBusy = true;
      try {
        const response = await SpfApiMailBoxAccessor.updateMbCapacity(mailAddressInfo.mailAddressId);
        if (response.errorMessage) {
          // TODO : エラーの表示方法については問合せ中。
          this.errorMessages = [response.errorMessage];
        } else {
          await this.initMbCapacity();
        }
      } catch (error) {
        await this.$router.push('/e-mansion/error');
      }
      this.isBusy = false;
    },
  }, // methods

  computed: {
    /** ローディング中かどうかの判定 */
    isLoading(): boolean {
      return this.virusCheck === undefined || this.mbCapacity === undefined || this.isBusy;
    },

    /** 表示するメールアドレスがあるかどうかの判定 */
    hasMailAddresses(): boolean {
      if (this.mailAddressInfos?.length) {
        return true;
      }
      return false;
    },

    /** メールアドレスごとの情報を表示用にまとめたもの */
    mailAddressInfos(): MailAddressInfo[] | null {
      const infos: MailAddressInfo[] | null =
        this.virusCheck?.data.map((virusCheck) => {
          // ウィルスチェック利用状況と同じ emailAddressId の メールボックス容量
          const mbCapacity = this.mbCapacity?.data.find((mbCapacity) => {
            return mbCapacity.emailAddressId === virusCheck.emailAddressId;
          });
          // ウィルスチェック利用状況とメールボックス容量を合わせて1個の要素にする
          const mailAddressInfo: MailAddressInfo = {
            mailAddressId: virusCheck.emailAddressId,
            mailAddress: this.hasOriginalDomain ? virusCheck.emailAddressOriginal : virusCheck.emailAddress,
            virusCheck: {
              subscriptionStatusDisplay: virusCheck.subscriptionStatusDisplay,
              registerSubscriptionOn: virusCheck.registerSubscriptionOn ? new Date(virusCheck.registerSubscriptionOn) : null,
              serviceInTime: virusCheck.serviceInTime ? new Date(virusCheck.serviceInTime) : null,
              subscriptionStatus: virusCheck.subscriptionStatus,
              cancelOn: virusCheck.cancelOn ? new Date(virusCheck.cancelOn) : null,
            },
            capacity: {
              amount: mbCapacity?.emailCapacity ?? '',
              canChange: !this.isMailMember && (mbCapacity?.canChangeCapacity ?? false),
            },
          };
          return mailAddressInfo;
        }) ?? null;
      return infos;
    }, // mailAddressInfos

    /** TTP またはスリムプラン (特例型を含む) であることを判定する */
    isTtpOrSlimPlan(): boolean {
      return this.planType === SERVICE_PLAN_TYPE.EM_TTP || this.planType === SERVICE_PLAN_TYPE.EM_SLIM || this.planType === SERVICE_PLAN_TYPE.EM_SLIM_SPECIAL;
    },
  }, // computed
});
