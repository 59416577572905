

































































































import Vue from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiMailboxCapacityAccessor } from '@/infra/accessor/spf/ucom/spf-api-mailbox-capacity-accessor';
import { UpdateUcomMbCapacityRequest } from '@/infra/request/spf/ucom/mailbox-capacity/update-ucom-mb-capacity-request';
import { UcomMailboxCapacityConfirmInfo } from '@/shared/classes/spf-api/mail/model/ucom/mailbox-capacity/ucom-mailbox-capacity-confirm-info';

export default Vue.extend({
  name: 'mailbox-capacity-confirm',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },
  data: () => ({
    ucomAccountMailId: '', // UCOM_アカウントメールID
    accountName: '', // アカウント名
    mailAddress: '', // メールアドレス
    mailBoxCapacity: '', // メールボックス容量
    changeCapacityLabel: '', // 追加／縮小容量ラベル
    changeCapacityValue: '', // 追加／縮小容量バリュー
    price: '', // 単価
    effectiveDate: '', // 申込日
    serviceDescription: '', // サービス説明
    errorMessages: [], // エラーメッセージを格納する配列
    errorMessageTitle: '恐れ入りますが、内容をもう一度ご確認ください。', // エラーメッセージ部タイトル
    isLoading: false, //ローディング
  }),
  async mounted() {
    try {
      this.$data.isLoading = true;

      // 前画面からの情報をStoreから取得し画面データをセット
      await this.setDataByStore();

      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/error');
      return;
    }
  },
  methods: {
    // storeから画面項目データをセット
    async setDataByStore(): Promise<void> {
      this.$data.ucomAccountMailId = this.$store.getters['ucomMailboxCapacityStore/ucomAccountMailId'];
      this.$data.accountName = this.$store.getters['ucomMailboxCapacityStore/accountName'];
      this.$data.mailAddress = this.$store.getters['ucomMailboxCapacityStore/mailAddress'];
      this.$data.mailBoxCapacity = this.$store.getters['ucomMailboxCapacityStore/mailBoxCapacity'];
      this.$data.changeCapacityLabel = this.$store.getters['ucomMailboxCapacityStore/changeCapacityLabel'];
      this.$data.changeCapacityValue = this.$store.getters['ucomMailboxCapacityStore/changeCapacityValue'];
      this.$data.price = this.$store.getters['ucomMailboxCapacityStore/price'];
      this.$data.effectiveDate = this.$store.getters['ucomMailboxCapacityStore/effectiveDate'];
      this.$data.serviceDescription = this.$store.getters['ucomMailboxCapacityStore/serviceDescription'];
    },
    // 申し込むボタンクリック
    async onApply(): Promise<void> {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      // エラーメッセージ初期化
      this.$data.errorMessages = [];

      // 会員情報取得
      const member = this.$store.getters['memberStore/member'];

      // 確認_更新API
      try {
        const requestDto = new UpdateUcomMbCapacityRequest(
          member.id,
          this.$data.ucomAccountMailId,
          this.$data.changeCapacityLabel,
          this.$data.changeCapacityValue,
          this.$data.effectiveDate
        );
        const response = await SpfApiMailboxCapacityAccessor.updateUcomMbCapacity(requestDto);
        // エラー処理
        if (response.errorMessages && response.errorMessages.length > 0) {
          this.$data.errorMessages = response.errorMessages;
          // エラーメッセージを見せるために画面最上部にスクロール
          this.scrollPageTop();
          this.isLoading = false;
          return;
        }
        // 確認更新API レスポンスをstoreに保存
        this.saveStore(response);
      } catch (e) {
        await this.$router.push('/error');
        return;
      }

      // 完了画面へ遷移
      await this.$router.push('/ucom/mailbox-capacity/completed');
      this.$data.isLoading = false;
    },
    // storeに保存
    saveStore(response: UcomMailboxCapacityConfirmInfo): void {
      this.$store.commit('ucomMailboxCapacityStore/notifyMailAddress', response.notifyMailAddress);
      this.$store.commit('ucomMailboxCapacityStore/ucomAccountMailId', this.$data.ucomAccountMailId);
      this.$store.commit('ucomMailboxCapacityStore/accountName', this.$data.accountName);
      this.$store.commit('ucomMailboxCapacityStore/mailBoxCapacity', response.mailCapacity);
      this.$store.commit('ucomMailboxCapacityStore/changeCapacityLabel', response.changeCapacityLabel);
      this.$store.commit('ucomMailboxCapacityStore/price', response.price);
      this.$store.commit('ucomMailboxCapacityStore/subscriptionDate', response.subscriptionDate);
      this.$store.commit('ucomMailboxCapacityStore/serviceDescription', this.$data.serviceDescription);
    },
    // 戻るボタンクリック
    async onBack(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      // データの変更は無いのでそのまま入力画面へ遷移
      await this.$router.push('/ucom/mailbox-capacity/input');
      this.$data.isLoading = false;
    },
    // ページトップへスクロール
    scrollPageTop(): void {
      window.scrollTo(0, 0);
    },
  },
});
