









































































































































import Vue from 'vue';

/** 使用するバリデーションの定義 */
import { required, maxLength } from 'vuelidate/lib/validators';
import { halfWidth } from '@/shared/util/validators';
/** エラーメッセージ用コンポーネント */
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
/** Entity e-mansion 共通利用 API : 契約基本情報取得 : レスポンス */
import { EMansionCustomer } from '../../../shared/classes/external-api/e-mansion/customer-response';
/** Entity UCOM Connectix API : Connectix お申し込み可能な部屋番号一覧 : レスポンス */
import { EMansionConnectixPropertyRooms, EMansionConnectixPropertyRoomsResponseResult } from '../e-mansion/classes/external-api/property-rooms-response';
/** Entity UCOM Connectix API : エラー時のレスポンス */
import { EMansionConnectixErrorResponse } from '../e-mansion/classes/external-api/connectix-error-response';
/** Entity UCOM Connectix お申し込み 入力フォーム */
import { EMansionConnectixEntryInputForm } from '../e-mansion/classes/entry-input-form';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionConnectixRoomsRequest } from '@/shared/classes/external-api/e-mansion/connectix-room-request';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { Property } from '@/shared/classes/spf-api/property';
import { EMansionProperty } from '../../../shared/classes/external-api/e-mansion/property-response';
import { Member } from '@/shared/classes/spf-api/member';
import { AuthService } from '@/shared/services/auth/auth-service';
import { ISP_MEMBER_STATUS, UaTypeValue, UA_TYPE } from '@/shared/const/service-type';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { getIspMemberStatusEMansion } from '@/shared/util/func-get-isp-member-status';

/** Apply コンポーネント */
export default Vue.extend({
  name: 'e-mansion-connectix-apply',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    /** マンション名 */
    mansionName: '',
    /** Connectix利用金額 */
    connectixUsageFee: '' as string,
    /** Connectixキャンペーン利用金額 */
    connectixUsageFeeCp: '' as string,
    /** 画面入力項目 */
    inputData: {
      /** 部屋番号 */
      roomNumber: '',
      /** 棟ID */
      buildingId: '',
    },
    /** 棟名 */
    buildingName: '',
    /** 物件基本情報の棟名。棟名選択欄の初期値 */
    selectedBuilding: '',
    /** 棟名表示用配列 */
    buildingNameList: [],
    /** 物件基本情報 */
    eMansionProperty: null as EMansionProperty | null,
    /** 物件ID */
    eMansionPropertyId: '',
    /** 部屋番号一覧 */
    propertyRooms: [],
    /** エラーの発生有無 部屋番号 */
    isInvalidRoomNumber: false,
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    isLoading: true, // 読み込み状態,最初はロード中
    customer: EMansionCustomer,
    member: null as Member | null,
    // ISP(e-mansion)会員ステータス
    ispMemberStatusEMansion: '',
  }),
  /** バリデーション定義 */
  validations: {
    inputData: {
      /** 部屋番号 */
      roomNumber: {
        required,
        halfWidth,
        maxLength: maxLength(8),
      },
    },
  },
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    try {
      /** ログインしているか否かの情報を取得 */
      const isLoggedIn = await AuthService.isLoggedIn();

      // ログインしていない場合「総合トップ」画面にリダイレクトする
      if (!isLoggedIn) {
        this.$router.push('/platform');
        return;
      }

      // エラー判定の初期化
      this.$data.isInvalidRoomNumber = false;

      /** 契約基本情報をStoreから取得
       * e-mansion 契約基本情報の取得でもっとシンプルにできる処理があれば変更
       */
      // 会員情報をStoreから取得
      const member: Member = this.$store.getters['memberStore/member'];
      const eMansionMemberId = member.primaryKeyMye ?? '';
      this.$data.member = member;

      // 物件情報をStoreから取得
      const property: Property = this.$store.getters['propertyStore/property'];
      const uaType: UaTypeValue | undefined = property.uaType;
      const propertyId: string | undefined = property.apartmentId;

      // 必要な物件情報を取得できなかった場合はエラー画面に遷移
      if (!uaType) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.PROPERTY_NO_UA_TYPE);
      }

      // 契約基本情報取得 API 用のクエリ
      // const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
      //   ua_type: uaType,
      //   apartment_id: propertyId,
      // });

      // const customer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
      //   memberId: eMansionMemberId,
      //   query: findCustomerByIdQueryDto,
      // });
      const customer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.getters['eMansionCommonStore/customer'];
      if (customer instanceof EMansionCustomer) {
        // 正常ケースの場合
        // 物件ID
        this.$data.eMansionPropertyId = customer.property_id;
        this.$data.customer = customer;
      } else {
        // 外部APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }

      // 物件基本情報をStoreから取得
      // const params = {
      //   // propertyId: this.$data.eMansionPropertyId,
      //   apartmentId: property.apartmentId,
      //   uaType: UA_TYPE.E_MANSION,
      // };
      // const eMansionProperty: EMansionProperty | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/property', params);
      const eMansionProperty: EMansionProperty | EMansionSharedErrorResponse = await this.$store.getters['eMansionCommonStore/property'];
      if (eMansionProperty instanceof EMansionProperty) {
        this.eMansionProperty = eMansionProperty;
        // 物件名
        this.mansionName = this.eMansionProperty.em_ap_name;
        // Connectix利用金額
        this.connectixUsageFee = this.eMansionProperty.op.sdwan.fee;
        this.connectixUsageFeeCp = this.eMansionProperty.op.sdwan.fee_cp;
      } else {
        // 外部APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.PROPERTY_INFO);
      }

      // 部屋番号一覧をStoreから取得
      const eMansionConnectixRoomsRequest: EMansionConnectixRoomsRequest = {
        propertyId: this.$data.eMansionPropertyId,
        uaType: UA_TYPE.E_MANSION,
      };
      const propertyRooms: EMansionConnectixPropertyRoomsResponseResult | EMansionConnectixErrorResponse = await this.$store.dispatch(
        'eMansionConnectixEntryStore/propertyRooms',
        eMansionConnectixRoomsRequest
      );
      if (propertyRooms instanceof EMansionConnectixPropertyRoomsResponseResult) {
        // 部屋番号情報を管理していない物件はAPIのroom配列を空でレスポンスされるので、その場合はエラーメッセージを表示する
        if (propertyRooms.result.rooms.length === 0) {
          this.$data.errorMessages.push('物件の部屋番号情報が取得できませんでした。');
          window.scrollTo(0, 0);
          this.$data.isLoading = false;
          return;
        }
        this.$data.propertyRooms = propertyRooms.result.rooms;
        for (const propertyRoom of this.$data.propertyRooms) {
          const findPropertyArray = {
            key: propertyRoom.building_id,
            value: propertyRoom.building_name,
          };
          // building_id === '0000' の場合は棟名なし（API 定義）
          if (!this.$data.buildingNameList.some((b: { key: string; value: string }) => b.key === propertyRoom.building_id) || propertyRoom.building_id !== '0000') {
            this.$data.buildingNameList.push(findPropertyArray);
          }
        }

        // 重複削除
        const uniqueBuildingNameList = Array.from(new Map(this.$data.buildingNameList.map((ele: { key: string; value: string }) => [ele.key, ele])).values());
        this.$data.buildingNameList = uniqueBuildingNameList;
        // 棟名無し('0000')削除
        this.$data.buildingNameList = Array.from(
          this.$data.buildingNameList.filter((room: { key: string; value: string }) => {
            return room.key !== '0000';
          })
        );
      } else if (propertyRooms instanceof EMansionConnectixErrorResponse) {
        // エラーケースの場合
        // API側から返されたエラーメッセージを表示
        this.$data.errorMessages = propertyRooms.errorInfo;
        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
        this.$data.isLoading = false;
        return;
      } else {
        // 外部APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.ROOM_INFO);
      }

      // 確認画面から戻って来た場合は、事前に入力されていた情報を再現する
      const inputApply = this.$store.getters['eMansionConnectixEntryStore/entryInputForm'];
      if (inputApply != null) {
        this.$data.inputData.roomNumber = inputApply.roomNumber;
        this.$data.inputData.buildingId = inputApply.buildingId;
      } else if (this.$data.eMansionProperty.em_bl_name) {
        // 棟名欄に初期値
        this.$data.inputData.buildingId = this.$data.buildingNameList.find((building: { key: string; value: string }) => {
          return building.value === customer.building_name;
        }).key;
      }

      // 部屋番号
      if (!this.$data.inputData.roomNumber) {
        if (customer.room_number) {
          this.$data.inputData.roomNumber = customer.room_number;
        } else {
          this.$data.inputData.roomNumber = '';
        }
      }
      this.$data.isLoading = false;
    } catch (error) {
      throw error;
    }
  },
  methods: {
    /** 「お申し込み ➡」ボタン押下時 お申し込み可能な部屋かチェック後、確認画面へ遷移。 */
    async onApply() {
      try {
        // エラーメッセージ格納配列初期化
        this.$data.errorMessages = [];
        // エラー判定の初期化
        this.$data.isInvalidRoomNumber = false;

        if (await this.isInCancelApplication()) {
          this.$router.push('/connectix/e-mansion/terms').catch(error => {checkRouterError(error)});
          return;
        }

        const ERROR_MESSAGE = '棟名、部屋番号をご確認の上、正しく入力してください。';
        const ERROR_MESSAGE_NO_BUILDING = '部屋番号をご確認の上、正しく入力してください。';
        const ERROR_MESSAGE_CANNOT_APPLY_CONNECTIX = 'インターネット接続サービス提供開始時にお申し込みください。';

        // 棟名の入力チェック
        if (this.$data.buildingNameList.length) {
          for (const selectFindPropertyRoom of this.$data.buildingNameList) {
            if (selectFindPropertyRoom.key == this.$data.inputData.buildingId) {
              this.$data.buildingName = selectFindPropertyRoom.value;
              break;
            } else {
              this.$data.buildingName = null;
            }
          }
          if (this.$data.buildingName == null || this.$data.buildingName == '') {
            this.$data.errorMessages.push(ERROR_MESSAGE);
            window.scrollTo(0, 0);
            return;
          }
        }

        // 部屋番号の入力チェック
        if (this.$v.inputData.$invalid) {
          if (this.$v.inputData.roomNumber?.$invalid) {
            this.$data.isInvalidRoomNumber = true;
            // 必須チェック
            // 部屋番号を管理している物件のみ存在チェック
            if (this.$data.propertyRooms.length && !this.$v.inputData.roomNumber?.required) {
              if (this.$data.buildingName) {
                this.$data.errorMessages.push(ERROR_MESSAGE);
              } else {
                this.$data.errorMessages.push(ERROR_MESSAGE_NO_BUILDING);
              }
              window.scrollTo(0, 0);
              return;
            }
            // 形式・桁数チェック
            if (!this.$v.inputData.roomNumber?.halfWidth || !this.$v.inputData.roomNumber?.maxLength) {
              if (this.$data.buildingName) {
                this.$data.errorMessages.push(ERROR_MESSAGE);
              } else {
                this.$data.errorMessages.push(ERROR_MESSAGE_NO_BUILDING);
              }
              window.scrollTo(0, 0);
              return;
            }
          } else {
            this.$data.isInvalidRoomNumber = false;
          }
        }

        const propertyRooms = this.$data.propertyRooms;
        // お申し込み可能な部屋の中に自部屋があるかチェック
        const applyRooms = propertyRooms.find((propertyRoom: EMansionConnectixPropertyRooms) => {
          return (
            (propertyRoom.building_id === this.$data.inputData.buildingId || propertyRoom.building_id === '0000') && propertyRoom.room_number === this.$data.inputData.roomNumber
          );
        });
        if (applyRooms === undefined) {
          // 入力した部屋がお申し込み可能な部屋ではなかった場合はエラー
          if (this.$data.buildingName) {
            this.$data.errorMessages.push(ERROR_MESSAGE);
          } else {
            this.$data.errorMessages.push(ERROR_MESSAGE_NO_BUILDING);
          }
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          return;
        } else {
          if (applyRooms.apply_connectix_id === '1') {
            this.$data.errorMessages.push(ERROR_MESSAGE_CANNOT_APPLY_CONNECTIX);
            window.scrollTo(0, 0);
            return;
          }
        }

        // 0000の場合undefinedのはずなので設定する
        if (!this.$data.inputData.buildingId) {
          this.$data.inputData.buildingId = applyRooms.building_id;
        }
      } catch (error) {
        throw error;
      }
      // 入力情報をストアに保存
      const inputData = new EMansionConnectixEntryInputForm({
        // 部屋番号
        roomNumber: this.$data.inputData.roomNumber.trim(),
        // 棟ID
        buildingId: this.$data.inputData.buildingId,
        // 棟名表示用
        buildingName: this.$data.buildingName,
      });

      this.$store.commit('eMansionConnectixEntryStore/entryInputForm', inputData);

      this.$router.push('/connectix/e-mansion/confirm').catch(error => {checkRouterError(error)});
    },
    async isInCancelApplication() {

      const property: Property = this.$store.getters['propertyStore/property'];

      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId
      });

      const eMansionMemberId = this.member?.primaryKeyMye ?? '';
      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: eMansionMemberId,
        query: findCustomerByIdQueryDto
      });

      if (eMansionCustomer instanceof EMansionCustomer) {

        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, eMansionCustomer);

        /**
         * ISP(e-mansion)会員ステータスが次の3つの場合、ISP退会申し込み中であると判定する
         *
         * 1. ISP(e-mansion)会員ステータス: 退会申し込み
         * 2. ISP(e-mansion)会員ステータス: 移転退会
         * 3. ISP(e-mansion)会員ステータス: 退会済みログイン可能
         */
        if (this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION
            || this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL
            || this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK) {
          return true;
        }
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }
      return false;
    }
  }
});
