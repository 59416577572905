
































































































import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';

export default Vue.extend({
  name: 'web-space-register-completed',
  components: {
    LoadingComponent,
  },

  data: () => ({
    isLoading: false, // ローディング

    /** 各種パラメータ */
    accountName: '', // アカウント名
    mailAddress: '', // メールアドレス
    applicationDate: Date, // お申込み日
    homepageAddress: '', // ホームページアドレス
    homepageUrlScheme: process.env.VUE_APP_UCOM_HOMEPAGE_URL_SCHEME, // UCOM ホームページURIスキーム
  }),
  async mounted() {
    try {
      this.$data.isLoading = true;
      const accountName = this.$store.getters['ucomWebSpaceRegisterStore/accountName'];
      if (!accountName) {
        this.$router.push('/ucom/account-option-change/account-list');
        this.$data.isLoading = false;
      }

      // 前画面からのデータを画面項目にセット
      await this.setDataByStore();
      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/error');
    }
  },
  methods: {
    // 前画面からのデータを画面項目にセット
    async setDataByStore() {
      this.$data.accountName = this.$store.getters['ucomWebSpaceRegisterStore/accountName'];
      this.$data.mailAddress = this.$store.getters['ucomWebSpaceRegisterStore/mailAddress'];
      this.$data.homepageAddress = this.$store.getters['ucomWebSpaceRegisterStore/homepageAddress'];
      this.$data.applicationDate = this.$store.getters['ucomWebSpaceRegisterStore/applicationDate'];
    },

    //FTP設定画面へ遷移
    async ftpSettingInfro(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      await this.$router.push('/ucom/account-option-change/account-list');

      this.$data.isLoading = false;
    },

    // 「UCOM光レジデンス会員専用トップ」ボタンクリック
    async onUcomTop(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      await this.$router.push('/ucom');

      this.$data.isLoading = false;
    },

    // ページトップへスクロール
    async scrollPageTop() {
      window.scrollTo(0, 0);
    },
  },
});
