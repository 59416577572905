export class EMansionHomepageCancelInitialDisplayResponse {
  readonly _isHomepageOpened!: boolean;
  readonly _homepageOpenedMessage!: string | undefined;
  readonly _registerStatus!: number;
  readonly _data!: HomepageData;

  /** コンストラクター */
  constructor(partial: Partial<EMansionHomepageCancelInitialDisplayResponse>) {
    Object.assign(this, partial);
  }

  public get isHomepageOpened(): boolean {
    return this._isHomepageOpened;
  }

  public get homepageOpenedMessage(): string | undefined {
    return this._homepageOpenedMessage;
  }

  public get registerStatus(): number {
    return this._registerStatus;
  }

  public get data(): HomepageData {
    return this._data;
  }
}

export class HomepageData {
  readonly _eMansionHomepageId!: number;
  readonly _ftpAccount!: string;
  readonly _ftpPassword!: string;
  readonly _ftpServerName!: string;
  readonly _fileDest!: string;
  readonly _homepageUrl!: string;
  readonly _serviceEndDate!: Date | undefined;

  public get eMansionHomepageId(): number {
    return this._eMansionHomepageId;
  }

  public get ftpAccount(): string {
    return this._ftpAccount;
  }

  public get ftpPassword(): string {
    return this._ftpPassword;
  }

  public get ftpServerName(): string {
    return this._ftpServerName;
  }

  public get fileDest(): string {
    return this._fileDest;
  }

  public get homepageUrl(): string {
    return this._homepageUrl;
  }

  public get serviceEndDate(): Date | undefined {
    return this._serviceEndDate;
  }
}
