



































































































import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiAccountManagementAccessor } from '@/infra/accessor/spf/ucom/spf-api-account-management-accessor';
import { Member } from '@/shared/classes/spf-api/member';
import { UcomAccountManagementAccountList } from '@/shared/classes/spf-api/mail/model/ucom/account-management/ucom-account-management-account-list';
import { AuthService } from '@/shared/services/auth/auth-service';

export default Vue.extend({
  name: 'list',
  components: {
    LoadingComponent,
  },
  data: () => ({
    isLoading: true,
    accountManagementList: {},
    UNREGISTERED: '未登録',
    additionalAccountUrl: '',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      const member: Member = this.$store.getters['memberStore/member'];

      //アカウントID管理情報をAPIより取得する
      this.$data.accountManagementList = await SpfApiAccountManagementAccessor.getAccountManagementList(member.id);

      this.$data.isLoading = false;
      this.$data.additionalAccountUrl = `${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_UCOM_SUB_ACCOUNT_LINKING_PAGE_FOR_DISPLAY}`;
    } catch (error) {
      await this.$router.push('/error');
      return;
    }
  },
  methods: {
    /**新規登録ボタンクリック */
    async onCreate() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      // 入力画面で入力中や既存アカウントの更新中であることの判定に使われているステートをストアから削除する
      this.$store.commit('ucomAccountManagementInputStore/accountName', undefined);
      this.$store.commit('ucomAccountManagementStore/ucomAccountId', undefined);
      /** アカウントID管理：登録・変更　入力画面に遷移 */
      await this.$router.push('/ucom/account-management/input');
      this.$data.isLoading = false;
    },
    /**アカウントID リンククリック */
    async onAccountId(accountInfo: UcomAccountManagementAccountList) {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      /** 入力中判定となるステートをストアから削除する */
      this.$store.commit('ucomAccountManagementInputStore/accountName', undefined);
      //押下された行のアカウントIDをstoreに保存する
      this.$store.commit('ucomAccountManagementStore/ucomAccountId', accountInfo.ucomAccountId);
      /** アカウントID管理：登録・変更　入力画面に遷移 */
      await this.$router.push('/ucom/account-management/input');
      this.$data.isLoading = false;
    },
    /**メールアドレス リンククリック */
    async onMailAddress(accountInfo: UcomAccountManagementAccountList) {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      this.$store.commit('ucomAccountManagementStore/ucomAccountId', accountInfo.ucomAccountId);
      this.$store.commit('ucomAccountManagementStore/currentMailAddress', accountInfo.mailAddress);
      this.$store.commit('ucomAccountManagementStore/mailAddress', accountInfo.mailAddress != this.$data.UNREGISTERED ? accountInfo.mailAddress : '');
      this.$store.commit('ucomAccountManagementStore/mailAddressType', accountInfo.mailAddressType);
      this.$store.commit('ucomAccountManagementStore/isUpdate', accountInfo.mailAddress != this.$data.UNREGISTERED);
      this.$store.commit('ucomAccountManagementStore/accountName', accountInfo.accountName);

      // TODO VUE_APP_ISP_URLの設定
      const next = Number(accountInfo.isOldDomain) ? process.env.VUE_APP_ISP_URL : '/ucom/account-option-change/mail-input';
      /** アカウントID管理：登録・変更　入力画面に遷移 */
      await this.$router.push(next);
      this.$data.isLoading = false;
    },
    /**削除 ボタンクリック */
    async onDelete(accountInfo: UcomAccountManagementAccountList) {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      this.$store.commit('ucomAccountManagementStore/ucomAccountId', accountInfo.ucomAccountId);
      this.$store.commit('ucomAccountManagementStore/deleteOn', new Date());
      /** アカウントID管理：登録・変更　入力画面に遷移 */
      await this.$router.push('/ucom/account-management/delete');
      this.$data.isLoading = false;
    },
    /**こちらリンククリック */
    async onPortasLinking() {
      if (this.$data.isLoading) {
        return;
      }

      // 現在ログインしているアカウントをログアウト
      await AuthService.logoutWithFederatedTrue();
      // アカウントID専用連携ページを別タブで開く
      window.open(process.env.VUE_APP_BASE_URL + process.env.VUE_APP_UCOM_LOGIN_ACCOUNT_ID_USER);

      this.$data.isLoading = false;
    },
    // ページTOPへ
    scrollPageTop() {
      window.scrollTo(0, 0);
    },
  },
});
