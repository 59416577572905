








































































































import Vue from 'vue';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { AuthService } from '@/shared/services/auth/auth-service';

export const E_MANSION_SUPPORT_CONTACT = {
  TOWA: 0,
  NORMAL: 1,
  SLIM: 2,
} as const;

/** エラー画面 */
export default Vue.extend({
  name: 'e-mansion-error',
  components: {
    LoadingComponent,
  },
  data: () => ({
    isLoggedIn: false,
    contactInfo: E_MANSION_SUPPORT_CONTACT,
    isTowaApartment: false,
    isSlimPlan: false,
    isNormal: false,
    eMansionErrorDisplayInfoList: undefined,
    specialMessage: '',
  }),
  async mounted() {
    this.$data.isLoggedIn = await AuthService.isLoggedIn();
    try {
      const property: EMansionProperty = this.$store.getters['eMansionCommonStore/property'];
      if (!property) {
        await this.$router.push('/error');
        return;
      }
      //storeから取得
      this.specialMessage = this.$store.getters['errorStore/messages'];
      //Storeをリセット
      this.$store.commit('errorStore/messages', undefined);
      this.contactInfo = E_MANSION_SUPPORT_CONTACT;
      this.isTowaApartment = isTowaApartment(property.apartment_id);
      this.isSlimPlan = property.slim_plan_flag == '1';
      this.isNormal = isNormal(this.isTowaApartment, this.isSlimPlan);
      this.eMansionErrorDisplayInfoList = await this.$store.dispatch('eMansionErrorDisplayInfoStore/eMansionErrorDisplayInfoList');
      if (!this.eMansionErrorDisplayInfoList) {
        await this.$router.push('/error');
        return;
      }
    } catch (e) {
      await this.$router.push('/error');
      return;
    }
  },
  methods: {
    isSpecialErrorMessage(): boolean {
      if (!this.specialMessage) {
        return false;
      }
      if (this.specialMessage.length > 0) {
        return true;
      }
      return false;
    },
  },
});

function isTowaApartment(apartment_id: string) {
  if (apartment_id.match(/^T/)) {
    return true;
  }
  return !!apartment_id.match(/^W/);
}
function isNormal(isTowaApartment: boolean, isSlimPlan: boolean): boolean {
  if (isTowaApartment) {
    return false;
  }
  return !isSlimPlan;
}
