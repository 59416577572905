





























































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import { Swiper as SwiperClass, Navigation, Pagination, Autoplay } from 'swiper/core';
import MainSwiperComponent from './components/main-swiper.vue';
import PickupSwiperComponent from './components/pickup-swiper.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import FrequentlyAskedQuestions from './components/frequently-asked-questions.vue';
import 'swiper/swiper-bundle.min.css';
import '../styles/swiper-style.css';
import { AuthService } from '@/shared/services/auth/auth-service';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { MEMBER_STATUS } from '@/shared/const/member-status';
import { Member } from '@/shared/classes/spf-api/member';
import { Property } from '@/shared/classes/spf-api/property';
import { UA_TYPE } from '@/shared/const/service-type';

export default Vue.extend({
  name: 'top',
  components: {
    PickupSwiperComponent,
    LoadingComponent,
    FrequentlyAskedQuestions
  },
  data: () => ({
    status: 'top',
    notLoginSwiperOptions: {
      loop: true,
      navigation: {
        nextEl: '.notlogin-swiper-button-next',
        prevEl: '.notlogin-swiper-button-prev'
      },
      slidesPerView: 'auto',
      centeredSlides: true,
      spaceBetween: 20,
      preloadImages: true,
      updateOnImagesReady: true    
    },
    eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
    ucomUrl: process.env.VUE_APP_UCOM_URL,
    /** MCLOUD の URL */
    mcloudUrl: process.env.VUE_APP_MCLOUD_URL,
    connectixLpUrl: process.env.VUE_APP_CONNECTIX_LP_URL,
    fiveAUrl: process.env.VUE_APP_FIVE_A_BUILDING_SEARCH_URL,
    lNetUrl: process.env.VUE_APP_FIVE_A_LIC_SERVICE_DOMAIN,
    nAssetUrl: process.env.VUE_APP_FIVE_A_N_ASSET_SERVICE_DOMAIN,
    isMounted: false,
    loadedList: new Array<boolean>(), // ファーストビューで表示される画像のうち、読み込み終わった件数
    isImagesLoaded: false, // ファーストビューで表示される画像の読み込みがすべて終わったかどうか
    oemsMaster: [], // 有効期間のOEMマスタ一覧
    oemMaster1: [], // 表示用OEMマスタ一覧
    oemMaster2: [],
    oemMaster3: [],
  }),
  async mounted() {
    this.$nextTick(() => {
      this.isMounted = true;
    });
    this.$data.oemsMaster = this.$store.getters['oemsStore/oems'];
    this.$emit('isLoggedIn'); // /pages/index.vue宛てにログインしていないことを通知
    for(let i=0;i < this.$data.oemsMaster.length; i++) {
      if(i%3 === 0) {
        this.oemMaster1.push(this.oemsMaster[i]);
      }else if(i%3 === 1) {
        this.oemMaster2.push(this.oemsMaster[i]);
      }else {
        this.oemMaster3.push(this.oemsMaster[i]);
      }
    }
  },
  methods: {
    /** ログイン処理を行い、ログインしていなければログイン画面に遷移する */
    async onLogin() {
      await AuthService.login();
    },
    /** ログイン処理を行い、ログインしていなければログイン画面に遷移する */
    async onEntry() {
      await AuthService.login();
    }
  }
});
