



































































































































































































































































import { SpfApiService } from '@/shared/services/api/spf-api-service';
import Vue from 'vue';
import { required, sameAs } from 'vuelidate/lib/validators';
import { emailAddressMaxLength, emailAddressIllegalChar, emailAddressIllegalCharMark, } from '@/shared/util/validators';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { AuthService } from '@/shared/services/auth/auth-service';
import { MEMBER_STATUS } from '@/shared/const/member-status';
import { UA_TYPE, PROPERTY_SEARCH_TYPE, TRANSITION_SOURCE_SERVICE, TRANSITION_STORAGE } from '@/shared/const/service-type'
import { convert8DigitIdTo6digitId } from '@/shared/util/convert';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { SearchPropertyDetail, SearchResultOver } from '@/shared/classes/spf-api/property';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';

/** InputEmail コンポーネント */
export default Vue.extend({
  name: 'input-email',
  components: {
    ErrorMessagesComponent,
    LoadingComponent
  },
  data: () => ({
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '入力項目にエラーがあります。ご確認をお願いいたします。',
    form: {
      mailAddress: '', // メールアドレス
      confirm: '', //確認用メールアドレス
      termsAndPrivacyPolicy: false // 利用規約同意チェック
    },
    transitionSourceService: null,
    transitionSourceServiceId: null,
    isSubmitting: false, // 同意してメール送信ボタン押下判定
    noQueryOrBuildingData: false, // クエリパラメータが不適切または、PortasDBの物件テーブルを検索して条件を満たさないとき
    memberProperty: {} as SearchPropertyDetail, //新規登録時に物件情報を格納する
    isPropertyUaTypeFiveA: false
  }),

  /** コンポーネント生成時の処理 */
  async mounted() {

    let from = '';

    if (!this.$route.query?.from && localStorage.getItem(TRANSITION_STORAGE)) {// LINEの場合と判断
      const transitionInfo = JSON.parse(localStorage.getItem(TRANSITION_STORAGE)!);
      this.$data.transitionSourceServiceId = transitionInfo.apid;
      from = transitionInfo.from;
    } else {
      /** 遷移元サービス物件 ID をクエリパラメータから取得 */
      this.$data.transitionSourceServiceId = this.$route.query?.apid;
      from = String(this.$route.query?.from);
    }

    /**
     * 遷移元サービス、 遷移元サービス物件ID をクエリパラメータから取得
     * 遷移元サービス: 1: e-mansion, 2: UCOM, 3: Five.A
     * 
     * | ISP      | URL                                        | convert8DigitIdTo6digitId | searchPropertyType |
     * | e-manion | input-email?from=emansion&apid=240ef415    | require                   | 3 (E_MANSION)      |
     * | ucom     | input-email?from=ucom-r&apid=31114C11      | require                   | 3 (E_MANSION)      |
     * | ucom     | input-email?from=ucom-r&apid=5130000000001 | x                         | 4 (UCOM)           |
     * | Five.A   | input-email?from=Five.A&apid=AA0146        | x                         | 3 (E_MANSION)      |
     *
     * ただし、リンク直打ちの場合は、次のように入力してauth0新規会員登録をする
     * {VUE_APP_BASE_URL}/login-forwarding?from=Five.A&apid=AA0146&processtype=regist
     * そうすると、次のURLへと遷移する
     * {VUE_APP_BASE_URL}/input-email?from=Five.A&apid=AA0146&processtype=regist
     */
    const FROM_REGEXP = {
      E_MANSION: /^emansion$/,
      UCOM: /^ucom-r$/,
      FIVE_A: /^Five\.A$/
    } as const;

    const APID_REGEXP = {
      ID_8_DIGIT: /^[0-9a-zA-Z]{8}$/,
      ID_13_DIGIT: /^[0-9]{13}$/
    } as const;

    if (FROM_REGEXP.FIVE_A.test(from)) {
      this.$data.isPropertyUaTypeFiveA = true;
      if (this.$data.transitionSourceServiceId) {
        this.$data.transitionSourceService = TRANSITION_SOURCE_SERVICE.FIVE_A;
        this.memberProperty = await this.findPropertyFromQueryParameter(PROPERTY_SEARCH_TYPE.E_MANSION, UA_TYPE.FIVE_A);
      } else {
        this.$data.noQueryOrBuildingData = true;
      }
    } else if (FROM_REGEXP.UCOM.test(from)) {
      if (APID_REGEXP.ID_8_DIGIT.test(this.$data.transitionSourceServiceId)) {
        this.$data.transitionSourceService = TRANSITION_SOURCE_SERVICE.UCOM;
        this.$data.transitionSourceServiceId = convert8DigitIdTo6digitId(String(this.$data.transitionSourceServiceId));
        this.memberProperty = await this.findPropertyFromQueryParameter(PROPERTY_SEARCH_TYPE.E_MANSION, UA_TYPE.UCOM);
      } else if (APID_REGEXP.ID_13_DIGIT.test(this.$data.transitionSourceServiceId)) {
        this.$data.transitionSourceService = TRANSITION_SOURCE_SERVICE.UCOM;
        this.memberProperty = await this.findPropertyFromQueryParameter(PROPERTY_SEARCH_TYPE.UCOM, UA_TYPE.UCOM);
      } else {
        this.$data.noQueryOrBuildingData = true;
      }
    } else if (FROM_REGEXP.E_MANSION.test(from)) {
      if (APID_REGEXP.ID_8_DIGIT.test(this.$data.transitionSourceServiceId)) {
        this.$data.transitionSourceService = TRANSITION_SOURCE_SERVICE.E_MANSION;
        this.$data.transitionSourceServiceId = convert8DigitIdTo6digitId(String(this.$data.transitionSourceServiceId));
        this.memberProperty = await this.findPropertyFromQueryParameter(PROPERTY_SEARCH_TYPE.E_MANSION, UA_TYPE.E_MANSION);
      } else {
        this.$data.noQueryOrBuildingData = true;
      }
    } else {
      this.$data.noQueryOrBuildingData = true;
    }

  },
  validations: {
    form: {
      mailAddress: {
        required,
        emailAddressMaxLength,
        emailAddressIllegalChar,
        emailAddressIllegalCharMark,
      },
      confirm: {
        sameAsMailAddress: sameAs('mailAddress')
      },
      termsAndPrivacyPolicy: {
        isChecked: sameAs(() => true)
      }
    }
  },
  methods: {
    async findPropertyFromQueryParameter(searchType: number, uaType: string): Promise<SearchPropertyDetail> {
      try {
        const propertyList: SearchPropertyDetail[] | SearchResultOver = await SpfApiService.searchProperties(
          searchType,
          this.$data.transitionSourceServiceId,
          false
        );
        if ('errorMessage' in propertyList) {
          this.$data.noQueryOrBuildingData = true;
          throw new Error();
        }
        if (propertyList.length === 0 || propertyList.length >= 2) {
          this.$data.noQueryOrBuildingData = true;
          throw new Error();
        }
        if (propertyList[0].uaType !== uaType) {
          this.$data.noQueryOrBuildingData = true;
          throw new Error();
        }
        this.$data.transitionSourceServiceId = String(this.$data.transitionSourceServiceId);
        return propertyList[0];
      } catch (e) {
        this.$data.noQueryOrBuildingData = true;
        throw e;
      }
    },
    async onSendMail(): Promise<void> {
      // ボタン押下中は何もしない
      if (this.$data.isSubmitting) {
        return;
      }

      // ボタン押下中扱いとする
      this.$data.isSubmitting = true;

      this.errorMessages = [];
      if (this.$v.form.$invalid) {
        if (this.$v.form.termsAndPrivacyPolicy?.$invalid) {
          this.errorMessages.push('「Portas利用規約」「Portasにおける個人情報の取り扱いについて」に同意する」にチェックを入れてください。');
        }
        if (!this.$v.form.mailAddress?.required) {
          this.errorMessages.push('メールアドレスを入力してください。');
        }
        if (!this.$v.form.mailAddress?.emailAddressMaxLength) {
          this.errorMessages.push('メールアドレスは48文字以内で入力してください。');
        }
        if (!this.$v.form.mailAddress?.emailAddressIllegalChar) {
          this.errorMessages.push('メールアドレスの形式が正しくありません。');
        }
        if (!this.$v.form.mailAddress?.emailAddressIllegalCharMark) {
          this.errorMessages.push('メールアドレスの形式が正しくありません。');
        }
        if (!this.$v.form.confirm?.sameAsMailAddress) {
          this.errorMessages.push('メールアドレスが確認用メールアドレスと一致しません。');
        }
        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
        this.$data.isSubmitting = false; // ボタン押下解除
        return;
      }
      try {
        // 会員ストアから会員ステータス情報を取得する
        let memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];

        const APID_REGEXP = {
          ID_8_DIGIT: /^[0-9a-zA-Z]{8}$/,
          ID_13_DIGIT: /^[0-9]{13}$/
        } as const;

        // 会員ステータス無しの場合はテーブルへ新規作成
        if (!memberStatus) {
          const newMemberStatus: MemberStatus = new MemberStatus({
            primaryKeyAuth0: await AuthService.getSub(),
            status: MEMBER_STATUS.ACCOUNT_CREATED,
            emailAddress: this.form.mailAddress,
            transitionSourceService: this.$data.transitionSourceService,
            transitionSourceServiceId: this.$data.transitionSourceServiceId
          });

          // 新規会員ステータス作成
          if (!await SpfApiService.createMemberStatus(newMemberStatus)) {
            throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_CREATE_MEMBER_STATUS);
          }

          //会員ステータスのストアの情報を更新
          this.$store.commit('memberStore/memberStatus', newMemberStatus);
          //会員ステータスのストアの情報を再取得・格納
          memberStatus = this.$store.getters['memberStore/memberStatus'];

        } else {
          // 会員ステータス存在するので更新
          memberStatus.emailAddress = this.form.mailAddress;
          // 退会から再度入会のケースを想定
          memberStatus.status = MEMBER_STATUS.ACCOUNT_CREATED;
          memberStatus.transitionSourceService = this.$data.transitionSourceService;
          memberStatus.transitionSourceServiceId = this.$data.transitionSourceServiceId;
          await this.$store.dispatch('memberStore/updateMemberStatus', { primaryKeyAuth0: await AuthService.getSub(), memberStatus });
        }

        //UCOMとe-mansionの場合は会員新規登録の処理を実行して完了画面に遷移
        if (memberStatus.transitionSourceService === TRANSITION_SOURCE_SERVICE.E_MANSION || memberStatus.transitionSourceService === TRANSITION_SOURCE_SERVICE.UCOM) {

          const sub = await AuthService.getSub();
          const eMansionMemberId = await AuthService.getEMansionMemberId();
          const eMansionMemberIdETnc = await AuthService.getEMansionMemberIdETnc();
          const ucomMemberId = await AuthService.getUcomMemberId();

          const member: Member = new Member({
            emailAddress: memberStatus.emailAddress,
            memberStatus: 0,
            primaryKeyAuth0: sub,
            propertyId: this.memberProperty?.id
          });

          // 既存会員の場合
          if (eMansionMemberId || ucomMemberId) {
            member.primaryKeyUcom = ucomMemberId ? ucomMemberId : undefined;
            member.primaryKeyMye = eMansionMemberId ? eMansionMemberId : undefined;
            member.eTncMemberId = eMansionMemberIdETnc ? eMansionMemberIdETnc : undefined;
          }

          try {
            // 会員登録処理
            const resultMember = await SpfApiService.createMember(member);

            // あり得ないけど念のため確認
            if (!resultMember) {
              throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
            }

            const sendMemberStatus = memberStatus;
            sendMemberStatus.status = MEMBER_STATUS.ACCOUNT_REGISTERED

            await this.$store.dispatch('memberStore/updateMemberStatus', { primaryKeyAuth0: sub, memberStatus: sendMemberStatus });

          } catch (error: any) {
            throw error;
          }

          await AuthService.refresh();

          this.$router.push('/platform/entry/completed')
            .catch(
              error => {
                checkRouterError(error)
              }
            )
            .finally(
              () => {
                this.isSubmitting = false;
              }
            );
          return;
        }

        const result = await SpfApiService.createToken(this.form.mailAddress);

        localStorage.removeItem(TRANSITION_STORAGE);// localstrage削除

        // SpfApiService.createToken(this.form.mailAddress) に成功するとバックエンドで会員ステータスが更新される
        // ページ遷移時に beforeEach で会員ステータス取得できるように null にする
        this.$store.commit('memberStore/memberStatus', null);

        this.$router.push('/platform/entry/accept-email').catch(error => { checkRouterError(error) });
      } catch (error: any) {
        const status: number = error.response?.status;
        /** httpステータスコードが500番台またはタイムアウトなどによってレスポンスを返さない場合は、エラー画面へ遷移させる */
        if (!status || status >= 500) {
          throw error;
        }
        // メアド重複
        if (status === 409) {
          this.errorMessages.push('ご入力いただいたメールアドレスは既に登録されています。');
          window.scrollTo(0, 0);
          this.$data.isSubmitting = false; // ボタン押下解除
          return;
        }
        /** 400番台の場合はエラーメッセージを表示させる */
        throw error;
      } finally {
        this.$data.isSubmitting = false; // ボタン押下解除
      }
    },
  },
  computed: {
    /**
     * メール送信ボタンの活性非活性を判断する
     */
    isActivatedSendButton(): boolean {
      if (!this.form.termsAndPrivacyPolicy || this.$data.noQueryOrBuildingData) {
        return false;
      }
      return true;
    },
    /**
     * メール送信ボタンの活性非活性に応じてクラスを変更する
     */
    getSendButtonClass(): string {
      return this.isActivatedSendButton ? 'btn btn01 bs' : 'btn btn04 bs';
    }
  }
});
