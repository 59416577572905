













































































import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';

/** UCOM光レジデンス Connectixお申し込み 完了画面 */
export default Vue.extend({
  name: 'ucom-connectix-completed',
  components: {
    LoadingComponent
  },
  data: () => ({
    isRouting: false, // router.pushが実行されるまで用
    isMounting: false,
  }),
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    this.$data.isMounting = true;
    // 入力値を保持しているストアの削除
    this.$store.commit('ucomConnectixEntryStore/entryInputForm', null);
    this.$data.isMounting = false;
  },
  methods: {
    onNext() {
      if (this.isRouting) {
        return;
      }
      this.isRouting = true;

      this.$router.push('/ucom').catch(error => {checkRouterError(error)}).finally( () => {
        this.isRouting = false;
      });
    }
  }
});
