






import Vue from 'vue';

/** App */
export default Vue.extend({
  name: 'app',
  watch: {
    $route: {
      handler(to) {
        /** 画面遷移時に `title` 要素の値を変更する */
        if (to.meta.title) document.title = to.meta.title;
        /** 画面遷移時に `meta description` を変更する */
        const metaDescription: any = document.querySelector('meta[name="description"]');
        if (to.meta.description) {
          metaDescription.setAttribute('content', to.meta.description);
        } else {
          metaDescription.removeAttribute('content');
        }
        /** 画面遷移時に `meta keywords` を変更する */
        const metaKeywords: any = document.querySelector('meta[name="keywords"]');
        if (to.meta.keywords) {
          metaKeywords.setAttribute('content', to.meta.keywords);
        } else {
          metaKeywords.removeAttribute('content');
        }
      },
      immediate: true // 初期表示時にも発火させるため指定する
    }
  },
  mounted() {
  }
});
