import { BuildingInfoStatus, BUILDING_INFO_STATUS } from "@/store/platform/platform-edit-store";
import { EMansionRoomsDetail } from "../classes/external-api/e-mansion/rooms-response";
import { ISP_TYPE_REGEXP, UaTypeValue, UA_TYPE } from "../const/service-type";

export function validateRoomNumber(
  roomNumber: string,
  uaType?: UaTypeValue,
  ispType?: string
): string[] {
  const errorMessages = [];

  if (!roomNumber) {
    errorMessages.push('部屋番号を入力してください。');
    /** 以降のバリデーションチェックは不要のため return */
    return errorMessages;
  }

  if (uaType === UA_TYPE.E_MANSION || uaType === UA_TYPE.FIVE_A) {
    // e-mansion物件の場合/five-A物件の場合/e-mansion+Mcloud物件の場合
    if (!roomNumber.match(/^[0-9a-zA-Z]*$/)) {
      errorMessages.push('部屋番号は半角英数字で入力してください');
    }
    if (roomNumber.length > 8) {
      errorMessages.push('部屋番号は8文字以内で入力してください');
    }
  } else if (uaType === UA_TYPE.UCOM) {
    if (!ispType) {
      //UCOM単体の物件の場合
      if (!roomNumber.match(/^[^ -~｡-ﾟ]+$/)) {
        errorMessages.push('部屋番号は全角で入力してください');
      }
      if (roomNumber.length > 64) {
        errorMessages.push('部屋番号は64文字以内で入力してください');
      }
    } else if(typeof ispType === 'string' && ISP_TYPE_REGEXP.UCOM_MCLOUD.test(ispType)){
      //UCOM+Mcloudの物件の場合
      if (!roomNumber.match(/^[^ -~｡-ﾟ]+$/)) {
        errorMessages.push('部屋番号は全角で入力してください');
      }
      if (roomNumber.length > 64) {
        errorMessages.push('部屋番号は64文字以内で入力してください');
      }
    }
  }
  return errorMessages;
}

/**入力した棟名と部屋番号が一致する部屋情報が含まれているかどうかのチェック */
export function checkIsMatchRoom(
  buildingInfoStatus: BuildingInfoStatus,
  roomsData: {
    id: string;
    buildingInfoStatus: BuildingInfoStatus;
    rooms: EMansionRoomsDetail[];
  }[],
  buildingData: {
    buildingId: string;
    buildingName: string;
    roomNumber: string;
  }
): boolean {
  if (
    buildingInfoStatus === BUILDING_INFO_STATUS.BOTH ||
    buildingInfoStatus === BUILDING_INFO_STATUS.BUILDING_ONLY
  ) {
    return roomsData.some((room) => {
      return room.rooms.some((room) => {
        return (
          room.building_name === buildingData.buildingName &&
          room.room_number === buildingData.roomNumber
        );
      });
    });
  } else if (buildingInfoStatus === BUILDING_INFO_STATUS.ROOM_ONLY) {
    return roomsData.some((room) => {
      return room.rooms.some((room) => {
        return (
          room.room_number === buildingData.roomNumber
        );
      });
    });
  } else {
    return false;
  }
}