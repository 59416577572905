















































































































































import Vue from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiAccountManagementAccessor } from '@/infra/accessor/spf/ucom/spf-api-account-management-accessor';
import { ObfuscationService } from '@/shared/services/obfuscation/obfuscation-service';

export default Vue.extend({
  name: 'account-management-input',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },
  data: () => ({
    isAccountRegistrationMax: false, // アカウント登録上限の場合true
    isLoading: false, // ローディング
    errorMessages: [], // エラーメッセージを格納する配列
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。', // エラーメッセージ部タイトル
    isOneSentence: false, // エラータイトルのみ表示かどうか
    isPasswordView: false, // パスワード表示制御
    isPasswordViewConf: false, // 確認用パスワード表示制御
    isDisabled: false, // アカウント名入力制御
    ucomMemberSupportUrl: process.env.VUE_APP_UCOM_MEMBER_SUPPORT_MY_PAGE_FUNCTION_URL, // UCOM光：会員サポート：マイページの機能画面

    /* 各種パラメータ */
    accountName: '',
    familyName: '',
    firstName: '',
    familyNameKana: '',
    firstNameKana: '',
    userName: '',
    password: '',
    passwordConf: '',
    accountAuthorization: '',
    ucomAccountId: '',
  }),
  computed: {
    inputType: function () {
      return this.isPasswordView ? 'text' : 'password';
    },
    inputTypeConf: function () {
      return this.isPasswordViewConf ? 'text' : 'password';
    },
  },
  async mounted() {
    try {
      this.$data.isLoading = true;

      // 前画面(アカウントID管理：一覧)からの情報をStoreから取得
      this.$data.ucomAccountId = this.$store.getters['ucomAccountManagementStore/ucomAccountId'];

      // 画面項目データセット
      await this.setData(this.$data.ucomAccountId);

      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/error');
      return;
    }
  },
  methods: {
    async setData(ucomAccountId: string): Promise<void> {
      // 確認画面から戻るボタンで遷移の場合はstoreにデータがある
      if (this.$store.getters['ucomAccountManagementInputStore/accountName']) {
        this.setDataByStore();
        this.setDisabledAccountNameForm(this.$data.ucomAccountId);
        return;
      }

      // 更新の場合、値を取得して設定
      if (ucomAccountId) {
        // アカウントID管理 入力・更新 初期表示用 アカウント情報取得
        const accountInputInitInfo = await SpfApiAccountManagementAccessor.getAccountInputInitInfo(ucomAccountId);
        // 画面項目データをセット
        this.$data.accountName = accountInputInitInfo.accountName;
        this.$data.familyName = accountInputInitInfo.familyName;
        this.$data.firstName = accountInputInitInfo.firstName;
        this.$data.familyNameKana = accountInputInitInfo.familyNameKana;
        this.$data.firstNameKana = accountInputInitInfo.firstNameKana;
        this.$data.userName = accountInputInitInfo.userName;
        this.$data.password = ObfuscationService.decode(accountInputInitInfo.password);
        this.$data.passwordConf = ObfuscationService.decode(accountInputInitInfo.password);
        this.$data.accountAuthorization = accountInputInitInfo.accountAuthorization;

        this.setDisabledAccountNameForm(ucomAccountId);
        return;
      }

      // 会員情報取得
      const member = this.$store.getters['memberStore/member'];
      // 登録の場合、アカウント上限チェックを実行
      const accountRegistrationMax = await SpfApiAccountManagementAccessor.checkAccountRegistrationMax(member.id);
      // アカウント登録上限の場合
      if (accountRegistrationMax.isAccountRegistrationMax) {
        this.$data.isAccountRegistrationMax = accountRegistrationMax.isAccountRegistrationMax;
        this.$data.errorMessages.push(accountRegistrationMax.message);
        this.$data.isOneSentence = true;
      }
      // 登録の場合 ユーザ権限にチェック
      this.$data.accountAuthorization = 1;
    },
    // storeからデータをセット
    setDataByStore(): void {
      this.$data.accountName = this.$store.getters['ucomAccountManagementInputStore/accountName'];
      this.$data.familyName = this.$store.getters['ucomAccountManagementInputStore/familyName'];
      this.$data.firstName = this.$store.getters['ucomAccountManagementInputStore/firstName'];
      this.$data.familyNameKana = this.$store.getters['ucomAccountManagementInputStore/familyNameKana'];
      this.$data.firstNameKana = this.$store.getters['ucomAccountManagementInputStore/firstNameKana'];
      this.$data.userName = this.$store.getters['ucomAccountManagementInputStore/userName'];
      this.$data.password = ObfuscationService.decode(this.$store.getters['ucomAccountManagementInputStore/password']);
      this.$data.passwordConf = ObfuscationService.decode(this.$store.getters['ucomAccountManagementInputStore/password']);
      this.$data.accountAuthorization = this.$store.getters['ucomAccountManagementInputStore/accountAuthorization'];
      this.$data.ucomAccountId = this.$store.getters['ucomAccountManagementInputStore/ucomAccountId'];
    },
    // 戻るボタンクリック
    async onBack(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      // 前画面から保持している値を削除
      this.$store.commit('ucomAccountManagementStore/ucomAccountId', undefined);
      this.$store.commit('ucomAccountManagementInputStore/accountName', undefined);

      // アカウントID管理：一覧画面へ遷移
      await this.$router.push('/ucom/account-management/list');
      this.$data.isLoading = false;
    },
    // 次へボタンクリック
    async onNext() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      // エラーメッセージ初期化
      this.$data.errorMessages = [];

      // バリデーションチェック
      if (this.hasValidationError()) {
        // エラーメッセージを見せるために画面最上部にスクロール
        this.scrollPageTop();
        this.isLoading = false;
        return;
      }

      try {
        // APIチェック
        const accountManagementInputCheck = await SpfApiAccountManagementAccessor.checkAccountManagementInput(
          this.$data.accountName,
          this.$data.familyName,
          this.$data.firstName,
          this.$data.familyNameKana,
          this.$data.firstNameKana,
          this.$data.userName,
          ObfuscationService.encode(this.$data.password),
          ObfuscationService.encode(this.$data.passwordConf)
        );

        // エラーメッセージがあった場合
        if (accountManagementInputCheck.errorMessages.length > 0) {
          this.$data.errorMessages = accountManagementInputCheck.errorMessages;
          // エラーメッセージを見せるために画面最上部にスクロール
          this.scrollPageTop();
          this.isLoading = false;
          return;
        }
      } catch (error) {
        await this.$router.push('/error');
        return;
      }

      // メールボックス容量情報をstoreに保存
      this.saveStore();

      // 確認画面へ遷移
      await this.$router.push('/ucom/account-management/confirm');
      this.$data.isLoading = false;
    },
    // storeへ保存
    saveStore(): void {
      this.$store.commit('ucomAccountManagementInputStore/accountName', this.$data.accountName);
      this.$store.commit('ucomAccountManagementInputStore/familyName', this.$data.familyName);
      this.$store.commit('ucomAccountManagementInputStore/firstName', this.$data.firstName);
      this.$store.commit('ucomAccountManagementInputStore/familyNameKana', this.$data.familyNameKana);
      this.$store.commit('ucomAccountManagementInputStore/firstNameKana', this.$data.firstNameKana);
      this.$store.commit('ucomAccountManagementInputStore/userName', this.$data.userName);
      this.$store.commit('ucomAccountManagementInputStore/password', (this.$data.password = ObfuscationService.encode(this.$data.password)));
      this.$store.commit('ucomAccountManagementInputStore/accountAuthorization', this.$data.accountAuthorization);
      this.$store.commit('ucomAccountManagementInputStore/ucomAccountId', this.$data.ucomAccountId);
    },
    // ページTOPへ
    scrollPageTop() {
      window.scrollTo(0, 0);
    },
    onPasswordView() {
      this.isPasswordView = !this.isPasswordView;
    },
    onPasswordViewConf() {
      this.isPasswordViewConf = !this.isPasswordViewConf;
    },
    // 更新の場合アカウント名の入力欄をdisabled制御
    setDisabledAccountNameForm(accountId: string) {
      if (accountId) {
        this.$data.isDisabled = true;
      }
    },
    hasValidationError(): boolean {
      const ACCOUNT_NAME_MIN_LENGTH = 3;
      const ACCOUNT_NAME_MAX_LENGTH = 15;
      const USER_NAME_MAX_LENGTH = 32;
      const PASSWORD_MAX_LENGTH = 16;
      const PASSWORD_CONF_MAX_LENGTH = 16;

      const ERROR_ACCOUNT_NAME_REQUIRED = '「アカウント名」を入力してください。';
      const ERROR_FAMILY_NAME_REQUIRED = '「氏名（姓）」を入力してください。';
      const ERROR_FIRST_NAME_REQUIRED = '「氏名（名）」を入力してください。';
      const ERROR_FAMILY_NAME_KANA_REQUIRED = '「氏名カナ（姓）」を入力してください。';
      const ERROR_FIRST_NAME_KANA_REQUIRED = '「氏名カナ（名）」を入力してください。';
      const ERROR_USER_NAME_REQUIRED = '「ユーザーネーム」を入力してください。';
      const ERROR_PASSWORD_REQUIRED = '「パスワード」を入力してください。';
      const ERROR_PASSWORD_CONF_REQUIRED = '「パスワード（確認用）」を入力してください。';
      const ERROR_ACCOUNT_AUTHORIZATION_REQUIRED = '「アカウント権限種別」を入力してください。';

      const ERROR_ACCOUNT_NAME_LENGTH = '「アカウント名」は' + ACCOUNT_NAME_MIN_LENGTH + '文字以上' + ACCOUNT_NAME_MAX_LENGTH + '文字以下で入力してください。';
      const ERROR_USER_NAME_LENGTH = '「ユーザーネーム」は' + USER_NAME_MAX_LENGTH + '文字以下で入力してください。';
      const ERROR_PASSWORD_LENGTH = '「パスワード」は' + PASSWORD_MAX_LENGTH + '文字以下で入力してください。';
      const ERROR_PASSWORD_CONF_LENGTH = '「パスワード（確認用）」は' + PASSWORD_CONF_MAX_LENGTH + '文字以下で入力してください。';

      const ERROR_ACCOUNT_NAME_FORMAT = '「アカウント名」は半角英字小文字、半角数字、半角記号(-)で入力してください。';
      const ERROR_FAMILY_NAME_KANA_FORMAT = '「氏名カナ（姓）」は全角カナで入力してください。';
      const ERROR_FIRST_NAME_KANA_FORMAT = '「氏名カナ（名）」は全角カナで入力してください。';
      const ERROR_USER_NAME_FORMAT = '「ユーザーネーム」は全角、半角英数字、半角記号(-_.)で入力してください。';
      const ERROR_PASSWORD_FORMAT = '「パスワード」は半角英数字、半角記号(-_.)で入力してください。';
      const ERROR_PASSWORD_CONF_FORMAT = '「パスワード（確認用）」は半角英数字、半角記号(-_.)で入力してください。';

      // 必須チェック
      if (!this.$data.accountName) {
        this.$data.errorMessages.push(ERROR_ACCOUNT_NAME_REQUIRED);
      }
      if (!this.$data.familyName) {
        this.$data.errorMessages.push(ERROR_FAMILY_NAME_REQUIRED);
      }
      if (!this.$data.firstName) {
        this.$data.errorMessages.push(ERROR_FIRST_NAME_REQUIRED);
      }
      if (!this.$data.familyNameKana) {
        this.$data.errorMessages.push(ERROR_FAMILY_NAME_KANA_REQUIRED);
      }
      if (!this.$data.firstNameKana) {
        this.$data.errorMessages.push(ERROR_FIRST_NAME_KANA_REQUIRED);
      }
      if (!this.$data.userName) {
        this.$data.errorMessages.push(ERROR_USER_NAME_REQUIRED);
      }
      if (!this.$data.password) {
        this.$data.errorMessages.push(ERROR_PASSWORD_REQUIRED);
      }
      if (!this.$data.passwordConf) {
        this.$data.errorMessages.push(ERROR_PASSWORD_CONF_REQUIRED);
      }
      if (!this.$data.accountAuthorization) {
        this.$data.errorMessages.push(ERROR_ACCOUNT_AUTHORIZATION_REQUIRED);
      }

      // 桁数チェック
      if (this.$data.accountName.length < ACCOUNT_NAME_MIN_LENGTH || ACCOUNT_NAME_MAX_LENGTH < this.$data.accountName.length) {
        this.$data.errorMessages.push(ERROR_ACCOUNT_NAME_LENGTH);
      }
      if (USER_NAME_MAX_LENGTH < this.$data.userName.length) {
        this.$data.errorMessages.push(ERROR_USER_NAME_LENGTH);
      }
      if (PASSWORD_MAX_LENGTH < this.$data.password.length) {
        this.$data.errorMessages.push(ERROR_PASSWORD_LENGTH);
      }
      if (PASSWORD_CONF_MAX_LENGTH < this.$data.passwordConf.length) {
        this.$data.errorMessages.push(ERROR_PASSWORD_CONF_LENGTH);
      }

      // フォーマットチェック
      if (this.$data.accountName.match(/[a-z][-a-z0-9]{1,13}[a-z0-9]/)) {
        this.$data.errorMessages.push(ERROR_ACCOUNT_NAME_FORMAT);
      }
      if (!this.$data.familyNameKana.match(/^[ァ-ヴー]+$/)) {
        this.$data.errorMessages.push(ERROR_FAMILY_NAME_KANA_FORMAT);
      }
      if (!this.$data.firstNameKana.match(/^[ァ-ヴー]+$/)) {
        this.$data.errorMessages.push(ERROR_FIRST_NAME_KANA_FORMAT);
      }
      if (!this.isUserName(this.$data.userName)) {
        this.$data.errorMessages.push(ERROR_USER_NAME_FORMAT);
      }
      if (this.$data.password.match(/[^a-zA-Z0-9\-_\.]+/)) {
        this.$data.errorMessages.push(ERROR_PASSWORD_FORMAT);
      }
      if (this.$data.passwordConf.match(/[^a-zA-Z0-9\-_\.]+/)) {
        this.$data.errorMessages.push(ERROR_PASSWORD_CONF_FORMAT);
      }

      return this.$data.errorMessages.length > 0 ? true : false;
    },
    isUserName(value: string) {
      // 許容する半角文字(半角英数字記号[-_.])をパラメータから削除
      const removeValue = value.replace(/[a-zA-Z0-9\-_\.]/g, '');

      // 値が半角文字のみの場合
      if (removeValue === '' && value.match(/[a-zA-Z0-9\-_\.]+/)) {
        return true;
      }

      // 全角ひらがな、カタカナ、漢字
      return removeValue.match(/^[-_.a-zA-Z0-9\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF]{1,32}$/) ? true : false;
    },
  },
});
