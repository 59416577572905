

















































































import Vue from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiVirusCheckAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-virus-check-accessor';

export default Vue.extend({
  name: 'virus-check-cancel-confirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data(): {
    isBusy: boolean;
    errorMessageTitle: string;
    errorMessages: string[];
  } {
    return {
      isBusy: false,
      errorMessageTitle: '',
      errorMessages: [],
    };
  },

  methods: {
    /**
     * 「解約」のクリックハンドラ
     */
    async cancelVirusCheck(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isBusy = true;
      try {
        // ウィルスチェック解約 API を呼び出す
        const eMansionVirusCheckCancelResponseDto = await SpfApiVirusCheckAccessor.cancelVirusCheck(this.mailAddressId);
        if (eMansionVirusCheckCancelResponseDto.message) {
          this.errorMessages = [eMansionVirusCheckCancelResponseDto.message];
          this.isBusy = false;
          return;
        }

        // 処理対象のメールアドレス, サービス終了日を Store に保存する
        this.$store.commit('eMansionMailStore/setSelectedMailAddress', this.mailAddress);
        this.$store.commit('eMansionMailStore/setSelectedCancelOn', new Date(eMansionVirusCheckCancelResponseDto.cancelOn));
        // 完了 (解約) 画面に遷移する
        this.$router.push('/e-mansion/mail/virus-check-cancel-complete');
      } catch (error) {
        await this.$router.push('/e-mansion/error');
      }
    },

    /**
     * 「戻る」のクリックハンドラ
     */
    goBack(): void {
      this.$router.push('/e-mansion/mail/usage');
    },
  }, // methods

  computed: {
    /** ローディング中かどうかの判定 */
    isLoading(): boolean {
      return this.isBusy;
    }, // isLoading

    /** 処理対象のメールアドレスID */
    mailAddressId(): number {
      const mailAddressId = this.$store.getters['eMansionMailStore/selectedMailAddressId'];

      if (!mailAddressId) {
        throw new Error();
      }

      return mailAddressId;
    },

    /** 処理対象のメールアドレス */
    mailAddress(): string {
      const mailAddress = this.$store.getters['eMansionMailStore/selectedMailAddress'];

      if (!mailAddress) {
        throw new Error();
      }

      return mailAddress;
    },
  }, // computed
});
