




































































































import Vue from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { EMansionMainMailAddressInput } from '@/shared/classes/spf-api/mail/model/main-mail-address/e-mansion-main-mail-address-input';
import { SpfApiMainMailAddressAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-main-mail-address-accessor';
import { hasEMansionOriginalDomain } from '@/shared/const/e-mansion';
import { EMansionAccountInfo } from '@/shared/classes/spf-api/mail/model/account/e-mansion-account-info';

export default Vue.extend({
  name: 'eMansionMailMailInput',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    /** 現在のメールアドレス */
    mailAddress: null,
    /** 新しいメールアドレス */
    newMailAddress: null,
    /** メールアドレスドメイン */
    mailAddressDomain: process.env.VUE_APP_E_MANSION_MAIL_SUBDOMAIN + '.' + process.env.VUE_APP_E_MANSION_DOMAIN,
    /** メールアドレス変更に伴う注意事項表示有無 */
    isDisplayInfo: false,
    /** エラーメッセージを格納する配列 */
    errorMessages: [],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    // onNextメソッドが実行中かどうか
    isOnNextExecuting: false,
    /** メールアドレス入力チェック結果 */
    eMansionMainMailaddressInput: EMansionMainMailAddressInput,
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    //メインメールアドレス情報をAPIより取得する
    try {
      // 現在のメールアドレスを取得(共通情報より取得)
      const accountInfo: EMansionAccountInfo = await this.$store.getters['eMansionAccountStore/eMansionAccountInfo'];
      this.$data.mailAddress = accountInfo.eMansionMailAddressInfo?.mailAddress;

      // 新しいメールアドレスを取得
      this.$data.newMailAddress = await this.$store.getters['eMansionMainMailAddressStore/newMailAddress'];

      // 物件基本情報をStoreから取得
      const property: EMansionProperty = await this.$store.getters['eMansionCommonStore/property'];
      if (!property) {
        await this.$router.push('/e-mansion/error');
        return;
      }

      // メールアドレス変更に伴う注意事項表示有無を判定
      this.$data.isDisplayInfo = this.$data.mailAddress != undefined;

      // 独自ドメイン有無判定(1:あり、0:なし)
      if (hasEMansionOriginalDomain(property.em_op_mail_flag)) {
        this.$data.mailAddress = accountInfo.eMansionMailAddressInfo?.mailAddressOriginal;
        this.$data.mailAddressDomain = property.original_domain_name;
      }
    } catch (error) {
      await this.$router.push('/e-mansion/error');
      return;
    }
  },
  methods: {
    /** 次へボタン押下時： メインメールアドレス登録変更：確認画面に遷移する */
    async goNext() {
      if (this.isOnNextExecuting) {
        return;
      }
      this.isOnNextExecuting = true;

      try {
        // エラーメッセージ格納配列初期化
        this.$data.errorMessages = [];

        // 入力チェック
        // 新しいメールアドレス
        const newMailAddress = String(this.$data.newMailAddress);

        if (newMailAddress == '' || newMailAddress == 'null') {
          this.$data.errorMessages.push('「新しいメールアドレス」を入力してください。');
        } else if (!newMailAddress.match(/^[a-z0-9\_-]+$/)) {
          this.$data.errorMessages.push('「新しいメールアドレス」は、半角英数小文字と「-（ハイフン）」「_（アンダーバー）」の組み合わせで指定してください。');
        } else if (!(newMailAddress.length >= 3 && newMailAddress.length <= 12)) {
          this.$data.errorMessages.push('「新しいメールアドレス」は、3文字以上12文字以内で入力してください。');
        }

        if (this.$data.errorMessages.length > 0) {
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          this.isOnNextExecuting = false;
          return;
        }

        // メールアドレス入力チェックをAPIにて行う
        const newMailAddressAddDomain = newMailAddress + '@' + String(this.$data.mailAddressDomain);
        this.$data.eMansionMainMailaddressInput = await SpfApiMainMailAddressAccessor.checkMainMailAddress(newMailAddressAddDomain);

        // チェック結果をエラーメッセージ配列に追加
        if (!this.$data.eMansionMainMailaddressInput.checkResult) {
          this.$data.errorMessages.push(...this.$data.eMansionMainMailaddressInput.checkMessages);
        }

        if (this.$data.errorMessages.length > 0) {
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          this.isOnNextExecuting = false;
          return;
        }

        //メールアドレスをstoreに保存する
        this.$store.commit('eMansionMainMailAddressStore/mailAddress', this.$data.mailAddress);
        this.$store.commit('eMansionMainMailAddressStore/newMailAddress', newMailAddress);
        this.$store.commit('eMansionMainMailAddressStore/newMailAddressAddDomain', newMailAddressAddDomain);


      } catch (error) {
        // エラー画面に遷移
        await this.$router.push('/e-mansion/error');
      }

      // メインメールアドレス登録・変更：確認画面へ遷移
      await this.$router.push('/e-mansion/main-mail-address/confirm');
      this.isOnNextExecuting = false;
    },
  },
});
