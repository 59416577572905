



































































































































































































































import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { EMansionSubAccountInfo } from '@/shared/classes/spf-api/mail/model/sub-account/e-mansion-sub-account-info';
import { subAccountData } from '@/shared/classes/spf-api/mail/model/sub-account/e-mansion-sub-account-data';
import { SpfApiMainMailAddressAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-main-mail-address-accessor';
import { EMansionMainMailAddressInput } from '@/shared/classes/spf-api/mail/model/main-mail-address/e-mansion-main-mail-address-input';
import { SpfApiSubAccountAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-sub-account-accessor';
import { SpfApiPaymentStatusAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-payment-status-accessor';
import { E_MANSION_PAYMENT_STATUS } from '@/shared/const/e-mansion/e-mansion-payment-status';
import { E_MANSION_SUB_ACCOUNT_LIMIT } from '@/shared/const/e-mansion/e-mansion-sub-account-limit';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { hasEMansionOriginalDomain } from '@/shared/const/e-mansion';
import { AuthService } from '@/shared/services/auth/auth-service';
import { TRANSITION_STORAGE } from '@/shared/const/service-type';

export default Vue.extend({
  name: 'list',
  components: {
    LoadingComponent,
  },

  data: () => ({
    /**AE17-001の返り値 */
    subAccountInfo: EMansionSubAccountInfo,
    subAccountData: [] as subAccountData[],
    /**お支払情報登録状態 */
    paymentInfo: false,
    isNotMaxSubAccount: true, //追加アカウントの上限に達していると申し込みできない(上限だとfalse)
    isRegisterPayment: true, //スリムプランの時お支払登録状態が登録されていなければfalse
    canApply: true,
    slimPlan: false,
    isLoading: true,
    isErr: false,
    isView: true,
    inputMailAddress: '',
    mailAddress: '',
    messageList: [] as string[],
    tax: '',
    additionalAccountUrl: '',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      this.$data.tax = this.$store.getters['taxStore/tax'];
      if (this.$store.getters['servicePlanTypeStore/servicePlanType'] == SERVICE_PLAN_TYPE.EM_SLIM) {
        this.$data.slimPlan = true;
      }
      if (this.$store.getters['servicePlanTypeStore/servicePlanType'] == SERVICE_PLAN_TYPE.EM_SLIM_SPECIAL) {
        this.$data.slimPlan = true;
      }
      const memberId = this.$store.getters['memberStore/member'].id;
      const apartmentId = this.$store.getters['propertyStore/property'].apartmentId;
      const slimPlanFlg: string = this.$data.slimPlan ? '1' : '0';
      this.$data.subAccountInfo = await SpfApiSubAccountAccessor.getSubAccountList(apartmentId, memberId, slimPlanFlg);
      if (this.$data.subAccountInfo.countSubAccount == 0) {
        this.$data.isView = false;
      }
      if (this.$data.subAccountInfo.countSubAccount >= E_MANSION_SUB_ACCOUNT_LIMIT.limit) {
        this.isNotMaxSubAccount = false;
      }
      this.subAccountData = this.$data.subAccountInfo.subAccountData;
      if ((await SpfApiPaymentStatusAccessor.getPaymentStatus(memberId)).status == E_MANSION_PAYMENT_STATUS.REGISTERED) {
        this.$data.paymentInfo = true;
      }
      this.inputMailAddress = this.$store.getters['eMansionSubAccountStore/desiredAccount'];
      this.$data.isRegisterPayment = this.checkRegisterPayment(this.$data.paymentInfo, this.$data.slimPlan);
      this.$data.canApply = this.$data.isNotMaxSubAccount && this.$data.isRegisterPayment;
      this.$data.isLoading = false;
      this.$data.isErr = this.isErrMsg(this.$data.isRegisterPayment, this.messageList);
      this.$data.additionalAccountUrl = `${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_E_MANSION_SUB_ACCOUNT_LINKING_PAGE_FOR_DISPLAY}`;
    } catch (error) {
      await this.$router.push('/e-mansion/error');
      return;
    }
  },
  methods: {
    /**解約ボタンクリック */
    async onCancel(account: string, mailAddress: string, eMansionAccountId: number) {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      this.$store.commit('eMansionSubAccountStore/account', account);
      this.$store.commit('eMansionSubAccountStore/mailAddress', mailAddress);
      this.$store.commit('eMansionSubAccountStore/eMansionAccountId', eMansionAccountId);
      await this.$router.push('/e-mansion/sub-account/cancel-confirm');
    },
    /**申し込むボタンクリック*/
    async onApply() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      this.messageList = [];
      this.$data.isErr = false;
      if (!this.inputMailAddress) {
        this.$data.isErr = true;
        this.messageList.push('「ご希望アカウント」を入力してください。');
        window.scrollTo(0, 0);
        this.$data.isLoading = false;
        return;
      }
      if (this.inputMailAddress.length < 3) {
        this.messageList.push('「ご希望アカウント」は、3文字以上12文字以内で入力してください。');
      }
      if (this.inputMailAddress.length > 12) {
        this.messageList.push('「ご希望アカウント」は、3文字以上12文字以内で入力してください。');
      }
      if (!this.inputMailAddress.match(/^[a-z0-9\_-]+$/)) {
        this.messageList.push('「ご希望アカウント」は、半角英数小文字と「-（ハイフン）」「_（アンダーバー）」の組み合わせで指定してください。');
      }
      if (!this.inputMailAddress.charAt(0).match(/[a-z]/)) {
        this.messageList.push('「ご希望アカウント」の先頭1文字は英字（a~z）で指定してください。');
      }
      if (this.inputMailAddress.charAt(this.inputMailAddress.length - 1).match(/[_-]/)) {
        this.messageList.push('-（ハイフン）や_（アンダーバー）で終わるURLは指定できません。');
      }
      if (this.messageList.length > 0) {
        this.$data.isErr = true;
        window.scrollTo(0, 0);
        this.$data.isLoading = false;
        return;
      }
      const emOpMailFlag = this.$store.getters['eMansionCommonStore/property'].em_op_mail_flag;
      this.$data.mailAddress = this.$data.inputMailAddress + '@' + process.env.VUE_APP_E_MANSION_MAIL_SUBDOMAIN + '.' + process.env.VUE_APP_E_MANSION_DOMAIN;
      if (hasEMansionOriginalDomain(emOpMailFlag)) {
        this.$data.mailAddressDomain = this.$store.getters['eMansionCommonStore/property'].original_domain_name;
        this.$data.mailAddress = this.$data.inputMailAddress + '@' + this.$data.mailAddressDomain;
      }
      const mailAddressInputChackResult: EMansionMainMailAddressInput = await SpfApiMainMailAddressAccessor.checkMainMailAddress(this.$data.mailAddress);

      if (!mailAddressInputChackResult.checkResult) {
        this.$data.isErr = true;
        this.messageList = mailAddressInputChackResult.checkMessages;
        window.scrollTo(0, 0);
        this.$data.isLoading = false;
        return;
      }
      //エラーがない場合

      this.$store.commit('eMansionSubAccountStore/desiredAccount', this.$data.inputMailAddress);
      this.$store.commit('eMansionSubAccountStore/usageFee', this.$data.subAccountInfo.mailPackFee);
      await this.$router.push('/e-mansion/sub-account/confirm');
    },
    async goTop() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      await this.$router.push('/e-mansion');
      this.$data.isLoading = false;
    },
    /**
     * スリムプランかつお支払情報が登録されていなければfalseを返す
     * @param paymentInfo お支払情報登録状態
     * @param slimPlan スリムプランフラグ
     */
    checkRegisterPayment(paymentInfo: boolean, slimPlan: boolean): boolean {
      if (paymentInfo) {
        return true;
      }
      if (!slimPlan) {
        return true;
      }
      //非活性であるという情報を保存
      this.$store.commit('eMansionSubAccountStore/canApply', false);
      return false;
    },
    isErrMsg(isRegisterPayment: boolean, messageList: string[]): boolean {
      if (!isRegisterPayment) {
        return true;
      }
      if (messageList.length > 0) {
        return true;
      }
      return false;
    },
    /** ログアウト処理 */
    async onLogout() {
      await AuthService.logoutWithFederatedTrue();
      window.open(`${process.env.VUE_APP_BASE_URL}` + process.env.VUE_APP_E_MANSION_LOGIN_ADDITIONAL_ACCOUNT);
    },
  },
});
