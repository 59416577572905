








































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { EMansionCustomer, EMansionEmailAuthenticationKeyResponse } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionProperty, EmInfoMessage } from '@/shared/classes/external-api/e-mansion/property-response';
import { EMansionRoomsResponseResult, EMansionRoomsDetail } from '@/shared/classes/external-api/e-mansion/rooms-response';
import Vue from 'vue';
import { Property } from '../../shared/classes/spf-api/property';
import { Member } from '@/shared/classes/spf-api/member';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { AuthService } from '@/shared/services/auth/auth-service';
import { EMansionSharedExternalApiService } from '@/shared/services/external-api/e-mansion-shared-external-api-service';
import { EMansionEmailAuthenticationKeyRequest, EMansionEmailAuthenticationKeyRequestResult } from '@/shared/classes/external-api/e-mansion/customer-request';
import { required } from 'vuelidate/lib/validators';
import { halfWidth } from '@/shared/util/validators';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import { UA_TYPE } from '@/shared/const/service-type';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import PermissionControledRouterLink from '@/shared/components/permission-controled-router-link.vue';
import PermissionControledListLink from '@/shared/components/permission-controled-list-link.vue';
import PermissionControledSpanLink from '@/shared/components/permission-controled-span-link.vue';
import PermissionControledMenuLink from '@/shared/components/permission-controled-menu-link.vue';
import { SERVICE_ID } from '@/shared/const/service-ids';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { E_MANSION_ACCOUNT_AUTHORITY } from '@/shared/const/e-mansion/e-mansion-account-authority';
import { EMansionAccountInfo } from '@/shared/classes/spf-api/mail/model/account/e-mansion-account-info';
import { E_MANSION_ISP_STATUS } from '@/shared/const/e-mansion/e-mansion-isp-status';
import { E_MANSION_DEFAULTER_STATUS } from '@/shared/const/e-mansion/e-mansion-defaulter-status';
import { EMansionMailAddress } from '@/shared/classes/spf-api/mail/model/mail-address/e-mansion-mail-address';
import { MYE_REGISTER_STATUS } from '@/shared/const/e-mansion';
import { XMAIL_REGISTER_STATUS } from '@/shared/const/e-mansion/e-mansion-xmail-register-status';
import { XMAIL_PASSWORD_REGISTER_STATUS } from '@/shared/const/e-mansion/e-mansion-xmail-password-register-status';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { XmailEMansionWebMailLoginService } from '@/shared/services/xmail/xmail-e-mansion-web-mail-login-service';

type DataType = {
  customer: EMansionCustomer | null;
  property: EMansionProperty | null;
  rooms: Array<EMansionRoomsDetail> | null;
  /** メール認証キーの設定エリア 表示/非表示 */
  isEmailAuthenticationKeySettingDisplay: boolean;
  /** メール認証キー */
  emailAuthenticationKey: string;
  /** メール認証キー設定 成功/失敗 */
  isCompletedEmailAuthenticationKeySetting: boolean;
  /** メール認証キー設定完了メッセージ */
  completedEmailAuthenticationKeySettingMessage: string;
  /** メール認証キー設定エラーメッセージを格納する配列 */
  messageCheckEmailAuthenticationKey: Array<string>;
  displayEmInfoTop: boolean;
  /** 物件情報に掲載するお知らせ */
  emInfoTopMessages: EmInfoMessage[] | null;
  isSlimPlan?: boolean;
  isApplyConnectix: boolean;
  /**ランダムで設定する9桁の数字を当てはめる */
  rd: string;
  /** フッターに表示するバナー */
  banners: Array<{ url: string; src: string }>;
  /** e-mantion My の URL */
  eMansionMyUrl: string;
  /** e-mantion の URL */
  eMansionUrl: string;
  /** ConnectixLPのURL */
  connectixLpUrl: string;
  // メール認証キーチェック中かどうか
  isCreatingEmailAuthenticationKey: boolean;
  ScrollHint: any;
  // 契約内容案内の表示フラグ
  contractFlag: boolean;
  // Connectix契約/未契約のフラグ true:契約済み,false:未契約
  isContractConnectixFlag: boolean;
  // メールご利用状況 の機能ID
  functionIdMailUsage: string;
  // メインメールアドレス の機能ID
  functionIdMainMail: string;
  // メールパスワード変更 の機能ID
  functionIdMailPassword: string;
  // WEBメール の機能ID
  functionIdWebMail: string;
  // 追加メールアドレス の機能ID
  functionIdAddAccount: string;
  // メールパック の機能ID
  functionIdMailPack: string;
  // ホームページ開設 の機能ID
  functionIdHp: string;
  // ホームページ容量変更 の機能ID
  functionIdHpVolume: string;
  // ご利用状況確認
  functionIdLinkUsage: string;
  // クレジットカード情報登録変更
  functionIdLinkCreditCard: string;
  // 会員情報変更
  functionIdLinkMembersInfoChange: string;
  // 契約内容のご案内
  functionIdLinkContractContentGuidance: string;
  // ご請求明細・販売証明書表示
  functionIdLinkBillingItemSalesCertificateDisplay: string;
  // e-mansion お知らせメールの配信設定
  functionIdLinkNewsEmailDeliveryPreference: string;
  // ISP会員契約の解除
  functionIdLinkIspMemberContractCancellation: string;
  // オプションサービスはこちら
  functionIdLinkOptionServilce: string;
  // 退会状況
  isWithdrawn: boolean;
  // アカウント種別
  accountType: E_MANSION_ACCOUNT_AUTHORITY | undefined;
  // 会員種別
  planType: SERVICE_PLAN_TYPE | undefined;
  //追加アカウントかどうか
  isAdditional: boolean;
  // 滞納状況
  isDefaulter: boolean;
  // 会員単位_メールアドレス有無
  hasMailAddressAsMember: boolean;
  // 会員単位_メインメールアドレス有無
  hasMainMailAddressAsMember: boolean;
  // ログインユーザ_ホームページ有無
  hasHomepageAsLoginUser: boolean;
  // ホームページ容量リンク表示有無
  isOpenedHomepage: boolean;
  //メールパスワード変更リンク活性
  canChangeMailPassword: boolean;
  // WEBメールのリンク URL
  xmailUrl: string;
  // 別タブの名前
  tabTarget: string;
  // ISP退会済みフラグ
  isIspCancelled: boolean;
  // 退会お申し込み中フラグ
  isApplyCancelIsp: boolean;
  // ISP(e-mansion)退会済みログイン可能期間に表示する 請求明細・販売証明書を確認できる 「My e-mansion」 のURL
  billingDetailsURL: string;
};

/** 画面要素を表示するかの判定に使う、退会状況・アカウント種別・会員種別の条件パターン */
type MemberConditionPattern = {
  additional: boolean; // 追加アカウントでも表示するかどうか
  normal: boolean; // 通常会員なら表示する
  slim: boolean; // スリムプランなら表示する
  ttp: boolean; // TTP なら表示する
  mailFree: boolean; // メール会員(無償)なら表示する
  mailCharge: boolean; // メール会員(有償)なら表示する
};

/** e-mansion Index コンポーネント */
export default Vue.extend({
  name: 'e-mansion-index',
  components: {
    PermissionControledRouterLink,
    PermissionControledListLink,
    PermissionControledSpanLink,
    PermissionControledMenuLink,
  },
  data(): DataType {
    return {
      customer: new EMansionCustomer({}),
      property: null,
      rooms: null,
      isEmailAuthenticationKeySettingDisplay: false,
      emailAuthenticationKey: '',
      isCompletedEmailAuthenticationKeySetting: false,
      completedEmailAuthenticationKeySettingMessage: '',
      messageCheckEmailAuthenticationKey: [],
      displayEmInfoTop: false,
      emInfoTopMessages: [],
      isSlimPlan: undefined,
      isApplyConnectix: false,
      rd: '',
      banners: [],
      eMansionMyUrl: process.env.VUE_APP_E_MANSION_MY_URL,
      eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
      connectixLpUrl: process.env.VUE_APP_E_MANSION_LP_URL,
      isCreatingEmailAuthenticationKey: false,
      ScrollHint: require('../../shared/libraries/scroll-hint/scroll-hint'),
      contractFlag: false,
      isContractConnectixFlag: false,
      isIspCancelled: false,
      isApplyCancelIsp: false,
      billingDetailsURL: process.env.VUE_APP_E_MANSION_BILLING_DETAILS_URL,
      functionIdMailUsage: SERVICE_ID.MAIL_VIRUS_CHECK,
      functionIdMainMail: SERVICE_ID.MAIN_MAIL_ADDRESS_USAGE,
      functionIdMailPassword: SERVICE_ID.MAIL_PASSWORD,
      functionIdWebMail: SERVICE_ID.WEB_MAIL,
      functionIdAddAccount: SERVICE_ID.MAIL_ACCOUNT_ADDITION,
      functionIdMailPack: SERVICE_ID.MAIL_ACCOUNT_ADDITION,
      functionIdHp: SERVICE_ID.HOMEPAGE_USAGE,
      functionIdHpVolume: SERVICE_ID.HOMEPAGE_VOLUME,
      functionIdLinkUsage: SERVICE_ID.E_MANSION.LINK_E_MANSION_USAGE,
      functionIdLinkCreditCard: SERVICE_ID.E_MANSION.LINK_E_MANSION_CREDIT,
      functionIdLinkMembersInfoChange: SERVICE_ID.E_MANSION.LINK_E_MANSION_INFO_CHANGE,
      functionIdLinkContractContentGuidance: SERVICE_ID.E_MANSION.LINK_CONTRACT_CONTENT_GUIDANCE,
      functionIdLinkBillingItemSalesCertificateDisplay: SERVICE_ID.E_MANSION.LINK_BILLING_ITEM_SALES_CERTIFICATE_DISPLAY,
      functionIdLinkNewsEmailDeliveryPreference: SERVICE_ID.E_MANSION.LINK_NEWS_EMAIL_DELIVERY_PREFERENCE,
      functionIdLinkIspMemberContractCancellation: SERVICE_ID.E_MANSION.LINK_ISP_MEMBER_CONTRACT_CANCELLATION,
      functionIdLinkOptionServilce: SERVICE_ID.E_MANSION.LINK_ISP_OPTION_SERVICE,
      isWithdrawn: false,
      accountType: undefined,
      planType: undefined,
      isAdditional: false,
      isDefaulter: true,
      hasMailAddressAsMember: false,
      hasMainMailAddressAsMember: false,
      hasHomepageAsLoginUser: false,
      isOpenedHomepage: false,
      canChangeMailPassword: false,
      xmailUrl: 'https://xmail.com', // TODO : 動作確認用。削除すること。
      tabTarget: 'My e-mansion',
    };
  },
  /** バリデーション定義 */
  validations: {
    emailAuthenticationKey: {
      required,
      halfWidth,
    },
  },
  async mounted() {
    try {
      const isLoggedIn = await AuthService.isLoggedIn();
      // ログインしていない場合「総合トップ」画面にリダイレクトする
      if (!isLoggedIn) {
        this.$router.push('/').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      /** 会員取得 */
      const member: Member = this.$store.getters['memberStore/member'];
      // 会員情報に物件が紐づいていない or e-mansionアカウントが紐づいていなければエラーとする
      if (!member.propertyId || !member.primaryKeyMye) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      }

      /** 物件取得 */
      const property: Property = this.$store.getters['propertyStore/property'];
      // 利用可能なe-mansionのサービスが紐付けられていなければエラーとする
      if (!property.apartmentId) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_PROPERTY);
      }

      /** 契約基本情報をStoreから取得 */
      const customer: EMansionCustomer | EMansionSharedErrorResponse = this.$store.getters['eMansionCommonStore/customer'];
      if (customer instanceof EMansionCustomer) {
        // 顧客情報に物件情報が紐付けられていなければエラーとする
        if (!customer.property_id) {
          // データ不整合エラー
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO_DATA_INCONSISTENCT);
        }
        // 正常ケースの場合
        this.customer = customer;
        // メール認証キーの設定欄の表示/非表示
        if (customer.email_authentication_key_setting_display === '0') {
          this.isEmailAuthenticationKeySettingDisplay = true;
        } else {
          this.isEmailAuthenticationKeySettingDisplay = false;
        }
        if (this.customer.contract_document_flag === '1') {
          this.contractFlag = true;
        } else {
          this.contractFlag = false;
        }
        if (this.customer.op_connectix_contract_status === '1') {
          this.isContractConnectixFlag = true;
        } else {
          this.isContractConnectixFlag = false;
        }
        // 退会申込中・退会済み済み判定
        if (customer.quit_date) {
          const buf = customer.quit_date.split('/'); // yyyy/mm/dd を想定
          const utc = Date.UTC(parseInt(buf[0], 10), parseInt(buf[1], 10) - 1, parseInt(buf[2], 10));

          if (utc < new Date().getTime()) {
            this.isIspCancelled = true;
          } else {
            if (customer.status === '01') {
              this.isApplyCancelIsp = true;
            }
            if (customer.status === '03') {
              this.isApplyCancelIsp = true;
            }
          }
        }
      } else {
        // データ不整合エラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }

      // 物件基本情報をStoreから取得
      const eMansionProperty: EMansionProperty | EMansionSharedErrorResponse = this.$store.getters['eMansionCommonStore/property'];
      if (eMansionProperty instanceof EMansionProperty) {
        this.property = eMansionProperty;
        this.isSlimPlan = eMansionProperty.slim_plan_flag === '1';
        if (eMansionProperty.em_info_message !== null) {
          this.displayEmInfoTop = true;
          this.emInfoTopMessages = eMansionProperty.em_info_message;
        }
      } else {
        // データ不整合エラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.PROPERTY_INFO);
      }

      /** 部屋番号一覧をStoreから取得 */
      const propertyRoomParams = { uaType: UA_TYPE.E_MANSION, propertyId: customer.property_id };
      const propertyRooms: EMansionRoomsResponseResult | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/rooms', propertyRoomParams);
      if (propertyRooms instanceof EMansionRoomsResponseResult) {
        this.rooms = propertyRooms.rooms;
      } else {
        // データ不整合エラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.ROOM_INFO);
      }

      // 9桁のランダムな数字を取得
      this.rd = this.createRd();
      // 会員種別を取得
      this.planType = this.$store.getters['servicePlanTypeStore/servicePlanType'];
      if (this.planType == SERVICE_PLAN_TYPE.EM_ADDITIONAL) {
        this.isAdditional = true;
      }
      // Connectixお申し込みの可/不可を取得
      this.isApplyConnectix = this.checkApplyConnectix(eMansionProperty);

      // 退会状況を取得
      this.isWithdrawn = this.isIspStatusUnsubscribe(customer.status);

      // アカウント種別 (メインアカウント/追加アカウント) を取得
      const accountInfo: EMansionAccountInfo = this.$store.getters['eMansionAccountStore/eMansionAccountInfo'];
      this.accountType = accountInfo.eMansionAccountInfo.accountAuthorization;

      // 滞納状況
      this.isDefaulter = this.isIspDefaulter(this.customer.defaulter_status);

      // 会員単位_メールアドレス有無
      this.hasMailAddressAsMember = accountInfo.hasMailAddressAsMember;

      // 会員単位_メインメールアドレス有無
      this.hasMainMailAddressAsMember = accountInfo.hasMainMailAddressAsMember;

      //ログインユーザーのメールアドレス情報
      const mailAsLoginUserInfo = accountInfo.eMansionMailAddressInfo;
      if (mailAsLoginUserInfo) {
        //ステータスチェック（メールPWリンク用）
        this.$data.canChangeMailPassword = this.checkMailStatus(mailAsLoginUserInfo);
      }
      // ログインユーザ_ホームページ有無
      this.hasHomepageAsLoginUser = accountInfo.hasHomepageAsLoginUser;
      // ホームページ容量リンク表示有無
      this.isOpenedHomepage = accountInfo.isOpenedHomepage;
    } catch (error) {
      throw error;
    }

    this.banners = this.findBanner();
    this.$nextTick(() => {
      this.jsScrollable();
    });
    this.$emit('isMemberTopMountingEmansion', false); // pages/index.vue宛てにmounted終了を通知
  },
  methods: {
    /** メール認証キーチェック */
    async createEMansionEmailAuthenticationKey(): Promise<void> {
      // createEMansionEmailAuthenticationKey()が同時に1回以上実行されないようにする
      if (this.isCreatingEmailAuthenticationKey) {
        return;
      }
      this.isCreatingEmailAuthenticationKey = true;

      this.messageCheckEmailAuthenticationKey = [];
      if (this.$v.emailAuthenticationKey?.$invalid) {
        if (!this.$v.emailAuthenticationKey?.required) {
          // 必須エラー
          this.messageCheckEmailAuthenticationKey.push('メール認証キーを入力してください。');
        } else if (!this.$v.emailAuthenticationKey?.halfWidth) {
          // 形式エラー
          this.messageCheckEmailAuthenticationKey.push('メール認証キーは半角英数字で入力してください。');
        }
        this.isCreatingEmailAuthenticationKey = false;
        return;
      }
      const member = this.$store.getters['memberStore/member'];
      const property = this.$store.getters['propertyStore/property'];
      const request = new EMansionEmailAuthenticationKeyRequest({
        ua_type: UA_TYPE.E_MANSION,
        property_id: convert6DigitIdTo8DigitId(property.apartmentId),
        email_authentication_key: this.emailAuthenticationKey,
      });
      const eMansionEmailAuthenticationKeyRequest = new EMansionEmailAuthenticationKeyRequestResult(request);
      const eMansionEmailAuthenticationKeyResponse: EMansionEmailAuthenticationKeyResponse | EMansionSharedErrorResponse =
        await EMansionSharedExternalApiService.createEMansionEmailAuthenticationKey(member.primaryKeyMye, eMansionEmailAuthenticationKeyRequest);
      if (eMansionEmailAuthenticationKeyResponse instanceof EMansionEmailAuthenticationKeyResponse) {
        if (eMansionEmailAuthenticationKeyResponse.result?.processing_result) {
          // メール認証キー設定成功
          this.isCompletedEmailAuthenticationKeySetting = true;
          this.completedEmailAuthenticationKeySettingMessage = 'メール認証キーを設定しました。';
        } else {
          // メール認証キー設定失敗
          this.isCompletedEmailAuthenticationKeySetting = false;
          this.messageCheckEmailAuthenticationKey.push('メール認証キーが正しくありません。');
        }
      } else {
        // メール認証キー設定失敗
        this.isCompletedEmailAuthenticationKeySetting = false;
        this.messageCheckEmailAuthenticationKey.push('メール認証キーが正しくありません。');
      }
      this.isCreatingEmailAuthenticationKey = false;
    },
    /**9桁のランダムな数字を生成 */
    createRd(): string {
      const CODE_TABLE = '0123456789';
      let rd = '';
      for (let i = 0, j = CODE_TABLE.length; i < 9; i++) {
        rd = `${rd}${CODE_TABLE.charAt(Math.floor(j * Math.random()))}`;
      }
      return rd;
    },
    /**Connectixお申し込みの可/不可を判定 */
    checkApplyConnectix(property: EMansionProperty): boolean {
      /**
       * ・Connectix対象物件であること
       * 物件情報取得APIの「SD-WANフラグ（op_sdwan_flag）」が"1"
       */

      if (property.op.sdwan.flag === '1') {
        return true;
      }
      return false;
    },
    /**APIで取得したバナーIDでバナーを検索 */
    findBanner() {
      const bannerList = [
        /** フレッツ光 個人お申し込み用（西・光配線のみ） */
        { url: `${this.$data.eMansionUrl}/${this.apid}/dfn00/`, src: require('../../images/114_bn000114_20200311095931840472.png') },
        /** フレッツ光 物件導入用 */
        { url: `${this.$data.eMansionUrl}/${this.apid}/dfl00/`, src: require('../../images/138_bn000138_20200311095909425454.png') },
        /** 防災グッズ */
        { url: 'http://mg.emlife.jp/lifestyle/bousaigoods_20150901/', src: require('../../images/170_bn000170_20160525112619059170.jpg') },
        /** 【Mcloud】引渡し前の施設予約について */
        { url: `http://www.em-net.ne.jp/${this.apid}/atp05?function=message&message_id=10824`, src: require('../../images/175_bn000175_20170727153849244066.png') },
        /** フレッツ光 個人お申し込み用（東V・西V） */
        { url: `${this.$data.eMansionUrl}/${this.apid}/dfk00/`, src: require('../../images/31_bn000031_20130401101628707021.gif') },
        /** フレッツ光 個人お申し込み用（東・光配線のみ） */
        { url: `${this.$data.eMansionUrl}/${this.apid}/dfm00/`, src: require('../../images/32_bn000032_20200311100014711082.png') },
        /** 三菱地所レジデンスクラブ */
        { url: 'https://www.resiclub.com/', src: require('../../images/74_bn000074_20140114193918073611.jpg') },
        /** Ｂｒｉｌｌｉａ Ｒｅｓｉｄｅｎｃｅ六甲アイランド インターネットコンシェルジュサービス */
        { url: 'https://www2.concierge-service.jp/members/login/574/', src: require('../../images/85_bn000085_20120326115516014461.jpg') },
      ];
      let findBannerResult: { url: string; src: string }[] = [];
      findBannerResult = bannerList.filter((banner) => {
        if (!this.property?.em_bn.em_bn_id) {
          return false;
        }
        return this.property?.em_bn.em_bn_id.find((emBnId: string) => banner.src.includes(emBnId));
      });
      return findBannerResult;
    },
    getKindString(kind: string): string {
      let kindStr = '';
      switch (kind) {
        case '01':
          kindStr = 'お知らせ';
          break;
        case '02':
          kindStr = 'キャンペーン';
          break;
        case '03':
          kindStr = 'メンテナンス';
          break;
        case '04':
        case '05':
        case '06':
          kindStr = '障害';
          break;
        default:
          kindStr = 'お知らせ';
          break;
      }
      return kindStr;
    },
    jsScrollable() {
      new this.ScrollHint('.js-scrollable');
      new this.ScrollHint('.js-scrollable-shadow', {
        suggestiveShadow: true,
      });
    },

    /** ISPの滞納状況判定 */
    isIspDefaulter(status: string): boolean {
      return status === E_MANSION_DEFAULTER_STATUS.DEFAULTER_CREDIT_CARD_REGISTERED;
    },

    /** ISPの会員ステータス判定 */
    isIspStatusUnsubscribe(status: string): boolean {
      return status === E_MANSION_ISP_STATUS.UNSUBSCRIBE || status === E_MANSION_ISP_STATUS.TRANSFER_UNSUBSCRIBE;
    },

    /**
     * 退会状況・アカウント種別・会員種別が与えられたパターンにマッチするか判定する関数
     * @param: condition 判定に使うパターン
     * @return: 判定結果
     */
    matchPlanWithPattern(condition: MemberConditionPattern): boolean {
      // 追加アカウントならマッチしないパターンで、追加アカウントだった場合
      if (!condition.additional && this.accountType === E_MANSION_ACCOUNT_AUTHORITY.ADDITIONAL_ACCOUNT) {
        return false;
      }
      // 通常会員ならマッチするパターンで、通常会員だった場合
      if (condition.normal && this.planType === SERVICE_PLAN_TYPE.EM_NORMAL) {
        return true;
      }
      // スリムプランならマッチするパターンで、スリムプランだった場合
      if (condition.slim && (this.planType === SERVICE_PLAN_TYPE.EM_SLIM || this.planType === SERVICE_PLAN_TYPE.EM_SLIM_SPECIAL)) {
        return true;
      }
      // TTP ならマッチするパターンで、TTP だった場合
      if (condition.ttp && this.planType === SERVICE_PLAN_TYPE.EM_TTP) {
        return true;
      }
      // メール会員(無償)ならマッチするパターンで、メール会員(無償)だった場合
      if (condition.mailFree && this.planType === SERVICE_PLAN_TYPE.EM_MAIL_FREE) {
        return true;
      }
      // メール会員(有償)ならマッチするパターンで、メール会員(有償)だった場合
      if (condition.mailCharge && this.planType === SERVICE_PLAN_TYPE.EM_MAIL_CHARGE) {
        return true;
      }
      return false;
    },
    checkMailStatus(mailInfo: EMansionMailAddress): boolean {
      if (mailInfo.myeRegisterStatus == MYE_REGISTER_STATUS.IN_CONTRACT_APPLICATION) {
        return false;
      }
      if (mailInfo.myeRegisterStatus == MYE_REGISTER_STATUS.CANCELLED) {
        return false;
      }
      if (mailInfo.xMailRegisterStatus == XMAIL_REGISTER_STATUS.IN_CONTRACT_APPLICATION) {
        return false;
      }
      if (mailInfo.xMailRegisterStatus == XMAIL_REGISTER_STATUS.CANCELLED) {
        return false;
      }
      if (mailInfo.xMailPasswordRegisterStatus == XMAIL_PASSWORD_REGISTER_STATUS.IN_CONTRACT_APPLICATION) {
        return false;
      }
      if (mailInfo.xMailPasswordRegisterStatus == XMAIL_PASSWORD_REGISTER_STATUS.CANCELLED) {
        return false;
      }
      return true;
    },

    async webMailLogin(): Promise<void> {
      try {
        const eMansionAccountInfo = this.$store.getters['eMansionAccountStore/eMansionAccountInfo']; // e-mansion アカウント情報取得
        console.log('とおった1');
        console.log(eMansionAccountInfo);

        const response = await XmailEMansionWebMailLoginService.xmailEMansionWebMailLogin(eMansionAccountInfo.eMansionMailAddressInfo.eMansionMailAddressId);
        if (response.errorMessages) {
          this.$store.commit('errorStore/messages', response.errorMessages);
          await this.$router.push('/e-mansion/error');
          return;
        }
        window.open(response.redirectUrl);
        return;
      } catch (error) {
        console.log('とおったerror2');
        console.log(error);
        // 共通エラー画面に遷移する
        await this.$router.push('/e-mansion/error');
        return;
      }
    },
  },
  computed: {
    apid() {
      if (this.$data.property) {
        return this.$data.property.IN_APID;
      } else {
        return '';
      }
    },

    /**
     * 「各種登録情報の確認・変更」ブロックを表示するかの判定
     *  認可制御外のため用意
     */
    isRegistrationLinkBlockAvailable(): boolean {
      return this.matchPlanWithPattern({ normal: true, slim: true, ttp: true, mailFree: true, mailCharge: true, additional: false });
    },

    /**
     *「ホームページサービス」ブロックを表示するかの判定
     * 認可制御外のため用意
     */
    isHomePageServiceBlockAvailable(): boolean {
      return this.matchPlanWithPattern({ normal: true, slim: false, ttp: true, mailFree: true, mailCharge: false, additional: false });
    },

    /** スリムプラン判定 */
    isUserSlimPlan(): boolean {
      if (this.planType === SERVICE_PLAN_TYPE.EM_SLIM) {
        return true;
      }

      if (this.planType === SERVICE_PLAN_TYPE.EM_SLIM_SPECIAL) {
        return true;
      }

      return false;
    },

    /** 「契約内容のご案内」リンクを表示するかの判定 */
    isContractLinkAvailable(): boolean {
      if (!this.contractFlag) {
        return false;
      }
      return true;
    },

    /** 「e-mansion 各種オプションサービスのお申込・変更はこちら」のリンク先 */
    optionServilceUrl(): string {
      // メール会員(無償)
      if (this.planType === SERVICE_PLAN_TYPE.EM_MAIL_FREE) {
        return `${this.eMansionUrl}/mmb/`;
      }
      // メール会員(有償)
      if (this.planType === SERVICE_PLAN_TYPE.EM_MAIL_CHARGE) {
        return `${this.eMansionMyUrl}/login/login.php?em_redirect=${this.eMansionUrl}/${this.apid}/`;
      }
      // メール会員(有償)と同じ URL だが、設計書に準じて場合分けしている
      return `${this.eMansionMyUrl}/login/login.php?em_redirect=${this.eMansionUrl}/${this.apid}/`;
    },
  },
});
