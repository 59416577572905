import { Route } from 'vue-router';
import { AuthService } from '../auth/auth-service';
import store from '@/store';

/**
 * UCOM ユーザー共通データロード用サービス
 */
export class UcomCommonDataLoadService {
  /**
   * ログイン時共通データのロード
   * @returns なし
   */
  public static async loadInitialData(to: Route): Promise<void> {
    if (!(await AuthService.isLinkedToUcom())) {
      return;
    }

    // 強制リフレッシュが必要か確認
    const doRefresh = this.needRefresh(to);

    // UCOMアカウント
    await this.storeUcomAccount(store, doRefresh);
    // 会員種別をストック
    if (!store.getters['servicePlanTypeStore/servicePlanType']) {
      await store.dispatch('servicePlanTypeStore/servicePlanType');
    }
  }

  /**
   * 強制読み込みが必要かチェックする
   * @param to 遷移先のパス
   * @returns boolean trueの場合、強制リロードが必要
   */
  private static needRefresh(to: Route): boolean {
    // Portasのメニュー画面や、会員専用トップではクレカの登録情報などを最新状態にする必要がある為、
    // 必ずロードし直さなければならない
    // その為、メール基盤統合で追加した画面以外は強制読み込み要と判断する
    return to.path.match(/^\/ucom\/.+/) == null;
  }

  /**
   * UCOM_アカウントのロード
   * @param store データストア
   * @param doRefresh 強制リロードフラグ
   */
  private static async storeUcomAccount(store: any, doRefresh: boolean): Promise<void> {
    const needLoad = doRefresh || !store.getters['ucomAccountStore/ucomAccount'];
    if (!needLoad) {
      return;
    }

    // お客様番号ユーザーの場合
    const customerNo = await AuthService.getUcomMemberId();
    if (customerNo) {
      await store.dispatch('ucomAccountStore/ucomAccount', customerNo);
      return;
    }
    // 追加アカウントユーザーの場合
    const accountName = await AuthService.getUcomAccountName();
    if (accountName) {
      await store.dispatch('ucomAccountStore/ucomSubAccount', accountName);
      return;
    }
  }
}
