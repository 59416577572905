































































import { Member } from '@/shared/classes/spf-api/member';
import { AuthService } from '@/shared/services/auth/auth-service';
import Vue from 'vue';
import { SpfApiService } from '../../../shared/services/api/spf-api-service';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { Property } from '@/shared/classes/spf-api/property';
import { UA_TYPE } from '@/shared/const/service-type';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';

type DataType = {
  member: Member | null;
  property: Property | null;
  isSubmitting: boolean;
  isLinkedToUcom: boolean;
  isLinkedToEMansion: boolean;
  isLinkedToFiveA: boolean;
  isMounting: boolean;
  isPropertyUaTypeFiveA: boolean;
};

/** Confirm コンポーネント */
export default Vue.extend({
  name: 'platform-cancel-confirm',
  components: {
    LoadingComponent
  },
  data(): DataType {
    return {
      /** 会員情報 */
      member: null,
      // 物件情報
      property: null,
      /** ボタン押下判定 */
      isSubmitting: false,
      // Auth0ユーザとの連携チェック
      isLinkedToUcom: false,
      isLinkedToEMansion: false,
      isLinkedToFiveA: false,
      isMounting: true, // mountedが終わったかどうか
      isPropertyUaTypeFiveA: false
    }
  },
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    try {
      const isLoggedIn = await AuthService.isLoggedIn();
      // ログインしていない場合「総合トップ」画面にリダイレクトする
      if (!isLoggedIn) {
        this.$router.push('/').catch(error => {checkRouterError(error)});
        return;
      }
      // 会員情報をStoreから取得
      const member = this.$store.getters['memberStore/member'];
      this.member = member;
      if (!member) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      }

      this.property = this.$store.getters['propertyStore/property'];

      // PortasDBの会員テーブルのレコードについて、
      // e-mansion, ucom は 名前、電話番号、生年月日がnullなので表示しない
      // Five.A は、名前、電話番号、生年月日に値があるので表示する
      if (this.property?.uaType === UA_TYPE.FIVE_A) {
        this.isPropertyUaTypeFiveA = true;
      }
      this.isMounting = false;
    } catch(error) {
      throw error;
    } finally {
      this.isMounting = false;
    }
  },
  methods: {
    /** 「退会する ➡」ボタン押下時 : 退会の完了画面に遷移する */
    async onApply() {
      if (!this.member) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_MEMBER);
      }
      try {
        // ボタン押下中は何もしない
        if (this.isSubmitting) {
          return;
        }
        // ボタン押下中扱いとする
        this.isSubmitting = true;
        // 退会処理
        await this.deleteMember(this.member.id);

        this.$router.push('/platform/cancel/completed').catch(error => {checkRouterError(error)});

      } catch (error) {
        throw error;
      }

      // isSubmitting: true の間がスピナー表示される
      // AuthService.logout() によるリダイレクトの間もスピナー表示させるため、isSubmitting: false にしない
      // エラー時は、エラー画面に遷移するので、isSubmitting: true のままで問題ない
    },
    /**会員削除API実行 */
    async deleteMember(memberId: number) {
      try {
        await SpfApiService.deleteMember(memberId);
        /**storeの会員情報を削除 */
        this.$store.commit('memberStore/member', null);

        // SpfApiService.deleteMember(memberId) に成功するとバックエンドで会員ステータスが更新される
        // 会員ステータスが更新されるときは、null にするよう統一している関係で記述
        this.$store.commit('memberStore/memberStatus', null);
      } catch (error) {
        throw error;
      }
    }
  }
});
