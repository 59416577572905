import { UaTypeValue } from "@/shared/const/service-type";

/**
 * Five-a 共通利用 API : Connectix お申し込み : リクエスト
 *
 * 外部 API のレスポンス仕様により、階層構造になっている
 */
export class FiveAConnectixNewConnectixRequestBody {
  /**結果値 */
  public request!: FiveAConnectixNewConnectixRequest;

  constructor(partial: Partial<FiveAConnectixNewConnectixRequest>) {
    Object.assign(this, partial);
  }
}

/**
 * Five-a Connectix API : Connectix お申し込み : リクエスト
 *
 * 外部 API のリクエスト仕様に合わせてスネークケースで命名している
 *
 */
export class FiveAConnectixNewConnectixRequest {
  /** UA種別 */
  public ua_type!: UaTypeValue;
  /** 会員ID */
  public member_id!: string;
  /** 物件ID */
  public property_id!: string;
  /** roomsで取得したbuilding_id */
  public building_id: string = '0000';
  /** 部屋番号 */
  public room_number!: string;
  /** クレジットカードトークン */
  public credit_card_token?: string;
  /** トークン有効期限 */
  public token_expire_date?: string;

  constructor(partial: Partial<FiveAConnectixNewConnectixRequest>) {
    Object.assign(this, partial);
  }
}
