




















































































import Vue from 'vue';
import { required, numeric, minLength, maxLength } from 'vuelidate/lib/validators';
import { VeritransCreditCardTokenRequest } from '../classes/veritrans-credit-card-token-request';
import { VeritransApiService } from '../services/api/veritrans-api-service';
import axios from 'axios';

/** (UCOM以外共通) Veritrans クレジットカード情報入力コンポーネント */
export default Vue.extend({
  name: 'veritrans-credit-card-component',
  // 親コンポーネントから受け取るデータ
  props: {
    /** API トークンキー */
    apiTokenKey: {
      type: String,
      default: ''
    },
    /** トークン取得成功後に表示するメッセージ */
    successMessage: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    /** 入力値 */
    form: {
      /** クレジットカード番号1 */
      number1: '',
      /** クレジットカード番号2 */
      number2: '',
      /** クレジットカード番号3 */
      number3: '',
      /** クレジットカード番号4 */
      number4: '',
      /** クレジットカード有効期限月 (ゼロパディング2桁) */
      expiryMonth: '',
      /** クレジットカード有効期限年 (西暦下2桁) */
      expiryYear: '',
    },
    /** 入力フォームを表示するか否か */
    isInput: true,
    /** 西暦の入力欄を組み立てるための現在年 */
    currentYear: 0,
    // onPostCreditCard()が実行中かどうか
    isOnPostCreditCardExecuting: false
  }),
  validations: {
    form: {
      number1: {
        required,
        numeric,
        minLength: minLength(4),
        maxLength: maxLength(4)
      },
      number2: {
        required,
        numeric,
        minLength: minLength(4),
        maxLength: maxLength(4)
      },
      number3: {
        required,
        numeric,
        minLength: minLength(4),
        maxLength: maxLength(4)
      },
      number4: {
        required,
        numeric,
        minLength: minLength(1),
        maxLength: maxLength(4)
      },
      expiryMonth: {
        required
      },
      expiryYear: {
        required
      }
    }
  },
  mounted() {
    // computed() では西暦が正しく取得できない・mounted() で処理する必要あり
    (this as any).currentYear = new Date().getFullYear();
  },
  methods: {
    /** 「クレジットカード情報を送信」ボタン押下時 */
    async onPostCreditCard() {
      if (this.$data.isOnPostCreditCardExecuting) {
        return;
      }
      this.$data.isOnPostCreditCardExecuting = true;

      try {
        /** API をコールしてトークンを取得する
         * テスト環境ではセキュリティコードが必要なのでテスト用セキュリティコードを送信している
        */
        const tokenRequest = new VeritransCreditCardTokenRequest({
          card_number: `${this.$data.form.number1}${this.$data.form.number2}${this.$data.form.number3}${this.$data.form.number4}`,
          card_expire: `${this.$data.form.expiryMonth}/${this.$data.form.expiryYear}`,
          token_api_key: this.$props.apiTokenKey,
          lang: 'ja'
        });
        if (process.env['VUE_APP_VERITRANS_SECURITY_CODE']) {
          tokenRequest.security_code = process.env['VUE_APP_VERITRANS_SECURITY_CODE'];
        }
        const response = await VeritransApiService.fetchCreditCardToken(tokenRequest);
        const token = response.token;
        const token_expire_date = response.token_expire_date;
        // 入力エリアを非表示にする
        this.$data.isInput = false;
        // 親コンポーネントにトークンを渡す
        this.$emit('onPostCreditCard', token);
        // 親コンポーネントにトークン有効期限を渡す
        this.$emit('getTokenExpireDate', token_expire_date);
        // 親コンポーネントにクレジットカード番号の下4桁を渡す
        this.$emit('getCreditEndNumber', this.$data.form.number4);
      } catch (_error: any) {
        // エラーレスポンスに応じた、表示するエラーメッセージの場合分け
        if (axios.isAxiosError(_error)) {

          // エラーレスポンス例
          // {
          //  code: "digit_check_error",
          //  message: "ディジットチェックエラーです",
          //  status: "failure"
          // }
          const status: number = _error.response?.status ? _error.response?.status : -1;
          const errorCode: string = _error.response?.data.code;

          if (this.isErrorDueToInputValue(errorCode)) {
            this.$emit('onPostCreditCardError', 'カード番号もしくは有効期限を正しく入力してください。');

          } else if(this.isErrorDueToConfig(errorCode)) {
            this.$emit('onPostCreditCardError',
                        [ '処理が正常に完了できませんでした。しばらく時間をおいてから再度お試しください。',
                          '再度お試しいただいても正常に完了しない場合、大変お手数ですがサポートセンターまでエラー番号をお知らせください。',
                          '<エラー番号: ' + errorCode +'>'].join('</br>'));

            // 通信エラーのとき
          } else if(status >= 500) {
            this.$emit('onPostCreditCardError', 'クレジットカード情報の送信に失敗しました。');

          }

          throw _error;

        } else {
          throw _error;
        }
      } finally {
        this.$data.isOnPostCreditCardExecuting = false;
      }
      this.$data.isOnPostCreditCardExecuting = false;
    },
    isErrorDueToInputValue(errorCode: string) {
      const EXPRESSION = [/^missing_card_number$/, /^invalid_card_number$/, /^digit_check_error$/,
                          /^missing_card_expire$/, /^invalid_card_expire$/];

      const index = EXPRESSION.findIndex( (expression) => {
        return expression.test(errorCode);
      });

      if (index !== -1) return true;
      return false;
    },
    isErrorDueToConfig(errorCode: string) {
      const EXPRESSION = [/^missing_token_api_key$/, /^invalid_token_api_key$/, /^unauthorized_merchant$/,
                          /^invalid_lang$/, /^invalid_request_format$/];

      const index = EXPRESSION.findIndex( (expression) => {
        return expression.test(errorCode);
      });

      if (index !== -1) return true;
      return false;
    }
  },
  computed: {
    computedForm() {
      return JSON.parse(JSON.stringify(this.form));
    }
  },
  watch: {
    computedForm: {
      handler: function (newVal, oldVal) {
        if (newVal.number1 && !newVal.number1.match(/^\d{1,4}$/)) {
          (this as any).form.number1 = oldVal.number1;
        }
        if (newVal.number2 && !newVal.number2.match(/^\d{1,4}$/)) {
          (this as any).form.number2 = oldVal.number2;
        }
        if (newVal.number3 && !newVal.number3.match(/^\d{1,4}$/)) {
          (this as any).form.number3 = oldVal.number3;
        }
        if (newVal.number4 && !newVal.number4.match(/^\d{1,4}$/)) {
          (this as any).form.number4 = oldVal.number4;
        }
        // セキュリティコードは3桁・4桁だが念のため6桁まで
        if (newVal.securityCode && !newVal.securityCode.match(/^\d{1,6}$/)) {
          (this as any).form.securityCode = oldVal.securityCode;
        }
      },
      deep: true
    }
  }
});
