

































import Vue from 'vue';

/** Not Found 画面 */
export default Vue.extend({
  name: 'not-found'
});
