


















































































































import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import Vue from 'vue';
import { PlatformEntryInputFormForCreate } from '@/shared/classes/platform/entry-input-form';

import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { AuthService } from '@/shared/services/auth/auth-service';
import { EntryProperty, InputPropertyForm } from '@/store/platform/platform-edit-store';
import { MEMBER_STATUS } from '@/shared/const/member-status';
import { UA_TYPE } from '@/shared/const/service-type';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';

type DataType = {
  entryProperty: EntryProperty | null;
  form: PlatformEntryInputFormForCreate | null;
  inputPropertyForm: InputPropertyForm | null;
  resultMember: Member | null;
  resultMemberStatus: MemberStatus | null;
  buildingName: string;
  roomNumber: string;
  showBuildingName: boolean;
  showRoomNumber: boolean;
  isSubmitting: boolean;
};

/** Terms コンポーネント */
export default Vue.extend({
  name: 'platform-entry-confirm',
  components: {
    LoadingComponent
  },
  data(): DataType {
    return {
      entryProperty: null,
      form: null,
      inputPropertyForm: null,
      resultMember: null,
      resultMemberStatus: null,
      buildingName: '',
      roomNumber: '',
      showBuildingName: false,
      showRoomNumber: false,
      isSubmitting: false,
    };
  },
  async mounted() {
    this.form = this.$store.getters['platformEntryStore/entryInputForm'];
    // リロードによりストアが初期化されていたら入力画面に飛ばす
    if(!this.form || !this.form.firstName){
      this.$router.push('/platform/entry/input').catch(error => {checkRouterError(error)});
      return;
    }
    this.entryProperty = this.$store.getters['platformEditStore/entryProperty'];
    this.inputPropertyForm = this.$store.getters['platformEditStore/inputPropertyForm'];
    if (this.entryProperty && this.inputPropertyForm) {
      this.buildingName = this.inputPropertyForm.inputBuildingData.buildingName ?? '';
      this.roomNumber = this.entryProperty.roomNumber;

      this.showBuildingName = !!this.buildingName;
      this.showRoomNumber = !!this.roomNumber;
    }
  },
  methods: {
    /** 会員情報を新規登録し、確認画面に遷移する */
    async onSignUp() {
      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      // ボタン押下中扱いとする
      this.isSubmitting = true;

      if (this.form) {

        const sub = await AuthService.getSub();
        const eMansionMemberId = await AuthService.getEMansionMemberId();
        const eMansionMemberIdETnc = await AuthService.getEMansionMemberIdETnc();
        const ucomMemberId = await AuthService.getUcomMemberId();
        const mcloudMemberId = await AuthService.getMcloudMemberId();

        let member: Omit<Member, 'id'> = {
          firstName: this.form.firstName,
          givenName: this.form.lastName,
          firstNameKatakana: this.form.firstNameKana,
          givenNameKatakana: this.form.lastNameKana,
          emailAddress: this.form.mailAddress,
          phoneNumber: `${this.form.phoneNumber1}-${this.form.phoneNumber2}-${this.form.phoneNumber3}`,
          birthdate: `${this.form.birthYear}-${('00'+this.form.birthMonth).slice(-2)}-${('00'+this.form.birthDay).slice(-2)}`,
          memberStatus: 0,
          primaryKeyAuth0: sub,
        };

        if (this.entryProperty && this.inputPropertyForm) {
          const propertyDto = {
            propertyId: this.entryProperty.propertyId,
            zipcode: this.entryProperty.zipcode,
            address: this.entryProperty.address,
            buildingNumber: this.entryProperty.buildingId,
            roomNumber: this.roomNumber
          };
          member = { ...member, ...propertyDto };
          // apartmentBlockNameをUA_TYPEで分ける
          if (this.inputPropertyForm?.selectedProperty?.uaType === UA_TYPE.UCOM) {
            member.apartmentBlockName = null;
          }
          // e-mansion物件・Mcloud物件のときのApartmentBlockName（選択された棟名）
          if (this.inputPropertyForm?.selectedProperty?.uaType === UA_TYPE.E_MANSION) {
            member.apartmentBlockName = this.inputPropertyForm.inputBuildingData.buildingName;
          }
        }

        // 既存会員の場合
        if(eMansionMemberId || ucomMemberId || mcloudMemberId){
          member.primaryKeyUcom = ucomMemberId;
          member.primaryKeyMye = eMansionMemberId ? eMansionMemberId : undefined;
          member.eTncMemberId = eMansionMemberIdETnc ? eMansionMemberIdETnc : undefined;
          member.primaryKeyMcloud = mcloudMemberId ? mcloudMemberId : undefined;
        }

        try {
          // 会員登録処理
          this.resultMember = await SpfApiService.createMember(member);

          // 会員ストアから会員ステータス情報を取得する
          const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];

          // あり得ないけど念のため確認
          if(!this.resultMember){
            throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_CREATE_MEMBER);
          }

          /** 物件が選択されている */
          if (this.entryProperty && (eMansionMemberId || ucomMemberId)) {
            memberStatus.status = MEMBER_STATUS.PROPERTY_LINKED;
          } else if(this.entryProperty){
            memberStatus.status = MEMBER_STATUS.ACCOUNT_REGISTERED;
          } else {
            memberStatus.status = MEMBER_STATUS.ACCOUNT_REGISTERED_NO_PROPERTY;
          }

          await this.$store.dispatch('memberStore/updateMemberStatus',{primaryKeyAuth0 :sub, memberStatus});

        } catch(error: any) {
          const status: number = error.response?.status;

          // メアド重複
          if(status === 409){
            this.form.errorMessages = ['ご入力いただいたメールアドレスは既に登録されています。'];
            this.$store.commit('platformEntryStore/entryInputForm', this.form);
            this.$router.push('/platform/entry/input').catch(error => {checkRouterError(error)});
            this.isSubmitting = false;
            return;
          }

          throw error;
        }

        await AuthService.refresh();

        this.$router.push('/platform/entry/completed')
          .catch(
            error => {
              checkRouterError(error)
            }
          )
          .finally(
            () => {
              this.isSubmitting = false;
            }
          );
      }
    }
  }
});
