export class EMansionMainMailAddressList {
  readonly _hasMailAddress!: boolean; //メールアドレス有無
  readonly _mailAddress!: string | undefined; //メールアドレス
  readonly _registerStatusView!: string | undefined; //登録ステータス（表示用）
  readonly _registerStatus!: string | undefined; //登録ステータス
  readonly _canMainMailAddressCancel!: boolean | undefined; //メインメールアドレス解約可否
  readonly _serviceInTime!: Date | undefined; //サービスイン日
  readonly _cancelOn!: Date | undefined; //解約日
  readonly _shouldNotificationMailAddress!: boolean | undefined; //通知先メールアドレスチェック要否
  readonly _accountName!: string | undefined; //e-mansionアカウント名
  readonly _initialMailPassword!: string | undefined; //初期パスワード
  readonly _popServer!: string | undefined; //POPサーバー
  readonly _smtpServer!: string | undefined; //SMTPサーバー
  readonly _errMsg!: string[] | undefined; //エラーメッセージ

  constructor(partial: Partial<EMansionMainMailAddressList>) {
    Object.assign(this, partial);
  }
  get hasMailAddress(): boolean {
    return this._hasMailAddress;
  }

  get mailAddress(): string | undefined {
    return this._mailAddress;
  }

  get registerStatusView(): string | undefined {
    return this._registerStatusView;
  }

  get registerStatus(): string | undefined {
    return this._registerStatus;
  }

  get canMainMailAddressCancel(): boolean | undefined {
    return this._canMainMailAddressCancel;
  }

  get serviceInTime(): Date | undefined {
    return this._serviceInTime;
  }

  get cancelOn(): Date | undefined {
    return this._cancelOn;
  }

  get shouldNotificationMailAddress(): boolean | undefined {
    return this._shouldNotificationMailAddress;
  }

  get accountName(): string | undefined {
    return this._accountName;
  }

  get initialMailPassword(): string | undefined {
    return this._initialMailPassword;
  }

  get popServer(): string | undefined {
    return this._popServer;
  }

  get smtpServer(): string | undefined {
    return this._smtpServer;
  }

  get errMsg(): string[] | undefined {
    return this._errMsg;
  }
}
