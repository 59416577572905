import { Module } from 'vuex';
import { RootState } from '../index';

import { UcomApplicationCodeData, UcomEntryInputForm } from '../../pages/ucom/entry/classes/entry-input-form';
import { UcomPropertyResponse } from '../../shared/classes/external-api/ucom/property-response';

/** UCOM 新規会員登録 State */
const ucomEntryState: {
  entryInputForm: UcomEntryInputForm | null;
  externalApiProperty: UcomPropertyResponse | null;
  applicationCodeData: UcomApplicationCodeData | null;
} = {
  /** UCOM 新規会員登録 入力フォーム */
  entryInputForm: null,
  /** UCOM 新規会員登録 物件情報 (外部 API より取得した値) */
  externalApiProperty: null,
  /** UCOM 光電話お申し込みコード確認API 実行結果 */
  applicationCodeData: null
};

/** State の型 */
type UcomEntryState = typeof ucomEntryState;

/** UCOM 新規会員登録用 Store */
export const ucomEntryStore: Module<UcomEntryState, RootState> = {
  namespaced: true,
  state: ucomEntryState,
  mutations: {
    entryInputForm: (state, value: UcomEntryInputForm) => (state.entryInputForm = value),
    externalApiProperty: (state, value: UcomPropertyResponse) => (state.externalApiProperty = value),
    applicationCodeData: (state, value: UcomApplicationCodeData) => (state.applicationCodeData = value)
  },
  getters: {
    entryInputForm: (state): UcomEntryInputForm | null => state.entryInputForm,
    externalApiProperty: (state): UcomPropertyResponse | null => state.externalApiProperty,
    applicationCodeData: (state): UcomApplicationCodeData | null => state.applicationCodeData
  }
};
