export class subAccountData {
  readonly _eMansionAccountId!: string | undefined;
  readonly _eMansionAccountName!: string | undefined;
  readonly _initialAccountPassword!: string | undefined;
  readonly _initialMailPassword!: string | undefined;
  readonly _popServer!: string | undefined;
  readonly _smtpServer!: string | undefined;
  readonly _mailAddress!: string | undefined;
  readonly _displayRegisterStatus!: string | undefined;
  readonly _registerStatus!: string | undefined;
  readonly _cancelOn!: string | undefined;
  readonly _canCancelBtn!: boolean | undefined;
  public get eMansionAccountId(): string | undefined {
    return this._eMansionAccountId;
  }
  public get eMansionAccountName(): string | undefined {
    return this._eMansionAccountName;
  }
  public get initialAccountPassword(): string | undefined {
    return this._initialAccountPassword;
  }
  public get initialMailPassword(): string | undefined {
    return this._initialMailPassword;
  }
  public get popServer(): string | undefined {
    return this._popServer;
  }
  public get smtpServer(): string | undefined {
    return this._smtpServer;
  }
  public get mailAddress(): string | undefined {
    return this._mailAddress;
  }
  public get displayRegisterStatus(): string | undefined {
    return this._displayRegisterStatus;
  }
  public get registerStatus(): string | undefined {
    return this._registerStatus;
  }
  public get canCancelBtn(): boolean | undefined {
    return this._canCancelBtn;
  }
  public get cancelOn(): string | undefined {
    return this._cancelOn;
  }
  constructor(partial: Partial<subAccountData>) {
    Object.assign(this, partial);
  }
}
