




























































































import Vue from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { UcomVirusCheckPurchaseState } from '../../../shared/classes/ucom/ucom-virus-check-purchase-state';
import {
  UCOM_VIRUS_CHECK_PURCHASE_SUBSCRIPTION_TYPE,
  UcomVirusCheckPurchaseSubscriptionType,
  convertUcomVirusCheckPurchaseSubscriptionTypeById,
} from '../../../shared/const/ucom/ucom-virus-check-purchase-subscription-type';
import { SpfApiVirusCheckAccessor } from '../../../infra/accessor/spf/ucom/spf-api-virus-check-accessor';
import { UpdateUcomVirusCheckRequest } from '@/infra/request/spf/ucom/virus-check/update-ucom-virus-check-request';
import { UCOM_VIRUS_CHECK_ACTION, UcomVirusCheckAction } from '@/shared/const/ucom/ucom-virus-check-action';
import { AuthService } from '@/shared/services/auth/auth-service';
import { numberWithDelimiter } from '../../../shared/util/vue-filters';

export default Vue.extend({
  name: 'virus-check-purchase-confirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data(): {
    isMounted: boolean;
    isBusy: boolean;
    errorMessages: string[];
    accountId?: number;
    purchaseState?: UcomVirusCheckPurchaseState;
  } {
    return {
      isMounted: false,
      isBusy: false,
      errorMessages: [],
      accountId: undefined,
      purchaseState: undefined,
    };
  },
  mounted() {
    const ucomAccountId: string = this.$store.getters['ucomAccountOptionStore/ucomAccountId'];
    this.accountId = Number(ucomAccountId);
    // 前画面からの情報を取得する
    this.purchaseState = this.$store.getters['ucomVirusCheckStore/purchaseState'];
    this.isMounted = true;
  },
  methods: {
    /** 「戻る」のクリックハンドラ */
    async goBack(): Promise<void> {
      await this.$router.push('/ucom/virus-check/input');
    },

    /** 「決定」のクリックハンドラ */
    async apply(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isBusy = true;

      try {
        // 型ガード
        if (this.accountId === undefined) {
          throw new Error();
        }
        if (this.purchaseState === undefined) {
          throw new Error();
        }
        const action = this.convertPurchaseAction(this.purchaseState.subscriptionType);
        if (!action) {
          throw new Error();
        }
        // ウイルスチェック情報更新 (購入/解約) API 呼出し
        const virusCheckUpdateResult = await SpfApiVirusCheckAccessor.updateUcomVirusCheckPurchase(
          new UpdateUcomVirusCheckRequest(Number(await AuthService.getMemberId()), this.accountId, this.purchaseState.subscriptionDate, action)
        );
        // レスポンスにエラーメッセージがあればエラーメッセージを表示して処理を終了する
        if (virusCheckUpdateResult.errorMessage) {
          this.errorMessages = [virusCheckUpdateResult.errorMessage];
          this.isBusy = false;
          return;
        }
        // 購入/解約完了 画面に渡す情報を Store に保存する
        this.$store.commit('ucomVirusCheckStore/setNotifyMailAddress', virusCheckUpdateResult.mailAddress);
        // 購入/解約完了 画面に遷移する。catch() はナビゲーションガードでエラー画面へ行くのを防ぐために必要。
        await this.$router.push('/ucom/virus-check/purchase-complete').catch(() => { ; });
        this.isBusy = false;
      } catch (error) {
        await this.$router.push('/error');
      }
    },

    /**
     * 「購入」または「解約」の値を API に渡すパラメータに変換する
     */
    convertPurchaseAction(purchaseAction: UcomVirusCheckPurchaseSubscriptionType | undefined): UcomVirusCheckAction | undefined {
      if (purchaseAction === UCOM_VIRUS_CHECK_PURCHASE_SUBSCRIPTION_TYPE.PURCHASE) {
        return UCOM_VIRUS_CHECK_ACTION.PURCHASE;
      }
      if (purchaseAction === UCOM_VIRUS_CHECK_PURCHASE_SUBSCRIPTION_TYPE.CANCEL) {
        return UCOM_VIRUS_CHECK_ACTION.CANCEL;
      }
    },
  },
  computed: {
    /** ローダーを表示するかどうか */
    isLoading(): boolean {

      if (!this.isMounted) {
        return true;
      }
      return this.isBusy;
    },
    /** お申込内容 に表示するテキスト */
    subscriptionTypeText(): string {
      if (this.purchaseState?.subscriptionType === undefined) {
        return '';
      }
      return convertUcomVirusCheckPurchaseSubscriptionTypeById(this.purchaseState.subscriptionType) ?? '';
    },
  },
  filters: {
    numberWithDelimiter,
  },
});
