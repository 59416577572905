





































































































































































import Vue from 'vue';
import dayjs from 'dayjs';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { UcomVirusCheckInfo } from '../../../shared/classes/spf-api/model/ucom/ucom-virus-check-info';
import { SpfApiVirusCheckAccessor } from '../../../infra/accessor/spf/ucom/spf-api-virus-check-accessor';
import { UCOM_REGISTERED_PAYMENT } from '../../../shared/const/ucom/ucom-registered-payment';
import { convertUcomPurchaseStatusById } from '../../../shared/const/ucom/ucom-purchase-status';
import { UCOM_VIRUS_CHECK_PAUSE_SETTING } from '../../../shared/const/ucom/ucom-virus-check-pause-setting';
import { UCOM_VIRUS_CHECK_PAUSE_ACTION, UcomVirusCheckPauseAction } from '../../../shared/const/ucom/ucom-virus-check-pause-action';
import { UcomVirusCheckPurchaseState } from '../../../shared/classes/ucom/ucom-virus-check-purchase-state';
import { UcomVirusCheckPauseState } from '../../../shared/classes/ucom/ucom-virus-check-pause-state';
import { UCOM_VIRUS_CHECK_PURCHASE_SUBSCRIPTION_TYPE, UcomVirusCheckPurchaseSubscriptionType } from '../../../shared/const/ucom/ucom-virus-check-purchase-subscription-type';
import { numberWithDelimiter } from '../../../shared/util/vue-filters';
import { UCOM_ACCOUNT_AUTHORITY } from '@/shared/const/ucom/ucom-account-authority';

export default Vue.extend({
  name: 'virus-check-input',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data(): {
    isMounted: boolean;
    isBusy: boolean;
    virusCheck: UcomVirusCheckInfo | undefined;
    purchaseErrors: string[];
    pauseErrors: string[];
    isAgreedOnPurchaseTerms: boolean;
    isAgreedOnPauseTerms: boolean;
    pauseAction: UcomVirusCheckPauseAction | undefined;
    pauseTerms: string;
    paymentUrl: string;
    isAdminLoggedIn: boolean;
  } {
    return {
      isMounted: false,
      isBusy: false,
      virusCheck: undefined,
      purchaseErrors: [],
      pauseErrors: [],
      isAgreedOnPurchaseTerms: false,
      isAgreedOnPauseTerms: false,
      pauseAction: undefined,
      pauseTerms: 'ウイルスチェックを一時停止している間も課金は通常通り発生しますのでご注意ください。',
      paymentUrl: process.env.VUE_APP_UCOM_PAYMENT_REGISTER_URL,
      isAdminLoggedIn: false,
    };
  },
  async mounted() {
    const ucomAccountId: string = this.$store.getters['ucomAccountOptionStore/ucomAccountId'];
    if (!ucomAccountId) {
      this.$router.push('/ucom/account-option-change/account-list');
      return;
    }
    // ウイルスチェック初期表示情報を取得する
    try {
      this.virusCheck = await SpfApiVirusCheckAccessor.findUcomVirusCheck(Number(ucomAccountId));
    } catch (error) {
      await this.$router.push('/error');
      return;
    }
    // 「ウイルスチェック設定」ラジオボタンの初期値を設定する
    if (this.virusCheck?.virusCheckPauseSetting == UCOM_VIRUS_CHECK_PAUSE_SETTING.PAUSED) {
      this.pauseAction = UCOM_VIRUS_CHECK_PAUSE_ACTION.PAUSE;
    } else if (this.virusCheck?.virusCheckPauseSetting == UCOM_VIRUS_CHECK_PAUSE_SETTING.UNPAUSED) {
      this.pauseAction = UCOM_VIRUS_CHECK_PAUSE_ACTION.UNPAUSE;
    }
    this.isAdminLoggedIn = this.getIsAdminLoggedIn();

    this.isMounted = true;
  },
  methods: {
    /** 「購入」ボタンのクリックハンドラ */
    async goToPurchase(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isBusy = true;

      // バリデーション
      const error = this.validatePurchaseAgreement();
      if (error) {
        this.purchaseErrors = [error];
        this.isBusy = false;
        return;
      }

      // 購入/解約確認 画面に渡す情報を Store に保存する
      this.storePurchaseState(UCOM_VIRUS_CHECK_PURCHASE_SUBSCRIPTION_TYPE.PURCHASE);
      // 購入/解約確認 画面に遷移する
      await this.$router.push('/ucom/virus-check/purchase-confirm');
      this.isBusy = false;
    },

    /** 「解約」ボタンのクリックハンドラ */
    async goToCancel(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isBusy = true;

      // バリデーション
      const error = this.validatePurchaseAgreement();
      if (error) {
        this.purchaseErrors = [error];
        this.isBusy = false;
        return;
      }

      // 購入/解約確認 画面に渡す情報を Store に保存する
      this.storePurchaseState(UCOM_VIRUS_CHECK_PURCHASE_SUBSCRIPTION_TYPE.CANCEL);
      // 購入/解約確認 画面に遷移する
      await this.$router.push('/ucom/virus-check/purchase-confirm');
      this.isBusy = false;
    },

    /** 「次へ進む」ボタンのクリックハンドラ */
    async goToPauseSetting(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isBusy = true;

      // バリデーション
      const error = this.validatePauseAgreement();
      if (error) {
        this.pauseErrors = [error];
        this.isBusy = false;
        return;
      }

      // 一時停止/開始確認 画面に渡す情報を Store に保存する
      if (this.pauseAction === undefined) {
        await this.$router.push('/error');
        this.isBusy = false;
        return;
      }
      this.storePauseState(this.pauseAction);

      // 一時停止/開始確認 画面に遷移する
      await this.$router.push('/ucom/virus-check/pause-confirm');
      this.isBusy = false;
    },

    /**
     * 購入/解約の説明に同意しているかを検証する。
     *
     * @return エラーならメッセージを返す。
     */
    validatePurchaseAgreement(): string | undefined {
      const ERROR_MESSAGE = '「サービス説明への同意」の「上記の内容に同意する」をチェックしてください。';
      if (!this.isAgreedOnPurchaseTerms) {
        return ERROR_MESSAGE;
      }
    },

    /**
     * 一時停止/開始の説明に同意しているかを検証する。
     *
     * @return エラーならメッセージを返す。
     */
    validatePauseAgreement(): string | undefined {
      const ERROR_MESSAGE = '「注意事項への同意」の「上記の内容に同意する」をチェックしてください。';
      if (!this.isAgreedOnPauseTerms) {
        return ERROR_MESSAGE;
      }
    },

    /**
     * 購入/解約関連の情報を Store に保存する
     *
     * @param subscriptionType 1:購入, 2:解約
     */
    async storePurchaseState(subscriptionType: UcomVirusCheckPurchaseSubscriptionType): Promise<void> {
      if (!this.virusCheck) {
        await this.$router.push('/error');
        return;
      }
      const today = dayjs(new Date()).format('YYYY/MM/DD');
      const purchaseState: UcomVirusCheckPurchaseState = {
        accountName: this.virusCheck.accountName,
        mailAddress: this.virusCheck.mailAddress,
        virusCheckRegisterStatus: this.virusCheck.virusCheckRegisterStatus,
        monthlyFee: this.virusCheck.monthlyFee,
        subscriptionDate: today,
        serviceDescriptionBeforePurchase: this.virusCheck.serviceDescriptionBeforePurchase,
        serviceDescriptionAfterPurchase: this.virusCheck.serviceDescriptionAfterPurchase,
        subscriptionType: subscriptionType,
      };
      this.$store.commit('ucomVirusCheckStore/setPurchaseState', purchaseState);
    },

    /**
     * 一時停止/開始関連の情報を Store に保存する
     *
     * @param pauseAction 0:一時停止する, 1:実施する
     */
    async storePauseState(pauseAction: UcomVirusCheckPauseAction): Promise<void> {
      if (!this.virusCheck) {
        await this.$router.push('/error');
        return;
      }
      const today = dayjs(new Date()).format('YYYY/MM/DD');
      const pauseState: UcomVirusCheckPauseState = {
        accountName: this.virusCheck.accountName,
        mailAddress: this.virusCheck.mailAddress,
        virusCheckPauseAction: pauseAction,
        subscriptionDate: today,
        terms: this.pauseTerms,
      };
      this.$store.commit('ucomVirusCheckStore/setPauseState', pauseState);
    },
    /**
     * ログインユーザが管理者権限を持つかどうかを返す
     */
    getIsAdminLoggedIn(): boolean {
      const loginUserAuthority: UCOM_ACCOUNT_AUTHORITY = this.$store.getters['ucomAccountStore/ucomAccount'].accountInfo.accountAuthorization;
      if (loginUserAuthority === UCOM_ACCOUNT_AUTHORITY.ADMIN_ACCOUNT) {
        return true;
      }
      if (loginUserAuthority === UCOM_ACCOUNT_AUTHORITY.CUSTOMER_NO_USER) {
        return true;
      }
      return false;
    },
  },
  computed: {
    /** ローダーを表示するかどうか */
    isLoading(): boolean {
      if (!this.isMounted) {
        return true;
      }
      return this.isBusy;
    },
    /** お支払い方法が登録済みかどうか */
    isPaymentRegistered(): boolean {
      return this.virusCheck?.paymentRegisterStatus === UCOM_REGISTERED_PAYMENT.REGISTERED.id.toString();
    },
    /** お支払情報登録未登録時メッセージ */
    paymentUnregisteredMessage(): string {
      return this.virusCheck?.paymentUnregisteredMessage ?? '';
    },
    /** ご契約状態の文字列 */
    registerStatus(): string | undefined {
      if (!this.virusCheck) {
        return undefined;
      }
      const registerStatus = convertUcomPurchaseStatusById(parseInt(this.virusCheck.virusCheckRegisterStatus));
      return registerStatus?.value;
    },
    /** 「購入」ボタンを有効にするかどうか */
    canPurchase(): boolean {
      if (!this.isAgreedOnPurchaseTerms) {
        return false;
      }
      return this.virusCheck?.canPurchase() ?? false;
    },
    /** 「解約」ボタンを有効にするかどうか */
    canCancel(): boolean {
      if (!this.isAgreedOnPurchaseTerms) {
        return false;
      }
      return this.virusCheck?.canCancel() ?? false;
    },
  },
  filters: {
    numberWithDelimiter,
  },
});
