


































































































































import Vue from 'vue';

/** エラーメッセージ用コンポーネント */
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
/** クレジットカード用コンポーネント */
import CreditCardComponent from '@/shared/components/veritrans-credit-card-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FiveACustomer } from '../../../shared/classes/external-api/five-a/customer-response';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/five-a/customer-dto';
import { Property } from '@/shared/classes/spf-api/property';
import { AuthService } from '@/shared/services/auth/auth-service';
import { Member } from '@/shared/classes/spf-api/member';
import { FiveASharedErrorResponse } from '@/shared/classes/external-api/five-a/shared-error-response';
import { FiveAProperty } from '@/shared/classes/external-api/five-a/property-response';
import { FiveAConnectixErrorResponse } from './classes/external-api/connectix-error-response';
import { FiveAConnectixNewConnectixRequest, FiveAConnectixNewConnectixRequestBody } from './classes/external-api/new-connectix-request';
import { FiveAConnectixExternalApiService } from '@/shared/services/external-api/connectix/five-a/five-a-connectix-external-api-service';
import { UaTypeValue, UA_TYPE, SERVICE_TYPE, ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { ConnectixMaintenanceInfo } from '@/shared/classes/spf-api/connectix-maintenance';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { getIspMemberStatusFiveA } from '@/shared/util/func-get-isp-member-status';

/** Confirm コンポーネント */
export default Vue.extend({
  name: 'five-a-connectix-confirm',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    /** クレジットカード入力フォームコンポーネント */
    CreditCardComponent,
    LoadingComponent
  },
  data: () => ({
    /** 物件名 */
    propertyName: '',
    /** 部屋番号 */
    roomNumber: '',
    /** Connectix利用金額 */
    connectixUsageFee: '',
    /** Connectixキャンペーン利用金額 */
    connectixUsageFeeCp: '',
    /** 会員ID */
    memberId: '' as string,
    /** 会員ID */
    buildingId: '' as string,
    /** 物件ID */
    propertyId: '',
    /** クレジットカードを含む、有効な支払い方法が登録されているかどうか */
    isRegisteredValidPaymentMethod: false,
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    /** クレジットカードアクセストークン */
    creditCardAccessToken: '',
    /** クレジットカードトークン有効期限 */
    creditTokenExpireDate: '',
    /** APIトークンキー */
    apiTokenKey: '',
    /** トークン取得成功後に表示するメッセージ */
    successMessage: '画面下の「お申し込み」ボタンをクリックしてください。',
    // mounted() 終わったかどうか
    isMounted: false,
    // onApply() 実行中かどうか
    isExecutingOnApply: false,
    // ISP(Five.A)会員ステータス
    ispMemberStatusFiveA: ''
  }),
  /** ログインしていない状態で遷移した場合は「総合トップ」画面にリダイレクトする */
  async mounted(): Promise<void> {
    try {
      const isLoggedIn = await AuthService.isLoggedIn();
      if (!isLoggedIn) {
        this.$router.push('/').catch(error => {checkRouterError(error)});
        return;
      }

      // 会員情報をStoreから取得
      const member: Member = this.$store.getters['memberStore/member'];
      // five.A のメンバー ID
      this.memberId = member.primaryKeyMye ?? '';
      this.$data.buildingId = member.buildingNumber;

      // 物件情報をStoreから取得
      const property: Property = this.$store.getters['propertyStore/property'];
      if (!property) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_PROPERTY);
      }

      const uaType: UaTypeValue | undefined = property.uaType;
      const propertyId: string | undefined = property.apartmentId;

      // 必要な物件情報を取得できなかった場合はエラー画面に遷移
      if (!uaType) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.PROPERTY_NO_UA_TYPE);
      }

      // 契約基本情報取得 API 用のクエリ
      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: uaType,
        property_id: propertyId
      });

      // 契約基本情報をStoreから取得
      const customer: FiveACustomer | FiveASharedErrorResponse = await this.$store.dispatch('fiveACommonStore/customer', {
        memberId: this.memberId,
        query: findCustomerByIdQueryDto
      });
      if (customer instanceof FiveACustomer) {
        // 物件ID
        this.propertyId = customer.property_id;

        // 登録している支払方法が以下のいずれかの場合、クレジットカード申請フォームを表示しない
        /**
         * 0: 口座振替
         * 1: 振込
         * 4: 口振（請求書）
         * 8: カード
         */
        const paymentIds = ['0', '1', '4', '8'];
        if (paymentIds.indexOf(customer.payment_id) !== -1) {
          this.isRegisteredValidPaymentMethod = true;
        }
      } else {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.E_MANSION.NO_CUSTOMER_INFO);
      }

      // 物件基本情報を取得
      const params = {
        propertyId: this.propertyId,
        uaType: UA_TYPE.FIVE_A
      };
      const propertyInfo: FiveAProperty | FiveASharedErrorResponse = await this.$store.dispatch('fiveACommonStore/property', params);
      if (propertyInfo instanceof FiveAProperty) {
        // 物件名
        this.propertyName = propertyInfo.em_ap_name;
        // Connectix利用金額
        this.connectixUsageFee = propertyInfo.op.sdwan.fee;
        this.connectixUsageFeeCp = propertyInfo.op.sdwan.fee_cp;
      } else {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.E_MANSION.NO_CUSTOMER_INFO);
      }
      // APIトークンキーを設定（e-mansion のトークンを使用）
      this.$data.apiTokenKey = process.env.VUE_APP_VERITRANS_TOKEN_API_KEY_E_MANSION;
      // 入力画面で入力した情報をStoreから取得
      const inputApply = this.$store.getters['fiveAConnectixEntryStore/entryInputForm'];
      if (!inputApply) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_INPUT_DATA);
      }
      // 部屋番号
      this.roomNumber = inputApply.roomNumber;
    } catch (error) {
      throw error;
    } finally {
      this.isMounted = true;
    }
  },
  methods: {
    async onApply() {
      if (this.isExecutingOnApply) {
        return;
      }
      this.isExecutingOnApply = true;

      const connectixMaintenance: ConnectixMaintenanceInfo =  await this.$store.dispatch('connectixMaintenanceStore/connectixMaintenance', SERVICE_TYPE.FIVE_A);

      if(connectixMaintenance){
        this.$router.push('/platform/maintenance').catch(error => {checkRouterError(error)});
        return;
      }

      // ISP(Five.A)側で退会申し込み中のとき、/connectix/5a/terms に遷移してエラーメッセージを表示
      if (await this.isInCancelApplication()) {
        this.$router.push('/connectix/5a/terms').catch(error => {checkRouterError(error)});
        return; 
      }

      try {
        // Connectixお申し込みAPIを実行
        const request = new FiveAConnectixNewConnectixRequest({
          /** UA種別 */
          ua_type: UA_TYPE.FIVE_A,
          /** 会員ID */
          member_id: this.memberId,
          /** 物件ID */
          property_id: this.propertyId,
          /** 部屋番号 */
          room_number: this.roomNumber,
        });

        if(this.$data.buildingId){
          request.building_id = this.$data.buildingId;
        }

        /** クレジットカードトークン */
        if (this.creditCardAccessToken) request.credit_card_token = this.creditCardAccessToken;
        /** トークン有効期限 */
        if (this.creditTokenExpireDate) request.token_expire_date = this.creditTokenExpireDate;
        const requestBody: FiveAConnectixNewConnectixRequest = request;
        const resultCreateConnectix = await FiveAConnectixExternalApiService.createConnectix(requestBody);

        if (resultCreateConnectix instanceof FiveAConnectixErrorResponse) {
          // データ不整合エラー
          throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.CONNECTIX);
        }

        this.$router.push('/connectix/5a/completed').catch(error => {checkRouterError(error)});

      } catch (error) {
        throw error;
      } finally {
        this.isExecutingOnApply = false;
      }
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークンを受け取る */
    getCreditCardToken(token: string) {
      // エラーメッセージ格納配列初期化
      this.errorMessages = [];
      this.creditCardAccessToken = token;
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークン有効期限を受け取る */
    getTokenExpireDate(creditTokenExpireDate: string) {
      this.creditTokenExpireDate = creditTokenExpireDate;
    },
    /** クレジットカードエラーが発生していた場合 */
    executeCreditCardError(errorMessage: string) {
      this.errorMessages = [errorMessage];
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
    },
    onBack() {
      this.$router.push('/connectix/5a/apply').catch(error => {checkRouterError(error)});
    },
    async isInCancelApplication() {
      try {
        const property: Property = this.$store.getters['propertyStore/property'];

        const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
          ua_type: property.uaType,
          property_id: property.apartmentId
        });

        const member: Member = this.$store.getters['memberStore/member'];
        const fiveAMemberId = member.primaryKeyMye ?? '';

        const fiveACustomer: FiveACustomer | FiveASharedErrorResponse = await this.$store.dispatch('fiveACommonStore/customer', {
          memberId: fiveAMemberId,
          query: findCustomerByIdQueryDto
        });

        if (fiveACustomer instanceof FiveACustomer) {

          // ISP会員ステータス取得
          const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
          this.ispMemberStatusFiveA = getIspMemberStatusFiveA(memberStatus, fiveACustomer);

          /**
           * ISP(Five.A)会員ステータスが次の2つの場合、ISP退会申し込み中であると判定する
           *
           * 1. ISP(Five.A)会員ステータス: 退会申し込み
           * 2. ISP(Five.A)会員ステータス: 退会済みログイン可能
           */
          if (this.ispMemberStatusFiveA === ISP_MEMBER_STATUS.FIVE_A.IN_CANCEL_APPLICATION
              || this.ispMemberStatusFiveA === ISP_MEMBER_STATUS.FIVE_A.CANCEL_AND_LOGIN_OK) {
            return true;
          }
        } else {
          // データ不整合エラー
          throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.E_MANSION.NO_CUSTOMER_INFO);
        }
      } catch (e) {
        throw e;
      }
      return false;
    }
  },
  computed: {
    isDisabledButton(): boolean {
      return !this.isRegisteredValidPaymentMethod && this.creditCardAccessToken === '';
    }
  }
});
