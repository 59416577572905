import axios from 'axios';

import { FiveAConnectixErrorResponse } from '@/pages/connectix/five-a/classes/external-api/connectix-error-response';
import { FiveAConnectixNewConnectixRequest, FiveAConnectixNewConnectixRequestBody } from '@/pages/connectix/five-a/classes/external-api/new-connectix-request';
import { FiveAConnectixPropertyRoomsResponse, FiveAConnectixPropertyRoomsResponseResult } from '../../../../../pages/connectix/five-a/classes/external-api/property-rooms-response';
import { FiveAConnectixNewConnectixResponse } from '@/pages/connectix/five-a/classes/external-api/new-connectix-response';
import { FiveAConnectixRoomsRequest } from '@/shared/classes/external-api/five-a/connectix-room-request';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import { BaseExternalApiService } from '@/shared/services/external-api/base-external-api-service';

const api = BaseExternalApiService.api(1);

/** five-a Connectix 外部 API をコールするサービス */
export class FiveAConnectixExternalApiService {
  /**
   * Connectix お申し込み
   *
   * @param FiveAConnectixNewConnectixRequest Connectix お申し込み情報
   * @return Connectix お申し込み結果
   */
  public static async createConnectix(emansionConnectixNewConnectixRequestResult: FiveAConnectixNewConnectixRequest): Promise<FiveAConnectixErrorResponse | undefined> {
    try {
      emansionConnectixNewConnectixRequestResult.property_id = convert6DigitIdTo8DigitId(emansionConnectixNewConnectixRequestResult.property_id);
      await api.post<FiveAConnectixNewConnectixResponse>('connectix', emansionConnectixNewConnectixRequestResult);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * 部屋番号一覧
   *
   * @param propertyId string  物件ID
   * @return 部屋番号一覧
   */
  public static async findPropertyRooms(
    propertyId: string,
    fiveAConnectixRoomsRequest: FiveAConnectixRoomsRequest
  ): Promise<FiveAConnectixPropertyRoomsResponse | FiveAConnectixErrorResponse> {
    const apid = convert6DigitIdTo8DigitId(propertyId);
    const query = {
      params: fiveAConnectixRoomsRequest
    };
    try {
      const response = await api.get<FiveAConnectixPropertyRoomsResponseResult>(`properties/${apid}`, query);
      return new FiveAConnectixPropertyRoomsResponse(response.data.result);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * API 通信エラー時のハンドリング
   *
   * @param error エラーオブジェクト
   * @return five-a API 共通エラーオブジェクト
   */
  private static handleError(error: unknown): FiveAConnectixErrorResponse {
    if (axios.isAxiosError(error)) {
      const errorResponse: FiveAConnectixErrorResponse = error.response?.data;
      if (errorResponse) return new FiveAConnectixErrorResponse(errorResponse);
    }
    throw error;
  }
}
