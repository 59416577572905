



























































































import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { AuthService } from '@/shared/services/auth/auth-service';

export default Vue.extend({
  name: 'account-delete-complete',
  components: {
    LoadingComponent,
  },
  data: () => ({
    isLoading: true,
    accountName: '',
    fullName: '',
    mailAddress: '',
    accountAuthorization: '',
    isTargetLoginUser: false,
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    //前画面よりアカウント情報を取得する
    this.$data.accountName = this.$store.getters['ucomAccountManagementDeleteStore/accountName'];
    this.$data.fullName = this.$store.getters['ucomAccountManagementDeleteStore/fullName'];
    this.$data.mailAddress = this.$store.getters['ucomAccountManagementDeleteStore/mailAddress'];
    this.$data.accountAuthorization = this.$store.getters['ucomAccountManagementDeleteStore/accountAuthorization'];
    this.$data.isTargetLoginUser = this.$store.getters['ucomAccountManagementDeleteStore/isTargetLoginUser'];

    if (!this.$data.accountName || !this.$data.fullName || !this.$data.accountAuthorization) {
      // 削除関連store情報の削除
      this.$store.commit('ucomAccountManagementDeleteStore/clear');

      //アカウントID管理一覧画面へ
      await this.$router.push('/ucom/account-management/list').catch((error) => {
        checkRouterError(error);
      });
      this.$data.isLoading = false;
      return;
    }
    this.$data.isLoading = false;
  },

  // 削除対象アカウントがログインユーザの場合、ログアウトする
  // ボタンのイベントハンドラでストアをクリアしているので、チェックデータはあらかじめ取得している
  async destroyed() {
    if (this.$data.isTargetLoginUser) {
      await AuthService.logout();
    }
  },

  methods: {
    /** アカウントID管理一覧画面へ */
    async goManagement() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      // 削除関連store情報の削除
      this.$store.commit('ucomAccountManagementDeleteStore/clear');

      await this.$router.push('/ucom/account-management/list').catch((error) => {
        checkRouterError(error);
      });
      this.$data.isLoading = false;
    },
    /**UCOM光 レジデンス会員専用トップ押下時 */
    async goTop() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      // 削除関連store情報の削除
      this.$store.commit('ucomAccountManagementDeleteStore/clear');

      await this.$router.push('/ucom').catch((error) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    // ページTOPへ
    scrollPageTop() {
      window.scrollTo(0, 0);
    },
  },
});
