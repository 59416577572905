











































































































import Vue from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { EMansionAccountInfo } from '@/shared/classes/spf-api/mail/model/account/e-mansion-account-info';
import { SpfApiHomepageCapacityAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-homepage-capacity-accessor';
import { EMansionHomepageCapacityList } from '@/shared/classes/spf-api/homepage/model/e-mansion-homepage-capacity-list';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';

export default Vue.extend({
  name: 'homepageCapacityList',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    ftpAccount: '', // FTPアカウント
    eMansionHomepageCapacity: '', // 現在のホームページ容量
    eMansionAccountId: '', // e-mansion アカウントID
    eMansionHomepageId: '', // e-mansion ホームページID
    fee: '', // 月額利用料
    jimFee: '', // 事務手数料
    maxQuota: '', // 最大容量(MB)
    errorMessages: [], // エラーメッセージを格納する配列
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。', // エラーメッセージ部に表示するタイトル
    isChangeBtn: false, //変更ボタン活性化するか
    isSameMonthChange: false, //同月内変更有無
    isLoading: false, //ローディング
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      this.$data.isLoading = true;

      // 物件基本情報をStoreから取得
      const property: EMansionProperty = this.$store.getters['eMansionCommonStore/property'];
      if (!property) {
        await this.$router.push('/e-mansion/error');
        return;
      }
      // 月額利用料
      this.$data.fee = property.op.hp_quota.fee;
      // 事務手数料
      this.$data.jimFee = property.op.hp_quota.jimu_fee;
      // 最大容量
      this.$data.maxQuota = property.op.hp_quota.max_quota;

      // e-mansion_アカウントIDを取得(共通情報より取得)
      const accountInfo: EMansionAccountInfo = this.$store.getters['eMansionAccountStore/eMansionAccountInfo'];
      if (!accountInfo) {
        await this.$router.push('/e-mansion/error');
        return;
      }

      // e-mansion_アカウントID
      this.$data.eMansionAccountId = accountInfo.eMansionAccountInfo.eMansionAccountId;

      // 現在日付の取得
      const currentDateObject = await SpfApiServerCommonUtilAccessor.getServerTimeNow();

      // ホームページ情報をAPIより取得する
      const response = await SpfApiHomepageCapacityAccessor.getEMansionHomepageCapacityList(this.$data.eMansionAccountId, currentDateObject);
      // API処理結果にエラーメッセージがある場合
      if (response.errorMessages.length != 0) {
        for (const errMsg of response.errorMessages) {
          this.$data.errorMessages.push(errMsg);
        }
      }

      // 画面項目セット
      this.$data.ftpAccount = response.ftpAccount;
      this.$data.eMansionHomepageCapacity = response.homepageCapacity;

      // ホームページID(Hidden)
      this.$data.eMansionHomepageId = response.eMansionHomepageId;

      // ボタンを活性化するかどうかを判断
      this.$data.isChangeBtn = this.isViewChangeBtn(response);

      // 同月内変更チェック
      this.$data.isSameMonthChange = response.isSameMonthChange;

      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error');
      return;
    }
  },
  methods: {
    // 変更ボタンを活性化するかの判断を行う
    isViewChangeBtn(response: EMansionHomepageCapacityList): boolean {
      // 同月内変更なし、且つ、容量変更可否：可
      if (!response.isSameMonthChange && response.canChangeCapacity) {
        return true;
      } else {
        return false;
      }
    },
    // 変更ボタンクリック
    async onChange() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      // ホームページ容量情報をstoreに保存する
      this.$store.commit('eMansionHomepageCapacityStore/eMansionAccountId', this.$data.eMansionAccountId);
      this.$store.commit('eMansionHomepageCapacityStore/eMansionHomepageId', this.$data.eMansionHomepageId);
      this.$store.commit('eMansionHomepageCapacityStore/ftpAccount', this.$data.ftpAccount);
      this.$store.commit('eMansionHomepageCapacityStore/eMansionHomepageCapacity', this.$data.eMansionHomepageCapacity);
      this.$store.commit('eMansionHomepageCapacityStore/fee', this.$data.fee);
      this.$store.commit('eMansionHomepageCapacityStore/jimFee', this.$data.jimFee);
      this.$store.commit('eMansionHomepageCapacityStore/maxQuota', this.$data.maxQuota);

      await this.$router.push('/e-mansion/homepage-capacity/input');
      this.$data.isLoading = false;
    },
  },
});
