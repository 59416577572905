


































import Vue from 'vue';
import {AuthService} from "@/shared/services/auth/auth-service";

/** エラー画面 */
export default Vue.extend({
  name: 'error',
  data: () => ({
    isLoggedIn: false,
  }),
  async mounted() {
    this.$data.isLoggedIn = await AuthService.isLoggedIn();
  }
});

