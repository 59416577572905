import { UcomOAuthToken } from '@/shared/classes/ucom/ucom-oauth-token';
import { Module } from 'vuex';
import { RootState } from '../index';

/** UCOM Bearer State */
const ucomBearerState: {
  token: UcomOAuthToken | null,
} = {
  token: null,
};

/** State の型 */
type UcomBearerState = typeof ucomBearerState;

/** UCOM Bearer Store */
// store内でのBearer発行処理は無しで保存と再取得のみを行う
export const ucomBearerStore: Module<UcomBearerState, RootState> = {
  namespaced: true,
  state: ucomBearerState,
  mutations: {
    token: (state, value: UcomOAuthToken) => (state.token = value),
  },
  getters: {
    token: (state): UcomOAuthToken | null => state.token,
  }
};
