import { UCOM_REGISTERED_PAYMENT } from '@/shared/const/ucom/ucom-registered-payment';

/** UCOMメールボックス容量 入力(ユーザー) */
export class UcomMailboxCapacityUserInfo {
  readonly _isRegisteredPayment!: UCOM_REGISTERED_PAYMENT; // お支払情報登録状態
  readonly _accountName!: string; // アカウント名
  readonly _mailAddress!: string; // メールアドレス
  readonly _mailBoxCapacity!: number; // メールボックス容量

  constructor(isRegisteredPayment: UCOM_REGISTERED_PAYMENT, accountName: string, mailAddress: string, mailBoxCapacity: number) {
    this._isRegisteredPayment = isRegisteredPayment;
    this._accountName = accountName;
    this._mailAddress = mailAddress;
    this._mailBoxCapacity = mailBoxCapacity;
  }

  get isRegisteredPayment(): UCOM_REGISTERED_PAYMENT {
    return this._isRegisteredPayment;
  }
  get accountName(): string {
    return this._accountName;
  }
  get mailAddress(): string {
    return this._mailAddress;
  }
  get mailBoxCapacity(): number {
    return this._mailBoxCapacity;
  }
}
