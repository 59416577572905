import { AuthService } from '../auth/auth-service';

export class AccountUtilService {
  /**
   * 会員ステータスが必要なログインユーザーかをチェックする
   * @returns trueの場合、必要
   */
  public static async needMemberStatus(): Promise<boolean> {
    // 会員ステータスは、Portasに会員登録する際、その状態を管理するために使用する。
    // 追加アカウントのユーザーは、自身をPortas会員として登録することが無い為、
    // 会員ステータスは不要となる。
    return !(await this.isSubAccount());
  }

  /**
   * ログインユーザーが追加アカウント（=会員情報を持たない）か否かをチェックする
   * @returns trueの場合、追加アカウント
   */
  public static async isSubAccount(): Promise<boolean> {
    return (await AuthService.isEMansionAdditionalAccount()) || (await AuthService.isUcomAdditionalAccount());
  }
}
