






















































































































































































































































import Vue from 'vue';
/** 使用するバリデーションの定義 */
import { AuthService } from '@/shared/services/auth/auth-service';
import { required, numeric, minLength, maxLength } from 'vuelidate/lib/validators';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { FiveASharedExternalApiService } from '@/shared/services/external-api/five-a-shared-external-api-service';
import { FiveARoomsResponseResult } from '@/shared/classes/external-api/five-a/rooms-response';
import { FiveASharedErrorResponse } from '@/shared/classes/external-api/five-a/shared-error-response';
import { FiveAEntryInputForm } from './classes/entry-input-form';
import { Property } from '@/shared/classes/spf-api/property';
import { UA_TYPE } from '@/shared/const/service-type';
import { Member } from '@/shared/classes/spf-api/member';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { isValidMonthAndDatePairs } from '@/shared/util/func-is-valid-month-and-date-pairs';
import { parsePhoneNumber } from 'libphonenumber-js';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';

/** Input コンポーネント */
export default Vue.extend({
  name: 'five-a-entry-input',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    LoadingComponent
  },
  data: () => ({
    customer: null as FiveAEntryInputForm | null,
    member: null as Member | null,
    property: {},
    /** 画面入力項目 */
    inputData: {
      /** 性別 */
      sex: null as string | null,
      /** 生年月日 */
      birthdayYear: null,
      birthdayMonth: null,
      birthdayDate: null,
      /** 緊急連絡先 */
      emergencyPhoneNumber: null as string | null,
      /** FAX番号 */
      faxNumber: null as string | null,
      /** ご入居予定日 */
      scheduledMoveInOnYear: null as string | null,
      scheduledMoveInOnMonth: '',
      scheduledMoveInOnDate: ''
    },
    isAbleToConnectixContract: false,
    /** エラーメッセージを格納する配列 */
    errorMessages: [],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    /** 建物名 */
    propertyName: '',
    // ボタン押下時対応
    isSubmitting: false,
    // mounted() 実行中かどうか
    isMounted: false
  }),
  /** バリデーション定義 */
  validations: {
    inputData: {
      // 性別
      sex: { required },
      // 生年月日
      birthdayYear: { required, numeric, minLength: minLength(4), maxLength: maxLength(4) },
      birthdayMonth: { required, numeric, minLength: minLength(1), maxLength: maxLength(2) },
      birthdayDate: { required, numeric, minLength: minLength(1), maxLength: maxLength(2) },
      /** ご入居予定日 */
      scheduledMoveInOnYear: { numeric, minLength: minLength(4), maxLength: maxLength(4) },
      scheduledMoveInOnMonth: { numeric, minLength: minLength(1), maxLength: maxLength(2) },
      scheduledMoveInOnDate: { numeric, minLength: minLength(1), maxLength: maxLength(2) }
    },
    customer: {
      /** お部屋番号 */
      roomNumber: { required },
      /** お名前 姓 */
      nameSurname: { required },
      /** お名前 名 */
      nameFirstName: { required },
      /** フリガナ 姓カナ */
      kanaSurname: { required },
      /** フリガナ 名カナ */
      kanaFirstName: { required },
      /** お電話番号 */
      phoneNumber: { required },
      /** 郵便番号 */
      zipcode: { required },
      /** 住所 */
      address: { required },
      /** 連絡先メールアドレス */
      emailAddress: { required },
    },
  },
  async mounted(): Promise<void> {
    try {
      /** ログインしているか否かの情報を取得 */
      const isLoggedIn = await AuthService.isLoggedIn();
      // ログインしていない場合「総合トップ」画面にリダイレクトする
      if (!isLoggedIn) {
        this.$router.push('/').catch(error => {checkRouterError(error)});
        return;
      }
      // Five.A会員情報取得処理
      const customer: FiveAEntryInputForm = this.$store.getters['fiveAEntryStore/entryInputForm'];
      // customer情報が無い場合、規約同意していないためエラーとする
      if (!customer) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_INPUT_DATA);
      }
      this.customer = customer;

      // 既に入力済みの情報があれば表示する
      this.inputData.sex = this.customer.sex ?? null;
      this.inputData.emergencyPhoneNumber = this.customer.emergencyPhoneNumber ?? null;
      this.inputData.faxNumber = this.customer.faxNumber ?? null;
      if (customer.scheduledMoveInOn) {
        const buf: string[] = customer.scheduledMoveInOn.split('/');
        this.inputData.scheduledMoveInOnYear = buf[0];
        this.inputData.scheduledMoveInOnMonth = Number(buf[1]).toString();
        this.inputData.scheduledMoveInOnDate = Number(buf[2]).toString();
      }

      // 会員情報取得処理
      this.member = this.$store.getters['memberStore/member'];

      // 後者の !this.member は、ほぼ生じえない
      // マイページ編集後に限定して処理した方がより良いが、煩瑣になるため一旦はこの形としておく
      if (this.$store.getters['platformEditStore/beforePath'] === '/5a/entry/input' || !this.member) {
        this.$store.commit('platformEditStore/beforePath', null);
        this.member = this.$store.getters['memberStore/member']; // beforeEach により、変更後の会員情報が取得される
        if (!this.member || !this.member.propertyId) {
          // データ不整合エラー
          throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_MEMBER);
        }
        // 修正後の member データで更新（紐づけ物件の修正は生じえない）
        this.customer.buildingId = this.member.buildingNumber ?? '';
        this.customer.roomNumber = this.member.roomNumber ?? '';
        this.customer.nameSurname = this.member.firstName;
        this.customer.nameFirstName = this.member.givenName;
        this.customer.kanaSurname = this.member.firstNameKatakana;
        this.customer.kanaFirstName = this.member.givenNameKatakana;
        this.customer.birthday = this.member.birthdate!.replace(/\-/g, '/');
        this.customer.phoneNumber = this.member.phoneNumber!;
        this.customer.emailAddress = this.member.emailAddress;
      }

      // 物件情報の取得
      const property: Property | null = this.$store.getters['propertyStore/property'];
      if (property) {
        this.propertyName = property.dispApartmentName;
      }

      // 会員情報の必須チェック
      if (this.$v.customer.$invalid) {
        if (this.$v.customer.nameSurname?.$invalid || this.$v.customer.nameFirstName?.$invalid) {
          if (!this.$v.customer.nameSurname?.required && this.$v.customer.nameFirstName?.required) {
            this.$data.errorMessages.push('お名前を入力してください。');
          }
        }
        if (this.$v.customer.kanaSurname?.$invalid || this.$v.customer.kanaFirstName?.$invalid) {
          if (!this.$v.customer.kanaSurname?.required && !this.$v.customer.kanaFirstName?.required) {
            this.$data.errorMessages.push('フリガナを入力してください。');
          }
        }
        if (this.$v.customer.phoneNumber?.$invalid) {
          if (!this.$v.customer.phoneNumber?.required) {
            this.$data.errorMessages.push('お電話番号を入力してください。');
          }
        }
        if (this.$v.customer.emailAddress?.$invalid) {
          if (!this.$v.customer.emailAddress?.required) {
            this.$data.errorMessages.push('ご連絡先メールアドレスを入力してください。');
          }
        }
        if ( this.$data.errorMessages.length > 0) {
          // エラーメッセージを見せるために画面最上部にスクロール
          this.$data.errorMessageTitle = '未入力の項目があります。マイページで登録をお願いいたします。'
          return;
        }
      }

      // ストアのデータをセットする
      if (this.$data.customer) {
        this.setBirthday(this.$data.customer.birthday);
      }
    } catch (error) {
      throw error;
    } finally {
      this.isMounted = true;
    }
  },
  computed: {
    /** 生年月日の取得 */
    getBirthday(){
      if (
        typeof this.inputData.birthdayYear === 'number' &&
        typeof this.inputData.birthdayMonth === 'number' &&
        typeof this.inputData.birthdayDate === 'number'
      ) {
        const [
          birthdayYear,
          birthdayMonth,
          birthdayDate
        ] = [
          String(this.inputData.birthdayYear),
          String(this.inputData.birthdayMonth).padStart(2, '0'),
          String(this.inputData.birthdayDate).padStart(2, '0'),
        ];
        /**0字詰め&「/」結合 */
        return `${birthdayYear}/${birthdayMonth}/${birthdayDate}`;
      } else { return null; }
    },
    /** ご入居予定日の取得 */
    getScheduledMoveInOn(){
      if(!this.inputData.scheduledMoveInOnYear || !this.inputData.scheduledMoveInOnMonth || !this.inputData.scheduledMoveInOnDate){
        return null;
      }
      return (
        String(this.inputData.scheduledMoveInOnYear) +
        '/' +
        ('00' + String(this.inputData.scheduledMoveInOnMonth)).slice(-2) +
        '/' +
        ('00' + String(this.inputData.scheduledMoveInOnDate)).slice(-2)
      );
    },
    isInputed(): boolean {
      return (
        this.$data.inputData.sex && this.$data.customer.roomNumber && this.$data.inputData.birthdayYear && this.$data.inputData.birthdayMonth && this.$data.inputData.birthdayDate
      );
    }
  },
  methods: {
    /** 生年月日の設定 */
    setBirthday(birthday: string) {
      if (!birthday) {
        return;
      }
      const date = birthday.split('/');
      this.$data.inputData.birthdayYear = Number(date[0]);
      this.$data.inputData.birthdayMonth = Number(date[1]);
      this.$data.inputData.birthdayDate = Number(date[2]);
    },
    onEdit() {
      this.$router.push('/platform/my-page/member-edit').catch(error => {checkRouterError(error)});
    },
    /** フォームのバリデーション */
    async validate(): Promise<boolean> {
      this.$data.errorMessages = [];
      this.$data.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
      // お部屋番号必須チェック
      if (!this.$v.customer.roomNumber?.required) {
        this.$data.errorMessages.push('お部屋番号を入力してください。');
      }
      // 性別必須チェック
      if (!this.$v.inputData.sex?.required) {
        this.$data.errorMessages.push('性別を選択してください。');
      }
      // 生年月日必須チェック
      if (!this.$v.inputData.birthdayYear?.required || !this.$v.inputData.birthdayMonth?.required || !this.$v.inputData.birthdayDate?.required) {
        this.$data.errorMessages.push('生年月日を入力してください。');
      }
      // 生年月日形式チェック
      else if (this.$v.inputData.birthdayYear?.$invalid || this.$v.inputData.birthdayMonth?.$invalid || this.$v.inputData.birthdayDate?.$invalid) {
        this.$data.errorMessages.push('正しい生年月日を入力してください。');
      }

      // 緊急連絡先とFAX番号の入力欄に入力されて問題ない文字集合
      const REGEX_INPUT_PHONE_NUMBER = /^[0-9]([0-9]|-)*[0-9]$/;

      // 緊急連絡先の入力がある場合のみ形式チェック
      if (this.inputData.emergencyPhoneNumber) {
        const phoneNumber: string = this.$data.inputData.emergencyPhoneNumber;

        if (!REGEX_INPUT_PHONE_NUMBER.test(phoneNumber)) {
          this.$data.errorMessages.push('正しい緊急連絡先を入力してください。');
        } else {
          try {
            if (/^0120-[0-9]{1,4}-[0-9]{3,4}$/.test(phoneNumber)) {
              // 問題ないためスルー
            } else if (!parsePhoneNumber(phoneNumber, 'JP')?.isValid()) {
              this.$data.errorMessages.push('正しい緊急連絡先を入力してください。');
            } else if (!/^[0-9]{1,4}-[0-9]{1,4}-[0-9]{4}$/.test(phoneNumber)) {
              this.$data.errorMessages.push('正しい緊急連絡先を入力してください。');
            }
          } catch {
            // parsePhoneNumberがparse出来なくてエラーを出すとき用
            this.$data.errorMessages.push('正しい緊急連絡先を入力してください。');
          }
        }
      }
      // FAX番号の入力がある場合のみ形式チェック
      if (this.inputData.faxNumber) {
        const faxNumber: string = this.$data.inputData.faxNumber;

        if (!REGEX_INPUT_PHONE_NUMBER.test(faxNumber)) {
          this.$data.errorMessages.push('正しいFAX番号を入力してください。');
        } else {
          try {
            if (/^0120-[0-9]{1,4}-[0-9]{3,4}$/.test(faxNumber)) {
              // 問題ないためスルー
            } else if (!parsePhoneNumber(faxNumber, 'JP')?.isValid()) {
              this.$data.errorMessages.push('正しいFAX番号を入力してください。');
            } else if (!/^[0-9]{1,4}-[0-9]{1,4}-[0-9]{4}$/.test(faxNumber)) {
              this.$data.errorMessages.push('正しいFAX番号を入力してください。');
            }
          } catch {
            // parsePhoneNumberがparse出来なくてエラーを出すとき用
            this.$data.errorMessages.push('正しいFAX番号を入力してください。');
          }
        }
      }
      // ご入居予定日欄について、少なくとも1つ入力があるとき
      if (this.inputData.scheduledMoveInOnYear || this.inputData.scheduledMoveInOnMonth || this.inputData.scheduledMoveInOnDate) {

        // 未入力欄が存在するときはエラーに
        if (!this.inputData.scheduledMoveInOnYear || !this.inputData.scheduledMoveInOnMonth || !this.inputData.scheduledMoveInOnDate) {
          this.$data.errorMessages.push('正しいご入居予定日を入力してください。');

        // Five.A 外部APIのためのご入居予定日バリデーション
        // 年: 4桁であり かつ 0001-9999 の範囲
        // 月: 2桁であり かつ 01-12の範囲
        // 日: 2桁であり かつ うるう年を考慮した日付の範囲
        // 月と日について、getScheduledMoveInOn()にてゼロパディングしているため、1桁も許容している

        // vuelidateで指定した条件を満たさないときはエラーに (半角数字と桁数を指定)
        } else if(
          this.$v.inputData.scheduledMoveInOnYear?.$invalid
          || this.$v.inputData.scheduledMoveInOnMonth?.$invalid
          || this.$v.inputData.scheduledMoveInOnDate?.$invalid) {
          this.$data.errorMessages.push('正しいご入居予定日を入力してください。');

        // 年について、0000のときはエラーに
        } else if(/^0000$/.test(this.inputData.scheduledMoveInOnYear)) {
          this.$data.errorMessages.push('正しいご入居予定日を入力してください。');

        // 月について、1-12 以外のときはエラーに
        } else if(!/^[1-9]$|1[0-2]$/.test(String(this.inputData.scheduledMoveInOnMonth))) {
          this.$data.errorMessages.push('正しいご入居予定日を入力してください。');

        // 日について、月との組み合わせで有効でないときはエラーに
        } else if(
          !isValidMonthAndDatePairs(Number(this.inputData.scheduledMoveInOnYear),
                                    Number(this.inputData.scheduledMoveInOnMonth),
                                    Number(this.inputData.scheduledMoveInOnDate))) {
          this.$data.errorMessages.push('正しいご入居予定日を入力してください。');
        }
      }
      // エラーメッセージを見せるために画面最上部にスクロール
      if (this.errorMessages.length > 0) {
        window.scrollTo(0, 0);

        // ボタン押下解除
        this.isSubmitting = false;
        return false;
      }

      // 部屋番号の入力チェック
      const resultFindPropertyRooms: FiveARoomsResponseResult | FiveASharedErrorResponse = await FiveASharedExternalApiService.findRooms(
        /** UA種別 (2:Five.A）*/
        UA_TYPE.FIVE_A,
        /** 物件ID */
        this.$data.customer.propertyId
      );
      if (resultFindPropertyRooms instanceof FiveASharedErrorResponse) {

        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.ROOM_INFO);

      } else if (resultFindPropertyRooms instanceof FiveARoomsResponseResult) {
        const roomNumbers = resultFindPropertyRooms.rooms;
        if (roomNumbers !== null && roomNumbers.length) {
          // お申し込み可能な部屋の中に自部屋があるかチェック
          const applyRooms = roomNumbers.find((room) => room.room_number === this.$data.customer.roomNumber);
          if (applyRooms === undefined) {
            // お申し込み可能
            this.$data.errorMessages.push('正しいお部屋番号を入力してください。');
            window.scrollTo(0, 0);
          } else {
            if (applyRooms.apply_connectix_id === '1') {
              // 「Connectixお申し込みの可/不可（applyConnectixId）」のレスポンスが不可のためお申し込み不可
              this.$data.errorMessages.push('インターネット開通工事完了後にConnectixをお申し込みいただけます。大変恐れ入りますが、Five.A建物専用ホームページ「ご利用方法」をご確認の上、インターネット開通工事をお申し込みください');
              window.scrollTo(0, 0);
            }
          }
        } else {
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.ROOM_INFO);
        }
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.ROOM_INFO);
      }
      if (this.$data.errorMessages.length > 0) {
        // エラーメッセージを見せるために画面最上部にスクロール
        this.$data.errorMessageTitle = '恐れ入りますが、入力内容をもう一度ご確認ください。';
        window.scrollTo(0, 0);

        // ボタン押下解除
        this.isSubmitting = false;
        return false;
      }

      return true;
    },
    /** 修正ボタン押下時：マイページ(入力)に遷移する */
    async modCustomerInfo() {
      // 入力情報をストアに保存
      if (this.customer) {
        this.customer.sex = this.inputData.sex ?? '';
        this.customer.birthday = this.getBirthday ?? '';
        this.customer.emergencyPhoneNumber = this.inputData.emergencyPhoneNumber ?? '';
        this.customer.faxNumber = this.inputData.faxNumber ?? '';
        this.customer.scheduledMoveInOn = this.getScheduledMoveInOn ?? '';
        this.$store.commit('fiveAEntryStore/entryInputForm', this.customer);
      }
      this.$router.push('/platform/my-page/member-edit').catch(error => {checkRouterError(error)});
    },
    /** 次へボタン押下時：確認に遷移する */
    async onNext() {
      // ボタン押下中は何もしない
      if (this.isSubmitting) return;

      // ボタン押下中とする
      this.isSubmitting = true;

      if (!(await this.validate())) return;
      try {
        // 入力情報をストアに保存
        this.$data.customer.sex = this.$data.inputData.sex;
        this.$data.customer.birthday = this.getBirthday;
        this.$data.customer.emergencyPhoneNumber = this.$data.inputData.emergencyPhoneNumber;
        this.$data.customer.faxNumber = this.$data.inputData.faxNumber;
        this.$data.customer.scheduledMoveInOn = this.getScheduledMoveInOn;
        this.$store.commit('fiveAEntryStore/entryInputForm', this.$data.customer);
        // 確認画面に遷移
        this.$router.push('/5a/entry/confirm').catch(error => {checkRouterError(error)});
      } catch (error) {
        throw error;
      } finally {
        // ボタン押下解除
        this.isSubmitting = false;
      }
    },
  }
});
