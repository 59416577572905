













































































































































































































































































































































































import Vue from 'vue';
import { FiveAProperty, EmInfoMessage } from '@/shared/classes/external-api/five-a/property-response';
import { AuthService } from '@/shared/services/auth/auth-service';
import { Property } from '@/shared/classes/spf-api/property';
import { Member } from '@/shared/classes/spf-api/member';
import { FiveACustomer } from '@/shared/classes/external-api/five-a/customer-response';
import { SpfApiService } from '@/shared/services/api/spf-api-service';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { ISP_MEMBER_STATUS, UA_TYPE } from '@/shared/const/service-type';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { GetOemsMasterResponse } from '@/shared/classes/spf-api/oems';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { MEMBER_STATUS } from '@/shared/const/member-status';
import { getIspMemberStatusFiveA } from '@/shared/util/func-get-isp-member-status';

type DataType = {
  /**建物ID */
  apartmentId: string | null;
  /**建物ID_8桁 */
  apartmentId8Digit: string | null;
  /**物件 */
  property: Property | null;
  /**物件基本情報 */
  propertyInfo: FiveAProperty | null;
  /**ドメイン名 */
  domainName: string;
  /**Connectixサービス利用料金 */
  connectixUsageFee: string;
  /**Connectixサービス利用料金 */
  connectixUsageFeeCp: string;
  /** お知らせメッセージ*/
  emInfoMessages: Array<EmInfoMessage>;
  /**Connectix契約/未契約 */
  isContractConnectix: string;
  /**ランダムで設定する9桁の数字 */
  rd: string;
  /**接続先サービスURL（現在不使用） */
  connectServiceUrl: string;
  /** ConnectixLPのURL */
  connectixLpUrl: string;
  /** Five.A サービス ドメイン */
  fiveADomain: string;
  /** スピナー表示制御 */
  isMounted: boolean;
  /** connectix対象物件フラグ */
  isAvailableConnectix: string;
  // navigateToLocation() を実行中かどうか
  isExecutingNavigateToLocation: boolean;
  // ScrollHint用
  ScrollHint: any;
  // oemsMaster用
  oemsMaster: Array<GetOemsMasterResponse>;
  // 会員ステータス
  memberStatus: MemberStatus | null;
  // ISP(Five.A)会員ステータス
  ispMemberStatusFiveA: string,
  // ISP(Five.A)会員ステータスが「退会申し込み」かどうか(表示制御に使う)
  isIspStatusInCancelApplication: boolean,
  // ISP(Five.A)会員ステータスが「退会ログイン可能」のとき表示するご請求明細・販売証明書を表示するかどうか
  isDisplayOnlyBillingDetails: boolean,
  // ISP(Five.A)会員ステータスが「退会ログイン可能」のとき表示するご請求明細・販売証明書表示URL
  billingDetailsURL: string
};

/** 「Five.A サービストップ」画面 */
export default Vue.extend({
  name: 'five-a-index',
  components: {
    LoadingComponent,
  },
  data(): DataType {
    return {
      apartmentId: null,
      apartmentId8Digit: null,
      property: null,
      propertyInfo: null,
      domainName: '',
      connectixUsageFee: '',
      connectixUsageFeeCp: '',
      emInfoMessages: [],
      isContractConnectix: '',
      rd: '',
      connectServiceUrl: process.env.VUE_APP_FIVE_A_SERVICE_URL,
      connectixLpUrl: process.env.VUE_APP_CONNECTIX_LP_URL,
      fiveADomain: process.env.VUE_APP_FIVE_A_SERVICE_DOMAIN,
      isMounted: false,
      isAvailableConnectix: '',
      isExecutingNavigateToLocation: false,
      ScrollHint: require('../../shared/libraries/scroll-hint/scroll-hint'),
      oemsMaster: [],
      memberStatus: null,
      ispMemberStatusFiveA: '',
      isIspStatusInCancelApplication: false,
      billingDetailsURL: process.env.VUE_APP_FIVE_A_BILLING_DETAILS_URL,
      isDisplayOnlyBillingDetails: false
    };
  },
  async mounted() {
    try {
      const isLoggedIn = await AuthService.isLoggedIn();
      // ログインしていない場合「総合トップ」画面にリダイレクトする
      if (!isLoggedIn) {
        this.$router.push('/').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      /** 会員情報を取得 */
      const member: Member = this.$store.getters['memberStore/member'];
      this.memberStatus = this.$store.getters['memberStore/memberStatus'];

      // 顧客情報に物件情報が紐づけられていない場合
      if (!member || !member.propertyId || !member.primaryKeyMye || !this.memberStatus) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      }

      // 物件情報を取り出す
      const property: Property = this.$store.getters['propertyStore/property'];

      /** 物件IDを取得 */
      if (property.apartmentId) {
        this.apartmentId = property.apartmentId;
        this.apartmentId8Digit = convert6DigitIdTo8DigitId(property.apartmentId);
      } else {
        this.$router.push('/').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      // Five.Aのサービス提供物件にのユーザーでない場合「総合トップ」画面にリダイレクトする
      if (property.uaType !== UA_TYPE.FIVE_A) {
        this.$router.push('/').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
      //外部API取得用クエリパラメーター
      const params = { propertyId: this.apartmentId, uaType: UA_TYPE.FIVE_A };
      // 物件基本情報を取得
      const propertyInfo = await this.$store.dispatch('fiveACommonStore/property', params);
      if (propertyInfo instanceof FiveAProperty) {
        this.propertyInfo = propertyInfo;
        this.isAvailableConnectix = propertyInfo.op.sdwan.flag;
        this.emInfoMessages = propertyInfo.em_info_message.map((message) => {
          message.message_kind = this.getKindString(message.message_kind);
          return message;
        });
        // Connectix利用金額
        this.connectixUsageFee = propertyInfo.op.sdwan.fee;
        this.connectixUsageFeeCp = propertyInfo.op.sdwan.fee_cp;
      } else {
        // データ不整合エラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.PROPERTY_INFO);
      }
      // 契約基本情報を取得
      const customerParams: {
        memberId: string;
        query: FindCustomerByIdQueryDto;
      } = {
        memberId: member.primaryKeyMye,
        query: { apartment_id: this.apartmentId, ua_type: UA_TYPE.FIVE_A },
      };
      const customer = await this.$store.dispatch('fiveACommonStore/customer', customerParams);
      if (customer instanceof FiveACustomer) {
        this.isContractConnectix = customer.op_connectix_contract_status;
        this.ispMemberStatusFiveA = getIspMemberStatusFiveA(this.memberStatus, customer);

        this.isIspStatusInCancelApplication = this.getFlagIspStatusInCancelApplication();
        this.isDisplayOnlyBillingDetails = this.getFlagDisplayOnlyBillingDetails();

      } else {
        // データ不整合エラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.CONTRACTOR_INFO);
      }

      //ドメイン名の取得 */
      if (property.oemType) {
        this.oemsMaster = await this.$store.dispatch('oemsStore/oems');
        const filtered = this.oemsMaster.filter((oem) => oem.oemType === String(property.oemType));
        if (filtered.length >= 1) {
          let latestOem = filtered[0];
          if (filtered.length >= 2) {
            for (let i = 1; i < filtered.length; i++) {
              if (latestOem.startAt < filtered[i].startAt) {
                latestOem = filtered[i];
              }
            }
          }
          this.domainName = latestOem.domainForOsumai;
        }
      } else {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.PROPERTY_NO_OEM_TYPE);
      }
    } catch (error) {
      throw error;
    }

    // 9桁のランダムな数字を取得
    this.rd = this.createRd();
    this.$nextTick(() => {
      this.jsScrollAble();
    });
    this.isMounted = true;
  },
  methods: {
    /**9桁のランダムな数字を生成 */
    createRd(): string {
      const CODE_TABLE = '0123456789';
      let rd = '';
      for (let i = 0, j = CODE_TABLE.length; i < 9; i++) {
        rd = `${rd}${CODE_TABLE.charAt(Math.floor(j * Math.random()))}`;
      }
      return rd;
    },
    getKindString(kind: string): string {
      let kindStr = '';
      switch (kind) {
        case '01':
          kindStr = 'お知らせ';
          break;
        case '02':
          kindStr = 'キャンペーン';
          break;
        case '03':
          kindStr = 'メンテナンス';
          break;
        case '04':
          kindStr = '障害';
          break;
        case '05':
          kindStr = '障害「マンション全体」';
          break;
        case '06':
          kindStr = '障害「マンション一部」';
          break;
        default:
          kindStr = 'お知らせ';
          break;
      }
      return kindStr;
    },
    navigateToLocation(path: string) {
      if (this.isExecutingNavigateToLocation) {
        return;
      }
      this.isExecutingNavigateToLocation = true;

      this.$router
        .push(path)
        .catch((error) => {
          checkRouterError(error);
        })
        .finally(() => {
          this.isExecutingNavigateToLocation = false;
        });
    },
    jsScrollAble() {
      new this.ScrollHint('.js-scrollable');
      new this.ScrollHint('.js-scrollable-shadow', {
        suggestiveShadow: true,
      });
    },
    /**
     * ISP(Five.A)会員ステータスが「退会申し込み」かどうか(表示制御に使う)
     *
     * | isIspStatusInCancelApplication | isp-member-status   | portas-member-status |
     * | true                           | inCancelApplication | 5                    |
     * | true                           | inCancelApplication | 7                    |
     */
    getFlagIspStatusInCancelApplication() {

      const isExpectedPortasMemberStatus = [
        MEMBER_STATUS.PROPERTY_LINKED,
        MEMBER_STATUS.IN_CANCEL_APPLICATION
      ].some((value) => value === this.memberStatus?.status);

      if (isExpectedPortasMemberStatus
          && this.ispMemberStatusFiveA === ISP_MEMBER_STATUS.FIVE_A.IN_CANCEL_APPLICATION) {
        return true;
      }
      return false;
    },
    /**
     * ISP(Five.A)会員ステータスが「退会ログイン可能」のとき表示するご請求明細・販売証明書を表示するかどうか
     *
     * | isDisplayOnlyBillingDetails | isp-member-status | portas-member-status |
     * | true                        | cancelAndLoginOK  | 5                    |
     * | true                        | cancelAndLoginOK  | 7                    |
     */
    getFlagDisplayOnlyBillingDetails() {

      const isExpectedPortasMemberStatus = [
        MEMBER_STATUS.PROPERTY_LINKED,
        MEMBER_STATUS.IN_CANCEL_APPLICATION
      ].some((value) => value === this.memberStatus?.status);

      if (isExpectedPortasMemberStatus
          && this.ispMemberStatusFiveA === ISP_MEMBER_STATUS.FIVE_A.CANCEL_AND_LOGIN_OK) {
        return true;
      }
      return false;
    }
  },
  computed: {
    apid() {
      if (this.$data.propertyInfo) {
        return this.$data.propertyInfo.IN_APID;
      } else {
        return '';
      }
    },
  },
});
