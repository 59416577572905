

















































import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { FiveACustomer } from '@/shared/classes/external-api/five-a/customer-response';
import { FiveASharedErrorResponse } from '@/shared/classes/external-api/five-a/shared-error-response';
import { UcomCustomerResponse } from '@/shared/classes/external-api/ucom/customer-response';
import { UcomSharedErrorResponse } from '@/shared/classes/external-api/ucom/shared-error-response';
import { Member } from '@/shared/classes/spf-api/member';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Property } from '@/shared/classes/spf-api/property';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { MEMBER_STATUS } from '@/shared/const/member-status';
import { UaTypeValue, UA_TYPE } from '@/shared/const/service-type';
import { AuthService } from '@/shared/services/auth/auth-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import Vue from 'vue';
/** Guide コンポーネント */
export default Vue.extend({
  name: 'platform-cancel-guide',
  components: {
    ErrorMessagesComponent
  },
  data: () => ({
    /** データ読込み終了 */
    isMounted: false,
    // Portas会員退会申し込み済みかどうか
    isInCancelApplication: false,
    // エラーメッセージ
    errorMessages: new Array<string>()
  }),
  async mounted() {
    const isLoggedIn = AuthService.isLoggedIn();
    // ログインしていない場合「総合トップ」画面にリダイレクトする
    if (!isLoggedIn) {
      this.$router.push('/').catch(error => {checkRouterError(error)});
      return;
    }
    // 直リンク対策 連携済みのサービスがある場合は退会不可の案内画面へ遷移させる
    const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
    if (!memberStatus) {
      this.$router.push('/').catch(error => {checkRouterError(error)});
      return;
    }
    const member: Member = this.$store.getters['memberStore/member'];
    if (!member) {
      this.$router.push('/').catch(error => {checkRouterError(error)});
      return;
    }

    // 退会要件を満たすかどうか調べる
    const canCancelResult = await Promise.all([
      this.canCancelUcom(),
      this.canCancelEmansion(),
      this.canCancelFiveA()
    ]);

    // 外部APIがエラーのとき
    const foundIndex = canCancelResult.findIndex((value) => value instanceof Error);

    if (foundIndex === 0) {
      throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.CONTRACTOR_INFO);
    } else if (foundIndex === 1) {
      throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
    } else if (foundIndex === 2) {
      throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.CONTRACTOR_INFO);
    }

    // 退会要件を満たさないサービスがあるとき、退会不可の案内画面へ遷移
    if (canCancelResult.some((value) => value === false)) {
      this.$router.push('/platform/cancel/impossible').catch(error => {checkRouterError(error)});
      return;
    }

    // 既にPortas会員退会申し込み済みの場合、エラーメッセージを表示 & ボタンを非活性にする
    if (memberStatus.status === MEMBER_STATUS.IN_CANCEL_APPLICATION) {
      this.isInCancelApplication = true;
      this.errorMessages.push('既にPortas会員退会のお申し込みをいただいております。現在退会手続き中です。');
    }

    this.isMounted = true;
  },
  methods: {
    /** 「同意する ➡」ボタン押下時 : 退会の確認画面に遷移する */
    async onApply() {
      this.$router.push('/platform/cancel/confirm').catch(error => {checkRouterError(error)});
    },
    /**
     * 退会可能かどうか調べる(UCOM用)
     *
     * 1. UCOMと紐づけがない場合: true
     * 紐づけがないパターン
     * |          | AuthService.isLinkedToUcom | member.primaryKeyUcom | property.uaType |
     * | pattern1 | x                          | x                     | all             |
     * | pattern2 | x                          | o                     | Not(ucom)       |
     *
     * 2. UCOMと紐づけあり かつ UCOM契約基本情報取得APIの結果の退会日(quit_date) が 空文字列でない場合: true
     * 上記以外の場合: false
     */
    async canCancelUcom(): Promise<boolean | Error> {

      const isLinked = await AuthService.isLinkedToUcom();
      const ucomMemberId = this.$store.getters['memberStore/member'].primaryKeyUcom;
      const property: Property | undefined = this.$store.getters['propertyStore/property'];
      const uaType: UaTypeValue | undefined = property?.uaType;

      if ((!isLinked && !ucomMemberId)
          || (!isLinked && ucomMemberId && uaType !== UA_TYPE.UCOM)) {
        return true;
      }

      try {
        // ucomの契約基本情報をStoreから取得
        const result: Promise<UcomCustomerResponse | UcomSharedErrorResponse>
          = await this.$store.dispatch('ucomCommonStore/customer', ucomMemberId);

        if (result instanceof UcomCustomerResponse) {
          if (result.quit_date) {
            return true;
          }
        }
      } catch (error) {
        return new Promise((resolve) => { resolve(error as Error); });
      }

      return false;
    },
    /**
     * 退会可能かどうか調べる(e-mansion用)
     *
     * 1. e-mansionと紐づけがない場合: true
     * 紐づけがないパターン
     * |          | AuthService.isLinkedToEmansion | member.primaryKeyMye | property.uaType |
     * | pattern1 | x                              | x                    | all             |
     * | pattern2 | x                              | o                    | Not(e-mansion)  |
     *
     *
     * 2. e-mansionと紐づけあり かつ e-mansion契約基本情報取得APIの結果の退会日(quit_data) < 現在日付 の場合: true
     *
     * 3. e-mansionと紐づけあり かつ
     *      e-mansion契約基本情報取得APIの結果の退会日(quit_data) >= 現在日付
     *      かつ
     *      e-mansion契約基本情報取得APIの結果のステータス(status) === '01' ('01': 退会)
     *    の場合: true
     *
     * 上記以外の場合: false
     */
    async canCancelEmansion(): Promise<boolean | Error> {

      const isLinked = await AuthService.isLinkedToEMansion();
      const emansionMemberId = this.$store.getters['memberStore/member'].primaryKeyMye;
      const property: Property | undefined = this.$store.getters['propertyStore/property'];
      const uaType: UaTypeValue | undefined = property?.uaType;

      if ((!isLinked && !emansionMemberId)
          || (!isLinked && emansionMemberId && uaType !== UA_TYPE.E_MANSION)) {
        return true;
      }

      const propertyId: string | undefined = property?.apartmentId;
      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: uaType,
        apartment_id: propertyId
      });

      try {
        // 契約基本情報取得
        const customer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
          memberId: emansionMemberId,
          query: findCustomerByIdQueryDto
        });

        if (customer instanceof EMansionCustomer) {
          if (customer.quit_date) {
            const buf = customer.quit_date.split('/'); // yyyy/mm/dd を想定
            const utc = Date.UTC(parseInt(buf[0], 10), parseInt(buf[1], 10) - 1, parseInt(buf[2], 10));

            if (utc < new Date().getTime()) {
              return true;
            }

            if (utc >= new Date().getTime() && customer.status === '01') {
              return true;
            }
          }
        }
      } catch (error) {
        return new Promise((resolve) => { resolve(error as Error); });
      }

      return false;
    },
    /**
     * 退会可能かどうか調べる(Five.A用)
     *
     * 1. Five.Aと紐づけがない場合: true
     * 紐づけがないパターン
     * |          | AuthService.isLinkedToFiveA | member.primaryKeyMye | property.uaType |
     * | pattern1 | x                           | x                    | all             |
     * | pattern2 | x                           | o                    | Not(Five.A)     |
     *
     *
     * 2. Five.Aと紐づけあり かつ Five.A契約基本情報取得APIの結果の退会日(quit_data) < 現在日付 の場合: true
     *
     * 3. Five.Aと紐づけあり かつ
     *      Five.A契約基本情報取得APIの結果の退会日(quit_data) >= 現在日付
     *      かつ
     *      Five.A契約基本情報取得APIの結果のステータス(status) === '01' ('01': 退会)
     *    の場合: true
     *
     * 上記以外の場合: false
     */
    async canCancelFiveA(): Promise<boolean | Error> {

      const isLinked = await AuthService.isLinkedToFiveA();
      const fiveAMemberId = this.$store.getters['memberStore/member'].primaryKeyMye;
      const property: Property | undefined = this.$store.getters['propertyStore/property'];
      const uaType: UaTypeValue | undefined = property?.uaType;

      if ((!isLinked && !fiveAMemberId)
          || (!isLinked && fiveAMemberId && uaType !== UA_TYPE.FIVE_A)) {
        return true;
      }

      const propertyId: string | undefined = property?.apartmentId;
      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: uaType,
        apartment_id: propertyId
      });

      try {
        // 契約基本情報をStoreから取得
        const customer: FiveACustomer | FiveASharedErrorResponse = await this.$store.dispatch('fiveACommonStore/customer', {
          memberId: fiveAMemberId,
          query: findCustomerByIdQueryDto
        });

        if (customer instanceof FiveACustomer) {
          if (customer.quit_date) {
            const buf = customer.quit_date.split('/'); // yyyy/mm/dd を想定
            const utc = Date.UTC(parseInt(buf[0], 10), parseInt(buf[1], 10) - 1, parseInt(buf[2], 10));

            if (utc < new Date().getTime()) {
              return true;
            }

            if (utc >= new Date().getTime() && customer.status === '01') {
              return true;
            }
          }
        }
      } catch (error) {
        return new Promise((resolve) => { resolve(error as Error); });
      }

      return false;
    }
  },
  computed: {
    /**
     * 活性非活性に応じてクラスを変更する
     */
    getApplyButtonCancel(): string {
      return this.isMounted && !this.isInCancelApplication ? 'btn btn01 bs' : 'btn btn04 bs';
    }
  }
});
