import axios from 'axios';
import { BaseApiService } from '@/shared/services/api/base-api-service';
import { JudgeError } from '@/infra/accessor/spf/common/judge-error';
import { EMansionHomepageCapacityList } from '@/shared/classes/spf-api/homepage/model/e-mansion-homepage-capacity-list';
import { EMansionHomepageCapacityInput } from '@/shared/classes/spf-api/homepage/model/e-mansion-homepage-capacity-input';
import { EMansionHomepageCapacityConfirm } from '@/shared/classes/spf-api/homepage/model/e-mansion-homepage-capacity-confirm';

const api = BaseApiService.api();
const rawApi = axios.create({ baseURL: process.env.VUE_APP_API_ENDPOINT_URL });

/** 内部 API をコールするサービス */
export class SpfApiHomepageCapacityAccessor {
  /**
   * ホームページ容量情報(利用状況)を取得する
   *
   * @param eMansionAccountId
   * @param processOn
   * @return Promise<EMansionHomepageCapacityList>
   */
  public static async getEMansionHomepageCapacityList(eMansionAccountId: number, processOn: Date): Promise<EMansionHomepageCapacityList> {
    const response = await api.get<any>(`/hp-youryou/e-mansion/joukyou/${eMansionAccountId}?processingDate=${processOn}`).catch(async (error) => {
      await JudgeError.judgeError(error);
      throw error;
    });
    return await this.convertEMansionHomepageCapacityList(response);
  }
  private static async convertEMansionHomepageCapacityList(response: any | undefined): Promise<EMansionHomepageCapacityList> {
    if (!response) {
      throw new Error('アカウント情報無し');
    }

    return new EMansionHomepageCapacityList({
      _eMansionHomepageId: response.data.eMansionHomepageId,
      _ftpAccount: response.data.ftpAccount,
      _homepageCapacity: response.data.eMansionHomepageCapacity,
      _canChangeCapacity: response.data.canChangeCapacity,
      _isSameMonthChange: response.data.sameMonthChangeJudgememt,
      _errorMessages: response.data.errorMessages,
    });
  }

  /**
   * ホームページ容量情報(変更)を取得する
   *
   * @param eMansionAccountId
   * @param eMansionHomepageId
   * @param maxQuota
   * @return Promise<EMansionHomepageCapacityList>
   */
  public static async getEMansionHomepageCapacityInput(eMansionAccountId: number, eMansionHomepageId: number, maxQuota: string, processOn: Date): Promise<EMansionHomepageCapacityInput> {
    const response = await api
      .get<any>(`/hp-youryou/e-mansion/henkou/${eMansionAccountId}?eMansionHomepageId=${eMansionHomepageId}&maximumHpCapacity=${maxQuota}&processingDate=${processOn}`)
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });
    console.log(response);
    return await this.convertEMansionHomepageCapacityInput(response);
  }
  private static async convertEMansionHomepageCapacityInput(response: any | undefined): Promise<EMansionHomepageCapacityInput> {
    if (!response) {
      throw new Error('アカウント情報無し');
    }

    return new EMansionHomepageCapacityInput({
      _hpCapacityListCount: response.data.hpCapacityListCount,
      _hpCapacityList: response.data.hpCapacityList,
      _errorMessages: response.data.errorMessages,
    });
  }

  /**
   * ホームページ容量情報(変更)を更新する
   *
   * @param eMansionAccountId
   * @param eMansionHomepageId
   * @param eMansionHomepageCapacityKey
   * @param processOn
   * @return Promise<EMansionHomepageCapacityConfirm>
   */
  public static async updateEMansionHomepageCapacity(eMansionAccountId: number, eMansionHomepageId: number, eMansionHomepageCapacityKey: string, processOn: Date): Promise<EMansionHomepageCapacityConfirm> {
    const response = await api.post<any>(`/hp-youryou/e-mansion/kakunin`, {
      eMansionAccountId: eMansionAccountId,
      eMansionHomepageId: eMansionHomepageId,
      hpCapacityAfterChange: eMansionHomepageCapacityKey,
      processingDate: processOn,
    })
    .catch(async (error) => {
      await JudgeError.judgeError(error);
      throw error;
    });
    return this.convertEMansionHomepageCapacityConfirm(response);
  }
  private static async convertEMansionHomepageCapacityConfirm(response: any | undefined): Promise<EMansionHomepageCapacityConfirm> {
    if (!response) {
      throw new Error('レスポンス無し');
    }
    return new EMansionHomepageCapacityConfirm({
      _errorMessages: response.data.errorMessages,
    });
  }

}
