
























































































































import Vue from 'vue';

/** 使用するバリデーションの定義 */
import { required, maxLength } from 'vuelidate/lib/validators';
import { halfWidth } from '@/shared/util/validators';
/** エラーメッセージ用コンポーネント */
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';

/** サービス UCOM Connectix 外部 API をコールするサービス */
import { UcomConnectixExternalApiService } from '@/shared/services/external-api/connectix/ucom/ucom-connectix-external-api-service';

/** Entity UCOM Connectix API : Connectix お申し込み可能な部屋番号一覧 : レスポンス */
import { UcomConnectixPropertyRoomsResponse } from '../ucom/classes/external-api/property-rooms-response';
/** Entity UCOM 共通利用 API : 物件基本情報取得 : レスポンス */
import { UcomPropertyResponse } from '../../../shared/classes/external-api/ucom/property-response';
/** Entity UCOM 共通利用 API : 契約基本情報取得 : レスポンス */
import { UcomCustomerResponse } from '../../../shared/classes/external-api/ucom/customer-response';
/** Entity UCOM Connectix API : エラー時のレスポンス */
import { UcomConnectixErrorResponse } from '../ucom/classes/external-api/connectix-error-response';
/** Entity UCOM Connectix お申し込み 入力フォーム */
import { UcomConnectixEntryInputForm } from '../ucom/classes/entry-input-form';
import { AuthService } from '@/shared/services/auth/auth-service';
import { UcomSharedErrorResponse } from '@/shared/classes/external-api/ucom/shared-error-response';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';

/** UCOM光レジデンス Connectixお申し込み お申し込み画面 */
export default Vue.extend({
  name: 'ucom-connectix-apply',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    LoadingComponent
  },
  data: () => ({
    /** 物件名 */
    builingName: '',
    /** Connectix利用金額 */
    connectixUsageFee: '',
    /** 画面入力項目 */
    inputData: {
      /** 部屋番号 */
      roomNumber: ''
    },
    /** エラーの発生有無 部屋番号 */
    isInvalidRoomNumber: false,
    /** エラーメッセージを格納する配列 */
    errorMessages: [],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '',
    /** お申し込みボタン活性/非活性判定 */
    disableApply: false,
    /** UCOM 物件 ID */
    ucomPropertyId: '',
    /** UCOM 会員 ID */
    ucomMemberId: '',
    // vueのmounted実行中かどうか
    isMounting: true,
    // onApply()の実行中かどうか
    isOnApplyExecuting: false
  }),
  /** バリデーション定義 */
  validations: {
    inputData: {
      /** 部屋番号 */
      roomNumber: {
        required,
        halfWidth,
        maxLength: maxLength(50)
      }
    }
  },
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    // エラーメッセージ格納配列初期化
    this.$data.errorMessages = [];

    // UCOM 物件基本情報をStoreから取得
    try {

      /** ログインしているか否かの情報を取得 */
      const isLoggedIn = await AuthService.isLoggedIn();
      // ログインしていない場合「総合トップ」画面にリダイレクトする
      if (!isLoggedIn) {
        this.$router.push('/').catch(error => {checkRouterError(error)});
        return;
      }

      // Auth0ユーザのapp_metadataからUCOMの物件IDを取得する
      this.$data.ucomPropertyId = await AuthService.getUcomPropertyId();

      const property: Promise<UcomPropertyResponse | UcomSharedErrorResponse> = await this.$store.dispatch('ucomCommonStore/property', this.$data.ucomPropertyId);
      if (property instanceof UcomPropertyResponse) {
        // 物件名
        this.$data.builingName = property.building_name;
      } else if (property instanceof UcomSharedErrorResponse) {
        // データ不整合エラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.PROPERTY_INFO);
      }
    } catch (error) {
      throw error;
    }

    /** UCOM 契約基本情報を取得 */
    try {
      // UCOM のメンバー ID をStoreから取得する
      this.$data.ucomMemberId = await AuthService.getUcomMemberId();
      const customer: Promise<UcomCustomerResponse | UcomSharedErrorResponse> = await this.$store.dispatch('ucomCommonStore/customer', this.$data.ucomMemberId);
      if (customer instanceof UcomCustomerResponse) {
        // 正常ケースの場合
        if (customer.connectix_status) {
          this.$data.errorMessages.push('お客様は既にConnectixを契約しています。');
          return;
        }
        // Connectix利用金額
        this.$data.connectixUsageFee = customer.connectix_price;
        // 部屋番号はUCOM側のAPIで取得したものが空白以外であればデフォルトで表示
        if (customer.room_number !== '') {
          // 部屋番号
          this.$data.inputData.roomNumber = customer.room_number;
        }
      } else if (customer instanceof UcomSharedErrorResponse) {
        // データ不整合エラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.CONTRACTOR_INFO);
      }
    } catch (error) {
      throw error;
    } finally {
      this.isMounting = false;
    }

    this.isMounting = true;

    // 入力画面から戻って来た場合は、事前に入力されていた情報を再現する
    const inputApply = this.$store.getters['ucomConnectixEntryStore/entryInputForm'];
    if (inputApply != null) {
      // 部屋番号
      this.$data.inputData.roomNumber = inputApply.roomNumber;
    }

    this.isMounting = false;
  },
  methods: {
    /** 「お申し込み ➡」ボタン押下時 : Connectixお申し込みチェックAPIを実行し 「お申し込み確認」画面に遷移する */
    async onApply() {
      if (this.isOnApplyExecuting) {
        return;
      }
      this.isOnApplyExecuting = true;

      // エラーの発生有無 部屋番号初期化
      this.$data.isInvalidRoomNumber = false;
      // エラーメッセージ格納配列初期化
      this.$data.errorMessages = [];
      // 入力チェック
      if (this.$v.inputData.$invalid) {
        // 部屋番号
        if (this.$v.inputData.roomNumber?.$invalid) {
          this.$data.isInvalidRoomNumber = true;
          // 必須チェック
          if (!this.$v.inputData.roomNumber?.required) {
            this.$data.errorMessages.push(`部屋番号を入力してください。`);
          }
          // 形式チェック
          if (!this.$v.inputData.roomNumber?.halfWidth || !this.$v.inputData.roomNumber?.maxLength) {
            this.$data.errorMessages.push(`正しい部屋番号を入力してください。`);
          }
        }
        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
        this.isOnApplyExecuting = false;
        return;
      }
      // Connectixお申し込みチェックAPIを実行する
      try {
        const resultFindPropertyRooms: UcomConnectixPropertyRoomsResponse | UcomConnectixErrorResponse = await UcomConnectixExternalApiService.findPropertyRooms(
          this.$data.ucomMemberId
        );
        if (resultFindPropertyRooms instanceof UcomConnectixErrorResponse) {
          // エラーケースの場合
          // API側から返されたエラーメッセージを表示
          this.$data.errorMessages = resultFindPropertyRooms.errorInfo;
          window.scrollTo(0, 0);
          return;
        } else if (resultFindPropertyRooms instanceof UcomConnectixPropertyRoomsResponse) {
          // 正常ケースの場合
          const roomNumbers: string[] | undefined = resultFindPropertyRooms.room_numbers;
          if (!roomNumbers || roomNumbers.length === 0) {
            // 物件内にConnectixお申し込みが出来る部屋が無い場合はエラー
            this.$data.errorMessages.push(`該当の物件にはConnectixのお申し込みが出来る部屋がありません。`);
            this.$data.isInvalidRoomNumber = true;
            window.scrollTo(0, 0);
            return;
          }
          // お申し込み可能な部屋の中に自部屋があるかチェック
          const applyRooms: string | undefined = roomNumbers.find((room) => room === this.$data.inputData.roomNumber);
          if (!applyRooms) {
            // 入力した部屋がお申し込み可能な部屋ではなかった場合はエラー
            this.$data.errorMessages.push(`この部屋は現在お手続きが出来ない状態です。`);
            this.$data.isInvalidRoomNumber = true;
            window.scrollTo(0, 0);
            return;
          }
        } else {
          // データ不整合エラー
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.ROOM_INFO);
        }
      } catch (error) {
        throw error;
      } finally {
        this.isOnApplyExecuting = false;
      }
      this.isOnApplyExecuting = true;

      // 入力情報をストアに保存
      const inputData = new UcomConnectixEntryInputForm({
        // 部屋番号
        roomNumber: this.$data.inputData.roomNumber
      });
      this.$store.commit('ucomConnectixEntryStore/entryInputForm', inputData);
      this.$router.push('/connectix/ucom/confirm').catch(error => {checkRouterError(error)}).finally( () => {
        this.isOnApplyExecuting = false;
      });
    }    
  }
});
