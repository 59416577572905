






















import Vue from 'vue';
import { AuthService } from '../shared/services/auth/auth-service';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { TRANSITION_STORAGE } from '@/shared/const/service-type';

/** アカウント登録踏み台 画面 */
export default Vue.extend({
  name: 'login-forwarding',
  components: {
    LoadingComponent,
  },
  data: () => ({
    isLoading: true
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async created() {
    if(Object.keys(this.$route.query).length){
      localStorage.setItem(TRANSITION_STORAGE, JSON.stringify(this.$route.query));// LINE対策
    }
    await AuthService.login({ path: this.$route.path, query: this.$route.query });
  }
});
