




















































































import Vue from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiPaymentStatusAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-payment-status-accessor';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { SpfApiHomepageCapacityAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-homepage-capacity-accessor';
import { Member } from '@/shared/classes/spf-api/member';
import { E_MANSION_PAYMENT_STATUS } from '@/shared/const/e-mansion/e-mansion-payment-status';
import { numberWithDelimiter } from '../../../shared/util/vue-filters';

export default Vue.extend({
  name: 'homepageCapacityConfirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    ftpAccount: '', // FTPアカウント
    eMansionHomepageCapacity: '', // 現在のホームページ容量
    eMansionHomepageCapacitySelected: undefined, // 選択したホームページ容量
    eMansionHomepageCapacitySelectedValue: '', // 選択したホームページ容量
    homepageCapacityListCount: 0, // ホームページ容量追加要素数
    homepageCapacityList: [], // ホームページ容量配列
    eMansionAccountId: '', // e-mansion アカウントID
    eMansionHomepageId: '', // e-mansion ホームページID
    fee: '', // 月額利用料 (50MBごと)
    monthlyFee: 0, // 表示する月額利用料
    jimFee: '', // 事務手数料
    maxQuota: '', // 最大容量(MB)
    tax: '', // 消費税率
    errorMessages: [], // エラーメッセージを格納する配列
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。', // エラーメッセージ部に表示するタイトル
    isChangeBtn: false, //変更ボタン活性化するか
    isSameMonthChange: false, //同月内変更有無
    isLoading: false, //ローディング
    canApply: false, //お支払情報登録有無
    member: Member,
    CANT_APPLY_MESSAGE: 'お支払情報のご登録が必要です',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      this.$data.isLoading = true;

      // 前画面からの情報をStoreから取得
      if (!this.$store.getters['eMansionHomepageCapacityStore/eMansionAccountId']) {
        // e-mansionアカウントID(Store)を取得できない場合、前画面へ戻る
        await this.$router.push('/e-mansion/homepage-capacity/list');
        this.$data.isLoading = false;
        return;
      }

      // 会員情報をStoreから取得
      this.$data.member = this.$store.getters['memberStore/member'];

      // お支払い情報登録状態を取得
      this.$data.paymentInfo = await SpfApiPaymentStatusAccessor.getPaymentStatus(this.$data.member.id);
      if (!this.$data.paymentInfo) {
        this.$router.push('/e-mansion/error');
        this.$data.isLoading = false;
        return;
      }
      // お支払い情報登録状態チェック
      this.checkPaymentInfo(this.$data.paymentInfo.status);

      // 前画面情報の取得
      this.$data.eMansionAccountId = this.$store.getters['eMansionHomepageCapacityStore/eMansionAccountId'];
      this.$data.eMansionHomepageId = this.$store.getters['eMansionHomepageCapacityStore/eMansionHomepageId'];
      this.$data.ftpAccount = this.$store.getters['eMansionHomepageCapacityStore/ftpAccount'];
      this.$data.eMansionHomepageCapacity = this.$store.getters['eMansionHomepageCapacityStore/eMansionHomepageCapacity'];
      this.$data.eMansionHomepageCapacitySelected = this.$store.getters['eMansionHomepageCapacityStore/eMansionHomepageCapacitySelected'];
      this.$data.eMansionHomepageCapacitySelectedValue = this.$data.eMansionHomepageCapacitySelected.value;
      this.$data.fee = this.$store.getters['eMansionHomepageCapacityStore/fee'];
      this.$data.jimFee = this.$store.getters['eMansionHomepageCapacityStore/jimFee'];
      this.$data.maxQuota = this.$store.getters['eMansionHomepageCapacityStore/maxQuota'];

      // お支払い情報登録状態が未登録の場合のエラーメッセージ
      if(!this.$data.canApply) {
        this.$data.errorMessages.push(this.$data.CANT_APPLY_MESSAGE);
        this.isLoading = false;
        return;
      }

      // 表示する月額利用料の計算
      const unitFee = Number(this.fee.replace(',', ''));
      const selectedCapacity = Number(this.eMansionHomepageCapacitySelectedValue.replace('MB', ''));
      this.monthlyFee = unitFee * (selectedCapacity / 50);

      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error');
      return;
    }
  },
  methods: {
    // 戻るボタンクリック
    async goBack() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      // ホームページ容量情報をstoreに保存する
      this.$store.commit('eMansionHomepageCapacityStore/eMansionAccountId', this.$data.eMansionAccountId);
      this.$store.commit('eMansionHomepageCapacityStore/eMansionHomepageId', this.$data.eMansionHomepageId);
      this.$store.commit('eMansionHomepageCapacityStore/ftpAccount', this.$data.ftpAccount);
      this.$store.commit('eMansionHomepageCapacityStore/eMansionHomepageCapacity', this.$data.eMansionHomepageCapacity);
      this.$store.commit('eMansionHomepageCapacityStore/fee', this.$data.fee);
      this.$store.commit('eMansionHomepageCapacityStore/jimFee', this.$data.jimFee);
      this.$store.commit('eMansionHomepageCapacityStore/maxQuota', this.$data.maxQuota);

      await this.$router.push('/e-mansion/homepage-capacity/input');
      this.isLoading = false;
    },
    // 申し込みボタンクリック
    async goEntry() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      try {

        // ホームページ容量情報をstoreに保存する
        this.$store.commit('eMansionHomepageCapacityStore/eMansionAccountId', this.$data.eMansionAccountId);
        this.$store.commit('eMansionHomepageCapacityStore/eMansionHomepageId', this.$data.eMansionHomepageId);
        this.$store.commit('eMansionHomepageCapacityStore/eMansionHomepageCapacitySelected', this.$data.eMansionHomepageCapacitySelected.key);

        // 現在日付の取得
        const processOn = await SpfApiServerCommonUtilAccessor.getServerTimeNow();

        // ホームページ容量を更新する
        const response = await SpfApiHomepageCapacityAccessor.updateEMansionHomepageCapacity(
          this.$data.eMansionAccountId,
          this.$data.eMansionHomepageId,
          this.$data.eMansionHomepageCapacitySelected.key,
          processOn,
        );
        // エラーメッセージがあれば画面に表示する
        if (response.errorMessages.length > 0) {
          this.$data.errorMessages = response.errorMessages;
          window.scrollTo(0, 0);
          this.$data.isLoading = false;
          return;
        }

      } catch (error) {
        await this.$router.push('/e-mansion/error');
        this.$data.isLoading = false;
        return;
      }

      await this.$router.push('/e-mansion/homepage-capacity/complete');
      this.$data.isLoading = false;
    },
    checkPaymentInfo(paymentInfoStatus: E_MANSION_PAYMENT_STATUS): boolean {
      // お支払情報登録があった場合、活性化などを設定
      this.$data.canApply = paymentInfoStatus == E_MANSION_PAYMENT_STATUS.REGISTERED;

      // お支払情報登録有無の保持
      this.$store.commit('eMansionHomepageCapacityStore/canApply', this.$data.canApply);
      return false;
    },
  },
  filters: {
    numberWithDelimiter,
  },
});
