import axios from 'axios';
import { BaseApiService } from '@/shared/services/api/base-api-service';
import { EMansionMainMailAddressList } from '@/shared/classes/spf-api/mail/model/main-mail-address/e-mansion-main-mail-address';
import { EMansionMainMailAddressInput } from '@/shared/classes/spf-api/mail/model/main-mail-address/e-mansion-main-mail-address-input';
import { EMansionMainMailAddressCancel } from '@/shared/classes/spf-api/mail/model/main-mail-address/e-mansion-main-mail-address-cancel';
import { JudgeError } from '@/infra/accessor/spf/common/judge-error';
import { EMansionMainMailAddressCreate } from '@/shared/classes/spf-api/mail/model/main-mail-address/e-mansion-main-mail-address-create';
import { EMansionMainMailAddressUpdate } from '@/shared/classes/spf-api/mail/model/main-mail-address/e-mansion-main-mail-address-update';
import { ObfuscationService } from '@/shared/services/obfuscation/obfuscation-service';
import { EMansionMainMailAddressDto } from '@/shared/classes/spf-api/mail/model/main-mail-address/e-mansion-main-mail-address-dto';
import { EMansionMainMailAddressCancelDto } from '@/shared/classes/spf-api/mail/model/main-mail-address/e-mansion-main-mail-address-cancel-dto';

const api = BaseApiService.api();
const rawApi = axios.create({ baseURL: process.env.VUE_APP_API_ENDPOINT_URL });

/** 内部 API をコールするサービス */
export class SpfApiMainMailAddressAccessor {
  /**
   * メインメール利用状況取得
   * @param id e-mansion_アカウントID
   * @return メールアドレス利用状況
   */
  public static async getMainMailAddressList(id: number): Promise<EMansionMainMailAddressList> {
    const response = await api.get<EMansionMainMailAddressDto>(`/main-mail-address/e-mansion/joukyou/${id}`).catch(async (error) => {
      await JudgeError.judgeError(error);
      throw error;
    });
    return await this.convertMainMailInfo(response);
  }

  private static async convertMainMailInfo(response: any | undefined): Promise<EMansionMainMailAddressList> {
    if (!response.data) {
      throw new Error('メインメール情報無し');
    }
    return new EMansionMainMailAddressList({
      _hasMailAddress: response.data.hasMailAddress,
      _mailAddress: response.data.mailAddress,
      _registerStatusView: response.data.registerStatusView,
      _registerStatus: response.data.registerStatus,
      _canMainMailAddressCancel: response.data.canMainMailAddressCancel,
      _serviceInTime: response.data.serviceInTime,
      _cancelOn: response.data.cancelOn,
      _shouldNotificationMailAddress: response.data.shouldNotificationMailAddress,
      _accountName: response.data.accountName,
      _initialMailPassword: response.data.initialMailPassword ? ObfuscationService.decode(response.data.initialMailPassword) : undefined,
      _popServer: response.data.popServer,
      _smtpServer: response.data.smtpServer,
      _errMsg: response.data.errMsg,
    });
  }

  /**
   * e-mansion メインメールを登録する
   * @param mailAddress 新しいメールアドレス（ドメインなし）
   * @param eMansionAccountId e-mansion_アカウントID
   * @param propertyId 物件ID
   * @param servicePlanType 会員種別
   * @param mailAddressDomain メールアドレスドメイン
   * @param mailAddressOriginalDomain 独自ドメイン
   * @param processOn 処理日
   * @return メールアドレス, サービス終了日, メッセージ
   */
  public static async createMainMailAddress(
    mailAddress: string,
    eMansionAccountId: number,
    propertyId: string,
    servicePlanType: string,
    mailAddressDomain: string,
    mailAddressOriginalDomain: string | undefined,
    processOn: Date
  ): Promise<EMansionMainMailAddressCreate> {
    const response = await api
      .post<EMansionMainMailAddressCreate>(`/main-mail-address/e-mansion/kakunin-touroku`, {
        mailAddress: mailAddress,
        eMansionAccountId: eMansionAccountId,
        propertyId: propertyId,
        memberType: servicePlanType,
        mailAddressDomain: mailAddressDomain,
        mailAddressOriginalDomain: mailAddressOriginalDomain,
        processOn: processOn,
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });
    return this.convertCreateMainMailAddress(response);
  }

  private static async convertCreateMainMailAddress(response: any | undefined): Promise<EMansionMainMailAddressCreate> {
    if (!response) {
      throw new Error('メインメール登録情報無し');
    }
    return new EMansionMainMailAddressCreate({
      _mailAddress: response.data.mailAddress,
      _errorMessages: response.data.errorMessages,
    });
  }

  /**
   * e-mansion メインメールを解約する
   * @param mailAddress ご解約対象メールアドレス
   * @param accId e-mansion_アカウントID
   * @param proId 物件ID
   * @param date 処理日
   * @return メールアドレス, サービス終了日, メッセージ
   */
  public static async cancelMainMail(mailAddress: string, accId: string, proId: string, date: string): Promise<EMansionMainMailAddressCancel> {
    try {
      const response = await api
        .post<EMansionMainMailAddressCancelDto>(`/main-mail-address/e-mansion/kakunin-kaiyaku`, {
          mailAddress,
          accId,
          proId,
          date,
        })
        .catch(async (error) => {
          await JudgeError.judgeError(error);
          throw error;
        });
      return this.convertMainMailCancelInfo(response);
    } catch (error: any) {
      return new EMansionMainMailAddressCancel({ _message: [error.message] });
    }
  }

  private static async convertMainMailCancelInfo(response: any | undefined): Promise<EMansionMainMailAddressCancel> {
    if (!response) {
      throw new Error('メインメール解約情報無し');
    }
    return new EMansionMainMailAddressCancel({
      _mailAddress: response.data.mailAddress,
      _finishDate: response.data.finishDate,
      _message: response.data.message,
    });
  }

  /**
   * メールアドレス入力チェックAPI
   * @param mailAddress メールアドレス
   * @return チェック結果
   */
  public static async checkMainMailAddress(mailAddress: string): Promise<EMansionMainMailAddressInput> {
    try {
      const response = await api
        .get<EMansionMainMailAddressInput>(`/common/e-mansion/mailAddress-check/`, { params: { mailAddress: mailAddress } })
        .catch(async (error) => {
          await JudgeError.judgeError(error);
          throw error;
        });
      return response.data;
    } catch (error: any) {
      throw error;
    }
  }

  /** e-mansion メインメールを更新する
   *
   * @param mailAddress 新しいメールアドレス（ドメインなし）
   * @param eMansionAccountId e-mansion_アカウントID
   * @param propertyId 物件ID
   * @param mailAddressDomain メールアドレスドメイン
   * @param mailAddressOriginalDomain 独自ドメイン
   * @param processOn 処理日
   * @return メールアドレス, サービス終了日, メッセージ
   */
  public static async updateMainMailAddress(
    mailAddress: string,
    eMansionAccountId: number,
    propertyId: string,
    mailAddressDomain: string,
    mailAddressOriginalDomain: string | undefined,
    processOn: Date
  ): Promise<EMansionMainMailAddressUpdate> {
    const response = await api
      .post<any>(`/main-mail-address/e-mansion/kakunin-koushin`, {
        mailAddress: mailAddress,
        eMansionAccountId: eMansionAccountId,
        propertyId: propertyId,
        mailAddressDomain: mailAddressDomain,
        mailAddressOriginalDomain: mailAddressOriginalDomain,
        processOn: processOn,
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });
    return this.convertUpdateMainMailAddress(response);
  }

  private static async convertUpdateMainMailAddress(response: any | undefined): Promise<EMansionMainMailAddressUpdate> {
    if (!response) {
      throw new Error('メインメール登録情報無し');
    }
    return new EMansionMainMailAddressUpdate({
      _mailAddress: response.data.mailAddress,
      _errorMessages: response.data.errorMessages,
    });
  }
}
