export const SERVICE_ID = {
  WEB_MAIL: 'C01',
  MAIL_VIRUS_CHECK: 'SE04',
  MAIL_VOLUME: 'SE07',
  MAIN_MAIL_ADDRESS_USAGE: 'SE10',
  MAIL_PASSWORD: 'SE11',
  MAIL_ACCOUNT_ADDITION: 'SE17',
  HOMEPAGE_USAGE: 'SE05',
  HOMEPAGE_VOLUME: 'SE06',
  E_MANSION: {
    LINK_E_MANSION_USAGE: 'LE01',
    LINK_E_MANSION_CREDIT: 'LE02',
    LINK_E_MANSION_INFO_CHANGE: 'LE03',
    LINK_CONTRACT_CONTENT_GUIDANCE: 'LE04',
    LINK_BILLING_ITEM_SALES_CERTIFICATE_DISPLAY: 'LE05',
    LINK_NEWS_EMAIL_DELIVERY_PREFERENCE: 'LE06',
    LINK_ISP_MEMBER_CONTRACT_CANCELLATION: 'LE07',
    LINK_ISP_OPTION_SERVICE: 'LE08',
  },
  UCOM_VIRUS_CHECK: 'SU04', // UCOM ウイルスチェック
  UCOM_MAIL_SERVICE_PURCHASE: 'SU08', // UCOM メールサービス購入
  UCOM_WEB_SPACE_CAPACITY_CHANGE: 'SU09', // UCOM Webスペース容量変更
  UCOM_MAIL_BOX_CAPACITY_CHANGE: 'SU10', // UCOM メールボックス容量変更
  UCOM_ACCOUNT_ID_MANAGEMENT: 'SU14', // UCOM アカウントID管理
  UCOM_ACCOUNT_ID_OPTION_CHANGE: 'SU17', // UCOM アカウントIDオプション変更
  UCOM: {
    LINK_UCOM_INFORMATION_FROM_UCOM_R: 'LU01', // UCOM光レジデンスからのお知らせ・障害情報
    LINK_UCOM_HELP_FOR_INTERNET_LATENCY: 'LU02', // UCOM インターネット通信速度にお困りの方へ
    LINK_UCOM_SHOW_REGISTRATION_INFO: 'LU03', // UCOM 各種登録情報を確認する
    LINK_UCOM_CONTRACT_INFO_LIST: 'LU04', // UCOM ご契約情報一覧
    LINK_UCOM_GUEST_INFO_CHANGE: 'LU05', // UCOM お客様情報変更
    LINK_UCOM_GUEST_NUMBER_PASSWORD_CHANGE: 'LU06', // UCOM お客様番号パスワード変更
    LINK_UCOM_PAYMENT_METHODS_CHANGE: 'LU07', // UCOM お支払方法変更
    LINK_UCOM_MONTHLY_BILL_INQUIRY: 'LU08', // UCOM 月額請求明細照合
    LINK_UCOM_OPTION_SERVICES: 'LU09', // UCOM UCOM光レジデンス 各種オプションサービスのお申込み・変更はこちらから
    LINK_CONNECTIX_APPLICATION_CANSEL: 'LU10', // UCOM Connectix お申し込み・解約 の機能ID
    LINK_UCOM_BUILDING_ONLY_SITE: 'LU11', // UCOM お住まいの「建物専用サイト」はこちら
  },
} as const;

export type SERVICE_ID = typeof SERVICE_ID[keyof typeof SERVICE_ID];
