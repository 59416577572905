





























































import {Member} from '@/shared/classes/spf-api/member';
import {MemberStatus} from '@/shared/classes/spf-api/member-status';
import {SearchPropertyDetail, SearchResultOver} from '@/shared/classes/spf-api/property';
import {MEMBER_STATUS} from '@/shared/const/member-status';
import {PROPERTY_SEARCH_TYPE, TRANSITION_SOURCE_SERVICE} from '@/shared/const/service-type';
import {SpfApiService} from '@/shared/services/api/spf-api-service';
import {AuthService} from '@/shared/services/auth/auth-service';
import {checkRouterError} from '@/shared/util/router-navigation-func';
import LoadingComponent from '@/shared/components/loading-component.vue';
import Vue from 'vue';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';

type DataType = {
  memberStatus: MemberStatus | undefined;
  memberProperty: SearchPropertyDetail | undefined;
  propertyId: number | undefined;
  isSubmitting: boolean;
  isPropertyUaTypeFiveA: boolean;
};

/** コンポーネント */
export default Vue.extend({
  name: 'confirm-email',
  components: {
    LoadingComponent
  },
  data(): DataType {
    return {
      memberStatus: undefined,
      memberProperty: undefined,
      propertyId: undefined,
      isSubmitting: false,
      isPropertyUaTypeFiveA: false //アプリケーションフローの表示判定用
    };
  },
  async mounted() {

    this.isSubmitting = true;

    const isLoggedIn = AuthService.isLoggedIn();
    // ログインしていない場合「総合トップ」画面にリダイレクトする
    if (!isLoggedIn) {
      this.$router.push('/').catch(error => {
        checkRouterError(error)
      });
      return;
    }

    // 会員ストアから会員ステータス情報を取得する
    this.memberStatus = this.$store.getters['memberStore/memberStatus'];

    const APID_REGEXP = {
      ID_8_DIGIT: /^[0-9a-zA-Z]{8}$/,
      ID_13_DIGIT: /^[0-9]{13}$/
    } as const;

    if (!this.memberStatus) {
      this.$router.push('/').catch(error => {
        checkRouterError(error)
      });
      return;
    }

    try {

      // FiveAユーザはここに来たらトップへ差し戻す
      if (this.memberStatus.transitionSourceService === TRANSITION_SOURCE_SERVICE.FIVE_A) {

        this.isPropertyUaTypeFiveA = true;

        this.$router.push('/');
        return;

        //e-mansion user
      } else if (this.memberStatus.transitionSourceService === TRANSITION_SOURCE_SERVICE.E_MANSION) {

        const searchPropertyId = this.memberStatus.transitionSourceServiceId;
        this.memberProperty = await this.findPropertyFromQueryParameter(PROPERTY_SEARCH_TYPE.E_MANSION, searchPropertyId);

      } else if (this.memberStatus.transitionSourceService === TRANSITION_SOURCE_SERVICE.UCOM) {

        if (APID_REGEXP.ID_13_DIGIT.test(this.memberStatus.transitionSourceServiceId)) {
          const searchPropertyId = String(this.memberStatus.transitionSourceServiceId);
          this.memberProperty = await this.findPropertyFromQueryParameter(PROPERTY_SEARCH_TYPE.UCOM, searchPropertyId);
        } else {
          const searchPropertyId = this.memberStatus.transitionSourceServiceId;
          this.memberProperty = await this.findPropertyFromQueryParameter(PROPERTY_SEARCH_TYPE.E_MANSION, searchPropertyId);
        }

      }

    } catch (error) {
      this.isSubmitting = false;
      throw error;
    }

    if (!this.memberProperty) {
      this.isSubmitting = false;
      throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_PROPERTY);
    }

    this.isSubmitting = false;

  },
  methods: {
    /** 会員情報を新規登録し、完了画面に遷移する */
    async onSignUp() {

      // ボタン押下中扱いの時は処理を抜ける
      if (this.isSubmitting) {
        return;
      }

      // ありえないけど実装都合で記述
      if (!this.memberStatus) {
        return;
      }

      // ボタン押下中扱いとする
      this.isSubmitting = true;

      const sub = await AuthService.getSub();
      const eMansionMemberId = await AuthService.getEMansionMemberId();
      const eMansionMemberIdETnc = await AuthService.getEMansionMemberIdETnc();
      const ucomMemberId = await AuthService.getUcomMemberId();
      const mcloudMemberId = await AuthService.getMcloudMemberId();

      const member: Member = new Member({
        emailAddress: this.memberStatus.emailAddress,
        memberStatus: 0,
        primaryKeyAuth0: sub,
        propertyId: this.memberProperty?.id
      });

      // 既存会員の場合
      if (eMansionMemberId || ucomMemberId || mcloudMemberId) {
        member.primaryKeyUcom = ucomMemberId;
        member.primaryKeyMye = eMansionMemberId ? eMansionMemberId : undefined;
        member.eTncMemberId = eMansionMemberIdETnc ? eMansionMemberIdETnc : undefined;
        member.primaryKeyMcloud = mcloudMemberId ? mcloudMemberId : undefined;
      }

      try {

        // 会員登録処理
        const resultMember = await SpfApiService.createMember(member);

        // あり得ないけど念のため確認
        if (!resultMember) {
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.PORTAS.CAN_NOT_CREATE_MEMBER);
        }

        const sendMemberStatus = this.memberStatus;
        sendMemberStatus.status = MEMBER_STATUS.ACCOUNT_REGISTERED

        await this.$store.dispatch('memberStore/updateMemberStatus', {
          primaryKeyAuth0: sub,
          memberStatus: sendMemberStatus
        });

      } catch (error: any) {
        throw error;
      }

      await AuthService.refresh();

      this.$router.push('/platform/entry/completed')
          .catch(
              error => {
                checkRouterError(error)
              }
          )
          .finally(
              () => {
                this.isSubmitting = false;
              }
          );
    },
    async findPropertyFromQueryParameter(searchType: number, transitionSourceServiceId: string): Promise<SearchPropertyDetail> {
      try {
        const propertyList: SearchPropertyDetail[] | SearchResultOver = await SpfApiService.searchProperties(
            searchType,
            transitionSourceServiceId,
            false
        );

        if (propertyList instanceof SearchResultOver) {
          throw new Error('対象物件無し');
        } else {
          return propertyList[0];
        }

      } catch(error) {
        throw error;
      }
    },
  }
});
