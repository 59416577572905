

































































































import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { ObfuscationService } from '@/shared/services/obfuscation/obfuscation-service';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { SpfApiHomepageAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-homepage-accessor';
import { EMansionHomepageCancelInitialDisplayRequest } from '@/infra/request/spf/e-mansion/homepage/e-mansion-homepage-cancel-initial-display-request';

export default Vue.extend({
  name: 'homepage-application-complete',
  components: {
    LoadingComponent,
  },

  data: () => ({
    isLoading: true,
    ftpAccount: undefined,
    ftpPassword: undefined,
    ftpServerName: undefined,
    fileDest: undefined,
    homepageUrl: undefined,
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      const ftpAccount = await this.$store.getters['eMansionHomepageOpenStore/ftpAccount'];

      if (!ftpAccount) {
        await this.reload();
      }

      // 確認画面から遷移した場合は、完了画面にデータを表示する
      await this.setData();
    } catch (error) {
      await this.$router.push('/e-mansion/error');
      return;
    }
  },
  methods: {
    async reload() {
      // リロードや戻るボタンを押下された場合は、ホームページ開設有無によって表示する画面を切り替える
      const eMansionAccountInfo = await this.$store.getters['eMansionAccountStore/eMansionAccountInfo']; // e-mansion アカウント情報取得
      const now = await SpfApiServerCommonUtilAccessor.getServerTimeNow();

      // AE05-003.ホームページ：ご利用状況／ご解約_初期表示API呼び出し
      const response = await SpfApiHomepageAccessor.callHomepageCancelInitialDisplay(
        new EMansionHomepageCancelInitialDisplayRequest({
          _eMansionAccountId: eMansionAccountInfo.eMansionAccountInfo.eMansionAccountId,
          _date: now,
        })
      );

      // 開設状態の場合はご利用状況/解約画面へ
      if (response.isHomepageOpened) {
        await this.$router.push('/e-mansion/homepage/usage').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      // それ以外は会員専用TOP画面へ
      await this.$router.push('/e-mansion').catch((error) => {
        checkRouterError(error);
      });

      return;
    },
    async setData() {
      this.$data.isLoading = true;

      this.$data.ftpAccount = await this.$store.getters['eMansionHomepageOpenStore/ftpAccount'];
      // パスワードは解読して表示
      const password = await this.$store.getters['eMansionHomepageOpenStore/ftpPassword'];
      if (password) {
        this.$data.ftpPassword = ObfuscationService.decode(password);
      }
      this.$data.ftpServerName = await this.$store.getters['eMansionHomepageOpenStore/ftpServerName'];
      this.$data.fileDest = await this.$store.getters['eMansionHomepageOpenStore/fileDest'];
      this.$data.homepageUrl = await this.$store.getters['eMansionHomepageOpenStore/homepageUrl'];

      this.$data.isLoading = false;
    },
    async goTop() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // ホームページURLをstoreに保存する
      await this.$router.push('/e-mansion').catch((error) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
    async goUsageCancel() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      // ホームページURLをstoreに保存する
      await this.$router.push('/e-mansion/homepage/usage').catch((error) => {
        checkRouterError(error);
      });
      this.isLoading = false;
    },
  },
});
