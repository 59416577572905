









































import Vue from 'vue';

/** Completed コンポーネント */
export default Vue.extend({
  name: 'platform-cancel-completed'
});
