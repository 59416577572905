



























































































import Vue from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { UcomMailPackInfo } from '@/shared/classes/spf-api/model/ucom/ucom-mail-pack-info';

export default Vue.extend({
  name: 'complete',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    // メソッドが実行中かどうか
    isLoading: true,
    mailPackInfo: UcomMailPackInfo,
    contactMailAddress: '',
    messageList: [] as string[],
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    processDate: '',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      this.$data.mailPackInfo.productName = this.$store.getters['ucomMailPackStore/productName'];
      if (!this.$data.mailPackInfo.productName) {
        await this.$router.push('/ucom/mail-pack/input');
        return;
      }
      this.$data.mailPackInfo.monthlyFee = this.$store.getters['ucomMailPackStore/monthlyFee'];
      if (!this.$data.mailPackInfo.monthlyFee) {
        await this.$router.push('/ucom/mail-pack/input');
        return;
      }
      this.$data.processDate = this.$store.getters['ucomMailPackStore/processDate'];
      if (!this.$data.processDate) {
        await this.$router.push('/ucom/mail-pack/input');
        return;
      }
      this.$data.contactMailAddress = this.$store.getters['ucomMailPackStore/contactMailAddress'];

      this.$data.mailPackInfo.serviceDescription = this.$store.getters['ucomMailPackStore/serviceDescription'];
      if (!this.$data.mailPackInfo.serviceDescription) {
        await this.$router.push('/ucom/mail-pack/input');
        return;
      }

      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/error');
      return;
    }
  },
  methods: {
    //アカウント情報登録変更画面へボタン
    async goChange() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      await this.$router.push('/ucom/account-management/list');
      this.$data.isLoading = false;
    },
    //契約アカウント情報一覧画面へボタン
    async goList() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      await this.$router.push('/ucom/account-option-change/account-list');
      this.$data.isLoading = false;
    },
    //トップへボタン
    async goTop() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      await this.$router.push('/ucom');
      this.$data.isLoading = false;
    },
    formatDate(dt: Date) {
      const y = dt.getFullYear();
      const m = ('00' + (dt.getMonth() + 1)).slice(-2);
      const d = ('00' + dt.getDate()).slice(-2);
      return y + '/' + m + '/' + d;
    },
  },
});
