import { ServicePermissionMaster } from '@/shared/classes/spf-api/mail/model/common/permission/service-permission-master';
import { ItemPermissionMaster } from '@/shared/classes/spf-api/mail/model/common/permission/item-permission-master';
import { BaseApiService } from '@/shared/services/api/base-api-service';
import { JudgeError } from './judge-error';

const api = BaseApiService.api();

/** 内部 API をコールするサービス */
export class SpfApiPermissionAccessor {
  /**
   * 認可マスタデータを取得する
   * @return ServicePermissionMasterResponse 認可マスタ取得API レスポンス
   */
  public static async getServicePermissionMasters(): Promise<ServicePermissionMaster[]> {
    try {
      const response = await api.get<ServicePermissionMaster[]>(`/master/permission`).catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });
      return response.data.map((master) => this.createServicePermissionMaster(master));
    } catch (error: any) {
      throw error;
    }
  } // getPermissionMasters

  /**
   * レスポンスから内部データに変換
   * @param master サービス別認可マスタデータ
   * @returns ServicePermissionMaster
   */
  private static createServicePermissionMaster(master: ServicePermissionMaster): ServicePermissionMaster {
    return new ServicePermissionMaster({
      _serviceId: master.serviceId,
      _planType: master.planType,
      _permission: master.permission,
    });
  }

  /**
   * 認可マスタデータを取得する
   * @return ItemPermissionMasterResponse アイテム別認可マスタ取得API レスポンス
   */
  public static async getItemPermissionMasters(): Promise<ItemPermissionMaster[]> {
    try {
      const response = await api.get<ItemPermissionMaster[]>(`/master/item-permission`).catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });
      return response.data.map((master) => this.createItemPermissionMaster(master));
    } catch (error: any) {
      throw error;
    }
  } // getPermissionItemMasters

  /**
   * レスポンスから内部データに変換
   * @param master アイテム別認可マスタデータ
   * @returns ServicePermissionMaster
   */
  private static createItemPermissionMaster(master: ItemPermissionMaster): ItemPermissionMaster {
    return new ItemPermissionMaster({
      _serviceId: master.serviceId,
      _planType: master.planType,
      _permission: master.permission,
      _serviceType: master.serviceType,
      _screenId: master.screenId,
      _itemId: master.itemId,
    });
  }
}
