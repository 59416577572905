export const UA_TYPE = {
  E_MANSION: '1',
  FIVE_A: '2',
  UCOM: '3'
} as const;

export const OEM_TYPE = {
  FIVE_A: '00',
  L_NET: '01',
  N_ASSET: '02'
} as const;

export const TRANSITION_SOURCE_SERVICE = {
  E_MANSION: '1',
  FIVE_A: '3',
  UCOM: '2',
} as const;

export const SERVICE_TYPE = TRANSITION_SOURCE_SERVICE;

export const PROPERTY_SEARCH_TYPE = {
  E_MANSION: 3,
  UCOM: 4,
  MCLOUD: 5
} as const;

export const ISP_TYPE_REGEXP = {
  ALL: /^[01234567]{1}$/,
  MCLOUD: /^[0367]{1}$/,
  E_MANSION_MCLOUD: /^[25]{1}$/,
  UCOM_MCLOUD: /^[14]{1}$/,
} as const;

export const SERVICE_NAME = {
  E_MANSION: 'e-mansion',
  FIVE_A: 'Five.A',
  UCOM: 'UCOM光 レジデンス'
} as const;

// ISPの会員ステータスを利用する関係で追加
export const ISP_MEMBER_STATUS = {
  // Five.A外部APIの問い合わせ結果を用いて定義されるISP(Five.A)会員ステータス
  FIVE_A: {
    MEMBER: '会員',
    IN_CANCEL_APPLICATION: '退会申し込み中',
    CANCEL_AND_LOGIN_OK: '退会済みログイン可能',
    CANCEL_AND_LOGIN_NG: '退会済みログイン不可'
  },
  UCOM: {
    MEMBER: '会員',
    IN_CANCEL_APPLICATION: '退会申し込み中',
    CANCEL_AND_LOGIN_OK: '退会済みログイン可能',
    CANCEL_AND_LOGIN_NG: '退会済みログイン不可'
  },
  E_MANSION: {
    MEMBER: '会員',
    IN_CANCEL_APPLICATION: '退会申し込み中',
    TRANSFER_AND_CANCEL: '移転退会',
    CANCEL_AND_LOGIN_OK: '退会済みログイン可能',
    CANCEL_AND_LOGIN_NG: '退会済みログイン不可'
  }
} as const;

export const TRANSITION_STORAGE = 'transition';
export const TRANSITION_COOKIE = 'transition';

export type TransitionSourceServiceType = typeof TRANSITION_SOURCE_SERVICE[keyof typeof TRANSITION_SOURCE_SERVICE];
export type UaTypeValue = typeof UA_TYPE[keyof typeof UA_TYPE];