import axios, { AxiosResponse } from 'axios';

import { Member } from '../../classes/spf-api/member';
import { Property, SearchPropertyDetail, SearchResultOver } from '../../classes/spf-api/property';
import { MemberStatus } from '../../classes/spf-api/member-status';
import { AuthService } from '../auth/auth-service';
import { Address } from '../../classes/spf-api/address';
import { Building } from '../../classes/spf-api/building';
import { BaseApiService } from './base-api-service';
import { LinkServiceWithUcomRequest, LinkServiceWithUcomResponse } from '@/shared/classes/spf-api/link-service-with-ucom';
import {
    LinkServiceWithEMansionRequest,
    LinkServiceWithEMansionResponse
} from '@/shared/classes/spf-api/link-service-with-e-mansion';
import { ContactsDto } from '@/pages/platform/contacts/classes/contacts-dto';
import {
    LinkServiceWithFiveARequest,
    LinkServiceWithFiveAResponse
} from '@/shared/classes/spf-api/link-service-with-five-a';
import { convert6DigitIdTo8DigitId } from '../../util/convert';
import vm from '../../../main';
import store from '../../../store/index'
import { ConnectixMaintenanceInfo } from '@/shared/classes/spf-api/connectix-maintenance';
import { UcomOAuthToken } from '@/shared/classes/ucom/ucom-oauth-token';
import { GetOemsMasterResponse } from '@/shared/classes/spf-api/oems';
import { FrontErrorRequest } from '@/shared/classes/spf-api/front-error-request';

const api = BaseApiService.api();
const rawApi = BaseApiService.rawApi();

/** 内部 API をコールするサービス */
export class SpfApiService {
  /**
   * mcloudとアカウント連携を行う
   * @param memberId
   * @param loginInformation
   */
  public static async createMcloudLinkService(memberId: number, loginInformation: { loginId: string, password: string }) {
        await api.post(`/auth0-management/mcloud/linking/${memberId}`, loginInformation).catch(async (error) => {
            await this.judgeError(error);
            throw error;
        });
    }

    /**
     * Connectixメンテナンス情報を取得する
     *
     * @return メンテナンス情報
     */
    public static async getConnectixMaintenance(maintenanceUa: string): Promise<ConnectixMaintenanceInfo> {
    const response = await rawApi.get<ConnectixMaintenanceInfo>(`/maintenance/${maintenanceUa}` ,{ params: { nocache: new Date().getTime() } }).catch(async (error) => { await this.judgeError(error); throw error; });
        return response.data;
    }

    /**
     * 会員情報を取得する
     *
     * @return 会員情報
     */
    public static async findMember(): Promise<Member> {
        const id = await AuthService.getMemberId();
    const response = await api.get<Member>(`/members/${id}`,{ params: { nocache: new Date().getTime() }}).catch(async (error) => { await this.judgeError(error); throw error; });
        return response.data;
    }

    /**
     * 会員情報を新規登録する
     *
     * @param member 会員情報
     * @return 作成された会員情報
     */
    public static async createMember(member: Omit<Member, 'id'>): Promise<Member> {
        const response = await api.post<Member>('/members', member).catch(async (error) => {
            await this.judgeError(error);
            throw error;
        });
        return response.data;
    }

    /**
     * 会員情報を削除する
     *
     * @param memberId 会員ID
     */
    public static async deleteMember(memberId: number): Promise<void> {
        await api.delete(`members/${memberId}`).catch(async (error) => {
            await this.judgeError(error);
            throw error;
        });
    }

    /**
     * 会員情報を更新する・正常レスポンスは 204 No Content
     *
     * @param id 会員 ID
     * @param member 会員情報
     */
    public static async updateMember(id: string, member: Omit<Member, 'id'>): Promise<void> {
        await api.patch<Member>(`/members/${id}`, member).catch(async (error) => {
            await this.judgeError(error);
            throw error;
        });
    }

    /**
     * 物件情報を1件取得する
     * @param id 物件 ID
     * @return 物件情報
     */
    public static async findPropertyById(id: number): Promise<Property> {
    const response = await api.get<Property>(`/properties/${id}`, { params: { nocache: new Date().getTime() } }).catch(async (error) => { await this.judgeError(error); throw error; });
        return response.data;
    }

    /**
     *  物件検索API
     * @param type 検索タイプ (1:物件名, 2:住所, 3:e-mansion_物件ID, 4:UCOM_物件ID, 5:Mcloud_物件ID)
     * @param key 検索キー
     * @return 物件情報[]
     */
    public static async searchProperties(type: number, key: string, isPerfectMatch: boolean): Promise<SearchPropertyDetail[] | SearchResultOver> {
        try {
      let data: { searchWord: string, searchType: number, exactMatch?: number, nocache: number };

            if (type === 1) {
        data = { searchWord: key, searchType: type, exactMatch: isPerfectMatch ? 1 : 0 , nocache: new Date().getTime() };
            } else {
        data = { searchWord: key, searchType: type , nocache: new Date().getTime()}
            }

            const response = await api.get<SearchPropertyDetail[] | SearchResultOver>('/search/search-properties', { params: data }).catch(async (error) => {
                await this.judgeError(error);
                throw error;
            });
            return response.data;
        } catch (e: any) {
            throw e;
        }
    }

    /**
     * 物件棟検索API
     * @param zipcode 郵便番号
     * @return 住所情報
     */
    public static async searchBuildings(apartmentId: string): Promise<Building[]> {
        try {
      const data = { apartmentId: apartmentId, nocache: new Date().getTime() };
      const response = await api.get<Building[]>('/search/search-buildings', { params: data }).catch(async (error) => { await this.judgeError(error); throw error; });
      return response.data;
    } catch (e: any) {
      throw e;
    }
  }

    /**
     * 住所検索API
     * @param zipcode 郵便番号
     * @return 住所情報
     */
    public static async searchAddresses(zipcode: string): Promise<Address[]> {
        try {
      const data = { zipcode: zipcode.split('-').join(''), nocache: new Date().getTime() };
      const response = await api.get<Address[]>('/search/search-addresses', { params: data }).catch(async (error) => { await this.judgeError(error); throw error; });
      return response.data;
    } catch (e: any) {
      throw e;
    }
  }

    /**
     * 会員ステータス情報を1件取得する
     * @param primaryKeyAuth0 PortasテナントのAuth0 ID
     * @return 会員ステータス情報
     */
    public static async findMemberStatusByPrimaryKeyAuth0(primaryKeyAuth0: string): Promise<MemberStatus> {
    const response = await api.get<MemberStatus>(`/member-statuses/${primaryKeyAuth0}`, { params: { nocache: new Date().getTime() } }).catch(async (error) => { await this.judgeError(error); throw error; });
    return response.data;
  }

    /**
     * 会員ステータスを登録する
     * @param memberStatus 会員ステータス
     * @return 作成された会員ステータス情報
     */
    public static async createMemberStatus(memberStatus: MemberStatus): Promise<MemberStatus> {
        const response = await api.post<MemberStatus>('/member-statuses', memberStatus).catch(async (error) => {
            await this.judgeError(error);
            throw error;
        });
        return response.data;
    }

    /**
     * 会員ステータスを更新する
     * @param memberStatus 会員ステータス
     * @param primaryKeyAuth0 PortasテナントのAuth0 ID
     */
    public static async updateMemberStatus(primaryKeyAuth0: string, memberStatus: MemberStatus): Promise<MemberStatus> {
        try {
            const response = await api.patch<MemberStatus>(`/member-statuses/${primaryKeyAuth0}`, memberStatus).catch(async (error) => {
                await this.judgeError(error);
                throw error;
            });
            return response.data;
        } catch (e: any) {
            throw e;
        }
    }

    /**
     * UCOMの同時お申し込みを行う
     * @param id 会員ID
     * @param linkServiceWithUcomRequest 同時お申し込み情報
     * @return 作成された会員ステータス情報
     */
    public static async linkServiceWithUcom(id: number, linkServiceWithUcomRequest: LinkServiceWithUcomRequest): Promise<LinkServiceWithUcomResponse> {
        const response = await api.post<LinkServiceWithUcomResponse>(`/auth0-management/ucom/link-service/${id}`, linkServiceWithUcomRequest).catch(async (error) => {
            await this.judgeError(error);
            throw error;
        });
        return response.data;
    }

    /**
     * e-mansionの同時お申し込みを行う
     * @param id 会員ID
     * @param linkServiceWithEMansionRequest 同時お申し込み情報
     * @return 作成された会員ステータス情報
     */
    public static async linkServiceWithEMansion(id: number, linkServiceWithEmansionRequest: LinkServiceWithEMansionRequest): Promise<LinkServiceWithEMansionResponse> {
        const response = await api.post<LinkServiceWithEMansionResponse>(`/auth0-management/e-mansion/link-service/${id}`, linkServiceWithEmansionRequest).catch(async (error) => {
            await this.judgeError(error);
            throw error;
        });
        return response.data;
    }

    /**
     * five-aの同時お申し込みを行う
     * @param id 会員ID
     * @param linkServiceWithFiveARequest 同時お申し込み情報
     * @return 作成された会員ステータス情報
     */
    public static async linkServiceWithFiveA(id: number, linkServiceWithFiveARequest: LinkServiceWithFiveARequest): Promise<LinkServiceWithFiveAResponse> {
        linkServiceWithFiveARequest.propertyId = convert6DigitIdTo8DigitId(linkServiceWithFiveARequest.propertyId);
        const response = await api.post<LinkServiceWithFiveAResponse>(`/auth0-management/five-a/link-service/${id}`, linkServiceWithFiveARequest).catch(async (error) => {
            await this.judgeError(error);
            throw error;
        });
        return response.data;
    }

    /**
     * お問い合わせ
     */
    public static async contacts(contactsDto: ContactsDto): Promise<AxiosResponse<string>> {
        if (contactsDto.isLogin) {
            // ログイン済み
            return await api.post<string>('/contacts', contactsDto).catch(async (error) => {
                await this.judgeError(error);
                throw error;
            });
        } else {
            // 未ログイン
            // Auth0認証をしないのでトークン未設定
            return await rawApi.post<string>('/contacts/not-login', contactsDto).catch(async (error) => {
                await this.judgeError(error);
                throw error;
            });
        }
    }

    /**
     * 確認メール送信API
     * @param emailAddress メールアドレス
     * @return
     */
    public static async createToken(emailAddress: string): Promise<void> {
        try {
            const response = await api.post(`/token/create`, { emailAddress: emailAddress }).catch(async (error) => {
                await this.judgeError(error);
                throw error;
            });
            if (!response.status || !(response.status >= 200) || !(response.status <= 299)) {
                throw new Error();
            }
            return;
        } catch (error: any) {
            throw error;
        }
    }

    /**
     * UCOMのAuthToken取得
     * @param primaryKeyAuth0 PortasテナントのAuth0 ID
     * @return 会員ステータス情報
     */
    public static async getUcomAuthToken(): Promise<UcomOAuthToken> {
    const response = await api.get<UcomOAuthToken>(`/auth0-management/ucom/auth-token`, { params: { nocache: new Date().getTime() } }).catch(async (error) => { await this.judgeError(error); throw error; });
        response.data.dateTime = new Date(response.data.dateTime);// Dateの送受信がうまくいかないため変換
        return response.data;
    }

    /**
     * OEMマスタ一覧取得API
     * @return OEMマスタ
     */
    public static async getOemsMaster(): Promise<GetOemsMasterResponse[]> {
    const response = await rawApi.get<GetOemsMasterResponse[]>(`/oems`, { params: { nocache: new Date().getTime() } }).catch(async (error) => { await this.judgeError(error); throw error; });
    return response.data;
  }

  /**
   * フロントエンドエラーロギングAPI
   *
   * @param frontErrorInfo エラー情報
   */
  public static async postReportFrontError(frontErrorInfo: FrontErrorRequest): Promise<void> {
    await rawApi.post(`/front-error`, frontErrorInfo, { params: { nocache: new Date().getTime() } }).catch(async (error) => { await this.judgeError(error); throw error; });
    }

    private static async judgeError(error: any) {
        if (error.response?.status === 503 && error.response.data) {
            store.dispatch('maintenanceStore/maintenance', error.response.data);
            await vm.$router.push('/maintenance');
        }
    }


}
