










































































































































































import Vue from 'vue';
import { PlatformEntryInputForm } from '@/shared/classes/platform/entry-input-form';
import { Address } from '@/shared/classes/spf-api/address';
import { Property } from '@/shared/classes/spf-api/property';
import { required, numeric, minLength, maxLength } from 'vuelidate/lib/validators';
import { emailAddressMaxLength, emailAddressIllegalChar, emailAddressIllegalCharMark, fullKanaAndBlank, fullWidthNoNum, blank } from '@/shared/util/validators';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import InputProperty from '@/shared/components/platform/input-property.vue';
import { BuildingInfoStatus, BUILDING_INFO_STATUS, EntryProperty, InputPropertyForm } from '../../../store/platform/platform-edit-store';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { ISP_TYPE_REGEXP, UA_TYPE } from '@/shared/const/service-type';
import { validateRoomNumber, checkIsMatchRoom } from '@/shared/util/input-property-func';
import { EMansionRoomsDetail } from '@/shared/classes/external-api/e-mansion/rooms-response';
import parsePhoneNumber from 'libphonenumber-js';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { isValidMonthAndDatePairs } from '@/shared/util/func-is-valid-month-and-date-pairs';

/** dataオブジェクトの型  */
type DataType = {
  form: PlatformEntryInputForm; // 会員情報入力フォーム（※様々な改変を経て、現在はバリデーションで使用されているくらい）
  property: Property | null; // 遷移元サービスで選択した物件
  isPropertyRegistered: boolean; // お住まい選択済みで遷移（※使用されていない）
  isReEnter: boolean; // 入力確認画面から戻ってきた場合
  isAfterResister: boolean;
  isLoading: boolean; // 読み込み状態
  isMainLoading: boolean; // 読み込み状態
  searchKey: string; // 名称検索フォーム
  zipcode: string; // 郵便番号検索フォーム
  propertyName: string; // 物件名検索フォーム
  addresses: Address[]; // 住所一覧
  selectedAddress: string; // 選択した住所
  propertyInfoList: Property[]; //物件一覧
  errorMessages: string[];
  errorMessageTitle: string;
  searchErrorMessages: string[];
  searchErrorMessageTitle: string;
  selectRoomNumberList: string[];
};

/** Input コンポーネント */
export default Vue.extend({
  name: 'platform-entry-input',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
    InputProperty
  },
  data(): DataType {
    return {
      property: null,
      isPropertyRegistered: false, /** お住まい選択済みで遷移 */
      isReEnter: false, /** 入力確認画面から戻ってきた場合 */
      isAfterResister: false,
      isLoading: true, // 最初はロード中 ロード解除はコンポーネントの方で行う
      isMainLoading: true, // 読み込み状態
      form: {
        firstName: '',
        lastName: '',
        firstNameKana: '',
        lastNameKana: '',
        mailAddress: '',
        phoneNumber1: '',
        phoneNumber2: '',
        phoneNumber3: '',
        birthYear: '',
        birthMonth: '',
        birthDay: '',
      },
      searchKey: '',
      zipcode: '',
      propertyName: '',
      addresses: [],
      selectedAddress: '',
      propertyInfoList: [],
      errorMessages: [],
      errorMessageTitle: '入力項目にエラーがあります。ご確認をお願いいたします。',
      searchErrorMessages: [],
      searchErrorMessageTitle: '',
      selectRoomNumberList: [],
    };
  },
  validations: {
    form: {
      // 姓 + '空白' + 名 が 30文字以内
      firstName: { required, fullWidthNoNum, maxLength: maxLength(28), blank },
      lastName: { required, fullWidthNoNum, maxLength: maxLength(28), blank },
      // 姓 カタカナ + '空白' + 名 カタカナ が 30文字以内
      firstNameKana: { required, maxLength: maxLength(28), fullKanaAndBlank },
      lastNameKana: { required, maxLength: maxLength(28), fullKanaAndBlank },
      mailAddress: { required, emailAddressMaxLength, emailAddressIllegalChar, emailAddressIllegalCharMark, },
      phoneNumber1: { required, numeric },
      phoneNumber2: { required, numeric },
      phoneNumber3: { required, numeric },
      birthYear: { required, numeric, minLength: minLength(4), maxLength: maxLength(4) },
      birthMonth: { required },
      birthDay: { required }
    }
  },
  async mounted() {
    window.scrollTo(0, 0);

    /** ストアに入力内容が保存してあれば復元する */
    const form = this.$store.getters['platformEntryStore/entryInputForm'];
    const inputPropertyForm: InputPropertyForm | null = this.$store.getters['platformEditStore/inputPropertyForm'];
    const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
    form.mailAddress = memberStatus.emailAddress;
    if (inputPropertyForm) {
      this.isAfterResister = inputPropertyForm.isAfterResister;
    }
    if (form) {
      this.form = form;
      if(form.errorMessages && form.errorMessages.length){
        this.errorMessages = form.errorMessages;
      }
    }

    this.$data.isMainLoading = false;
  },
  methods: {
    /**
     * 入力項目を表示する
     */
    getItem(itemName: string):string {
      return this.$store.getters['platformEntryStore/entryInputFormItem'](itemName);
    },
    /**
     * 入力内容をストアに反映する
     */
    updateItem($event: any, itemName: string): void {
      this.$store.commit('platformEntryStore/updateFormItem', {value: $event.target.value, itemName: itemName});
    },
    /** フォームのバリデーション */

    validate(): boolean {
      this.form = this.$store.getters['platformEntryStore/entryInputForm'];

      if (!this.$v.form.firstName?.required || !this.$v.form.lastName?.required) {
        this.errorMessages.push('お名前を入力してください。');
      } else if (!this.$v.form.firstName?.fullWidthNoNum || !this.$v.form.lastName?.fullWidthNoNum) {
        this.errorMessages.push('お名前の形式が正しくありません。');
      } else if (this.$v.form.firstName?.blank || this.$v.form.lastName?.blank) {
        this.errorMessages.push('お名前に空白を含めないようにしてください。');
      }
      if (this.isOver31Characters()) {
        this.errorMessages.push('お名前は姓と名合わせて全角29文字以内で入力してください。');
      }
      if (!this.$v.form.firstNameKana?.required || !this.$v.form.lastNameKana?.required) {
        this.errorMessages.push('フリガナを入力してください。');
      } else if (!this.$v.form.firstNameKana?.fullKanaAndBlank || !this.$v.form.lastNameKana?.fullKanaAndBlank) {
        this.errorMessages.push('フリガナの形式が正しくありません。');
      }
      if (this.isOver31CharactersKatakana()) {
        this.errorMessages.push('フリガナはセイとメイ合わせて全角29文字以内で入力してください。');
      }
      if (!this.$v.form.mailAddress?.required) {
        this.errorMessages.push('メールアドレスを入力してください。');
      } else if (!this.$v.form.mailAddress?.emailAddressMaxLength) {
          this.errorMessages.push('メールアドレスは48文字以内で入力してください。');
      } else if (!this.$v.form.mailAddress?.emailAddressIllegalChar) {
        this.errorMessages.push('メールアドレスの形式が正しくありません。');
      } else if (!this.$v.form.mailAddress?.emailAddressIllegalCharMark) {
        this.errorMessages.push('メールアドレスの形式が正しくありません。');
      }
      if (!this.$v.form.phoneNumber1?.required || !this.$v.form.phoneNumber2?.required || !this.$v.form.phoneNumber3?.required) {
        this.errorMessages.push('電話番号を入力してください。');
      } else if (!this.$v.form.phoneNumber1?.numeric || !this.$v.form.phoneNumber2?.numeric || !this.$v.form.phoneNumber3?.numeric) {
        this.errorMessages.push('電話番号の形式が正しくありません。');
      } else {
        const phoneNumber = `${this.form.phoneNumber1}-${this.form.phoneNumber2}-${this.form.phoneNumber3}`;
        if (!parsePhoneNumber(phoneNumber, 'JP')?.isValid()) {
          this.errorMessages.push('電話番号の形式が正しくありません。');
        }
      }
      if (!this.$v.form.birthYear?.required || !this.$v.form.birthMonth?.required || !this.$v.form.birthDay?.required) {
        this.errorMessages.push('生年月日を入力してください。');
      } else if (!this.$v.form.birthYear?.minLength || !this.$v.form.birthYear?.maxLength || !this.$v.form.birthYear?.numeric) {
        this.errorMessages.push('生年月日の形式が正しくありません。');
      } else if (!isValidMonthAndDatePairs(Number(this.form.birthYear), Number(this.form.birthMonth), Number(this.form.birthDay))) {
        this.errorMessages.push('生年月日の形式が正しくありません。');
      } else {
        const birthDate = new Date(this.form.birthYear + '-' + this.form.birthMonth + '-' + this.form.birthDay);
        if (birthDate > new Date()) {
          this.errorMessages.push('生年月日の形式が正しくありません。');
        }
      }

      if (this.errorMessages.length > 0) {
        return false;
      }
      return true;
    },
    /** 次へボタン押下時： フォームの値をストアに保存し入力確認画面に遷移する(お住まいを登録した場合) */
    onNext() {
      this.errorMessages = [];
      this.validate();
      // ストアに更新を反映する
      this.form = this.$store.getters['platformEntryStore/entryInputForm']; // this.form は基本的に空なので、ここで値を入れてバリデーションに使用している
      const inputPropertyForm: InputPropertyForm | null = this.$store.getters['platformEditStore/inputPropertyForm'];
      if (!inputPropertyForm?.selectedProperty) {
        this.errorMessages.push('お住まいを選択してください。');
      } else {
        // UA_TYPE・ISP_TYPEで分ける
        const uaType = inputPropertyForm?.selectedProperty?.uaType;
        const ispType = inputPropertyForm?.selectedProperty?.ispType;
        /**部屋番号バリデーションはe-mansion, ucom, Mcloud共通 */
        this.errorMessages.push(
          ...validateRoomNumber(inputPropertyForm.inputBuildingData.roomNumber, uaType, ispType)
        );

        if (uaType === UA_TYPE.E_MANSION) {
          /**Mcloud物件の場合 */
          if (typeof ispType === 'string' && ISP_TYPE_REGEXP.MCLOUD.test(ispType)) {
            // 棟情報を持った物件かどうかをストアから取得する
            const isIncludesBuildingsInfo: boolean = this.$store.getters['platformEditStore/isIncludesBuildingsInfo'];
            if (isIncludesBuildingsInfo && !inputPropertyForm.inputBuildingData.buildingName) {
              this.errorMessages.push('棟名が選択されていません。');
            }
          /**e-mansionの場合 */
          } else {
            if (inputPropertyForm?.inputBuildingData) {
              const rooms: { id: string; buildingInfoStatus: BuildingInfoStatus; rooms: EMansionRoomsDetail[] } | undefined = inputPropertyForm.roomsData.find((room) => {
                return room.id === String(inputPropertyForm.selectedProperty?.id); // e-mansion 特殊物件の場合でも「検索当初の portas 物件 id == room.id」となるため問題はない
              });
              const buildingInfoStatus = rooms?.buildingInfoStatus ?? '';
              // 棟情報を持った物件かどうかをストアから取得する
              const isIncludesBuildingsInfo: boolean = this.$store.getters['platformEditStore/isIncludesBuildingsInfo'];
              // 棟情報を持った物件である場合
              if (isIncludesBuildingsInfo) {
                if (
                  buildingInfoStatus === BUILDING_INFO_STATUS.BOTH ||
                  buildingInfoStatus === BUILDING_INFO_STATUS.BUILDING_ONLY
                ) {
                  if (!inputPropertyForm.inputBuildingData.buildingName) {
                    this.errorMessages.push('棟名が選択されていません。');
                  } else {
                    /**入力した棟名と部屋番号が一致する部屋情報が含まれているかどうかのチェック */
                    // 条件を満たす部屋情報を取得
                    // 建物選択後の検索であるが検索結果全ての建物を対象としたチェックとなっている（const rooms を送る形式で新たな checkIsMatchRoom を作成するかどうか）
                    const isMatch = checkIsMatchRoom(
                      buildingInfoStatus,
                      inputPropertyForm.roomsData,
                      inputPropertyForm.inputBuildingData
                    );
                    if (!isMatch) {
                      this.errorMessages.push(
                        `正しい棟名と部屋番号を入力してください。`
                      );
                      window.scrollTo(0, 0);
                      return;
                    }
                  }
                }
              } else {
                // 棟情報を持たない物件である場合
                if (buildingInfoStatus === BUILDING_INFO_STATUS.ROOM_ONLY) {
                  /**入力した部屋番号が一致する部屋情報が含まれているかどうかのチェック */
                  // 条件を満たす部屋情報を取得
                  // 建物選択後の検索であるが検索結果全ての建物を対象としたチェックとなっている（const rooms を送る形式で新たな checkIsMatchRoom を作成するかどうか）
                  const isMatch = checkIsMatchRoom(
                    buildingInfoStatus,
                    inputPropertyForm.roomsData,
                    inputPropertyForm.inputBuildingData
                  );
                  if (!isMatch) {
                    this.errorMessages.push(
                      `正しい部屋番号を入力してください。`
                    );
                    window.scrollTo(0, 0);
                    return;
                  }
                }
              }
            }
          }
        }
      }

      if (this.errorMessages.length > 0) {
        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
        return;
      }

      if (this.errorMessages.length === 0) {
        // inputPropertyForm.selectedProperty が存在しない場合は、エラーメッセージ表示でここまでたどりつけない
        if (inputPropertyForm?.selectedProperty) {
          const entryProperty: EntryProperty = {
            propertyId: inputPropertyForm.eMansionSpecialPropertyId ? inputPropertyForm.eMansionSpecialPropertyId : inputPropertyForm.selectedProperty.id,
            apartmentName: inputPropertyForm.eMansionSpecialPropertyName
              ? `${inputPropertyForm.eMansionSpecialPropertyName} ${inputPropertyForm.inputBuildingData.buildingName}`
              : `${inputPropertyForm.selectedProperty.apartmentName} ${inputPropertyForm.inputBuildingData.buildingName}`,
            dispApartmentName: inputPropertyForm.eMansionSpecialPropertyName
              ? inputPropertyForm.eMansionSpecialPropertyName
              : inputPropertyForm.selectedProperty.dispApartmentName,
            zipcode: inputPropertyForm.selectedProperty.zipcode,
            address: inputPropertyForm.selectedProperty.address,
            roomNumber: inputPropertyForm.inputBuildingData.roomNumber,
            buildingId: inputPropertyForm.inputBuildingData.buildingId
          };
          this.$store.commit('platformEditStore/inputPropertyForm', inputPropertyForm); // 実装上、既に同じデータが platformEditStore/inputPropertyForm に入っている
          this.$store.commit('platformEditStore/entryProperty', entryProperty);
        }
        this.$router.push('/platform/entry/confirm').catch(error => {checkRouterError(error)});
      }
    },
    /** 次へボタン押下時： フォームの値をストアに保存し入力確認画面に遷移する(お住まいを登録しない場合) */
    onNextWithoutProperty() {
      this.errorMessages = [];
      if (this.validate()) {
        this.$store.commit('platformEntryStore/entryInputForm', this.form);
        this.$store.commit('platformEditStore/property', null);
        this.$store.commit('platformEditStore/entryProperty', null);
        this.$store.commit('platformEditStore/inputPropertyForm', null);
        this.$router.push('/platform/entry/confirm').catch(error => {checkRouterError(error)});
      } else {
        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
        return;
      }
    },
    changeIsAfterRegister(value: boolean) {
      this.isAfterResister = value;
    },
    changeIsReEnter(value: boolean) {
      this.isReEnter = value;
    },
    changeIsLoading(value: boolean) {
      this.isLoading = value;
    },
    isOver31Characters(): boolean {
      const array = [
        this.form.firstName,
        '　',
        this.form.lastName
      ];
      return array.join('').length >= 31;
    },
    isOver31CharactersKatakana(): boolean {
      const array = [
        this.form.firstNameKana,
        '　',
        this.form.lastNameKana
      ];
      return array.join('').length >= 31;
    }
  }
});
