import { UaTypeValue } from '@/shared/const/service-type';

export class FindCustomerByIdQueryDto {
  public ua_type!: UaTypeValue;
  public apartment_id!: string;

  constructor(partial: Partial<FindCustomerByIdQueryDto>) {
    Object.assign(this, partial);
  }
}
