import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Tokyo');

/**
 * システム日付を返す
 * タイムゾーン アジア / 東京
 *
 * @param format YYYY/MM/DDなどのフォーマット
 * @return フォーマットされたシステム日付を返す
 */
export function processDate(format?: string): string {
  const yyyymmdd = format ? format : 'YYYY-MM-DD';
  return dayjs(new Date()).tz().format(yyyymmdd);
}
