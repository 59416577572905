/** エラー情報 */
export const FRONT_ERROR_INFO_DATA_INCONSISTENCT = {
  E_MANSION: {
    NO_CUSTOMER_INFO: 'e-mansion契約基本情報がありません。（想定外遷移）',
    CUSTOMER_INFO_API: 'e-mansion契約基本情報のデータ整合性エラーです。',
    NO_PROPERTY_INFO: 'e-mansion物件基本情報がありません。（想定外遷移）',
  },
  UCOM: {
    NO_BUILDING_ID: 'UCOM物件情報にBuilding_IDが設定されていません。（PortasDB不整合エラー）',
  },
  DATA_INCONSISTENT: 'データ不整合です。各種データを確認してください。',
  NO_PROPERTY: '物件情報がありません。',
  LINKED_PROPERTY_INCONSISTENT: '紐づけられた物件情報が不正です。',
  PROPERTY_NO_UA_TYPE: 'UA_TYPEがありません。',
  PROPERTY_NO_OEM_TYPE: 'OEM_TYPEがありません。',
  PROPERTY_NO_ROOM: '部屋番号情報がありません。',
  NO_MEMBER: '会員情報がありません。',
  NO_INPUT_DATA: '入力情報がありません。',
  AUTH0: 'Auth0の情報が不正です。',
  TRANSITION_SOURCE_INCONSISTENT: '会員ステータスが不正です。対象のTRANSITION_SOURCEを確認してください。',
  MEMBER_STATUS: '会員ステータスが不正です。対象のSTATUSを確認してください。',
  INPUT_PROPERTY_NO_RESULT: '会員情報入力画面にて検索エラーが発生しました。データを確認してください。',
} as const;

/** エラー情報 */
export const FRONT_ERROR_INFO_API_FRONT_ERROR = {
  E_MANSION: {
    CONTRACTOR_INFO: 'e-mansion外部API、契約基本情報APIエラー',
    CONTRACTOR_INFO_DATA_INCONSISTENCT: 'e-mansion外部API、契約基本情報API データ不整合',
    PROPERTY_INFO: 'e-mansion外部API、物件基本情報APIエラー',
    ROOM_INFO: 'e-mansion外部API、部屋番号一覧APIエラー',
    CONNECTIX: 'e-mansion外部API、Connectix申し込みAPIエラー',
  },
  FIVE_A: {
    CONTRACTOR_INFO: 'Five.A(e-mansion)外部API、契約基本情報APIエラー',
    CONTRACTOR_INFO_DATA_INCONSISTENCT: 'Five.A(e-mansion)外部API、契約基本情報API データ不整合',
    PROPERTY_INFO: 'Five.A(e-mansion)外部API、物件基本情報APIエラー',
    ROOM_INFO: 'Five.A(e-mansion)外部API、部屋番号一覧APIエラー',
    CONNECTIX: 'Five.A(e-mansion)外部API、Connectix申し込みAPIエラー',
  },
  UCOM: {
    CONTRACTOR_INFO: 'UCOM外部API、契約基本情報APIエラー',
    CONTRACTOR_INFO_DATA_INCONSISTENCT: 'UCOM外部API、契約基本情報API データ不整合',
    PROPERTY_INFO: 'UCOM外部API、物件基本情報APIエラー',
    E_MANSION_API_PROPERTY_INFO: 'UCOMが利用するe-mansion外部API、物件基本情報APIエラー',
    ROOM_INFO: 'UCOM外部API、部屋番号一覧APIエラー',
    CONNECTIX: 'UCOM外部API、Connectix申し込みAPIエラー',
  },
  PORTAS: {
    CAN_NOT_CREATE_MEMBER: '会員情報が作成できませんでした。',
    CAN_NOT_CREATE_MEMBER_STATUS: '会員ステータス情報が作成できませんでした。',
    OVER_SEARCH_PROPERTIES: '物件検索結果が多すぎます。',
  },
  AUTH0_PORTAS: {
    E_MANSION_ACCOUNT_LINKING: 'e-mansionアカウント連携に失敗しました。',
    UCOM_ACCOUNT_LINKING: 'UCOMアカウント連携に失敗しました。',
  }
} as const;

export const REVISION_UP_ERROR = {
  CANCEL: 'リビジョンが上がったためキャンセルされました。'
} as const;