export const E_MANSION_ACCOUNT_SUB_DOMAIN = {
    MAIL_SUB_DOMAIN: 'ea',
    HOME_PAGE_SUBDOMAIN: 'zk',
    ACCOUNT_SUB_DOMAIN: 'ec'
} as const;
export type E_MANSION_ACCOUNT_SUB_DOMAIN = typeof E_MANSION_ACCOUNT_SUB_DOMAIN[keyof typeof E_MANSION_ACCOUNT_SUB_DOMAIN];

export const convertEMansionAccountSubDomain = (value: string): E_MANSION_ACCOUNT_SUB_DOMAIN | undefined => {
    for (const v of Object.values(E_MANSION_ACCOUNT_SUB_DOMAIN)) {
        if (String(v) == value) {
            return v;
        }
    }
}