





























































































import Vue from 'vue';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { Member } from '@/shared/classes/spf-api/member';
import { Property } from '@/shared/classes/spf-api/property';
import { ISP_TYPE_REGEXP, OEM_TYPE, UaTypeValue, UA_TYPE } from '@/shared/const/service-type';
import { EMansionSharedExternalApiService } from '@/shared/services/external-api/e-mansion-shared-external-api-service';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { MEMBER_STATUS } from '@/shared/const/member-status';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';

type DataType = {
  memberStatus: MemberStatus | null;
  member: Member | null;
  property: Property | null;
  isRouting: boolean; // onNextをクリックした後、router.pushが行われるまで用
  isMounting: boolean;
  isPropertyUaTypeEmansion: boolean;
  isPropertyUaTypeUcom: boolean;
  isPropertyUaTypeFiveA: boolean;
};

/** 新規会員登録完了 コンポーネント */
export default Vue.extend({
  name: 'platform-entry-completed',
  components: {
    LoadingComponent
  },
  data(): DataType {
    return {
      memberStatus: null,
      member: null,
      property: null,
      isRouting: false,
      isMounting: false,
      isPropertyUaTypeEmansion: false,
      isPropertyUaTypeUcom: false,
      isPropertyUaTypeFiveA: false
    };
  },
  async mounted() {
    try {
      this.$data.isMounting = true;
      // メンバーの取得が出来なかった時はエラー画面に遷移
      this.memberStatus = this.$store.getters['memberStore/memberStatus'];

      // 新規会員登録が実行されるタイミングは次の通り
      // | ISP       | Portas新規会員登録が実行されるタイミング                         |
      // | e-mansion | Portas新規会員登録のお申し込み受付メール内のリンクをクリックしたとき |
      // | ucom      | Portas新規会員登録のお申し込み受付メール内のリンクをクリックしたとき |
      // | Five.A    | /platfrom/entry/confirm の新規会員登録ボタンをクリックしたとき     |

      // routerのbeforeEachで会員情報取得される
      this.member = await this.$store.getters['memberStore/member'];

      if (!this.member || !this.memberStatus) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      }

      if (this.member.propertyId) {
        this.property = this.$store.getters['propertyStore/property'];
      }

      if (this.property?.uaType === UA_TYPE.E_MANSION) {
        this.isPropertyUaTypeEmansion = true;
      } else if (this.property?.uaType === UA_TYPE.UCOM) {
        this.isPropertyUaTypeUcom = true;
      } else if (this.property?.uaType === UA_TYPE.FIVE_A) {
        this.isPropertyUaTypeFiveA = true;
      }

      this.$data.isMounting = false;

    } catch (error) {
      throw error;
    }
  },
  methods: {
    async onNext() {
      // onNext()が複数回実行されないようにする
      if (this.isRouting) {
        return;
      }

      this.isRouting = true;
      try {
        /** 物件が取得できなかった場合（≒ お住まいを登録しなかった場合） */
        if (!this.property) {
          await this.$router.push('/platform').catch(error => {checkRouterError(error)});
          return;
        }
        /** UCOM 自動紐づけの場合 */
        if (this.member?.primaryKeyUcom) {
          await this.$router.push('/ucom').catch(error => {checkRouterError(error)});
          return;
        }
        /** e-mansion 自動紐づけの場合 */
        if (this.member?.primaryKeyMye) {
          await this.$router.push('/e-mansion').catch(error => {checkRouterError(error)});
          return;
        }
        /** ※現在は生じえない（mCloud 自動紐づけの場合） */
        if (this.member?.primaryKeyMcloud) {
          await this.$router.push('/mcloud').catch(error => {checkRouterError(error)});
          return;
        }
        /**お住まいを登録しなかった場合ログイン後トップに遷移する */
        if (this.memberStatus?.status === MEMBER_STATUS.ACCOUNT_REGISTERED_NO_PROPERTY) {
          await this.$router.push('/platform').catch(error => {checkRouterError(error)});
          return;
        } else if (this.memberStatus?.status === MEMBER_STATUS.PROPERTY_LINKED || this.memberStatus?.status === MEMBER_STATUS.ACCOUNT_REGISTERED) {
          // なぜかmethodsに定義した関数をthisから読み込んでくれないので条件ベタ書き
          const uaType: UaTypeValue | undefined = this.property.uaType;
          if (uaType === UA_TYPE.E_MANSION) {
            /**登録した物件がe-mansion対象物件の場合（手動紐づけへ） */
            await this.$router.push('/e-mansion/linking').catch(error => {checkRouterError(error)});
            return;
          }
          /**登録した物件がucom対象物件の場合（手動紐づけへ） */
          if (uaType === UA_TYPE.UCOM) {
            await this.$router.push('/ucom/linking').catch(error => {checkRouterError(error)});
            return;
          }
          // 登録した物件が「Five.A」/「L-net（エルネット）Powerd By つなぐ」/「エヌアセット光」対象物件の場合
          if (
            uaType === UA_TYPE.FIVE_A
          ) {
            await this.$router.push('/5a/link-service').catch(error => {checkRouterError(error)});
            return;
          }
          await this.$router.push('/platform').catch(error => {checkRouterError(error)});
          return;
        /**会員ステータスが3, 4, 5 以外 */
        } else {
          new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.MEMBER_STATUS);
        }
      } catch(error) {
        throw error;
      } finally {
        this.isRouting = false;
      }
    }
  }
});
