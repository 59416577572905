import { Module } from 'vuex';
import { RootState } from '../index';

/** サービス five-a Connectix 外部 API をコールするサービス */
import { FiveASharedExternalApiService } from '../../shared/services/external-api/five-a-shared-external-api-service';
import { FiveACustomer } from '../../shared/classes/external-api/five-a/customer-response';
import { FiveAProperty } from '../../shared/classes/external-api/five-a/property-response';
import { FiveASharedErrorResponse } from '../../shared/classes/external-api/five-a/shared-error-response';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/five-a/customer-dto';
import { FiveARoomsResponseResult } from '@/shared/classes/external-api/five-a/rooms-response';
import { UaTypeValue } from '@/shared/const/service-type';
/** five-a 共通系 State */
const fiveACommonState: {
  customer: FiveACustomer | FiveASharedErrorResponse | null;
  property: FiveAProperty | FiveASharedErrorResponse | null;
  rooms: FiveARoomsResponseResult | FiveASharedErrorResponse | null;
} = {
  /** five-a 契約基本情報 */
  customer: null,
  /** five-a 物件基本情報 */
  property: null,
  /** 部屋番号一覧 */
  rooms: null
};

/** State の型 */
type FiveACommonState = typeof fiveACommonState;

/** five-a 共通系 Store */
export const fiveACommonStore: Module<FiveACommonState, RootState> = {
  namespaced: true,
  state: fiveACommonState,
  actions: {
    /**
     * five-a 契約基本情報取得 action
     * @param context
     * @param memberId string 会員 ID
     * @param ua_type UA種別 1:five-a,2:Five.A,3:UCOM光R
     * @param property_id 物件ID
     * @return EmansionCustomer 契約基本情報
     * @return EmansionSharedErrorResponse 共通エラークラス
     */
    async customer(context, params: { memberId: string, query: FindCustomerByIdQueryDto }) {
      // API コールする
      const customerValue: FiveASharedErrorResponse | FiveACustomer = await FiveASharedExternalApiService.findCustomerById(params.memberId, params.query);
      // 値をコミットする
      context.commit('customer', customerValue);
      // Getter から取り直して返す
      const afterCustomerValue = context.getters['customer'];
      return afterCustomerValue;
    },
    /**
     * five-a 物件基本情報取得 action
     * @param context
     * @param propertyId string 物件 ID
     * @param uaType クエリパラメータ
     * @return EmansionProperty 物件基本情報
     * @return EmansionSharedErrorResponse 共通エラークラス
     */
    async property(context, params: { propertyId: string, uaType: UaTypeValue }) {
      // API コールする
      const propertyValue: FiveAProperty | FiveASharedErrorResponse = await FiveASharedExternalApiService.findPropertyById(params);
      // 値をコミットする
      context.commit('property', propertyValue);
      // Getter から取り直して返す
      const afterPropertyValue = context.getters['property'];
      return afterPropertyValue;
    },
    /**
     * five-a 部屋番号一覧取得 action
     * @param context
     * @param ua_type UA種別 1:e-mansion,2:Five.A,3:UCOM光R
     * @param property_id 物件ID
     * @return FiveARoomsResponseResult 部屋番号一覧
     * @return FiveASharedErrorResponse 共通エラークラス
     */
    async rooms(context, params: { uaType: UaTypeValue, propertyId: string }) {
      // API コールする
      const roomsValue: FiveARoomsResponseResult | FiveASharedErrorResponse = await FiveASharedExternalApiService.findRooms(params.uaType, params.propertyId);
      // 値をコミットする
      context.commit('rooms', roomsValue);
      // Getter から取り直して返す
      const afterRoomsValue = context.getters['rooms'];
      return afterRoomsValue;
    }
  },
  mutations: {
    customer: (state, value: FiveACustomer) => (state.customer = value),
    property: (state, value: FiveAProperty) => (state.property = value),
    rooms: (state, value: FiveARoomsResponseResult) => (state.rooms = value)
  },
  getters: {
    customer: (state): FiveACustomer | FiveASharedErrorResponse | null => state.customer,
    property: (state): FiveAProperty | FiveASharedErrorResponse | null => state.property,
    rooms: (state): FiveARoomsResponseResult | FiveASharedErrorResponse | null => state.rooms
  }
};
