








































































import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import { SpfApiHomepageAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-homepage-accessor';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { EMansionHomepageCancelUpdateRequest } from '@/infra/request/spf/e-mansion/homepage/e-mansion-homepage-cancel-update-request';

export default Vue.extend({
  name: 'homepage-cancel-confirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },

  data: () => ({
    isLoading: true,
    errorMessages: undefined,
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、内容をもう一度ご確認ください。',
    /** e-mansion ホームページID */
    eMansionHomepageId: undefined,
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      this.$data.isLoading = true;

      this.$data.eMansionHomepageId = await this.$store.getters['eMansionHomepageOpenStore/eMansionHomepageId'];

      // storeにデータが無ければ、利用状況/解約画面に遷移する
      if (!this.$data.eMansionHomepageId) {
        await this.$router.push('/e-mansion/homepage/usage/');
      }
      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error');
      return;
    }
  },
  methods: {
    async goBack() {
      if (this.isLoading) {
        return;
      }
      await this.$router.push('/e-mansion/homepage/usage');
      this.isLoading = true;
    },
    async goNext() {
      if (this.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      try {
        const eMansionAccountInfo = this.$store.getters['eMansionAccountStore/eMansionAccountInfo']; // e-mansion アカウント情報取得
        const eMansionHomepageId = this.$store.getters['eMansionHomepageOpenStore/eMansionHomepageId']; // e-mansion ホームページID
        const now = await SpfApiServerCommonUtilAccessor.getServerTimeNow(); // 現在日付

        // AE05-005 ホームページ：ご解約確認_更新APIの呼び出し
        const response = await SpfApiHomepageAccessor.callHomepageCancelUpdate(
          new EMansionHomepageCancelUpdateRequest({
            _eMansionAccountId: eMansionAccountInfo.eMansionAccountInfo.eMansionAccountId,
            _eMansionHomepageId: eMansionHomepageId,
            _processingDate: now,
          })
        );

        // エラーメッセージがあれば画面に表示する
        if (response.errorMessages.length > 0) {
          this.$data.errorMessages = response.errorMessages;
          window.scrollTo(0, 0);
          this.$data.isLoading = false;
          return;
        }

        // サービス終了日を設定
        this.$store.commit('eMansionHomepageOpenStore/serviceEndDate', response.serviceEndDate);

        // ホームページ：ご解約完了画面に遷移する
        await this.$router.push('/e-mansion/homepage/cancel-complete').catch((error) => {
          checkRouterError(error);
        });
        this.$data.isLoading = false;
      } catch (error) {
        if (error.response?.status !== 503) {
          await this.$router.push('/e-mansion/error');
          return;
        }
      }
    },
  },
});
