import { Module } from 'vuex';
import { RootState } from '../index';

/** e-mansion ホームページ容量 State */
const eMansionHomepageCapacityState: {
  eMansionAccountId: number | undefined;
  eMansionHomepageId: number | undefined;
  ftpAccount: string | undefined;
  eMansionHomepageCapacity: string | undefined;
  eMansionHomepageCapacitySelected: { key: string; value: string } | undefined;
  fee: string | undefined;
  jimFee: string | undefined;
  maxQuota: string | undefined;
  canApply: boolean;
} = {
  eMansionAccountId: undefined,
  eMansionHomepageId: undefined,
  ftpAccount: undefined,
  eMansionHomepageCapacity: undefined,
  eMansionHomepageCapacitySelected: undefined,
  fee: undefined,
  jimFee: undefined,
  maxQuota: undefined,
  canApply: false,
};

/** State の型 */
type EMansionHomepageCapacityState = typeof eMansionHomepageCapacityState;

/** e-mansion ホームページ容量 Store */
export const eMansionHomepageCapacityStore: Module<EMansionHomepageCapacityState, RootState> = {
  namespaced: true,
  state: eMansionHomepageCapacityState,
  actions: {},
  mutations: {
    eMansionAccountId: (state, value: number) => (state.eMansionAccountId = value),
    eMansionHomepageId: (state, value: number) => (state.eMansionHomepageId = value),
    ftpAccount: (state, value: string) => (state.ftpAccount = value),
    eMansionHomepageCapacity: (state, value: string) => (state.eMansionHomepageCapacity = value),
    eMansionHomepageCapacitySelected: (state, value: { key: string; value: string }) => (state.eMansionHomepageCapacitySelected = value),
    fee: (state, value: string) => (state.fee = value),
    jimFee: (state, value: string) => (state.jimFee = value),
    maxQuota: (state, value: string) => (state.maxQuota = value),
    canApply: (state, value: boolean) => (state.canApply = value),
  },
  getters: {
    eMansionAccountId: (state): number | undefined => state.eMansionAccountId,
    eMansionHomepageId: (state): number | undefined => state.eMansionHomepageId,
    ftpAccount: (state): string | undefined => state.ftpAccount,
    eMansionHomepageCapacity: (state): string | undefined => state.eMansionHomepageCapacity,
    eMansionHomepageCapacitySelected: (state): { key: string; value: string } | undefined => state.eMansionHomepageCapacitySelected,
    fee: (state): string | undefined => state.fee,
    jimFee: (state): string | undefined => state.jimFee,
    maxQuota: (state): string | undefined => state.maxQuota,
    canApply: (state): boolean => state.canApply,
  },
};
