




















































































































































import Vue from 'vue';

/** エラーメッセージ用コンポーネント */
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
/** クレジットカード用コンポーネント */
import CreditCardComponent from '@/shared/components/veritrans-credit-card-component.vue';
/** サービス UCOM Connectix 外部 API をコールするサービス */
import { EMansionConnectixExternalApiService } from '@/shared/services/external-api/connectix/e-mansion/e-mansion-connectix-external-api-service';
/** Entity UCOM Connectix API : エラー時のレスポンス */
import { EMansionConnectixErrorResponse } from './classes/external-api/connectix-error-response';
/** Entity e-mansion 共通利用 API : 契約基本情報取得 : レスポンス */
import { EMansionCustomer } from '../../../shared/classes/external-api/e-mansion/customer-response';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { Property } from '@/shared/classes/spf-api/property';
import { EMansionConnectixNewConnectixRequest, EMansionConnectixNewConnectixRequestResult } from './classes/external-api/new-connectix-request';
import { EMansionProperty } from '../../../shared/classes/external-api/e-mansion/property-response';
import { AuthService } from '@/shared/services/auth/auth-service';
import { Member } from '@/shared/classes/spf-api/member';
import { UaTypeValue, UA_TYPE, SERVICE_TYPE, ISP_MEMBER_STATUS } from '@/shared/const/service-type';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { ConnectixMaintenanceInfo } from '@/shared/classes/spf-api/connectix-maintenance';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { getIspMemberStatusEMansion } from '@/shared/util/func-get-isp-member-status';

/** Confirm コンポーネント */
export default Vue.extend({
  name: 'e-mansion-connectix-confirm',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    /** クレジットカード入力フォームコンポーネント */
    CreditCardComponent,
    LoadingComponent,
  },
  data: () => ({
    /** 物件名 */
    mansionName: '',
    /** 棟名 */
    buildingName: '',
    /** 棟ID */
    buildingId: '',
    /** 部屋番号 */
    roomNumber: '',
    /** Connectix利用金額 */
    connectixUsageFee: '' as string,
    /** Connectixキャンペーン利用金額 */
    connectixUsageFeeCp: '' as string,
    /** e-mansion 会員ID */
    eMansionMemberId: '' as string,
    /** 物件ID */
    eMansionPropertyId: '',
    /** UA種別 */
    uaType: '',
    /** 支払い方法によってクレジットカード申請フォームの表示/非表示を切り替える */
    isFormForCreditCardApplication: false,
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    /** クレジットカードアクセストークン */
    creditCardAccessToken: '',
    /** クレジットカードトークン有効期限 */
    creditTokenExpireDate: '',
    /** APIトークンキー */
    apiTokenKey: '',
    /** トークン取得成功後に表示するメッセージ */
    successMessage: '画面下の「お申し込み」ボタンをクリックしてください。',
    /** ボタン押下判定 */
    isSubmitting: false,
    isLoading: true, // 読み込み状態,最初はロード中
    // ISP(e-mansion)会員ステータス
    ispMemberStatusEMansion: ''
  }),
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    try {
      /** ログインしているか否かの情報を取得 */
      const isLoggedIn = await AuthService.isLoggedIn();
      // ログインしていない場合「総合トップ」画面にリダイレクトする
      if (!isLoggedIn) {
        this.$router.push('/platform').catch((error) => {
          checkRouterError(error);
        });
        return;
      }

      // 会員情報をStoreから取得
      const member: Member = this.$store.getters['memberStore/member'];
      this.eMansionMemberId = member.primaryKeyMye ?? '';

      // 物件情報をStoreから取得
      const property: Property = this.$store.getters['propertyStore/property'];
      const uaType: UaTypeValue | undefined = property.uaType;
      const apartmentId: string | undefined = property.apartmentId;

      // 必要な物件情報を取得できなかった場合はエラー画面に遷移
      if (!uaType || !apartmentId) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.PROPERTY_NO_UA_TYPE);
      }

      // // 契約基本情報取得 API 用のクエリ
      // const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
      //   ua_type: uaType,
      //   apartment_id: apartmentId,
      // });

      // // 契約基本情報をStoreから取得
      // const customer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
      //   memberId: this.$data.eMansionMemberId,
      //   query: findCustomerByIdQueryDto,
      // });
      const customer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.getters['eMansionCommonStore/customer'];
      if (customer instanceof EMansionCustomer) {
        // 正常ケースの場合
        // 物件ID
        this.$data.eMansionPropertyId = customer.property_id;

        // 登録している支払方法が以下のいずれかの場合、クレジットカード申請フォームを表示しない
        /**
         * 0: 口座振替
         * 1: 振込
         * 4: 口振（請求書）
         * 8: カード
         */
        const paymentIds = ['0', '1', '4', '8'];
        if (paymentIds.some((paymentId) => paymentId === customer.payment_id)) {
          this.$data.isFormForCreditCardApplication = true;
        }
      } else {
        // 外部APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }

      // 物件基本情報をStoreから取得
      // const params = {
      //   // propertyId: this.$data.eMansionPropertyId,
      //   apartmentId: apartmentId,
      //   uaType: UA_TYPE.E_MANSION,
      // };
      // const eMansionProperty: EMansionProperty | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/property', params);
      const eMansionProperty: EMansionProperty | EMansionSharedErrorResponse = await this.$store.getters['eMansionCommonStore/property'];
      if (eMansionProperty instanceof EMansionProperty) {
        // 物件名
        this.$data.mansionName = eMansionProperty.em_ap_name;
        // Connectix利用金額
        this.connectixUsageFee = eMansionProperty.op.sdwan.fee;
        this.connectixUsageFeeCp = eMansionProperty.op.sdwan.fee_cp;
      } else {
        // 外部APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.PROPERTY_INFO);
      }

      // APIトークンキーを設定
      this.$data.apiTokenKey = process.env.VUE_APP_VERITRANS_TOKEN_API_KEY_E_MANSION;
      // 入力画面で入力した情報をStoreから取得
      const inputApply = this.$store.getters['eMansionConnectixEntryStore/entryInputForm'];
      if (inputApply == null) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_INPUT_DATA);
      }
      // 部屋番号
      this.$data.roomNumber = inputApply.roomNumber;
      // 棟名
      this.$data.buildingName = inputApply.buildingName;
      // 棟ID
      this.$data.buildingId = inputApply.buildingId;
    } catch (error) {
      throw error;
    }

    this.$data.isLoading = false;
  },
  methods: {
    /** 「お申し込み ➡」ボタン押下時 : Connectixお申し込みAPIを実行し お申し込み完了画面に遷移する */
    async onApply() {
      try {
        // ボタン押下中は何もしない
        if (this.isSubmitting) {
          return;
        }
        // ボタン押下中扱いとする
        this.isSubmitting = true;

        const connectixMaintenance: ConnectixMaintenanceInfo = await this.$store.dispatch('connectixMaintenanceStore/connectixMaintenance', SERVICE_TYPE.E_MANSION);
        if (connectixMaintenance) {
          this.$router.push('/platform/maintenance').catch((error) => {
            checkRouterError(error);
          });
          return;
        }

        // エラーメッセージ格納配列初期化
        this.$data.errorMessages = [];

        if (!this.$data.isFormForCreditCardApplication && this.$data.creditCardAccessToken === '') {
          // クレジットカードが登録されておらず、かつAPIキーが発行されていない場合はエラー
          this.$data.errorMessages.push(`カード番号、有効期限を正しく入力してください。`);
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          return;
        }

        // ISP(e-mansion)側で退会申し込み中のとき、エラーメッセージを表示させる
        if (await this.isInCancelApplication()) {
          this.$router.push('/connectix/e-mansion/terms').catch(error => {checkRouterError(error)});
          return;
        }

        // Connectix申し込みAPIを実行
        const request = new EMansionConnectixNewConnectixRequest({
          /** UA種別 */
          ua_type: UA_TYPE.E_MANSION,
          /** 会員ID */
          member_id: this.$data.eMansionMemberId,
          /** 物件ID */
          property_id: this.$data.eMansionPropertyId,
          /** 棟名 */
          building_id: this.$data.buildingId,
          /** 部屋番号 */
          room_number: this.$data.roomNumber,
          /** クレジットカードトークン */
          credit_card_token: this.$data.creditCardAccessToken,
          /** トークン有効期限 */
          token_expire_date: this.$data.creditTokenExpireDate,
        });

        const resultCreateConnectix = await EMansionConnectixExternalApiService.createConnectix(request);
        if (resultCreateConnectix instanceof EMansionConnectixErrorResponse) {
          // 外部APIエラー
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONNECTIX);
        }
        // お申し込み完了画面に遷移
        this.$router.push('/connectix/e-mansion/completed').catch((error) => {
          checkRouterError(error);
        });
      } catch (error) {
        throw error;
      } finally {
        // ボタン押下解除
        this.isSubmitting = false;
      }
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークンを受け取る */
    getCreditCardToken(token: string) {
      // エラーメッセージ格納配列初期化
      this.$data.errorMessages = [];
      this.$data.creditCardAccessToken = token;
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークン有効期限を受け取る */
    getTokenExpireDate(creditTokenExpireDate: string) {
      this.$data.creditTokenExpireDate = creditTokenExpireDate;
    },
    /** クレジットカードエラーが発生していた場合 */
    executeCreditCardError(errorMessage: string) {
      this.$data.errorMessages = [errorMessage];
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
    },
    async isInCancelApplication() {

      const property: Property = this.$store.getters['propertyStore/property'];

      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: property.uaType,
        apartment_id: property.apartmentId
      });

      const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
        memberId: this.eMansionMemberId,
        query: findCustomerByIdQueryDto
      });

      if (eMansionCustomer instanceof EMansionCustomer) {

        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusEMansion = getIspMemberStatusEMansion(memberStatus, eMansionCustomer);

        /**
         * ISP(e-mansion)会員ステータスが次の3つの場合、ISP退会申し込み中であると判定する
         *
         * 1. ISP(e-mansion)会員ステータス: 退会申し込み
         * 2. ISP(e-mansion)会員ステータス: 移転退会
         * 3. ISP(e-mansion)会員ステータス: 退会済みログイン可能
         */
        if (this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.IN_CANCEL_APPLICATION
            || this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.TRANSFER_AND_CANCEL
            || this.ispMemberStatusEMansion === ISP_MEMBER_STATUS.E_MANSION.CANCEL_AND_LOGIN_OK) {
          return true;
        }
      } else {
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
      }
      return false;
    }
  },
  computed: {
    isDisabledButton(): boolean {
      return !this.isFormForCreditCardApplication && this.creditCardAccessToken === '';
    },
  },
});
