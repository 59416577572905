






































































































































import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';

/** Terms コンポーネント */
export default Vue.extend({
  name: 'terms',
  components: {
	LoadingComponent
  },
  data: () => ({
	isRouting: false // onTopPage()が実行された後、router.pushが実行されるまで用
  }),
  methods: {
    onTopPage() {
	  if (this.isRouting) {
		return;
	  }

	  this.isRouting = true;
	  this.$router.push('/').catch(error => {checkRouterError(error)}).finally( () => {
		this.isRouting = false;
	  });
	}
  }
});
