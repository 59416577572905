































































import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { EMansionAccountInfo } from '@/shared/classes/spf-api/mail/model/account/e-mansion-account-info';

export default Vue.extend({
  name: 'complete',
  components: {
    LoadingComponent,
  },

  data: () => ({
    isLoading: true,
    mailAddress: '',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  mounted() {
    try {
      this.$data.mailAddress = this.$store.getters['eMansionMailPasswordStore/mailAddress'];
      if (!this.$data.mailAddress) {
        const accountInfo: EMansionAccountInfo = this.$store.getters['eMansionAccountStore/eMansionAccountInfo'];
        this.$data.mailAddress = accountInfo.eMansionMailAddressInfo?.mailAddress;
      }
      this.$data.isLoading = false;
    } catch (error) {
      this.$router.push('/e-mansion/error');
      return;
    }
  },
  methods: {
    async goTop() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      await this.$router.push('/e-mansion');
      this.$data.isLoading = false;
    },
  },
});
