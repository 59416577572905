






















































































































































































































































































































import { Member } from '@/shared/classes/spf-api/member';
import Vue from 'vue';
import { AuthService } from '../shared/services/auth/auth-service';
import EMansionLeftMenu from './e-mansion/left-menu.vue';
import UcomLeftMenu from './ucom/left-menu.vue';
import LeftMenuCommonLink from './components/left-menu-common-link.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { Property } from '@/shared/classes/spf-api/property';
import { TRANSITION_STORAGE, UA_TYPE } from '@/shared/const/service-type';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { hasAdditionalAccount } from '@/shared/const/service-plan-type';

/** Index コンポーネント */
export default Vue.extend({
  name: 'index',
  components: {
    UcomLeftMenu,
    EMansionLeftMenu,
    LeftMenuCommonLink,
    LoadingComponent
  },
  data: () => ({
    /** 画面右下に表示する「↑」(画面上部に戻る) ボタンが表示されていれば true */
    isShownToTop: false,
    /** ログインしているかどうか */
    isLoggedIn: false,
    /** ヘッダに表示する物件名 */
    apartmentName: '',
    /** ヘッダに表示する部屋番号 */
    roomNumber: '',
    /** e-mansionとの紐づけがあるか */
    isLinkedEMansion: false,
    /** Five.Aとの紐づけがあるか */
    isLinkedFiveA: false,
    /** ucomとの紐づけがあるか */
    isLinkedUcom: false,
    /** mcloudとの紐づけがあるか */
    isLinkedMcloud: false,
    /** マイページ表示してよいか */
    isDispMyPage: false,
    /** ucomまたはe-mansionトップか */
    isUcomOrEmansionToppage: false,
    // e-mansion会員専用Topでvueのmounted実行中かどうか
    isMemberTopMountingEmansion: false,
    // ucom会員専用Topでvueのmounted実行中かどうか
    isMemberTopMountingUcom: false,
    // ucom会員専用Topでvueのmounted実行中かどうか
    isMemberTopMountingMcloud: false,
    // ハンバーガーメニューのアイコンのcheckboxがcheckedかどうか
    isHamburgerChecked: false,
    // ログインボタンを表示する画面かどうか
    isDisplayedLoggedInButton: true,
    // ログインボタンを表示する画面かどうか
    isLogginedDisplayedLoggedInButton: false,
    // サブアカウント用表示判定
    isSubAccount: false
  }),
  /** コンポーネント生成時の処理 */
  async mounted() {
    this.isLoggedIn = await AuthService.isLoggedIn();
    // e-mansion | ucom | mloud 会員専用トップのときスピナー表示させる用
    if (this.$route.path === '/e-mansion') {
      this.isMemberTopMountingEmansion = true;
    } else if (this.$route.path === '/ucom') {
      this.isMemberTopMountingUcom = true;
    // } else if (this.$route.path === '/mcloud') {
    //   this.isMemberTopMountingMcloud = true;
    }
    // スクロールを監視する
    window.addEventListener('scroll', this.onScroll);
    // 紐づけがされているサービスを確認（マイページ表示要否も確認）
    await this.isLinkedService();
    this.disableDisplayedLoggedInButton();
    // ログインボタンの表示制御
    // 画面を新しく開く場合
    if(this.$route.path === '/error') {
      this.$data.isDisplayedLoggedInButton = false;
    } else if(this.$route.path === '/platform/cancel/completed') {
      this.$data.isDisplayedLoggedInButton = false;
    } else if(this.$route.name === 'Not Found') {
      this.$data.isDisplayedLoggedInButton = false;
    } else {
      this.$data.isDisplayedLoggedInButton = true;
    }

    this.isSubAccount = await this.isSubAccountFunc();
  },
  /** コンポーネント破棄時の処理 */
  beforeDestroy() {
    // スクロールの監視を止める
    window.removeEventListener('scroll', this.onScroll);
  },
  computed: {
    isExistLeftMenuComponent() {
      const path = this.$route.path;
      if (path === '/e-mansion' || path === '/ucom') {
        return true;
      } else {
        return false;
      }
    },
    leftMenuComponent() {
      switch (this.$route.path) {
        case '/e-mansion':
          return EMansionLeftMenu;
        case '/ucom':
          return UcomLeftMenu;
        default:
          return '';
      }
    }
  },
  watch: {
    async $route(to, from) {
      this.isHamburgerChecked = false; // ハンバーガーメニューを閉じる
      this.isLoggedIn = await AuthService.isLoggedIn();
      if(!this.$data.isDispMyPage){
        await this.isDispMyPageFunc();
      }
      this.displayedLoggedInButton(); // 通常の画面遷移用
      this.disableDisplayedLoggedInButton();
      await this.isLinkedService();
      
      if(to.path === '/e-mansion' || to.path === '/ucom') {
        this.$data.isUcomOrEmansionToppage = true;
      } else {
        this.$data.isUcomOrEmansionToppage = false;
      }
      if (to.path === '/e-mansion') {
        this.isMemberTopMountingEmansion = true;
      } else if (to.path === '/ucom') {
        this.isMemberTopMountingUcom = true;
      // } else if (to.path === '/mcloud') {
      //   this.isMemberTopMountingMcloud = true;
      }
    },
  },
  methods: {
    /** スクロールを検知し、画面最上部から 100px 以上スクロールしていたら「↑」ボタンを表示させる */
    onScroll() {
      this.$data.isShownToTop = window.scrollY > 100;
    },
    /** 「ログイン」ボタン押下時 : ログイン処理を行い「ログイン後トップ」画面に遷移する */
    async onLogin() {
      if(await AuthService.isLoggedIn()){
        localStorage.removeItem(TRANSITION_STORAGE);
        return await AuthService.logout('/login-forwarding');
      } else {
        return await AuthService.login();
      }
    },
    /** ログアウト処理 */
    async onLogout() {
      await AuthService.logout();
    },
    /** マイページ遷移処理 */
    onMyPage(){
      this.$router.push('/platform/my-page').catch(error => {checkRouterError(error)});
    },
    /** Connectix遷移処理 */
    onIndex(){
      this.$router.push('/').catch(error => {checkRouterError(error)});
      this.isHamburgerChecked = false; // ハンバーガーメニューを閉じる
    },
    /** マイページ表示してよいか */
    async isDispMyPageFunc() {
      if (this.$store.getters['memberStore/member']) {
        this.$data.isDispMyPage = true;
        return this.$data.isDispMyPage;
      } else {
        this.$data.isDispMyPage = false;
        return this.$data.isDispMyPage;
      }
    },
    /** サービス連携しているか */
    async isLinkedService() {
      const member: Member | null = this.$store.getters['memberStore/member'];
      if (this.isLoggedIn && member) {
        this.isDispMyPage = true;
        if (member.primaryKeyMye && member.propertyId) {
          const property: Property | null = this.$store.getters['propertyStore/property'];
          if (property) {
            if (property.uaType === UA_TYPE.E_MANSION) {
              this.isLinkedEMansion = true;
            } else {
              this.isLinkedEMansion = false;
            }
            if (property.uaType === UA_TYPE.FIVE_A) {
              this.isLinkedFiveA = true;
            } else {
              this.isLinkedFiveA = false;
            }
          }
        } else {
          this.isLinkedEMansion = false;
          this.isLinkedFiveA = false;
        }
        this.isLinkedUcom = member.primaryKeyUcom ? true : false;
        this.isLinkedMcloud = member.primaryKeyMcloud ? true : false;
      } else {
        this.isLinkedEMansion = false;
        this.isLinkedUcom = false;
        this.isLinkedMcloud = false;
        this.isLinkedFiveA = false;
      }
    },
    changeIsMemberTopMountingEmansion() {
      this.isMemberTopMountingEmansion = false;
    },
    changeIsMemberTopMountingUcom() {
      this.isMemberTopMountingUcom = false;
    },
    changeIsMemberTopMountingMcloud() {
      this.isMemberTopMountingMcloud = false;
    },
    clickContacts(value: boolean){
      this.isHamburgerChecked = value;
    },
    cancelLoggedIn() {
      this.isLoggedIn = false;
    },
    displayedLoggedInButton() {
      if(this.$route.path === '/error') {
        this.$data.isDisplayedLoggedInButton = false;
      } else if (this.$route.path === '/platform/cancel/completed') {
        this.$data.isDisplayedLoggedInButton = false;
      } else if(this.$route.name === 'Not Found') {
        this.$data.isDisplayedLoggedInButton = false;
      } else {
        this.$data.isDisplayedLoggedInButton = true;
      }
    },
    disableDisplayedLoggedInButton() {
      if(this.$route.path === '/') {
        this.isLogginedDisplayedLoggedInButton = true;
      } else {
        this.isLogginedDisplayedLoggedInButton = false;
      }
    },
    async isSubAccountFunc() {
      const planType = this.$store.getters['servicePlanTypeStore/servicePlanType'];
      return hasAdditionalAccount(planType);
    }
  }
});
