/** 使用するバリデーションの定義 */
import { helpers } from 'vuelidate/lib/validators';

/** 正規表現を用いたチェック定義：全角文字（バリデーション定義にhelperを加え、そこからregexでチェック定義の正規表現を指定可） */
export const fullWidth = helpers.regex('banHalfWidth', /^[^ -~｡-ﾟ]+$/);
/** 正規表現を用いたチェック定義：全角文字（バリデーション定義にhelperを加え、そこからregexでチェック定義の正規表現を指定可） */
export const fullWidthNoNum = helpers.regex('banHalfWidth', /^[^ -~｡-ﾟ０-９]+$/);
/** 正規表現を用いたチェック定義：半角英数字（バリデーション定義にhelperを加え、そこからregexでチェック定義の正規表現を指定可） */
export const halfWidth = helpers.regex('halfWidth', /^[0-9a-zA-Z]*$/);
/** 正規表現を用いたチェック定義：UCOM電話番号用(数字2～4文字)-(2～4文字)-(4文字)（バリデーション定義にhelperを加え、そこからregexでチェック定義の正規表現を指定可） */
export const phoneStyle = helpers.regex('phoneStyle', /^0\d{1,4}-\d{1,4}-\d{4}$/);
/** 正規表現を用いたチェック定義：全角文字（バリデーション定義にhelperを加え、そこからregexでチェック定義の正規表現を指定可） */
export const fullKana = helpers.regex('fullKana', /^[ァ-ンヴー]*$/);
export const fullKanaAndBlank = helpers.regex('fullKana', /^[ァ-ンヴー\s]*$/);
/** 正規表現を用いたチェック定義：メールアドレス（API側のバリデーションと統一するため追加）（バリデーション定義にhelperを加え、そこからregexでチェック定義の正規表現を指定可） */
// export const emailAddress = helpers.regex('emailAddress', /^([a-zA-Z0-9_+-])+([a-zA-Z0-9._+-])*@([a-zA-Z0-9._+-])+.([a-zA-Z0-9])+$/);
export const emailAddressMaxLength = helpers.regex('emailAddressMaxLength', /^\S{1,48}$/);
export const emailAddressIllegalChar = helpers.regex('emailAddressIllegalChar', /^.+@.+$/);
export const emailAddressIllegalCharMark = helpers.regex('emailAddressIllegalCharMark', /^[\w.\!\"\#\$\%\&\'\(\)\*\+\-\.\/\:\;\<\=\>\?\[\]\^\_\`\{\|\}\~]+@[^@\s\,\\]+$/);
/** 正規表現を用いたチェック定義：e-mansionアカウント名用（バリデーション定義にhelperを加え、そこからregexでチェック定義の正規表現を指定可）*/
export const emansionAccountName = helpers.regex('emansionAccountName', /^([a-z]{1})(?=.*[_-])[a-zA-Z0-9_-]{1,10}([a-z0-9]{1})$/);
export const blank = helpers.regex('blank', /(.*)(\s+)(.*)/);
