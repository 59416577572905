



































































import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { UcomVirusCheckPauseState } from '@/shared/classes/ucom/ucom-virus-check-pause-state';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { convertUcomVirusCheckPauseActionById } from '@/shared/const/ucom/ucom-virus-check-pause-action';

export default Vue.extend({
  name: 'virus-check-pause-completed',
  components: {
    LoadingComponent,
  },
  data(): {
    ucomVirusCheckPauseState: UcomVirusCheckPauseState | undefined;
    isTransitionProcessing: boolean;
    isMounting: boolean;
  } {
    return {
      // UCOM ウイルスチェック一時停止/開始 ストアから取得した値を入れる
      ucomVirusCheckPauseState: undefined,
      // ページ遷移処理実行中かどうか
      isTransitionProcessing: false,
      // mounted() 実行中かどうか
      isMounting: true,
    }
  },
  mounted() {
    this.ucomVirusCheckPauseState = this.$store.getters['ucomVirusCheckStore/pauseState'];
    this.isMounting = false;
  },
  methods: {
    /**
     * ucom会員専用トップへページ遷移する処理
     */
    async transitionTo() {
      if (this.isTransitionProcessing) {
        return;
      }

      this.isTransitionProcessing = true;

      await this.$router.push('/ucom').catch((error) => {
        checkRouterError(error);
      });
      return;
    },
  },
  filters: {
    // ウイルスチェック設定表示用
    numberToDisplayPauseAction(number: number): string | undefined {
      return convertUcomVirusCheckPauseActionById(number) ?? '-';
    },
  },
});
