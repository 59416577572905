import axios from 'axios';
import { BaseApiService } from '@/shared/services/api/base-api-service';
import { JudgeError } from '@/infra/accessor/spf/common/judge-error';
import { EMansionSubAccountInfo } from '@/shared/classes/spf-api/mail/model/sub-account/e-mansion-sub-account-info';
import { subAccountData } from '@/shared/classes/spf-api/mail/model/sub-account/e-mansion-sub-account-data';
import { ObfuscationService } from '@/shared/services/obfuscation/obfuscation-service';
import { subAccountAddInfo } from '@/shared/classes/spf-api/mail/model/sub-account/e-mansion-sub-account-add-info';
import { subAccountCancelInfo } from '@/shared/classes/spf-api/mail/model/sub-account/e-mansion-sub-account-cancel-info';
import { MYE_REGISTER_STATUS } from '@/shared/const/e-mansion';

const api = BaseApiService.api();
const rawApi = axios.create({ baseURL: process.env.VUE_APP_API_ENDPOINT_URL });

/** 内部 API をコールするサービス */
export class SpfApiSubAccountAccessor {
  /**
   * AE17-001追加アカウント利用状況取得
   * @param id 会員ID
   * @param flg スリムプラン物件フラグ
   * @return メールアドレス利用状況
   */
  public static async getSubAccountList(apartmentId: string, memberId: number, slimPlan: string): Promise<EMansionSubAccountInfo> {
    const response = await api.get<EMansionSubAccountInfo>(`/account/e-mansion/joukyou/${memberId}?slimPlan=${slimPlan}&apartmentId=${apartmentId}`).catch(async (error) => {
      await JudgeError.judgeError(error);
      throw error;
    });
    return await this.convertSubAccountInfo(response);
  }

  private static async convertSubAccountInfo(response: any | undefined): Promise<EMansionSubAccountInfo> {
    if (!response) {
      throw new Error('追加アカウント情報無し');
    }
    return new EMansionSubAccountInfo({
      _mailPackFee: response.data.mailPackFee,
      _countSubAccount: response.data.countSubAccount,
      _subAccountData: this.convertSubAccountDatas(response.data.subAccountData),
    });
  }

  private static convertSubAccountDatas(response: any | undefined): subAccountData[] {
    if (!response) {
      throw new Error('追加アカウント情報なし');
    }
    const list: subAccountData[] = new Array(0);
    for (let i = 0; i < response.length; i++) {
      list.push(this.convertSubAccountData(response[i]));
    }
    return list;
  }

  private static convertSubAccountData(response: any | undefined): subAccountData {
    if (!response) {
      throw new Error('追加アカウント情報なし');
    }
    if (response.cancelOn) {
      const date = new Date(response.cancelOn);
      response.cancelOn = this.formatDate(date);
    }
    return new subAccountData({
      _eMansionAccountId: response.eMansionAccountId,
      _eMansionAccountName: response.eMansionAccountName,
      _initialAccountPassword: ObfuscationService.decode(response.initialAccountPassword),
      _initialMailPassword: ObfuscationService.decode(response.initialMailPassword),
      _popServer: response.popServer,
      _smtpServer: response.smtpServer,
      _mailAddress: response.mailAddress,
      _displayRegisterStatus: response.displayRegisterStatus,
      _registerStatus: response.registerStatus,
      _cancelOn: response.cancelOn,
      _canCancelBtn: response.registerStatus != MYE_REGISTER_STATUS.IN_WITHDRAWAL_APPLICATION.id,
    });
  }

  /**日付をyyyy/mm/ddの形式にする
   * @param date Date型の日付
   * @return yyyy/mm/ddの形式の文字列
   */
  private static formatDate(date: Date): string {
    const y = date.getFullYear();
    const m = ('00' + (date.getMonth() + 1)).slice(-2);
    const d = ('00' + date.getDate()).slice(-2);
    return y + '/' + m + '/' + d;
  }

  /**
   * AE17-002_追加アカウント：確認(お申込み)_更新API
   * @param memberId　会員ID
   * @param choiceAccount ご希望アカウント
   * @param memberType 会員種別
   * @param domainFlag 独自ドメインフラグ
   * @param domainName 独自ドメイン名
   * @param date 処理日
   * @returns
   */
  public static async addSubAccount(
    memberId: number,
    choiceAccount: string,
    mailAddressDomain: string,
    memberType: string,
    domainFlag: string,
    domainName: string,
    date: Date
  ): Promise<subAccountAddInfo> {
    const processOn = this.formatDate(new Date(date));
    const response = await api
      .post<any>(`/account/e-mansion/kakunin-touroku`, {
        memberId,
        choiceAccount,
        mailAddressDomain,
        memberType,
        domainFlag,
        domainName,
        processOn,
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });
    return await this.convertAddSubAccount(response);
  }

  private static async convertAddSubAccount(response: any): Promise<subAccountAddInfo> {
    if (!response) {
      throw new Error('追加アカウント情報なし');
    }
    return new subAccountAddInfo({
      _mailAddress: response.data.mailAddress,
      _mailPassword: response.data.mailPassword ? ObfuscationService.decode(response.data.mailPassword) : undefined,
      _popServer: response.data.popServer,
      _smtpServer: response.data.smtpServer,
      _resultMessage: response.data.resultMessage,
    });
  }

  /**
   * AE17-003_追加アカウント：確認(解約)_更新API
   * @param eMansionAccountId e-mansionアカウントID
   * @param date 処理日
   * @returns
   */
  public static async cancelSubAccount(eMansionAccountId: number, date: Date): Promise<subAccountCancelInfo> {
    const processOn = this.formatDate(new Date(date));
    const response = await api
      .post<subAccountCancelInfo>(`/account/e-mansion/kakunin-kaiyaku`, {
        eMansionAccountId: eMansionAccountId,
        processOn: processOn,
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });
    return await this.convertCancelSubAccount(response);
  }

  private static async convertCancelSubAccount(response: any): Promise<subAccountCancelInfo> {
    if (!response) {
      throw new Error('追加アカウント情報なし');
    }
    return new subAccountCancelInfo({
      _accountName: response.data.accountName,
      _mailAddress: response.data.mailAddress,
      _cancelOn: response.data.cancelOn,
      _errorMessage: response.data.resultMessage ? response.data.resultMessage : response.data.errorMessage,
    });
  }
}
