































































































































































































































































































































































































































import Vue from 'vue';
import MainSwiperComponent from '../components/main-swiper.vue'
import PickupSwiperComponent from '../components/pickup-swiper.vue';
import PortalServiceComponent from './components/portal-service.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { MEMBER_STATUS } from '@/shared/const/member-status';
import FrequentlyAskedQuestions from '../components/frequently-asked-questions.vue';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { EMansionSharedErrorResponse } from '@/shared/classes/external-api/e-mansion/shared-error-response';
import { UcomCustomerResponse } from '@/shared/classes/external-api/ucom/customer-response';
import { ISP_MEMBER_STATUS, TRANSITION_SOURCE_SERVICE, UA_TYPE } from '@/shared/const/service-type';
import { Member } from '@/shared/classes/spf-api/member';
import { Property } from '@/shared/classes/spf-api/property';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { FiveACustomer } from '@/shared/classes/external-api/five-a/customer-response';
import { FiveASharedErrorResponse } from '@/shared/classes/external-api/five-a/shared-error-response';
import { getIspMemberStatusFiveA, getIspMemberStatusUcom } from '@/shared/util/func-get-isp-member-status';

export default Vue.extend({
  name: 'platform-index',
  components: {
    MainSwiperComponent,
    PickupSwiperComponent,
    PortalServiceComponent,
    LoadingComponent,
    FrequentlyAskedQuestions
  },
  data: () => ({
    status: 'members-only-top',
    // お住まいを登録しているかどうか
    isRegisterApartment: false,
    connectixLpUrl: process.env.VUE_APP_CONNECTIX_LP_URL,
    isStopLinking: false,
    isMounted: false,
    isMountedPortalService: false, // portal-serviceコンポーネントのmounted終わったかどうか
    isImagesLoadedMainSwiper: false, // ファーストビューで表示されるmain-swiperの画像の読み込みがすべて終わったかどうか
    // よくある質問の表示を切り替えるために使う
    serviceType: 'default',
    // connectix表示可否 よくある質問
    isDisplayConnectixAsk: false,
    // connectix表示可否 swiper
    isDisplayConnectix: false,
    // 会員情報
    member: null as Member | null,
    // 会員ステータス
    memberStatus: null as MemberStatus | null,
    // 物件情報
    property: null as Property | null,
    // ISP(FiveA)会員ステータス
    ispMemberStatusFiveA: '',
    // ISP(ucom)会員ステータス
    ispMemberStatusUcom: ''
  }),
  async mounted(){

    // 会員情報取得
    this.$data.member = this.$store.getters['memberStore/member'];
    this.memberStatus = this.$store.getters['memberStore/memberStatus'];
    this.$data.isRegisterApartment = this.$data.member.propertyId ? true : false;

    // !this.isRegisterApartmentのとき、portal-serviceコンポーネント使われない
    if (!this.$data.isRegisterApartment) {
      this.isMountedPortalService = true;
    }

    if((this.memberStatus?.status === MEMBER_STATUS.ACCOUNT_REGISTERED || this.memberStatus?.status === MEMBER_STATUS.PROPERTY_LINKED) && !this.$data.member.propertyId){
      this.$data.isStopLinking = true;
    }

    if (this.$data.member?.propertyId) {
      this.$data.property = this.$store.getters['propertyStore/property'];
    }

    // e-mansionパターン connectix対応確認
    if(this.property?.uaType === UA_TYPE.E_MANSION && this.member?.primaryKeyMye){
      // 物件基本情報をStoreから取得
      // const propertyParams = {
      //   // propertyId: this.property.apartmentId,
      //   apartmentId: this.property.apartmentId,
      //   uaType: UA_TYPE.E_MANSION
      // };
      // const eMansionProperty: EMansionProperty | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/property', propertyParams);
      const eMansionProperty: EMansionProperty | EMansionSharedErrorResponse = await this.$store.getters['eMansionCommonStore/property'];
      if (eMansionProperty instanceof EMansionProperty) {
        this.isDisplayConnectix = this.isDisplayConnectixAsk = eMansionProperty.op.sdwan.flag === '1';
      } else {
        // APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.PROPERTY_INFO);
      }
      // 契約基本情報をStoreから取得
      const today = new Date();
      if (this.$data.member.primaryKeyMye) {
        const customerParams = new FindCustomerByIdQueryDto({
          ua_type: UA_TYPE.E_MANSION ,
          apartment_id: this.property.apartmentId
        });
        const eMansionCustomer: EMansionCustomer | EMansionSharedErrorResponse = await this.$store.dispatch('eMansionCommonStore/customer', {
          memberId: this.$data.member.primaryKeyMye,
          query: customerParams
        });
        if (eMansionCustomer instanceof EMansionCustomer) {
          if (eMansionCustomer.quit_date) {
            if (new Date(eMansionCustomer.quit_date) < today) {
              this.isDisplayConnectix = this.isDisplayConnectixAsk = false;
            } else {
              if (eMansionCustomer.status === '01') {
                this.isDisplayConnectix = this.isDisplayConnectixAsk = false;
              }
              if (eMansionCustomer.status === '03') {
                this.isDisplayConnectix = this.isDisplayConnectixAsk = false;
              }
            }
          }
        } else {
          // APIエラー
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.E_MANSION.CONTRACTOR_INFO);
        }
      }
    } else if(this.property?.uaType === UA_TYPE.UCOM && this.member?.primaryKeyUcom){
      // UCOMパターン connectix対応確認
      /** 契約基本情報をStoreから取得 */
      const customer = await this.$store.dispatch('ucomCommonStore/customer', this.member.primaryKeyUcom);
      if (customer instanceof UcomCustomerResponse) {

        this.ucomPattern(customer);
      } else {
        // APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.CONTRACTOR_INFO);
      }
    } else if(this.property?.uaType === UA_TYPE.FIVE_A){

      // primary_key_mye が無い場合、外部APIを実行しない
      if (this.member?.primaryKeyMye) {
        await this.fiveAPattern(this.member?.primaryKeyMye);
      }
    }

    this.serviceType = this.getServiceType();
    this.isMounted = true;
  },
  methods: {
    changeIsMountedPortalService() {
      this.isMountedPortalService = true;
    },
    changeIsImagesLoadedMainSwiper() {
      this.isImagesLoadedMainSwiper = true;
    },
    ucomPattern(ucomCustomer: UcomCustomerResponse) {
      this.ispMemberStatusUcom = getIspMemberStatusUcom(this.memberStatus!, ucomCustomer);
      this.isDisplayConnectix = this.getFlagDisplayConnectixCaseUcom(ucomCustomer);
      this.isDisplayConnectixAsk = this.isDisplayConnectix;
    },
    /**
     * swiperコンポーネントに渡すconnectix画像表示判定フラグ(ucom物件のとき実行する)
     * よくある質問のconnectix部分の表示制御にも使う(isDisplayConnectixAsk)
     *
     * | isDisplayConnectix | isp-member-status-ucom | portas-member-status | ucomCustomer.connectix_offer |
     * | false              | cancelAndLoginNG       | 3                    | true or false                |
     * | false              | cancelAndLoginOK       | 5                    | true or false                |
     * | false              | cancelAndLoginOK       | 7                    | true or false                |
     * | true               | all                    | all                  | true                         |
     */
    getFlagDisplayConnectixCaseUcom(ucomCustomer: UcomCustomerResponse) {

      if (this.memberStatus?.status === MEMBER_STATUS.ACCOUNT_REGISTERED_NO_PROPERTY
          && this.ispMemberStatusUcom === ISP_MEMBER_STATUS.FIVE_A.CANCEL_AND_LOGIN_NG) {
        return false;
      }

      const isExpectedPortasMemberStatus = [
        MEMBER_STATUS.PROPERTY_LINKED,
        MEMBER_STATUS.IN_CANCEL_APPLICATION
      ].some((value) => value === this.memberStatus?.status)

      if (isExpectedPortasMemberStatus
          && this.ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_OK) {
        return false;
      }

      if (ucomCustomer.connectix_offer) {
        return true;
      }
      return false;
    },
    /**
     * Five.A会員のとき、表示内容を制御する
     */
    async fiveAPattern(fiveAMemberId: string) {
      // FiveA の契約基本情報をStoreから取得（e-mansion 契約基本情報取得API を使用）
      const fiveAFindCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: UA_TYPE.FIVE_A,
        apartment_id: this.$data.property.apartmentId
      });

      const fiveACustomer: FiveACustomer | FiveASharedErrorResponse = await this.$store.dispatch('fiveACommonStore/customer', {
        memberId: fiveAMemberId,
        query: fiveAFindCustomerByIdQueryDto
      });

      if(fiveACustomer instanceof FiveASharedErrorResponse){
        // 外部APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.FIVE_A.CONTRACTOR_INFO);
      } else if (fiveACustomer instanceof FiveACustomer){
        this.ispMemberStatusFiveA = getIspMemberStatusFiveA(this.memberStatus!, fiveACustomer);
        this.isDisplayConnectix = this.getFlagDisplayConnectixCaseFiveA();
        this.isDisplayConnectixAsk = this.isDisplayConnectix;
      }
    },
    /**
     * swiperコンポーネントに渡すconnectix画像表示判定フラグ(Five.A物件のとき実行する)
     * よくある質問のconnectix部分の表示制御にも使う(isDisplayConnectixAsk)
     *
     * | isDisplayConnectix | isp-member-status   | portas-member-status |
     * | false              | cancelAndLoginNG    | 3                    |
     * | false              | inCancelApplication | 5                    |
     * | false              | inCancelApplication | 7                    |
     * | false              | cancelAndLoginOK    | 5                    |
     * | false              | cancelAndLoginOK    | 7                    |
     */
    getFlagDisplayConnectixCaseFiveA() {

      if (this.memberStatus?.status === MEMBER_STATUS.ACCOUNT_REGISTERED_NO_PROPERTY
          && this.ispMemberStatusFiveA === ISP_MEMBER_STATUS.FIVE_A.CANCEL_AND_LOGIN_NG) {
        return false;
      }

      const isExpectedIspMemberStatus = [
        ISP_MEMBER_STATUS.FIVE_A.IN_CANCEL_APPLICATION,
        ISP_MEMBER_STATUS.FIVE_A.CANCEL_AND_LOGIN_OK
      ].some((value) => value === this.ispMemberStatusFiveA);

      const isExpectedPortasMemberStatus = [
        MEMBER_STATUS.PROPERTY_LINKED,
        MEMBER_STATUS.IN_CANCEL_APPLICATION
      ].some((value) => value === this.memberStatus?.status);

      if (isExpectedIspMemberStatus && isExpectedPortasMemberStatus) {
        return false;
      }

      return true;
    },
    /**
     * Portas会員退会申し込み中 (会員ステータスが7) のときも
     * よくある質問が表示されるように追加
     */
    getServiceType() {
      // 会員ステータス.transition_source_service が存在するとき
      if (this.memberStatus?.transitionSourceService) {
        return this.memberStatus.transitionSourceService;

      } else {
        // 会員ステータス.transition_source_service が存在しない かつ 会員.property_id が存在するとき
        if (this.property) {

          // 物件.ua_type を 会員ステータス.transition_source_service に変換する
          if (this.property.uaType === UA_TYPE.E_MANSION) {
            return TRANSITION_SOURCE_SERVICE.E_MANSION;

          } else if (this.property.uaType === UA_TYPE.FIVE_A) {
            return TRANSITION_SOURCE_SERVICE.FIVE_A;

          } else if (this.property.uaType === UA_TYPE.UCOM) {
            return TRANSITION_SOURCE_SERVICE.UCOM;
          }
        }
      }

      return '';
    }
  }
});
