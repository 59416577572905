














































































import Vue from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { AuthService } from '@/shared/services/auth/auth-service';
import { SpfApiVirusCheckAccessor } from '@/infra/accessor/spf/ucom/spf-api-virus-check-accessor';
import { UpdateUcomVirusCheckRequest } from '@/infra/request/spf/ucom/virus-check/update-ucom-virus-check-request';
import { UcomVirusCheckPauseState } from '@/shared/classes/ucom/ucom-virus-check-pause-state';
import { convertUcomVirusCheckPauseActionById } from '@/shared/const/ucom/ucom-virus-check-pause-action';
import { UCOM_VIRUS_CHECK_ACTION, UcomVirusCheckAction } from '@/shared/const/ucom/ucom-virus-check-action';
import { UCOM_VIRUS_CHECK_PAUSE_ACTION, UcomVirusCheckPauseAction } from '@/shared/const/ucom/ucom-virus-check-pause-action';

export default Vue.extend({
  name: 'virus-check-pause-confirm',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },
  data(): {
    isMounted: boolean;
    isBusy: boolean;
    virusCheck: UcomVirusCheckPauseState | undefined;
    errorMessages: string[];
  } {
    return {
      isMounted: false,
      isBusy: false,
      virusCheck: undefined,
      errorMessages: [],
    };
  },
  async mounted() {
    // No.1 初期表示
    // 1. お申込み内容表示
    // 1.1. 前画面の情報を受け取り、表示制御を行う
    // 画面項目定義の書式／設定値と参照元に記載の内容で、各項目の値を加工し表示する。
    try {
      this.virusCheck = this.$store.getters['ucomVirusCheckStore/pauseState'];
      if (!this.virusCheck) {
        throw new Error('this.virusCheck is undefined');
      }
    } catch (e) {
      await this.$router.push('/error');
    }

    this.isMounted = true;
  },
  methods: {
    // No.2 戻る ボタン クリック
    async onBack(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.isBusy = true;

      // 1.1.ウイルスチェック: 入力画面に遷移する
      await this.$router.push('/ucom/virus-check/input');
    },
    // No.3 上記内容に設定する ボタン クリック
    async onChange(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      if (!this.virusCheck) {
        await this.$router.push('/error');
        return;
      }
      const ucomVirusCheckAction = this.convertPauseAction(this.virusCheck.virusCheckPauseAction);
      if (!ucomVirusCheckAction) {
        await this.$router.push('/error');
        return;
      }

      this.isBusy = true;
      // エラーメッセージ初期化
      this.errorMessages = [];

      // 1. ウイルスチェック情報更新
      // 1.1. ウイルスチェック情報をAPIより更新する(正常時、お申込み完了メールの送信も行う)
      try {
        const response = await SpfApiVirusCheckAccessor.updateUcomVirusCheckPause(
          new UpdateUcomVirusCheckRequest(
            Number(await AuthService.getMemberId()),
            Number(this.$store.getters['ucomAccountOptionStore/ucomAccountId']),
            this.virusCheck.subscriptionDate,
            ucomVirusCheckAction
          )
        );
        // 1.2. APIのレスポンスを元に、エラー表示部にエラー内容を設定する。
        const errorMessage = response.errorMessage && response.errorMessage.length ? response.errorMessage : undefined;
        if (errorMessage) {
          // エラーがある場合、エラー表示部にエラー内容を設定し、表示する
          this.errorMessages.push(errorMessage);
          this.isBusy = false;
          return;
        }
        // エラーが無い場合のみ、次の 2. 画面遷移処理 を行う
        // 2. 画面遷移処理
        // 画面項目の内容を、次画面に引き渡す
        await this.storePauseState();

        // 2.1. ウイルスチェック：一時停止／開始完了画面に遷移する
        await this.$router.push('/ucom/virus-check/pause-completed');
      } catch (e) {
        await this.$router.push('/error');
      }

      this.isBusy = false;
    },
    /**
     * 一時停止/開始関連の情報を Store に保存する
     */
    async storePauseState(): Promise<void> {
      if (!this.virusCheck) {
        await this.$router.push('/error');
        return;
      }
      /**
       * 以下の画面項目の内容を、次画面に引き渡す
       * アカウント名: 画面保持値
       * メールアドレス: 画面保持値
       * ウイルスチェック設定: 画面保持値
       * お申込み日: 画面保持値
       * 注意事項: 画面保持値
       */
      this.$store.commit('ucomVirusCheckStore/setPauseState', {
        accountName: this.virusCheck.accountName,
        mailAddress: this.virusCheck.mailAddress,
        virusCheckPauseAction: this.virusCheck.virusCheckPauseAction,
        subscriptionDate: this.virusCheck.subscriptionDate,
        terms: this.virusCheck.terms,
      } as UcomVirusCheckPauseState);
    },
    convertPauseAction(pauseAction: UcomVirusCheckPauseAction | undefined): UcomVirusCheckAction | undefined {
      if (pauseAction === UCOM_VIRUS_CHECK_PAUSE_ACTION.PAUSE) {
        return UCOM_VIRUS_CHECK_ACTION.PAUSE;
      }
      if (pauseAction === UCOM_VIRUS_CHECK_PAUSE_ACTION.UNPAUSE) {
        return UCOM_VIRUS_CHECK_ACTION.UNPAUSE;
      }
    },
  },
  computed: {
    // ローダー表示
    isLoading(): boolean {
      return !this.isMounted || this.isBusy;
    },
  },
  filters: {
    // ウイルスチェック設定表示用
    numberToDisplayPauseAction(number: number): string | undefined {
      return convertUcomVirusCheckPauseActionById(number) ?? '-';
    },
  },
});
