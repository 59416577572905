

































































































import Vue from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';

export default Vue.extend({
  name: 'cancel-complete',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    // メソッドが実行中かどうか
    isLoading: true,
    accountName: '',
    mailAddress: '',
    cancelOn: '',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    //メインメールアドレス情報をAPIより取得する
    try {
      this.$data.accountName = this.$store.getters['eMansionSubAccountStore/account'];
      this.$data.mailAddress = this.$store.getters['eMansionSubAccountStore/mailAddress'];
      this.$data.cancelOn = this.$store.getters['eMansionSubAccountStore/cancelOn'];
      if (!this.$data.accountName) {
        await this.$router.push('/e-mansion/sub-account/list');
        return;
      }
      if (!this.$data.mailAddress) {
        await this.$router.push('/e-mansion/sub-account/list');
        return;
      }
      if (!this.$data.cancelOn) {
        await this.$router.push('/e-mansion/sub-account/list');
        return;
      }
      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error');
      return;
    }
  },
  methods: {
    async goList() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.$store.commit('eMansionSubAccountStore/desiredAccount', undefined);
      await this.$router.push('/e-mansion/sub-account/list');
      this.isLoading = false;
    },

    async goTop() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      await this.$router.push('/e-mansion');
      this.$data.isLoading = false;
    },
  },
});
