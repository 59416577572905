





































































































































import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { SpfApiAccountOptionChangeAccessor } from '@/infra/accessor/spf/ucom/spf-api-account-option-change-accessor';
import { AuthService } from '@/shared/services/auth/auth-service';
import dayjs from 'dayjs';
import { UCOM_ACCOUNT_OPTION_QUERY_TYPE } from '@/shared/const/ucom/ucom-account-option';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';

export default Vue.extend({
  name: 'ucom-mail-address-register-confirm',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },
  data: () => ({
    isLoading: false,
    /** ボタンダブルクリック防止用 */
    isSubmitting: false,
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    mailAddressType: {
      UCOM: '1',
      OTHER: '2',
    },
    memberId: '',
    ucomAccountId: '',
    /** 画面表示用のデータ */
    displayData: {
      accountName: '',
      /** ご利用メール */
      mailAddressType: '',
      displayMailAddressType: '',
      mailAddress: '',
      registerSubscriptionOn: '',
      isChange: '',
    },
    /** UCOM の URL */
    ucomUrl: process.env.VUE_APP_UCOM_UPDATE_MAIL_CAUTION_URL,
  }),
  async mounted(): Promise<void> {
    this.isLoading = true;
    try {
      const isLoggedIn = await AuthService.isLoggedIn();
      if (!isLoggedIn) {
        this.$router.push('/').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
    } catch (error) {
      // エラー画面に遷移
      this.$router.push('/error');
      return;
    }

    //Portas会員IDを取得
    this.$data.memberId = this.$store.getters['memberStore/member'].id;

    // Storeから情報を取得する
    this.$data.ucomAccountId = this.$store.getters['ucomAccountOptionStore/ucomAccountId'];
    this.$data.displayData.accountName = this.$store.getters['ucomAccountOptionStore/accountName'];
    this.$data.displayData.displayMailAddressType = this.$store.getters['ucomAccountOptionStore/displayMailAddressType'];
    this.$data.displayData.mailAddress = this.$store.getters['ucomAccountOptionStore/mailAddress'];
    this.$data.displayData.mailAddressType = this.$store.getters['ucomAccountOptionStore/mailAddressType'];
    this.$data.displayData.isChange = this.$store.getters['ucomAccountOptionStore/isChange'];

    // リロードによりストアが初期化されていたら入力画面に飛ばす
    if (
      !this.$data.ucomAccountId ||
      !this.$data.displayData.accountName ||
      !this.$data.displayData.displayMailAddressType ||
      !this.$data.displayData.mailAddress ||
      !this.$data.displayData.mailAddressType ||
      !this.$data.displayData.isChange
    ) {
      this.$router.push('/ucom/account-option-change/mail-input').catch((error) => {
        checkRouterError(error);
      });
    }
    // 画面表示用のデータを設定する
    const formatDate = 'YYYY/MM/DD';
    this.$data.displayData.registerSubscriptionOn = dayjs(new Date()).format(formatDate);
    this.isLoading = false;
  },
  methods: {
    async onNext() {
      // ボタン押下中は何もしない
      if (this.isSubmitting) return;

      // ボタン押下中とする
      this.isSubmitting = true;

      try {
        this.$data.errorMessages = [];
        const subscriptionOn = this.formatDate(await SpfApiServerCommonUtilAccessor.getServerTimeNow());
        // 登録API呼出
        if (this.$data.displayData.isChange === UCOM_ACCOUNT_OPTION_QUERY_TYPE.INSERT) {
          const registerMessage: string | undefined = await SpfApiAccountOptionChangeAccessor.registerMailAddress(
            Number(this.$data.memberId),
            Number(this.$data.ucomAccountId),
            Number(this.$data.displayData.mailAddressType),
            this.$data.displayData.mailAddress,
            subscriptionOn
          );
          if (registerMessage) {
            this.$data.errorMessages.push(registerMessage);
          }
        }
        // 更新API呼出
        if (this.$data.displayData.isChange === UCOM_ACCOUNT_OPTION_QUERY_TYPE.UPDATE) {
          const updateMessage = await SpfApiAccountOptionChangeAccessor.updateMailAddress(
            Number(this.$data.ucomAccountId),
            Number(this.$data.displayData.mailAddressType),
            this.$data.displayData.mailAddress,
            subscriptionOn
          );
          if (updateMessage) {
            this.$data.errorMessages.push(updateMessage);
          }
        }
        if (this.$data.errorMessages.length > 0) {
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          return;
        }

        // 画面表示値をStoreに保存する
        this.$store.commit('ucomAccountOptionStore/ucomAccountId', this.$data.ucomAccountId);
        this.$store.commit('ucomAccountOptionStore/accountName', this.$data.displayData.accountName);
        this.$store.commit('ucomAccountOptionStore/displayMailAddressType', this.$data.displayData.displayMailAddressType);
        this.$store.commit('ucomAccountOptionStore/mailAddressType', this.$data.displayData.mailAddressType);
        this.$store.commit('ucomAccountOptionStore/mailAddress', this.$data.displayData.mailAddress);
        this.$store.commit('ucomAccountOptionStore/registerSubscriptionOn', this.$data.displayData.registerSubscriptionOn);
        this.$store.commit('ucomAccountOptionStore/isChange', this.$data.displayData.isChange);
        this.$router.push('/ucom/account-option-change/mail-completed').catch((error) => {
          checkRouterError(error);
        });
      } catch (error) {
        // エラー画面に遷移
        this.$router.push('/error');
      } finally {
        // ボタン押下解除
        this.isSubmitting = false;
      }
    },
    formatDate(date: Date): string {
      const y = date.getFullYear();
      const m = ('00' + (date.getMonth() + 1)).slice(-2);
      const d = ('00' + date.getDate()).slice(-2);
      return y + '/' + m + '/' + d;
    },
    async onBack() {
      this.$router.push('/ucom/account-option-change/mail-input').catch((error) => {
        checkRouterError(error);
      });
    },
  },
});
