import { ApiFrontError } from "@/shared/classes/error/api-front-error";
import { DataInconsistencyFrontError } from "@/shared/classes/error/data-inconsistency-front-error";
import { FrontErrorRequest } from "@/shared/classes/spf-api/front-error-request";
import { FRONT_ERROR_TYPE_EXPECTED, FRONT_ERROR_TYPE_GLOBAL_CAPTURE } from "@/shared/const/error/error-handle";
import { SpfApiService } from "@/shared/services/api/spf-api-service";
import { AuthService } from "@/shared/services/auth/auth-service";

/**
 * vueで発生したエラーをバックエンドへ送信するクラス
 */
export class ReportVueErrorHandler {

  /**
   * vueで発生したエラーをバックエンドへ送信する
   * 
   * @param error エラーオブジェクト
   * @param vm vueオブジェクト。画面名取得に使用する
   * @param info 提供されるエラー情報文字列がある場合は使用する
   */
  public static async reportErrorToVueErrorHandler(error: Error, vm: Vue, info?: string): Promise<void> {

    const errorScreenName: string = vm.$router.currentRoute.meta?.title;
    const errorScreenPath: string = vm.$router.currentRoute.path;

    const request = await this.createVueFrontErrorRequest(error, vm, errorScreenName, errorScreenPath, info);
    try {
      await SpfApiService.postReportFrontError(request);
    } catch (error) {
      // 握りつぶす
    }

  }

  /**
   * バックエンドへエラー情報を送信する際のリクエスト情報を作成する
   * 
   * @param error エラーオブジェクト
   * @param vm vueオブジェクト。画面名取得に使用する
   * @param errorScreenName エラーが発生した画面名
   * @param errorScreenPath エラーが発生した画面のパス
   * @param info 提供されるエラー情報文字列がある場合は使用する
   */
  private static async createVueFrontErrorRequest(error: Error, vm: Vue, errorScreenName: string, errorScreenPath: string, info?: string): Promise<FrontErrorRequest> {

    const primaryKeyAuth0 = await AuthService.isLoggedIn() ? await AuthService.getSub() : undefined;

    const errorType = this.getVueErrorType(error);

    const request = new FrontErrorRequest({
      primaryKeyAuth0: primaryKeyAuth0,
      errorObject: JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error))), // エラーオブジェクト出力のための措置
      errorInfo: info,
      errorScreenName: errorScreenName,
      errorScreenPath: errorScreenPath,
      errorType: errorType,
      screenHistory: vm.$store.getters['userScreenTransitionStore/history'],
      userAgent: navigator.userAgent,
    });

    return request;
  }

  /**
   * エラーオブジェクトからエラーの種類を特定する
   * 
   * @param error エラーオブジェクト
   */
  private static getVueErrorType(error: Error): string {

    if (error instanceof DataInconsistencyFrontError) {
      return FRONT_ERROR_TYPE_EXPECTED.DATA_INCONSISTENCY;
    }

    if (error instanceof ApiFrontError) {
      return FRONT_ERROR_TYPE_EXPECTED.API;
    }

    return FRONT_ERROR_TYPE_GLOBAL_CAPTURE.VUE;
  }

}