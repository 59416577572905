




























































































































































































import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { SpfApiAccountOptionChangeAccessor } from '@/infra/accessor/spf/ucom/spf-api-account-option-change-accessor';
import { SpfApiMailAddressAccessor } from '@/infra/accessor/spf/ucom/spf-api-mail-address-accessor';
import { AuthService } from '@/shared/services/auth/auth-service';
import { UCOM_ACCOUNT_OPTION_QUERY_TYPE } from '@/shared/const/ucom/ucom-account-option';

export default Vue.extend({
  name: 'ucom-mail-address-register-input',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },
  data: () => ({
    /** UCOM.上記の内容に同意する */
    isAgreeUcom: false,
    /** 他社.上記の内容に同意する */
    isAgreeOther: false,
    /** ボタンダブルクリック防止用 */
    isSubmitting: false,
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    ucomAccountId: '',
    accountName: '',
    currentMailAddress: '',
    mailAddress: '',
    domainName: '',
    mailAddressType: {
      UCOM: '1',
      OTHER: '2',
    },
    /** 登録変更フラグ */
    isChange: '',
    /** 画面入力値用 */
    inputData: {
      mailAddressType: '',
      mailAddressUcom: '',
      mailAddressOther: '',
    },
    /** 次画面へ渡すデータ */
    confirmData: {
      displayMailAddressType: '',
      mailAddress: '',
    },
    /** UCOM の URL */
    ucomUrl: process.env.VUE_APP_UCOM_UPDATE_MAIL_CAUTION_URL,
    /** 次画面で表示用のメールアドレス種別(UCOM) */
    displayMailAddressUcom: 'UCOM光の提供するメールアドレスを利用する',
    /** 次画面で表示用のメールアドレス種別(他社) */
    displayMailAddressOther: '他社のメールアドレスを利用する',
    checkResult: {
      VALID: '0',
      INVALID: '1',
    },
    errorMessage: {
      AGREE: '注意事項を確認し、同意してください',
      MU00_0002: '「メールアドレス」を入力してください。',
    },
    FROM_LINK: {
      MANAGEMENT_LIST: '/ucom/account-management/list',
      MANAGEMENT_COMPLETED: '/ucom/account-management/completed',
      OPTION_CHANGE_LIST: '/ucom/account-option-change/account-list',
      OPTION_CHANGE_COMFIRM: '/ucom/account-option-change/mail-confirm',
    },
    // 入力値チェック用の配列
    inputErrorMessages: [],
  }),
  computed: {
    buttonColorSet() {
      return {
        'btn btn04 bs': !this.$data.isAgreeUcom && !this.$data.isAgreeOther,
        'btn btn01 bs': this.$data.isAgreeUcom || this.$data.isAgreeOther,
      };
    },
  },
  async mounted(): Promise<void> {
    this.$data.isSubmitting = true;
    try {
      const isLoggedIn = await AuthService.isLoggedIn();
      if (!isLoggedIn) {
        this.$router.push('/').catch((error) => {
          checkRouterError(error);
        });
        return;
      }
    } catch (error) {
      // エラー画面に遷移
      this.$router.push('/error');
      return;
    }

    // Storeから情報を取得する
    const from = this.$store.getters['ucomAccountOptionStore/beforePath'];
    if (from === this.$data.FROM_LINK.MANAGEMENT_LIST) {
      this.$data.accountName = this.$store.getters['ucomAccountManagementStore/accountName'];
      this.$data.ucomAccountId = this.$store.getters['ucomAccountManagementStore/ucomAccountId'];
      this.$data.currentMailAddress = this.$store.getters['ucomAccountManagementStore/currentMailAddress'];
      this.$data.mailAddress = this.$store.getters['ucomAccountManagementStore/mailAddress'];
      this.$data.inputData.mailAddressType = this.$store.getters['ucomAccountManagementStore/mailAddressType'];
      const isUpdate: boolean = this.$store.getters['ucomAccountManagementStore/isUpdate'];
      if (isUpdate === true) {
        this.$data.isChange = UCOM_ACCOUNT_OPTION_QUERY_TYPE.UPDATE;
      }
      if (isUpdate === false) {
        this.$data.isChange = UCOM_ACCOUNT_OPTION_QUERY_TYPE.INSERT;
      }
    }
    if (from === this.$data.FROM_LINK.OPTION_CHANGE_LIST || from === this.$data.FROM_LINK.OPTION_CHANGE_COMFIRM) {
      this.$data.accountName = this.$store.getters['ucomAccountOptionStore/accountName'];
      this.$data.ucomAccountId = this.$store.getters['ucomAccountOptionStore/ucomAccountId'];
      this.$data.currentMailAddress = this.$store.getters['ucomAccountOptionStore/currentMailAddress'];
      this.$data.mailAddress = this.$store.getters['ucomAccountOptionStore/mailAddress'];
      this.$data.inputData.mailAddressType = this.$store.getters['ucomAccountOptionStore/mailAddressType'];
      this.$data.isChange = this.$store.getters['ucomAccountOptionStore/isChange'];
    }

    if (from === this.$data.FROM_LINK.MANAGEMENT_COMPLETED) {
      this.$data.accountName = this.$store.getters['ucomAccountManagementInputStore/accountName'];
      this.$data.ucomAccountId = this.$store.getters['ucomAccountManagementInputStore/ucomAccountId'];
      this.$data.currentMailAddress = this.$store.getters['ucomAccountManagementInputStore/currentMailAddress'];
      this.$data.mailAddress = this.$store.getters['ucomAccountManagementInputStore/mailAddress'];
      this.$data.isChange = this.$store.getters['ucomAccountManagementInputStore/isChange'];
    }

    if (!this.$data.inputData.mailAddressType) {
      this.$data.inputData.mailAddressType = this.$data.mailAddressType.UCOM;
    }

    // 存在しない場合APIでエラーが発生し無限ロードが発生するため回避するための分岐
    if (!this.$data.ucomAccountId) {
      this.$router.push('/ucom/account-option-change/account-list');
    }

    // 確認画面から戻った際に入力した値を表示するようにする、同意部分のみ意図的にチェックを外している
    if (this.$data.mailAddress) {
      if (this.$data.inputData.mailAddressType === this.$data.mailAddressType.UCOM) {
        if (this.$data.mailAddress.split('@').length === 2) {
          this.$data.inputData.mailAddressUcom = this.$data.mailAddress.split('@')[0];
        }
      }
      if (this.$data.inputData.mailAddressType === this.$data.mailAddressType.OTHER) {
        this.$data.inputData.mailAddressOther = this.$data.mailAddress;
      }
    }
    // ドメイン取得(メールアドレス登録_初期表示API)
    try {
      const getUcomDomainResult = await SpfApiAccountOptionChangeAccessor.getUcomDomain(this.$data.ucomAccountId);
      this.$data.domainName = getUcomDomainResult.domainName;
    } catch (error) {
      await this.$router.push('/error');
    }

    this.$data.isSubmitting = false;
  },
  methods: {
    async onNext() {
      // ボタン押下中は何もしない
      if (this.$data.isSubmitting) return;

      // ボタン押下中とする
      this.$data.isSubmitting = true;

      try {
        // エラーメッセージを保存していた配列の初期化
        this.$data.errorMessages = [];
        this.$data.inputErrorMessages = [];

        // 入力値チェック
        if (this.$data.inputData.mailAddressType === this.$data.mailAddressType.UCOM) {
          this.$data.inputErrorMessages = await this.inputCheckUcom(this.$data.inputData.mailAddressType, this.$data.inputData.mailAddressUcom, this.$data.isAgreeUcom);
        }
        if (this.$data.inputData.mailAddressType === this.$data.mailAddressType.OTHER) {
          this.$data.inputErrorMessages = await this.inputCheckOther(this.$data.inputData.mailAddressType, this.$data.inputData.mailAddressOther, this.$data.isAgreeOther);
        }
        this.$data.inputErrorMessages.map((inputErrorMessage: string) => this.$data.errorMessages.push(inputErrorMessage));
        if (this.$data.errorMessages.length > 0) {
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          return;
        }

        if (this.$data.inputData.mailAddressType == this.$data.mailAddressType.UCOM) {
          this.$data.confirmData.displayMailAddressType = this.$data.displayMailAddressUcom;
          this.$data.confirmData.mailAddress = `${this.$data.inputData.mailAddressUcom}@${this.$data.domainName}`;
        }
        if (this.$data.inputData.mailAddressType == this.$data.mailAddressType.OTHER) {
          this.$data.confirmData.displayMailAddressType = this.$data.displayMailAddressOther;
          this.$data.confirmData.mailAddress = this.$data.inputData.mailAddressOther;
        }
        // 画面入力値をStoreに保存する
        this.$store.commit('ucomAccountOptionStore/accountName', this.$data.accountName);
        this.$store.commit('ucomAccountOptionStore/ucomAccountId', this.$data.ucomAccountId);
        this.$store.commit('ucomAccountOptionStore/displayMailAddressType', this.$data.confirmData.displayMailAddressType);
        this.$store.commit('ucomAccountOptionStore/mailAddressType', this.$data.inputData.mailAddressType);
        this.$store.commit('ucomAccountOptionStore/mailAddress', this.$data.confirmData.mailAddress);
        this.$store.commit('ucomAccountOptionStore/isChange', this.$data.isChange);
        this.$store.commit('ucomAccountOptionStore/currentMailAddress', this.$data.currentMailAddress);
        // 確認画面に遷移
        this.$router.push('/ucom/account-option-change/mail-confirm').catch((error) => {
          checkRouterError(error);
        });
      } catch (error) {
        // エラー画面に遷移
        this.$router.push('/error');
      } finally {
        // ボタン押下解除
        this.$data.isSubmitting = false;
      }
    },
    async inputCheckUcom(mailAddressType: string, mailAddress: string, isAgree: boolean): Promise<string[]> {
      const errorMessages: string[] = [];

      if (!isAgree) {
        errorMessages.push(this.$data.errorMessage.AGREE);
      }
      const checkMailAddress = `${mailAddress}@${this.$data.domainName}`;
      const checkMailResult = await SpfApiMailAddressAccessor.checkMailAddress(checkMailAddress, mailAddressType);
      if (checkMailResult.checkResult === this.$data.checkResult.INVALID) {
        checkMailResult.checkMessages.map((errorMessage) => errorMessages.push(errorMessage));
      }
      return errorMessages;
    },
    async inputCheckOther(mailAddressType: string, mailAddress: string, isAgree: boolean): Promise<string[]> {
      const errorMessages: string[] = [];

      if (!mailAddress) {
        errorMessages.push(this.$data.errorMessage.MU00_0002);
        return errorMessages;
      }
      if (!isAgree) {
        errorMessages.push(this.$data.errorMessage.AGREE);
      }
      const checkMailResult = await SpfApiMailAddressAccessor.checkMailAddress(mailAddress, mailAddressType);
      if (checkMailResult.checkResult === this.$data.checkResult.INVALID) {
        checkMailResult.checkMessages.map((errorMessage) => errorMessages.push(errorMessage));
      }
      return errorMessages;
    },
    // ページTOPへ
    scrollPageTop() {
      window.scrollTo(0, 0);
    },
  },
});
