










































































import Vue from 'vue';

/** エラーメッセージ用コンポーネント */
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { Property } from '@/shared/classes/spf-api/property';
import { AuthService } from '@/shared/services/auth/auth-service';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/five-a/customer-dto';
import { Member } from '@/shared/classes/spf-api/member';
import { FiveASharedErrorResponse } from '@/shared/classes/external-api/five-a/shared-error-response';
import { FiveAProperty } from '@/shared/classes/external-api/five-a/property-response';
import { FiveACustomer } from '@/shared/classes/external-api/five-a/customer-response';
import { ISP_MEMBER_STATUS, UaTypeValue, UA_TYPE } from '@/shared/const/service-type';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { GetOemsMasterResponse } from '@/shared/classes/spf-api/oems';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { getIspMemberStatusFiveA } from '@/shared/util/func-get-isp-member-status';

export default Vue.extend({
  name: 'five-a-connectix-terms',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    LoadingComponent
  },
  data: () => ({
    /** 物件ID */
    propertyId: '',
    /** 規約に同意する */
    isAgreeTerms: false,
    /** サービス利用規約に同意するチェック、お申し込みボタン 表示/非表示 */
    availableApply: true,
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、お申し込み手続きを進めることができません。',
    /** エラーメッセージを格納する配列 */
    errorMessages: [],
    /** データ読込完了 */
    isMounted: false,
    /** サービス規約URL */
    serviceTermsUrl: process.env.VUE_APP_FIVE_A_CONNECTIX_SERVICE_TERMS_URL,
    /** APID */
    apid: '',
    // onApplication()実行中かどうか
    isExecutingOnApplication: false as boolean,
    // キャンペーンメッセージ用
    service: 'Connectix',
    /**ドメイン名 */
    domainName: '',
    /**建物ID_8桁 */
    apartmentId8Digit: null,
    // oemsMaster用
    oemsMaster: [],
    /* 滞納中のエラーメッセージのstyle */
    tainoErrorMessageStyles: {
      color: "",
    },
    // ISP(Five.A)会員ステータス
    ispMemberStatusFiveA: '',
    // Connectixエラーを1文エラーにするかどうか
    isErrorMessageOneSentence: false
  }),
  computed: {
    isAgreed() {
      return this.$data.isAgreeTerms;
    },
    buttonColorSet() {
      if (this.availableApply && this.isAgreeTerms) {
        return 'btn btn01 bs'
      }
      return 'btn btn04 bs';
    },
    activeApplyButton() {
      if (this.availableApply) {
        if (this.isAgreeTerms || this.errorMessages.length != 0 || !this.isMounted) {
          return true;
        }
      }
      return false;
    },
    /**9桁のランダムな数字を生成 */
    apid() {
      if (this.$data.fiveAProperty) {
        return this.$data.fiveAProperty.IN_APID;
      } else {
        return '';
      }
    }
  },
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    // 入力値を保持しているストアの削除
    this.$store.commit('fiveAConnectixEntryStore/entryInputForm', null);

    /** Five.Aの会員であるかの確認 */
    try {

      /** ログインしていない状態で遷移した場合は「総合トップ」画面にリダイレクトする */
      const isLoggedIn = await AuthService.isLoggedIn();
      if (!isLoggedIn) {
        this.$router.push('/').catch(error => {checkRouterError(error)});
        return;
      }

      const isFiveAMember: boolean = await AuthService.isLinkedToFiveA();
      if (!isFiveAMember) {
        this.$router.push('/').catch(error => {checkRouterError(error)});
        return;
      }
    } catch (error) {
      // データ不整合エラー
      throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.AUTH0);
    }

    /** 顧客の物件がConnectixお申し込み可能な物件であるかの確認 */
    try {
      // 会員情報をStoreから取得
      const member: Member = this.$store.getters['memberStore/member'];
      // 物件情報をStoreから取得
      const property: Property = this.$store.getters['propertyStore/property'];
      const uaType: UaTypeValue | null = property.uaType ?? null;
      const propertyId: string | null = property.apartmentId ?? null;

      // 物件テーブルに「Mye物件IDが空文字」OR「UA種別が Null」の場合は e-mansion の外部 API の情報が取得できないためエラー表示
      if (!uaType || !propertyId) {
        this.$data.availableApply = false; // 利用規約同意チェックとお申し込みボタン使用不可
        this.$data.errorMessages.push('Connectix のお申し込みに必要な情報が取得できませんでした。');
        return;
      }

      /** e-mansion 契約基本情報 API を取得してConnectix未契約であることを確認 */
      const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
        ua_type: uaType,
        property_id: propertyId
      });

      // five.A のメンバーID
      const fiveAMemberId = member.primaryKeyMye ?? '';

      // 契約基本情報を Store から取得
      const fiveACustomer: FiveACustomer | FiveASharedErrorResponse = await this.$store.dispatch('fiveACommonStore/customer', {
        memberId: fiveAMemberId,
        query: findCustomerByIdQueryDto
      });

      if (fiveACustomer instanceof FiveACustomer) {

        // ISP会員ステータス取得
        const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
        this.ispMemberStatusFiveA = getIspMemberStatusFiveA(memberStatus, fiveACustomer);

        /**
         * ISP(Five.A)会員ステータスが次の2つの場合、エラーメッセージを表示する
         *
         * 1. ISP(Five.A)会員ステータス: 退会申し込み
         * 2. ISP(Five.A)会員ステータス: 退会済みログイン可能
         */
        if (this.ispMemberStatusFiveA === ISP_MEMBER_STATUS.FIVE_A.IN_CANCEL_APPLICATION
            || this.ispMemberStatusFiveA === ISP_MEMBER_STATUS.FIVE_A.CANCEL_AND_LOGIN_OK) {

          this.$data.availableApply = false; // 利用規約同意チェックとお申し込みボタン使用不可
          this.isErrorMessageOneSentence = true;
          this.$data.errorMessages.push('Connectixのお申し込みは現在承ることができません。');
          window.scrollTo(0, 0);
          return;
        }

        if (fiveACustomer.op_connectix_contract_status === '1') {
          this.$data.availableApply = false; // 利用規約同意チェックとお申し込みボタン使用不可
          // 既にConnectix契約済みであれば、エラーメッセージ表示
          this.$data.errorMessages.push('お客様は既にConnectixを契約しています。');
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          return;
        }
        // 正常ケースの場合
        this.propertyId = fiveACustomer.property_id;
      } else {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.E_MANSION.NO_CUSTOMER_INFO);
      }

      /*ドメイン名の取得 */
      if (property.oemType) {
        this.oemsMaster = await this.$store.dispatch('oemsStore/oems');
        const filtered: GetOemsMasterResponse[] = this.oemsMaster.filter((oem: GetOemsMasterResponse) => oem.oemType === String(property.oemType));
        if (filtered.length >= 1) {
          let latestOem = filtered[0];
          if (filtered.length >= 2) {
            for (let i = 1; i < filtered.length; i++) {
              if (latestOem.startAt < filtered[i].startAt) {
                latestOem = filtered[i];
              }
            }
          }
          this.domainName = latestOem.domainForOsumai;
        }
      } else {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.PROPERTY_NO_OEM_TYPE);
      }

      // 料金滞納チェック
      if (fiveACustomer instanceof FiveACustomer) {
        if (fiveACustomer.defaulter_status === "1" || fiveACustomer.defaulter_status === "2") {
          // 料金滞納中であれば、エラーメッセージ表示          
          let apid = "";
          if (property) {
            apid = convert6DigitIdTo8DigitId(String(property.apartmentId));
          }

          this.$data.availableApply = false; // 利用規約同意チェックとお申し込みボタン使用不可
          const url = this.domainName + "/" + apid;
          this.$data.errorMessages.push('ご登録済みのお支払い方法ではお申し込みを承ることができません。大変恐れ入りますが、<a href="' + url + '"class="link" target="My e-mansion">お住まいの建物専用ホームページ</a>からお問い合わせください。');
          // エラーメッセージタイトルを非表示
          this.errorMessageTitle = '';
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          // エラーメッセージを赤色に変更
          this.tainoErrorMessageStyles = {
            color: "red"
          };
          this.$data.isLoading = false;
          return;
        } else if (!fiveACustomer.defaulter_status || fiveACustomer.defaulter_status !== "0") {
          // データ不整合エラー
          throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.E_MANSION.CUSTOMER_INFO_API);
        }
        // 正常ケースの場合
        this.$data.eMansionPropertyId = fiveACustomer.property_id;
      } else {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.E_MANSION.NO_CUSTOMER_INFO);
      }

      // five-a 物件基本情報を Store から取得
      const propertyParams = { propertyId: property.apartmentId, uaType: UA_TYPE.FIVE_A };
      const fiveAProperty: FiveAProperty | FiveASharedErrorResponse = await this.$store.dispatch('fiveACommonStore/property', propertyParams);
      if (fiveAProperty instanceof FiveAProperty) {
        this.apid = fiveAProperty.IN_APID;
      } else {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.E_MANSION.NO_PROPERTY_INFO);
      }
    } catch (error) {
      throw error;
    } finally {
      this.isMounted = true;
    }
  },
  methods: {
    async onApplication() {
      if (this.isExecutingOnApplication) {
        return;
      }
      this.isExecutingOnApplication = true;

      this.$data.errorMessages = [];
      this.isErrorMessageOneSentence = false;

      // ISP(Five.A)側で退会申し込み中のとき、エラーメッセージを表示させる
      if (await this.isInCancelApplication()) {
        this.isErrorMessageOneSentence = true;
        this.$data.errorMessages.push('Connectixのお申し込みは現在承ることができません。');
        this.isExecutingOnApplication = false;
        window.scrollTo(0, 0);
        return;
      }

      // 同意チェックボックスにチェックされており、かつエラーメッセージがない場合はお申し込み画面に遷移する。
      if (this.isAgreeTerms && !this.errorMessages.length) {
        this.$router.push('/connectix/5a/apply').catch(error => {checkRouterError(error)}).finally( () => {
          this.isExecutingOnApplication = false;
        });
      } else {
        this.isExecutingOnApplication = false;
      }
    },
    async isInCancelApplication() {
      try {
        const property: Property = this.$store.getters['propertyStore/property'];

        const findCustomerByIdQueryDto = new FindCustomerByIdQueryDto({
          ua_type: property.uaType,
          property_id: property.apartmentId
        });

        const member: Member = this.$store.getters['memberStore/member'];
        const fiveAMemberId = member.primaryKeyMye ?? '';

        const fiveACustomer: FiveACustomer | FiveASharedErrorResponse = await this.$store.dispatch('fiveACommonStore/customer', {
          memberId: fiveAMemberId,
          query: findCustomerByIdQueryDto
        });

        if (fiveACustomer instanceof FiveACustomer) {

          // ISP会員ステータス取得
          const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
          this.ispMemberStatusFiveA = getIspMemberStatusFiveA(memberStatus, fiveACustomer);

          /**
           * ISP(Five.A)会員ステータスが次の2つの場合、ISP退会申し込み中であると判定する
           *
           * 1. ISP(Five.A)会員ステータス: 退会申し込み
           * 2. ISP(Five.A)会員ステータス: 退会済みログイン可能
           */
          if (this.ispMemberStatusFiveA === ISP_MEMBER_STATUS.FIVE_A.IN_CANCEL_APPLICATION
              || this.ispMemberStatusFiveA === ISP_MEMBER_STATUS.FIVE_A.CANCEL_AND_LOGIN_OK) {
            return true;
          }
        } else {
          // データ不整合エラー
          throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.E_MANSION.NO_CUSTOMER_INFO);
        }
        return false;
      } catch (e) {
        throw e;
      }
    }
  }
});
