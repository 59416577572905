




















import Vue from 'vue';

/** エラーメッセージ表示エリアコンポーネント */
export default Vue.extend({
  name: 'error-messages-inquiry-component',
  // 親コンポーネントから受け取るデータ
  props: {
    /** エラーメッセージ部に表示するタイトル */
    errorMessageInquiry: {
      type: String,
      default: ''
    },
    /** 表示したいエラーメッセージを格納した配列 */
    errorMessagesInquiry: {
      type: Array,
      default: Array
    }
  }
});
