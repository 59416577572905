


































































import dayjs from 'dayjs';
import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';

export default Vue.extend({
  name: 'virus-check-cancel-complete',
  components: {
    LoadingComponent,
  },

  data(): {
    mailAddress: string | undefined;
    cancelOn: string | undefined;
  } {
    return {
      mailAddress: undefined,
      cancelOn: undefined,
    };
  },

  mounted() {
    // 前画面で保存したメールアドレスとサービス終了日を受け取る
    this.mailAddress = this.$store.getters['eMansionMailStore/selectedMailAddress'];
    if (!this.mailAddress) {
      throw new Error();
    }
    const cancelOn = this.$store.getters['eMansionMailStore/selectedCancelOn'];
    if (!cancelOn) {
      throw new Error();
    }
    this.cancelOn = this.formatDateToYyyymmdd(cancelOn);

    // 前画面に戻れないようにストアをクリアしておく
    this.$store.commit('eMansionMailStore/setSelectedMailAddressId', null);
    this.$store.commit('eMansionMailStore/setSelectedMailAddress', null);
    this.$store.commit('eMansionMailStore/setSelectedMailAddress', null);
    this.$store.commit('eMansionMailStore/setSelectedCancelOn', null);
  },
  methods: {
    /**
     * 「ご利用状況確認」のクリックハンドラ
     */
    goToUsage(): void {
      this.$router.push('/e-mansion/mail/usage');
    },

    /**
     * 「e-mansion 会員専用トップページへ」のクリックハンドラ
     */
    goToTop(): void {
      this.$router.push('/e-mansion');
    },

    /**
     * Date 型を YYYY/MM/DD に変換する
     * @param date 変換元の日時
     * @return 変換先の文字列
     */
    formatDateToYyyymmdd(date: Date | null): string {
      return date ? dayjs(date).format('YYYY/MM/DD') : '';
    },
  }, // methods

  computed: {
    /** ローディング中かどうかの判定 */
    isLoading(): boolean {
      return false;
    }, // isLoading
  }, // computed
});
