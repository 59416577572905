































































import { Member } from '@/shared/classes/spf-api/member';
import { Property } from '@/shared/classes/spf-api/property';
import { UA_TYPE } from '@/shared/const/service-type';
import { AuthService } from '@/shared/services/auth/auth-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import Vue from 'vue';

/** LinkService コンポーネント */
export default Vue.extend({
  name: 'left-menu-common-link',
  props: [
    'isLinkedEMansion',
    'isLinkedUcom',
    'isLinkedMcloud',
    'isUcomOrEmansionToppage',
    'isLinkedFiveA'
  ],
  methods: {
    onContacts(){
      this.$router.push('/platform/contacts').catch(error => {checkRouterError(error)});
      return this.$emit('clickContacts', false);
    },
  },
});
