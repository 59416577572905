import { UserScreenTransitionInfo } from '@/shared/classes/user/user-screen-transition';
import store from '@/store';
import { Route } from 'vue-router';

export class UserScreenTransitionHistoryWithStore {
  public static addHistory(to: Route, from: Route) {
    store.dispatch('userScreenTransitionStore/addHistory', new UserScreenTransitionInfo({
      toPath: to.path,
      toScreenName: to.meta?.title,
      fromPath: from.path,
      fromScreenName: from.meta?.title,
    }));
  }
}