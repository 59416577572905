import { Module } from 'vuex';
import { RootState } from './index';

/** 消費税 State */
const taxState: {
  tax: string | null;
} = {
  tax: '10',
};

/** State の型 */
type TaxState = typeof taxState;

/** 消費税 Store */
export const taxStore: Module<TaxState, RootState> = {
  namespaced: true,
  state: taxState,
  mutations: {
    tax: (state, value: string) => (state.tax = value),
  },
  getters: {
    tax: (state): string | null => state.tax,
  },
  
};