import { Module } from 'vuex';
import { RootState } from '../index';

/** UCOM メールボックス容量 State */
const UcomMailboxCapacityState: {
  ucomAccountMailId: number | undefined;
  accountName: string | undefined;
  mailAddress: string | undefined;
  mailBoxCapacity: number | undefined;
  changeCapacityLabel: string | undefined;
  changeCapacityValue: string | undefined;
  price: number | undefined;
  effectiveDate: Date | undefined; // 対象日
  serviceDescription: string | undefined;
  isRegisteredPayment: string | undefined;
  subscriptionDate: Date | undefined; // お申込日
  notifyMailAddress: string | undefined; // 通知先メールアドレス
} = {
  ucomAccountMailId: undefined,
  accountName: undefined,
  mailAddress: undefined,
  mailBoxCapacity: undefined,
  changeCapacityLabel: undefined,
  changeCapacityValue: undefined,
  price: undefined,
  effectiveDate: undefined,
  serviceDescription: undefined,
  isRegisteredPayment: undefined,
  subscriptionDate: undefined,
  notifyMailAddress: undefined,
};

/** State の型 */
type UcomMailboxCapacityState = typeof UcomMailboxCapacityState;

/** UCOM メールボックス容量 Store */
export const ucomMailboxCapacityStore: Module<UcomMailboxCapacityState, RootState> = {
  namespaced: true,
  state: UcomMailboxCapacityState,
  actions: {},
  mutations: {
    ucomAccountMailId: (state, value: number) => (state.ucomAccountMailId = value),
    accountName: (state, value: string) => (state.accountName = value),
    mailAddress: (state, value: string) => (state.mailAddress = value),
    mailBoxCapacity: (state, value: number) => (state.mailBoxCapacity = value),
    changeCapacityLabel: (state, value: string) => (state.changeCapacityLabel = value),
    changeCapacityValue: (state, value: string) => (state.changeCapacityValue = value),
    price: (state, value: number) => (state.price = value),
    effectiveDate: (state, value: Date) => (state.effectiveDate = value),
    serviceDescription: (state, value: string) => (state.serviceDescription = value),
    isRegisteredPayment: (state, value: string) => (state.isRegisteredPayment = value),
    subscriptionDate: (state, value: Date) => (state.subscriptionDate = value),
    notifyMailAddress: (state, value: string) => (state.notifyMailAddress = value),
  },
  getters: {
    ucomAccountMailId: (state): number | undefined => state.ucomAccountMailId,
    accountName: (state): string | undefined => state.accountName,
    mailAddress: (state): string | undefined => state.mailAddress,
    mailBoxCapacity: (state): number | undefined => state.mailBoxCapacity,
    changeCapacityLabel: (state): string | undefined => state.changeCapacityLabel,
    changeCapacityValue: (state): string | undefined => state.changeCapacityValue,
    price: (state): number | undefined => state.price,
    effectiveDate: (state): Date | undefined => state.effectiveDate,
    serviceDescription: (state): string | undefined => state.serviceDescription,
    isRegisteredPayment: (state): string | undefined => state.isRegisteredPayment,
    subscriptionDate: (state): Date | undefined => state.subscriptionDate,
    notifyMailAddress: (state): string | undefined => state.notifyMailAddress,
  },
};
