






























































































































































































































import Vue from 'vue';
import { AuthService } from '@/shared/services/auth/auth-service';
import { FiveAConnectixFee, FiveAEntryInputForm } from '../entry/classes/entry-input-form';
import { Property } from '@/shared/classes/spf-api/property';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';

/** Completed コンポーネント */
export default Vue.extend({
  name: 'completed',
  components: {
    LoadingComponent
  },
  data: () => ({
    customer: null as FiveAEntryInputForm | null,
    member: null,
    /** 性別（表示用） */
    sex: '' as string,
    /** Connectix 料金 */
    fiveAConnectixFee: null as FiveAConnectixFee | null,
    /** 建物名 */
    propertyName: '' as string,
    /** 生年月日（表示用） */
    dispBirthday: '',
    /** 物件ID */
    apartmentId: '',
    // mounted()実行中かどうか
    isMounted: false as boolean,
    // navigateToLocation()実行中かどうか
    isExecutingNavigateToLocation: false as boolean
  }),
  async mounted() {
    try {
      /** ログインしているか否かの情報を取得 */
      const isLoggedIn = await AuthService.isLoggedIn();
      // ログインしていない場合「総合トップ」画面にリダイレクトする
      if (!isLoggedIn) {
        this.$router.push('/').catch(error => {checkRouterError(error)});
        return;
      }
      // 会員情報取得処理
      this.$data.member = this.$store.getters['memberStore/member'];
      if (!this.$data.member || !this.$data.member.propertyId) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.DATA_INCONSISTENT);
      }

      // 会員情報取得処理
      const customer: FiveAEntryInputForm = this.$store.getters['fiveAEntryStore/entryInputForm'];
      // customer情報が無い場合、会員規約の同意なしとしてエラー画面に遷移する
      if (!customer) {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_INPUT_DATA);
      }
      this.customer = customer;
      this.sex = this.getGender(customer.sex);
      this.$data.dispBirthday = this.getBirthday(customer.birthday);

      // 物件情報の取得
      const property: Property | null = this.$store.getters['propertyStore/property'];
      if (property) {
        this.propertyName = property.dispApartmentName;
        this.$data.apartmentId = property.apartmentId;
      } else {
        // データ不整合エラー
        throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_PROPERTY);
      }

      // Store から Connectix 料金を取得
      this.fiveAConnectixFee = this.$store.getters['fiveAEntryStore/fiveAConnectixFee'];
    } catch (e) {
      throw e;
    } finally {
      this.isMounted = true;
    }
  },
  methods: {
    /** 表示用性別を取得する */
    getGender(sex: string) {
      if (sex === '1') {
        return '男性';
      } else if (sex === '2') {
        return '女性';
      } else {
        return '';
      }
    },
    /** 表示用生年月日を取得する */
    getBirthday(birthday: string) {
      const date = birthday.split('/');
      const birthdayYear = Number(date[0]);
      const birthdayMonth = Number(date[1]);
      const birthdayDate = Number(date[2]);
      return (String(birthdayYear) + '年 ' + String(birthdayMonth) + '月 ' + String(birthdayDate) + '日');
    },
    navigateToLocation(path: string) {
      if (this.isExecutingNavigateToLocation) {
        return;
      }
      this.isExecutingNavigateToLocation = true;

      this.$router.push(path).catch(error => {checkRouterError(error)}).finally( () => {
        this.isExecutingNavigateToLocation = false;
      });
    }
  }
});
