import { FrontErrorRequest } from "@/shared/classes/spf-api/front-error-request";
import { FRONT_ERROR_TYPE_GLOBAL_CAPTURE } from "@/shared/const/error/error-handle";
import { SpfApiService } from "@/shared/services/api/spf-api-service";
import { AuthService } from "@/shared/services/auth/auth-service";

export class ReportUnexpectedErrorHandler {

  public static async reportErrorToUnexpectedErrorHandler(error: Error, vm: Vue, info?: string): Promise<void> {

    const errorScreenName: string = vm.$router.currentRoute.meta?.title;
    const errorScreenPath: string = vm.$router.currentRoute.path;

    const request = await this.createUnexpectedFrontErrorRequest(error, vm, errorScreenName, errorScreenPath, info);
    try {
      await SpfApiService.postReportFrontError(request);
    } catch (error) {
      // 握りつぶす
    }

  }

  private static async createUnexpectedFrontErrorRequest(error: Error, vm: Vue, errorScreenName: string, errorScreenPath: string, info?: string): Promise<FrontErrorRequest> {

    const primaryKeyAuth0 = await AuthService.isLoggedIn() ? await AuthService.getSub() : undefined;

    const errorType = FRONT_ERROR_TYPE_GLOBAL_CAPTURE.UNEXPECTED;

    const request = new FrontErrorRequest({
      primaryKeyAuth0: primaryKeyAuth0,
      errorObject: JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error))), // エラーオブジェクト出力のための措置
      errorInfo: info,
      errorScreenName: errorScreenName,
      errorScreenPath: errorScreenPath,
      errorType: errorType,
      screenHistory: vm.$store.getters['userScreenTransitionStore/history'],
      userAgent: navigator.userAgent,
    });

    return request;
  }

}