import { ReportVueErrorHandler } from "./reporter/report-vue-error-handler";
import { RevisionHandler } from "./revision/revision-handler";

export class VueErrorHandler {

  public static async handle(error: Error, vm: Vue, info: string) {
    try {

      const isRevisionError = await RevisionHandler.checkRevisionError(error, vm);

      if (isRevisionError) {
        // リビジョンエラーは何もしない
        return;
      }

      this.report(error, vm, info);
      await vm.$router.push('/error');
    } catch (error) {
      // 握りつぶす
    }
  }

  private static async report(error: Error, vm: Vue, info: string) {

    // Vueが検知したバックエンドへ送るべきエラーは全てここで吸収する
    ReportVueErrorHandler.reportErrorToVueErrorHandler(error, vm, info);

  }
}