import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
import Vuelidate from 'vuelidate';

import router from './router';
import store from './store';

// 以下service内でstoreを使用する際に必要な措置
import { injectStore } from './shared/services/external-api/base-external-api-service';
injectStore(store);

import App from './app.vue';
import { VueErrorHandler } from './handler/error/vue-error-handler';
import { UnexpectedErrorHandler } from './handler/error/unexpected-error-handler';
import { UnhandledredrejectionErrorHandler } from './handler/error/unhandledrejection-error-handler';

Vue.config.productionTip = false;
Vue.config.errorHandler = async function (error: Error, vm: Vue, info: string) {

  // 呼び出し先にお任せする
  await VueErrorHandler.handle(error, vm, info);

}

window.addEventListener("error", async event => {

  // 呼び出し先にお任せする
  await UnexpectedErrorHandler.handle(event.error, vm);

});

window.addEventListener("unhandledrejection", async event => {

  // 呼び出し先にお任せする
  await UnhandledredrejectionErrorHandler.handle(new Error(event.reason), vm);

});

Vue.use(VueScrollTo);
Vue.use(Vuelidate);

const vm = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

export default vm;
