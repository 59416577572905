






































































































































































import Vue from 'vue';
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import { Swiper as SwiperClass, Navigation, Pagination, Autoplay } from 'swiper/core';
import 'swiper/swiper-bundle.min.css';
import '../../styles/swiper-style.css';
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);

SwiperClass.use([Navigation, Pagination, Autoplay]);

export default Vue.extend({
  name: 'pickup-swiper-component',
  components: {
    Swiper,
    SwiperSlide
  },
  data: () => ({
    pickupSwiperOptions: {
      navigation: {
        nextEl: '.pickup-swiper-button-next',
        prevEl: '.pickup-swiper-button-prev'
      },
      slidesPerView: 'auto',
      spaceBetween: 40,
      preloadImages: true,
      updateOnImagesReady: true
    }
  })
});
