

























































































import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { ObfuscationService } from '@/shared/services/obfuscation/obfuscation-service';
import { UCOM_ACCOUNT_OPTION_QUERY_TYPE } from '@/shared/const/ucom/ucom-account-option';

export default Vue.extend({
  name: 'account-management-completed',
  components: {
    LoadingComponent,
  },
  data: () => ({
    isLoading: false, // ローディング
    UNREGISTERED: '未登録',
    isChange: UCOM_ACCOUNT_OPTION_QUERY_TYPE.INSERT,

    /** 各種パラメータ */
    ucomAccountId: '', // UCOM_アカウントID
    accountName: '', // アカウント名
    familyName: '', // 姓
    firstName: '', // 名
    familyNameKana: '', // 姓カナ
    firstNameKana: '', // 名カナ
    userName: '', // ユーザーネーム
    password: '', // パスワード
    accountAuthorization: '', // 表示用UCOM_アカウント権限
    isMailAddressRegistration: '', // メールアドレス登録有無
  }),
  async mounted() {
    try {
      this.$data.isLoading = true;

      // URL直叩きの場合の画面遷移
      const ucomAccountId = await this.$store.getters['ucomAccountManagementInputStore/ucomAccountId'];
      if (!ucomAccountId) {
        await this.$router.push('/ucom/account-management/list');
        return;
      }

      // 前画面(アカウントID管理：登録・変更 確認)からの情報をStoreから取得
      this.setDataByStore();

      // store内のデータをクリア
      this.deleteStore();

      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/error');
      return;
    }
  },

  methods: {
    // storeからデータをセット
    setDataByStore(): void {
      this.$data.ucomAccountId = this.$store.getters['ucomAccountManagementInputStore/ucomAccountId'];
      this.$data.accountName = this.$store.getters['ucomAccountManagementInputStore/accountName'];
      this.$data.familyName = this.$store.getters['ucomAccountManagementInputStore/familyName'];
      this.$data.firstName = this.$store.getters['ucomAccountManagementInputStore/firstName'];
      this.$data.familyNameKana = this.$store.getters['ucomAccountManagementInputStore/familyNameKana'];
      this.$data.firstNameKana = this.$store.getters['ucomAccountManagementInputStore/firstNameKana'];
      this.$data.userName = this.$store.getters['ucomAccountManagementInputStore/userName'];
      this.$data.password = ObfuscationService.decode(this.$store.getters['ucomAccountManagementInputStore/password']);
      this.$data.accountAuthorization = this.$store.getters['ucomAccountManagementInputStore/accountAuthorization'];
      this.$data.isMailAddressRegistration = this.$store.getters['ucomAccountManagementInputStore/isMailAddressRegistration'];
    },

    // store内のデータをクリア
    deleteStore(): void {
      this.$store.commit('ucomAccountManagementInputStore/ucomAccountId', undefined);
      this.$store.commit('ucomAccountManagementInputStore/accountName', undefined);
      this.$store.commit('ucomAccountManagementInputStore/familyName', undefined);
      this.$store.commit('ucomAccountManagementInputStore/firstName', undefined);
      this.$store.commit('ucomAccountManagementInputStore/familyNameKana', undefined);
      this.$store.commit('ucomAccountManagementInputStore/firstNameKana', undefined);
      this.$store.commit('ucomAccountManagementInputStore/userName', undefined);
      this.$store.commit('ucomAccountManagementInputStore/password', undefined);
      this.$store.commit('ucomAccountManagementInputStore/accountAuthorization', undefined);
      this.$store.commit('ucomAccountManagementInputStore/isMailAddressRegistration', undefined);
      this.$store.commit('ucomAccountManagementInputStore/currentMailAddress', undefined);
      this.$store.commit('ucomAccountManagementInputStore/mailAddress', undefined);
      this.$store.commit('ucomAccountManagementInputStore/isChange', undefined);
    },

    // 「UCOM光レジデンス会員専用トップ」ボタンクリック
    async onUcomTop(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      // データの変更は無いのでそのまま入力画面へ遷移
      await this.$router.push('/ucom');

      this.$data.isLoading = false;
    },

    // アカウントIDオプション変更：メールアドレス登録・変更 入力画面に遷移
    async mailAddressRegisterScreenMove() {
      if (this.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      // 次画面に データを渡す
      this.$store.commit('ucomAccountManagementInputStore/ucomAccountId', this.$data.ucomAccountId);
      this.$store.commit('ucomAccountManagementInputStore/currentMailAddress', this.$data.UNREGISTERED);
      this.$store.commit('ucomAccountManagementInputStore/mailAddress', '');
      this.$store.commit('ucomAccountManagementInputStore/isChange', this.$data.isChange);
      this.$store.commit('ucomAccountManagementInputStore/accountName', this.$data.accountName);

      await this.$router.push('/ucom/account-option-change/mail-input');

      this.$data.isLoading = false;
    },

    // ページTOPへ
    scrollPageTop() {
      window.scrollTo(0, 0);
    },
  },
});
