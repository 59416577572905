import { BaseExternalApiService } from '@/shared/services/external-api/base-external-api-service';
import axios from 'axios';

import { UcomConnectixErrorResponse } from '@/pages/connectix/ucom/classes/external-api/connectix-error-response';
import { UcomConnectixNewConnectixRequest } from '@/pages/connectix/ucom/classes/external-api/new-connectix-request';
import { UcomConnectixPropertyRoomsResponseResult, UcomConnectixPropertyRoomsResponse } from '@/pages/connectix/ucom/classes/external-api/property-rooms-response';

const api = BaseExternalApiService.api(0);

/** UCOM Connectix 外部 API をコールするサービス */
export class UcomConnectixExternalApiService {
  /**
   * Connectix お申し込み
   *
   * @param ucomConnectixNewConnectixRequest Connectix お申し込み情報
   * @return 正常時は返却値無し
   */
  public static async createConnectix(ucomConnectixNewConnectixRequest: UcomConnectixNewConnectixRequest): Promise<undefined | UcomConnectixErrorResponse> {
    try {
      await api.post('/connectix', { request: ucomConnectixNewConnectixRequest });
      return;
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * Connectix お申し込み可能な部屋番号一覧
   *
   * @param id 部屋番号
   * @return Connectix お申し込み可能な部屋番号一覧
   */
  public static async findPropertyRooms(id: string): Promise<UcomConnectixPropertyRoomsResponse | UcomConnectixErrorResponse> {
    try {
      const response = await api.get<UcomConnectixPropertyRoomsResponseResult>(`/connectix/rooms/${id}`);
      return new UcomConnectixPropertyRoomsResponse(response.data.result);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * API 通信エラー時のハンドリング
   *
   * @param error エラーオブジェクト
   * @return UCOM API 共通エラーオブジェクト
   */
  private static handleError(error: unknown): UcomConnectixErrorResponse {
    if (axios.isAxiosError(error)) {
      const errorResponse: UcomConnectixErrorResponse = error.response?.data;
      if (errorResponse) return new UcomConnectixErrorResponse(errorResponse);
    }
    throw error;
  }
}
