import axios, { AxiosResponse } from 'axios';
import { BaseApiService } from '@/shared/services/api/base-api-service';
import { EMansionHomepageInputCheckResponse } from '@/infra/response/spf/e-mansion/homepage/e-mansion-homepage-input-check-response';
import { EMansionHomepageApplicationUpdateResponse } from '@/infra/response/spf/e-mansion/homepage/e-mansion-homepage-application-update-response';
import { EMansionHomepageApplicationUpdateRequest } from '@/infra/request/spf/e-mansion/homepage/e-mansion-homepage-application-update-request';
import { EMansionHomepageCancelInitialDisplayRequest } from '@/infra/request/spf/e-mansion/homepage/e-mansion-homepage-cancel-initial-display-request';
import { EMansionHomepageCancelInitialDisplayResponse } from '@/infra/response/spf/e-mansion/homepage/e-mansion-homepage-cancel-initial-display-response';
import { JudgeError } from '@/infra/accessor/spf/common/judge-error';
import { EMansionHomepageCancelUpdateRequest } from '@/infra/request/spf/e-mansion/homepage/e-mansion-homepage-cancel-update-request';
import { EMansionHomepageCancelUpdateResponse } from '@/infra/response/spf/e-mansion/homepage/e-mansion-homepage-cancel-update-response';

const api = BaseApiService.api();

/** 内部 API をコールするサービス */
export class SpfApiHomepageAccessor {
  /**
   * AE05-001 ホームページ：お申込み_入力チェックAPIの呼び出し
   * @param requestUrl ご希望URL
   * @return レスポンスデータ
   */
  public static async callApplicationInputCheck(requestUrl: string): Promise<EMansionHomepageInputCheckResponse> {
    const response = await api
      .get<EMansionHomepageInputCheckResponse>('/hp-kaisetsu/e-mansion/moushikomi', {
        params: {
          requestUrl: requestUrl,
        },
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });

    return response.data;
  }

  /**
   * AE05-002 ホームページ：お申込み確認_更新APIの呼び出し
   * @param eMansionHomepageApplicationUpdateRequest リクエストデータ
   * @return レスポンスデータ
   */
  public static async callHomepageApplicationUpdate(
    eMansionHomepageApplicationUpdateRequest: EMansionHomepageApplicationUpdateRequest
  ): Promise<EMansionHomepageApplicationUpdateResponse> {
    const response = await api
      .post<EMansionHomepageApplicationUpdateResponse>('/hp-kaisetsu/e-mansion/moushikomi-kakunin', {
        id: eMansionHomepageApplicationUpdateRequest.portasId,
        requestUrl: eMansionHomepageApplicationUpdateRequest.requestUrl,
        processingDate: eMansionHomepageApplicationUpdateRequest.processingDate,
        eMansionAccountId: eMansionHomepageApplicationUpdateRequest.eMansionAccountId,
        homepageCapacity: eMansionHomepageApplicationUpdateRequest.homepageCapacity,
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });

    return response.data;
  }

  /**
   * AE05-003_ホームページ：ご利用状況／ご解約_初期表示APIの呼び出し
   * @param eMansionHomepageCancelInitialDisplayRequest ご利用状況／ご解約_初期表示APIのリクエスト
   * @return レスポンス
   */
  public static async callHomepageCancelInitialDisplay(
    eMansionHomepageCancelInitialDisplayRequest: EMansionHomepageCancelInitialDisplayRequest
  ): Promise<EMansionHomepageCancelInitialDisplayResponse> {
    const response = await api
      .get<EMansionHomepageCancelInitialDisplayResponse>('/hp-kaisetsu/e-mansion/kaiyaku', {
        params: {
          eMansionAccountId: eMansionHomepageCancelInitialDisplayRequest.eMansionAccountId,
          date: eMansionHomepageCancelInitialDisplayRequest.date,
        },
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });

    return response.data;
  }

  /**
   * AE05-005 ホームページ：ご解約確認_更新APIの呼び出し
   * @param eMansionHomepageCancelUpdateRequest ご解約確認_更新APIのリクエスト
   * @return レスポンス
   */
  public static async callHomepageCancelUpdate(eMansionHomepageCancelUpdateRequest: EMansionHomepageCancelUpdateRequest): Promise<EMansionHomepageCancelUpdateResponse> {
    const response = await api
      .post<EMansionHomepageCancelUpdateResponse>('/hp-kaisetsu/e-mansion/kaiyaku-kakunin', {
        eMansionAccountId: eMansionHomepageCancelUpdateRequest.eMansionAccountId,
        eMansionHomepageId: eMansionHomepageCancelUpdateRequest.eMansionHomepageId,
        processingDate: eMansionHomepageCancelUpdateRequest.processingDate,
      })
      .catch(async (error) => {
        await JudgeError.judgeError(error);
        throw error;
      });

    return response.data;
  }
}
