




































































































import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { EMansionMainMailAddressList } from '@/shared/classes/spf-api/mail/model/main-mail-address/e-mansion-main-mail-address';
import { SpfApiMainMailAddressAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-main-mail-address-accessor';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { EMansionAccountInfo } from '@/shared/classes/spf-api/mail/model/account/e-mansion-account-info';

export default Vue.extend({
  name: 'list',
  components: {
    LoadingComponent,
  },
  data: () => ({
    mainMailList: EMansionMainMailAddressList, //利用状況情報
    cancelOn: '',
    isLoading: true,
    isBeforeSin: false, // サービスイン日前ならtrue
    isCancelBtn: false, //解約ボタン活性化するか
    isChangeBtn: false, //変更ボタン活性化するか
    isCancelErrMsg: false, //解約ボタンの非活性時にメッセージを表示するか
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      const accountInfo: EMansionAccountInfo | undefined = await this.$store.getters['eMansionAccountStore/eMansionAccountInfo'];
      if (!accountInfo) {
        throw new Error('エラーが発生しました');
      }
      //メインメールアドレス情報をAPIより取得する
      this.$data.mainMailList = await SpfApiMainMailAddressAccessor.getMainMailAddressList(accountInfo.eMansionAccountInfo.eMansionAccountId);
      //メールアドレスが無い場合はTOPページへ(リンク直打ち対策)
      if (!this.$data.mainMailList.hasMailAddress) {
        await this.$router.push('/e-mansion');
        return;
      }
      // 現在日付の取得
      const currentDateObject: Date = await SpfApiServerCommonUtilAccessor.getServerTimeNow();

      if (this.$data.mainMailList.serviceInTime) {
        const sin = new Date(this.$data.mainMailList.serviceInTime);
        this.$data.isBeforeSin = this.compareSin(currentDateObject, sin);
      }
      /**ボタンを活性化するかどうかを判断 */
      this.$data.isCancelBtn = this.isViewCancelBtn(
        this.$data.mainMailList.shouldNotificationMailAddress,
        this.$data.mainMailList.canMainMailAddressCancel,
        this.$data.mainMailList.registerStatus,
        this.$data.isBeforeSin
      );
      this.$data.isChangeBtn = this.isViewChangeBtn(this.$data.mainMailList.registerStatus, this.$data.isBeforeSin);
      //日付の形式に変換
      if (this.$data.mainMailList.cancelOn) {
        this.$data.cancelOn = new Date(this.$data.mainMailList.cancelOn).toLocaleDateString();
      }
      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error');
      return;
    }
  },
  methods: {
    /**サービスイン日が現在日時より前→true */
    compareSin(date: Date, sin: Date): boolean {
      return date > sin;
    },
    /**解約ボタンを活性化するかの判断を行う */
    isViewCancelBtn(shouldNotificationMailAddress: boolean, canMainMailAddressCancel: boolean, registerStatus: string, isBeforeSin: boolean): boolean {
      if (registerStatus == '3') {
        return false;
      }
      if (registerStatus == '4') {
        return false;
      }
      if (registerStatus == '1') {
        if (!isBeforeSin) {
          return false;
        }
      }
      if (shouldNotificationMailAddress) {
        if (!canMainMailAddressCancel) {
          this.$data.isCancelErrMsg = true;
          return false;
        }
      }
      return true;
    },
    /**変更ボタンを活性化するかの判断を行う */
    isViewChangeBtn(registerStatus: string, isBeforeSin: boolean): boolean {
      if (registerStatus == '3') {
        return false;
      }
      if (registerStatus == '4') {
        return false;
      }
      if (registerStatus == '1') {
        if (!isBeforeSin) {
          return false;
        }
      }
      return true;
    },
    /**変更ボタンクリック */
    async onChange() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      //メールアドレスをstoreに保存する
      this.$store.commit('eMansionMainMailAddressStore/mailAddress', this.$data.mainMailList.mailAddress);
      await this.$router.push('/e-mansion/main-mail-address/input');
      this.$data.isLoading = false;
    },
    /**解約ボタンクリック */
    async onCancel() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      //メールアドレスをstoreに保存する
      this.$store.commit('eMansionMainMailAddressStore/mailAddress', this.$data.mainMailList.mailAddress);
      await this.$router.push('/e-mansion/main-mail-address/cancel-confirm');
      this.$data.isLoading = false;
    },
  },
});
