






















































































import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { UcomVirusCheckPurchaseState } from '../../../shared/classes/ucom/ucom-virus-check-purchase-state';
import { convertUcomVirusCheckPurchaseSubscriptionTypeById } from '../../../shared/const/ucom/ucom-virus-check-purchase-subscription-type';
import { numberWithDelimiter } from '../../../shared/util/vue-filters';

export default Vue.extend({
  name: 'virus-check-purchase-complete',
  components: {
    LoadingComponent,
  },
  data(): {
    purchaseState?: UcomVirusCheckPurchaseState;
    notifyMailAddress?: string;
  } {
    return {
      purchaseState: undefined,
      notifyMailAddress: undefined,
    };
  },
  mounted() {
    // 前画面からの情報を取得する
    this.purchaseState = this.$store.getters['ucomVirusCheckStore/purchaseState'];
    this.notifyMailAddress = this.makeMailAddressForView(this.$store.getters['ucomVirusCheckStore/notifyMailAddress']);
  },
  methods: {
    /** 「UCOM光 レジデンス会員専用トップ」のクリックハンドラ */
    goToTop(): void {
      this.$router.push('/ucom');
    },
    makeMailAddressForView(mailAddress: string[]): string {
      if (!mailAddress[0]) {
        return mailAddress[1];
      }
      if (mailAddress[0] === '') {
        return mailAddress[1];
      }
      return `${mailAddress[0]}, ${mailAddress[1]}`;
    },
  }, // methods
  computed: {
    /** ローディング中かどうかの判定 */
    isLoading(): boolean {
      return false;
    }, // isLoading
    /** お申込内容 に表示するテキスト */
    subscriptionTypeText(): string {
      if (this.purchaseState?.subscriptionType === undefined) {
        return '';
      }
      return convertUcomVirusCheckPurchaseSubscriptionTypeById(this.purchaseState.subscriptionType) ?? '';
    },
  },
  filters: {
    numberWithDelimiter,
  },
});
