





























































































import Vue from 'vue';
/** エラーメッセージ用コンポーネント */
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import {required} from 'vuelidate/lib/validators';
import {Member} from '@/shared/classes/spf-api/member';
import {AuthManegementSharedErrorResponse} from '@/shared/classes/auth-manegement/shared-error-response';
import {EMansionLinkingInput} from '@/shared/classes/auth-manegement/e-mansion-linking-input';
import {AuthService} from '@/shared/services/auth/auth-service';
import {checkRouterError} from '@/shared/util/router-navigation-func';
import { AUTH0_PROPERTY_ID_NO_MATCH } from '@/shared/const/error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import {InitEMansionMainAccount} from "@/shared/classes/spf-api/mail/model/account/init-e-mansion-main-account";
import {SpfApiAccountAccessor} from "@/infra/accessor/spf/e-mansion/spf-api-account-accessor";

export default Vue.extend({
  name: "linking",
  components: {
    /** エラーメッセージ用コンポーネント */
    ErrorMessagesComponent,
    LoadingComponent
  },
  data: () => ({
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    auth0PropertyIdNoMatchErrorMessageTitle: AUTH0_PROPERTY_ID_NO_MATCH.displayTitle.E_MANSION,
    defaultErrorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    errorMessageTitle: '',
    /** 入力データ */
    inputData: {
      userAccountFirst: '' as string,
      userAccountSecond: '' as string,
      password: '' as string,
    },
    eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
    eMansionMyUrl: process.env.VUE_APP_E_MANSION_MY_URL,
    isExecutingOnAccountlinkage: false, // onAccountlinkage()実行中かどうか
    service: 'Portas',
  }),
  validations: {
    inputData: {
      userAccountFirst: {
        required,
      },
      userAccountSecond: {
        required,
      },
      password: {
        required,
      },
    },
  },
  async mounted(): Promise<void> {
    /** ログインしているか否かの情報を取得 */
    const isLoggedIn = await AuthService.isLoggedIn();
    // ログインしていなければ トップ画面 に遷移する
    if (!isLoggedIn) {
      this.$router.push('/');
      return;
    }

    /** 会員テーブルの e-mansion の主キーからユーザが e-mansion と連携しているかを確認する */
    const member: Member | null = this.$store.getters['memberStore/member'];
    if (member?.primaryKeyMye) {
      // 連携していれば e-mansion サービストップに遷移する
      this.$router.push('/e-mansion').catch(error => {
        checkRouterError(error)
      });
    }
  },
  methods: {
    /** フォームのバリデーション */
    validate(): boolean {
      this.errorMessages = [];
      if (this.$v.inputData.$invalid) {
        if (!this.$v.inputData.userAccountFirst?.required || !this.$v.inputData.userAccountSecond?.required) {
          this.errorMessages.push('アカウントを入力してください。');
          window.scrollTo(0, 0);
        }
        if (!this.$v.inputData.password?.required) {
          this.errorMessages.push('パスワードを入力してください。');
          window.scrollTo(0, 0);
        }
        return false;
      }
      return true;
    },
    /** アカウント連携ボタン押下時 */
    async onAccountlinkage(): Promise<void> {
      try {
        if (this.isExecutingOnAccountlinkage) {
          return;
        }
        this.isExecutingOnAccountlinkage = true;
        this.errorMessageTitle = this.defaultErrorMessageTitle;

        if (this.validate()) {
          const accountSubDomain = this.inputData.userAccountSecond;
          const eMansionLinkingInput: EMansionLinkingInput = {
            loginId: `${this.inputData.userAccountFirst}-${accountSubDomain}`,
            password: this.inputData.password,
          };
          /** e-mansion アカウント連携 API を使用して次の 3 つの処理を実行する
           * 1. e-mansion 外部 API に入力情報（アカウント・パスワード）を渡して e-mansion 登録済みか否かを確認
           * 2. Portas 会員テーブルの「e-mansion の主キー」項目にアカウントを格納
           * 3. Auth0 のプライマリにセカンダリとして e-mansion 会員 ID の紐付けを行う
           */
          const result: Promise<undefined | AuthManegementSharedErrorResponse> = await this.$store.dispatch(
              'eMansionLinkingStore/updateLinking',
              eMansionLinkingInput
          );
          if (result instanceof AuthManegementSharedErrorResponse) {

            if (result.status === 409) {
              this.errorMessages.push('アカウント連携に失敗しました。');
              window.scrollTo(0, 0);
              return;
            } else if (result.errors.some((value) => value === AUTH0_PROPERTY_ID_NO_MATCH.errorMessage)) {
              this.errorMessageTitle = this.auth0PropertyIdNoMatchErrorMessageTitle;

              this.errorMessages.push(AUTH0_PROPERTY_ID_NO_MATCH.displayMessage.E_MANSION[0]);
              this.errorMessages.push(AUTH0_PROPERTY_ID_NO_MATCH.displayMessage.E_MANSION[1]);
              this.errorMessages.push(AUTH0_PROPERTY_ID_NO_MATCH.displayMessage.E_MANSION[2].replace('REPLACE_URL', process.env.VUE_APP_REPLACE_URL_AUTH0_PROPERTY_ID_NO_MATCH_EMANSION));

            } else if(result.status === 500) {
              throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.AUTH0_PORTAS.E_MANSION_ACCOUNT_LINKING);

            } else {
              this.errorMessages.push('アカウントあるいはパスワードが一致しません。');
              window.scrollTo(0, 0);
              return;
            }

          }
          await AuthService.refresh();

          // アカウントテーブル作成APIコール
          // エラーハンドリングについては既存踏襲
          const member: Member = await this.$store.getters['memberStore/member'];
          await SpfApiAccountAccessor.insertInitEMansionMainAccount(
              new InitEMansionMainAccount(
                  member.id,
                  await AuthService.getEMansionLoginId(),
                  accountSubDomain
              )
          );
          // account 情報 を store に反映
          await this.$store.dispatch('eMansionAccountStore/eMansionAccountInfo', await AuthService.getEMansionLoginId());

          // 正常ケースの場合
          // this.$store.dispatch('eMansionLinkingStore/updateLinking', input) に成功するとバックエンドで会員ステータスが更新される
          // ページ遷移時に beforeEach で会員ステータス取得できるように null にする
          this.$store.commit("memberStore/memberStatus", null);
          this.$store.commit("memberStore/member", null);

          // e-mansion サービストップ に遷移
          await this.$router.push('/e-mansion').catch((error) => {
            checkRouterError(error);
          });
          return;

        }
      } catch (error) {
        throw error;
      } finally {
        this.isExecutingOnAccountlinkage = false;
      }
    },
  },
});
