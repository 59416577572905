







































import Vue from 'vue';

/** エラーメッセージ表示エリアコンポーネント */
export default Vue.extend({
  name: 'error-messages-component',
  // 親コンポーネントから受け取るデータ
  props: {
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: {
      type: String,
      default: ''
    },
    /** 表示したいエラーメッセージを格納した配列 */
    errorMessages: {
      type: Array,
      default: Array
    },
    isOneSentence: {
      type: Boolean,
      default: false
    },
    isIspLinkingStyle: {
      type: Boolean,
      default: false
    }
  }
});
