import axios from 'axios';

import { EMansionConnectixErrorResponse } from '@/pages/connectix/e-mansion/classes/external-api/connectix-error-response';
import { EMansionConnectixNewConnectixRequest } from '@/pages/connectix/e-mansion/classes/external-api/new-connectix-request';
import { EMansionConnectixPropertyRoomsResponseResult } from '@/pages/connectix/e-mansion/classes/external-api/property-rooms-response';
import { EMansionConnectixNewConnectixResponse } from '@/pages/connectix/e-mansion/classes/external-api/new-connectix-response';
import { BaseExternalApiService } from '@/shared/services/external-api/base-external-api-service';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';

const api = BaseExternalApiService.api(1);

/** e-mansion Connectix 外部 API をコールするサービス */
export class EMansionConnectixExternalApiService {
  /**
   * Connectix お申し込み
   *
   * @param EMansionConnectixNewConnectixRequest Connectix お申し込み情報
   * @return Connectix お申し込み結果
   */
  public static async createConnectix(
    emansionConnectixNewConnectixRequestResult: EMansionConnectixNewConnectixRequest
  ): Promise<EMansionConnectixErrorResponse | undefined> {
    try {
      emansionConnectixNewConnectixRequestResult.property_id = convert6DigitIdTo8DigitId(emansionConnectixNewConnectixRequestResult.property_id);
      await api.post<EMansionConnectixNewConnectixResponse>('/connectix', emansionConnectixNewConnectixRequestResult);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * 部屋番号一覧
   *
   * @param propertyId string  物件ID
   * @return 部屋番号一覧
   */
  public static async findPropertyRooms(
    propertyId: string,
    uaType: string | null = null
  ): Promise<EMansionConnectixPropertyRoomsResponseResult | EMansionConnectixErrorResponse> {
    const apid = convert6DigitIdTo8DigitId(propertyId);
    const query = {
      params: uaType ? { ua_type: uaType } : null
    };
    try {
      const response = await api.get<EMansionConnectixPropertyRoomsResponseResult>(`/properties/${apid}`, query);
      return new EMansionConnectixPropertyRoomsResponseResult(response.data);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * API 通信エラー時のハンドリング
   *
   * @param error エラーオブジェクト
   * @return e-mansion API 共通エラーオブジェクト
   */
  private static handleError(error: unknown): EMansionConnectixErrorResponse {
    if (axios.isAxiosError(error)) {
      const errorResponse: EMansionConnectixErrorResponse = error.response?.data;
      if (errorResponse) return new EMansionConnectixErrorResponse(errorResponse);
    }
    throw error;
  }
}
