








































































































































import Vue from 'vue';

/** エラーメッセージ用コンポーネント */
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
/** Connectixお申し込みAPIエラー時メッセージ用コンポーネント */
import ErrorMessagesInquiryComponent from '@/shared/components/error-messages-inquiry-component.vue';
/** クレジットカード用コンポーネント */
import CreditCardComponent from '@/shared/components/ucom/veritrans-credit-card-ucom-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';

/** サービス UCOM Connectix 外部 API をコールするサービス */
import { UcomConnectixExternalApiService } from '@/shared/services/external-api/connectix/ucom/ucom-connectix-external-api-service';

/** Entity UCOM Connectix API : Connectix お申し込み : リクエスト */
import { UcomConnectixNewConnectixRequest } from './classes/external-api/new-connectix-request';
/** Entity UCOM Connectix API : エラー時のレスポンス */
import { UcomConnectixErrorResponse } from './classes/external-api/connectix-error-response';
/** Entity UCOM 共通利用 API : 契約基本情報取得 : レスポンス */
import { UcomCustomerResponse } from '../../../shared/classes/external-api/ucom/customer-response';
/** Entity UCOM 共通利用 API : 物件基本情報取得 : レスポンス */
import { UcomPropertyResponse } from '../../../shared/classes/external-api/ucom/property-response';
import { AuthService } from '@/shared/services/auth/auth-service';
import { UcomSharedErrorResponse } from '@/shared/classes/external-api/ucom/shared-error-response';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { ConnectixMaintenanceInfo } from '@/shared/classes/spf-api/connectix-maintenance';
import { ISP_MEMBER_STATUS, SERVICE_TYPE } from '@/shared/const/service-type';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR, FRONT_ERROR_INFO_DATA_INCONSISTENCT } from '@/shared/const/error/error-info';
import { DataInconsistencyFrontError } from '@/shared/classes/error/data-inconsistency-front-error';
import { MemberStatus } from '@/shared/classes/spf-api/member-status';
import { getIspMemberStatusUcom } from '@/shared/util/func-get-isp-member-status';

/** UCOM光レジデンス Connectixお申し込み お申し込み確認画面 */
export default Vue.extend({
  name: 'ucom-connectix-confirm',
  components: {
    /** エラーメッセージコンポーネント */
    ErrorMessagesComponent,
    /** Connectixお申し込みAPIエラー時メッセージコンポーネント */
    ErrorMessagesInquiryComponent,
    /** クレジットカード入力フォームコンポーネント */
    CreditCardComponent,
    LoadingComponent
  },
  data: () => ({
    /** 物件名 */
    builingName: '',
    /** 部屋番号 */
    roomNumber: '',
    /** 物件ID */
    propertyId: '',
    /** 顧客ID */
    ucomMemberId: '',
    /** Connectix利用金額 */
    connectixUsageFee: '',
    /** クレジットカード情報の登録有無(登録済か否か) */
    isRegisteredCreditCard: false,
    /** クレジットカード情報の登録の必要有無 */
    needRegisteredCreditCard: true,
    /** エラーメッセージを格納する配列 */
    errorMessages: [],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    /** Connectixお申し込みAPIエラー時メッセージ部に表示するタイトル */
    errorMessageInquiry: 'お申し込みが正常に完了しませんでした。恐れ入りますが、サポートセンターへお問合せください。',
    /** Connectixお申し込みAPIエラー時メッセージ */
    errorMessagesInquiry: [
      {
        header: 'UCOM光レジデンスサポートセンター',
        message1: '電話番号：0120-286-645/03-6823-0368',
        message2: '営業時間：年中無休　9:00-21:00',
        message2IsLink: false
      },
      {
        header: '「UCOM光 レジデンス 建物専用サイト」でお問い合わせフォームからも承っております',
        message1: '▼お住いの建物名を検索',
        message2: `${process.env.VUE_APP_UCOM_URL}/search.html`,
        message2IsLink: true
      }
    ],
    /** Connectixお申し込みAPIエラー時メッセージの表示/非表示 */
    showConnectixApiError: false,
    /** クレジットカードアクセストークン */
    creditCardAccessToken: '',
    /** クレジットカードトークン有効期限 */
    creditTokenExpireDate: '',
    /** APIトークンキー */
    apiTokenKey: '',
    /** トークン取得成功後に表示するメッセージ */
    successMessage: '画面下の「お申し込み」をクリックしてください。',
    /** ボタン押下判定 */
    isSubmitting: false,
    /** ボタン活性/非活性判定 */
    disableButton: false,
    /** クレジットカード分けのため */
    creditCardType: '',
    // vueのmounted実行中かどうか
    isMounting: true,
    // ISP(ucom)会員ステータス
    ispMemberStatusUcom: '',
    // Connectixサービスイン前エラー用に1文エラーにするかどうか
    isErrorMessageOneSentence: false
  }),
  /** 画面初期表示時の処理 */
  async mounted(): Promise<void> {
    // 物件基本情報をStoreから取得
    try {
      /** ログインしているか否かの情報を取得 */
      const isLoggedIn = await AuthService.isLoggedIn();
      // ログインしていない場合「総合トップ」画面にリダイレクトする
      if (!isLoggedIn) {
        this.$router.push('/platform').catch(error => {checkRouterError(error)});
      }

      // Auth0ユーザのapp_metadataからUCOMの物件IDを取得する
      this.$data.propertyId = await AuthService.getUcomPropertyId();

      const property: Promise<UcomPropertyResponse | UcomSharedErrorResponse> = await this.$store.dispatch('ucomCommonStore/property', this.$data.propertyId);
      if (property instanceof UcomPropertyResponse) {
        // 物件名
        this.$data.builingName = property.building_name;

        // サービス提供企業を設定
        this.$data.creditCardType = property.service_provid_former.service_provid_former_id;
      } else if (property instanceof UcomSharedErrorResponse) {
        // APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.PROPERTY_INFO);
      }
    } catch (error) {
      throw error;
    }
    // 契約基本情報をStoreから所得
    try {
      // UCOM のメンバー ID をStoreから取得する
      this.$data.ucomMemberId = await AuthService.getUcomMemberId();
      const customer: Promise<UcomCustomerResponse | UcomSharedErrorResponse> = await this.$store.dispatch('ucomCommonStore/customer', this.$data.ucomMemberId);
      if (customer instanceof UcomCustomerResponse) {
        // 正常ケースの場合
        // Connectixにお申し込み済みかどうかのチェック
        if (customer.connectix_status) {
          this.$data.errorMessages.push('お客様は既にConnectixを契約しています。');
          return;
        }
        // Connectix利用金額
        this.$data.connectixUsageFee = customer.connectix_price;
        // クレジットカード情報の登録有無(登録済みか否か)
        this.$data.isRegisteredCreditCard = customer.is_register_credit_card;
        if (this.$data.isRegisteredCreditCard === true) {
          // 登録済みの場合はConnectixお申し込みAPI実行時に登録不要とする
          this.$data.needRegisteredCreditCard = false;
        }
      } else if (customer instanceof UcomSharedErrorResponse) {
        // APIエラー
        throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.CONTRACTOR_INFO);
      }
    } catch (error) {
      throw error;
    } finally {
      this.isMounting = false;
    }

    this.isMounting = true;

    // APIトークンキーを設定

    /**
     * .env.development, .env.staging, .env.production それぞれに次の環境変数を追加
     * 1. VUE_APP_VERITRANS_TOKEN_API_KEY_UCOM_TNC
     * 2. VUE_APP_VERITRANS_TOKEN_API_KEY_UCOM_ARTE
     */
    // サービス提供企業ID:1（株式会社つなぐネットコミュニケーションズ） => TNCのトークンAPIキーを利用
    if(this.$data.creditCardType === '1'){
      this.$data.apiTokenKey = process.env.VUE_APP_VERITRANS_TOKEN_API_KEY_UCOM_TNC;
    }

    // サービス提供企業ID:2（アルテリア・ネットワークス株式会社） => ARTE(大和)のトークンAPIキーを利用
    if(this.$data.creditCardType === '2'){
      this.$data.apiTokenKey = process.env.VUE_APP_VERITRANS_TOKEN_API_KEY_UCOM_ARTE;
    }

    // 入力画面の情報をStoreから取得
    const inputApply = this.$store.getters['ucomConnectixEntryStore/entryInputForm'];
    if (inputApply != null) {
      // 部屋番号
      this.$data.roomNumber = inputApply.roomNumber;
    } else {
      // データ不整合エラー
      throw new DataInconsistencyFrontError(FRONT_ERROR_INFO_DATA_INCONSISTENCT.NO_INPUT_DATA);
    }
    this.isMounting = false;
  },
  methods: {
    /** 「お申し込み ➡」ボタン押下時 :  Connectixお申し込みAPIを実行し お申し込み完了画面に遷移する */
    async onApply() {
      // ボタン押下中は何もしない
      if (this.isSubmitting) {
        return;
      }
      // ボタン押下中扱いとする
      this.isSubmitting = true;

      const connectixMaintenance: ConnectixMaintenanceInfo = await this.$store.dispatch('connectixMaintenanceStore/connectixMaintenance', SERVICE_TYPE.UCOM);
      if (connectixMaintenance) {
        this.$router.push('/platform/maintenance').catch(error => {checkRouterError(error)});
        return;
      }

      try {
        // エラーメッセージ格納配列初期化
        this.$data.errorMessages = [];
        this.isErrorMessageOneSentence = false;
        // Connectixお申し込みAPIエラー時メッセージ非表示
        this.$data.showConnectixApiError = false;
        // クレジットカードトークンがない かつ クレジットカード未登録の場合はエラー表示
        if (this.$data.creditCardAccessToken === '' && !this.$data.isRegisteredCreditCard) {
          // APIキーが発行されていない場合はエラー
          this.$data.errorMessages.push(`カード番号もしくは有効期限を正しく入力してください。`);
          // エラーメッセージを見せるために画面最上部にスクロール
          window.scrollTo(0, 0);
          return;
        }

        // ISP(ucom)側で退会申し込み中のとき、エラーメッセージを表示させる
        if (await this.isInCancelApplication()) {
          this.isErrorMessageOneSentence = true;
          this.$data.errorMessages.push('Connectixのお申し込みは現在承ることができません。');
          window.scrollTo(0, 0);
          return;
        }

        // Connectixお申し込みAPIを実行
        const request = new UcomConnectixNewConnectixRequest({
          /** 物件ID */
          property_id: this.$data.propertyId,
          /** 顧客ID */
          customer_id: this.$data.ucomMemberId,
          /** 部屋番号 */
          room_number: this.$data.roomNumber,
          /** クレジットカード登録有無(true:登録必要あり/false:登録必要なし) */
          is_register_credit_card: this.$data.needRegisteredCreditCard,
          /** クレジットカードトークン */
          credit_card_token: this.$data.creditCardAccessToken
        });

        try{
          const resultCreateConnectix: undefined | UcomConnectixErrorResponse = await UcomConnectixExternalApiService.createConnectix(request);
          if (resultCreateConnectix instanceof UcomConnectixErrorResponse) {
            // APIエラー
            throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.CONNECTIX);
          } else {
            // 正常ケースの場合
            this.$router.push('/connectix/ucom/completed').catch(error => {checkRouterError(error)});
          }
        } catch(error: any) {

          // createConnectixはレスポンスなし200が正
          if(error.message === 'Nothing Result'){
            // 正常ケースの場合
            return this.$router.push('/connectix/ucom/completed').catch(error => {checkRouterError(error)});
          } else {
            throw error;
          }

        }

      } catch (error: any) {
        throw error;
      } finally {
        // ボタン押下解除
        this.isSubmitting = false;
      }
    },
    /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークンを受け取る */
    getCreditCardToken(token: string) {
      // エラーメッセージ格納配列初期化
      this.$data.errorMessages = [];
      this.$data.creditCardAccessToken = token;
    },
    /** クレジットカードエラーが発生していた場合 */
    executeCreditCardError(errorMessage: string) {
      // エラーメッセージ格納配列初期化
      this.$data.errorMessages = [errorMessage];
      // エラーメッセージを見せるために画面最上部にスクロール
      window.scrollTo(0, 0);
    },
     /** 株式会社DGフィナンシャルテクノロジーから返却されたクレジットカードトークン有効期限を受け取る */
    getTokenExpireDate(creditTokenExpireDate: string) {
      this.$data.creditTokenExpireDate = creditTokenExpireDate;
    },
    async isInCancelApplication() {
      try {
        this.$data.ucomMemberId = await AuthService.getUcomMemberId();
        const customer: Promise<UcomCustomerResponse | UcomSharedErrorResponse> = await this.$store.dispatch('ucomCommonStore/customer', this.$data.ucomMemberId);

        if (customer instanceof UcomCustomerResponse) {

          // ISP会員ステータス取得
          const memberStatus: MemberStatus = this.$store.getters['memberStore/memberStatus'];
          this.ispMemberStatusUcom = getIspMemberStatusUcom(memberStatus, customer);

          /**
           * ISP(ucom)会員ステータスが次の2つの場合、ISP退会申し込み中であると判定する
           * 1. ISP(ucom)会員ステータス: 退会申し込み
           * 2. ISP(ucom)会員ステータス: 退会済みログイン可能
           */
          if (this.ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.IN_CANCEL_APPLICATION
              || this.ispMemberStatusUcom === ISP_MEMBER_STATUS.UCOM.CANCEL_AND_LOGIN_OK) {
            return true;
          }
        } else if (customer instanceof UcomSharedErrorResponse) {
          throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.UCOM.CONTRACTOR_INFO);
        }
      } catch (e) {
        throw e;
      }
      return false;
    }
  },
  computed: {
    isDisabledButton(): boolean {
      return !this.isRegisteredCreditCard && this.creditCardAccessToken === '';
    }
  }
});
