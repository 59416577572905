import { NavigationGuardNext, Route } from 'vue-router';
import UcomIndex from '@/pages/ucom/index.vue';
import LinkService from '@/pages/ucom/link-service.vue';
import Linking from '@/pages/ucom/linking.vue';
import SubAccountLinking from '@/pages/ucom/sub-account-linking.vue';
import AccountManagementList from '@/pages/ucom/account-management/list.vue';
import AccountManagementInput from '@/pages/ucom/account-management/input.vue';
import AccountManagementConfirm from '@/pages/ucom/account-management/confirm.vue';
import AccountManagementCompleted from '@/pages/ucom/account-management/completed.vue';
import MailboxCapacityInput from '@/pages/ucom/mailbox-capacity/input.vue';
import MailboxCapacityConfirm from '@/pages/ucom/mailbox-capacity/confirm.vue';
import MailboxCapacityCompleted from '@/pages/ucom/mailbox-capacity/completed.vue';
import AccountOptionChangeAccountList from '@/pages/ucom/account-option-change/list.vue';
import AccountOptionChangeMailAddressInput from '@/pages/ucom/account-option-change/mail/input.vue';
import AccountOptionChangeMailAddressConfirm from '@/pages/ucom/account-option-change/mail/confirm.vue';
import AccountOptionChangeMailAddressCompleted from '@/pages/ucom/account-option-change/mail/completed.vue';
import AccountOptionChangeWebSpaceConfirm from '@/pages/ucom/account-option-change/web-space/confirm.vue';
import AccountOptionChangeWebSpaceCompleted from '@/pages/ucom/account-option-change/web-space/completed.vue';
import AccountInfoForDelete from '@/pages/ucom/account-management/account-info-for-delete.vue';
import AccountInfoForDeleteConfirmation from '@/pages/ucom/account-management/account-info-for-delete-confirmation.vue';
import AccountInfoForDeleteComplete from '@/pages/ucom/account-management/account-info-for-delete-complete.vue';
import MailPackInput from '@/pages/ucom/mail-pack/input.vue';
import MailPackConfirm from '@/pages/ucom/mail-pack/confirm.vue';
import MailPackComplete from '@/pages/ucom/mail-pack/complete.vue';
import VirusCheckInput from '@/pages/ucom/virus-check/input.vue';
import VirusCheckPurchaseConfirm from '@/pages/ucom/virus-check/purchase-confirm.vue';
import VirusCheckPurchaseComplete from '@/pages/ucom/virus-check/purchase-complete.vue';
import VirusCheckPauseConfirm from '@/pages/ucom/virus-check/pause-confirm.vue';
import VirusCheckPauseCompleted from '@/pages/ucom/virus-check/pause-completed.vue';
import WebSpaceInput from '@/pages/ucom/web-space/input.vue';
import WebSpaceConfirm from '@/pages/ucom/web-space/confirm.vue';
import WebSpaceComplete from '@/pages/ucom/web-space/complete.vue';
import Input from '@/pages/ucom/entry/input.vue';
import Confirm from '@/pages/ucom/entry/confirm.vue';
import Completed from '@/pages/ucom/entry/completed.vue';
import Contact from '@/pages/ucom/entry/contact.vue';
import store from '@/store';
import { isTransitionNoLinked } from '.';
import { UA_TYPE } from '../shared/const/service-type';
import { PermissionControlService } from '../shared/services/permission/permission-control-service';
import { SERVICE_ID } from '@/shared/const/service-ids';
import { SCREEN_ID } from '@/shared/const/screen-ids';
/** UCOM ルーティング定義 */
const ucomRouter = [
  {
    path: '/ucom',
    name: 'UCOM光レジデンス サービストップ',
    component: UcomIndex,
    meta: {
      title: 'Portas | UCOM光 レジデンス 会員専用トップページ | ポルタス',
      description: 'Portas（ポルタス）UCOM光 レジデンス会員専用トップページになります。',
      keywords: 'UCOM光 レジデンス 会員専用トップページ',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      const isLinkedToUcom: boolean = await store.dispatch('memberStore/isLinkedToUcom');
      if (isLinkedToUcom) {
        next();
        return;
      } else {
        return next({ path: '/login-forwarding' }); // トップページに遷移
      }
    },
  },
  //{
  //  path: '/ucom/link-service',
  //  name: 'UCOM光レジデンス 同時お申し込み',
  //  component: LinkService,
  //  meta: {
  //    title: 'Portas | UCOM光 レジデンス お客様番号連携、新規会員登録 | ポルタス',
  //    description: 'Portas（ポルタス）とUCOM光 レジデンスのお客様番号連携、新規会員登録を行います。',
  //    keywords: 'UCOM光 レジデンス同時お申し込みページ（新規会員登録、アカウント連携）'
  //  },
  //  beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
  //    if(await isTransitionNoLinked()){
  //      return next();
  //    }
  //    return next({ path: '/platform' });
  //  }
  //},
  {
    path: '/ucom/linking',
    name: 'UCOM光レジデンス 既存サービスとの紐づけ',
    component: Linking,
    meta: {
      title: 'Portas | UCOM光 レジデンス お客様番号連携 | ポルタス',
      description: 'Portas（ポルタス）とUCOM光 レジデンスのお客様番号連携を行います。',
      keywords: 'UCOM光 レジデンス お客様番号連携',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (await isTransitionNoLinked(UA_TYPE.UCOM)) {
        return next();
      }
      return next({ path: '/platform' });
    },
  },
  {
    path: '/ucom/sub-account-linking',
    name: 'UCOM光レジデンス アカウント 既存サービスとの紐づけ',
    component: SubAccountLinking,
    meta: {
      title: 'Portas | UCOM光 レジデンス アカウント連携 | ポルタス',
      description: 'Portas（ポルタス）とUCOM光 レジデンスのアカウント連携を行います。',
      keywords: 'UCOM光 レジデンス アカウント連携',
    },
  },
  //{
  //  path: '/ucom/entry/input',
  //  name: 'UCOM光レジデンス 新規会員登録 情報の入力',
  //  component: Input,
  //  meta: {
  //    title: 'Portas | UCOM光 レジデンス お客様情報登録ページ 情報の入力 | ポルタス',
  //    description: 'Portas（ポルタス）でのUCOM光 レジデンスの新規会員登録はこちらから行ってください。お客様情報登録ページ 情報の入力',
  //    keywords: 'UCOM光 レジデンス お客様情報登録ページ情報の入力'
  //  },
  //  beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
  //    // 確認画面から戻ったときだけ、入力内容が保持されるようにする
  //    if (from.path !== '/ucom/entry/confirm') {
  //      store.commit('ucomEntryStore/entryInputForm', null);
  //      store.commit('ucomEntryStore/applicationCodeData', null);
  //    }
  //
  //    if(await isTransitionNoLinked()){
  //      return next();
  //    }
  //    return next({ path: '/platform' });
  //  }
  //},
  //{
  //  path: '/ucom/entry/confirm',
  //  name: 'UCOM光レジデンス 新規会員登録 入力内容の確認',
  //  component: Confirm,
  //  meta: {
  //    title: 'Portas | UCOM光 レジデンス お客様情報登録ページ 入力内容の確認 | ポルタス',
  //    description: 'Portas（ポルタス）でのUCOM光 レジデンスの新規会員登録はこちらから行ってください。お客様情報登録ページ 入力内容の確認',
  //    keywords: 'UCOM光 レジデンス お客様情報登録ページ入力内容の確認'
  //  },
  //  // URL直叩き防止のため、直前のURLを判定する。
  //  // 情報の入力画面だった場合は入力内容の確認画面に遷移させる。
  //  // それ以外だった場合、情報の入力画面へ遷移させる。
  //  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  //  beforeEnter: (_to: Route, from: Route, next: NavigationGuardNext) => {
  //    if (from.path !== '/ucom/entry/input') {
  //      next({path: '/ucom/entry/input'});
  //    }
  //    next();
  //  }
  //},
  //{
  //  path: '/ucom/entry/completed',
  //  name: 'UCOM光レジデンス 新規会員登録 完了',
  //  component: Completed,
  //  meta: {
  //    title: 'Portas | UCOM光 レジデンス お客様情報登録ページ 登録完了 | ポルタス',
  //    description: 'Portas（ポルタス）でのUCOM光 レジデンスの新規会員登録はこちらから行ってください。お客様情報登録ページ 登録完了',
  //    keywords: 'UCOM光 レジデンス お客様情報登録ページ登録完了'
  //  },
  //  // URL直叩き防止のため、直前のURLを判定しする。
  //  // 入力内容の確認画面だった場合は完了画面に遷移させる。
  //  // それ以外だった場合、情報の入力画面へ遷移させる。
  //  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  //  beforeEnter: (_to: Route, from: Route, next: NavigationGuardNext) => {
  //    if (from.path !== '/ucom/entry/confirm') {
  //      next({ path: '/ucom/entry/input' });
  //      return;
  //    }
  //    next();
  //  }
  //},
  {
    path: '/ucom/account-management/list',
    name: 'UCOM光レジデンス アカウントID管理 一覧',
    component: AccountManagementList,
    meta: {
      title: 'Portas | UCOM光 レジデンス アカウントID管理 一覧 | ポルタス',
      description: 'アカウントIDの登録／変更／削除が行えます。',
      keywords: 'UCOM光 レジデンス アカウントID管理 一覧',
    },
  },
  {
    path: '/ucom/account-management/input',
    name: 'UCOM光レジデンス アカウントID管理：登録・変更　入力',
    component: AccountManagementInput,
    meta: {
      title: 'Portas | UCOM アカウントID　登録・変更　入力 | ポルタス',
      description: 'Portas（ポルタス）でのUCOM光 レジデンス アカウントIDの登録・変更はこちらから行ってください。',
      keywords: 'UCOM光レジデンス アカウントID管理：登録・変更　入力',
    },
  },
  {
    path: '/ucom/account-management/confirm',
    name: 'UCOM光レジデンス アカウントID管理：登録・変更　確認',
    component: AccountManagementConfirm,
    meta: {
      title: 'Portas | UCOM アカウントID　登録・変更　確認 | ポルタス',
      description: 'Portas（ポルタス）でのUCOM光 レジデンス アカウントIDの登録・変更の内容をご確認いただけます。',
      keywords: 'UCOM光レジデンス アカウントID管理：登録・変更　確認',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      // UCOM光レジデンス アカウントID管理：登録・変更_入力画面からの遷移のみ対応
      if (from.path != '/ucom/account-management/input') {
        return next({ path: '/ucom/account-management/list' });
      }
      return next();
    },
  },
  {
    path: '/ucom/account-management/completed',
    name: 'UCOM光レジデンス アカウントID管理：登録・変更　完了',
    component: AccountManagementCompleted,
    meta: {
      title: 'Portas | UCOM アカウントID　登録・変更　完了 | ポルタス',
      description: 'Portas（ポルタス）でのUCOM光 レジデンス アカウントIDの登録・変更手続きが完了しました。',
      keywords: 'UCOM光レジデンス アカウントID管理：登録・変更　完了',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      // 認可制御
      if (!(await PermissionControlService.isReadable(SERVICE_ID.UCOM_ACCOUNT_ID_MANAGEMENT))) {
        return next({ path: '/error' });
      }
      // UCOM光レジデンス アカウントID管理：登録・変更_確認画面からの遷移のみ対応
      if (from.path != '/ucom/account-management/confirm') {
        return next({ path: '/ucom/account-management/list' });
      }
      return next();
    },
  },
  {
    path: '/ucom/account-option-change/account-list',
    name: 'UCOM アカウントIDオプション変更 アカウントID情報一覧',
    component: AccountOptionChangeAccountList,
    meta: {
      title: 'Portas | UCOM アカウントIDオプション変更 アカウントID情報一覧 | ポルタス',
      description: '',
      keywords: 'UCOM アカウントIDオプション変更 アカウントID情報一覧',
    },
  },
  {
    path: '/ucom/mailbox-capacity/input',
    name: 'UCOM光レジデンス メールボックス容量変更：入力',
    component: MailboxCapacityInput,
    meta: {
      title: 'Portas | UCOM光 レジデンス メールボックス容量変更：入力画面 | ポルタス',
      description: 'Portas（ポルタス）でのUCOM光 レジデンス メールボックス容量の変更はこちらから行ってください。',
      keywords: 'UCOM光 レジデンス メールボックス容量の変更 入力画面',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      // 確認画面から戻ったときだけ、入力内容が保持されるようにする
      if (from.path !== '/ucom/mailbox-capacity/confirm') {
        store.commit('ucomMailboxCapacityStore/ucomAccountMailId', undefined);
        store.commit('ucomMailboxCapacityStore/accountName', undefined);
        store.commit('ucomMailboxCapacityStore/mailAddress', undefined);
        store.commit('ucomMailboxCapacityStore/mailBoxCapacity', undefined);
        store.commit('ucomMailboxCapacityStore/changeCapacityLabel', undefined);
        store.commit('ucomMailboxCapacityStore/changeCapacityValue', undefined);
        store.commit('ucomMailboxCapacityStore/price', undefined);
        store.commit('ucomMailboxCapacityStore/effectiveDate', undefined);
        store.commit('ucomMailboxCapacityStore/serviceDescription', undefined);
        store.commit('ucomMailboxCapacityStore/isRegisteredPayment', undefined);
        store.commit('ucomMailboxCapacityStore/subscriptionDate', undefined);
        store.commit('ucomMailboxCapacityStore/notifyMailAddress', undefined);
      }
      return next();
    },
  },
  {
    path: '/ucom/mailbox-capacity/confirm',
    name: 'UCOM光レジデンス メールボックス容量変更：確認',
    component: MailboxCapacityConfirm,
    meta: {
      title: 'Portas | UCOM光 レジデンス メールボックス容量変更：確認画面 | ポルタス',
      description: 'Portas（ポルタス）でのUCOM光 レジデンス メールボックス容量の変更はこちらから行ってください。',
      keywords: 'UCOM光 レジデンス メールボックス容量の変更 確認画面',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      // メールボックス容量変更：入力画面からの遷移のみ対応
      if (from.path != '/ucom/mailbox-capacity/input') {
        return next({ path: '/ucom/account-option-change/account-list' });
      }
      return next();
    },
  },
  {
    path: '/ucom/mailbox-capacity/completed',
    name: 'UCOM光レジデンス メールボックス容量変更：完了',
    component: MailboxCapacityCompleted,
    meta: {
      title: 'Portas | UCOM光 レジデンス メールボックス容量変更：完了画面 | ポルタス',
      description: 'Portas（ポルタス）でのUCOM光 レジデンス メールボックス容量の変更手続きが完了しました。',
      keywords: 'UCOM光 レジデンス メールボックス容量の変更 完了画面',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      // メールボックス容量変更：確認画面からの遷移のみ対応
      if (from.path != '/ucom/mailbox-capacity/confirm') {
        return next({ path: '/ucom/account-option-change/account-list' });
      }
      return next();
    },
  },
  {
    path: '/ucom/web-space/input',
    name: 'UCOM光レジデンス Webスペース容量変更：入力',
    component: WebSpaceInput,
    meta: {
      title: 'Portas | UCOM光 レジデンス Webスペース容量変更：入力画面 | ポルタス',
      description: '',
      keywords: 'UCOM光 レジデンス Webスペース容量の変更 入力画面',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      // 認可制御
      if (!(await PermissionControlService.isReadable(SERVICE_ID.UCOM_WEB_SPACE_CAPACITY_CHANGE))) {
        return next({ path: '/error' });
      }
      return next();
    },
  },
  {
    path: '/ucom/web-space/confirm',
    name: 'UCOM光レジデンス Webスペース容量変更：確認',
    component: WebSpaceConfirm,
    meta: {
      title: 'Portas | UCOM光 レジデンス Webスペース容量変更：確認画面 | ポルタス',
      description: '',
      keywords: 'UCOM光 レジデンス Webスペース容量の変更 確認画面',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      // 認可制御チェック
      if (!(await PermissionControlService.isReadable(SERVICE_ID.UCOM_WEB_SPACE_CAPACITY_CHANGE))) {
        return next({ path: '/error' });
      }
      // Webスペース容量変更：入力画面からの遷移のみ対応
      if (from.path != '/ucom/web-space/input') {
        return next({ path: '/ucom/account-option-change/account-list' });
      }
      return next();
    },
  },
  {
    path: '/ucom/web-space/complete',
    name: 'UCOM光レジデンス Webスペース容量変更：完了',
    component: WebSpaceComplete,
    meta: {
      title: 'Portas | UCOM光 レジデンス Webスペース容量変更：完了画面 | ポルタス',
      description: '',
      keywords: 'UCOM光 レジデンス Webスペース容量の変更 完了画面',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      // 認可制御チェック
      if (!(await PermissionControlService.isReadable(SERVICE_ID.UCOM_WEB_SPACE_CAPACITY_CHANGE))) {
        return next({ path: '/error' });
      }
      // Webスペース容量変更：確認画面からの遷移のみ対応
      if (from.path != '/ucom/web-space/confirm') {
        return next({ path: '/ucom/account-option-change/account-list' });
      }
      return next();
    },
  },
  {
    path: '/ucom/mail-pack/input',
    name: 'UCOM メールサービス購入：入力',
    component: MailPackInput,
    meta: {
      title: 'Portas | UCOM メールサービス購入：入力 | ポルタス',
      description: '',
      keywords: 'UCOM メールサービス購入：入力',
    },
  },
  {
    path: '/ucom/mail-pack/confirm',
    name: 'UCOM メールサービス購入：確認',
    component: MailPackConfirm,
    meta: {
      title: 'Portas | UCOM メールサービス購入：確認 | ポルタス',
      description: '',
      keywords: 'UCOM メールサービス購入：確認',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      // 遷移チェック
      if (from.path != '/ucom/mail-pack/input') {
        return next({ path: '/ucom/mail-pack/input' });
      }
      return next();
    },
  },
  {
    path: '/ucom/mail-pack/complete',
    name: 'UCOM メールサービス購入：完了',
    component: MailPackComplete,
    meta: {
      title: 'Portas | UCOM メールサービス購入：完了 | ポルタス',
      description: '',
      keywords: 'UCOM メールサービス購入：完了',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      // 遷移チェック
      if (from.path != '/ucom/mail-pack/confirm') {
        return next({ path: '/ucom/mail-pack/input' });
      }
      return next();
    },
  },
  {
    path: '/ucom/account-option-change/mail-input',
    name: 'UCOM アカウントIDオプション変更 メールアドレス登録 入力',
    component: AccountOptionChangeMailAddressInput,
    meta: {
      title: 'Portas | UCOM アカウントIDオプション変更 メールアドレス登録 入力 | ポルタス',
      description: '',
      keywords: 'UCOM アカウントIDオプション変更 メールアドレス登録 入力',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (
        from.path === '/ucom/account-option-change/account-list' ||
        from.path === '/ucom/account-option-change/mail-confirm' ||
        from.path === '/ucom/account-management/list' ||
        from.path === '/ucom/account-management/completed'
      ) {
        store.commit('ucomAccountOptionStore/beforePath', from.path);
        return next();
      }
      return next({ path: '/ucom/account-option-change/account-list' });
    },
  },
  {
    path: '/ucom/account-option-change/mail-confirm',
    name: 'UCOM アカウントIDオプション変更 メールアドレス登録 確認',
    component: AccountOptionChangeMailAddressConfirm,
    meta: {
      title: 'Portas | UCOM アカウントIDオプション変更 メールアドレス登録 確認 | ポルタス',
      description: '',
      keywords: 'UCOM アカウントIDオプション変更 メールアドレス登録 確認',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/ucom/account-option-change/mail-input') {
        return next({ path: '/ucom/account-option-change/mail-input' });
      }
      return next();
    },
  },
  {
    path: '/ucom/account-option-change/mail-completed',
    name: 'UCOM アカウントIDオプション変更 メールアドレス登録 完了',
    component: AccountOptionChangeMailAddressCompleted,
    meta: {
      title: 'Portas | UCOM アカウントIDオプション変更 メールアドレス登録 完了 | ポルタス',
      description: '',
      keywords: 'UCOM アカウントIDオプション変更 メールアドレス登録 完了',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/ucom/account-option-change/mail-confirm') {
        next({ path: '/ucom/account-option-change/mail-input' });
        return;
      }
      return next();
    },
  },
  {
    path: '/ucom/virus-check/input',
    name: 'UCOM ウイルスチェック：入力',
    component: VirusCheckInput,
    meta: {
      title: 'Portas | UCOM ウイルスチェック：入力 | ポルタス',
      description: '',
      keywords: 'UCOM ウイルスチェック：入力',
    },
  },
  {
    path: '/ucom/virus-check/purchase-confirm',
    name: 'UCOM ウイルスチェック：購入／解約確認',
    component: VirusCheckPurchaseConfirm,
    meta: {
      title: 'Portas | UCOM ウイルスチェック：購入／解約確認 | ポルタス',
      description: '',
      keywords: 'UCOM ウイルスチェック：購入／解約確認',
    },
    beforeEnter: async (_to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/ucom/virus-check/input') {
        return next({ path: '/ucom/virus-check/input' });
      }
      // 遷移先で必要となるデータがストアされているかチェック
      const purchaseState = store.getters['ucomVirusCheckStore/purchaseState'];
      if (!purchaseState) {
        // ストアされていなければ UCOM アカウントID情報一覧 画面に戻る
        next({ path: '/ucom/account-option-change/account-list' });
        return;
      }
      next();
    },
  },
  {
    path: '/ucom/virus-check/purchase-complete',
    name: 'UCOM ウイルスチェック：購入／解約完了',
    component: VirusCheckPurchaseComplete,
    meta: {
      title: 'Portas | UCOM ウイルスチェック：購入／解約完了 | ポルタス',
      description: '',
      keywords: 'UCOM ウイルスチェック：購入／解約完了',
    },
    beforeEnter: async (_to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
      // お申込み内容がストアされていなければ UCOM アカウントID情報一覧 画面に戻る
      const purchaseState = store.getters['ucomVirusCheckStore/purchaseState'];
      if (!purchaseState) {
        next({ path: '/ucom/account-option-change/account-list' });
        return;
      }
      // 通知先メールアドレスがストアされていなければ UCOM アカウントID情報一覧 画面に戻る
      const notifyMailAddress = store.getters['ucomVirusCheckStore/notifyMailAddress'];
      if (!notifyMailAddress) {
        next({ path: '/ucom/account-option-change/account-list' });
        return;
      }
      next();
    },
  },
  {
    path: '/ucom/virus-check/pause-confirm',
    name: 'UCOM ウイルスチェック：一時停止／開始確認',
    component: VirusCheckPauseConfirm,
    meta: {
      title: 'Portas | UCOM ウイルスチェック：一時停止／開始確認 | ポルタス',
      description: 'Portas（ポルタス）でのUCOM光 レジデンス ウイルスチェック 一時停止／開始確認ページです。',
      keywords: 'UCOM, ウイルスチェック, 一時停止, 開始, 確認, Portas, ポルタス',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      // UCOM ウイルスチェック：入力から遷移のみ対応
      if (from.path === '/ucom/virus-check/input') {
        return next();
      }
      // UCOM ウイルスチェック：入力 画面へ戻す
      return next({ path: '/ucom/virus-check/input' });
    },
  },
  {
    path: '/ucom/account-option-change/web-space/confirm',
    name: 'UCOM アカウントIDオプション変更 Webスペース登録 確認',
    component: AccountOptionChangeWebSpaceConfirm,
    meta: {
      title: 'Portas | UCOM アカウントIDオプション変更 Webスペース登録 確認 | ポルタス',
      description: '',
      keywords: 'UCOM アカウントIDオプション変更 Webスペース登録 確認',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      // 認可制御チェック
      if (!(await PermissionControlService.isReadable(SERVICE_ID.UCOM_ACCOUNT_ID_OPTION_CHANGE))) {
        return next({ path: '/error' });
      }
      // UCOM アカウントIDオプション変更 アカウントID情報一覧画面からの遷移のみ対応
      if (from.path != '/ucom/account-option-change/account-list') {
        return next({ path: '/ucom/account-option-change/account-list' });
      }
      return next();
    },
  },
  {
    path: '/ucom/account-option-change/web-space/completed',
    name: 'UCOM アカウントIDオプション変更 Webスペース登録 完了',
    component: AccountOptionChangeWebSpaceCompleted,
    meta: {
      title: 'Portas | UCOM アカウントIDオプション変更 Webスペース登録 完了 | ポルタス',
      description: '',
      keywords: 'UCOM アカウントIDオプション変更 Webスペース登録 完了',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      // 認可制御チェック
      if (!(await PermissionControlService.isReadable(SERVICE_ID.UCOM_ACCOUNT_ID_OPTION_CHANGE))) {
        return next({ path: '/error' });
      }
      // UCOM アカウントIDオプション変更 Webスペース登録 確認からの遷移のみ対応
      if (from.path != '/ucom/account-option-change/web-space/confirm') {
        return next({ path: '/ucom/account-option-change/account-list' });
      }
      return next();
    },
  },

  {
    path: '/ucom/account-management/delete',
    name: 'UCOM アカウントID管理 削除 確認',
    component: AccountInfoForDelete,
    meta: {
      title: 'Portas | UCOM アカウントID管理 削除 確認 | ポルタス',
      description: '',
      keywords: 'UCOM アカウントID管理 削除 確認',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (!(await PermissionControlService.isScreenUpdatable(SERVICE_ID.UCOM_ACCOUNT_ID_MANAGEMENT, SCREEN_ID.UCOM_ACCOUNT_INFO_FOR_CANCEL))) {
        return next({ path: '/error' });
      }
      if (from.path !== '/ucom/account-management/list' && from.path !== '/ucom/account-management/delete-confirmation') {
        return next({ path: '/ucom/account-management/list' });
      }
      return next();
    },
  },
  {
    path: '/ucom/account-management/delete-confirmation',
    name: 'UCOM アカウントID管理 メールサービス解約確認',
    component: AccountInfoForDeleteConfirmation,
    meta: {
      title: 'Portas | UCOM アカウントID管理 メールサービス解約確認 | ポルタス',
      description: '',
      keywords: 'UCOM アカウントID管理 メールサービス解約確認',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (!(await PermissionControlService.isScreenUpdatable(SERVICE_ID.UCOM_ACCOUNT_ID_MANAGEMENT, SCREEN_ID.UCOM_ACCOUNT_CANCEL_CONFIRMATION))) {
        return next({ path: '/error' });
      }
      if (from.path !== '/ucom/account-management/delete') {
        return next({ path: '/ucom/account-management/list' });
      }
      return next();
    },
  },
  {
    path: '/ucom/account-management/delete-complete',
    name: 'UCOM アカウントID管理 削除 完了',
    component: AccountInfoForDeleteComplete,
    meta: {
      title: 'Portas | UCOM アカウントID管理 削除 完了 | ポルタス',
      description: '',
      keywords: 'UCOM アカウントID管理 削除 完了',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (!(await PermissionControlService.isScreenUpdatable(SERVICE_ID.UCOM_ACCOUNT_ID_MANAGEMENT, SCREEN_ID.UCOM_ACCOUNT_CANCEL_COMPETE))) {
        return next({ path: '/error' });
      }
      if (from.path !== '/ucom/account-management/delete' && from.path !== '/ucom/account-management/delete-confirmation') {
        return next({ path: '/ucom/account-management/list' });
      }
      return next();
    },
  },
  {
    path: '/ucom/virus-check/pause-completed',
    name: 'UCOM ウイルスチェック：一時停止/開始完了',
    component: VirusCheckPauseCompleted,
    meta: {
      title: 'Portas | UCOM ウイルスチェック：一時停止/開始完了 | ポルタス',
      description: '',
      keywords: 'UCOM ウイルスチェック：一時停止/開始完了',
    },
    beforeEnter: (_to: Route, from: Route, next: NavigationGuardNext): void => {
      if (from.path !== '/ucom/virus-check/pause-confirm') {
        return next({ path: '/ucom' });
      }
      return next();
    },
  },
];
export default ucomRouter;
