/**
 * UCOM 共通利用 API : 物件基本情報取得 : レスポンス
 *
 * 外部 API のレスポンス仕様により、階層構造になっている
 */
export class UcomPropertyResponseResult {
  /** 結果値 */
  public result!: UcomPropertyResponse;

  constructor(partial: Partial<UcomPropertyResponseResult>) {
    Object.assign(this, partial);
  }
}

/**
 * UCOM 共通利用 API : 物件基本情報取得 : レスポンス 本体
 *
 * 外部 API のレスポンス仕様に合わせてスネークケースで命名している
 */
export class UcomPropertyResponse {
  /** 設置先郵便番号 */
  public postal_code!: string;
  /** 漢字都道府県 */
  public address_prefectures!: string;
  /** 漢字市区郡町村 */
  public address_municipalities!: string;
  /** 漢字大字・通称 */
  public address_town!: string;
  /** 漢字字名・丁目 */
  public address_street!: string;
  /** 漢字番地 */
  public address_number!: string;
  /** 設置先物件名 */
  public building_name!: string;
  /** IP電話サービスのお申し込みの可・不可 UCOM光電話 */
  public is_possible_opitcal_phone!: boolean;
  /** IP電話サービスのお申し込みの可・不可 GATE CALL050 (M) */
  public is_possible_gate_call_050!: boolean;
  /** UCOM光電話・事務手数料 */
  public optical_phone_commission!: string;
  /** UCOM光電話・月額基本料金 */
  public optical_phone_monthly_basic_charge!: string;
  /** UCOM光電話・月額機器利用料金 */
  public optical_phone_monthly_usage_fee!: string;
  /** GATE CALL050 (M)・月額基本料金 */
  public gate_call_050_monthly_basic_charge!: string;
  /** GATE CALL050 (M)・月額専用アダプタレンタル料 */
  public gate_call_050_monthly_adapter_rental_fee!: string;
  /** 契約種別 */
  public contract_kind_id!: string;
  /** サービス提供元企業ID */
  public service_provid_former!: ServiceProvidFormer;
  /** サービス提供企業ID */
  public service_supply_company_id!: string;
  /** ロゴURL */
  public logo_url!: string;
  /** 月額基本料金 */
  public monthly_basic_charge!: string;
  /** Connectixの提供開始日 */
  public connectix_start_date!: string;
  /** 特殊物件：お申し込み可不可 可能/不可 true/false */
  public is_possible_registration!: boolean;

  constructor(partial: Partial<UcomPropertyResponse>) {
    Object.assign(this, partial);
  }
}

/** サービス提供元企業 */
export class ServiceProvidFormer {
  /** サービス提供元企業ID */
  public service_provid_former_id!: string;
  /** サービス提供元企業名 */
  public service_provid_former_name!: string;

  constructor(partial: Partial<ServiceProvidFormer>) {
    Object.assign(this, partial);
  }
}
