































































































import Vue from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { SpfApiMailPackAccessor } from '@/infra/accessor/spf/ucom/spf-api-mail-pack-accessor';
import { UcomMailPackInfo } from '@/shared/classes/spf-api/model/ucom/ucom-mail-pack-info';

export default Vue.extend({
  name: 'inputMailPack',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    // メソッドが実行中かどうか
    isLoading: true,
    mailPackInfo: UcomMailPackInfo,
    check: false,
    strContractStatus: '',
    messageList: [] as string[],
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    tax: 10,
    isPayMent: true,
    isActiveButton: false,
    paymentUrl: `${process.env.VUE_APP_BASE_URL}` + process.env.VUE_APP_UCOM_PAYMENT_REGISTER_URL,
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      //Portas会員IDを取得
      const memberId = this.$store.getters['memberStore/member'].id;
      //メールサービスのご契約状況をAPI(AU08-001)より取得する
      this.$data.mailPackInfo = await SpfApiMailPackAccessor.getUcomMailPackInfo(memberId);
      if (this.$data.mailPackInfo.paymentStatus == false) {
        this.$data.isPayMent = false;
        this.isLoading = false;
        return;
      }
      if (this.$data.mailPackInfo.errorMessage) {
        this.messageList.push(this.$data.mailPackInfo.errorMessage);
      }
      //表示するための文言に変換
      this.$data.strContractStatus = this.$data.mailPackInfo.contractStatus.value;
      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/error');
      return;
    }
  },
  methods: {
    //購入ボタン
    async onClick() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      //バリデーションチェック
      if (!this.$data.check) {
        this.$data.messageList = ['「サービス説明への同意」の「上記の内容に同意する」をチェックしてください。'];
        window.scrollTo(0, 0);
        this.$data.isLoading = false;
        return;
      }
      //次画面への項目受け渡し
      this.$store.commit('ucomMailPackStore/productName', this.$data.mailPackInfo.productName);
      this.$store.commit('ucomMailPackStore/monthlyFee', this.$data.mailPackInfo.monthlyFee);
      const now = await SpfApiServerCommonUtilAccessor.getServerTimeNow();
      this.$store.commit('ucomMailPackStore/processDate', now);
      this.$store.commit('ucomMailPackStore/serviceDescription', this.$data.mailPackInfo.serviceDescription);
      this.$store.commit('ucomMailPackStore/isActiveButton', this.$data.isActiveButton);
      //次画面に遷移
      await this.$router.push('/ucom/mail-pack/confirm');
      this.isLoading = false;
    },
    judgeIsActiveButton(isCheck: boolean) {
      if (this.$data.mailPackInfo.isMax) {
        this.$data.isActiveButton = false;
        return;
      }
      if (!isCheck) {
        this.$data.isActiveButton = false;
        return;
      }
      this.$data.isActiveButton = true;
    },
  },
});
