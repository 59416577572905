export class EMansionHomepageCapacityList {
  readonly _eMansionHomepageId!: number;
  readonly _ftpAccount!: string;
  readonly _homepageCapacity!: string;
  readonly _canChangeCapacity!: string;
  readonly _isSameMonthChange!: string;
  readonly _errorMessages!: string[];

  constructor(partial: Partial<EMansionHomepageCapacityList>) {
    Object.assign(this, partial);
  }

  get eMansionHomepageId(): number {
    return this._eMansionHomepageId;
  }
  get ftpAccount(): string {
    return this._ftpAccount;
  }
  get homepageCapacity(): string {
    return this._homepageCapacity;
  }
  get canChangeCapacity(): string {
    return this._canChangeCapacity;
  }
  get isSameMonthChange(): string {
    return this._isSameMonthChange;
  }
  get errorMessages(): string[] {
    return this._errorMessages;
  }
}
