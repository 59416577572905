import { UaTypeValue } from "@/shared/const/service-type";

/**
 * e-mansion 共通利用 API : 新規会員登録 : リクエスト
 *
 * 外部 API のレスポンス仕様により、階層構造になっている
 */
export class EMansionCustomerCreateRequestResult {
  /**結果値 */
  public request!: EMansionCustomerCreateRequest;

  constructor(partial: Partial<EMansionCustomerCreateRequest>) {
    Object.assign(this, partial);
  }
}


/**
 * e-mansion 共通利用 API : 新規会員登録 : リクエスト
 *
 * 外部 API のレスポンス仕様に合わせてスネークケースで命名している
 */
export class EMansionCustomerCreateRequest {
  /**UA種別 */
  public readonly ua_type!: UaTypeValue;
  /**物件ID */
  public readonly property_id!: string;
  /**棟ID */
  public readonly building_ID!: string;
  /**部屋番号 */
  public readonly room_number!: string;
  /**姓 */
  public readonly name_surname!: string;
  /**名 */
  public readonly name_first_name!: string;
  /**姓カナ */
  public readonly kana_surname!: string;
  /**名カナ */
  public readonly kana_first_name!: string;
  /**性別 */
  public readonly sex!: string;
  /**誕生日 */
  public readonly birthday!: string;
  /**電話番号 */
  public readonly phone_number!: string;
  /**緊急連絡先 */
  public readonly emergency_phone_number?: string;
  /**FAX番号 */
  public readonly fax_number?: string;
  /**連絡先メールアドレス */
  public readonly email_address!: string;
  /**入居予定日 */
  public readonly scheduled_move_in_on?: string;
  /**書類送付先種別 */
  public readonly destination_kind!: string;
  /**郵便番号(ハイフンなし) */
  public readonly destination_zipcode!: string;
  /**住所1 */
  public readonly destination_address1!: string;
  /**住所2 */
  public readonly destination_address2?: string;
  /**連絡先電話番号 */
  public readonly destination_telephone!: string;
  /**希望メールアドレス */
  public readonly desired_account!: string;
  /**ウイルスチェック安心サービス */
  public readonly virus_check_service?: string;
  /**e-mansion IPフォン */
  public readonly ip_phone?: string;
  /**TA配送日(自動計算) */
  public readonly 'delivery_date'?: string;
  /**TA配送時間帯 */
  public readonly 'delivery_time_zone'?: string;
  /**マルチデバイスセキュリティ */
  public readonly multi_device_security?: string;
  /**iフィルター */
  public readonly i_filter?: string;
  /**クレジットカードトークン */
  public readonly credit_card_token?: string;
  /**トークン有効期限 */
  public readonly token_expire_date?: string;
  /**ご契約内容の電子交付希望 */
  public readonly electronicdelivery!: string;
  /**電子交付用メールアドレス */
  public readonly electronicdelivery_email_address?: string;
  /**スリムプラン用 */
  public readonly contract_destination_zipcode?: string;
  /**スリムプラン用 */
  public readonly contract_destination_address1?: string;
  /**スリムプラン用 */
  public readonly contract_destination_address2?: string;
  /**接続コースID */
  public readonly course_id!: string;
  /**アダプタ */
  public readonly adapter?: string;
  /**ご希望パスワード */
  public readonly password!: string;
  /**UCOM光電話 */
  public readonly hikariphone?: string;
  /**開通希望日 */
  public readonly uh_preferred_date?: string;
  /**番号ポータビリティお申し込み */
  public readonly uh_number_portability_flag?: string;
  /**番号ポータビリティ電話番号 */
  public readonly uh_portability_number?: string;
  /**名義人氏名 */
  public readonly uh_name?: string;
  /**名義人氏名カナ */
  public readonly uh_kana?: string;
  /**続柄 */
  public readonly uh_relationship_type?: string;
  /**電話会社種別 */
  public readonly uh_telephone_company_type?: string;
  /**電話会社 */
  public readonly uh_telephone_company_other?: string;
  /**登録住所郵便番号(ハイフン無し) */
  public readonly uh_destination_zipcode?: string;
  /**登録住所1 */
  public readonly uh_destination_address1?: string;
  /**登録住所2 */
  public readonly uh_destination_address2?: string;
  /**着信番号表示 */
  public readonly uh_option_1?: string;
  /**キャッチ通話 */
  public readonly uh_option_2?: string;
  /**キャッチ通話番号表示 */
  public readonly uh_option_3?: string;
  /**オプションサービスパック */
  public readonly uh_option_4?: string;
  /**番号通知 */
  public readonly uh_notification_flag?: string;

  constructor(partial: Partial<EMansionCustomerCreateRequest>) {
    Object.assign(this, partial);
  }
}

/**
 * e-mansion 共通利用 API : 会員情報変更 : リクエスト
 *
 * 外部 API のレスポンス仕様により、階層構造になっている
 */
export class EMansionCustomerUpdateRequestResult {
  public request!: EMansionCustomerUpdateRequest
  constructor(partial: Partial<EMansionCustomerUpdateRequest>) {
    Object.assign(this, partial);
  }
}

/**
 * e-mansion 共通利用 API : 会員情報変更 : リクエスト本体
 *
 * 外部 API のレスポンス仕様に合わせてスネークケースで命名している
 */
export class EMansionCustomerUpdateRequest {
  /**UA種別 */
  public readonly ua_type!: UaTypeValue;
  /**物件ID */
  public readonly property_id!: string;
  /**棟ID */
  public readonly building_id?: string;
  /**お部屋番号 */
  public readonly room_number?: string;
  /**名前(姓) */
  public readonly first_name?: string;
  /**名前(名) */
  public readonly given_name?: string;
  /**名前フリガナ(姓) */
  public readonly first_name_kana?: string;
  /**名前フリガナ(名) */
  public readonly given_name_kana?: string;
  /**郵便番号 */
  public readonly postal_code?: string;
  /**住所(都道府県) */
  public readonly prefecture_id?: string;
  /**住所(市区町村) */
  public readonly city?: string;
  /**住所(番地) */
  public readonly block?: string;
  /**メールアドレス */
  public readonly email_address?: string;

  constructor(partial: Partial<EMansionCustomerUpdateRequest>) {
    Object.assign(this, partial);
  }
}

export class EMansionEmailAuthenticationKeyRequestResult {
  public request!: EMansionEmailAuthenticationKeyRequest;

  constructor(partial: Partial<EMansionCustomerUpdateRequest>) {
    Object.assign(this, partial);
  }
}

export class EMansionEmailAuthenticationKeyRequest {
  public readonly ua_type!: UaTypeValue;
  public readonly property_id!: string;
  public readonly email_authentication_key!: string;

  constructor(partial: Partial<EMansionEmailAuthenticationKeyRequest>) {
    Object.assign(this, partial);
  }
}

export interface FindAccountValidationByIdQueryDto {
  ua_type: UaTypeValue;
  property_id: string;
  account_id: string;
}
