import { EMansionCustomer } from '@/shared/classes/external-api/e-mansion/customer-response';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { EMansionAccountInfo } from '@/shared/classes/spf-api/mail/model/account/e-mansion-account-info';
import { E_MANSION_ACCOUNT_AUTHORITY } from '@/shared/const/e-mansion/e-mansion-account-authority';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import store from '@/store';

/**
 * e-mansion 会員種別決定サービス
 */
export class EMansionPlanTypeService {
  /**
   * e-mansionアカウントの会員種別取得
   * @returns SERVICE_PLAN_TYPE 会員種別
   */
  public static getAccountPlanType(): SERVICE_PLAN_TYPE {
    const accountInfo: EMansionAccountInfo = store.getters['eMansionAccountStore/eMansionAccountInfo'];

    const account = accountInfo.eMansionAccountInfo;
    const planType = account.accountAuthorization === E_MANSION_ACCOUNT_AUTHORITY.MAIN_ACCOUNT ? this.getMainAccountPlanType(store) : SERVICE_PLAN_TYPE.EM_ADDITIONAL;

    return planType;
  }

  /**
   * e-mansionのメインアカウントの会員種別取得
   * @returns SERVICE_PLAN_TYPE 会員種別
   */
  private static getMainAccountPlanType(store: any): SERVICE_PLAN_TYPE {
    const customer: EMansionCustomer = store.getters['eMansionCommonStore/customer'];
    const property: EMansionProperty = store.getters['eMansionCommonStore/property'];
    const accountInfo: EMansionAccountInfo = store.getters['eMansionAccountStore/eMansionAccountInfo'];

    const FLAG_TRUE = '1';

    if (!property.apartment_special_type_flag) {
      return SERVICE_PLAN_TYPE.DEFAULT;
    }

    const flags = property.apartment_special_type_flag[0];

    if (flags[2] === FLAG_TRUE) {
      return SERVICE_PLAN_TYPE.EM_TTP;
    }
    if (flags[4] === FLAG_TRUE) {
      return accountInfo.eMansionMailAddressInfo ? SERVICE_PLAN_TYPE.EM_SLIM_SPECIAL : SERVICE_PLAN_TYPE.EM_SLIM;
    }
    if (customer.email_member_flag === FLAG_TRUE) {
      return flags[3] === FLAG_TRUE ? SERVICE_PLAN_TYPE.EM_MAIL_CHARGE : SERVICE_PLAN_TYPE.EM_MAIL_FREE;
    }
    if (flags[0] === FLAG_TRUE) {
      return SERVICE_PLAN_TYPE.EM_NORMAL;
    }
    return SERVICE_PLAN_TYPE.DEFAULT;
  }
}
