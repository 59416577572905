




















































































import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';

export default Vue.extend({
  name: 'web-space-complete',
  components: {
    LoadingComponent,
  },
  data: () => ({
    accountName: '', // UCOM_アカウント名
    mailAddress: '', // メールアドレス
    notifyMailAddress: '', // 通知先メールアドレス
    homepageAddress: '', // ホームページアドレス
    webSpaceCapacity: '', // 現在のWebスペース容量
    afterChangeWebSpaceCapacityLabel: '', // 追加/縮小するWebスペース容量のLabel
    displayAfterChangeWebSpaceCapacityLabel: '', // 表示用追加/縮小するWebスペース容量のLabel
    unitPrice: '', // 月額利用料
    applicationDate: '', // お申込み日
    serviceDescription: '', // サービス説明
    isLoading: false, // ローディング
  }),
  async mounted() {
    try {
      this.$data.isLoading = true;

      const accountName = await this.$store.getters['ucomWebSpaceStore/accountName'];
      if (!accountName) {
        await this.$router.push('/ucom/account-option-change/account-list');
        return;
      }

      // 前画面からの情報を取得し画面項目にセット
      this.setDataByStore();

      // storeをクリア
      this.deleteStore();
      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/error');
      return;
    }
  },
  methods: {
    // storeから画面項目データをセット
    setDataByStore(): void {
      this.$data.mailAddress = this.$store.getters['ucomWebSpaceStore/mailAddress'];
      this.$data.notifyMailAddress = this.makeMailAddressForView(this.$store.getters['ucomWebSpaceStore/notifyMailAddress']);
      this.$data.accountName = this.$store.getters['ucomWebSpaceStore/accountName'];
      this.$data.homepageAddress = this.$store.getters['ucomWebSpaceStore/homepageAddress'];
      this.$data.webSpaceCapacity = this.$store.getters['ucomWebSpaceStore/webSpaceCapacity'];
      this.$data.afterChangeWebSpaceCapacityLabel = this.$store.getters['ucomWebSpaceStore/changeCapacityLabel'];
      this.$data.unitPrice = this.$store.getters['ucomWebSpaceStore/unitPrice'];
      this.$data.applicationDate = this.$store.getters['ucomWebSpaceStore/applicationDate'];
      this.$data.serviceDescription = this.$store.getters['ucomWebSpaceStore/serviceDescription'];

      // 追加/縮小容量を表示用に加工
      if (String(this.$data.afterChangeWebSpaceCapacityLabel.toString().charAt(0)) === '-') {
        this.$data.displayAfterChangeWebSpaceCapacityLabel = this.$data.afterChangeWebSpaceCapacityLabel;
      }
      if (String(this.$data.afterChangeWebSpaceCapacityLabel.toString().charAt(0)) !== '-') {
        this.$data.displayAfterChangeWebSpaceCapacityLabel = '+' + this.$data.afterChangeWebSpaceCapacityLabel;
      }
    },
    // storeをクリア
    deleteStore(): void {
      this.$store.commit('ucomWebSpaceStore/ucomAccountMailId', null);
      this.$store.commit('ucomWebSpaceStore/accountName', null);
      this.$store.commit('ucomWebSpaceStore/mailAddress', null);
      this.$store.commit('ucomWebSpaceStore/homepageAddress', null);
      this.$store.commit('ucomWebSpaceStore/webSpaceCapacity', null);
      this.$store.commit('ucomWebSpaceStore/changeCapacityLabel', null);
      this.$store.commit('ucomWebSpaceStore/changeCapacityValue', null);
      this.$store.commit('ucomWebSpaceStore/unitPrice', null);
      this.$store.commit('ucomWebSpaceStore/webSpaceMaxCapacity', null);
      this.$store.commit('ucomWebSpaceStore/serviceDescription', null);
      this.$store.commit('ucomWebSpaceStore/notifyMailAddress', null);
    },
    makeMailAddressForView(mailAddress: string[]): string {
      if (!mailAddress[0]) {
        return mailAddress[1];
      }
      if (mailAddress[0] === '') {
        return mailAddress[1];
      }
      return `${mailAddress[0]}, ${mailAddress[1]}`;
    },
    // 「UCOM光レジデンス会員専用トップ」ボタンクリック
    async onUcomTop(): Promise<void> {
      if (this.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      // データの変更は無いのでそのまま入力画面へ遷移
      await this.$router.push('/ucom');
      this.$data.isLoading = false;
    },
    // ページトップへスクロール
    scrollPageTop(): void {
      window.scrollTo(0, 0);
    },
  },
});
