







































































import Vue from "vue";
import LoadingComponent from '@/shared/components/loading-component.vue';

/** Confirm コンポーネント */
export default Vue.extend({
  name: "five-a-connectix-completed",
  components: {
    LoadingComponent
  },
  data: () => ({
    // navigateToLocation() 実行中かどうか
    isExecutingNavigateToLocation: false
  }),
  methods: {
    navigateToLocation(path: string) {
      if (this.isExecutingNavigateToLocation) {
        return;
      }
      this.isExecutingNavigateToLocation = true;

      this.$router.push(path).finally( () => {
        this.isExecutingNavigateToLocation = false;
      });
    }
  }
});
