














































































import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import { SpfApiHomepageAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-homepage-accessor';
import { checkRouterError } from '@/shared/util/router-navigation-func';

export default Vue.extend({
  name: 'homepage-application',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },

  data: () => ({
    isLoading: true,
    /** エラーメッセージを格納する配列 */
    errorMessages: [],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    requestUrl: undefined,
    URL_MINIMUM_LEN: 3,
    URL_MAX_LEN: 12,
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      this.$data.isLoading = false;
      this.$data.requestUrl = await this.$store.getters['eMansionHomepageOpenStore/requestUrl'];
    } catch (error) {
      await this.$router.push('/e-mansion/error');
      return;
    }
  },
  methods: {
    async goNext() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.$data.errorMessages = [];

      /** バリデーション処理 */
      // 空欄
      if (!this.$data.requestUrl) {
        this.$data.errorMessages.push('「ご希望URL」を入力してください。');
        this.isLoading = false;
        window.scrollTo(0, 0);
        return;
      }
      // 長さ
      if (!(await this.isValidUrlLength())) {
        this.$data.errorMessages.push('「ご希望URL」は、3文字以上12文字以内で入力してください。');
        this.isLoading = false;
        window.scrollTo(0, 0);
        return;
      }

      try {
        // AE05-001 ホームページ：お申込み_入力チェックAPIの呼び出し
        const response = await SpfApiHomepageAccessor.callApplicationInputCheck(this.$data.requestUrl);

        // エラーメッセージがあれば画面に表示する
        if (response.errorMessages.length > 0) {
          this.$data.errorMessages = response.errorMessages;
          window.scrollTo(0, 0);
          this.$data.isLoading = false;
          return;
        }

        // URLを設定
        this.$store.commit('eMansionHomepageOpenStore/requestUrl', response.requestUrl);
        this.$data.isLoading = false;
        // ホームページ：お申し込み確認画面に遷移する
        return await this.$router.push('/e-mansion/homepage/application-confirm').catch((error) => {
          checkRouterError(error);
        });
      } catch (error) {
        await this.$router.push('/e-mansion/error');
        this.$data.isLoading = false;
      }
    },
    /**
     * ご希望URLの長さバリデーション
     * @param requestUrl ご希望URL
     * @return true: 有効なURL or false: 2文字以下か13文字以上
     */
    async isValidUrlLength() {
      const length = this.$data.requestUrl.length;
      /** 2文字以下の場合 or 13文字以上の場合 */
      if (length < this.URL_MINIMUM_LEN) {
        return false;
      }

      return this.URL_MAX_LEN >= length;
    },
  },
});
