


















































































import Vue from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiMailPackAccessor } from '@/infra/accessor/spf/ucom/spf-api-mail-pack-accessor';
import { UcomMailPackInfo } from '@/shared/classes/spf-api/model/ucom/ucom-mail-pack-info';

export default Vue.extend({
  name: 'confirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    // メソッドが実行中かどうか
    isLoading: true,
    mailPackInfo: UcomMailPackInfo,
    check: false,
    messageList: [] as string[],
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    hasError: false,
    processDate: undefined,
    isActiveButton: undefined,
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      //不正な遷移
      this.$data.isActiveButton = this.$store.getters['ucomMailPackStore/isActiveButton'];
      if (!this.$data.isActiveButton) {
        await this.$router.push('/ucom/mail-pack/input');
        return;
      }
      //リロードでデータが消えたとき
      this.$data.mailPackInfo.productName = this.$store.getters['ucomMailPackStore/productName'];
      if (!this.$data.mailPackInfo.productName) {
        await this.$router.push('/ucom/mail-pack/input');
        return;
      }
      this.$data.mailPackInfo.monthlyFee = this.$store.getters['ucomMailPackStore/monthlyFee'];
      if (!this.$data.mailPackInfo.monthlyFee) {
        await this.$router.push('/ucom/mail-pack/input');
        return;
      }
      this.$data.processDate = this.$store.getters['ucomMailPackStore/processDate'];
      if (!this.$data.processDate) {
        await this.$router.push('/ucom/mail-pack/input');
        return;
      }
      this.$data.processDate = this.formatDate(this.$data.processDate);
      this.$data.mailPackInfo.serviceDescription = this.$store.getters['ucomMailPackStore/serviceDescription'];
      if (!this.$data.mailPackInfo.serviceDescription) {
        await this.$router.push('/ucom/mail-pack/input');
        return;
      }
      if (this.$data.messageList.length > 0) {
        this.$data.hasError = true;
      }
      this.isLoading = false;
    } catch (error) {
      await this.$router.push('/error');
      return;
    }
  },
  methods: {
    //戻るボタン
    async goBack() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      await this.$router.push('/ucom/mail-pack/input');
      this.isLoading = false;
    },
    //申し込むボタン
    async goNext() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.$data.messageList = [];
      const memberId = this.$store.getters['memberStore/member'].id;
      const ucomAccountId = this.$store.getters['ucomAccountStore/ucomAccount'].accountInfo.ucomAccountId;
      try {
        //AU08-003により購入情報を更新
        const updateUcomMailPack = await SpfApiMailPackAccessor.updateUcomMailPackInfo(
          memberId,
          ucomAccountId,
          this.$data.processDate,
          this.$data.mailPackInfo.monthlyFee,
          this.$data.mailPackInfo.productName
        );

        if (updateUcomMailPack.message) {
          this.$data.hasError = true;
          this.$data.messageList.push(updateUcomMailPack.message);
          window.scrollTo(0, 0);
          this.$data.isLoading = false;
          return;
        }
        this.$store.commit('ucomMailPackStore/contactMailAddress', updateUcomMailPack.mailAddress);
        this.$store.commit('ucomMailPackStore/productName', this.$data.mailPackInfo.productName);
        this.$store.commit('ucomMailPackStore/monthlyFee', this.$data.mailPackInfo.monthlyFee);
        this.$store.commit('ucomMailPackStore/processDate', this.$data.processDate);
        this.$store.commit('ucomMailPackStore/serviceDescription', this.$data.mailPackInfo.serviceDescription);
        await this.$router.push('/ucom/mail-pack/complete');
        this.isLoading = false;
      } catch (error) {
        await this.$router.push('/error');
        return;
      }
    },
    //Topへボタン
    async goTop() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      await this.$router.push('/ucom');
      this.$data.isLoading = false;
    },
    formatDate(dt: Date) {
      const y = dt.getFullYear();
      const m = ('00' + (dt.getMonth() + 1)).slice(-2);
      const d = ('00' + dt.getDate()).slice(-2);
      return y + '/' + m + '/' + d;
    },
  },
});
