




































































































import Vue from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiSubAccountAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-sub-account-accessor';
import { subAccountCancelInfo } from '@/shared/classes/spf-api/mail/model/sub-account/e-mansion-sub-account-cancel-info';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';

export default Vue.extend({
  name: 'cancel-confirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    // メソッドが実行中かどうか
    isLoading: true,
    accountName: '',
    mailAddress: '',
    messageList: [],
    subAccountCancelInfo: subAccountCancelInfo,
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      this.$data.accountName = this.$store.getters['eMansionSubAccountStore/account'];
      this.$data.mailAddress = this.$store.getters['eMansionSubAccountStore/mailAddress'];
      this.$data.accountId = this.$store.getters['eMansionSubAccountStore/eMansionAccountId'];

      //リロードで表示する値がなくなったときは利用状況・入力画面に戻す
      if (!this.$data.accountName) {
        await this.$router.push('/e-mansion/sub-account/list');
        return;
      }
      if (!this.$data.mailAddress) {
        await this.$router.push('/e-mansion/sub-account/list');
        return;
      }
      if (!this.$data.accountId) {
        await this.$router.push('/e-mansion/sub-account/list');
        return;
      }
      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/e-mansion/error');
      return;
    }
  },
  methods: {
    /** 戻るボタン押下時 */
    async goBack() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      await this.$router.push('/e-mansion/sub-account/list');
      this.isLoading = false;
    },
    /** 次へボタン押下時 */
    async goCancel() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      //AE17-003を呼ぶ
      try {
        const processOn = await SpfApiServerCommonUtilAccessor.getServerTimeNow();
        this.$data.subAccountCancelInfo = await SpfApiSubAccountAccessor.cancelSubAccount(Number(this.$data.accountId), processOn);

        //エラーがある場合は表示
        if (this.$data.subAccountCancelInfo.errorMessage.length > 0) {
          this.$data.messageList = this.$data.subAccountCancelInfo.errorMessage;
          window.scrollTo(0, 0);
          this.$data.isLoading = false;
          return;
        }

        //Storeに保存
        this.$store.commit('eMansionSubAccountStore/account', this.$data.subAccountCancelInfo.accountName);
        this.$store.commit('eMansionSubAccountStore/mailAddress', this.$data.subAccountCancelInfo.mailAddress);
        this.$store.commit('eMansionSubAccountStore/cancelOn', this.formatDate(new Date(this.$data.subAccountCancelInfo.cancelOn)));
        //完了画面へ遷移
        await this.$router.push('/e-mansion/sub-account/cancel-complete');
        this.isLoading = false;
      } catch (error) {
        await this.$router.push('/e-mansion/error');
        return;
      }
    },
    formatDate(date: Date): string {
      const y = date.getFullYear();
      const m = ('00' + (date.getMonth() + 1)).slice(-2);
      const d = ('00' + date.getDate()).slice(-2);
      return y + '/' + m + '/' + d;
    },
  },
});
