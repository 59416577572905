




















































































































































































































































































































































































































































































































































import Vue from 'vue';
import { TRANSITION_SOURCE_SERVICE } from '@/shared/const/service-type';
export default Vue.extend({
  data: () => ({
    eMansionUrl: process.env.VUE_APP_E_MANSION_URL,
    ucomUrl: process.env.VUE_APP_UCOM_URL,
    fiveAUrl: process.env.VUE_APP_FIVE_A_BUILDING_SEARCH_URL,
    lNetUrl: process.env.VUE_APP_FIVE_A_LIC_SERVICE_DOMAIN,
    nAssetUrl: process.env.VUE_APP_FIVE_A_N_ASSET_SERVICE_DOMAIN,
    transitionSourceServiceType: TRANSITION_SOURCE_SERVICE,
    oemsMaster: [],
    // OEM一覧表示用配列
    oemMaster1: [],
    oemMaster2: [],
    oemMaster3: []
  }),
  // 親コンポーネントから受け取るデータ
  props: ['serviceType', 'isDisplayConnectixAsk'],
  async mounted() {
    this.$data.oemsMaster = this.$store.getters['oemsStore/oems'];
    for(let i=0;i < this.$data.oemsMaster.length; i++) {
      if(i%3 === 0) {
        this.oemMaster1.push(this.oemsMaster[i]);
      } else if(i%3 === 1) {
        this.oemMaster2.push(this.oemsMaster[i]);
      } else {
        this.oemMaster3.push(this.oemsMaster[i]);
      }
    }
  }
});
