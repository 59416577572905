























































import { AuthService } from '@/shared/services/auth/auth-service';
import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { checkRouterError } from '@/shared/util/router-navigation-func';

/** MemberCompleted コンポーネント */
export default Vue.extend({
  name: 'member-completed',
  components: {
    LoadingComponent
  },
  data: () => ({
    // 遷移元パス
    path: '',
    isRouting: false, // onNext()が実行された後、router.pushが実行されるまで用
    isMounting: false,
  }),
  mounted(): void {
    this.$data.isMounting = true;
    const isLoggedIn = AuthService.isLoggedIn();
    // ログインしていない場合「総合トップ」画面にリダイレクトする
    if (!isLoggedIn) {
      this.$router.push('/').catch(error => {checkRouterError(error)});
      return;
    }

    /** 遷移元パスをストアから呼び出す */
    this.path = this.$store.getters['platformEditStore/beforePath'] ?? '';
    this.$data.isMounting = false;
  },
  methods: {
    onNext() {

      if(this.isRouting) {
        return;
      }

      this.isRouting = true;

      /** 遷移元パスがISP新規会員登録入力・確認画面の場合は該当画面に遷移する */
      if (
        this.path === '/e-mansion/entry/input' ||
        this.path === '/e-mansion/entry/confirm' ||
        this.path === '/ucom/entry/input' ||
        this.path === '/5a/entry/input' ||
        this.path === '/5a/entry/confirm'
      ) {
        // confirmは結局規約に戻される
        this.$router.push(this.path).catch(error => {checkRouterError(error)});
      } else {
        this.$router.push('/platform').catch(error => {checkRouterError(error)});
      }
    }
  },
  computed: {
    isFromISPEntry(): boolean {
      return (
        this.path === '/e-mansion/entry/input' ||
        this.path === '/e-mansion/entry/confirm' ||
        this.path === '/ucom/entry/input' ||
        this.path === '/5a/entry/input' ||
        this.path === '/5a/entry/confirm'
      );
    }
  }
});
