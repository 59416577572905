

































































































import Vue from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { SpfApiWebSpaceAccessor } from '@/infra/accessor/spf/ucom/spf-api-web-space-accessor';
import { UcomWebSpaceCapacityUpdate } from '@/shared/classes/spf-api/mail/model/ucom/web-space/ucom-web-space-capacity-update';

export default Vue.extend({
  name: 'web-space-confirm',
  components: {
    LoadingComponent,
    ErrorMessagesComponent,
  },

  data: () => ({
    ucomAccountMailId: '', // COM_アカウントメールID
    accountName: '', // アカウント名 (=アカウントID(表示用))
    mailAddress: '', // メールアドレス
    homepageAddress: '', // ホームページアドレス
    webSpaceCapacity: '', // 現在のWebスペース容量
    changeCapacityList: [], // 追加/縮小するWebスペース容量リスト
    webSpaceMaxCapacity: '', // Webスペース最大容量
    afterChangeWebSpaceCapacityLabel: '', // 追加/縮小するWebスペース容量のLabel
    displayAfterChangeWebSpaceCapacityLabel: '', // 表示用追加/縮小するWebスペース容量のLabel
    afterChangeWebSpaceCapacityValue: '', // 追加/縮小するWebスペース容量のValue Hidden
    unitPrice: 0, // 月額利用料
    paymentStatus: true, //お支払情報登録有無
    applicationDate: '', // お申込み日
    serviceDescription: '', // サービス説明
    errorMessages: [], // エラーメッセージ
    isLoading: false, // ローディング
    errorMessageTitle: '恐れ入りますが、内容をもう一度ご確認ください。', // エラーメッセージ部タイトル
  }),
  async mounted() {
    try {
      this.$data.isLoading = true;

      // UCOM_アカウントメールIDをStoreから取得
      this.$data.ucomAccountMailId = this.$store.getters['ucomWebSpaceStore/ucomAccountMailId'];

      // UCOM_アカウントメールID(Store)を取得出来ない場合、前画面へ戻る
      if (!this.$data.ucomAccountMailId) {
        await this.$router.push('/ucom/account-option-change/account-list');
        this.$data.isLoading = false;
        return;
      }

      // Storeからデータを取得しセット
      this.$data.paymentStatus = this.$store.getters['ucomWebSpaceStore/paymentStatus'];
      this.$data.accountName = this.$store.getters['ucomWebSpaceStore/accountName'];
      this.$data.mailAddress = this.$store.getters['ucomWebSpaceStore/mailAddress'];
      this.$data.homepageAddress = this.$store.getters['ucomWebSpaceStore/homepageAddress'];
      this.$data.webSpaceCapacity = this.$store.getters['ucomWebSpaceStore/webSpaceCapacity'];
      this.$data.changeCapacityList = this.$store.getters['ucomWebSpaceStore/changeCapacityList'];
      this.$data.afterChangeWebSpaceCapacityLabel = this.$store.getters['ucomWebSpaceStore/changeCapacityLabel'];
      this.$data.afterChangeWebSpaceCapacityValue = this.$store.getters['ucomWebSpaceStore/changeCapacityValue'];
      this.$data.unitPrice = this.$store.getters['ucomWebSpaceStore/unitPrice'];
      this.$data.webSpaceMaxCapacity = this.$store.getters['ucomWebSpaceStore/webSpaceMaxCapacity'];
      this.$data.applicationDate = this.$store.getters['ucomWebSpaceStore/applicationDate'];
      this.$data.serviceDescription = this.$store.getters['ucomWebSpaceStore/serviceDescription'];

      // 追加/縮小容量を表示用に加工
      if (String(this.$data.afterChangeWebSpaceCapacityValue.toString().charAt(0)) === '-') {
        this.$data.displayAfterChangeWebSpaceCapacityLabel = this.$data.afterChangeWebSpaceCapacityValue + 'MB';
      }
      if (String(this.$data.afterChangeWebSpaceCapacityValue.toString().charAt(0)) !== '-') {
        this.$data.displayAfterChangeWebSpaceCapacityLabel = '+' + this.$data.afterChangeWebSpaceCapacityValue + 'MB';
      }

      this.$data.isLoading = false;
    } catch (error) {
      await this.$router.push('/error');
      return;
    }
  },
  methods: {
    // 「戻る」ボタン押下時の処理
    async onBack(): Promise<void> {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      this.$store.commit('ucomWebSpaceStore/paymentStatus', this.$data.paymentStatus);
      this.$store.commit('ucomWebSpaceStore/ucomAccountMailId', this.$data.ucomAccountMailId);
      this.$store.commit('ucomWebSpaceStore/accountName', this.$data.accountName);
      this.$store.commit('ucomWebSpaceStore/mailAddress', this.$data.mailAddress);
      this.$store.commit('ucomWebSpaceStore/homepageAddress', this.$data.homepageAddress);
      this.$store.commit('ucomWebSpaceStore/webSpaceCapacity', this.$data.webSpaceCapacity);
      this.$store.commit('ucomWebSpaceStore/changeCapacityList', this.$data.changeCapacityList);
      this.$store.commit('ucomWebSpaceStore/changeCapacityLabel', this.$data.afterChangeWebSpaceCapacityLabel);
      this.$store.commit('ucomWebSpaceStore/changeCapacityValue', this.$data.afterChangeWebSpaceCapacityValue);
      this.$store.commit('ucomWebSpaceStore/unitPrice', this.$data.unitPrice);
      this.$store.commit('ucomWebSpaceStore/webSpaceMaxCapacity', this.$data.webSpaceMaxCapacity);
      this.$store.commit('ucomWebSpaceStore/applicationDate', this.$data.applicationDate);
      this.$store.commit('ucomWebSpaceStore/serviceDescription', this.$data.serviceDescription);

      await this.$router.push('/ucom/web-space/input');
      this.$data.isLoading = false;
    },

    // 「申し込む」ボタン押下時の処理
    async onApply(): Promise<void> {
      if (this.$data.isLoading) {
        return;
      }

      this.$data.isLoading = true;
      try {
        // 会員IDの取得
        const memberId = this.$store.getters['memberStore/member'].id;

        // 現在日付の取得
        const now = await SpfApiServerCommonUtilAccessor.getServerTimeNow();
        const targetDate = this.formatDate(now);

        const response = await SpfApiWebSpaceAccessor.updateUcomWebSpaceCapacity(
          memberId,
          this.$data.ucomAccountMailId,
          this.$data.afterChangeWebSpaceCapacityLabel,
          this.$data.afterChangeWebSpaceCapacityValue,
          targetDate
        );

        if (response.errorMessages) {
          this.$data.errorMessages = [];
          this.$data.errorMessages.push(response.errorMessages);
          // エラーメッセージを見せるため画面最上部にスクロール
          await this.scrollPageTop();
          this.$data.isLoading = false;
          return;
        }

        const accountName = response.accountName;
        const unitPrice = response.price;
        const processingDate = response.subscriptionDate;

        // 確認更新API レスポンスをstoreに保存
        this.saveStore(response, accountName, unitPrice, processingDate);

        // 完了画面へ遷移
        await this.$router.push('/ucom/web-space/complete');
        this.$data.isLoading = false;
      } catch (e) {
        await this.$router.push('/error');
        return;
      }
    },

    // storeにデータを保存
    saveStore(response: UcomWebSpaceCapacityUpdate, accountName: string, unitPrice: string, processingDate: string): void {
      this.$store.commit('ucomWebSpaceStore/mailAddress', this.$data.mailAddress);
      this.$store.commit('ucomWebSpaceStore/notifyMailAddress', response.notifyMailAddress);
      this.$store.commit('ucomWebSpaceStore/accountName', accountName);
      this.$store.commit('ucomWebSpaceStore/homepageAddress', response.homepageAddress);
      this.$store.commit('ucomWebSpaceStore/webSpaceCapacity', response.webSpaceCapacity);
      this.$store.commit('ucomWebSpaceStore/changeCapacityLabel', response.changeCapacityLabel);
      this.$store.commit('ucomWebSpaceStore/unitPrice', unitPrice);
      this.$store.commit('ucomWebSpaceStore/applicationDate', processingDate);
      this.$store.commit('ucomWebSpaceStore/serviceDescription', response.serviceDescription);
    },
    formatDate(dt: Date) {
      const y = dt.getFullYear();
      const m = ('00' + (dt.getMonth() + 1)).slice(-2);
      const d = ('00' + dt.getDate()).slice(-2);
      return y + '/' + m + '/' + d;
    },
    // ページトップへスクロール
    async scrollPageTop() {
      window.scrollTo(0, 0);
    },
  },
});
