





















































































import Vue from 'vue';
/** エラーメッセージ用コンポーネント */
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { required } from 'vuelidate/lib/validators';
import { Member } from '@/shared/classes/spf-api/member';
import { AuthManegementSharedErrorResponse } from '@/shared/classes/auth-manegement/shared-error-response';
import { UcomLinkingInput } from '@/shared/classes/auth-manegement/ucom-linking-input';
import { AuthService } from '@/shared/services/auth/auth-service';
import { AuthManagementApiService } from '@/shared/services/auth/auth-management-api-service';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { AUTH0_PROPERTY_ID_NO_MATCH } from '@/shared/const/error';
import { ApiFrontError } from '@/shared/classes/error/api-front-error';
import { FRONT_ERROR_INFO_API_FRONT_ERROR } from '@/shared/const/error/error-info';
import { InitUcomCustomerNoUser } from '@/shared/classes/spf-api/mail/model/ucom/account/init-ucom-customer-no-user';
import { SpfApiAccountAccessor } from '@/infra/accessor/spf/ucom/spf-api-account-accessor';

/** Linking コンポーネント */
export default Vue.extend({
  name: 'linking',
  components: {
    /** エラーメッセージ用コンポーネント */
    ErrorMessagesComponent,
    LoadingComponent
  },
  data: () => ({
    /** エラーメッセージを格納する配列 */
    errorMessages: [] as string[],
    /** エラーメッセージ部に表示するタイトル */
    auth0PropertyIdNoMatchErrorMessageTitle: AUTH0_PROPERTY_ID_NO_MATCH.displayTitle.UCOM,
    defaultErrorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    errorMessageTitle: '',
    /** 入力データ */
    inputData: {
      loginId: '',
      password: ''
    } as UcomLinkingInput,
    isExecutingOnAccountlinkage: false, // onAccountlinkage()実行中かどうか
    ucomCustomerRegistrationUrl: process.env.VUE_APP_UCOM_CUSTOMER_REGISTRATION_URL
  }),
  validations: {
    inputData: {
      loginId: {
        required
      },
      password: {
        required
      }
    }
  },
  async mounted(): Promise<void> {
    /** ログインしているか否かの情報を取得 */
    const isLoggedIn: boolean = await AuthService.isLoggedIn();

    // ログインしていなければ エラー画面 に遷移する
    if (!isLoggedIn) {
      this.$router.push('/');
      return;
    }

    /** 会員テーブルの UCOM の主キーから ユーザが UCOM と連携しているかを確認する */
    const member: Member | null = this.$store.getters['memberStore/member'];
    if (member?.primaryKeyUcom) {
      // 連携していれば UCOM サービストップに遷移する
      this.$router.push('/ucom').catch(error => { checkRouterError(error) });
    }
  },
  methods: {
    /** フォームのバリデーション */
    validate(): boolean {
      this.errorMessages = [];
      if (this.$v.inputData.$invalid) {
        if (!this.$v.inputData.loginId?.required) {
          this.errorMessages.push('お客様番号を入力してください。');
        }
        if (!this.$v.inputData.password?.required) {
          this.errorMessages.push('パスワードを入力してください。');
        }
        return false;
      }
      return true;
    },
    /** アカウント連携ボタン押下時 */
    async onAccountlinkage(): Promise<void> {
      try {
        if (this.isExecutingOnAccountlinkage) {
          return;
        }
        this.isExecutingOnAccountlinkage = true;
        this.errorMessageTitle = this.defaultErrorMessageTitle;

        if (this.validate()) {
          /** UCOM アカウント連携 API を使用して次の 3 つの処理を実行する
           * 1. UCOM 外部 API に入力情報（お客様番号・パスワード）を渡して UCOM 登録済みか否かを確認
           * 2. Portas 会員テーブルの「UCOM の主キー」項目にお客様番号を格納
           * 3. Auth0 のプライマリにセカンダリとして UCOM 会員 ID の紐付けを行う
           */
          const result: undefined | AuthManegementSharedErrorResponse = await AuthManagementApiService.linkingToUcom(this.$data.inputData);
          if (result instanceof AuthManegementSharedErrorResponse) {

            if (result.status === 409) {
              this.errorMessages.push('アカウント連携に失敗しました。');
              return;
            } else if (result.errors.some((value) => value === AUTH0_PROPERTY_ID_NO_MATCH.errorMessage)) {
              this.errorMessageTitle = this.auth0PropertyIdNoMatchErrorMessageTitle;

              this.errorMessages.push(AUTH0_PROPERTY_ID_NO_MATCH.displayMessage.UCOM[0]);
              this.errorMessages.push(AUTH0_PROPERTY_ID_NO_MATCH.displayMessage.UCOM[1]);
              this.errorMessages.push(AUTH0_PROPERTY_ID_NO_MATCH.displayMessage.UCOM[2].replace('REPLACE_URL', process.env.VUE_APP_REPLACE_URL_AUTH0_PROPERTY_ID_NO_MATCH_UCOM));

            } else if(result.status === 500) {
              throw new ApiFrontError(FRONT_ERROR_INFO_API_FRONT_ERROR.AUTH0_PORTAS.UCOM_ACCOUNT_LINKING);
            } else {
              this.errorMessages.push('お客様番号あるいはパスワードが一致しません。');
              return;
            }

          } else {
            await AuthService.refresh();
            // 正常ケースの場合

            // アカウントテーブル作成APIコール
            // エラーハンドリングについては既存踏襲
            const member: Member = await this.$store.getters['memberStore/member'];
            await SpfApiAccountAccessor.insertInitUcomCustomerNoUser(
                new InitUcomCustomerNoUser(
                    member.id,
                    Number(await AuthService.getUcomMemberId())
                )
            );

            // AuthManagementApiService.linkingToUcom に成功するとバックエンドで会員ステータスが更新される
            // ページ遷移時に beforeEach で会員ステータス取得できるように null にする
            this.$store.commit('memberStore/memberStatus', null);
            this.$store.commit('memberStore/member', null);

            // UCOM サービストップ に遷移
            await this.$router.push('/ucom').catch(error => { checkRouterError(error) });
            return;
          }
        }
      } catch (error) {
        throw error;
      } finally {
        this.isExecutingOnAccountlinkage = false;
      }
    }
  }
});
