import axios from 'axios';

import { FiveASharedErrorResponse } from '../../classes/external-api/five-a/shared-error-response';
import { FiveACustomer, FiveACustomerCreateResponse, FiveACustomerCreateResponseResult, FiveACustomerResponse } from '../../classes/external-api/five-a/customer-response';
import { FiveAProperty, FiveAPropertyResponse } from '../../classes/external-api/five-a/property-response';
import { FindCustomerByIdQueryDto } from '../../classes/external-api/five-a/customer-dto';
import { FiveARoomsResponseResult } from '@/shared/classes/external-api/five-a/rooms-response';
import { FiveACustomerCreateRequestResult } from '../../classes/external-api/five-a/customer-request';
import { FiveARoomsResponse } from '../../classes/external-api/five-a/rooms-response';
import { FindPropertyByIdQueryDto } from '@/shared/classes/external-api/five-a/property-request';
import { BaseExternalApiService } from './base-external-api-service';
import { convert6DigitIdTo8DigitId } from '@/shared/util/convert';
import { UaTypeValue } from '@/shared/const/service-type';

const api = BaseExternalApiService.api(1);

/** 共通利用する five-a 外部 API をコールするサービス */
export class FiveASharedExternalApiService {
  /**
   * 契約基本情報取得
   *
   * @param memberId 会員ID
   * @param ua_type UA種別 1:e-mansion,2:Five.A,3:UCOM光
   * @param property_id 物件ID
   * @return 契約基本情報
   */
  public static async findCustomerById(memberId: string, query: FindCustomerByIdQueryDto): Promise<FiveACustomer | FiveASharedErrorResponse> {
    try {
      query.property_id = convert6DigitIdTo8DigitId(query.property_id);
      const response = await api.get<FiveACustomerResponse>(`/customers/${memberId}`, { params: query });
      return new FiveACustomer(response.data.result);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * 物件基本情報取得
   *
   * @param propertyId 物件ID
   * @param uaType 物件種別
   * @return 物件基本情報
   */
  public static async findPropertyById(params: { propertyId: string; uaType: UaTypeValue }): Promise<FiveAProperty | FiveASharedErrorResponse> {
    try {
      params.propertyId = convert6DigitIdTo8DigitId(params.propertyId);
      const query: FindPropertyByIdQueryDto = {
        ua_type: params.uaType
      };
      const response = await api.get<FiveAPropertyResponse>(`/apartment/properties/${params.propertyId}`, { params: query });
      return new FiveAProperty(response.data.result.PARAM);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * 部屋番号一覧取得
   *
   * @param uaType UA種別 1:five-a,2:Five.A,3:UCOM光R
   * @param property_id 物件ID
   * @return 部屋番号一覧
   */
  public static async findRooms(uaType: UaTypeValue, propertyId: string): Promise<FiveARoomsResponseResult | FiveASharedErrorResponse> {
    try {
      const apid = convert6DigitIdTo8DigitId(propertyId);
      const response = await api.get<FiveARoomsResponse>(`/properties/${apid}`, { params: { ua_type: uaType }});
      return new FiveARoomsResponseResult(response.data.result);
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * 新規会員登録
   * @param fiveACustomerCreateRequest 新規会員登録用リクエストボディ
   * @return レスポンス
   */
  public static async createFiveACustomer(fiveACustomerCreateRequestResult: FiveACustomerCreateRequestResult): Promise<FiveACustomerCreateResponse | FiveASharedErrorResponse>{
    try {
      fiveACustomerCreateRequestResult.request.property_id
        = convert6DigitIdTo8DigitId(fiveACustomerCreateRequestResult.request.property_id);
      const response = await api.post<FiveACustomerCreateResponseResult>('/customers', fiveACustomerCreateRequestResult);
      return response.data.result;
    } catch (error) {
      return this.handleError(error);
    }
  }

  /**
   * API 通信エラー時のハンドリング
   *
   * @param error エラーオブジェクト
   * @return five-a API 共通エラーオブジェクト
   */
  private static handleError(error: unknown): FiveASharedErrorResponse {

    if(error instanceof axios.Cancel){
      throw error;
    }

    if (axios.isAxiosError(error)) {
      const errorResponse: FiveASharedErrorResponse = error.response?.data;
      if (errorResponse) return new FiveASharedErrorResponse(errorResponse);
    }
    throw error;
  }
}
