




















































































import dayjs from 'dayjs';
import Vue from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { EMansionProperty } from '@/shared/classes/external-api/e-mansion/property-response';
import { EMansionMainMailAddressInput } from '@/shared/classes/spf-api/mail/model/main-mail-address/e-mansion-main-mail-address-input';
import { EMansionAccountInfo } from '@/shared/classes/spf-api/mail/model/account/e-mansion-account-info';
import { SpfApiMainMailAddressAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-main-mail-address-accessor';
import { hasEMansionOriginalDomain } from '@/shared/const/e-mansion';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';

import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';

export default Vue.extend({
  name: 'eMansionMailMailConfirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    /** 現在のメールアドレス */
    mailAddress: null,
    /** 新しいメールアドレス */
    newMailAddress: null,
    /** 新しいメールアドレス(ドメイン付き) */
    newMailAddressAddDomain: null,
    /** ドメイン */
    mailAddressDomain: process.env.VUE_APP_E_MANSION_MAIL_SUBDOMAIN + '.' + process.env.VUE_APP_E_MANSION_DOMAIN,
    /** 独自ドメイン */
    mailAddressOriginalDomain: null,
    /** メールアドレス変更に伴う注意事項表示有無 */
    isDisplayInfo: false,
    /** エラーメッセージを格納する配列 */
    errorMessages: [],
    /** エラーメッセージ部に表示するタイトル */
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
    // メソッドが実行中かどうか
    isLoading: false,
    /** メールアドレス入力チェック結果 */
    eMansionMainMailaddressInput: EMansionMainMailAddressInput,
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    //メインメールアドレス情報をAPIより取得する
    try {
      // 現在のメールアドレスを取得(共通情報より取得)
      const accountInfo: EMansionAccountInfo = this.$store.getters['eMansionAccountStore/eMansionAccountInfo'];
      this.$data.mailAddress = accountInfo.eMansionMailAddressInfo?.mailAddress;

      // 新しいメールアドレスを取得
      this.$data.newMailAddress = await this.$store.getters['eMansionMainMailAddressStore/newMailAddress'];

      // Storeから前提情報を取得できない場合、入力画面へ戻る（リロード対応）
      if (!this.$data.newMailAddress) {
        await this.$router.push('/e-mansion/main-mail-address/input');
        return;
      }

      // 新しいメールアドレス(ドメイン付き)を取得
      this.$data.newMailAddressAddDomain = await this.$store.getters['eMansionMainMailAddressStore/newMailAddressAddDomain'];

      // 物件基本情報をStoreから取得
      const property: EMansionProperty = this.$store.getters['eMansionCommonStore/property'];
      if (!property) {
        await this.$router.push('/e-mansion/error');
        return;
      }

      // メールアドレス変更に伴う注意事項表示有無を判定
      this.$data.isDisplayInfo = this.$data.mailAddress != undefined;

      // 独自ドメイン有無判定(1:あり、0:なし)
      if (hasEMansionOriginalDomain(property.em_op_mail_flag)) {
        this.$data.mailAddress = accountInfo.eMansionMailAddressInfo?.mailAddressOriginal;
        this.$data.mailAddressOriginalDomain = property.original_domain_name;
      }
    } catch (error) {
      await this.$router.push('/e-mansion/error');
      return;
    }
  },
  methods: {
    /** 戻るボタン押下時： メインメールアドレス登録変更：入力画面に遷移する */
    async goBack() {
      if (this.isLoading) {
        return;
      }
      //メールアドレスをstoreに保存する
      this.$store.commit('eMansionMainMailAddressStore/mailAddress', this.$data.mailAddress);
      this.$store.commit('eMansionMainMailAddressStore/newMailAddress', this.$data.newMailAddress);
      await this.$router.push('/e-mansion/main-mail-address/input');
      this.isLoading = true;
    },
    /** 次へボタン押下時： メインメールアドレス登録変更：確認画面に遷移する */
    async goEntry() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      // エラーメッセージ格納配列初期化
      this.$data.errorMessages = [];

      // 物件基本情報をStoreから取得
      const property: EMansionProperty = this.$store.getters['eMansionCommonStore/property'];
      if (!property) {
        await this.$router.push('/e-mansion/error');
        return;
      }

      // e-mansion_アカウントIDを取得
      const accountInfo: EMansionAccountInfo = this.$store.getters['eMansionAccountStore/eMansionAccountInfo'];
      if (!accountInfo) {
        await this.$router.push('/e-mansion/error');
        return;
      }

      // 会員種別の取得
      const servicePlanType: SERVICE_PLAN_TYPE = this.$store.getters['servicePlanTypeStore/servicePlanType'];

      // 現在日付の取得
      const processOn = await SpfApiServerCommonUtilAccessor.getServerTimeNow();

      try {
        let result: any;
        // 現在のメールアドレスに値があるか（ある場合、更新、ない場合、新規）
        if (this.$data.mailAddress) {
          // AE10-004_メインメールアドレス登録・変更：確認(登録変更)_更新API
          result = await SpfApiMainMailAddressAccessor.updateMainMailAddress(
            this.$data.newMailAddress,
            accountInfo.eMansionAccountInfo.eMansionAccountId,
            property.apartment_id,
            this.$data.mailAddressDomain,
            this.$data.mailAddressOriginalDomain,
            processOn
          );
        } else {
          // 新規：AE10-003_メインメールアドレス登録・変更：確認(登録変更)_登録API
          result = await SpfApiMainMailAddressAccessor.createMainMailAddress(
            this.$data.newMailAddress,
            accountInfo.eMansionAccountInfo.eMansionAccountId,
            property.apartment_id,
            servicePlanType,
            this.$data.mailAddressDomain,
            this.$data.mailAddressOriginalDomain,
            processOn
          );
        }

        // API処理結果にエラーメッセージがある場合
        if (result.errorMessages) {
          this.$data.errorMessages = result.errorMessages;
        }
        // Storeにメールアドレスを保持
        if (result.mailAddress) {
          this.$store.commit('eMansionMainMailAddressStore/newMailAddressAddDomain', result.mailAddress);
        }
      } catch (error) {
        // エラー画面に遷移
        await this.$router.push('/e-mansion/error');
        return;
      }

      if (this.$data.errorMessages.length > 0) {
        // エラーメッセージを見せるために画面最上部にスクロール
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }

      await this.$router.push('/e-mansion/main-mail-address/complete');
      this.isLoading = false;
    },
  },
});
