













































































import Vue from 'vue';
import ErrorMessagesComponent from '@/shared/components/error-messages-component.vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { SpfApiSubAccountAccessor } from '@/infra/accessor/spf/e-mansion/spf-api-sub-account-accessor';
import { subAccountAddInfo } from '@/shared/classes/spf-api/mail/model/sub-account/e-mansion-sub-account-add-info';
import { hasEMansionOriginalDomain } from '@/shared/const/e-mansion';

export default Vue.extend({
  name: 'confirm',
  components: {
    ErrorMessagesComponent,
    LoadingComponent,
  },
  data: () => ({
    // メソッドが実行中かどうか
    isLoading: false,
    desiredAccount: '',
    usageFee: '',
    subAccountAddInfo: subAccountAddInfo,
    messageList: [],
    slimPlan: false,
    tax: '',
    mailAddress: '',
    mailAddressDomain: '',
    errorMessageTitle: '恐れ入りますが、入力内容をもう一度ご確認ください。',
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      this.$data.tax = this.$store.getters['taxStore/tax'];

      //不正な遷移

      if (!this.$store.getters['eMansionSubAccountStore/canApply']) {
        await this.$router.push('/e-mansion/sub-account/list');
      }
      this.$data.desiredAccount = this.$store.getters['eMansionSubAccountStore/desiredAccount'];
      //リロードで表示する値がなくなったときは利用状況・入力画面に戻す
      if (!this.$data.desiredAccount) {
        await this.$router.push('/e-mansion/sub-account/list');
        return;
      }
      if (this.$store.getters['servicePlanTypeStore/servicePlanType'] == SERVICE_PLAN_TYPE.EM_SLIM) {
        this.$data.slimPlan = true;
      }
      if (this.$store.getters['servicePlanTypeStore/servicePlanType'] == SERVICE_PLAN_TYPE.EM_SLIM_SPECIAL) {
        this.$data.slimPlan = true;
      }
      const emOpMailFlag = this.$store.getters['eMansionCommonStore/property'].em_op_mail_flag;
      this.$data.mailAddress = this.$data.desiredAccount + '@' + process.env.VUE_APP_E_MANSION_MAIL_SUBDOMAIN + '.' + process.env.VUE_APP_E_MANSION_DOMAIN;
      if (hasEMansionOriginalDomain(emOpMailFlag)) {
        this.$data.mailAddressDomain = this.$store.getters['eMansionCommonStore/property'].original_domain_name;
        this.$data.mailAddress = this.$data.desiredAccount + '@' + this.$data.mailAddressDomain;
      }
      if (this.$data.slimPlan) {
        this.$data.usageFee = this.$store.getters['eMansionSubAccountStore/usageFee'];
        if (!this.$data.usageFee) {
          await this.$router.push('/e-mansion/sub-account/list');
        }
      }
    } catch (error) {
      await this.$router.push('/e-mansion/error');
      return;
    }
  },
  methods: {
    /** 戻るボタン押下時： 追加アカウント利用状況画面に遷移する */
    async goBack() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      //Storeにご希望アカウントを保存
      this.$store.commit('eMansionSubAccountStore/desiredAccount', this.$data.desiredAccount);
      await this.$router.push('/e-mansion/sub-account/list');
      this.isLoading = false;
    },
    /** 申し込むボタン押下時：追加アカウント完了(お申込み)画面に遷移する */
    async onApply() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      try {
        const memberId = this.$store.getters['memberStore/member'].id;
        const emOpMailFlag = this.$store.getters['eMansionCommonStore/property'].em_op_mail_flag;
        const originalDomainName = this.$store.getters['eMansionCommonStore/property'].original_domain_name;
        const servicePlanType: SERVICE_PLAN_TYPE = this.$store.getters['servicePlanTypeStore/servicePlanType'];
        const processDate = await SpfApiServerCommonUtilAccessor.getServerTimeNow();
        this.$data.subAccountAddInfo = await SpfApiSubAccountAccessor.addSubAccount(
          memberId,
          this.$data.desiredAccount,
          process.env.VUE_APP_E_MANSION_MAIL_SUBDOMAIN + '.' + process.env.VUE_APP_E_MANSION_DOMAIN,
          servicePlanType,
          emOpMailFlag,
          originalDomainName,
          processDate
        );
      } catch (error) {
        // エラー画面に遷移
        await this.$router.push('/e-mansion/error');
        return;
      }

      if (this.$data.subAccountAddInfo.resultMessage.length > 0) {
        this.$data.messageList = this.$data.subAccountAddInfo.resultMessage;
        window.scrollTo(0, 0);
        this.$data.isLoading = false;
        return;
      }
      /**返り値を次画面に渡すために保存*/
      //追加メールアドレス
      this.$store.commit('eMansionSubAccountStore/mailAddress', this.$data.subAccountAddInfo.mailAddress);
      //メールパスワード
      this.$store.commit('eMansionSubAccountStore/mailPassword', this.$data.subAccountAddInfo.mailPassword);
      //POPサーバー
      this.$store.commit('eMansionSubAccountStore/popServer', this.$data.subAccountAddInfo.popServer);
      //SMTPサーバー
      this.$store.commit('eMansionSubAccountStore/smtpServer', this.$data.subAccountAddInfo.smtpServer);
      await this.$router.push('/e-mansion/sub-account/complete');
      this.isLoading = false;
    },
  },
});
