import { Module } from 'vuex';
import { RootState } from './index';
import { ServicePermissionMaster } from '@/shared/classes/spf-api/mail/model/common/permission/service-permission-master';
import { ItemPermissionMaster } from '@/shared/classes/spf-api/mail/model/common/permission/item-permission-master';
import { SpfApiPermissionAccessor } from '@/infra/accessor/spf/common/spf-api-permission-accessor';

/** 初期化ステップ */
const INITIALIZING_STEP = {
  NONE: 0,
  PROCESSING: 1,
  COMPLETE: 2,
};
type INITIALIZE_STEP = typeof INITIALIZING_STEP[keyof typeof INITIALIZING_STEP];

/** 認可マスタ State */
const permissionState: {
  /** サービス別認可マスタデータ */
  servicePermissions: ServicePermissionMaster[] | undefined;
  /** サービス別認可マスタ取得ステータス */
  servicePermissionStatus: INITIALIZE_STEP;
  /** アイテム別認可マスタデータ */
  itemPermissions: ItemPermissionMaster[] | undefined;
  /** アイテム別認可マスタ初期化ステータス */
  itemPermissionStatus: INITIALIZE_STEP;
} = {
  servicePermissions: undefined,
  servicePermissionStatus: INITIALIZING_STEP.NONE,
  itemPermissions: undefined,
  itemPermissionStatus: INITIALIZING_STEP.NONE,
};
/** State の型 */
type PermissionState = typeof permissionState;

/** データ取得待機時間（単位:msec） */
const WAIT_TIME: number = 10;

/** サービス別認可マスタ Store */
export const permissionStore: Module<PermissionState, RootState> = {
  namespaced: true,
  state: permissionState,
  actions: {
    /**
     * サービス別認可マスタ取得 action
     * @param context
     * @return PermissionMasterResponse[] 認可マスタレスポンス
     */
    async servicePermissions(context) {
      // 同時に複数コンポーネントからdispatchが呼び出される場合に備え、
      // マスタ取得APIの呼び出しを一度に押さえる様、状態を管理する
      switch (context.getters['servicePermissionStatus']) {
        case INITIALIZING_STEP.NONE:
          // 初期化ステータスを処理中に進める
          context.commit('stepServicePermissionStatus');
          // サービス別認可マスタ取得API コールする
          const masterRecords: ServicePermissionMaster[] = await SpfApiPermissionAccessor.getServicePermissionMasters();
          // レスポンスをコミット → 初期化ステータスを完了に進める
          context.commit('servicePermissions', masterRecords);
          context.commit('stepServicePermissionStatus');
          break;
        case INITIALIZING_STEP.PROCESSING:
          // 通信待機
          while (context.getters['servicePermissionStatus'] === INITIALIZING_STEP.PROCESSING) {
            await new Promise((f) => setTimeout(f, WAIT_TIME));
          }
          break;
        case INITIALIZING_STEP.COMPLETE:
        default:
          break;
      }
      // Getter から取り直して返す
      const records = context.getters['servicePermissions'];
      return records;
    },
    /**
     * アイテム別認可マスタ取得 action
     * @param context
     * @return ItemPermissionMasterResponse[] アイテム別認可マスタレスポンス
     */
    async itemPermissions(context) {
      // 同時に複数コンポーネントからdispatchが呼び出される場合に備え、
      // マスタ取得APIの呼び出しを一度に押さえる様、状態を管理する
      switch (context.getters['itemPermissionStatus']) {
        case INITIALIZING_STEP.NONE:
          // 初期化ステータスを処理中に進める
          context.commit('stepItemPermissionStatus');
          // アイテム別認可マスタ取得API コールする
          const itemPermissions: ItemPermissionMaster[] = await SpfApiPermissionAccessor.getItemPermissionMasters();
          // レスポンスをコミット → 初期化ステータスを完了に進める
          context.commit('itemPermissions', itemPermissions);
          context.commit('stepItemPermissionStatus');
          break;
        case INITIALIZING_STEP.PROCESSING:
          // 通信待機
          while (context.getters['itemPermissionStatus'] === INITIALIZING_STEP.PROCESSING) {
            await new Promise((f) => setTimeout(f, WAIT_TIME));
          }
          break;
        case INITIALIZING_STEP.COMPLETE:
        default:
          break;
      }
      // Getter から取り直して返す
      const records = context.getters['itemPermissions'];
      return records;
    },
  },
  mutations: {
    servicePermissions: (state, payload: ServicePermissionMaster[]) => {
      state.servicePermissions = payload;
    },
    itemPermissions: (state, payload: ItemPermissionMaster[]) => {
      state.itemPermissions = payload;
    },
    stepServicePermissionStatus: (state) => {
      state.servicePermissionStatus++;
    },
    stepItemPermissionStatus: (state) => {
      state.itemPermissionStatus++;
    },
  },
  getters: {
    servicePermissions: (state): ServicePermissionMaster[] | undefined => state.servicePermissions,
    itemPermissions: (state): ItemPermissionMaster[] | undefined => state.itemPermissions,
    servicePermissionStatus: (state): INITIALIZE_STEP => state.servicePermissionStatus,
    itemPermissionStatus: (state): INITIALIZE_STEP => state.itemPermissionStatus,
  },
};
