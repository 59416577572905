import EMansionIndex from '@/pages/e-mansion/index.vue';
import Linking from '@/pages/e-mansion/linking.vue';
import SubAccountLinking from '@/pages/e-mansion/sub-account-linking.vue';
import MainMailList from '@/pages/e-mansion/main-mail-address/list.vue';
import MainMailConfirm from '@/pages/e-mansion/main-mail-address/confirm.vue';
import MainMailComplete from '@/pages/e-mansion/main-mail-address/complete.vue';
import MainMailCanselConfirm from '@/pages/e-mansion/main-mail-address/cancel-confirm.vue';
import MainMailCanselComplete from '@/pages/e-mansion/main-mail-address/cancel-complete.vue';
import MainMailInput from '@/pages/e-mansion/main-mail-address/input.vue';
import MailUsage from '@/pages/e-mansion/mail/list/usage.vue';
import VirusCheckApplyConfirm from '@/pages/e-mansion/mail/virus-check/virus-check-apply-confirm.vue';
import VirusCheckApplyComplete from '@/pages/e-mansion/mail/virus-check/virus-check-apply-complete.vue';
import VirusCheckCancelConfirm from '@/pages/e-mansion/mail/virus-check/virus-check-cancel-confirm.vue';
import VirusCheckCancelComplete from '@/pages/e-mansion/mail/virus-check/virus-check-cancel-complete.vue';
import HomePageCapacityList from '@/pages/e-mansion/homepage-capacity/list.vue';
import HomePageCapacityInput from '@/pages/e-mansion/homepage-capacity/input.vue';
import HomePageCapacityConfirm from '@/pages/e-mansion/homepage-capacity/confirm.vue';
import HomePageCapacityComplete from '@/pages/e-mansion/homepage-capacity/complete.vue';
import MailPasswordInput from '@/pages/e-mansion/mail-pw/input.vue';
import MailPasswordComplete from '@/pages/e-mansion/mail-pw/complete.vue';
import SubAccountList from '@/pages/e-mansion/sub-account/list.vue';
import SubAccountConfirm from '@/pages/e-mansion/sub-account/confirm.vue';
import SubAccountComplete from '@/pages/e-mansion/sub-account/complete.vue';
import SubAccountCancelConfirm from '@/pages/e-mansion/sub-account/cancel-confirm.vue';
import SubAccountCancelComplete from '@/pages/e-mansion/sub-account/cancel-complete.vue';
import Terms from '@/pages/e-mansion/entry/terms.vue';
import Input from '@/pages/e-mansion/entry/input.vue';
import ConnectionCourse from '@/pages/e-mansion/entry/connection-course.vue';
import ConnectionDevice from '@/pages/e-mansion/entry/connection-device.vue';
import Option from '@/pages/e-mansion/entry/option.vue';
import Confirm from '@/pages/e-mansion/entry/confirm.vue';
import Completed from '@/pages/e-mansion/entry/completed.vue';
import { NavigationGuardNext, Route } from 'vue-router';
import store from '@/store';
import { isTransitionNoLinked } from '.';
import { UA_TYPE } from '@/shared/const/service-type';
import HomepageApplication from '@/pages/e-mansion/homepage/application.vue';
import HomepageApplicationConfirm from '@/pages/e-mansion/homepage/application-confirm.vue';
import HomepageApplicationComplete from '@/pages/e-mansion/homepage/application-complete.vue';
import HomepageUsageCancel from '@/pages/e-mansion/homepage/usage-cancel.vue';
import HomepageCancelConfirm from '@/pages/e-mansion/homepage/cancel-confirm.vue';
import HomepageCancelComplete from '@/pages/e-mansion/homepage/cancel-complete.vue';
import EMansionError from '@/pages/e-mansion/error.vue';
import EMansionInArrears from '@/pages/e-mansion/in-arrears.vue';
import { SERVICE_ID } from '@/shared/const/service-ids';
import { PermissionControlService } from '@/shared/services/permission/permission-control-service';

/** e-mansion ルーティング定義 */
const eMansionRouter = [
  {
    path: '/e-mansion',
    name: 'e-mansion サービストップ',
    component: EMansionIndex,
    meta: {
      title: 'Portas | e-mansion 会員専用トップページ | ポルタス',
      description: 'Portas（ポルタス）e-mansion会員専用トップページになります。',
      keywords: 'e-mansion 会員専用トップページ',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      const isLinkedToEMansion: boolean = await store.dispatch('memberStore/isLinkedToEMansion');
      if (isLinkedToEMansion) {
        return next();
      } else {
        return next({ path: '/login-forwarding' }); // トップページに遷移
      }
    },
  },
  //{
  //  path: '/e-mansion/link-service',
  //  name: 'e-mansion 同時お申し込み',
  //  component: LinkService,
  //  meta: {
  //    title: 'Portas | e-mansion 同時お申し込みページ（新規会員登録、アカウント連携） | ポルタス',
  //    description: 'Portas（ポルタス）とe-mansionのアカウント連携、およびe-mansionの新規会員登録はこちらから行えます。',
  //    keywords: 'e-mansion同時お申し込みページ（新規会員登録、アカウント連携）'
  //  },
  //  beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
  //    if(await isTransitionNoLinked()){
  //      return next();
  //    }
  //    return next({ path: '/platform' });
  //  }
  //},
  {
    path: '/e-mansion/linking',
    name: 'e-mansion アカウント連携',
    component: Linking,
    meta: {
      title: 'Portas | e-mansion アカウント連携 | ポルタス',
      description: 'Portas（ポルタス）とe-mansionのアカウント連携を行います。',
      keywords: 'e-mansion アカウント連携',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (await isTransitionNoLinked(UA_TYPE.E_MANSION)) {
        return next();
      }
      return next({ path: '/platform' });
    },
  },
  {
    path: '/e-mansion/sub-account-linking',
    name: 'e-mansion 追加アカウント連携',
    component: SubAccountLinking,
    meta: {
      title: 'Portas | e-mansion 追加アカウント連携 | ポルタス',
      description: 'Portas（ポルタス）とe-mansionの追加アカウント連携を行います。',
      keywords: 'e-mansion 追加アカウント連携',
    },
  },
  {
    // TODO : 未決あり
    path: '/e-mansion/mail/usage',
    name: 'e-mansion メールサービス利用状況',
    component: MailUsage,
    meta: {
      title: 'Portas | e-mansion メールサービスご利用状況 | ポルタス',
      description: 'サンプル (未決)',
      keywords: 'サンプル (未決)',
    },
  },
  {
    // TODO : 未決あり
    path: '/e-mansion/mail/virus-check-apply-confirm',
    name: 'e-mansion ウイルスチェックあんしんサービス plus 確認 (申込)',
    component: VirusCheckApplyConfirm,
    meta: {
      title: 'Portas | e-mansion ウイルスチェックあんしんサービス plus 確認 | ポルタス',
      description: 'サンプル (未決)',
      keywords: 'サンプル (未決)',
    },
    beforeEnter: async (_to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/mail/usage') {
        return next({ path: '/e-mansion/mail/usage', replace: true });
      }

      // 遷移先で必要となるデータがストアされているかチェック
      const mailAddressId = store.getters['eMansionMailStore/selectedMailAddressId'];
      const mailAddress = store.getters['eMansionMailStore/selectedMailAddress'];
      const feeInfo = store.getters['eMansionMailStore/selectedFeeInfo'];
      // mailAddressId がストアされている場合は 0 でないという想定で判定している
      if (!mailAddressId || !mailAddress || !feeInfo) {
        // ストアされていなければ e-mansion メールサービス利用状況画面に戻る
        next({ path: '/e-mansion/mail/usage', replace: true });
      } else {
        next();
      }
    },
  },
  {
    // TODO : 未決あり
    path: '/e-mansion/mail/virus-check-apply-complete',
    name: 'e-mansion ウイルスチェックあんしんサービス plus 完了 (申込)',
    component: VirusCheckApplyComplete,
    meta: {
      title: 'Portas | e-mansion ウイルスチェックあんしんサービス plus 完了 | ポルタス',
      description: 'サンプル (未決)',
      keywords: 'サンプル (未決)',
    },
    beforeEnter: async (_to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
      // 遷移先で必要となるデータがストアされているかチェック
      const mailAddress = store.getters['eMansionMailStore/selectedMailAddress'];
      if (!mailAddress) {
        // ストアされていなければ e-mansion メールサービス利用状況画面に戻る
        next({ path: '/e-mansion/mail/usage', replace: true });
      } else {
        next();
      }
    },
  },
  {
    // TODO : 未決あり
    path: '/e-mansion/mail/virus-check-cancel-confirm',
    name: 'e-mansion ウイルスチェックあんしんサービス plus 確認 (解約)',
    component: VirusCheckCancelConfirm,
    meta: {
      title: 'Portas | e-mansion ウイルスチェックあんしんサービス plus 確認 | ポルタス',
      description: 'サンプル (未決)',
      keywords: 'サンプル (未決)',
    },
    beforeEnter: async (_to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (from.path !== '/e-mansion/mail/usage') {
        return next({ path: '/e-mansion/mail/usage', replace: true });
      }

      // 遷移先で必要となるデータがストアされているかチェック
      const mailAddressId = store.getters['eMansionMailStore/selectedMailAddressId'];
      const mailAddress = store.getters['eMansionMailStore/selectedMailAddress'];
      // mailAddressId がストアされている場合は 0 でないという想定で判定している
      if (!mailAddressId || !mailAddress) {
        // ストアされていなければ e-mansion メールサービス利用状況画面に戻る
        next({ path: '/e-mansion/mail/usage', replace: true });
      } else {
        next();
      }
    },
  },
  {
    // TODO : 未決あり
    path: '/e-mansion/mail/virus-check-cancel-complete',
    name: 'e-mansion ウイルスチェックあんしんサービス plus 完了 (解約)',
    component: VirusCheckCancelComplete,
    meta: {
      title: 'Portas | e-mansion ウイルスチェックあんしんサービス plus 完了 | ポルタス',
      description: 'サンプル (未決)',
      keywords: 'サンプル (未決)',
    },
    beforeEnter: async (_to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
      // 遷移先で必要となるデータがストアされているかチェック
      const mailAddress = store.getters['eMansionMailStore/selectedMailAddress'];
      const cancelOn = store.getters['eMansionMailStore/selectedCancelOn'];
      if (!mailAddress || !cancelOn) {
        // ストアされていなければ e-mansion メールサービス利用状況画面に戻る
        next({ path: '/e-mansion/mail/usage', replace: true });
      } else {
        next();
      }
    },
  },
  {
    // TODO : 未決あり
    path: '/e-mansion/homepage-capacity/list',
    name: 'e-mansion ホームページ容量 利用状況',
    component: HomePageCapacityList,
    meta: {
      title: 'Portas | e-mansion ホームページ容量 ご利用状況 | ポルタス',
      description: 'サンプル (未決)',
      keywords: 'サンプル (未決)',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (!await PermissionControlService.isReadable(SERVICE_ID.HOMEPAGE_VOLUME)) {
        return next({path:'/error'});
      }
      return next();
    },
  },
  {
    // TODO : 未決あり
    path: '/e-mansion/homepage-capacity/input',
    name: 'e-mansion ホームページ容量 変更',
    component: HomePageCapacityInput,
    meta: {
      title: 'Portas | e-mansion ホームページ容量 変更 | ポルタス',
      description: 'サンプル (未決)',
      keywords: 'サンプル (未決)',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (!await PermissionControlService.isReadable(SERVICE_ID.HOMEPAGE_VOLUME)) {
        return next({path:'/error'});
      }
      return next();
    },
  },
  {
    // TODO : 未決あり
    path: '/e-mansion/homepage-capacity/confirm',
    name: 'e-mansion ホームページ容量 確認',
    component: HomePageCapacityConfirm,
    meta: {
      title: 'Portas | e-mansion ホームページ容量 確認 | ポルタス',
      description: 'サンプル (未決)',
      keywords: 'サンプル (未決)',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if(from.path !== '/e-mansion/homepage-capacity/input'){
        return next({path:'/e-mansion/homepage-capacity/list'});
      }
      if (!await PermissionControlService.isReadable(SERVICE_ID.HOMEPAGE_VOLUME)) {
        return next({path:'/error'});
      }
      return next();
    },
  },
  {
    // TODO : 未決あり
    path: '/e-mansion/homepage-capacity/complete',
    name: 'e-mansion ホームページ容量 完了',
    component: HomePageCapacityComplete,
    meta: {
      title: 'Portas | e-mansion ホームページ容量 完了 | ポルタス',
      description: 'サンプル (未決)',
      keywords: 'サンプル (未決)',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if(from.path !== '/e-mansion/homepage-capacity/confirm'){
        return next({path:'/e-mansion/homepage-capacity/list'});
      }
      if (!await PermissionControlService.isReadable(SERVICE_ID.HOMEPAGE_VOLUME)) {
        return next({path:'/error'});
      }
      return next();
    },
  },
  {
    // TODO : 未決あり
    path: '/e-mansion/error',
    name: 'e-mansion エラー画面',
    component: EMansionError,
    meta: {
      title: 'Portas | e-mansion エラー | ポルタス',
      description: 'サンプル (未決)',
      keywords: 'サンプル (未決)',
    },
  },
  {
    // TODO : 未決あり
    path: '/e-mansion/no-card-in-arrears',
    name: 'e-mansion 料金滞納中画面',
    component: EMansionInArrears,
    meta: {
      title: 'Portas | e-mansion 料金滞納中 | ポルタス',
      description: 'サンプル (未決)',
      keywords: 'サンプル (未決)',
    },
  },
  //{
  //  path: '/e-mansion/entry/terms',
  //  name: 'e-mansion 新規会員登録 サービス規約',
  //  component: Terms,
  //  meta: {
  //    title: 'Portas | e-mansion ご入会お申し込み | ポルタス',
  //    description: 'Portas（ポルタス）でのe-mansionの新規会員登録はこちらから行ってください。　利用規約',
  //    keywords: 'e-mansion 新規会員登録 お客様情報登録ページ規約同意してお申し込み'
  //  },
  //  beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
  //    if(await isTransitionNoLinked()){
  //      return next();
  //    }
  //    return next({ path: '/platform' });
  //  }
  //},
  //{
  //  path: '/e-mansion/entry/input',
  //  name: 'e-mansion 新規会員登録 お客様情報入力',
  //  component: Input,
  //  meta: {
  //    title: 'Portas | e-mansion ご入会お申し込み お客様情報入力 | ポルタス',
  //    description: 'Portas（ポルタス）でのe-mansionの新規会員登録はこちらから行ってください。　お客様情報入力',
  //    keywords: 'e-mansion 新規会員登録 お客様情報登録ページお客様情報入力'
  //  },
  //  // 仕様書より、途中離脱したとき と URL直叩きしたときは、「規約同意して申し込む」ページからやり直し
  //  // ただし、登録内容の修正のために戻ったときは、inputに遷移させる
  //  beforeEnter: (_to: Route, from: Route, next: NavigationGuardNext) => {
  //    // 登録内容の修正のために戻ったときは、inputに遷移させる
  //    if (from.path === '/e-mansion/entry/connection-course'
  //        || from.path === '/e-mansion/entry/connection-device'
  //        || from.path === '/e-mansion/entry/option'
  //        || from.path === '/e-mansion/entry/confirm') {
  //
  //        next();
  //    } else if (from.path === '/e-mansion/entry/terms') {
  //      next();
  //    } else if (from.path !== '/e-mansion/entry/terms') {
  //      next({ path: '/e-mansion/entry/terms'});
  //    }
  //    next();
  //  }
  //},

  //{
  //  path: '/e-mansion/entry/connection-course',
  //  name: 'e-mansion 新規会員登録 接続コース選択',
  //  component: ConnectionCourse,
  //  meta: {
  //    title: 'Portas | e-mansion ご入会お申し込み 接続コース選択 | ポルタス',
  //    description: 'Portas（ポルタス）でのe-mansionの新規会員登録はこちらから行ってください。　接続コース選択',
  //    keywords: 'e-mansion 新規会員登録 お客様情報登録ページ接続コース選択'
  //  },
  //  // 仕様書より、途中離脱したとき と URL直叩きしたときは、「規約同意して申し込む」ページからやり直し
  //  beforeEnter: (_to: Route, from: Route, next: NavigationGuardNext) => {
  //    if (from.path !== '/e-mansion/entry/input') {
  //      next({ path: '/e-mansion/entry/terms'});
  //    }
  //    next();
  //  }
  //},
  //{
  //  path: '/e-mansion/entry/connection-device',
  //  name: 'e-mansion 新規会員登録 接続機器選択',
  //  component: ConnectionDevice,
  //  meta: {
  //    title: 'Portas | e-mansion ご入会お申し込み 接続機器選択 | ポルタス',
  //    description: 'Portas（ポルタス）でのe-mansionの新規会員登録はこちらから行ってください。　接続機器選択',
  //    keywords: 'e-mansion 新規会員登録 お客様情報登録ページ接続機器選択'
  //  },
  //  // 仕様書より、途中離脱したとき と URL直叩きしたときは、「規約同意して申し込む」ページからやり直し
  //  beforeEnter: (_to: Route, from: Route, next: NavigationGuardNext) => {
  //    if (from.path !== '/e-mansion/entry/connection-course') {
  //      next({ path: '/e-mansion/entry/terms'});
  //    }
  //    next();
  //  }
  //},
  //{
  //  path: '/e-mansion/entry/option',
  //  name: 'e-mansion 新規会員登録 オプションサービス選択',
  //  component: Option,
  //  meta: {
  //    title: 'Portas | e-mansion ご入会お申し込み オプションサービス選択 | ポルタス',
  //    description: 'Portas（ポルタス）でのe-mansionの新規会員登録はこちらから行ってください。オプションサービス選択',
  //    keywords: 'e-mansion 新規会員登録 お客様情報登録ページオプションサービス選択'
  //  },
  //  // 仕様書より、途中離脱したとき と URL直叩きしたときは、「規約同意して申し込む」ページからやり直し
  //  beforeEnter: (_to: Route, from: Route, next: NavigationGuardNext) => {
  //    if (from.path !== '/e-mansion/entry/connection-device') {
  //      next({ path: '/e-mansion/entry/terms'});
  //    }
  //    next();
  //  }
  //},
  //{
  //  path: '/e-mansion/entry/confirm',
  //  name: 'e-mansion 新規会員登録 入力内容の確認',
  //  component: Confirm,
  //  meta: {
  //    title: 'Portas | e-mansion ご入会お申し込み 確認 | ポルタス',
  //    description: 'Portas（ポルタス）でのe-mansionの新規会員登録はこちらから行ってください。入力内容の確認',
  //    keywords: 'e-mansion 新規会員登録 お客様情報登録ページ入力内容の確認'
  //  },
  //  // 仕様書より、途中離脱したとき と URL直叩きしたときは、「規約同意して申し込む」ページからやり直し
  //  beforeEnter: (_to: Route, from: Route, next: NavigationGuardNext) => {
  //    if (from.path !== '/e-mansion/entry/option') {
  //      next({ path: '/e-mansion/entry/terms'});
  //    }
  //    next();
  //  }
  //},
  //{
  //  path: '/e-mansion/entry/completed',
  //  name: 'e-mansion 新規会員登録 登録完了',
  //  component: Completed,
  //  meta: {
  //    title: 'Portas | e-mansion ご入会お申し込み 完了 | ポルタス',
  //    description: 'Portas（ポルタス）でのe-mansionの新規会員登録はこちらから行ってください。登録完了',
  //    keywords: 'e-mansion 新規会員登録 お客様情報登録ページ登録完了'
  //  }
  //}
  //TODO:descriptionの内容
  {
    path: '/e-mansion/main-mail-address/list',
    name: 'e-mansion メインメールアドレス登録・変更 利用状況',
    component: MainMailList,
    meta: {
      title: 'Portas | e-mansion メインメールアドレス登録・変更 利用状況 | ポルタス',
      description: '',
      keywords: 'e-mansion メインメールアドレス登録・変更',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (!await PermissionControlService.isReadable(SERVICE_ID.MAIN_MAIL_ADDRESS_USAGE)) {
        return next({path:'/error'});
      }
      return next();
    },
  },
  {
    path: '/e-mansion/main-mail-address/input',
    name: 'e-mansion メインメールアドレス登録・変更 入力',
    component: MainMailInput,
    meta: {
      title: 'Portas | e-mansion メインメールアドレス登録・変更 入力| ポルタス',
      description: '',
      keywords: 'e-mansion メインメールアドレス登録・変更',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (!await PermissionControlService.isReadable(SERVICE_ID.MAIN_MAIL_ADDRESS_USAGE)) {
        return next({path:'/error'});
      }
      return next();
    },
  },
  {
    path: '/e-mansion/main-mail-address/confirm',
    name: 'e-mansion メインメールアドレス登録・変更 確認',
    component: MainMailConfirm,
    meta: {
      title: 'Portas | e-mansion メインメールアドレス登録・変更 確認| ポルタス',
      description: '',
      keywords: 'e-mansion メインメールアドレス登録・変更',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (!await PermissionControlService.isReadable(SERVICE_ID.MAIN_MAIL_ADDRESS_USAGE)) {
        return next({path:'/error'});
      }
      return next();
    },
  },
  {
    path: '/e-mansion/main-mail-address/complete',
    name: 'e-mansion メインメールアドレス登録・変更 完了',
    component: MainMailComplete,
    meta: {
      title: 'Portas | e-mansion メインメールアドレス登録・変更 完了| ポルタス',
      description: '',
      keywords: 'e-mansion メインメールアドレス登録・変更',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if(from.path !== '/e-mansion/main-mail-address/confirm'){
        return next({path:'/e-mansion'});
      }
      if (!await PermissionControlService.isReadable(SERVICE_ID.MAIN_MAIL_ADDRESS_USAGE)) {
        return next({path:'/error'});
      }
      return next();
    },
  },
  {
    path: '/e-mansion/main-mail-address/cancel-confirm',
    name: 'e-mansion メインメールアドレス登録・変更 解約確認',
    component: MainMailCanselConfirm,
    meta: {
      title: 'Portas | e-mansion メインメールアドレス登録・解約確認 | ポルタス',
      description: '',
      keywords: 'e-mansion メインメールアドレス登録・変更',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (!await PermissionControlService.isReadable(SERVICE_ID.MAIN_MAIL_ADDRESS_USAGE)) {
        return next({path:'/error'});
      }
      return next();
    },
  },
  {
    path: '/e-mansion/main-mail-address/cancel-complete',
    name: 'e-mansion メインメールアドレス登録・変更 解約完了',
    component: MainMailCanselComplete,
    meta: {
      title: 'Portas | e-mansion メインメールアドレス登録・解約完了 | ポルタス',
      description: '',
      keywords: 'e-mansion メインメールアドレス登録・変更',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if(from.path !== '/e-mansion/main-mail-address/cancel-confirm'){
        return next({path:'/e-mansion'});
      }
      if (!await PermissionControlService.isReadable(SERVICE_ID.MAIN_MAIL_ADDRESS_USAGE)) {
        return next({path:'/error'});
      }
      return next();
    },
  },
  {
    path: '/e-mansion/mail-password/input',
    name: 'e-mansion メールパスワード変更　入力',
    component: MailPasswordInput,
    meta: {
      title: 'Portas | e-mansion メールパスワード変更 入力 | ポルタス',
      description: '',
      keywords: 'e-mansion メールパスワード変更 入力',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (!await PermissionControlService.isReadable(SERVICE_ID.MAIL_PASSWORD)) {
        return next({path:'/error'});
      }
      return next();
    },
  },
  {
    path: '/e-mansion/mail-password/complete',
    name: 'e-mansion メールパスワード変更　完了',
    component: MailPasswordComplete,
    meta: {
      title: 'Portas | e-mansion メールパスワード変更 完了 | ポルタス',
      description: '',
      keywords: 'e-mansion メールパスワード変更 完了',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if(from.path !== '/e-mansion/mail-password/input'){
        return next({path:'/e-mansion'});
      }
      if (!await PermissionControlService.isReadable(SERVICE_ID.MAIL_PASSWORD)) {
        return next({path:'/error'});
      }
      return next();
    },
  },
  {
    path: '/e-mansion/sub-account/list',
    name: 'e-mansion 追加アカウント　ご利用状況/お申込み',
    component: SubAccountList,
    meta: {
      title: 'Portas | e-mansion 追加アカウント ご利用状況/お申込み | ポルタス',
      description: '',
      keywords: 'e-mansion 追加アカウント ご利用状況/お申込み',
    },
  },
  {
    path: '/e-mansion/sub-account/confirm',
    name: 'e-mansion 追加アカウント　確認(お申込み)',
    component: SubAccountConfirm,
    meta: {
      title: 'Portas | e-mansion 追加アカウント 確認(お申込み)| ポルタス',
      description: '',
      keywords: 'e-mansion 追加アカウント 確認(お申込み)',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if(from.path !== '/e-mansion/sub-account/list'){
        return next({path:'/e-mansion/sub-account/list',replace: true });
      }
      return next();
    }
  },
  {
    path: '/e-mansion/sub-account/complete',
    name: 'e-mansion 追加アカウント　完了(お申込み)',
    component: SubAccountComplete,
    meta: {
      title: 'Portas | e-mansion 追加アカウント 完了(お申込み)| ポルタス',
      description: '',
      keywords: 'e-mansion 追加アカウント 完了(お申込み)',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if(from.path !== '/e-mansion/sub-account/confirm'){
        return next({path:'/e-mansion/sub-account/list',replace: true });
      }
      return next();
    }
  },
  {
    path: '/e-mansion/sub-account/cancel-confirm',
    name: 'e-mansion 追加アカウント　確認(解約)',
    component: SubAccountCancelConfirm,
    meta: {
      title: 'Portas | e-mansion 追加アカウント 確認(解約)| ポルタス',
      description: '',
      keywords: 'e-mansion 追加アカウント 確認(解約)',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if(from.path !== '/e-mansion/sub-account/list'){
        return next({path:'/e-mansion/sub-account/list',replace: true });
      }
      return next();
    }
  },
  {
    path: '/e-mansion/sub-account/cancel-complete',
    name: 'e-mansion 追加アカウント　完了(解約)',
    component: SubAccountCancelComplete,
    meta: {
      title: 'Portas | e-mansion 追加アカウント 完了(解約)| ポルタス',
      description: '',
      keywords: 'e-mansion 追加アカウント 完了(解約)',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if(from.path !== '/e-mansion/sub-account/cancel-confirm'){
        return next({path:'/e-mansion/sub-account/list',replace: true });
      }
      return next();
    }
  },
  {
    path: '/e-mansion/homepage/application',
    name: 'e-mansion ホームページ申し込み',
    component: HomepageApplication,
    meta: {
      title: 'Portas | e-mansion ホームページ申し込み | ポルタス',
      description: '',
      keywords: 'e-mansion ホームページ申し込み',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (!await PermissionControlService.isReadable(SERVICE_ID.HOMEPAGE_USAGE)) {
        return next({path:'/error'});
      }
      return next();
    },
  },
  {
    path: '/e-mansion/homepage/application-confirm',
    name: 'e-mansion ホームページ申し込み確認',
    component: HomepageApplicationConfirm,
    meta: {
      title: 'Portas | e-mansion ホームページ申し込み確認 | ポルタス',
      description: '',
      keywords: 'e-mansion ホームページ申し込み確認',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (!await PermissionControlService.isReadable(SERVICE_ID.HOMEPAGE_USAGE)) {
        return next({path:'/error'});
      }
      return next();
    },
  },
  {
    path: '/e-mansion/homepage/application-complete',
    name: 'e-mansion ホームページ申し込み完了',
    component: HomepageApplicationComplete,
    meta: {
      title: 'Portas | e-mansion ホームページ申し込み完了 | ポルタス',
      description: '',
      keywords: 'e-mansion ホームページ申し込み完了',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (!await PermissionControlService.isReadable(SERVICE_ID.HOMEPAGE_USAGE)) {
        return next({path:'/error'});
      }
      return next();
    },
  },
  {
    path: '/e-mansion/homepage/usage',
    name: 'e-mansion ホームページご利用状況／ご解約',
    component: HomepageUsageCancel,
    meta: {
      title: 'Portas | e-mansion ホームページご利用状況／ご解約 | ポルタス',
      description: '',
      keywords: 'e-mansion ホームページご利用状況／ご解約',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (!await PermissionControlService.isReadable(SERVICE_ID.HOMEPAGE_USAGE)) {
        return next({path:'/error'});
      }
      return next();
    },
  },
  {
    path: '/e-mansion/homepage/cancel-confirm',
    name: 'e-mansion ホームページご解約確認',
    component: HomepageCancelConfirm,
    meta: {
      title: 'Portas | e-mansion ホームページご解約確認 | ポルタス',
      description: '',
      keywords: 'e-mansion ホームページご解約確認',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (!await PermissionControlService.isReadable(SERVICE_ID.HOMEPAGE_USAGE)) {
        return next({path:'/error'});
      }
      return next();
    },
  },
  {
    path: '/e-mansion/homepage/cancel-complete',
    name: 'e-mansion ホームページご解約完了',
    component: HomepageCancelComplete,
    meta: {
      title: 'Portas | e-mansion ホームページご解約完了 | ポルタス',
      description: '',
      keywords: 'e-mansion ホームページご解約完了',
    },
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
      if (!await PermissionControlService.isReadable(SERVICE_ID.HOMEPAGE_USAGE)) {
        return next({path:'/error'});
      }
      return next();
    },
  },
];

export default eMansionRouter;
