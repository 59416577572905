import { ConnectixMaintenanceInfo } from "@/shared/classes/spf-api/connectix-maintenance";
import { SpfApiService } from "@/shared/services/api/spf-api-service";
import { Module } from "vuex";
import { RootState } from '../index';


const connectixMaintenanceState: {
  connectixMaintenance: ConnectixMaintenanceInfo | null;
} = {
  connectixMaintenance: null,
};

/** State の型 */
type ConnectixMaintenanceState = typeof connectixMaintenanceState;

/** ConnectixMaintenanceStore */
export const connectixMaintenanceStore: Module<ConnectixMaintenanceState, RootState> = {
  namespaced: true,
  state: connectixMaintenanceState,
  mutations: {
    connectixMaintenance: (state, value: ConnectixMaintenanceInfo)=> (state.connectixMaintenance = value),
  },
  getters: {
    connectixMaintenance: (state): ConnectixMaintenanceInfo | null => state.connectixMaintenance,
  },
  actions: {
    async connectixMaintenance(context, maintenanceUa: string) {
      const connectixMaintenance =  await SpfApiService.getConnectixMaintenance(maintenanceUa);
      // 値をコミットする
      context.commit('connectixMaintenance', connectixMaintenance);
      // Getter から取り直して返す
      const afterConnectixMaintenance = context.getters['connectixMaintenance'];
      return afterConnectixMaintenance;
    }
  },
}