import { PERMISSION_TYPE } from './permission-type';
import { SERVICE_PLAN_TYPE } from '@/shared/const/service-plan-type';
import { ServicePermissionMaster } from '@/shared/classes/spf-api/mail/model/common/permission/service-permission-master';
import { ItemPermissionMaster } from '@/shared/classes/spf-api/mail/model/common/permission/item-permission-master';
import store from '@/store';

/** 認可制御サービス */
export class PermissionControlService {
  /**
   * 更新権限チェック
   * @param functionId 機能ID
   * @returns boolean true:権限あり
   */
  public static async isUpdatable(functionId: string): Promise<boolean> {
    const permission = await this.getServicePermission(functionId);
    return permission === PERMISSION_TYPE.UPDATABLE;
  }

  /**
   * 更新権限チェック
   * @param functionId 機能ID
   * @param screenId 画面ID
   * @returns boolean true:権限あり
   */
  public static async isScreenUpdatable(functionId: string, screenId: string): Promise<boolean> {
    const permission = await this.getScreenPermission(functionId, screenId);
    return permission === PERMISSION_TYPE.UPDATABLE;
  }

  /**
   * 更新権限チェック
   * @param functionId 機能ID
   * @returns boolean true:権限あり
   */
  public static async isItemUpdatable(functionId: string, screenId: string, itemId: string): Promise<boolean> {
    const permission = await this.getItemPermission(functionId, screenId, itemId);
    return permission === PERMISSION_TYPE.UPDATABLE;
  }

  /**
   * 閲覧権限チェック（閲覧権限以上を持つか）
   * @param functionId 機能ID
   * @returns boolean true:権限あり
   */
  public static async isReadable(functionId: string): Promise<boolean> {
    const permission = await this.getServicePermission(functionId);
    return permission === PERMISSION_TYPE.UPDATABLE || permission === PERMISSION_TYPE.READONLY;
  }

  /**
   * 閲覧権限チェック（閲覧権限以上を持つか）
   * @param functionId 機能ID
   * @param screenId 画面ID
   * @returns boolean true:権限あり
   */
  public static async isScreenReadable(functionId: string, screenId: string): Promise<boolean> {
    const permission = await this.getScreenPermission(functionId, screenId);
    return permission === PERMISSION_TYPE.UPDATABLE || permission === PERMISSION_TYPE.READONLY;
  }

  /**
   * 閲覧権限チェック（閲覧権限以上を持つか）
   * @param functionId 機能ID
   * @param screenId 画面ID
   * @param itemId 画面項目ID
   * @returns boolean true:権限あり
   */
  public static async isItemReadable(functionId: string, screenId: string, itemId: string): Promise<boolean> {
    const permission = await this.getItemPermission(functionId, screenId, itemId);
    return permission === PERMISSION_TYPE.UPDATABLE || permission === PERMISSION_TYPE.READONLY;
  }

  /**
   * サービス別パーミッション取得
   * @param functionId 機能ID
   * @returns PERMISSION_TYPE パーミッション
   */
  private static async getServicePermission(functionId: string): Promise<PERMISSION_TYPE> {
    const planType = this.getServicePlanType();
    const servicePermissions = await this.getServicePermissionMasters();
    const servicePermission = servicePermissions.find((p) => p.planType === planType && p.serviceId === functionId);
    return servicePermission === undefined ? PERMISSION_TYPE.NONE : servicePermission.permission;
  }

  /**
   * アイテム別パーミッション取得
   * @param functionId 機能ID
   * @param screenId 画面ID
   * @returns PERMISSION_TYPE パーミッション
   */
  private static async getScreenPermission(functionId: string, screenId: string): Promise<PERMISSION_TYPE> {
    const planType = this.getServicePlanType();
    const itemPermissions = await this.getItemPermissionMasters();
    const screenPermissions = itemPermissions.filter((p) => p.planType === planType && p.serviceId === functionId && p.screenId == screenId);
    if (screenPermissions.length === 0) {
      return await this.getServicePermission(functionId);
    }
    return this.maxPermission(screenPermissions.map((p) => p.permission));
  }

  /**
   * パーミッションの最大値を取得する
   * @param permissions パーミッション配列
   * @returns 最大パーミッション
   */
  private static maxPermission(permissions: Array<PERMISSION_TYPE>): PERMISSION_TYPE {
    if (permissions.some((p) => p === PERMISSION_TYPE.UPDATABLE)) {
      return PERMISSION_TYPE.UPDATABLE;
    }
    return permissions.some((p) => p === PERMISSION_TYPE.READONLY) ? PERMISSION_TYPE.READONLY : PERMISSION_TYPE.NONE;
  }

  /**
   * アイテム別パーミッション取得
   * @param functionId 機能ID
   * @param screenId 画面ID
   * @param itemId 画面項目ID
   * @returns PERMISSION_TYPE パーミッション
   */
  private static async getItemPermission(functionId: string, screenId: string, itemId: string): Promise<PERMISSION_TYPE> {
    const planType = this.getServicePlanType();
    const itemPermissions = await this.getItemPermissionMasters();
    let itemPermission = itemPermissions.find((p) => p.planType === planType && p.serviceId === functionId && p.screenId == screenId && p.itemId === itemId);
    if (itemPermission) {
      return itemPermission.permission;
    }

    return await this.getScreenPermission(functionId, screenId);
  }

  /**
   * 会員種別取得
   * @returns SERVICE_PLAN_TYPE 会員種別
   */
  private static getServicePlanType(): SERVICE_PLAN_TYPE {
    const planType = store.getters['servicePlanTypeStore/servicePlanType'];
    return planType;
  }

  /**
   * サービス別認可マスタ取得
   * @returns ServicePermissionMasterResponse[] サービス別認可マスタ配列
   */
  private static async getServicePermissionMasters(): Promise<ServicePermissionMaster[]> {
    const STORE_NAME = 'permissionStore/servicePermissions';
    const masters = store.getters[STORE_NAME] ?? (await store.dispatch(STORE_NAME));
    return masters ?? new Array<ServicePermissionMaster>();
  }

  /**
   * アイテム別認可マスタ取得
   * @returns ItemPermissionMasterResponse[] アイテム別認可マスタ配列
   */
  private static async getItemPermissionMasters(): Promise<ItemPermissionMaster[]> {
    const STORE_NAME = 'permissionStore/itemPermissions';
    const masters = store.getters[STORE_NAME] ?? (await store.dispatch(STORE_NAME));
    return masters ?? new Array<ItemPermissionMaster>();
  }
}
