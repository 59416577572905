




























































































































































































import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { Member } from '@/shared/classes/spf-api/member';
import { SpfApiAccountOptionChangeAccessor } from '@/infra/accessor/spf/ucom/spf-api-account-option-change-accessor';
import { UcomAccountInfo } from '@/shared/classes/spf-api/mail/model/ucom/account/ucom-account-info-list';
import { UCOM_ACCOUNT_OPTION_QUERY_TYPE } from '@/shared/const/ucom/ucom-account-option';
import { UCOM_IS_OLD_DOMAIN } from '@/shared/const/ucom/ucom-is-old-domain';
import { UCOM_ACCOUNT_AUTHORITY } from '@/shared/const/ucom/ucom-account-authority';
import { UCOM_MAIL_ADDRESS_TYPE } from '@/shared/const/ucom/ucom-mail-address-type';

export default Vue.extend({
  name: 'account-info-list',
  components: {
    LoadingComponent,
  },

  data: () => ({
    isLoading: true,
    accountInfoList: [
      {
        accountName: '',
        familyName: '',
        firstName: '',
        familyNameKana: '',
        firstNameKana: '',
        userName: '',
        password: '',
        accountAuthorization: '',
        mailAddress: '',
        isOldDomain: '',
        mailboxDefaultCapacity: '',
        mailboxAddCapacity: '',
        mailSumCappacity: '',
        virusCheckId: '',
        mailAddressType: '',
        ucomAccountMailId: '',
        ucomWebSpaceId: '',
        homepageAddress: '',
        webSpaceDefaultCapacity: '',
        webspaceAddCapacity: '',
        webSpaceSumCapacity: '',
        ucomAccountId: '',
        mailForwardStatus: '',
        mailReplyStatus: '',
        isUcomMail: false,
        isSelected: false,
      },
    ],
    isAdminLoggedIn: false,

    UCOM_IS_OLD_DOMAIN: UCOM_IS_OLD_DOMAIN,
    UNREGISTERED: '未登録',
    authorityName: {
      USER: 'ユーザー権限',
      ADMIN: '管理者権限',
      CUSTOMER_NUMBER: 'お客様番号権限',
    },
  }),
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      // Storeから会員情報とUCOMアカウントIDを取得
      const member: Member = this.$store.getters['memberStore/member'];
      const ucomAccountId = this.$store.getters['ucomAccountStore/ucomAccount'].accountInfo.ucomAccountId;

      //APIからアカウント情報一覧を取得
      if (!member.primaryKeyUcom) {
        this.$router.push('/error');
        return;
      }

      const response = await SpfApiAccountOptionChangeAccessor.getAccountInfoList(member.id, Number(ucomAccountId));

      this.$data.accountInfoList = response.accountInfoList;

      // 選択フラグとUCOMメールかどうかの情報をListに追加する
      for (let i = 0; i < this.$data.accountInfoList.length; i++) {
        this.$data.accountInfoList[i].isSelected = false;
        if (i == 0) {
          this.$data.accountInfoList[i].isSelected = true;
        }
        if (this.$data.accountInfoList[i].mailAddressType == UCOM_MAIL_ADDRESS_TYPE.UCOM_HIKARI.toString()) {
          this.$data.accountInfoList[i].isUcomMail = true;
        }
      }

      this.isAdminLoggedIn = this.getIsAdminLoggedIn();

      this.$data.isLoading = false;
    } catch (error) {
      this.$router.push('/error');
      return;
    }
  },
  methods: {
    goTop(): void {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      this.$router.push('/ucom');
      this.$data.isLoading = false;
    },
    /** アカウントIDクリック */
    async onAccountId(accountName: string) {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      for (let i = 0; i < this.$data.accountInfoList.length; i++) {
        this.$data.accountInfoList[i].isSelected = false;
        if (this.$data.accountInfoList[i].accountName == accountName) {
          this.$data.accountInfoList[i].isSelected = true;
        }
      }

      this.$data.isLoading = false;
    },
    /**メールアドレス登録変更リンククリック */
    async onMailAddressChange(accountInfo: UcomAccountInfo) {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      //storeに保存
      this.$store.commit('ucomAccountOptionStore/ucomAccountId', accountInfo.ucomAccountId);
      this.$store.commit('ucomAccountOptionStore/currentMailAddress', accountInfo.mailAddress ? accountInfo.mailAddress : this.$data.UNREGISTERED);
      this.$store.commit('ucomAccountOptionStore/mailAddress', accountInfo.mailAddress ? accountInfo.mailAddress : null);
      this.$store.commit('ucomAccountOptionStore/mailAddressType', accountInfo.mailAddressType);
      this.$store.commit('ucomAccountOptionStore/isChange', accountInfo.mailAddress ? UCOM_ACCOUNT_OPTION_QUERY_TYPE.UPDATE : UCOM_ACCOUNT_OPTION_QUERY_TYPE.INSERT);
      this.$store.commit('ucomAccountOptionStore/accountName', accountInfo.accountName);

      //アカウントIDオプション変更：メールアドレス登録 入力画面に遷移
      await this.$router.push('/ucom/account-option-change/mail-input');
      this.$data.isLoading = false;
    },
    /**ウイルスチェック登録変更リンククリック */
    async onVirusCheckChange(accountInfo: UcomAccountInfo) {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      //旧ドメインの場合は現行マイページのVC画面に遷移
      if (accountInfo.isOldDomain == UCOM_IS_OLD_DOMAIN.OLD_DOMAIN) {
        window.location.href = process.env.VUE_APP_UCOM_MY_VIRUS_CHECK_URL + accountInfo.accountName;
        this.$data.isLoading = false;
        return;
      }

      //storeに保存
      const member: Member = this.$store.getters['memberStore/member'];
      this.$store.commit('ucomAccountOptionStore/memberId', member.id);
      this.$store.commit('ucomAccountOptionStore/ucomAccountId', accountInfo.ucomAccountId);

      //ウイルスチェック：入力画面に遷移
      await this.$router.push('/ucom/virus-check/input');
      this.$data.isLoading = false;
    },
    /**ホームページアドレス登録リンククリック */
    async onHomepageAddressInput(accountInfo: UcomAccountInfo) {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      //旧ドメインの場合は現行マイページのHP画面に遷移
      if (accountInfo.isOldDomain == UCOM_IS_OLD_DOMAIN.OLD_DOMAIN) {
        window.location.href = process.env.VUE_APP_UCOM_MY_HOMEPAGE_URL + accountInfo.accountName;
        this.$data.isLoading = false;
        return;
      }

      //storeに保存
      this.$store.commit('ucomAccountOptionStore/ucomAccountId', accountInfo.ucomAccountId);
      this.$store.commit('ucomAccountOptionStore/mailAddress', accountInfo.mailAddress);
      this.$store.commit('ucomAccountOptionStore/accountName', accountInfo.accountName);

      //アカウントIDオプション変更：Webスペース登録 確認画面に遷移
      await this.$router.push('/ucom/account-option-change/web-space/confirm');
      this.$data.isLoading = false;
    },
    /**Webスペース容量変更リンククリック */
    async onWebCapacityChange(accountInfo: UcomAccountInfo) {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      //旧ドメインの場合は現行マイページのHP画面に遷移
      if (accountInfo.isOldDomain == UCOM_IS_OLD_DOMAIN.OLD_DOMAIN) {
        window.location.href = process.env.VUE_APP_UCOM_MY_HOMEPAGE_CAPACITY_URL + accountInfo.accountName;
        this.$data.isLoading = false;
        return;
      }

      //storeに保存
      this.$store.commit('ucomAccountOptionStore/ucomAccountId', accountInfo.ucomAccountId);
      this.$store.commit('ucomAccountOptionStore/ucomAccountMailId', accountInfo.ucomAccountMailId);
      this.$store.commit('ucomAccountOptionStore/mailAddress', accountInfo.mailAddress);
      this.$store.commit('ucomAccountOptionStore/accountName', accountInfo.accountName);
      // 入力画面で入力中であることの判定に使われているステートをストアから削除する
      this.$store.commit('ucomWebSpaceStore/paymentStatus', undefined);

      // Webスペース容量変更：入力画面に遷移
      await this.$router.push('/ucom/web-space/input');
      this.$data.isLoading = false;
    },
    /**メールボックス容量変更リンククリック */
    async onMailboxCapacityChange(accountInfo: UcomAccountInfo) {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      //旧ドメインの場合は現行マイページのメールボックス容量画面に遷移
      if (accountInfo.isOldDomain == UCOM_IS_OLD_DOMAIN.OLD_DOMAIN) {
        window.location.href = process.env.VUE_APP_UCOM_MY_MAILBOX_CAPACITY_URL + accountInfo.accountName;
        this.$data.isLoading = false;
        return;
      }

      //storeに保存
      this.$store.commit('ucomAccountOptionStore/ucomAccountId', accountInfo.ucomAccountId);
      this.$store.commit('ucomAccountOptionStore/ucomAccountMailId', accountInfo.ucomAccountMailId);
      this.$store.commit('ucomAccountOptionStore/mailAddressType', accountInfo.mailAddressType);

      // メールボックス容量変更：入力画面に遷移
      await this.$router.push('/ucom/mailbox-capacity/input');
      this.$data.isLoading = false;
    },
    /**自動転送設定変更リンククリック */
    async onAutoTransmissionChange(accountInfo: UcomAccountInfo) {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      if (accountInfo.isOldDomain == UCOM_IS_OLD_DOMAIN.OLD_DOMAIN) {
        window.location.href = process.env.VUE_APP_UCOM_MY_AUTO_TRANSMISSION_URL + accountInfo.accountName;
        this.$data.isLoading = false;
        return;
      }

      // XMAILのログイン画面に遷移
      await this.$router.push(process.env.VUE_APP_XMAIL_LOGIN_URL);
      this.$data.isLoading = false;
    },
    /**自動返信設定変更リンククリック */
    async onAutoReplyChange(accountInfo: UcomAccountInfo) {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      //旧ドメインの場合は現行マイページの自動返信画面に遷移
      if (accountInfo.isOldDomain == UCOM_IS_OLD_DOMAIN.OLD_DOMAIN) {
        window.location.href = process.env.VUE_APP_UCOM_MY_AUTO_REPLY_URL + accountInfo.accountName;
        this.$data.isLoading = false;
        return;
      }

      // XMAILのログイン画面に遷移
      await this.$router.push(process.env.VUE_APP_XMAIL_LOGIN_URL);
      this.$data.isLoading = false;
    },
    /**
     * ログインユーザが管理者権限を持つかどうかを返す
     */
    getIsAdminLoggedIn(): boolean {
      const loginUserAuthority: UCOM_ACCOUNT_AUTHORITY = this.$store.getters['ucomAccountStore/ucomAccount'].accountInfo.accountAuthorization;
      if (loginUserAuthority === UCOM_ACCOUNT_AUTHORITY.ADMIN_ACCOUNT) {
        return true;
      }
      return loginUserAuthority === UCOM_ACCOUNT_AUTHORITY.CUSTOMER_NO_USER;
    },
  },
});
