import { Module } from 'vuex';
import { RootState } from '../index';

/** サービス e-mansion Connectix 外部 API をコールするサービス */
import { EMansionSharedExternalApiService } from '../../shared/services/external-api/e-mansion-shared-external-api-service';
import { EMansionCustomer } from '../../shared/classes/external-api/e-mansion/customer-response';
import { EMansionProperty } from '../../shared/classes/external-api/e-mansion/property-response';
import { EMansionSharedErrorResponse } from '../../shared/classes/external-api/e-mansion/shared-error-response';
import { FindCustomerByIdQueryDto } from '@/shared/classes/external-api/e-mansion/customer-dto';
import { EMansionRoomsResponseResult } from '@/shared/classes/external-api/e-mansion/rooms-response';
import { UaTypeValue } from '@/shared/const/service-type';

/** e-mansion 共通系 State */
const eMansionCommonState: {
  customer: EMansionCustomer | EMansionSharedErrorResponse | null;
  property: EMansionProperty | EMansionSharedErrorResponse | null;
  rooms: EMansionRoomsResponseResult | EMansionSharedErrorResponse | null;
} = {
  /** e-mansion 契約基本情報 */
  customer: null,
  /** e-mansion 物件基本情報 */
  property: null,
  /** 部屋番号一覧 */
  rooms: null,
};

/** State の型 */
type EMansionCommonState = typeof eMansionCommonState;

/** e-mansion 共通系 Store */
export const eMansionCommonStore: Module<EMansionCommonState, RootState> = {
  namespaced: true,
  state: eMansionCommonState,
  actions: {
    /**
     * e-mansion 契約基本情報取得 action
     * @param context
     * @param params リクエストパラメータ（会員 ID＋クエリパラメータ）
     * @return EmansionCustomer 契約基本情報
     * @return EmansionSharedErrorResponse 共通エラークラス
     */
    async customer(context, params: { memberId: string; query: FindCustomerByIdQueryDto }) {
      // API コールする
      const customerValue: EMansionSharedErrorResponse | EMansionCustomer = await EMansionSharedExternalApiService.findCustomerById(params.memberId, params.query);
      // 値をコミットする
      context.commit('customer', customerValue);
      // Getter から取り直して返す
      const afterCustomerValue = context.getters['customer'];
      return afterCustomerValue;
    },
    /**
     * e-mansion 物件基本情報取得 action
     * @param context
     * @param params リクエストパラメータ（物件ID、 UAタイプ）
     * @return EmansionProperty 物件基本情報
     * @return EmansionSharedErrorResponse 共通エラークラス
     */
    async property(context, params: { apartmentId: string; uaType: UaTypeValue }) {
      // API コールする
      const propertyValue: EMansionProperty | EMansionSharedErrorResponse = await EMansionSharedExternalApiService.findPropertyById(params);
      // 値をコミットする
      context.commit('property', propertyValue);
      // Getter から取り直して返す
      const afterPropertyValue = context.getters['property'];
      return afterPropertyValue;
    },
    /**
     * e-mansion 部屋番号一覧取得 action
     * @param context
     * @param ua_type UA種別 1:e-mansion,2:Five.A,3:UCOM光R
     * @param property_id 物件ID
     * @return EMansionRoomsResponseResult 部屋番号一覧
     * @return EMansionSharedErrorResponse 共通エラークラス
     */
    async rooms(context, params: { uaType: UaTypeValue; propertyId: string }) {
      // API コールする
      const roomsValue: EMansionRoomsResponseResult | EMansionSharedErrorResponse = await EMansionSharedExternalApiService.findRooms(params.uaType, params.propertyId);
      // 値をコミットする
      context.commit('rooms', roomsValue);
      // Getter から取り直して返す
      const afterRoomsValue = context.getters['rooms'];
      return afterRoomsValue;
    },
  },
  mutations: {
    customer: (state, value: EMansionCustomer) => (state.customer = value),
    property: (state, value: EMansionProperty) => (state.property = value),
    rooms: (state, value: EMansionRoomsResponseResult) => (state.rooms = value),
  },
  getters: {
    customer: (state): EMansionCustomer | EMansionSharedErrorResponse | null => state.customer,
    property: (state): EMansionProperty | EMansionSharedErrorResponse | null => state.property,
    rooms: (state): EMansionRoomsResponseResult | EMansionSharedErrorResponse | null => state.rooms,
  },
};
