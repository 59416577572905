




































































import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiAccountInfoForDeleteAccessor } from '@/infra/accessor/spf/ucom/spf-api-account-info-for-delete-accessor';
import { checkRouterError } from '@/shared/util/router-navigation-func';
import { UCOM_ACCOUNT_OPTION_QUERY_TYPE } from '@/shared/const/ucom/ucom-account-option';

export default Vue.extend({
  name: 'account-delete-confirmation',
  components: {
    LoadingComponent,
  },
  data: () => ({
    isLoading: true,
  }),
  async mounted() {
    //前画面よりアカウント情報を取得する
    const ucomAccountId = this.$store.getters['ucomAccountManagementStore/ucomAccountId'];
    if (!ucomAccountId) {
      this.$store.commit('ucomAccountManagementDeleteStore/clear');
      //アカウントID管理一覧画面へ
      await this.$router.push('/ucom/account-management/list').catch((error) => {
        checkRouterError(error);
      });
      this.$data.isLoading = false;
      return;
    }
    this.$data.isLoading = false;
  },

  methods: {
    /** 戻るボタン押下時 */
    async goBack() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      await this.$router.push('/ucom/account-management/delete').catch((error) => {
        checkRouterError(error);
      });
      this.$data.isLoading = false;
    },
    /**削除 確認ボタン押下時 */
    async goDelete() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      /** SU14-014 削除完了画面 */
      //アカウントID情報を$storeより取得する
      try {
        //アカウントID情報を取得
        const ucomAccountId = this.$store.getters['ucomAccountManagementStore/ucomAccountId'];

        const ucomAccountInfo = await SpfApiAccountInfoForDeleteAccessor.deleteAccountInfo(ucomAccountId);
        this.$store.commit('ucomAccountManagementDeleteStore/accountName', ucomAccountInfo.accountName);
        this.$store.commit('ucomAccountManagementDeleteStore/fullName', ucomAccountInfo.fullName);
        this.$store.commit('ucomAccountManagementDeleteStore/mailAddress', ucomAccountInfo.mailAddress);
        this.$store.commit('ucomAccountManagementDeleteStore/accountAuthorization', ucomAccountInfo.accountAuthorization);
        this.$store.commit('ucomAccountManagementDeleteStore/isTargetLoginUser', ucomAccountInfo.isTargetLoginUser);
      } catch (error) {
        // 削除関連store情報の削除
        this.$store.commit('ucomAccountManagementDeleteStore/clear');
        await this.$router.push('/error');
        this.$data.isLoading = false;
        return;
      }

      /** SU14-014 削除処理 */
      await this.$router.push('/ucom/account-management/delete-complete').catch((error) => {
        checkRouterError(error);
      });
      this.$data.isLoading = false;
    },
    async onAccountId() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      // 削除関連store情報の削除
      this.$store.commit('ucomAccountManagementDeleteStore/clear');

      /** アカウントID管理：登録・変更_入力画面に遷移 */
      await this.$router.push('/ucom/account-management/input').catch((error) => {
        checkRouterError(error);
      });
      this.$data.isLoading = false;
    },
    async onMailAddress() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;

      const accountName = this.$store.getters['ucomAccountManagementDeleteStore/accountName'];
      const ucomAccountId = this.$store.getters['ucomAccountManagementStore/ucomAccountId'];
      const currentMailAddress = this.$store.getters['ucomAccountManagementDeleteStore/mailAddress'];
      const mailAddressType = this.$store.getters['ucomAccountManagementDeleteStore/accountName'];
      this.$store.commit('ucomAccountOptionStore/accountName', accountName);
      this.$store.commit('ucomAccountOptionStore/ucomAccountId', ucomAccountId);
      this.$store.commit('ucomAccountOptionStore/currentMailAddress', currentMailAddress);
      this.$store.commit('ucomAccountOptionStore/mailAddressType', mailAddressType);
      // isChangeとあるがbooleanではないので注意
      this.$store.commit('ucomAccountOptionStore/isChange', UCOM_ACCOUNT_OPTION_QUERY_TYPE.UPDATE);

      // 削除関連store情報の削除
      this.$store.commit('ucomAccountManagementDeleteStore/clear');

      /** アカウントID管理：メールアドレス登録・変更_入力画面に遷移 */
      await this.$router.push('/ucom/account-option-change/mail-input').catch((error) => {
        checkRouterError(error);
      });
      this.$data.isLoading = false;
    },
    // ページTOPへ
    scrollPageTop() {
      window.scrollTo(0, 0);
    },
  },
});
