






























































































import Vue from 'vue';
import LoadingComponent from '@/shared/components/loading-component.vue';
import { SpfApiMailPasswordAccessor } from '../../../infra/accessor/spf/e-mansion/spf-api-mail-password-accessor';
import { EMansionMailPasswordInput } from '@/shared/classes/spf-api/mail/model/mail-pw/e-mansion-mail-password-input';
import { EMansionMailPasswordUpdate } from '@/shared/classes/spf-api/mail/model/mail-pw/e-mansion-mail-password-update';
import { EMansionAccountInfo } from '@/shared/classes/spf-api/mail/model/account/e-mansion-account-info';
import store from '@/store';
import { SpfApiServerCommonUtilAccessor } from '@/infra/accessor/spf/common/spf-api-server-common-util-accessor';
import { ObfuscationService } from '@/shared/services/obfuscation/obfuscation-service';
export default Vue.extend({
  name: 'eMansionPasswordInput',
  components: {
    LoadingComponent,
  },
  data: () => ({
    accountInfo: EMansionAccountInfo,
    mailAddress: '',
    message: undefined,
    isPasswordView: false,
    isPasswordViewConf: false,
    canChange: false,
    isLoading: true,
    isChecked: false,
    newPassword: '',
    newPasswordConf: '',
  }),
  computed: {
    inputType: function () {
      return this.isPasswordView ? 'text' : 'password';
    },
    inputTypeConf: function () {
      return this.isPasswordViewConf ? 'text' : 'password';
    },
  },
  /** インスタンスが生成され､且つデータが初期化された後の処理 */
  async mounted() {
    try {
      //e-mansion_アカウントIDを取得
      this.$data.accountInfo = store.getters['eMansionAccountStore/eMansionAccountInfo'];
      //AE11-001を呼ぶ
      const eMansionMailPasswordInputInfo: EMansionMailPasswordInput = await SpfApiMailPasswordAccessor.getMailPasswordInputInfo(
        this.$data.accountInfo.eMansionAccountInfo.eMansionAccountId
      );
      if (eMansionMailPasswordInputInfo.mailAddressPossessionMessage) {
        this.$data.message = eMansionMailPasswordInputInfo.mailAddressPossessionMessage;
        this.$data.isLoading = false;
        return;
      }
      this.$data.canChange = true;
      this.$data.mailAddress = eMansionMailPasswordInputInfo.mailAddress;
      this.$data.isLoading = false;
    } catch (error) {
      console.log('エラー');
      await this.$router.push('/e-mansion/error');
      return;
    }
  },
  methods: {
    /**登録ボタンクリック */
    async onChange() {
      if (this.$data.isLoading) {
        return;
      }
      this.$data.isLoading = true;
      //バリデーションチェック
      if (!this.newPassword) {
        this.$data.message = '「パスワード」を入力してください。';
        window.scrollTo(0, 0);
        this.$data.isLoading = false;
        return;
      }
      if (this.newPassword.length < 4) {
        this.$data.message = '「パスワード」は4文字以上12文字以内で入力してください。';
        window.scrollTo(0, 0);
        this.$data.isLoading = false;
        return;
      }
      if (this.newPassword.length > 12) {
        this.$data.message = '「パスワード」は4文字以上12文字以内で入力してください。';
        window.scrollTo(0, 0);
        this.$data.isLoading = false;
        return;
      }
      if (!this.newPassword.match(/^[0-9a-z]*$/)) {
        this.$data.message = '「パスワード」は半角英数小文字で指定してください。';
        window.scrollTo(0, 0);
        this.$data.isLoading = false;
        return;
      }
      if (this.newPassword != this.newPasswordConf) {
        this.$data.message = '「パスワード」の入力と、確認用に入力された内容が一致しません。もう一度新たに入力しなおしてください。';
        window.scrollTo(0, 0);
        this.$data.isLoading = false;
        return;
      }

      try {
        const date = this.formatDate(await SpfApiServerCommonUtilAccessor.getServerTimeNow());
        //パスワードを暗号化
        const obfuscationPassword = ObfuscationService.encode(this.newPassword);
        //AE11-002を呼ぶ
        const mailPasswordUpdateResponse: EMansionMailPasswordUpdate = await SpfApiMailPasswordAccessor.mailPasswordUpdate(
          this.$data.accountInfo.eMansionAccountInfo.eMansionAccountId.toString(),
          obfuscationPassword,
          date
        );

        if (mailPasswordUpdateResponse.message.length > 0) {
          this.$data.message = mailPasswordUpdateResponse.message[0];
          window.scrollTo(0, 0);
          this.$data.isLoading = false;
          return;
        }
      } catch (error) {
        // エラー画面に遷移
        await this.$router.push('/e-mansion/error');
        this.$data.isLoading = false;
        return;
      }

      this.$store.commit('eMansionMailPasswordStore/newPassword', this.newPassword);
      this.$store.commit('eMansionMailPasswordStore/mailAddress', this.$data.mailAddress);
      await this.$router.push('/e-mansion/mail-password/complete');

      this.$data.isLoading = false;
      return;
    },
    formatDate(dt: Date) {
      const y = dt.getFullYear();
      const m = ('00' + (dt.getMonth() + 1)).slice(-2);
      const d = ('00' + dt.getDate()).slice(-2);
      return y + '/' + m + '/' + d;
    },
    onPasswordView() {
      this.isPasswordView = !this.isPasswordView;
    },
    onPasswordViewConf() {
      this.isPasswordViewConf = !this.isPasswordViewConf;
    },
  },
});
