/** 会員 */
export class Member {
  /** 会員 ID */
  public id!: number;
  /** 物件 ID */
  public propertyId?: number;
  /** 姓 */
  public firstName!: string;
  /** 名 */
  public givenName!: string;
  /** 姓 フリガナ : 全角カタカナ */
  public firstNameKatakana!: string;
  /** 名 フリガナ : 全角カタカナ */
  public givenNameKatakana!: string;
  /** メールアドレス */
  public emailAddress!: string;
  /** 電話番号 : ハイフンを含める */
  public phoneNumber!: string | null;
  /** 生年月日 */
  public birthdate!: string | null;
  /** 部屋番号 : 半角英数。棟番号が入ることもある */
  public roomNumber?: string;
  /** Portas テナントの Auth0 ID  */
  public primaryKeyAuth0?: string;
  /** UCOM の主キー : 顧客基本情報 (顧客 ID) */
  public primaryKeyUcom?: string;
  /** Mye の主キー : 会員管理 (会員 ID) */
  public primaryKeyMye?: string;
  /** Mcloud の主キー : users.id */
  public primaryKeyMcloud?: string;
  /** 会員ステータス */
  public memberStatus!: number;
  /** 郵便番号 */
  public zipcode?: string;
  /** 住所 */
  public address?: string;
  /** 棟名を含む物件名 */
  public apartmentBlockName?: string | null;
  /** 棟番号 */
  public buildingNumber?: string;
  /** e-TNC用会員 ID */
  public eTncMemberId?: string;
  /** UCOM */
  public ucom?: UcomInMemberDto;
  /** e-mansion */
  public mye?: MyeInMemberDto;
  /** Mcloud */
  public mcloud?: McloudInMemberDto;

  constructor(partial: Partial<Member>) {
    Object.assign(this, partial);
  }
}


/** 会員情報用UCOMDTO */
export class UcomInMemberDto {
  /** リモートサポート契約状態 */
  public remote_support_status?: boolean;

  /** UCOM 光電話契約状態 */
  public ucom_optical_phone_status?: boolean;

  /** GATE CALL050 契約状態 */
  public gate_call050_status?: boolean;

  /** クレジットカードの登録有無 */
  public is_register_credit_card?: boolean;

  /** Connectix契約状態 */
  public connectix_status?: boolean;

  /** Connectix提供有無 */
  public connectix_offer?: boolean;

  /** Connectix 利用金額 */
  public connectix_price?: string;

  /** メールサービスの有無 */
  public email_servie?: boolean;

  /** リモートサポート料金 */
  public remote_support_price?: string;

  /** リモートサポート有無 */
  public remote_support?: boolean;

  /** U-NEXT with ギガシネマ表示有無 */
  public giga_cinema?: boolean;

  /** 雑誌読み放題ポイントサービス表示有無 */
  public magazine_point_service?: boolean;

  /** 建物専用サイトURL */
  public building_site?: string;

  /** 部屋番号 */
  public room_number?: string;

  /** コンストラクタ */
  constructor(partial: Partial<UcomInMemberDto>) {
    Object.assign(this, partial);
  }
}

/** 会員情報用Mye(e-mansion)DTO */
export class MyeInMemberDto {
  /** ステータス */
  public status?: number;

  /** 本体*/
  public result?: {
    /** 部屋番号 */
    room_number?: string;

    /** 氏名 */
    name?: string;

    /** カナ */
    kana?: string;

    /** 性別 */
    sex?: string;

    /** 生年月日 */
    birthday?: string;

    /** 電話番号 */
    phone_number?: string;

    /** 入居予定日 */
    scheduled_move_in_on?: string;

    /** 物件名称 */
    property_name?: string;

    /** 棟名称 */
    building_name?: string;

    /** 郵便番号 */
    postal_code?: string;

    /**住所1 */
    address1?: string;

    /** 物件 ID */
    property_id?: string;

    /** e-mansion ログイン後 TOP の「メール認証キー設定」表示有無 */
    email_authentication_key_setting_display?: string;

    /** ウィルスチェックあんしんサービス plus の契約状態 */
    op_virus_check_service_contract_status?: boolean;

    /** IP フォンの契約状態 */
    op_ipp_contract_status?: boolean;

    /** Norton360 の契約状態 */
    op_360_contract_status?: boolean;

    /** マルチデバイス N360 の契約状態 */
    op_md360_contract_status?: boolean;

    /** Internet Sagi Wall の契約状態 */
    op_isw_contract_status?: boolean;

    /** 有料メールの契約状態 */
    op_mailplus_contract_status?: boolean;

    /** Norton Internet Security の契約状態 */
    op_nis_contract_status?: boolean;

    /** i フィルターの契約状態 */
    op_ift_contract_status?: boolean;

    /** ホームページの契約状態 */
    op_hp_contract_status?: boolean;

    /** GIP の契約状態 */
    op_gip_contract_status?: boolean;

    /** Connectix の契約状態 */
    op_connectix_contract_status?: boolean;

    /** UCOM光電話の契約状態 */
    op_hphone_contract_status?: boolean;

    /** 支払方法 */
    payment_id?: string;

    /** ログインアカウント情報 */
    em_login?: string;
  }

  /** コンストラクタ */
  constructor(partial: Partial<MyeInMemberDto>) {
    Object.assign(this, partial);
  }
}

/** 会員情報用MCloud */
export class McloudInMemberDto {
  public data?: McloudDataInMemberDto;

  public included?: McloudIncludedInMemberDto[];

  /** コンストラクタ */
  constructor(partial: Partial<McloudInMemberDto>) {
    Object.assign(this, partial);
  }
}

export class McloudDataInMemberDto {
  public id?: string;

  public type?: string;

  public attributes?: {
    id?: number;

    post_id?: number;

    post_name?: string;

    last_name?: string;

    first_name?: string;

    last_kana?: string;

    first_kana?: string;

    kind?: number;

    room_number?: string;

    email?: string;

    emails: string[];

    notify_to?: number;

    telephone_number?: string;

    board_flag?: boolean;

    user_groups?: UserGroups[];

    role_id?: number;

    regist_status?: string;

    activated_at?: string;

    login?: string;

    login_session_retention_period?: string;

    verifying?: string;

    profile_image_file_id?: number;

    image_url?: string;

    use_ekey?: boolean;

    use_room_ekey_notice_inbound?: boolean;

    use_room_ekey_notice_outbound?: boolean;

    pos_bind_available?: boolean;
  };

  public relationships?: {
    building?: {
      data?: {
        id?: string;

        type?: string;
      };
    };
  };

  public pos_contractant?: {
    data?: {
      id?: string;

      type?: string;
    }
  };

  public room_ekeys?: {
    data?: RelationshipRoomEkeysData[];
  };
}

export class RelationshipRoomEkeysData {
  public id?: string;

  public type?: string;
}

export class UserGroups {
  public id?: number;

  public name?: string;
}

export class McloudIncludedInMemberDto {
  public id?: string;
  public type?: string;
  public attributes?: {
    name?: string;
  };
}

export class RelationshipInboundData {
  public id?: string;

  public type?: 'inbound';
}

export class RelationshipOutboundData {
  public id?: string;

  public type?: 'outbound';
}